import React, { useContext } from 'react';
import { hocRailsAction, FormsContext } from '../../Document';
import { Link, Grid, Typography } from '@mui/material';
import NavigationBar from "../../trip-page/NavigationBar";
import SiteHeader from "../../shared/SiteHeader";
import SiteFooter from "../../shared/SiteFooter";
import { useStyles as homePageStyles } from "../home/HomePage";
import { PublicTripType } from "../../shared/TripTypes";

type InviteRequiredPageProps = {
  currentUserAvatarUrl: string | undefined,
  isUserLoggedIn: boolean,
  navItems: [],
  trip: PublicTripType,
};

const InviteRequiredPage = ({
  currentUserAvatarUrl,
  isUserLoggedIn,
  navItems,
  trip,
}: InviteRequiredPageProps) => {
  const classes = homePageStyles();
  const { triggerLogIn } = useContext(FormsContext);

  return (
    <div className={classes.root}>
      <SiteHeader
        currentUserAvatarUrl={currentUserAvatarUrl}
        isUserLoggedIn={isUserLoggedIn}
        showCoverPhoto
        trip={trip}
      />
      <NavigationBar disabled={true} items={navItems} />
      <Grid container direction="row" alignItems="center" className={classes.messageBox}>
        <Grid item>
          <Typography variant="h1">
            Invite required 🔑
          </Typography>
          <Typography>
            Only invited travelers can access this tripsite. { !isUserLoggedIn && (
              <>
                Already invited?
                <Link href="#" onClick={ triggerLogIn }> Log in </Link>
              </>
            )}
          </Typography>
          <Typography>
            Looking to join the trip? Have a current traveler invite you via email, or reach out to the planner directly to be granted access.
          </Typography>
          <Typography>
            Have additional questions? Email
            <Link href="mailto:help@troupe.com" underline="none"> help@troupe.com </Link>
            and we’ll lend a hand.
          </Typography>
        </Grid>
      </Grid>
      <SiteFooter />
    </div>
  );
};

export default hocRailsAction(InviteRequiredPage);
