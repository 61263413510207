type TroupeAppType = {
  Notification: NotificationType | undefined,
  User: UserType | undefined,
};

type UserType = {
  // eslint-disable-next-line no-unused-vars
  identify: (id: string, oneSignalAuthHash: string) => {}
}
type NotificationType = {
  permission: 'notDetermined' | 'denied' | 'authorized',
  requestPermission: () => {},
};

export default {
  Notification: window['TroupeApp' as keyof Window]?.Notification,
  User: window['TroupeApp' as keyof Window]?.User,
} as TroupeAppType;
