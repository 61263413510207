import { gql } from "@apollo/client";

export const GET_FAVORITES_LISTINGS = gql`
  query GetFavorites($favoriteId: ID!) {
    node(id: $favoriteId) {
      id
      ... on Favorite {
        link
        listings {
          id
          arrival
          departure
          externalListing {
            id
            available
            externalId
            name
            bedrooms
            bathrooms
            starRating
            maxGuests
            reviewsCount
            images
            publicUrl
            propertyType
            pricing {
              currency
              nights
              price
              pricePerNight
              type
            }
          }
        }
      }
    }
  }
`;
