import React from "react";
import MainContent from "./ItineraryDatesPage/MainContent";
import withLayout from "./withLayout";
import SideBarPage from "../../../shared/SideBarPage";
import { ItineraryType } from "../../../shared/TripObjectTypes";

type ItineraryDatesPageProps = {
  itinerary: ItineraryType,
};

const ItineraryDatesPage = ({
  itinerary,
}: ItineraryDatesPageProps) => {

  return (
    <SideBarPage
      mainContent={
        <MainContent
          itinerary={itinerary}
        />}
    />
  );
}

export default withLayout(ItineraryDatesPage, { fluid: true });
