export const track = (event: string, props: object): void => {
  window.analytics.track(event, props);
};

export const trackLink = (element: HTMLElement, event: string, props: object): void => {
  window.analytics.trackLink(element, event, props);
};

export const group = (id: string, traits: object): void => {
  window.analytics.group(id, traits);
};

const analytics = {
  track,
  trackLink,
  group,
};

export default analytics;
