import React from 'react';
import { Grid, Typography, Checkbox, Switch } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

type BookingOptionsProps = {
  freeCancellationFilter: boolean,
  instantBookFilter: boolean,
  updateFreeCancellationFilter: (value: boolean) => void,
  updateInstantBookFilter: (value: boolean) => void,
};

const bookingOptionsFiltersTexts = {
  instantBook: {
    title: 'Instant confirmation',
    text: 'Booking without waiting for owner approval.',
  },
  freeCancellation: {
    title: 'Free cancellation',
    text: 'Properties offering free cancellation depend on your trip dates. Check policy for details.',
  },
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontWeight: 600,
  },
  selectedCount: {
    color: '#747474',
    fontSize: '12px',
  },
  filterSection: {
    paddingLeft: '16px !important',
  }
}));

const BookingOptions : React.FC<BookingOptionsProps> = ({
  instantBookFilter,
  freeCancellationFilter,
  updateFreeCancellationFilter,
  updateInstantBookFilter,
}) => {
  const classes = useStyles();

  const renderSection = (title : string, text: string, isChecked : boolean, onChange : (value: boolean)=>void) => (
    <Grid item container data-test-id={`${title}-section`} direction='row' flexWrap='nowrap' className={classes.filterSection}>
      <Switch
        checked={isChecked}
        onChange={(e : React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}

      />
      <Grid item container direction='column'>
        <Grid item>
          <Typography data-test-id='filter-title' className={classes.subtitle}>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography data-test-id='filter-desc'>{text}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return(
    <Grid item container direction='column' spacing={3} mb={4}>
      <Grid item>
        <Typography variant="h3" className={classes.title}>Booking options</Typography>
      </Grid>
      {renderSection(
        bookingOptionsFiltersTexts.freeCancellation.title,
        bookingOptionsFiltersTexts.freeCancellation.text,
        freeCancellationFilter,
        updateFreeCancellationFilter,
      )}
      {renderSection(
        bookingOptionsFiltersTexts.instantBook.title,
        bookingOptionsFiltersTexts.instantBook.text,
        instantBookFilter,
        updateInstantBookFilter
      )}
    </Grid>
  )
};

export default BookingOptions;
