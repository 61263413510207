import { theme } from "../Document"

type BorderColors = {
  danger: string,
  primary: string,
  success: string,
  warning: string,
};

const borderColors: BorderColors = {
  danger: 'linear-gradient(to top, #e55f5c 0%, #d85160 100%)',
  primary: theme.palette.general.primary,
  success: 'linear-gradient(to top, #9fe55c 0%, #27bb56 100%)',
  warning: 'linear-gradient(to top, #ffb925 0%, #ddd212 100%)',
};

export default borderColors;
