import React, { useContext, useReducer, useRef } from "react";
import {
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import withAddStayPageLayout from "./withAddStayPageLayout";
import Button from "../../../buttons/Button";
import { TripSiteContext } from '../../../Document';
import StayPreview from "../StayForm/StayPreview";
import useStateWithPromise from "../../../../services/useStateWithPromise";
import RailsForm from "../../../shared/RailsForm";
import { FormContext } from "../../../shared/RailsForm";
import {
  tripStaysPath,
} from '../../../../routes';
import AddressField from "../StayForm/AddressField";
import {Place} from "../../../shared/GooglePlacesAutocomplete";
import pick from "lodash/pick";

const useStyles = makeStyles((theme) => ({
  addDetailsButton: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  centeredSection: {
    textAlign: 'center',
  },
  multipleLinksButton: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
  },
}));

export const GoogleMapsForm = () => {
  const [value, setValue] = useStateWithPromise({});
  const [addDetails, setAddDetails] = useStateWithPromise(false);
  const { tripId } = useContext(TripSiteContext);
  const inputRef = useRef(null);
  const classes = useStyles();

  const initialState = {
    hasAutomaticAvailability: false,
    showFetchErrorMessage: false,
    showActions: false,
  };

  const reducer = (state, action) => {
    switch(action.type) {
      case 'showFetchErrorMessage':
        return { showFetchErrorMessage: true, showActions: false }
      case 'showActions':
        return { showFetchErrorMessage: false, showActions: true, hasAutomaticAvailability: action.hasAutomaticAvailability }
      case 'hideAll':
        return { showFetchErrorMessage: false, showActions: false }
      case 'resetForm':
        setValue({}).then(() => inputRef.current.focus());
        return initialState;
      default:
        throw new Error();
    }
  };

  const [{ hasAutomaticAvailability, showActions }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  let stayPreviewParams;

  if (value.address) {
    stayPreviewParams = { address: pick(value, ['address', 'google_place_id', 'google_place_image', 'google_place_url', 'longitude', 'latitude']) };
  } else {
    stayPreviewParams = {};
  }

  const handleAddressChange = (address: Place) => {
    if (address) {
      setValue({
        address: address.name,
        google_place_id: address.id,
        google_place_url: address.url,
        google_place_image: address.image,
        latitude: address.latitude,
        longitude: address.longitude,
      });
    } else {
      dispatch({ type: 'resetForm' });
    }
  };

  const saveButtonLabel = hasAutomaticAvailability ? "Save & add details" : "Save & add pricing";

  return (
    <RailsForm
      action={tripStaysPath(tripId)}
      method="post"
    >
      <Grid container justifyContent='center'>
        {addDetails && (
          <input type="hidden" name="add_details" value="true" />
        )}
        <FormContext.Consumer>
          {({ submit }) => (
            <Grid
              container
              direction="column"
              spacing={2}
              pb={3}
              sx={{ maxWidth: '800px' }}
            >
              <Grid item>
                <AddressField
                  currentGooglePlaceId={value.googlePlaceId}
                  data-test-id="google-maps-form"
                  currentGooglePlaceUrl={value.googlePlaceUrl}
                  currentAddress={value.address}
                  currentLatitude={value.latitude}
                  currentLongitude={value.longitude}
                  currentImage={value.googlePlaceImage}
                  onChange={handleAddressChange}
                  ref={inputRef}
                  required
                />
                <Grid pt={2}>
                  <StayPreview
                    onError={() => dispatch({ type: 'showFetchErrorMessage' })}
                    onFetch={(hasAutomaticAvailability) => dispatch({ type: 'showActions', hasAutomaticAvailability: hasAutomaticAvailability })}
                    onLoading={() => dispatch({ type: 'hideAll' })}
                    onRemove={() => dispatch({ type: 'resetForm' })}
                    resourcePath={tripStaysPath(tripId)}
                    tripId={tripId}
                    { ...stayPreviewParams }
                  />
                </Grid>
                {showActions && (
                  <>
                    <Button
                      data-test-id="save-and-add"
                      fullWidth
                      variant="primary"
                      onClick={() => setAddDetails(true).then(() => submit())}
                      type="button"
                      className={classes.addDetailsButton}
                    >
                      {saveButtonLabel}
                    </Button>
                    <Grid item pb={4} pt={2}>
                      <Button
                        data-test-id="save-now"
                        fullWidth
                        variant="tertiary"
                        onClick={submit}
                        type="button"
                      >
                        Save now
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </FormContext.Consumer>
      </Grid>
    </RailsForm>
  );
};
export default withAddStayPageLayout(GoogleMapsForm);
