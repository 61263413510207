import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type SectionHeaderProps = {
  note: string | React.ReactNode,
  title: string,
  rightElement?: string | React.ReactNode,
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  note: {
    fontSize: 16,
    paddingBottom: theme.spacing(2),
  },
}));

const SectionHeader = ({ title, note, rightElement }: SectionHeaderProps) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" justifyContent="space-between" className={classes.root} data-test-id="sectionHeader">
      <Grid container item justifyContent="space-between">
        <Grid item>
          <Typography className={classes.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          {rightElement}
        </Grid>
      </Grid>
      <Grid item className={classes.note}>{note}</Grid>
    </Grid>
  );
};

export default SectionHeader;
