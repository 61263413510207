import React, {useContext} from "react";
import clsx from "clsx";
import {
  Divider,
  Grid,
  Link as TitleLink,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TripObject from "../../../../shared/TripObject";
import VotingProgressBanner from '../../../../shared/VotingProgressBanner';
import { TripSiteContext } from '../../../../Document';
import {
  DestinationType,
} from "../../../../shared/TripObjectTypes";
import RankingLabel from "../shared/RankingLabel";
import VotingSummaryDialog from "../../../../shared/VotingSummaryDialog";
import { TravelerProps } from "../../../../shared/TravelersVotingBox";
import { usePhasingContext } from "../../../../../services/phasing";
import rankLabels from "../../../../shared/rankLabels";
import SentimentButtons from "../shared/SentimentButtons";
import CommentIcon from '@mui/icons-material/Comment';
import Button from "../../../../buttons/Chip";
import CommentsLink from "../shared/CommentsLink";
import ActionsButtonGroup from "../../../../shared/ActionsButtonGroup";
import {
  addBySearchTripStaysPath,
  tripDestinationRankingsPath,
  tripDestinationPath,
  editTripDestinationPath,
} from '../../../../../routes';
import TripObjectLink from "../../../../shared/TripObjectLink";
import Linkify from "../../../../shared/Linkify";
import AsyncSentimentButtons from "../shared/AsyncSentimentButtons";
import { shouldDisplayRankingLabel } from "../../../../../services/displayRankingLabel";
import ExploreStaysButton from "../../../../shared/ExploreStaysButton";

const useStyles = makeStyles((theme) => ({
  chosenDestination: {
    border: `2px solid ${theme.palette.general.primary}!important`,
  },
  destinationCardImage: {
    display: 'block',
    height: '270px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '172px',
    },
    objectFit: 'cover',
    objectPosition: 'center',
    background: '#F4F5FF',
  },
  destinationName: {
    display: 'inline-block',
    position: 'relative',
    textAlign: 'left',
    lineHeight: '24px',
    color: 'black',
    fontSize: 18,
    fontWeight: 600,
    paddingBottom: theme.spacing(2),
    zIndex: 2,
    '&:hover': {
      color: '#3B41B0',
      textDecoration: ["none","!important"],
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: "calc(100% - 88px)",
    },
  },
  destinationLink: {
    display: 'inline-block',
    fontSize: 16,
    zIndex: 2,
    '&:hover': {
      textDecoration: ["underline","!important"],
    },
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  openNewTabIcon: {
    position: 'relative',
    top: '3px',
    fontSize: '16px',
  },
  verticalActions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  commentButton: {
    zIndex: 2,
    height: '32px !important',
  },
  commentButtonContainer: {
    textAlign: 'right',
  },
  externalButtons: {
    flexDirection: 'row',
    padding: '16px 0px 16px 0',
  },
  divider: {
    borderColor: '#e6e7e8',
    width:'100%',
    marginBottom: theme.spacing(2),
  },
  viewOnMap: {
    paddingBottom: theme.spacing(4),
  },
}));

type DestinationProps = {
  className?: string,
  destination: DestinationType,
  displayCommentButton?: boolean,
  isChosen?: boolean,
  isClickable?: boolean,
  isPlanner?: boolean,
  hideVotes?: boolean,
  linkToComment?: string,
  showActions?: boolean,
  onDestinationClick?: () => void,
  ranking?: number,
  showVotingProgressBanner?: boolean,
  travelers?: TravelerProps[],
  setDestinationComment?: () => void,
  displayActions?: boolean,
  showCommentButton?: boolean,
  suggestionsCount?: number
  page?: 'index' | null,
  openVotingProgress?: (voteCompleted: boolean) => void,
}

const Destination = ({
  className,
  destination,
  displayCommentButton = false,
  isChosen,
  isClickable,
  isPlanner,
  hideVotes = false,
  linkToComment,
  showActions,
  onDestinationClick,
  displayActions = false,
  ranking,
  showVotingProgressBanner = false,
  travelers,
  showCommentButton = false,
  setDestinationComment,
  suggestionsCount,
  page,
  openVotingProgress,
}: DestinationProps) => {
  const classes = useStyles();
  const fallbackSource = "/assets/destination_fallback.svg";
  const { currentUserId, tripId, tripGid } = useContext(TripSiteContext);
  const destinationId = destination.uuid ?? destination.id
  const destinationGid = destination.gid ?? destination.id

  const { areDestinationsInVotingPhase, areDestinationsFinalized } = usePhasingContext();
  const shouldDisplayVotes = !hideVotes && (areDestinationsFinalized() || areDestinationsInVotingPhase());
  const destinationRankings = travelers?.find(traveler => traveler.userId === currentUserId )?.destinationRankings
  const queryParams = {
    latitude: destination.latitude,
    longitude: destination.longitude,
    location_name: destination.name,
  };

  const exploreStaysButtonUrl = addBySearchTripStaysPath(tripId, queryParams);

  const displayVotingProgressBanner = showVotingProgressBanner && areDestinationsInVotingPhase();

  return <>
    <TripObject
      anchor={`destination_${destinationId}`}
      className={clsx(
        className,
        {[classes.chosenDestination]: isChosen},
      )}
      isClickable={isClickable}
      linkHref={!onDestinationClick ? tripDestinationPath(tripId, destinationId) : undefined}
      header={
        <>
          {showActions && (
            <ActionsButtonGroup
              deletePath={tripDestinationPath(tripId, destinationId)}
              disableDeleteButton={destination.isChosen}
              editPath={editTripDestinationPath(tripId, destinationId)}
              resourceName="destination"
              testId="destinationActions"
            />
          )}
        </>
      }
      noMargin
      testId="tripDestination"
      onTripObjectClick={onDestinationClick}
      visual={
        <>
          {shouldDisplayRankingLabel(ranking) && (<RankingLabel ranking={destination.ranking}/>)}
          <img
            alt='destination_image'
            className={classes.destinationCardImage}
            src={destination.image || fallbackSource}
          />
        </>
      }
      content={
        <>
          <Grid container direction="column">
            <TitleLink
              href={tripDestinationPath(tripId, destinationId)}
              underline="none"
              color="inherit"
              className={classes.destinationName}
              data-test-id='destinationName'
              onClick={onDestinationClick}
            >
              <Linkify>{destination.name}</Linkify>
            </TitleLink>
            <Divider className={classes.divider}/>
            <Grid
              className={classes.viewOnMap}
              item
            >
              <TripObjectLink
                url={destination.googlePlaceUrl}
                linkText='View on map'
                dataTestId='destinationLink'
              />
              <ExploreStaysButton
                destination={destination}
                url={exploreStaysButtonUrl}
              />
            </Grid>
            {shouldDisplayVotes && <>
              <Grid container direction="row" pb={2}>
                <Grid item xs={6} container >
                  <VotingSummaryDialog
                    isPlanner={isPlanner}
                    travelers={travelers}
                    objectId={destinationId}
                    objectType='destinations'
                  />
                </Grid>
                {isClickable &&
                  <Grid item xs={6} container justifyContent="flex-end">
                    <CommentsLink
                      commentsLink={tripDestinationPath(tripId, destinationId)}
                      commentsCount={destination.commentsCount}
                      segment={{
                        tripId: tripId,
                        tripObjectId: destination.id,
                        tripObjectType: 'destinations',
                      }}
                    />
                  </Grid>
                }
              </Grid>
            </>
            }
          </Grid>
          {!shouldDisplayVotes && isClickable &&
            <Grid container direction="row">
              <Grid item xs={12} container justifyContent="flex-end" pb={2}>
                <CommentsLink
                  commentsLink={tripDestinationPath(tripId, destinationId)}
                  commentsCount={destination.commentsCount}
                  segment={{
                    tripId: tripId,
                    tripObjectId: destination.id,
                    tripObjectType: 'destinations',
                  }}
                />
              </Grid>
            </Grid>
          }
          {displayActions && (
            <>
              <Grid
                container
                direction="row"
                className={classes.verticalActions}
              >
                {areDestinationsInVotingPhase() && (
                  <Grid item xs={showCommentButton ? 9 : 12} container justifyContent="center">
                    { currentUserId ? (
                        <AsyncSentimentButtons
                          currentUserSentiment={destination.currentUserRanking}
                          disabled={destination.isDisabled}
                          page={page}
                          sentiments={rankLabels}
                          tripObjectGid={destinationGid}
                          tripGid={tripGid}
                          testId="tripDestinationButtons"
                          tripObjectType="destination"
                          openVotingProgress={openVotingProgress}
                        />
                    ) : (
                      <SentimentButtons
                        currentUserSentiment={destination.currentUserRanking}
                        disabled={destination.isDisabled}
                        sentimentParamName="position"
                        sentimentSettingPath={tripDestinationRankingsPath(tripId, destinationId)}
                        sentiments={rankLabels}
                        testId="tripDestinationButtons"
                      />
                    )}
                  </Grid>
                )}
                {showCommentButton && (
                  <Grid item xs={areDestinationsInVotingPhase() ? 3 : 12} className={classes.commentButtonContainer}>
                    <Button
                      className={classes.commentButton}
                      onClick={() => setDestinationComment()}
                      segmentProps={{title: 'Comment Field Initiated', tripObjectId: destination.id}}
                      data-test-id='commentButton'
                    >
                      <CommentIcon/>
                    </Button>
                </Grid>)}
              </Grid>
            </>
           )}
        </>
      }
      footer={displayVotingProgressBanner &&
        <VotingProgressBanner
          rankings={destinationRankings}
          suggestionsCount={suggestionsCount}
        />
      }
    />
  </>;
};

export default Destination;
