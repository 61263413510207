import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  ButtonBase,
  Grid,
} from "@mui/material";
import CardButton from "../buttons/CardButton";

export enum Variation {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
};

type TripObjectProps = {
  anchor?: string,
  className?: string,
  content: React.ReactNode,
  contentClassName?: string,
  footer?: React.ReactNode,
  header?: React.ReactNode,
  isClickable?: boolean,
  linkHref?: string,
  linkTargetBlank?: boolean,
  noMargin?: boolean,
  onTripObjectClick?: () => void,
  testId?: string,
  variation?: Variation,
  visual: React.ReactNode,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: '100%',
  },
  noMargin: {
    marginBottom: 0,
  },
  visual: {
    position: "relative",
    width: "100%",
  },
  content: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  clickArea: {
    height: '100%',
    position: "absolute",
    width: '100%',
    zIndex: 1,
  },
  addCursor: {
    cursor: 'pointer',
  },
}));

type ClickAreaProps = {
  anchor?: string,
  linkHref?: string,
  linkTargetBlank?: boolean,
  onTripObjectClick?: () => void,
};

const ClickArea = ({
  anchor,
  linkHref,
  linkTargetBlank,
  onTripObjectClick,
 }: ClickAreaProps) => {
  if (!linkHref && !onTripObjectClick) return null;

  const classes = useStyles();

  return (
    <ButtonBase
      className={classes.clickArea}
      href={linkHref}
      onClick={onTripObjectClick}
      target={linkTargetBlank ? '_blank' : ''}
      id={anchor}
    />
  );
};

const TripObject = ({
  anchor,
  className,
  content,
  contentClassName,
  footer,
  header,
  isClickable,
  linkHref,
  linkTargetBlank,
  noMargin,
  onTripObjectClick,
  testId,
  variation,
  visual,
}: TripObjectProps) => {
  const classes = useStyles();
  const isVertical = variation === Variation.Vertical;

  return (
    <CardButton
      className={clsx(
        classes.root,
        className,
        {
          [classes.noMargin]: noMargin,
          [classes.addCursor]: isClickable,
        },
      )}
      disabled={!isClickable}
    >
      <Box data-test-id={testId}>
        {isClickable && (
          <ClickArea
            anchor={anchor}
            linkTargetBlank={linkTargetBlank}
            linkHref={linkHref}
            onTripObjectClick={onTripObjectClick}
          />
        )}
        <Grid
          container
          direction={isVertical ? "column" : "row"}
          alignItems="stretch"
        >
          {header}
          <Grid item xs={12} sm={isVertical ? undefined : 4} className={classes.visual}>
            {visual}
          </Grid>
          <Grid item xs={12} sm={isVertical ? undefined : 8} className={clsx(classes.content, contentClassName)}>
            {content}
          </Grid>
          {footer &&
            <Grid item xs={12} sm={isVertical ? undefined : 12} className={classes.footer}>
              {footer}
            </Grid>
          }
        </Grid>
      </Box>
    </CardButton>
  );
};

TripObject.defaultProps = {
  variation: Variation.Horizontal,
};

export default TripObject;
