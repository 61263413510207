import React, { useMemo, useState } from "react";
import GoogleMapReact from 'google-map-react';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export type Marker = {
  latitude: number | null,
  longitude: number | null,
};

type MapViewProps = {
  latitude?: number,
  longitude?: number,
  markers: Marker[],
  onDragEnd?: () => void,
  onMapLoaded?: (map) => void,
  onZoomChange?: () => void,
  radius?: number,
  renderMarker: (map, marker) => void,
};

const MapView = ({
  latitude,
  longitude,
  markers,
  onDragEnd = () => {},
  onMapLoaded = () => {},
  onZoomChange = () => {},
  radius,
  renderMarker,
}: MapViewProps) => {
  const [map, setMap] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const defaultCenter = useMemo(() => {
    return (
      {
        lat: markers[0]?.latitude || Number(latitude),
        lng: markers[0]?.longitude || Number(longitude),
      }
    )
  }, []);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const maxMapZoomLevel = isMobile ? 19 : 18;
  const defaultZoom = useMemo(() => Math.round(maxMapZoomLevel - Math.log2(Number(radius) / 500)) || 14, []);

  const apiIsLoaded = (map) => {
    setMap(map);
    onMapLoaded(map);
  };

  const handleDragEnd = () => {
    if (loaded && onDragEnd) onDragEnd();
  };

  const handleZoomChange = () => {
    if (loaded && onZoomChange) onZoomChange();
  };

  return (
    <GoogleMapReact
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({map}) => apiIsLoaded(map)}
      onTilesLoaded={() => setLoaded(true)}
      options={{
        clickableIcons: false,
        fullscreenControl: false,
        panControl: false,
        scaleControl: false,
        maxZoom: maxMapZoomLevel,
      }}
      onDragEnd={() => handleDragEnd()}
      onZoomAnimationEnd={() => handleZoomChange()}
    >
      {map && markers.map((marker) => renderMarker(map, marker))}
    </GoogleMapReact>
  );
};

export default MapView;
