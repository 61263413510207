import React, {useEffect, useReducer} from "react";
import { makeStyles } from "@mui/styles";
import { DialogBottomOnMobile } from "../../../shared/Dialog";
import StepperInput from "../../../shared/StepperInput";
import {
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { default as DateRangePicker }from "../../../shared/CustomDateRangePicker";
import GooglePlacesAutocomplete from "../../../shared/GooglePlacesAutocomplete";
import Button from "../../../buttons/Button";
import clsx from "clsx";
import {aiSearchType} from "./Filters";
import {useTrackSERPEvent} from "../../../../services/segmentEvents/useSERPEvents";

export type AiSearchCriteriaDialogProps= {
  defaultArrival: string | null,
  defaultDeparture: string | null,
  isDialogOpen: boolean,
  defaultLocationName: string,
  defaultLatitude: string,
  defaultLongitude: string,
  defaultMinGuests: number,
  onChange?: (value: any) => void,
  onClose: () => void,
  aiSearchType: aiSearchType,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'initialState':
      return Object.assign({}, action.initialState)
    case 'updateGoogleLocation':
      return Object.assign({}, state, {
        locationName: action.name,
        longitude: action.longitude,
        latitude: action.latitude,
        locationFilled: true,
      })
    case 'setMinGuests':
      return Object.assign({}, state, { minGuests: action.value })
    case 'setSelectedDates':
      return Object.assign({}, state, { arrival: action.startDate, departure: action.endDate, enableClearDates: true })
    case 'clearDates':
      return Object.assign({}, state, { arrival: null, departure: null })
    case 'resetFilter':
      return Object.assign({}, state, action.value )
    case 'onGooglePlaceCloseIconClicked':
      return Object.assign({}, state, { locationFilled: false })
    default:
      throw new Error();
  }
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  clearDatesButton: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  disabled: {
    color: '#bdbdbd',
  },
}))

const AiSearchCriteriaDialog: React.FC<AiSearchCriteriaDialogProps> = ({
  defaultArrival,
  defaultLocationName,
  defaultDeparture,
  isDialogOpen,
  defaultLatitude,
  defaultLongitude,
  defaultMinGuests,
  onChange,
  onClose,
  aiSearchType,
}) => {
  const classes = useStyles();
  const { trackSERPEvent } = useTrackSERPEvent();

  const initialState = {
    arrival:  defaultArrival,
    departure: defaultDeparture,
    minGuests: defaultMinGuests,
    latitude: defaultLatitude,
    longitude: defaultLongitude,
    locationName: defaultLocationName,
    enableClearDates: defaultArrival && defaultDeparture,
    locationFilled: true,
  };

  useEffect(() => {
    dispatch({ type: 'initialState', initialState })
  }, [defaultLocationName]);

  const [{
    arrival, departure, minGuests, latitude, longitude, locationName, enableClearDates, locationFilled
  }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const handleGPChange = (value) => {
    if(value?.name !== locationName && value) {
      const { name, longitude, latitude } = value
      dispatch({type: 'updateGoogleLocation', name, longitude, latitude })
    }
  };

  const handleGuestChange = (value) => {
    dispatch({type: 'setMinGuests', value: value})
  };

  const handleDatesChange = (dateRange) => {
    const startDate = dateRange[0];
    const endDate = dateRange[1];

    if (startDate && endDate && endDate.isAfter(startDate, 'day')) {
      dispatch({
        type: 'setSelectedDates',
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      })
    }
  }
  const enableSubmit: () => boolean = () => {
    return ((arrival !== defaultArrival || minGuests !== defaultMinGuests ||
      departure !== defaultDeparture) && locationFilled) || (locationFilled && (locationName !== defaultLocationName))
  };

  const onSubmit = () => {
    if(enableSubmit() && locationName !== undefined) {
      onChange({
        arrival: arrival || '',
        departure: departure || '',
        minGuests: minGuests,
        latitude: latitude,
        longitude: longitude,
        locationName: locationName,
        searchMethod: 'searchCriteria',
      });
    }

    trackSERPEvent('Search Criteria Submitted', {
      location: aiSearchType,
      destinationChanged: locationName !== defaultLocationName,
      guestCountChanged: minGuests !== defaultMinGuests,
      datesChanged: arrival !== defaultArrival || departure !== defaultDeparture,
    });

    onClose()
  }

  const resetFilter = () => {
    dispatch({ type: 'resetFilter', value: initialState })
  };

  const onClearDatesClick = () => {
    dispatch({ type: 'clearDates' })
  }

  const renderMessage = () => {
    return (<>
      <Grid container flexDirection='column' spacing={2}>
        <Grid item>
          <Typography variant="h2" pb={1}>
            Location*
          </Typography>
          <GooglePlacesAutocomplete
            formattedAddress
            id={'ai_search_stay_location'}
            defaultValue={locationName}
            fullWidth
            onSelect={(value) => handleGPChange(value)}
            required={true}
            placeholder={"Search by name or address"}
            onCloseIconClicked={() => dispatch({ type: 'onGooglePlaceCloseIconClicked' })}
          />
        </Grid>
        <Grid item>
          <Grid container direction='row' justifyContent='space-between' align-items='stretch'>
            <Grid item>
              <Typography variant="h2" pb={1}>
                Dates
              </Typography>
            </Grid>
            <Grid item>
              <Link
                variant="h3"
                component="button"
                className={clsx(classes.clearDatesButton, { [classes.disabled]: !enableClearDates })}
                data-test-id="clearDatesButton"
                onClick={onClearDatesClick}
                underline="none"
              >
                Clear dates
              </Link>
            </Grid>
          </Grid>
          <DateRangePicker
            helperText={false}
            disabled={false}
            autoComplete="off"
            startValue={arrival}
            endValue={departure}
            onChange={(dateRange) => handleDatesChange(dateRange)}
            useMobilePicker
          />
        </Grid>
        <Grid item>
          <Typography variant="h2" pb={1}>
            Adults
          </Typography>
          <StepperInput
            defaultValue={Number(minGuests)}
            minValue={1}
            maxValue={20}
            onChange={(value) => handleGuestChange(value)}
          />
        </Grid>
        <Grid item alignSelf='flex-start'>
          <Button
            data-test-id='search-criteria-submit-button'
            onClick={onSubmit}
            disabled={!enableSubmit()}
          >
            <img src="/assets/stars.svg" className={classes.icon} />
            Update search
          </Button>
        </Grid>
      </Grid>
    </>);
  };

  const onDialogClose = () => {
    trackSERPEvent('Search Criteria Drawer Dismissed', {location: aiSearchType});
    resetFilter()
    onClose()
  };

  return (
    <>
      <DialogBottomOnMobile
        title='Search criteria'
        fullWidth
        maxWidth="xs"
        message={renderMessage()}
        isOpen={isDialogOpen}
        onClose={onDialogClose}
      />
    </>
  )
}

export default AiSearchCriteriaDialog
