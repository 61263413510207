import React, { useContext, useState, useReducer } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from '@mui/styles';
import Button from '../../buttons/Button';
import Dialog from "../../shared/Dialog";
import { GlobalContext, TripSiteContext, SegmentContext } from "../../Document";
import PendingInvite, { PendingInviteData } from "./IndexPage/PendingInvite";
import AcceptedInvite, { AcceptedInviteData } from "./IndexPage/AcceptedInvite";
import DeclinedInvite, { DeclinedInviteData } from "./IndexPage/DeclinedInvite";
import NoSuggestionsYet from "../../shared/NoSuggestionsYet";
import EmailInviteModal from "../../shared/EmailInviteModal";
import { track as trackAnalyticsEvent } from "../../../services/analytics";

type IndexPageProps = {
  acceptedInvites: AcceptedInviteData[],
  declinedInvites: DeclinedInviteData[],
  pendingInvites: PendingInviteData[],
  tripId: string,
};

type State = {
  resendModalOpened: boolean,
  isResendAllButtonDisabled: boolean,
};

type Action =
  | { type: 'INITIAL' }
  | {
      type: 'OPEN_MODAL',
    }
  | {
      type: 'RESEND_ALL_COMPLETED',
    }
  | { type: 'CLOSE_MODAL' }
;


const useStyles = makeStyles(theme => ({
  subtitle: {
    paddingTop: theme.spacing(1),
  },
  inviteSubText: {
    paddingBottom: theme.spacing(1),
  },
  inviteStatusSection: {
    paddingTop: theme.spacing(3),
  },
  invitesWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  outerContainer: {
    flexWrap: 'nowrap',
  },
  titleWrapper: {
    textAlign: 'center',
    flexDirection: 'row',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      minWidth: 480,
    },
  },
}));

const initialState: State = {
  resendModalOpened: false,
  isResendAllButtonDisabled: false,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'INITIAL':
      return initialState;
    case 'OPEN_MODAL':
      return { ...state, resendModalOpened: true };
    case 'RESEND_ALL_COMPLETED':
      return { resendModalOpened: false, isResendAllButtonDisabled: true };
    case 'CLOSE_MODAL':
      return { ...state, resendModalOpened: false };
    default:
      throw new Error(`Unknown action type: ${JSON.stringify(action)}`);
  };
};

const IndexPage = ({
  acceptedInvites,
  declinedInvites,
  pendingInvites,
  tripId,
}: IndexPageProps) => {
  const classes = useStyles();
  const { tripGid } = useContext(TripSiteContext)
  const { currentUserId, isPlanner } = useContext(SegmentContext);
  const [pendingInvitesList, setPendingInvitesList] = useState(pendingInvites);
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMobileApp = useMediaQuery(useTheme().breakpoints.down('sm'));
  const segmentProps = {
    userId: currentUserId,
    tripId: tripId,
    planner: isPlanner,
    isMobileApp: isMobileApp,
  };

  const pendingEmailInvites = pendingInvitesList.map(pendingInvite => pendingInvite.email)

  const openResendInvitesModal = () => {
    dispatch({ type: 'OPEN_MODAL' });
    trackAnalyticsEvent('Resend All Initiated', segmentProps);
  };

  const resendInviteCompleted = () => {
    dispatch({ type: 'RESEND_ALL_COMPLETED' });
  };

  return(
    <>
      <Grid
        className={classes.outerContainer}
        container
        data-test-id='manage-invites-tab'
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography>
            Here’s who has been invited via email.
          </Typography>
        </Grid>
        <Grid
          container
          item
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item>
            <Typography variant='h3'>
              Pending ({pendingInvitesList.length})
            </Typography>
          </Grid>
          <Grid item>
            <Button
              data-test-id='resend-all-button'
              onClick={openResendInvitesModal}
              disabled={state.isResendAllButtonDisabled || pendingEmailInvites.length == 0}
            >
              Resend all
            </Button>
          </Grid>
          {pendingInvitesList.length === 0 ? (
            <Grid container item>
              <NoSuggestionsYet
                subheading="There are no pending email invites."
                illustration='paper_airplane'
              />
            </Grid>
          ) : (
            <Grid className={classes.invitesWrapper} container direction="column" spacing={2}>
              {pendingInvitesList.map((invite) => (
                <Grid item key={invite.id}>
                  <PendingInvite
                    invite={invite}
                    onInviteDelete={setPendingInvitesList}
                    tripId={tripId}
                    disableResendButton={state.isResendAllButtonDisabled}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid className={classes.inviteStatusSection} item>
          <Typography variant='h3'>
            Accepted ({acceptedInvites.length})
          </Typography>
          {acceptedInvites.length === 0 ? (
            <NoSuggestionsYet
              subheading="There are no accepted email invites."
              illustration='mailbox_accepted'
            />
          ) : (
            <Grid className={classes.invitesWrapper} container direction="column" spacing={2}>
              {acceptedInvites.map((invite) => (
                <Grid item key={invite.id}>
                  <AcceptedInvite invite={invite} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid className={classes.inviteStatusSection} item>
          <Typography variant='h3'>
            Declined ({declinedInvites.length})
          </Typography>
          {declinedInvites.length === 0 ? (
            <NoSuggestionsYet
              subheading="There are no declined email invites."
              illustration='mailbox_declined'
            />
          ) : (
            <Grid className={classes.invitesWrapper} container direction="column" spacing={2}>
              {declinedInvites.map((invite) => (
                <Grid item key={invite.id}>
                  <DeclinedInvite invite={invite} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      {state.resendModalOpened &&
        <EmailInviteModal
          title='Resend invites'
          tripId={tripId}
          resendEmail={pendingEmailInvites}
          onResend={resendInviteCompleted}
          onModalClose={() => dispatch({ type: 'CLOSE_MODAL' })}
        />
      }
    </>
  );
};

export default IndexPage;
