import React from "react";
import { makeStyles, DefaultTheme } from "@mui/styles";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import useSegment from "../../../../../services/useSegment";

type Status = 'suggesting' | 'voting' | 'finalized' | 'skipped_voting_finalized';
type Vertical = 'Activities' | 'Dates' | 'Destinations' | 'Stays';

type VerticalStatusCardProps = {
  finalizedDetails?: string,
  vertical: Vertical,
  status: Status,
  path: string,
};

type StatusProps = {
  backgroundColor: string,
  chipColor: string,
};

type chipColorProps = (status: Status) => StatusProps

const suggestingLabelByVertical = {
  Activities: 'Suggest and vote',
  Stays: 'Search and add stays',
  Dates: 'Add suggestions',
  Destinations: 'Add suggestions',
};

const chipLabel = (vertical: Vertical) => ({
  suggesting: suggestingLabelByVertical[vertical],
  voting: 'Vote on options',
  finalized: 'Finalized',
  skipped_voting_finalized: 'Finalized',
});

const chipColor : chipColorProps = (status) => {
  const isFinalized = status === 'finalized' || status === 'skipped_voting_finalized';

  return {
    backgroundColor: isFinalized ? '#E9F9F4' : '#F4F5FF',
    chipColor: isFinalized ? '#1EBE95' : '#545DFC',
  }
};

const subtitleCopy = {
  Activities: 'Make your trip memorable with activities.',
  Dates: 'Pick a date that works for the group.',
  Destinations: 'Select the best destination for your trip.',
  Stays: 'Find the perfect place to stay.',
};

const verticalImages = (isFinalized) => {
  const assetPath = isFinalized ? '' : 'non-';

  return {
    Activities: `/assets/activities.svg`,
    Dates: `/assets/dates-${assetPath}finalized.svg`,
    Destinations: `/assets/destinations-${assetPath}finalized.svg`,
    Stays: `/assets/stays-${assetPath}finalized.svg`,
  }
};

const useStyles = makeStyles<DefaultTheme, { status: Status }>((theme) => ({
  root: {
    border: '1px solid #BEC9D4',
    borderRadius: '8px',
    backgroundColor: ({ status }) => chipColor(status).backgroundColor,
    cursor: 'pointer',
    width: '100%',
    maxWidth: '500px',
    padding: theme.spacing(2),
  },
  vertical: {
    color: '#272727',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  chip: {
    backgroundColor: ({ status }) => chipColor(status).chipColor,
    color: '#FFFFFF',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
    width: 'fit-content',
  },
  finalizedDetails: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const VerticalStatusCard = ({
  finalizedDetails,
  vertical,
  status,
  path,
}: VerticalStatusCardProps) => {
  const classes = useStyles({ status });
  const { trackSegment } = useSegment();
  const isFinalized = status === 'finalized' || status === 'skipped_voting_finalized';

  const onClick = () => {
    trackSegment('Trip Status Card Clicked', { verticalCardClicked: vertical, verticalStatus: status });
    window.location.href = path;
  };

  return (
    <Grid container direction="column" className={classes.root} onClick={onClick}>
      <Grid item container justifyContent="space-between" alignItems="flex-start">
        <Stack spacing={1}>
          <Typography className={classes.vertical}>{vertical}</Typography>
          <Chip
            className={classes.chip}
            label={chipLabel(vertical)[status]}
          />
        </Stack>
        <img src={verticalImages(isFinalized)[vertical]} data-test-id="vertical-image" />
      </Grid>
      {isFinalized && finalizedDetails && (
        <Grid item container pt={1}>
          <Typography className={classes.finalizedDetails}>
            {finalizedDetails}
          </Typography>
        </Grid>
      )}
      {!isFinalized && (
        <Grid item container pt={1}>
          <Typography className={classes.finalizedDetails}>
            {subtitleCopy[vertical]}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default VerticalStatusCard;
