import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Avatar from "../../../avatars/Avatar";

export type DeclinedInviteData = {
  email: string,
  id: string,
  inviterName: string,
  respondedAt: string,
};

type DeclinedInviteProps = {
  invite: DeclinedInviteData,
};

const useStyles = makeStyles((theme) => ({
  respondedAtText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  info: {
    paddingTop: `0px !important`,
  },
}));

const DeclinedInvite = ({ invite }: DeclinedInviteProps) => {
  const classes = useStyles();

  return (
    <Grid container data-test-id="declinedInvite" spacing={1}>
      <Grid item>
        <Avatar />
      </Grid>
      <Grid item className={classes.info}>
        <Typography>
          {invite.email}
        </Typography>
        <Typography className={classes.respondedAtText}>
          Declined {invite.respondedAt}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DeclinedInvite;
