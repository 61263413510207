import React from "react";
import Button from "../../../../buttons/Button";;
import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import Tooltip from "../../../../shared/Tooltip";
import pluralize from 'pluralize';
import BackToSuggestingButton from "./BackToSuggestingButton";
import { TypeTripObjectType } from "../../../../shared/TripObjectTypes";

type VotingTopSideBarContentProps = {
  className?: string,
  disabledActions: boolean,
  finalizePath: string,
  isCurrentUserPlanner?: boolean,
  transitionToSuggestingPath?: string,
  type: 'dates' | 'destination' | 'stay',
};

const useStyles = makeStyles((theme) => ({
  backToSuggesting: {
    display: 'grid',
    marginTop: theme.spacing(2),
  },
  finalizeButton: {
    textAlign: 'center',
  },
  header: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '32px',
    marginBottom: theme.spacing(1),
  },
  root: {
    [theme.breakpoints.up('md')]: {
      width: 360,
    },
  },
  subheader: {
    marginBottom: theme.spacing(3),
  },
  withMarginLeft:{
    marginLeft: theme.spacing(1.5),
  },
}));

const VotingTopSideBarContent = ({
  className,
  disabledActions,
  finalizePath,
  isCurrentUserPlanner,
  transitionToSuggestingPath,
  type,
}: VotingTopSideBarContentProps) => {
  const classes = useStyles();
  const pluralizedType = pluralize(type, 2) as Exclude<TypeTripObjectType, "activities" | "polls">;

  const tooltipTitle = () => {
    if(type === 'dates' && disabledActions) return 'Votes cannot be changed for past dates.'
    if(isCurrentUserPlanner) return ''

    return `Only planners can finalize ${pluralizedType}.`
  };

  return <>
    <Grid container className={clsx(classes.root, className)} direction="column">
      <Grid item>
        <Typography className={classes.header}>
          Finalize {type}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.subheader}>
          When voting is complete, finalize your {type} to notify the group.
        </Typography>
      </Grid>
      <Grid container direction="column" justifyContent="center">
        <Grid item>
          <Tooltip title={tooltipTitle()}>
            <Grid>
              <Button
                className={classes.finalizeButton}
                data-test-id="finalize-button"
                disabled={disabledActions || !isCurrentUserPlanner}
                fullWidth
                href={finalizePath}
              >
                Finalize {type}
              </Button>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
      {isCurrentUserPlanner &&
        <Grid container justifyContent='center' item className={classes.backToSuggesting}>
          <BackToSuggestingButton
            transitionToSuggestingPath={transitionToSuggestingPath}
            tripObjectType={pluralizedType}
          />
        </Grid>
      }
    </Grid>
  </>
};

export default VotingTopSideBarContent;
