import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import { Divider, Theme, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { HEADER_BAR_HEIGHT } from "../shared/SiteHeader";

export const NAV_BAR_HEIGHT = 58;

type StyleProps = {
  stickyTop?: number,
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    height: NAV_BAR_HEIGHT,
    backgroundColor: '#ffffff',
  },
  grid: {
    overflowX: 'auto',
    flexWrap: 'nowrap',
  },
  navItem: {
    position: 'relative',
  },
  navButton: {
    color: '#555e64',
    display: 'block',
    height: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
  },
  navButtonCurrent: {
    color: '#243060',
    '&::after': {
      content: "' '",
      display: 'block',
      background: theme.palette.primary.main,
      borderRadius: 2,
      height: 6,
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
  },
  navButtonDisabled: {
    cursor: 'not-allowed',
    color: '#c0c0c0',
  },
  sticky: {
    position: 'sticky',
    top: ({ stickyTop }) => (
      `${stickyTop}px`
    ),
    '@supports (top: env(safe-area-inset-top))': {
      top: ({ stickyTop }) => `calc(env(safe-area-inset-top) + ${stickyTop}px)`,
    },
    zIndex: '4',
  },
}))

type ItemProps = {
  activeItemRef?: React.MutableRefObject<HTMLDivElement>,
  current: boolean,
  disabled: boolean,
  path: string,
}

const Item: React.FC<ItemProps> = ({
  activeItemRef,
  children,
  current,
  disabled,
  path,
}) => {
  const classes = useStyles({});
  return (
    <Grid item className={classes.navItem} ref={activeItemRef}>
      <ButtonBase
        className={clsx(classes.navButton,
          { [classes.navButtonCurrent]: current,
            [classes.navButtonDisabled]: !current && disabled })}
        data-test-id={current ? "activeNavItem" : `navItem-${children}`}
        href={path}
        disabled={disabled}
      >
        {children}
      </ButtonBase>
    </Grid>
  );
}

export type NavItem = {
  title: string,
  id: string,
  path: string,
  active: boolean,
}

type NavBarProps = {
  disabled?: boolean,
  items: NavItem[],
}

const NavigationBar = ({ disabled, items }: NavBarProps) => {
  const stickyTop = HEADER_BAR_HEIGHT

  const classes = useStyles({ stickyTop: stickyTop });
  const activeItemRef = useRef(null);
  const navBarRef = useRef(null);
  // Horizontal scrolling to active item
  useEffect(() => {
    if(activeItemRef.current && typeof activeItemRef.current.scrollIntoView === 'function') {
      activeItemRef.current.scrollIntoView({ block: 'nearest', inline: 'center' });
    }
  });

  const contentPosition = useMediaQuery(useTheme().breakpoints.down('md')) ? 'left' : 'center';

  return (
    <Box
      className={clsx(classes.root, classes.sticky)}
    >
      <Divider/>
      <Grid
        container
        justifyContent={contentPosition}
        className={clsx(classes.grid, classes.sticky)}
        ref={navBarRef}
        data-test-id='sub-nav-bar'
      >
        {items.map(({ active, path, title }) => (
          <Item
            activeItemRef={active ? activeItemRef : undefined}
            key={path}
            current={active}
            path={path}
            disabled={!!disabled}
          >
            {title}
          </Item>
        ))}
      </Grid>
      <Divider/>
    </Box>
  )
};

export default NavigationBar;
