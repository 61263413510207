import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Box,
  ButtonBase,
} from "@mui/material";
import { ListingsContext } from "../../StaysSearch";

type ExamplePromptProps = {
  backgroundColor: string,
  borderColor: string,
  prompt: string;
  image: string,
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '0px 30px 30px 30px',
    borderStyle: 'solid',
    borderWidth: '1px',
    color: '#4A6176',
    fontSize: 16,
    fontWeight: 400,
    height: '100%',
    lineHeight: '22px',
    padding: theme.spacing(2,3),
    textAlign: 'left',
  },
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    height: '100%',
  },
  arrow: {
    marginLeft: theme.spacing(1),
  },
}));

const ExamplePrompt = ({
  backgroundColor,
  borderColor,
  prompt,
  image,
}: ExamplePromptProps) => {
  const classes = useStyles();
  const { onPromptExampleClick } = React.useContext(ListingsContext);

  return (
    <Box className={classes.root}>
      <img src={image} />
      <ButtonBase
        disableRipple
        className={classes.button}
        onClick={() => onPromptExampleClick(prompt)}
        sx={{ backgroundColor, borderColor }}
      >
        “{prompt}”
        <img src="/assets/example-prompts/arrow.svg" className={classes.arrow} />
      </ButtonBase>
    </Box>
  );
};

export default ExamplePrompt;
