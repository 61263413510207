import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Stack, Typography } from "@mui/material";
import { DateRange } from "@mui/icons-material";
import ItineraryEventDialog, { renderButtons } from "./ItineraryEventDialog";
import { ItineraryEventType } from "../../../../shared/TripObjectTypes";
import RichText from "../../../../shared/RichText";
import Button from "../../../../buttons/Button";
import moment from "moment/moment";
import { editTripStayEventPath } from "../../../../../routes";
import useSegment from "../../../../../services/useSegment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Divider from "../../../../shared/Divider";
import Avatar from "../../../../avatars/Avatar";
import { StayBookingDetailsProps } from "../../../comments/IndexPage/StayBookingDetails";
import BookButton from "../HousingPage/Stay/BookButton";

type ItineraryStayEventDialogProps = {
  itineraryDateId?: string,
  itineraryEvent: ItineraryEventType,
};

const useStyles = makeStyles(theme => ({
  date: {
    fontSize: theme.spacing(2),
    display: 'flex',
    paddingRight: theme.spacing(2),
    color: '#4A6176',
  },
  dateIcon: {
    fontSize: '1.2rem',
    top: '2px',
    position: 'relative',
    marginRight: theme.spacing(1),
  },
  dateTimeBox: {
    color: '#4A6176',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  greenCheck: {
    top: '4px',
    position: 'relative',
    marginRight: '8px',
    color: '#1EBE95',
  },
  boldText: {
    fontWeight: 600,
    fontSize: '16px',
    margin: "auto 0",
  },
  refundable: {
    fontWeight: 400,
    fontSize: '16px',
    margin: "auto 0",
    color: '#69737A',
  },
  checkinText: {
    fontWeight: 400,
    margin: "auto 0",
  },
}));

const BookingDetails = ({ booking } : StayBookingDetailsProps) => {
  const classes = useStyles();
  const formatDate = (date) => moment(date, "YYYY-MM-DD").format("ddd, MMM D");
  const formatTime = (time) => moment(time, "HH:mm:ss").format("h:mm A");
  const formatDateTime = (date, time = "") => {
    if (date && time) {
      return `${formatDate(date)}, ${formatTime(time)}`
    } else {
      return formatDate(date)
    }
  };
  const checkIn = formatDateTime(booking.checkInDate, booking.checkInTime);
  const checkOut = formatDateTime(booking.checkOutDate, booking.checkOutTime);
  const refundability = {
    'non_refundable': 'Non-refundable',
    'refundable': `Refundable until ${moment((booking?.refundableUntil), "YYYY-MM-DD").format("MMM Do")}`,
  }

  return (
    <Stack >
      <Divider/>
      <Typography className={classes.title} pt={1} pb={2}>
        <CheckCircleIcon className={classes.greenCheck} />{'Booking details'}
      </Typography>
      <Typography className={classes.boldText}>{'Check-in: '} <span className={classes.checkinText}>{checkIn}</span></Typography>
      <Typography className={classes.boldText}>{'Check-out: '} <span className={classes.checkinText}>{checkOut}</span></Typography>
      <Grid item pt={1} direction="row">
        <Typography className={classes.refundable}>{refundability[booking.refundability]}</Typography>
      </Grid>
      <Grid item container xs={12} pt={2}>
        <Grid item pr={1} >
          <Avatar src={booking.author.avatarUrl} size='xs' />
        </Grid>
        <Typography className={classes.checkinText}>
          Booked by <span style={{fontWeight: 600}}>{booking.author.displayName}</span>
        </Typography>
      </Grid>
    </Stack>
  );
};

const ItineraryStayEventDialog = ({
  itineraryDateId,
  itineraryEvent,
}: ItineraryStayEventDialogProps) => {
  const classes = useStyles();
  const formattedStartDate = moment(itineraryEvent.startDate, "YYYY-MM-DD").format("ddd, MMM Do");
  const formattedEndDate = moment(itineraryEvent.endDate, "YYYY-MM-DD").format('ddd, MMM Do');
  const displayBookButton = itineraryEvent.bookable;
  const { trackSegment } = useSegment();

  const onAddNotesClick = () => {
    trackSegment('Add Note Clicked', {objectId: itineraryEvent.id});
    window.location.href = `${editTripStayEventPath(itineraryEvent.tripId, itineraryEvent.id)}?itinerary_date_id=${itineraryDateId}`;
  };

  return (
    <ItineraryEventDialog
      itineraryDateId={itineraryDateId}
      itineraryEvent={itineraryEvent}
    >
      <Grid container className={classes.dateTimeBox} pt={1}>
        <Grid item className={classes.date} data-test-id="itinerary-event-date">
          {<DateRange className={classes.dateIcon}/>} {formattedStartDate} - {formattedEndDate}
        </Grid>
      </Grid>

      {renderButtons(itineraryEvent)}
      {displayBookButton && <BookButton fullWidth url={itineraryEvent.url} page='itinerary' provider={itineraryEvent.provider} />}
      {itineraryEvent.stayBookings?.length > 0 &&
        <BookingDetails
          stayId={itineraryEvent.schedulableId}
          tripId={itineraryEvent.tripId}
          booking={itineraryEvent.stayBookings[0]}
        />
      }
      <Grid container pt={2}>
        <Grid item sx={{width: '100%'}}>
          {itineraryEvent.notes ? (
            <RichText value={itineraryEvent.notes} />
          ) : (
            <Button
              aria-label='add-notes-button'
              disabled={false}
              data-test-id='add-notes-button'
              fullWidth
              onClick={() => onAddNotesClick()}
              variant={'secondary'}
            >
              Add Note
            </Button>
          )}
        </Grid>
      </Grid>
    </ItineraryEventDialog>
  );
};

export default ItineraryStayEventDialog;
