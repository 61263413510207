import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  TextField,
  useMediaQuery,
  Typography,
  Box,
} from "@mui/material";
import { DefaultTheme, makeStyles, useTheme } from "@mui/styles";
import Button from "../../../buttons/Button";

const useStyles = makeStyles<DefaultTheme, { disabled: boolean, aiTripSearch: boolean }>((theme) => ({
  aiTripSearchTitle: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '24px',
  },
  aiPublicSearchTitle: {
    color: '#4A6176',
    fontSize: 22,
    fontWeight: 600,
  },
  aiPublicSearchSubtitle: {
    color: '#4A6176',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '34px',
    [theme.breakpoints.down(600)]: {
      lineHeight: '28px',
    },
  },
  searchBox: {
    backgroundColor: ({ aiTripSearch }) => aiTripSearch ? '#F2F2F2' : "",
    borderRadius: 10,
    margin: 'auto',
    padding: theme.spacing(2, 3, 3, 2),
    width: '100%',
  },
  root: {
    marginTop: theme.spacing(3),
  },
  input: {
    backgroundColor: '#FFFFFF',
  },
  poweredByText: {
    fontSize: 12,
    lineHeight: '24px',
  },
  icon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  filtersBtn: {
    paddingLeft: theme.spacing(1),
  },
}));

const AiSearchPrompt: React.FC<{
  aiTripSearch: boolean,
  onSubmit: (prompt: string) => void,
  prompt: string,
  disabled?: boolean,
}> = ({
  aiTripSearch,
  onSubmit,
  prompt: initialPrompt,
  disabled = false,
}) => {
  const classes = useStyles({ disabled, aiTripSearch });
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const inputRef = useRef(null)
  const focusInput = () => inputRef.current?.querySelector('textarea')?.focus()
  useEffect(() => {
    if (!isMobile) focusInput()
  }, [])

  const [prompt, setPrompt] = useState<string>(initialPrompt ?? '');
  useEffect(() => {
    setPrompt(initialPrompt);
  }, [initialPrompt]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    onSubmit(prompt)
  }, [prompt, onSubmit])

  const handleEnterKey = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) handleSubmit(event)
  }

  const Title = () => {
    if (aiTripSearch) {
      return <Typography className={classes.aiTripSearchTitle}>Ask Troupie</Typography>
    } else {
      return (
        <>
          <Typography className={classes.aiPublicSearchTitle}>👋 Hi, I’m Troupie!</Typography>
          <Typography className={classes.aiPublicSearchSubtitle}>I’m here to help you find the perfect stay for your group trip.</Typography>
        </>
      )
    };
  };

  return (
    <Box>
      <Grid container direction="column" spacing={2} className={classes.searchBox} data-test-id="searchBox">
        <Grid item>
          <Title />
        </Grid>
        <Grid item>
          <TextField
            autoFocus
            className={classes.input}
            data-test-id="prompt-input"
            fullWidth
            multiline
            placeholder="Tell me what you’re looking for"
            type="search"
            onKeyDown={handleEnterKey}
            onChange={e => setPrompt(e.target.value)}
            value={prompt ?? ''}
            disabled={disabled}
            ref={inputRef}
            inputProps={{ maxLength: 500 }}
          />
        </Grid>
        <Grid item container spacing={2} justifyContent={isMobile ? "center" : "space-between"} alignItems="flex-end">
          <Grid item xs={12} sm={9} container>
            <Grid item>
              <Button
                data-test-id='searchButton'
                fullWidth={isMobile}
                onClick={(prompt) => handleSubmit(prompt)}
                disabled={disabled}
              >
                <img src="/assets/stars.svg" className={classes.icon} />
                Show me {!isMobile && 'results'}
              </Button>
            </Grid >
          </Grid>
          <Grid item xs={12} sm={3} alignSelf="flex-start">
            <Typography align={isMobile ? "left" : "right"} className={classes.poweredByText}>Powered by ChatGPT API</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AiSearchPrompt;
