import React from "react"
import { ButtonBase, Chip, Typography, Grid } from "@mui/material";
import { makeStyles, DefaultTheme } from "@mui/styles";
import { useAdClickedEvent } from "../../services/segmentEvents/useAdsEvents";

type AdImages = {
  regular: string,
  small: string,
};

export type AdContent = {
  color: string,
  ctaCopy: string,
  images: AdImages,
  label: string,
  title: string,
  url: string,
};

type ActivityAdProps = {
  content: AdContent,
  small?: boolean,
  vertical: string,
};

const useStyles = makeStyles<DefaultTheme, { image: string, small: boolean }>((theme) => ({
  root: {
    background: ({ image }) => `url("${image}") no-repeat center/cover`,
    width: ({ small }) => small ? '100%' : '345px',
    height: ({ small }) => small ? 'auto' : '177px',
    position: 'relative',
    marginBottom: '12px',
    paddingLeft: ({ small }) => small ? '4px' : '8px',
  },
  ctaLink: {
    fontWeight: 600,
    fontSize: ({ small }) => small ? 12 : 14,
    color: '#FFFFFF',
    position: 'absolute',
    top: ({ small }) => small ? theme.spacing(1) : theme.spacing(2),
    right: ({ small }) => small ? theme.spacing(1) : theme.spacing(2),
  },
  chip: {
    color: '#FFFFFF',
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 600,
    height: '21px',
    lineHeight: '30px',
    '& .MuiChip-label': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  title: {
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: ({ small }) => small ? 18 : 20,
    lineHeight: '28px',
    textShadow: '0px 2px 13px rgba(0, 0, 0, 0.4)',
  },
}));

const ActivityAd = ({
  content,
  small = false,
  vertical,
}: ActivityAdProps) => {
  const { color, ctaCopy, label, title, url, images } = content;
  const image = small ? images.small : images.regular;
  const classes = useStyles({ image, small });
  const trackAdClicked = useAdClickedEvent(vertical, label, title);

  return (
    <ButtonBase
      className={classes.root}
      data-test-id="activity-ad"
      href={url}
      ref={trackAdClicked}
      target="_blank"
    >
      <Typography className={classes.ctaLink}>{ctaCopy}</Typography>
      <Grid container direction="column" p={1} justifyContent="flex-end" sx={{ height: '100%' }}>
        <Grid item>
          <Chip className={classes.chip} label={label} sx={{ backgroundColor: color }} />
        </Grid>
        <Grid item>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default ActivityAd;
