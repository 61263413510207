import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import FiltersButton from "./FiltersButton";
import SearchFilterChips from "./SearchFilterChips";
import AiSearchPaginationText from "./AiSearchPaginationText";
import { Pagination as PaginationType } from "./ListingsBox"
import {aiSearchType, CurrentAppliedFilters} from "./Filters";

type AiFiltersProps = {
  currentAppliedFilters: CurrentAppliedFilters,
  onHandleAiSearchFilterChange: (value: object) => void,
  onFilter: (value: object) => void,
  triggerFilterDialog: (value: boolean) => void,
  aiSearchType?: aiSearchType,
  pagination: PaginationType,
};

const useStyles = makeStyles(() => ({
  searchFilterChips: {
    overflowX: 'auto',
  },
  filtersContainer: {
    flexWrap: 'nowrap',
  },
}));

const AiFilters : React.FC<AiFiltersProps> = ({
  currentAppliedFilters,
  onHandleAiSearchFilterChange,
  onFilter,
  triggerFilterDialog,
  aiSearchType,
  pagination,
}) => {
  const classes = useStyles();
  const {
    minGuests,
    locationName,
    arrival,
    departure,
  } = currentAppliedFilters;
  return(
    <>
      <AiSearchPaginationText
        minGuests={minGuests}
        pagination={pagination}
        triggerFilterDialog={triggerFilterDialog}
        locationName={locationName}
        arrival={arrival}
        departure={departure}
        aiSearchType={aiSearchType}
      />
      <Grid container className={classes.filtersContainer}>
        <Grid item>
          <FiltersButton
            aiSearch
            filters={currentAppliedFilters}
            onChange={onHandleAiSearchFilterChange}
          />
        </Grid>
        <Grid item className={classes.searchFilterChips}>
          <SearchFilterChips
            appliedFilters={currentAppliedFilters}
            onClick={onFilter}
          />
        </Grid>
      </Grid>
    </>
  )
};

export default AiFilters;
