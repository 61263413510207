import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Avatar from "../../avatars/Avatar";

type AvatarGridItemProps = {
  avatarUrl: string,
  byCurrentUser?: boolean,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(5),
    position: "relative",
    paddingRight: theme.spacing(1),
  },
  currentUser: {
    paddingRight: 0,
    paddingLeft: theme.spacing(1),
  },
  avatar: {
    position: 'absolute',
    bottom: 0,
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}));

const AvatarGridItem = ({ avatarUrl, byCurrentUser }: AvatarGridItemProps) => {
  const classes = useStyles();

  return (
    <Grid
      item
      className={clsx(
        classes.root,
        { [classes.currentUser]: byCurrentUser },
      )}
    >
      <Avatar src={avatarUrl} className={classes.avatar} />
    </Grid>
  );
};

export default AvatarGridItem;
