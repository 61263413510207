import { gql } from '@apollo/client'

export const STAY_BOOKING_DELETE = gql`
  mutation StayBookingDelete($stayBookingId: ID!) {
    stayBookingDelete(input: {
      stayBookingId: $stayBookingId,
    }) {
      errors
    }
  }
`
