import React from "react";
import Switch, { SwitchClassKey, SwitchProps } from '@mui/material/Switch';
import { makeStyles } from "@mui/styles";

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    width: 52,
    height: 24,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(28px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 24 / 2,
    border: `1px solid ${theme.palette.general.gray}`,
    backgroundColor: theme.palette.general.gray,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));

const StyledSwitch = ({ ...props }: SwitchProps) => {
  const classes = useStyles();

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export default StyledSwitch;
