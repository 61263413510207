import React from "react";
import {
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import ConfirmationDialog from "./ConfirmationDialog";

type LeaveConfirmationDialogProps = {
  path: string,
  fullWidthButtons: boolean,
  isOpen: boolean,
  onClose: () => void,
};

const useStyles = makeStyles((theme) =>({
  root: {
    color: theme.palette.text.primary,
  },
  bold: {
    fontWeight: 500,
    display: 'inline',
  },
}));

const LeaveConfirmationDialog = ({
  path,
  ...confirmationDialogProps
}: LeaveConfirmationDialogProps) => {
  const classes = useStyles();
  return (
    <ConfirmationDialog
      actionPath={path}
      actionMethod="DELETE"
      actionText="Leave"
      message={
        <Typography className={classes.root}>
          You’ll still be able to rejoin this trip if you change your mind.
        </Typography>
      }
      title={`Leave this trip?`}
      {...confirmationDialogProps}
    />
  );
};

export default LeaveConfirmationDialog;
