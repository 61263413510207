import React from "react";
import { hocRailsAction } from "../../../Document";
import { Typography } from "@mui/material";
import Button, { ButtonType } from "../../../buttons/Button";
import ModalForm from "../../../shared/ModalForm";
import SecondStepTripForm, { UpperImage as SecondStepUpperImage } from "./SecondStepTripForm";
import ThirdStepTripForm, {UpperImage as ThirdStepUpperImage} from "./ThirdStepTripForm";
import Errors from "../../../shared/Errors";
import makeStyles from "@mui/styles/makeStyles";
import { tripsPath } from "../../../../routes";
import { MARKETING_URL } from "../../../../constants/urls";
import { track } from "../../../../services/analytics";
import { DestinationLocationType } from '../../../../types/DestinationTypes';
import FirstStepTripForm, { UpperImage as FirstStepTripImage } from "./FirstStepTripForm";

type NewCreationFormProps = {
  errors: string[],
  currentUserId?: string,
};

type OptionalDataType = {
  start_date?: string,
  end_date?: string,
  location?: DestinationLocationType,
  disabledSubmit?: boolean,
  verticals: {
    dates: boolean,
    destinations: boolean,
    stays: boolean,
    nothing: boolean,
  },
  stay?: string,
}

const useStyles = makeStyles((theme) => ({
  errors: {
    paddingBottom: theme.spacing(2),
  },
  subTitle: {
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
  },
}));

const NewCreationForm = ({
  errors,
  currentUserId,
}: NewCreationFormProps) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = React.useState(1 as (1 | 2 | 3));
  const [optionalData, setOptionalData] = React.useState({
    disabledSubmit: true,
    verticals: {
      dates: false,
      destinations: false,
      nothing: false,
      stays: false,
  }} as OptionalDataType);

  type stepToScreenType = {
    Body: JSX.Element,
    title: string,
    subtitle: string,
    backButton: boolean,
    onFormSubmit: (e: any) => void,
    UpperImage: () => JSX.Element,
    buttonType: ButtonType,
    buttonLabel: string,
    page?: string,
  };

  const stepToScreen: {[key: number]: stepToScreenType} = {
    1: {
      Body: <FirstStepTripForm setOptionalData={setOptionalData} optionalData={optionalData} />,
      title: 'Let’s set up your trip!',
      subtitle: 'What have you decided?',
      backButton: false,
      onFormSubmit: (e) => {
        e.preventDefault();
        optionalData.verticals.nothing ? setCurrentStep(3) : setCurrentStep(2);
      },
      UpperImage: FirstStepTripImage,
      buttonType: 'button',
      buttonLabel: 'Continue',
    },
    2: {
      Body: <SecondStepTripForm setOptionalData={setOptionalData} optionalData={optionalData} />,
      title: 'A few more details...',
      subtitle: 'Fill in what’s been decided so Troupe can help you with the rest.',
      backButton: true,
      onFormSubmit: (e) => {
        e.preventDefault();
        stepSubmittedEvent();
        setCurrentStep(3)
      },
      UpperImage: SecondStepUpperImage,
      buttonType: 'button',
      buttonLabel: 'Next',
      page: 'decided input form',
    },
    3: {
      Body: <ThirdStepTripForm optionalData={optionalData} />,
      title: 'Finishing touches',
      subtitle: 'Let travelers know what your trip is all about.',
      backButton: true,
      onFormSubmit: () => {},
      UpperImage: ThirdStepUpperImage,
      buttonType: 'submit',
      buttonLabel: 'Submit',
      page: 'decided selection form',
    },
  };

  const closeOnCreatingSegmentEvent = () => {
    track('Trip Creation Form Dismissed', {
      userId: currentUserId ? currentUserId : 'anonymous',
    });
  };

  const stepSubmittedEvent = () => {
    track('Trip Creation Form Step Submitted', {
      currentUserId: currentUserId ? currentUserId : 'anonymous',
      buttonClicked: stepToScreen[currentStep].buttonLabel,
      page: stepToScreen[currentStep].page,
      decidedSelection: optionalData,
    });
  };

  const backButton = () => (
    <Button
      fullWidth
      onClick={() => setCurrentStep(prevStep => (
        (prevStep === 2 || optionalData.verticals.nothing) ? 1 : 2
      ))}
      variant="secondary"
      data-test-id="back-button"
    >
      Back
    </Button>
  );

  const renderDateHiddenInputs = () => (
    <div data-test-id='dates-input'>
      <input
        type="hidden"
        value={optionalData?.start_date}
        name={'trip[date_options_attributes][0][start_date]'}
      />
      <input
        type="hidden"
        value={optionalData?.end_date}
        name={'trip[date_options_attributes][0][end_date]'}
      />
    </div>
  );

  const renderDestinationHiddenInputs = () => (
    <div data-test-id='location-inputs'>
      <input
        type="hidden"
        value={optionalData?.location?.name || ""}
        name="trip[destinations_attributes][0][name]"
      />
      <input
        type="hidden"
        value={optionalData?.location?.google_place_id || ""}
        name="trip[destinations_attributes][0][google_place_id]"
      />
      <input
        type="hidden"
        value={optionalData?.location?.google_place_url || ""}
        name="trip[destinations_attributes][0][google_place_url]"
      />
      <input
        type="hidden"
        value={`POINT(${optionalData?.location?.longitude} ${optionalData?.location?.latitude})` || ""}
        name="trip[destinations_attributes][0][location]"
      />
      <input
        type="hidden"
        value={optionalData?.location?.image || ""}
        name="trip[destinations_attributes][0][original_image]"
      />
    </div>
  );

  const renderStayHiddenInputs = () => (
    <div data-test-id='stay-input'>
      <input
        type="hidden"
        value={optionalData?.stay || ""}
        name="trip[stay_url]"
      />
    </div>
  );

  return (
    <ModalForm
      closePath={currentUserId ? tripsPath() : MARKETING_URL}
      disabledSubmit={optionalData.disabledSubmit}
      extraSaveBarButton={stepToScreen[currentStep].backButton ? backButton() : null}
      formActionPath={tripsPath()}
      onClick={stepToScreen[currentStep].onFormSubmit}
      saveButtonLabel={stepToScreen[currentStep].buttonLabel}
      saveButtonType={stepToScreen[currentStep].buttonType}
      subtitle={
        <Typography className={classes.subTitle}>
          {stepToScreen[currentStep].subtitle}
        </Typography>
      }
      title={stepToScreen[currentStep].title}
      titleClassName={classes.title}
      useDefaultSaveButton
      onModalClose={closeOnCreatingSegmentEvent}
      upperImage={stepToScreen[currentStep].UpperImage()}
    >
      {errors.length > 0 && (
        <Errors errors={errors} className={classes.errors}/>
      )}
      {stepToScreen[currentStep].Body}
      { optionalData.verticals.dates && renderDateHiddenInputs()}
      { optionalData.verticals.destinations && renderDestinationHiddenInputs()}
      { optionalData.verticals.stays && renderStayHiddenInputs()}
    </ModalForm>
  );
};

export default hocRailsAction(NewCreationForm);
