import React from "react";
import clsx from "clsx";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";
import { makeStyles } from "@mui/styles";

export type HoverBorderIconButtonProps = ButtonBaseProps & {
  children: React.ReactNode,
  className?: string,
  onClick?: (event: any) => void,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    width: 32,
    height: 32,
    backgroundColor: "#FFFFFF",
    border: `1px solid ${theme.palette.general.background.disabled}`,
    borderRadius: '50%',
    "&:hover": {
      backgroundColor: '#F6F6F6',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const HoverBorderIconButton = ({
  children,
  className,
  ...buttonProps
}: HoverBorderIconButtonProps) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={clsx(classes.root, className)}
      {...buttonProps}
    >
      {children}
    </ButtonBase>
  );
};

export default HoverBorderIconButton;
