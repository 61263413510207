import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteResourceConfirmationDialog from "../../../../shared/DeleteResourceConfirmationDialog";
import Avatar from "../../../../avatars/Avatar";
import ReadMore from '../../../../shared/ReadMore';
import TimeStamp from '../../../../shared/TimeStamp';
import NoteForm from './ChosenTripObjectHeader/NoteForm';

type ChosenTripObjectNoteActionsProps = {
  objectPath: string,
};

type ChosenTripObjectHeaderProps = {
  avatarUrl: string,
  objectPath: string,
  hasChosenTripObject: boolean,
  isPlanner: boolean,
  note: string,
  chosenAt: number,
  chosenByName: string,
};

const useChosenTripObjectNoteActionsStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  deleteIcon: {
    marginRight: theme.spacing(1),
  },
}))

const ChosenTripObjectNoteActions = ({
  objectPath,
}: ChosenTripObjectNoteActionsProps) => {
  const classes = useChosenTripObjectNoteActionsStyles();
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleCloseMenu = () => setAnchorEl(null);
  const handleDeleteClick = () => { setDialogOpen(true); handleCloseMenu() };
  return (
    <div data-test-id="chosen-trip-object-actions">
      <Box className={classes.root}>
        <IconButton
          size="small"
          onClick={(event) => setAnchorEl(event.target)}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl as HTMLElement}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleDeleteClick}>
            <DeleteIcon className={classes.deleteIcon} />
            Delete note
          </MenuItem>
        </Menu>
        <DeleteResourceConfirmationDialog
          isOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
          path={objectPath}
          resourceName="note"
        />
      </Box>
    </div>
  );
}

const useChosenTripObjectHeaderStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  chosenByName: {
    fontSize: 14,
    fontWeight: 600,
  },
  chosenByTitle: {
    fontSize: 14,
    marginLeft: theme.spacing(0.5),
  },
  chosenAt: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  note: {
    marginBottom: theme.spacing(1),
  },
}));

const ChosenTripObjectHeader = ({
  avatarUrl,
  objectPath,
  hasChosenTripObject,
  isPlanner,
  note,
  chosenAt,
  chosenByName,
}: ChosenTripObjectHeaderProps) => {
  const classes = useChosenTripObjectHeaderStyles();
  return (
    <div data-test-id="chosen-trip-object-header">
      <Grid container spacing={1} alignItems="center" className={classes.root}>
        <Grid item>
          <Avatar src={avatarUrl} borderVariant="primary" />
        </Grid>
        <Grid item>
          <Typography>
            <span className={classes.chosenByName}>
              {chosenByName}
            </span>
            <span className={classes.chosenByTitle}>
              {!note && "chose the option"}
            </span>
          </Typography>
          <TimeStamp className={classes.chosenAt} date={chosenAt}/>
        </Grid>
        {note && (
          <>
            {hasChosenTripObject && <ChosenTripObjectNoteActions objectPath={objectPath} /> }
            <Grid item xs={12} mt={1}>
              <Typography className={classes.note}>
                <ReadMore
                  text={note}
                  lineHeight={1.4}
                  readMoreCharacterLimit={200}
                  showLessButton={true}
                />
              </Typography>
            </Grid>
          </>
        )}
        {!note && isPlanner && (
          <Grid item xs={12} mt={1}>
            <NoteForm objectPath={objectPath} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ChosenTripObjectHeader;
