import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FormContext } from "../../../shared/RailsForm";
import StepperInput from "../../../shared/StepperInput";
import { MaxPrice } from "../../../Document";

type DetailsFieldsProps = {
  shouldFocusPrice?: boolean,
  stay: {
    bedroomsCount: number,
    hasAutomaticAvailability: boolean,
    maxGuests: number,
    pricePerNight: string,
  },
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


const DetailsFields = ({ shouldFocusPrice, stay }: DetailsFieldsProps) => {
  const { onFormChange } = useContext(FormContext);
  const classes = useStyles();
  const [price, setPrice] = useState(stay.pricePerNight);

  const checkMaxPrice = (price) => {
    if(price < MaxPrice) {setPrice(price)}
  }

  const scrollToPrice = () => {
    document.getElementById('price-label')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  };

  useEffect(() => {
    if (shouldFocusPrice) {
      scrollToPrice();
    };
  }, []);

  const [
    maxGuests,
    setMaxGuests,
  ] = useState(stay.maxGuests || 1);

  const [
    bedroomsCount,
    setBedroomsCount,
  ] = useState(stay.bedroomsCount || 0);

  return (
    <Grid container spacing={2} direction="column">
      {!stay.hasAutomaticAvailability && (
        <Grid item>
          <Typography id="price-label" className={classes.label}>
            Cost
          </Typography>
          <TextField
            autoFocus={shouldFocusPrice}
            fullWidth
            label="Price per night (USD)"
            id="price_per_night"
            name="stay[price_per_night]"
            variant="outlined"
            defaultValue={stay.pricePerNight}
            data-test-id="price_per_night"
            type="number"
            value={price}
            onChange={event => checkMaxPrice(event.target.value)}
            inputProps={{
              min: 0,
              max: MaxPrice,
              step: 0.01,
              inputMode: "decimal",
            }}
          />
        </Grid>
      )}
      <Grid item data-test-id="bedroomsCountInput">
        <input
          name="stay[bedrooms_count]"
          type="hidden"
          value={bedroomsCount || ""}
        />
        <Typography className={classes.label}>Bedrooms</Typography>
        <StepperInput
          defaultValue={bedroomsCount}
          minValue={0}
          maxValue={20}
          onChange={setBedroomsCount}
        />
      </Grid>
      <Grid item data-test-id="maxGuestsInput">
        <input
          name="stay[max_guests]"
          type="hidden"
          value={maxGuests}
        />
        <Typography className={classes.label}>Max. guests</Typography>
        <StepperInput
          defaultValue={maxGuests}
          minValue={1}
          maxValue={20}
          onChange={setMaxGuests}
        />
      </Grid>
    </Grid>
  );
};

export default DetailsFields;
