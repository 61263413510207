import React, { useContext, useState } from "react";
import clsx from "clsx";
import {
  Grid,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { makeStyles } from "@mui/styles";
import RailsForm, { FormContext } from "../RailsForm";
import Button from "../../buttons/TextButton";
import { FormsContext } from "../../Document";
import InputWithCharacterCounter from "../InputWithCharacterCounter";

export type PageType = 'Vertical' | 'Permapage' | 'ActivityGroupPermapage';

type CommentFormProps = {
  commentsPath: string,
  isUserLoggedIn: boolean,
  page?: PageType,
};

const useStyles: () => any = makeStyles((theme): {}=> ({
  commentFormBox: {
    border: '1px solid #69737a',
    borderRadius: 6,
    padding: theme.spacing(1),
  },
  commentFormBoxWithTextInputted: {
    borderColor: '#3953e2',
    boxShadow: '0px 0px 0px 1px #3953e2',
  },
  commentSendButton: {
    float: 'right',
    padding: 0,
    minWidth: 'auto',
    width: theme.spacing(2),
  },
  inputHover: {
    '& textarea': {
      cursor: [['pointer'], '!important'],
    },
  },
}));

const CommentForm = ({
  commentsPath,
  isUserLoggedIn,
  page = 'Permapage',
}: CommentFormProps) => {
  const classes = useStyles();
  const { triggerLogIn } = useContext(FormsContext);
  const placeholder = isUserLoggedIn ? 'Reply to the group...' : 'Log in to add a comment'
  const [commentFocus, setCommentFocus] = useState(false)

  const logInUser = () => {
    if (!isUserLoggedIn) {
      triggerLogIn()
    }
  };

  return <>
    <RailsForm
      action={commentsPath}
      data-test-id="comment-form"
    >
      <FormContext.Consumer>
        {({ disabled }) => (
          <Grid container direction="row"
            className={clsx(
              classes.commentFormBox,
              { [classes.commentFormBoxWithTextInputted]: !disabled || commentFocus },
            )}
          >
            <Grid item xs={11.5}>
              <input
                type="hidden"
                value={page}
                name='page'
              />
              <InputWithCharacterCounter
                fullWidth
                characterLimit={5000}
                onClick={() => logInUser()}
                multiline
                className={clsx(classes.commentTextField,
                  { [classes.inputHover]: !isUserLoggedIn },
                )}
                name="comment[body]"
                id="comment_form_body"
                placeholder={placeholder}
                onTextFieldFocus={() => {setCommentFocus(true)}}
                onTextFieldBlur={() => {setCommentFocus(false)}}
              />
            </Grid>
            <Grid item xs={0.5}>
              <Button
                type="submit"
                className={classes.commentSendButton}
                data-test-id="comment-send-button"
                disabled={disabled}
              >
                <SendIcon />
              </Button>
            </Grid>
          </Grid>
        )}
      </FormContext.Consumer>
    </RailsForm>
  </>;
};

export default CommentForm;
