import React, { useState } from "react";
import { Link, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useMutation } from "@apollo/client";
import DeleteResourceConfirmationDialog from "../../shared/DeleteResourceConfirmationDialog";
import { StayBookingType } from "../../shared/TripObjectTypes";
import { STAY_BOOKING_DELETE } from "../../../graphql/queries/stayBookings";
import { STAYS_LIST_QUERY } from "../../../graphql/queries/stays";
import useSegment from "../../../services/useSegment";
import Errors from "../../shared/Errors";
import * as routes from "../../../routes";

type StayBookingActionsProps = {
  stayBooking: StayBookingType,
  stayId?: string,
  tripId?: string,
};

const StayBookingActions : React.FC<StayBookingActionsProps> = ({ stayBooking, tripId, stayId }) => {
  const { trackSegment } = useSegment();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [deleteBookingMutation] = useMutation(STAY_BOOKING_DELETE, { refetchQueries: [STAYS_LIST_QUERY] });

  const {
    editTripStayBookingPath,
  } = routes;

  const handleRemoveBooking = () => {
    trackSegment('Remove Stay Booking Initiated', {
     isMobileApp: isMobile,
     objectId: stayId,
     bookingId: stayBooking.uuid,
    });
    setDialogOpen(true);
  };

  const trackBookingRemoveEvent = () => {
    trackSegment('Stay Booking Removed', {
     isMobileApp: isMobile,
     objectId: stayId,
     bookingId: stayBooking.uuid,
    });
  };

  const onConfirm = () => {
    deleteBookingMutation({
      variables: {
        stayBookingId: stayBooking.id,
      },
      onCompleted: (result) => {
        if (result.errors?.length > 0) {
          setErrors(errors);
        } else {
          setSnackbarMessage && setSnackbarMessage("Success! Booking details removed.");
          trackBookingRemoveEvent();
        }
      },
      onError: (error) => {
        setErrors([error.message]);
      },
    });
    setDialogOpen( false );
  };

  const handleChangeBooking = () => {
    trackSegment('Stay Booking Edit Initiated', {
      isMobileApp: isMobile,
      objectId: stayId,
      bookingId: stayBooking.uuid,
    });

    window.location.href = editTripStayBookingPath(tripId, stayId, stayBooking.uuid);
  };

  const deleteMessageBody = (
    <Typography data-test-id="confirm-remove-booking">
      <span style={{fontWeight: 500}}>
        Heads up!
      </span> Once booking details are removed, this stay will no longer be marked as &quot;booked.&quot;
    </Typography>
  );

  return (
    <>
      <Stack>
        <Stack direction="row" spacing={2}>
          {stayId && tripId && (
            <Link href='#' onClick={handleChangeBooking} color="#777777" fontWeight={600} sx={{ textDecoration: "none" }} data-test-id="edit-booking">
              Change
            </Link>
          )}
          <Typography fontSize={16} color="#777777" fontWeight={600} sx={{ cursor: "pointer" }}
            onClick={handleRemoveBooking} data-test-id="remove-booking">
            Remove
          </Typography>
        </Stack>
        {errors && (<Errors errors={errors} />)}
      </Stack>
      <DeleteResourceConfirmationDialog
        isOpen={dialogOpen}
        resourceName='Booking Details'
        onClose={() => setDialogOpen(false)}
        onConfirm={onConfirm}
        path=''
        message={deleteMessageBody}
        snackbarMessage={snackbarMessage}
      />
    </>
  );
};

export default StayBookingActions;
