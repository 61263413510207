import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "../../../buttons/Button";

type ListingsErrorStateProps = {
  error: boolean,
  initialLoad: boolean,
  aiSearch: boolean,
  fetchListings: (object) => void,
};

const useStyles = makeStyles((theme) => ({
  image: {
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '400px',
    },
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  emptyStateText: {
    textAlign: 'center',
    fontSize: '18px',
  },
  emptyStateHeader: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 600,
    color: '#272727',
    marginTop: theme.spacing(2),
  },
  retryButton: {
    width: '140px',
    height: '50px',
    alignSelf: 'center',
    textDecoration: 'none',
    fontWeight: 600,
    padding: '8px 24px 8px 24px',
    borderRadius: '6px',
    fontSize: '16px',
    marginTop: theme.spacing(3),
  },
}));

const ListingsErrorState : React.FC<ListingsErrorStateProps> = ({
  error,
  initialLoad,
  aiSearch,
  fetchListings,
}) => {
  const classes = useStyles();

  const emptyStateKey = (initialLoad, isAiSearch, error) => {
    if (isAiSearch) {
      return 'aISearchError';
    } else if(error) {
      return 'searchError';
    }

    return 'defaultError';
  };

  const showEmptyState = (emptyState) => {
    const emptyStateDetails = {
      searchError: {
        alt: "after initial load",
        src: "02",
        header: "Sorry, there was an error making your request",
        subHeader: "",
      },
      aISearchError: {
        alt: "after initial load",
        src: "02",
        header: "No results found",
        subHeader: "Oops! Troupie couldn't find any matches based on your criteria. You can modify your prompt or try again.",
      },
      defaultError: {
        alt: "after initial load",
        src: "02",
        header: "We couldn’t find any stays that match your search",
        subHeader: "Try adjusting your dates or filters",
      },
    };

    const selectedDetail = emptyStateDetails[emptyState];

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
      >
        <img
          className={classes.image}
          alt={selectedDetail.alt}
          src={`/assets/illustrations/Search_${selectedDetail.src}.svg`}
        />
        <Typography className={classes.emptyStateHeader}>
          {selectedDetail.header}
        </Typography>
        {selectedDetail.subHeader &&
          <Typography className={classes.emptyStateText}>
            {selectedDetail.subHeader}
          </Typography>
        }
      </Grid>
    )
  };

  return (
    <>
      {showEmptyState(emptyStateKey(initialLoad, aiSearch, error))}
      {error && !initialLoad && (
        <Button
          className={classes.retryButton}
          data-test-id='retry-button'
          onClick={() => fetchListings({ skipCache: aiSearch, retrySameSearch: true })}
        >
          Try again
        </Button>
      )}
    </>
  )
}

export default ListingsErrorState;
