import React, { useCallback, useContext } from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ActivityGroupType } from "../../../../shared/TripObjectTypes";
import { ActivitySortingContext } from "../../../../shared/ActivityFilterContext";
import ActivityGroupActionsButtonGroup from "./ActivityGroupActionsButtonGroup";
import ActivitiesList from "../SuggestedActivitiesPage/ActivitiesList";
import ActivitiesFilteringAndSortingBar from "../SuggestedActivitiesPage/ActivitiesFilteringAndSortingBar";
import Button from "../../../../buttons/Button";
import Breadcrumbs from "../../../../shared/Breadcrumbs";
import NoSuggestionsYet from "../../../../shared/NoSuggestionsYet";
import responsiveText from "../../../../../services/responsiveText";
import { trackLink as trackAnalyticsEvent } from "../../../../../services/analytics";
import Linkify from "../../../../shared/Linkify";
import * as routes from '../../../../../routes';
import Tooltip from "../../../../shared/Tooltip";
import CurrentSortingAndCount from "../shared/CurrentSortingAndCount";

const {
  tripAddActivitiesPath,
  tripActivitiesPath,
  tripActivityGroupsPath,
  tripActivityGroupPath,
  editTripActivityGroupPath,
} = routes;

const useStyles = makeStyles((theme) => ({
  activityGroupTitle: {
    textAlign: 'left',
    lineHeight: theme.spacing(4),
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 600,
    minWidth: '100%',
    overflow: 'hidden',
  },
  activityGroupSubheader: {
    marginBottom: theme.spacing(2),
  },
  note: {
    fontSize: theme.spacing(2),
  },
}));

type MainContentProps = {
  activityGroup: ActivityGroupType,
  isPlanner: boolean,
};

const MainContent = ({
  activityGroup,
  isPlanner,
}: MainContentProps) => {
  const classes = useStyles();
  const noSuggestions = !activityGroup.activitiesSelected.length;
  const addActivityToActivityGroupText = responsiveText('+ Add', '+ Add activity');
  const activitiesAvailableEmpty = activityGroup.activitiesAvailable.length == 0;

  const { currentSortingLabel } = useContext(ActivitySortingContext);

  const tooltipTitle = () => {
    const noActivitiesCreated = activityGroup.activitiesAvailable.length === 0 && activityGroup.activitiesSelected.length === 0
    if(noActivitiesCreated) {
      return "Add activities to get this group rolling."
    }

    const allActivitiesAdded = activityGroup.activitiesAvailable.length === 0 && activityGroup.activitiesSelected.length !== 0
    if(allActivitiesAdded) {
      return "All activities from the list have been added."
    }

    return "";
  }

  const breadcrumbs = [
    {
      label: 'All activities',
      href: tripActivitiesPath(activityGroup.tripId),
    },
    {
      label: 'Groups',
      href: tripActivityGroupsPath(activityGroup.tripId),
    },
  ];

  const refs = useCallback((element) => {
    if (element !== null) {
      trackAnalyticsEvent(
        element,
        'Add Group Activity Initiated',
        {
          tripId: activityGroup.tripId,
          isPlanner: isPlanner,
          page: 'permapage',
        },
      );
    }
  }, []);

  return <>
    <Box my={2}>
      <Breadcrumbs
        active={activityGroup.title}
        links={breadcrumbs}
      />
    </ Box>
    <Grid
      container
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid item>
        <Typography
          className={classes.activityGroupTitle}
        >
          {activityGroup.title}
        </Typography>
      </Grid>
      <Grid item ml={2}>
        <ActivityGroupActionsButtonGroup
          deletePath={tripActivityGroupPath(activityGroup.tripId, activityGroup.id)}
          editPath={editTripActivityGroupPath(activityGroup.tripId, activityGroup.id)}
        />
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      my={2}
    >
      <Grid item className={classes.note}>
        <Linkify>{activityGroup.description}</Linkify>
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      my={2}
    >
      <Grid item>
        <ActivitiesFilteringAndSortingBar
          disabled={noSuggestions}
          tripId={activityGroup.tripId}
          formPath={tripActivityGroupPath(activityGroup.tripId, activityGroup.id)}
        />
      </Grid>
      <Tooltip title={tooltipTitle()} >
        <Grid item>
          <Button
            data-test-id="add-activity"
            href={tripAddActivitiesPath(activityGroup.tripId, activityGroup.id)}
            variant="primary"
            ref={(element) => refs(element)}
            disabled={activitiesAvailableEmpty}
          >
            {addActivityToActivityGroupText}
          </Button>
        </Grid>
      </Tooltip>
    </Grid>
    {!noSuggestions && (
      <Box pt={3}>
        <CurrentSortingAndCount
          count={activityGroup.activitiesSelected.length}
          sorting={currentSortingLabel}
          type="activity"
        />
      </Box>
    )}
    {!noSuggestions &&
      <ActivitiesList
        activities={activityGroup.activitiesSelected}
        activityGroupId={activityGroup.id}
        groupPermapage
      />
    }
    {noSuggestions && (
      <NoSuggestionsYet
        illustration='activity_group_fallback'
        subheading='Add some activities to get this group rolling!'
      />
    )}
  </>;
};

export default MainContent;
