import React, { useContext, useState } from "react";
import clsx from "clsx";
import {
  Checkbox,
  Grid,
  FormControlLabel,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormContext } from "../../../../shared/RailsForm";
import useStateWithPromise from "../../../../../services/useStateWithPromise"
import Chip from "../../../../buttons/Chip";
import DateOption from "../../../../shared/DateOption";
import getMostPopularDateOptions from "../../../../../services/getMostPopularDateOptions";
import { DateOptionWithAvailabilityType } from "../../../../shared/TripObjectTypes";
import * as routes from "../../../../../routes"
import { MaxPrice } from "../../../../Document";

type DateOptionsAvailabilityFieldsProps = {
  dateOptions: DateOptionWithAvailabilityType[],
  hasChosenDate: boolean,
  withTotalPriceField: boolean,
};

const {
  tripDatesPath,
} = routes

const useStyles = makeStyles((theme) => ({
  dateOption: {
    marginBottom: theme.spacing(2),
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  dateOptionName: {
    fontWeight: 600,
  },
  availableButtonText: {
    fontSize: 14,
    paddingLeft: theme.spacing(0.5),
    fontWeight: 500,
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(2),
  },
  unavailableChip: {
    maxWidth: '135px',
  },
  dateOptionsContainer: {
    [theme.breakpoints.up(theme.breakpoints.values.sm - 200)]: {
      maxWidth: '420px',
      flexGrow: 1,
      margin: 'auto',
    },
  },
  dateOptionIcon: {
    verticalAlign: 'bottom',
    marginRight: theme.spacing(1),
  },
  availableIcon: {
    color: '#1EBE95',
  },
  unavailableIcon: {
    color: '#FB5D5D',
  },
  totalPrice: {
    marginTop: theme.spacing(1),
  },
}));

const DateOptionsAvailabilityFields = ({
  dateOptions,
  hasChosenDate,
  withTotalPriceField,
}: DateOptionsAvailabilityFieldsProps) => {
  const classes = useStyles();
  const formContext = useContext(FormContext);
  const [
    dateOptionsStates,
    setDateOptionsStates,
  ] = useStateWithPromise(Array(...dateOptions.map(dateOption => dateOption.available)));
  const [allDatesAvailable, setAllDatesAvailable] = useState(dateOptionsStates.every(element => !!element));

  const updateDateOptionState = (index, available) => {
    let newDateOptionsStates = [...dateOptionsStates];

    let newState = newDateOptionsStates[index] === available ? null : available;

    newDateOptionsStates[index] = newState;

    setAllDatesAvailable(newDateOptionsStates.every(element => !!element))
    setDateOptionsStates(newDateOptionsStates).then(() => formContext.onFormChange());
  };

  const setOptionsAvailable = (available) => {
    let newDateOptionsStates = [...dateOptionsStates];
    let newState = available ? available : null;

    setAllDatesAvailable(!allDatesAvailable);

    newDateOptionsStates.map((element, index) => {
      newDateOptionsStates[index] = newState;
    });

    setDateOptionsStates(newDateOptionsStates).then(() => {
      formContext.onFormChange()
    });
  };

  const isAvailable = (index) => dateOptionsStates[index] === true;
  const isUnavailable = (index) => dateOptionsStates[index] === false;
  const mostPopularOptionsIds = getMostPopularDateOptions(dateOptions).map(option => option.id);

  return (
    <>
      {<span>
        {dateOptions.length > 0 && !hasChosenDate && (
          <Grid>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={allDatesAvailable}
                  onChange={(event) => setOptionsAvailable(event.currentTarget.checked) }
                  id="availableAllDates"
                />
              }
              label="Available for all dates"
            />
          </Grid>
        )}
      {dateOptions.map((dateOption, index) => (
        <React.Fragment key={dateOption.id}>
          <>
            <input
              type="hidden"
              value={dateOption.id}
              name={`stay[availability_dates_attributes][${index}][date_option_id]`}
            />
            <input
              type="hidden"
              value={dateOptionsStates[index] !== null ? dateOptionsStates[index].toString() : '' }
              name={`stay[availability_dates_attributes][${index}][available]`}
            />
          </>
          {dateOption.availabilityDateId && (
            <>
              <input
                type="hidden"
                value={dateOption.availabilityDateId}
                name={`stay[availability_dates_attributes][${index}][id]`}
              />
            </>
          )}
          <Grid
            className={classes.dateOptionsContainer}
            container
            data-test-id="dateOptionAvailability"
            direction="column"
          >
            <Grid
              item
              container
              direction="column"
              justifyContent="space-between"
              spacing={2}
              className={classes.dateOption}
            >
              <DateOption
                endDate={dateOption.endDate}
                endDateWeekday={dateOption.endDateWeekday}
                endDateYear={dateOption.endDateYear}
                isClickable={false}
                isMostPopular={mostPopularOptionsIds.includes(dateOption.id)}
                key={dateOption.id}
                linkPath={tripDatesPath(dateOption.tripId, { anchor: `date_option_${dateOption.id}` })}
                noVotesBreakdown
                numNights={dateOption.numNights}
                showLink={false}
                startDate={dateOption.startDate}
                startDateWeekday={dateOption.startDateWeekday}
                startDateYear={dateOption.startDateYear}
                travelersVotes={dateOption.travelersVotes}
              />
              {withTotalPriceField &&
                <TextField
                  data-test-id="availabilityPrice"
                  className={classes.totalPrice}
                  fullWidth
                  type="number"
                  inputProps={{
                    max: MaxPrice,
                    min: 0,
                    step: 0.01,
                  }}
                  label="Total price"
                  name={`stay[availability_dates_attributes][${index}][price]`}
                  variant="outlined"
                  defaultValue={dateOption.price}
                  id="total_price"
                />
              }
              <Grid item className={classes.chipsContainer} data-test-id={`chipsContainer-${index}`}>
                <Chip
                  active={isAvailable(index)}
                  onClick={() => updateDateOptionState(index, true)}
                  variant="successLighter"
                >
                  <CheckCircleIcon className={clsx(classes.availableIcon, classes.dateOptionIcon)}/>
                  <span className={clsx("button-text", classes.availableButtonText)} >
                    Available
                  </span>
                </Chip>
                <Chip
                  active={isUnavailable(index)}
                  onClick={() => updateDateOptionState(index, false)}
                  className={classes.unavailableChip}
                  variant="danger"
                >
                  <CancelIcon className={clsx(classes.unavailableIcon, classes.dateOptionIcon)}/>
                  <span className={clsx("button-text", classes.availableButtonText)}>
                    Unavailable
                  </span>
                </Chip>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}</span>}
    </>
  );
};

export default DateOptionsAvailabilityFields;
