import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TripNote } from '../NotesListPage';
import * as routes from '../../../../../routes';
import DeleteConfirmationDialog from "../../../../shared/DeleteConfirmationDialog";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

type ActionButtonsProps = {
  showDeleteButton: boolean,
  tripNote: TripNote,
};

const {
  editTripNotePath,
  tripNotePath,
} = routes;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(-0.625),
    marginBottom: theme.spacing(-0.625),
  },
  iconButton: {
    width: 32,
    height: 32,
    backgroundColor: "#FFFFFF",
    border: `1px solid ${theme.palette.general.background.disabled}`,
    "&:hover": {
      backgroundColor: '#F6F6F6',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  icon: {
    color: '#334351',
  },
  button: {
    height: 30,
    [theme.breakpoints.down('sm')]: {
      height: 36,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      borderRightColor: [theme.palette.primary.main, '!important'],
      background: theme.palette.general.hover,
      zIndex: 4,
    },
  },
  buttonGroup: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.palette.general.background.disabled}`,
    color: theme.palette.text.primary,
  },
}));

const ActionButtons = ({
  showDeleteButton,
  tripNote,
}: ActionButtonsProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseMenu = () => setAnchorEl(null);
  const handleDeleteClick = () => {
    handleCloseMenu()
    setDialogOpen(true);
  };

  return (
  <Box className={classes.root} data-test-id="actions-menu">
    <IconButton
      size="small"
      onClick={(event) => setAnchorEl(event.target)}
      data-test-id='actions-buttons'
      className={classes.iconButton}
    >
      <MoreHorizIcon fontSize="large" className={classes.icon}/>
    </IconButton>
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={() => window.location.href = editTripNotePath(tripNote.tripId, tripNote.id)} dense>
        <EditIcon fontSize="small"/>
        Edit
      </MenuItem>
      {showDeleteButton && (
        <MenuItem onClick={handleDeleteClick} dense>
          <DeleteIcon fontSize="small"/>
          Delete
        </MenuItem>
      )}
    </Menu>
    <DeleteConfirmationDialog
      isOpen={dialogOpen}
      fullWidthButtons
      message="This note will be deleted, along with all its contents."
      onClose={() => setDialogOpen(false)}
      path={tripNotePath(tripNote.tripId, tripNote.id)}
      title="Delete this note?"
    />
  </Box>
)};

export default ActionButtons;
