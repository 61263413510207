import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Divider,
  Grid,
} from "@mui/material";
import NewTripUpdatesDot from "./NewTripUpdatesDot";
import TripUpdateEntryAction, {
  TripEvent,
} from "./TripUpdateEntry/TripUpdateEntryAction";
import Activity from "../ActivitiesPage/Activity";
import Destination from "../DestinationPage/Destination";
import FinishedVotedTripEventLink from './TripUpdateEntry/FinishedVotedTripEventLink'
import TransitionedTripEventLink from './TripUpdateEntry/TransitionedTripEventLink'
import PollView from "../PollsPage/PollView"
import BroadcastBody from "../BroadcastsPage/BroadcastBody";
import UserAvatar from "../../../../shared/UserAvatar";
import DateOption from "../../../../shared/DateOption";
import CommentBody from "../../../../shared/Comments/CommentBody";
import {
  tripDatePath,
  tripDestinationPath,
  tripGetActivityPath,
  tripStayPath,
} from '../../../../../routes';
import Stay from "../HousingPage/Stay";
import TextButton from "../../../../buttons/TextButton";
import { tripNotePath } from "../../../../../routes";
import ActivityGroup from "../SuggestedActivityGroupsPage/ActivityGroup";
import ItineraryStayEvent from "../ItineraryDatePermapage/ItineraryStayEvent";
import ItineraryActivityEvent from "../ItineraryDatePermapage/ItineraryActivityEvent";

export type ActivityEntryProps = {
  tripEvent: TripEvent,
};

const useStyles = makeStyles((theme) => ({
  eventAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  eventDescriptionContainer: {
    position: 'relative',
  },
  eventDescription: {
    paddingLeft: `${theme.spacing(1)} !important`,
  },
  eventDivider: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  eventTripObject: {
    marginTop: theme.spacing(1),
  },
  goToNotes: {
    textAlign: 'center',
    width: '100%',
  },
}));

const avatarBadgeContent = (eventType) => {
  return isFinishedVotedEvent(eventType) ? <span>🎉</span> : null;
};

const isFinishedVotedEvent = (eventType) => {
  return ['finished_voted_date_options',
    'finished_voted_destination',
    'finished_voted_stay',
  ].indexOf(eventType) > -1;
};

const TripUpdateEntry = ({
  tripEvent,
}: ActivityEntryProps) => {
  const classes = useStyles();
  const showTransitionLink = (eventType) => {
    return eventType.match(/transitioned_to_voting/) || eventType.match(/transitioned_to_suggesting/);
  }

  return (
    <>
    <Grid container spacing={1} className={classes.eventDescriptionContainer}>
      <Grid item>
        <UserAvatar
          avatarSrc={tripEvent.authorAvatarUrl}
          avatarClassName={classes.eventAvatar}
          badgeContent={avatarBadgeContent(tripEvent.eventType)}
        />
      </Grid>
      <Grid item xs={9} className={classes.eventDescription}>
        <TripUpdateEntryAction tripEvent={tripEvent} />
      </Grid>
      {tripEvent.isUnviewedEvent && <NewTripUpdatesDot />}
    </Grid>
    <Grid container className={classes.eventTripObject}>
      {tripEvent.eventObjectType === 'Activity' && tripEvent.eventObject && (
        <Activity
          activity={tripEvent.eventObject}
          linkToComment={tripGetActivityPath(tripEvent.tripId, tripEvent.eventObject.id)}
          showSentiments={false}
        />
      )}
      {tripEvent.eventObjectType === 'ActivityGroup' && tripEvent.eventObject && (
        <ActivityGroup
          activityGroup={tripEvent.eventObject}
          hideActionButtons={true}
          hideActivityCards={true}
        />
      )}
      {tripEvent.eventObjectType === 'Broadcast' && (
        <BroadcastBody broadcast={tripEvent.eventObject} tripId={tripEvent.tripId} showReactions={false}/>
      )}
      {tripEvent.eventObjectType === 'Destination' && tripEvent.eventType !== 'finished_voted_destination' && (
        <Destination
          hideVotes={true}
          destination={tripEvent.eventObject}
          isClickable
          linkToComment={tripDestinationPath(tripEvent.tripId, tripEvent.eventObject.id)}
        />
      )}
      {tripEvent.eventObjectType === 'Stay' && (
        <Stay
          stay={tripEvent.eventObject}
          hideDateOptionsAvailability
          hideDetails
          imgPath={tripStayPath(tripEvent.tripId, tripEvent.eventObject.id)}
          linkNameToProvider
          isClickable
          noCarousel={true}
        />
      )}
      {tripEvent.eventObjectType === 'Comment' && (
        <CommentBody comment={tripEvent.eventObject} />
      )}
      {tripEvent.eventObjectType === 'TripDateOption' && (
        <DateOption
          commentsPath={tripDatePath(tripEvent.tripId, tripEvent.eventObject.id)}
          endDate={tripEvent.eventObject.endDate}
          endDateWeekday={tripEvent.eventObject.endDateWeekday}
          endDateYear={tripEvent.eventObject.endDateYear}
          noVotesBreakdown
          numNights={tripEvent.eventObject.numNights}
          showLink
          showMissingVoteAlert={tripEvent.eventObject.isCurrentUserVoteRequired}
          startDate={tripEvent.eventObject.startDate}
          startDateWeekday={tripEvent.eventObject.startDateWeekday}
          startDateYear={tripEvent.eventObject.startDateYear}
          travelersVotes={tripEvent.eventObject.travelersVotes}
        />
      )}
      {tripEvent.eventObjectType === 'Poll' && (
         <PollView
            displayDeleteButton={false}
            poll={tripEvent.eventObject}
            shouldRedirectTo='index'
            hideAnswers={true}
            wrapWithLink={true}
        />
      )}
      {tripEvent.eventObjectType === 'TripNote' && (
        <TextButton
          className={classes.goToNotes}
          href={tripNotePath(tripEvent.tripId, tripEvent.eventObject.id)}
        >
          Go to Notes
        </TextButton>
      )}
      {tripEvent.eventObjectType === 'Reaction' && (
        tripEvent.eventObject.reactableType === 'comment' ? (
        <CommentBody comment={{
          ...tripEvent.eventObject.reactable,
          commentsUrl: tripEvent.eventObject.commentableUrl,
        }} />)
        :
        (<BroadcastBody broadcast={tripEvent.eventObject.reactable} tripId={tripEvent.tripId} showReactions={false}/>)
      )}
      {tripEvent.eventObjectType === "ItineraryEvent" && (
        tripEvent.eventObject.schedulableType === 'Stay' ? (
          <Grid sx={{width: '100%'}}>
            <ItineraryStayEvent stayEvent={tripEvent.eventObject} page={"tripUpdates"} itineraryDateId={tripEvent.eventObject.itineraryStartDate.id} isClickeable/>
          </Grid>
        ) : (
          <Grid sx={{width: '100%'}}>
            <ItineraryActivityEvent activityEvent={tripEvent.eventObject} page={"tripUpdates"} itineraryDateId={tripEvent.eventObject.itineraryStartDate.id} isClickeable/>
          </Grid>
      ))}
      {isFinishedVotedEvent(tripEvent.eventType) && (
        <FinishedVotedTripEventLink
          tripId={tripEvent.tripId}
          eventType={tripEvent.eventType}/>
      )}
      {showTransitionLink(tripEvent.eventType) && (
        <TransitionedTripEventLink
          eventType={tripEvent.eventType}
          tripId={tripEvent.tripId}/>
      )}
    </Grid>
    <Divider className={classes.eventDivider} />
  </>
  );
};

export default TripUpdateEntry;
