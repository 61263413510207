import React from "react";
import {
  Dialog as DialogBase,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  IconButton,
  Slide,
  useMediaQuery,
  SlideProps,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, useTheme } from "@mui/styles";
import clsx from "clsx";

export type DialogProps = {
  actions?: React.ReactNode,
  children?: React.ReactNode,
  fullWidth?: boolean,
  isOpen: boolean,
  keepMounted?: boolean,
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl",
  message: string | React.ReactNode,
  onClose?: () => void,
  onConfirm?: () => void,
  position?: 'center' | 'bottom',
  transition?: React.ForwardRefExoticComponent<SlideProps>,
  subtitle?: string,
  title?: string | React.ReactNode,
  backgroundClass?: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  bottom: {
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      padding: 0,
      margin: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
    },
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
  },
  dialogActions: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    paddingTop: [[theme.spacing(1)], '!important'],
  },
  message: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    paddingBottom: [[theme.spacing(2)]],
    paddingTop: [[theme.spacing(2)]],
  },
  messageBody: {
    margin: 0,
  },
  title: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(445)]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogBottomOnMobile = ({children, ...props}: DialogProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down(445));
  const position = isMobile ? 'bottom' : 'center'
  const transition = position === 'bottom' ? Transition : undefined;

  return (
    <Dialog
      position={position}
      transition={transition}
      {...props}
    >
      {children}
    </Dialog>
  );
};

const Dialog = ({
  actions,
  children,
  isOpen,
  message,
  onClose,
  position = 'center',
  subtitle,
  title,
  transition,
  backgroundClass,
  ...dialogBaseProps
}: DialogProps) => {
  const classes = useStyles();

  const closeDialog = () => {
    if (onClose) {
      onClose();
    }
  };

  const CloseDialogButton = () => (
    <IconButton
      className={classes.closeIcon}
      data-test-id="closeDialogButton"
      onClick={closeDialog}
    >
      <CloseIcon />
    </IconButton>
  );

  return (
    <DialogBase
      classes={{ paper: clsx(classes[position], backgroundClass ) }}
      open={isOpen}
      onClose={closeDialog}
      TransitionComponent={transition}
      {...dialogBaseProps}
    >
      <CloseDialogButton />
        <DialogTitle className={classes.title}>
          {title && (
            <div>
              {title}
            </div>
          )}
          {subtitle && (
            <Typography py={1}>
              {subtitle}
            </Typography>
          )}
        </DialogTitle>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={12}>
          {(title || subtitle) && <Divider/>}
        </Grid>
      </Grid>
      <DialogContent className={classes.message}>
        {typeof message === 'string' ? (
          <DialogContentText className={classes.messageBody}>
            {message}
          </DialogContentText>
        ) : (
          message
        )}
      </DialogContent>
      {actions && (
        <DialogActions className={classes.dialogActions}>
          {actions}
        </DialogActions>
      )}
      {children}
    </DialogBase>
  );
};

export default Dialog;
