import React from "react";
import {makeStyles, DefaultTheme, useTheme} from "@mui/styles";
import {Avatar as MuiAvatar, useMediaQuery} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import clsx from "clsx";
import avatarSizes from "./avatarSizes";
import borderColors from "./borderColors";

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type BorderVariant = 'primary' | 'success' | 'warning' | 'danger';

type AvatarProps = {
  borderVariant?: BorderVariant,
  children?: React.ReactNode,
  className?: string,
  disabled?: boolean,
  plusOnes?: number,
  size?: Size,
  src?: string,
};

const useStyles = makeStyles<DefaultTheme, { borderVariant?: BorderVariant, size: Size }>((theme) => ({
  avatar: {
    backgroundColor: theme.palette.general.background.disabled,
    border: '1px solid #FFF',
    color: "white",
    height: ({ size }) => avatarSizes[size].height,
    width: ({ size }) => avatarSizes[size].width,
  },
  disabled: {
    background: '#FFFFFF',
    '& img': {
      opacity: 0.6,
    },
  },
  plusOnes: {
    backgroundColor: 'white',
    border: '1px solid #BEC9D4',
    borderRadius: '16px',
    height: ({ size }) => (avatarSizes[size].width >= 40 ? '24px' : '14px'),
    width: ({ size }) => `${avatarSizes[size].width - 8}px`,
    color: '#505050',
    fontSize: ({ size }) => (avatarSizes[size].width >= 40 ? '12px' : '10px'),
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: ({ size }) => `${avatarSizes[size].height - 10}px`,
    right: '8px',
  },
  mobilePlusOnes: {
    left: '6px',
  },
  wrapper: {
    alignItems: 'center',
    background: ({ borderVariant }) => borderColors[borderVariant],
    borderRadius: '50%',
    display: 'flex',
    height: ({ size }) => avatarSizes[size].borderHeight,
    justifyContent: 'center',
    verticalAlign: 'middle',
    width: ({ size }) => avatarSizes[size].borderWidth,
    position: 'relative',
  },
}));

export const statusToVariant = {
  going: 'success',
  cannot_go: 'danger',
  interested: 'warning',
};

const Avatar : React.FC<AvatarProps> = ({
  borderVariant,
  children,
  className,
  disabled = false,
  plusOnes = 0,
  size = 'md',
  src,
  ...props
}) => {
  const classes = useStyles({ borderVariant, size });
  const fallbackIcon = <PersonIcon />;
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const renderPlusOnes = () => (
    (plusOnes > 0) &&
    <div
      className={clsx(classes.plusOnes,
        { [classes.mobilePlusOnes]: isMobile })}
      data-test-id="plus-one-count">
      +{plusOnes}
    </div>
  );

  const avatar =
    <MuiAvatar
      className={clsx(
        classes.avatar,
        className,
        {[classes.disabled]: disabled},
      )}
      src={src}
      {...props}
    >
      {!src && (children || fallbackIcon)}
    </MuiAvatar>;

  return (
    <div className={classes.wrapper}>
      {avatar}
      {renderPlusOnes()}
    </div>
  );
};

export default Avatar;
