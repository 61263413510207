import React from "react";
import clsx from "clsx";
import {ButtonBase, Grid, useMediaQuery} from "@mui/material";
import {makeStyles, useTheme} from "@mui/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoWindow from "./../../../shared/MapView/InfoWindow";
import { ExternalListingProps } from "../../../shared/TripObjectTypes";
import { ListingsMapContext } from "../../../../services/RenderWithOrWithoutSidebar";
import MapCard from "../../trips/microsite/HousingPage/MapView/MapCard";
import { searchLocation, isCurrentlyInPublicSearch } from "../../../../services/windowLocation";
import useMapClickEventRef  from "../../../../services/segmentEvents/useMapClickEventRef";

type ListingMarkerProps = {
  active: boolean,
  alreadyOnTrip: boolean,
  label: string,
  lat: number,
  listing: ExternalListingProps,
  lng: number,
  onClick: () => void,
  onInfoWindowClickOut: () => void,
  sidebar: boolean,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    left: -(parseInt(theme.spacing(11)) / 2),
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(1),
    borderRadius: 6,
    height: 34,
    width: theme.spacing(11),
    textAlign: 'center',
    color: theme.palette.primary.main,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 38,
      bottom: -5,
      borderTop: '6px solid #FFFFFF',
      borderRight: '6px solid transparent',
      borderLeft: '6px solid transparent',
      borderBottom: 'none',
    },
    '&:focus': {
      zIndex: 99,
    },
  },
  active: {
    zIndex: 101,
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&::after': {
      borderTopColor: theme.palette.primary.main,
    },
  },
  alreadyOnTrip: {
    backgroundColor: theme.palette.general.dark,
    color: '#FFFFFF',
    '&::after': {
      borderTopColor: theme.palette.general.dark,
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
  },
  icon: {
    fontSize: 14,
    marginRight: 4,
  },
  listingCard: {
    position: 'fixed',
    borderRadius: '10px',
    [theme.breakpoints.up('sm')]: {
      top: '50%',
      left:'25%',
      transform: 'translate(-50%, -50%)',
    },
    backgroundColor: '#FFFFFF',
    width: '250px',
  },
}));

const ListingMarker = ({
  active,
  alreadyOnTrip,
  label,
  listing,
  onClick,
  onInfoWindowClickOut,
  sidebar,
}: ListingMarkerProps) => {
  const classes = useStyles();
  const isMobileApp = useMediaQuery(useTheme().breakpoints.down('sm'));
  const sidebarOrMobileView = sidebar || isMobileApp;
  const inPublicSearch = isCurrentlyInPublicSearch();
  const location = searchLocation()
  const mapClickEventRef = useMapClickEventRef('stays', listing.uuid, location);

  const { hoveredListing } = React.useContext(ListingsMapContext);
  const highlighted = listing.id === hoveredListing?.id;

  const renderListingCard = () => (
    <MapCard
      stay={listing}
      key={`marker-listing-${listing.id}`}
      isSERP={!inPublicSearch}
    />
  );

  const renderInfoWindowContent = () => {
    if (sidebarOrMobileView) return renderListingCard();

    return (
      <Grid className={classes.listingCard} data-test-id='listing-card-div'>
        {renderListingCard()}
      </Grid>
    );
  };

  return React.useMemo(() => <>
    {active && (
      <InfoWindow onClickOut={onInfoWindowClickOut} position={sidebarOrMobileView ? 'absolute' : 'relative'}>
        {renderInfoWindowContent()}
      </InfoWindow>
    )}
    <ButtonBase
      data-test-id="listingMarker"
      onClick={onClick}
      ref={mapClickEventRef}
      className={clsx(
        classes.root,
        {
          [classes.active]: highlighted || active,
          [classes.alreadyOnTrip]: alreadyOnTrip,
        },
      )}
    >
      {alreadyOnTrip && (
        <CheckCircleIcon className={classes.icon} />
      )}
      <span className={classes.label}>
        {label}
      </span>
    </ButtonBase>
  </>, [active, hoveredListing, listing]);
};

export default ListingMarker;
