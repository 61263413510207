import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
} from "@mui/material";
import clsx from "clsx";

type SideBarPageProps = {
  hideSideBarBorder?: boolean,
  mainContent: React.ReactNode,
  sideBarContent?: React.ReactNode,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  mainContent: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      borderRight: '1px solid rgba(0, 0, 0, 0.2)',
      paddingRight: theme.spacing(2.5),
    },
    paddingBottom: theme.spacing(4),
  },
  sideBarContent: {
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      borderTop: 'none',
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2.5),
    },
  },
  borderRight: {
    border: 'none',
  },
}));

const SideBarPage = ({
  hideSideBarBorder,
  mainContent,
  sideBarContent,
}: SideBarPageProps) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={8}
        className={clsx(classes.mainContent, {
          [classes.borderRight]: hideSideBarBorder,
        })}
      >
        {mainContent}
      </Grid>
      {sideBarContent && (
        <Grid item xs={12} md={4} className={classes.sideBarContent}>
          {sideBarContent}
        </Grid>
      )}
    </Grid>
  );
};

export default SideBarPage;
