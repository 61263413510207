import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Chip,
  Grid,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CurrentAppliedFilters } from "./Filters";
import pluralize from "pluralize";
import { PropertyType } from "./Filters/SortAndFilter/PropertyTypes";
import {
  ALL_FILTERS_OBJECT,
  AmenitiesFiltersType,
} from "../../../../constants/amenitiesFilters";
import { capitalize } from "../../../../utils/strings";
import { useTrackSERPEvent } from "../../../../services/segmentEvents/useSERPEvents";
import {
  getStaysSearchFilteredEventProps,
} from "../../../../services/segmentEvents/getStaysSearchFilteredEventProps";

type SearchFilterChipsProps= {
  appliedFilters: CurrentAppliedFilters,
  onClick: (value: object) => void,
};

const mapPriceAndRatingFilter = {
  "maxPrice": 'Less than',
  "minPrice": 'More than',
};

const mapRoomFilterValue = {
  "bedroomCount": 'bedroom',
  "bathroomCount": 'bathroom',
  "hotelRoomCount": 'hotel room',
};

const mapBookingOptionValue = {
  freeCancellation: 'Free cancellation',
  instantBook: 'Instant confirmation',
};

const useStyles = makeStyles((theme) => ({
  filterChipContainer: {
    paddingTop: theme.spacing(1),
    flexWrap: 'nowrap',
  },
  chip: {
    margin: '8px 0px 0px 4px',
  },
}))

const SearchFilterChips = ({
  appliedFilters,
  onClick,
}: SearchFilterChipsProps) => {
  const classes = useStyles();
  const { trackSERPEvent } = useTrackSERPEvent();

  const handleOnDelete = (object) => {
    const newFilters = Object.assign({}, appliedFilters, object)

    onClick({ ...newFilters, searchMethod: 'chipDismissed' })

    const props = getStaysSearchFilteredEventProps(newFilters, 'chip')
    trackSERPEvent('Stays Search Filtered', props);
  };

  const renderChip = (label: string, key: string, filterName: string, value: false | null | PropertyType[] | AmenitiesFiltersType[]) => {
      const filterPair = {};
      filterPair[filterName] = value

    return (
      <Chip
        data-test-id={`filter-chip-${key}`}
        className={classes.chip}
        deleteIcon={<CloseIcon/>}
        key={key}
        label={label}
        variant="outlined"
        onDelete={() => handleOnDelete(filterPair)}
      />
    )
  };

  return(
    <Grid container className={classes.filterChipContainer}>
      {Object.keys(mapPriceAndRatingFilter).map((filter) => {
        const priceFilter = appliedFilters[filter];
        return (priceFilter && renderChip(`${mapPriceAndRatingFilter[filter]} $${priceFilter}`, `price-${filter}`, filter,null))
      })}
      {appliedFilters?.rating && renderChip(`At least ${appliedFilters.rating} ratings`, 'rating','rating', null)}
      {Object.keys(mapRoomFilterValue).map((filter) => {
        const roomFilter = appliedFilters[filter]
        return (roomFilter && renderChip(`${roomFilter} ${pluralize(mapRoomFilterValue[filter], roomFilter)}`, `${filter}`, filter, null))
      })}
      {appliedFilters?.amenities?.map((filter) => {
        const newAppliedAmenities = appliedFilters?.amenities?.filter(am => am !== filter)

        return renderChip(ALL_FILTERS_OBJECT[filter] || capitalize((filter.replace(/_/g, ' '))), `amenities-${filter}`, 'amenities', newAppliedAmenities)
      })}
      {Object.keys(mapBookingOptionValue).map((filter) => {
        const bookingOptionFilter = appliedFilters[filter];
        return (bookingOptionFilter && renderChip(mapBookingOptionValue[filter], `bookingOption-${filter}`, filter,false))
      })}
      {appliedFilters?.propertyTypes?.map((propertyType) => {
        const newAppliedPropertyTypes = appliedFilters?.propertyTypes?.filter(pt => pt !== propertyType)
        return propertyType.checked && renderChip(propertyType.label, `propertyType-${propertyType.label}`, 'propertyTypes', newAppliedPropertyTypes)
      })}
    </Grid>
  )
};

export default SearchFilterChips;
