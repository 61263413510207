import React from 'react';
import { Grid, GridProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type ProfileFormSectionProps = {
  title: string,
  subtitle?: string,
  children: React.ReactNode,
} & GridProps;

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: theme.spacing(2),
    color: "#061722",
  },
}));

const ProfileFormSectionGrid = ({
  title,
  subtitle,
  children,
  spacing = 1,
  ...gridProps
}: ProfileFormSectionProps) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Grid container direction="column" spacing={spacing} {...gridProps}>
        <Grid item>
          <Typography variant="h2">{title}</Typography>
        </Grid>
        {subtitle && (
          <Grid item>
            <Typography className={classes.subtitle}>
              {subtitle}
            </Typography>
          </Grid>
        )}
        {children}
      </Grid>
    </Grid>
  );
};

export default ProfileFormSectionGrid;
