import React, { useContext } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import NoSuggestionsYet from "../../shared/NoSuggestionsYet";
import TravelerInfo from "./travelers/TravelerInfo";
import {
  TravelersType,
} from "./TravelersByStatusType";
import { useQuery } from "@apollo/client";
import { TripSiteContext } from "../../Document";
import TravelersSkeleton from "./travelers/TravelersSkeleton";
import { GlobalContext } from "../../Document";
import RenderChildWhenFFOn from "../../RenderChildWhenFFOn";
import GuestsSection from "./travelers/GuestsSection";
import { TRAVELERS_QUERY } from "../../../graphql/queries/travelers";

type ManageTravelersProps = {
  isCurrentUserPlanner: boolean,
  tripId: string,
  onRemoveTraveler: (travelers: TravelersType[]) => void,
};

const useStyles = makeStyles(theme => ({
  interestedSection: {
    paddingTop: '40px !important',
  },
  subtitle: {
    paddingTop: theme.spacing(1),
  },
  travelerSubText: {
    paddingBottom: theme.spacing(1),
  },
  travelerStatusSection: {
    paddingTop: theme.spacing(3),
  },
  travelersWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  outerContainer: {
    flexWrap: 'nowrap',
  },
}));

const ManageTravelers = ({
  isCurrentUserPlanner,
  tripId,
  onRemoveTraveler,
}: ManageTravelersProps) => {
  const classes = useStyles();
  const { tripGid } = useContext(TripSiteContext)
  const { data } = useQuery(
    TRAVELERS_QUERY, {
      variables: {
        tripId: tripGid,
      },
    },
  )

  const travelersGoingList : TravelersType[] = data?.travelers.filter((traveler: TravelersType) => traveler.status === 'going')
  const travelersInterestedList : TravelersType[] = data?.travelers.filter((traveler: TravelersType) => traveler.status === 'interested')
  const travelersNotGoingList : TravelersType[] = data?.travelers.filter((traveler: TravelersType) => traveler.status === 'cannot_go')
  const totalGoingPlusOnes = travelersGoingList?.reduce((total: number, traveler: TravelersType) => total + (traveler.plusOneCount || 0), 0);
  const totalInterestedPlusOnes = travelersInterestedList?.reduce((total: number, traveler: TravelersType) => total + (traveler.plusOneCount || 0), 0);
  const totalNotGoingPlusOnes = travelersNotGoingList?.reduce((total: number, traveler: TravelersType) => total + (traveler.plusOneCount || 0), 0);

  return <>
      <Grid
        className={classes.outerContainer}
        container
        data-test-id='manage-travelers-tab'
        direction="column"
        spacing={2}
      >
        <Grid className={classes.travelerStatusSection} item>
          <Typography variant='h3'>
            Going ({travelersGoingList?.length ? travelersGoingList.length + totalGoingPlusOnes : 0})
          </Typography>
          <Grid
            data-test-id='traveler-going'
            direction="column"
            container
            className={classes.travelersWrapper}
            spacing={2}
          >
            {travelersGoingList ? travelersGoingList.map((traveler: TravelersType, index: number) => (
              <Grid item key={`traveler-going-${index}`} id={`traveler-${traveler.uuid}`}>
                <TravelerInfo
                  traveler={traveler}
                  tripId={tripId}
                  onRemoveTraveler={onRemoveTraveler}
                  isCurrentUserPlanner={isCurrentUserPlanner}
                />
              </Grid>
            )) : <TravelersSkeleton />}
          </Grid>
        </Grid>
        <GuestsSection
            isCurrentUserPlanner={isCurrentUserPlanner}
        />
        <Grid item className={classes.interestedSection}>
          <Typography variant='h3'>
            Interested ({travelersInterestedList?.length ? travelersInterestedList.length + totalInterestedPlusOnes : 0})
          </Typography>
          {travelersInterestedList?.length === 0 ? (
            <NoSuggestionsYet
              subheading="No one has responded “Interested”"
              illustration='relaxation'
            />
          ) : (
            <Grid
              data-test-id='traveler-interested'
              direction="column"
              container
              className={classes.travelersWrapper}
              spacing={2}
            >
              {travelersInterestedList?.map((traveler: TravelersType, index: number) => (
                <Grid item key={`traveler-interested-${index}`}>
                  <TravelerInfo
                    isCurrentUserPlanner={isCurrentUserPlanner}
                    traveler={traveler}
                    tripId={tripId}
                    onRemoveTraveler={onRemoveTraveler}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid className={classes.travelerStatusSection} item>
          <Typography variant='h3'>
            Not going ({travelersNotGoingList?.length ? travelersNotGoingList.length + totalNotGoingPlusOnes : 0 })
          </Typography>
          {travelersNotGoingList?.length === 0 ? (
            <NoSuggestionsYet
              subheading="No one has responded “Not Going”"
              illustration='relaxation'
            />
          ) : (
            <Grid
              data-test-id='traveler-not-going'
              direction="column"
              className={classes.travelersWrapper}
              container
              spacing={2}
            >
              {travelersNotGoingList?.map((traveler: TravelersType, index: number) => (
                <Grid item key={`traveler-not-going-${index}`}>
                  <TravelerInfo
                    isCurrentUserPlanner={isCurrentUserPlanner}
                    traveler={traveler}
                    tripId={tripId}
                    hidePlusOneAddButton
                    onRemoveTraveler={onRemoveTraveler}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
}

export default ManageTravelers;
