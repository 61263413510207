import React, { useState } from "react";
import RailsForm from "../RailsForm";
import {
  Avatar,
  ButtonBase,
  Box,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import * as routes from '../../../routes';

const {
  accountPath,
  tripsPath,
} = routes;

type NavBarMenuProps = {
  currentUserAvatarUrl: string,
  hideMyTripsButton: boolean,
}

const useStyles = makeStyles((theme) => ({
  accountCopy: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '21px',
    color: '#FFFFFF',
  },
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: '4px',
  },
  avatarButton: {
    border: '1px solid #FFFFFF',
    borderRadius: '25px',
    padding: '8px',
    maxHeight: '34px',
  },
}));

const NavBarMenu = ({
  currentUserAvatarUrl,
  hideMyTripsButton,
}: NavBarMenuProps) => {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const openMenu = ({ currentTarget }: { currentTarget: Element }) => setMenuAnchorEl(currentTarget);
  const closeMenu = () => setMenuAnchorEl(null);
  const isLargerScreen = useMediaQuery(useTheme().breakpoints.up(420));

  return <>
    <ButtonBase onClick={openMenu} className={classes.avatarButton}>
      <Avatar
        className={classes.avatar}
        src={currentUserAvatarUrl}
        data-test-id="current-user-avatar"
      />
      <Typography className={classes.accountCopy}>Account</Typography>
    </ButtonBase>
    <Menu
      disableScrollLock={isLargerScreen}
      aria-controls="navbar-menu"
      open={Boolean(menuAnchorEl)}
      onClose={closeMenu}
      anchorEl={menuAnchorEl}
      keepMounted
      data-test-id="navBarMenu"
    >
      {!hideMyTripsButton && (
        <Box>
          { /* NOTE: for some reason, the types don't think `href` can be used with `component` */ }
          { /* @ts-expect-error */ }
          <MenuItem
            component={ButtonBase}
            href={tripsPath()}
            data-test-id="myTripsButton"
          >
            My trips
          </MenuItem>
        </Box>
      )}
      <Box>
        { /* NOTE: for some reason, the types don't think `href` can be used with `component` */ }
        { /* @ts-expect-error */ }
        <MenuItem
          component={ButtonBase}
          href={accountPath()}
          data-test-id="myAccountButton"
        >
          Account
        </MenuItem>
      </Box>
      <Box>
        <RailsForm method="DELETE" action="/auth" withChangeDetection={false}>
          <MenuItem
            id="logout-menu"
            component='button'
            type="submit"
            data-test-id="logOutButton"
          >
            Log out
          </MenuItem>
        </RailsForm>
      </Box>
    </Menu>
  </>;
}

export default NavBarMenu;
