import React from 'react';
import clsx from "clsx";
import pluralize from "pluralize";
import {
  Grid,
  Typography,
} from "@mui/material";
import titleize from 'titleize';
import { makeStyles } from "@mui/styles";
import Avatar, { statusToVariant } from '../../avatars/Avatar';
import { TravelerProps } from "../TravelersVotingBox";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TypeTripObjectType } from '../TripObjectTypes';
import ErrorIcon from '@mui/icons-material/Error';

type TravelerRowProps = {
  isEmptyState: boolean,
  traveler: TravelerProps,
  verticalType: TypeTripObjectType,
};

const useStyles = makeStyles(theme => ({
  icon: {
    height: 20,
    verticalAlign: 'text-bottom',
    marginRight: theme.spacing(0.5),
  },
  checkIcon: {
    color: '#20B163',
  },
  errorIcon: {
    color: '#7D94A9',
  },
  name: {
    color: '#303232',
    fontSize: 16,
    lineHeight: '24px',
  },
  missingVotes: {
    color: '#505050',
    fontSize: 14,
    lineHeight: '12px',
    marginTop: theme.spacing(0.5),
  },
}));

const TravelerRow = ({
  isEmptyState,
  traveler,
  verticalType,
}: TravelerRowProps) => {
  const classes = useStyles();
  const titleizedVerticalType = titleize(verticalType);

  const missingVotesText = () => {
    if (traveler.missingVotesCount === 0) {
      return (
        <>
          <CheckCircleIcon className={clsx(classes.icon, classes.checkIcon)} />
          {pluralize(titleizedVerticalType, 1, false)} voting complete
        </>
      );
    } else {
      return (
        <>
          <ErrorIcon className={clsx(classes.icon, classes.errorIcon)} />
          {traveler.missingVotesCount} {pluralize(verticalType, traveler.missingVotesCount, false)}
          {` missing ${pluralize('vote', traveler.missingVotesCount)}`}
        </>
      )
    };
  };

  return (
      <Grid item container spacing={2} alignItems="center" data-test-id="travelerRow">
       <Grid item>
         <Avatar
           borderVariant={statusToVariant[traveler.status]}
           src={traveler.avatarUrl}
           size="md"
         />
       </Grid>
      <Grid item>
        <Typography className={classes.name}>
          {traveler.name}
        </Typography>
        <Typography className={classes.missingVotes}>
          {isEmptyState ? (
            `No ${verticalType} to vote on`
          ) : (
            missingVotesText()
          )}
        </Typography>
      </Grid>
     </Grid>
  );
};

export default TravelerRow;
