import React, { useState } from "react";
import {
  Box,
  Grid,
  TextFieldProps,
  Typography,
  Theme,
} from "@mui/material";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DateRangePicker,
  MobileDateRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { makeStyles } from "@mui/styles";
import useStateWithPromise from "../../services/useStateWithPromise";
import { Dayjs } from "dayjs";
import { isSafari, isDesktop } from "react-device-detect";

export type CustomDateRangePickerProps = TextFieldProps & {
  autoComplete?: string,
  defaultEndValue?: string,
  defaultStartValue?: string,
  disabled?: boolean,
  hasError?: boolean,
  helperText?: boolean,
  nameEnd?: string,
  nameStart?: string,
  endValue?: string,
  startValue?: string,
  onChange?: (value: any) => void,
  useMobilePicker?: boolean,
  allowedMinDate?: string,
  allowedMaxDate?: string,
  shouldDisableDateEnabled?: boolean,
  disablePast?: boolean,
  isStayBookingForm?: boolean,
  defaultStayBookingCheckInTimeValue?: string,
  defaultStayBookingCheckOutTimeValue?: string,
};

type StyleProps = {
  isSafariDesktop: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  datesOuter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  picker: {
    "& .Mui-disabled": {
      color: theme.palette.general.background.disabled,
    },
  },
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  timeInput: {
    width: 191,
    height: 56,
    [theme.breakpoints.down('sm')]: {
      width: 131,
    },
    '& input': {
      // ensure placeholder text is visible on Safari desktop and that we don't break styles on iOS and other devices
      backgroundColor: ({ isSafariDesktop }) => isSafariDesktop ? theme.palette.background.default : null,
    },
  },
}));

const CustomDateRangePicker = ({
  defaultEndValue,
  defaultStartValue,
  disabled,
  hasError,
  nameEnd,
  nameStart,
  startValue,
  endValue,
  onChange,
  useMobilePicker,
  allowedMaxDate,
  allowedMinDate,
  shouldDisableDateEnabled = false,
  disablePast = true,
  isStayBookingForm = false,
  defaultStayBookingCheckInTimeValue,
  defaultStayBookingCheckOutTimeValue,
  ...dateRangePickerBaseInputProps
}: CustomDateRangePickerProps) => {
  const classes = useStyles({ isSafariDesktop: isSafari && isDesktop });
  let [dateRange, setDateRange] = useStateWithPromise([defaultStartValue || null, defaultEndValue || null]);

  const [checkInTime, setCheckInTime] = React.useState(defaultStayBookingCheckInTimeValue);
  const [checkOutTime, setCheckOutTime] = React.useState(defaultStayBookingCheckOutTimeValue);

  // This was added to make the DateRangePicker automatically close after selecting a date range.
  // https://github.com/mui-org/material-ui/issues/24828
  const [open, setOpen] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [isTextField, setIsTextField] = useState(false);

  if (startValue !== undefined || endValue !== undefined ) {
    dateRange = [startValue, endValue];
  }

  const Picker = useMobilePicker ? MobileDateRangePicker : DateRangePicker;

  const handleOnChange = (newRange) => {
    onChange && onChange(newRange)
  };

  const shouldDisableDate = (date: Dayjs) => {
    return shouldDisableDateEnabled ? (date.isBefore(allowedMinDate) || date.isAfter(allowedMaxDate)) : false;
  };

  const defaultDateRangeInputs = (startProps, endProps) => (
    <Grid container className={classes.datesOuter}>
      <TextField
        {...startProps}
        name={nameStart}
        error={hasError}
        data-test-id="start-date"
        onFocus={() => setIsTextField(true)}
        onBlur={() => setIsTextField(false)}
        onClick={() => setOpen(true)}
        {...dateRangePickerBaseInputProps}
      />
      <Box sx={{ mx: 1 }}> </Box>
      <TextField
        {...endProps}
        error={hasError}
        name={nameEnd}
        data-test-id="end-date"
        onFocus={() => setIsTextField(true)}
        onBlur={() => setIsTextField(false)}
        onClick={() => setOpen(true)}
        {...dateRangePickerBaseInputProps}
      />
    </Grid>
  )

  const stayBookingRenderInputs = (startProps, endProps) => (
    <Grid container spacing='2'>
      <Grid container className={classes.datesOuter}>
        <Grid item mr={2}>
          <Typography className={classes.label}>
            Check in date*
          </Typography>
          <TextField
            {...startProps}
            name={nameStart}
            error={hasError}
            data-test-id="start-date"
            onFocus={() => setIsTextField(true)}
            onBlur={() => setIsTextField(false)}
            onClick={() => setOpen(true)}
            {...dateRangePickerBaseInputProps}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.label}>
            Time
          </Typography>
          <TextField
            id="check_in_time"
            label="check in time"
            type="time"
            defaultValue={checkInTime}
            onChange={e => setCheckInTime(e.target.value)}
            name="stay_booking[check_in_time]"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.timeInput}
          />
        </Grid>
      </Grid>
      <Grid container my={1} className={classes.datesOuter}>
        <Grid item mr={2}>
          <Typography className={classes.label}>
            Check out date*
          </Typography>
          <TextField
            {...endProps}
            error={hasError}
            name={nameEnd}
            data-test-id="end-date"
            onFocus={() => setIsTextField(true)}
            onBlur={() => setIsTextField(false)}
            onClick={() => setOpen(true)}
            {...dateRangePickerBaseInputProps}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.label}>
            Time
          </Typography>
          <TextField
            id="check_out_time"
            label="check out time"
            type="time"
            defaultValue={checkOutTime}
            onChange={e => setCheckOutTime(e.target.value)}
            name="stay_booking[check_out_time]"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.timeInput}
          />
        </Grid>
      </Grid>
    </Grid>
  )

  const renderInputs = isStayBookingForm ? stayBookingRenderInputs : defaultDateRangeInputs;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: 'Start date', end: 'End date' }}>
      <Picker
        className={classes.picker}
        data-test-id="customDatePicker"
        inputFormat="MM/DD/YYYY"
        disablePast={disablePast}
        shouldDisableDate={shouldDisableDate}
        showToolbar={false}
        open={open}
        onAccept={(dateRange) => {
          handleOnChange(dateRange);
        }}
        onClose={() => {
          setIsAccept(false);
          if (!isTextField) {
            setOpen(false);
            setIsTextField(false);
          }
        }}
        onOpen={() => {
          if (!isAccept) setOpen(true);
        }}
        onChange={(dateRange) => {
          setDateRange(dateRange).then(() => handleOnChange(dateRange))
        }}
        value={dateRange}
        disabled={disabled}
        renderInput={renderInputs}
      />
    </LocalizationProvider>
  );
};

CustomDateRangePicker.defaultProps = {
  defaultStartValue: null,
  defaultEndValue: null,
};

export default CustomDateRangePicker;
