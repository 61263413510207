import React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import FavoritesButton from '../buttons/FavoritesButton';
import { searchPath } from "../../routes";
import { DefaultTheme } from '@mui/styles';

type TroupeLogoWithFavoritesHeaderProps = {
  withMobilePadding?: boolean,
  viewingShared?: boolean,
};

const useStyles = makeStyles<DefaultTheme, {withMobilePadding: boolean}>((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 4),
    backgroundColor: '#262A71',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: ({withMobilePadding}) => withMobilePadding ? theme.spacing(2, 2) : 0,
    },
  },
  logoContainer: {
    display: 'flex',
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: theme.spacing(3.5),
    marginLeft: '3%',
    cursor: 'pointer',
    [theme.breakpoints.down(701)]: {
      marginTop: 0,
      marginBottom: 0,
      height: theme.spacing(2),
    },
  },
}));

const TroupeLogoWithFavoritesHeader : React.FC<TroupeLogoWithFavoritesHeaderProps> = ({
  withMobilePadding = false,
  viewingShared = false,
}) => {
  const classes = useStyles({withMobilePadding});

  return(
    <div className={classes.header}>
      <div className={classes.logoContainer}>
        <img
          src="/assets/logo_white.svg"
          alt="Troupe logo"
          className={classes.logo}
          onClick={() => window.location.href = searchPath()}
        />
      </div>
      <div>
        <FavoritesButton viewingShared={viewingShared} />
      </div>
    </div>
  );
}

export default TroupeLogoWithFavoritesHeader;
