import React from 'react';
import Button from "../buttons/Button";
import pluralize from "pluralize";
import { makeStyles } from '@mui/styles';
import { track as trackAnalyticsEvent } from "../../services/analytics";
import CommentIcon from '@mui/icons-material/Comment';

type CommentsButtonProps = {
  commentsCount: number,
  commentsPath: string,
  tripId: string,
  vertical: string,
};

const useCommentsButtonStyles = makeStyles((theme) => ({
  root: {
    color: '#061722 !important',
    borderColor: '#555e64 !important',
    whiteSpace: 'nowrap',
    fontSize: 14,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#353535',
      color: 'white',
    },
  },
}));

const CommentsButton = ({
  commentsPath,
  commentsCount,
  tripId,
  vertical,
}: CommentsButtonProps) => {
  const classes = useCommentsButtonStyles();
  const trackClickedComments = () => {
    trackAnalyticsEvent('Comments Viewed', {
      property: {
        tripObjectType: vertical,
        tripObjectId: tripId,
      },
    });
  };

  return (
    <Button
      href={commentsPath}
      onClick={() => trackClickedComments()}
      className={classes.root}
      data-test-id="tripCommentsButton"
      variant="secondary"
    >
      {commentsCount === 0 && "Comments (0)"}
      {commentsCount > 0 && `Comments (${commentsCount})`}
    </Button>
  );
}

export default CommentsButton;
