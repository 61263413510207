import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FixedSaveBar from "../../../../../shared/FixedSaveBar";
import PropertyTypes from "./PropertyTypes";
import Drawer from '../../../../../shared/Drawer';
import RoomFilters from "./RoomFilters";
import PriceFilter from "./PriceFilter";
import RatingFilter from "./RatingFilter";
import AmenitiesFilters from "./AmenitiesFilters";
import BookingOptions from "./BookingOptions";
import { useTrackSERPEvent } from "../../../../../../services/segmentEvents/useSERPEvents";
import { CurrentAppliedFilters } from "../../Filters";
import {
  getStaysSearchFilteredEventProps,
} from "../../../../../../services/segmentEvents/getStaysSearchFilteredEventProps";

type SortAndFilterDrawerProps = {
  filters: CurrentAppliedFilters,
  onClose: () => void,
  onSubmit: ({
    propertyTypes,
    bedroomCount,
    bathroomCount,
    minPrice,
    maxPrice,
    rating,
    amenities,
    instantBook,
    freeCancellation,
    hotelRoomCount,
  }) => void,
  open: boolean,
};

const useStyles = makeStyles((theme) => ({
  drawerContent: {
    padding: theme.spacing(4),
    flex: 1,
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const SortAndFilterDrawer = ({
  filters,
  open,
  onSubmit,
  onClose,
} : SortAndFilterDrawerProps) => {
  const classes = useStyles();
  const [ temporalFilters, setTemporalFilters ] = React.useState( filters );

  React.useEffect(() => {
    setTemporalFilters(filters);
  }, [filters]);

  const { trackSERPEvent } = useTrackSERPEvent();

  const onFiltersButtonDrawerSubmit = () => {
    const props = getStaysSearchFilteredEventProps(temporalFilters, 'drawer')
    trackSERPEvent('Stays Search Filtered', props);

    if (canSubmitForm()) {
      onSubmit({
        propertyTypes: temporalFilters.propertyTypes,
        bedroomCount: temporalFilters.bedroomCount,
        bathroomCount: temporalFilters.bathroomCount,
        minPrice: temporalFilters.minPrice,
        maxPrice: temporalFilters.maxPrice,
        rating: temporalFilters.rating,
        amenities: temporalFilters.amenities,
        instantBook: temporalFilters.instantBook,
        freeCancellation: temporalFilters.freeCancellation,
        hotelRoomCount: temporalFilters.hotelRoomCount,
      });
    }
    onClose();
  };

  const objectsEqual = (currentPropertyType, originalPropertyType) =>
    currentPropertyType.checked === originalPropertyType.checked;

  const arraysEqual = (originalPropertyTypes, currentPropertyTypes) =>
    originalPropertyTypes.length === currentPropertyTypes.length &&
      originalPropertyTypes.every(
        (object, index) => objectsEqual(object, currentPropertyTypes[index]),
      );

  const canSubmitForm: () => boolean = () => {
    return !arraysEqual(filters.propertyTypes, temporalFilters.propertyTypes) ||
      filters.bedroomCount !== temporalFilters.bedroomCount ||
      filters.bathroomCount !== temporalFilters.bathroomCount ||
      filters.minPrice !== temporalFilters.minPrice ||
      filters.maxPrice !== temporalFilters.maxPrice ||
      filters.rating !== temporalFilters.rating ||
      filters.amenities !== temporalFilters.amenities ||
      filters.instantBook !== temporalFilters.instantBook ||
      filters.freeCancellation !== temporalFilters.freeCancellation ||
      filters.hotelRoomCount !== temporalFilters.hotelRoomCount;
  };

  const onResetAllClick = () => {
    trackSERPEvent('SERP Filters Reset',
      {
        priceRangeReset: !!temporalFilters.minPrice || !!temporalFilters.maxPrice,
        propertyTypeReset: temporalFilters.propertyTypes.filter((type) => type.checked === true).length > 0,
        ratingReset: !!temporalFilters.rating,
        roomsReset: !!temporalFilters.bedroomCount || !!temporalFilters.bathroomCount,
        amenitiesReset: temporalFilters.amenities.length > 0,
        instantBookReset: temporalFilters.instantBook,
        freeCancellationReset: temporalFilters.freeCancellation,
        hotelRoomReset: !!temporalFilters.hotelRoomCount,
      },
    );

    setTemporalFilters({
      propertyTypes: temporalFilters.propertyTypes?.map((type) => ({...type, checked : false})),
      bedroomCount: '',
      bathroomCount: '',
      minPrice: '',
      maxPrice: '',
      rating: '',
      amenities: [],
      instantBook: false,
      freeCancellation: false,
      hotelRoomCount: '',
    });
  };

  const updateTemporalFilter = ({key, value}) => {
    setTemporalFilters( prevState => ({...prevState, [key]: value}));
  };

  return <>
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
    >
      <Box className={classes.drawerContent}>
        <Grid container direction="column" spacing={2}>
          <Grid item container mb={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2">
                Filters
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose} data-test-id='CloseIcon'>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Grid item container direction="column" spacing={5}>
            <Grid item>
              <PriceFilter
                min={Number(temporalFilters.minPrice)}
                max={Number(temporalFilters.maxPrice)}
                updateMinPrice={(value) => updateTemporalFilter({key: 'minPrice', value})}
                updateMaxPrice={(value) => updateTemporalFilter({key: 'maxPrice', value})}
              />
            </Grid>
            <Grid item width="100%">
              <RatingFilter
                rating={temporalFilters.rating}
                updateRatingFilter={(value) => updateTemporalFilter({key: 'rating', value})}
              />
            </Grid>
            <Grid item width="100%">
              <RoomFilters
                bathroomCount={temporalFilters.bathroomCount}
                bedroomCount={temporalFilters.bedroomCount}
                hotelRoomCount={temporalFilters.hotelRoomCount}
                updateBedroomCount={(value) => updateTemporalFilter({key: 'bedroomCount', value})}
                updateBathroomCount={(value) => updateTemporalFilter({key: 'bathroomCount', value})}
                updateHotelRoomCount={(value) => updateTemporalFilter({key: 'hotelRoomCount', value})}
              />
            </Grid>
            <Divider className={classes.divider} />
            <Grid item>
              <AmenitiesFilters
                amenitiesFilters={temporalFilters.amenities ?? []}
                updateAmenitiesFilters={(value) => updateTemporalFilter({key: 'amenities', value})}
              />
            </Grid>
            <Divider className={classes.divider} />
            <BookingOptions
              instantBookFilter={temporalFilters.instantBook}
              updateInstantBookFilter={(value) => updateTemporalFilter({key: 'instantBook', value})}
              freeCancellationFilter={temporalFilters.freeCancellation}
              updateFreeCancellationFilter={(value) => updateTemporalFilter({key: 'freeCancellation', value})}
            />
            <Divider className={classes.divider} />
            <Grid item>
              <PropertyTypes
                onPropertyTypeCheck={(value) => updateTemporalFilter({key: 'propertyTypes', value})}
                propertyTypes={temporalFilters.propertyTypes}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <FixedSaveBar
        buttonContent="Apply"
        extraButton={
          <Link
            component="button"
            data-test-id='reset-all-button'
            onClick={onResetAllClick}
            sx={{ paddingTop: '16px', color: '#272727', paddingLeft: '16px' }}
            type="button"
            underline="none"
            variant="h3"
          >
            Reset all
          </Link>
        }
        onClick={onFiltersButtonDrawerSubmit}
        saveButtonType="button"
      />
    </Drawer>
  </>;
};

export default SortAndFilterDrawer;
