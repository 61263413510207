import React from "react";
import ActivitiesMapSection from '../shared/ActivitiesMap/ActivitiesMapSection';
import { ActivityType } from "../../../../shared/TripObjectTypes";

type SideBarContentProps = {
  activities: ActivityType[],
  onExpandMap?: () => void,
};

const SideBarContent = ({
  activities,
  onExpandMap,
}: SideBarContentProps) => {
  const activitiesWithCoords = activities.filter((activity) => activity.longitude && activity.latitude);

  return <>
    {activitiesWithCoords.length > 0 && (
      <ActivitiesMapSection
        activities={activitiesWithCoords}
        onExpandMap={onExpandMap}
      />
    )}
  </>;
};

export default SideBarContent;
