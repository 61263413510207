import React from "react";
import {
  Avatar,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";

const TravelersSkeleton = ({}) => {
  return <>
    {Array.from({ length: 3 }, (_, index) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }} key={`traveler-skeleton-${index}`}>
        <Box sx={{ margin: 1 }}>
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
    ))}
  </>;
};

export default TravelersSkeleton;
