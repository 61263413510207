import React from "react";
import { Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { tripTravelersPath } from '../../routes';
import useSegment from "../../services/useSegment";

type AddPlusOneButtonProps = {
  tripId: string,
};

const useStyles = makeStyles((theme) => ({
  addPlusOne: {
    color: "#4A6176",
    width: "100%",
    marginTop: theme.spacing(2),
  },
  addPlusOneText: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
  },
}));

const AddPlusOneButton = ( {tripId = ''} : AddPlusOneButtonProps ) => {
  const classes = useStyles();
  const { trackSegment } = useSegment();

  const onPlusOneClick = () => {
    trackSegment('Add Plus 1 Initiated',
        {
          tripId: tripId,
          page: 'Invite Modal',
        },
      );
    }
  ;

  return(
    <Button
      className={classes.addPlusOne}
      href={`${tripTravelersPath(tripId)}?create_plus_one=true`}
      data-test-id="add-plus-one-button"
      onClick={onPlusOneClick}
    >
      <PersonAddAlt1Icon />
      <Typography className={classes.addPlusOneText}>
        Add Plus-one
      </Typography>
    </Button>
  )
}

export default AddPlusOneButton;
