export const STAY_PAGE_FIELDS = `
  fragment StayPageFields on Stay {
    uuid
    address
    available
    bedroomsCount
    bookable
    commentsCount
    currency
    currentUserRanking
    googlePlaceId
    googlePlaceUrl
    hasAutomaticAvailability
    images
    isChosen
    isDisabled
    latitude
    longitude
    maxGuests
    name
    originalImages
    originalName
    ranking
    price {
      ... on Price {
        price
      }
      ... on PriceRange {
        prices
      }
    }
    pricePerNight
    pricingType
    propertyType
    provider
    publicUrl
    reviewsCount
    starRating
    dateOptions {
      id
      isChosenDate
      available
      availabilityDateId
      price
      estimatedPrice
      commentsCount
      numNights
      startDate
      startDateWeekday
      startDateYear
      endDate
      endDateWeekday
      endDateYear
      uuid
    }
    mostRecentComment {
      id
      body
      editedAt
      isAuthoredByCurrentUser
      timestamp
      uuid
      author {
        displayName
        avatarUrl
        id
      }
    }
    suggester {
      id
      displayName
      avatarUrl
    }
    trip {
      uuid
    }
    stayBookings {
      id
      uuid
      author {
        id
        displayName
        avatarUrl
      }
      confirmationCode
      checkInDate
      checkOutDate
      totalCost
      checkInTime
      checkOutTime
      refundability
      refundableUntil
      notes
      uuid
    }
  }
`

export const STAY_PERMAPAGE_FIELDS = `
  fragment StayPermapageFields on Stay {
    uuid
    address
    available
    bedroomsCount
    bookable
    commentsCount
    currency
    currentUserRanking
    googlePlaceId
    googlePlaceUrl
    hasAutomaticAvailability
    images
    isChosen
    isDisabled
    latitude
    longitude
    maxGuests
    name
    originalImages
    originalName
    ranking
    price {
      ... on Price {
        price
      }
      ... on PriceRange {
        prices
      }
    }
    pricePerNight
    pricingType
    propertyType
    provider
    publicUrl
    reviewsCount
    starRating
    dateOptions {
      id
      isChosenDate
      available
      availabilityDateId
      price
      estimatedPrice
      commentsCount
      numNights
      startDate
      startDateWeekday
      startDateYear
      endDate
      endDateWeekday
      endDateYear
      uuid
    }
    stayBookings {
      id
      uuid
      author {
        id
        displayName
        avatarUrl
      }
      confirmationCode
      checkInDate
      checkOutDate
      totalCost
      checkInTime
      checkOutTime
      refundability
      refundableUntil
      notes
      uuid
    }
  }
`
