import React, { useCallback, useContext } from "react";
import CampaignIcon from '@mui/icons-material/Campaign';
import { tripBroadcastsPath } from '../../../../routes';
import { trackLink as trackAnalyticsEvent } from "../../../../services/analytics";
import ButtonBase from "./ButtonBase";
import { makeStyles } from "@mui/styles";
import { FormsContext, TripSiteContext } from "../../../Document";

type BroadcastButtonProps = {
  broadcastNum: number,
  currentUserId: string,
  className?: string,
  tripId: string,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: theme.spacing(4),
  },
}));

const BroadcastButton = ({
  broadcastNum,
  currentUserId,
  tripId,
}: BroadcastButtonProps) => {
  const classes = useStyles();

  const [ref, setRef] = React.useState(null);
  const broadcastRef = useCallback((element) => {
    if (element !== null) {
      setRef(element);

      trackAnalyticsEvent(
        element,
        'Broadcasts Opened',
        {
          category: 'Broadcasts',
          source: 'main nav',
          tripId: tripId,
          userId: currentUserId,
        },
      );
    }
  }, []);

  return (
    <>
      <ButtonBase
        badgeContent={broadcastNum}
        href={tripBroadcastsPath(tripId)}
        testId='broadcast-icon'
        iconButtonRef={(element) => broadcastRef(element)}
        hoverState
        tooltipText={'Broadcasts'}
      >
        <CampaignIcon className={classes.icon}/>
      </ButtonBase>
    </>
  );
};

export default BroadcastButton;
