import React, { useCallback, useContext } from "react";
import Button from "../../../../buttons/Button";
import Tooltip from "../../../../shared/Tooltip";
import { newTripPollPath } from "../../../../../routes";
import { trackLink as trackLinkAnalyticsEvent } from '../../../../../services/analytics';
import { SegmentContext } from "../../../../Document";
import { Grid } from "@mui/material";

type AddPollButtonProps = {
  hasMoreThanThreeOpenedPolls: boolean,
};

const AddPollButton = ({
  hasMoreThanThreeOpenedPolls,
}: AddPollButtonProps) => {
  const { tripId, currentUserId, isPlanner } = useContext(SegmentContext)

  const createPollRef = useCallback((element) => {
    if (element !== null) {
      trackLinkAnalyticsEvent(
        element,
        'Poll Creation Initiated',
        {
          category: 'Polling',
          tripId: tripId,
          planner: isPlanner,
          userId: currentUserId,
        },
      );
    }
  }, []);

  const tooltip = 'Only 3 polls can be open at a time';

  return (
    <Tooltip title={hasMoreThanThreeOpenedPolls ? tooltip : ''}>
      <Grid>
        <Button
          data-test-id='create-poll-button'
          disabled={hasMoreThanThreeOpenedPolls}
          fullWidth
          href={newTripPollPath(tripId)}
          ref={(el) => createPollRef(el)}
        >
          Create poll
        </Button>
      </Grid>
    </Tooltip>
  );
};

export default AddPollButton;
