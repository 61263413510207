import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type VotingSectionProps = {
  header: string,
  subHeader: string,
  sentimentButtons: any,
};

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
  },
  subHeader: {
    fontSize: '1em',
  },
}));

const VotingSection = ({
  header,
  subHeader,
  sentimentButtons,
}: VotingSectionProps) => {
  const classes = useStyles();

  return (
    <Grid className={classes.divider}>
      <Grid item>
        <Typography variant="h2">
          {header}
        </Typography>
        <Typography className={classes.subHeader}>
          {subHeader}
        </Typography>
      </Grid>
      <Grid item className={classes.divider}>
        {sentimentButtons}
      </Grid>
    </Grid>
  );
};

export default VotingSection;
