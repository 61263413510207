import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import Dialog from "../../shared/Dialog";
import ShareFavoriteActions from "./ShareFavoriteActions";

type ShareFavoriteDialogProps = {
  listingCount?: number,
  onClose: () => void,
  open: boolean,
  shareUrl?: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '30px',
    maxWidth: '200px',
    textAlign: 'center',
  },
  image: {
    height: '114px',
  },
  shareFavoriteDialog: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '450px',
    },
    gap: theme.spacing(3),
  },
}));

const ShareFavoriteDialog : React.FC<ShareFavoriteDialogProps> = ({
  listingCount,
  onClose,
  open,
  shareUrl,
}) => {
  const classes = useStyles();

  const renderContent = () => (
    <Grid container direction="column" alignItems="center" className={classes.shareFavoriteDialog}>
      <Grid item>
        <Typography className={classes.title}>
          Invite Friends to View Your Favorites
        </Typography>
      </Grid>
      <Grid item>
        <img src='/assets/illustrations/paper_airplane.svg' className={classes.image} />
      </Grid>
      <ShareFavoriteActions
        shareUrl={shareUrl}
        listingCount={listingCount}
      />
    </Grid>
  );

  return (
    <Dialog
      data-test-id="shareFavoriteDialog"
      isOpen={open}
      message={renderContent()}
      onClose={onClose}
    />
  );
};

export default ShareFavoriteDialog;
