import React from "react";
import { Typography } from "@mui/material"
import DeleteConfirmationDialog, { DeleteConfirmationDialogProps } from "./DeleteConfirmationDialog";
import { makeStyles } from "@mui/styles";
import pluralize from 'pluralize';

type DeleteResourceConfirmationDialogProps = {
  additionalMessage?: string | React.ReactNode,
  resourceName: string,
  message?: React.ReactNode,
} & Omit<DeleteConfirmationDialogProps, "title" | "message">;

const useStyles = makeStyles((theme) =>({
  bold: {
    fontWeight: 500,
    display: 'inline',
  },
}));

const DeleteResourceConfirmationDialog = ({
  additionalMessage,
  resourceName,
  message,
  ...confirmationDialogProps
}: DeleteResourceConfirmationDialogProps) => {
  const classes = useStyles();
  const pluralizedResourceName = pluralize(resourceName, 2);

  return (
    <DeleteConfirmationDialog
      message={ message || (
        <>
          <Typography>
            <span className={classes.bold}>Heads up!</span> Once {pluralizedResourceName} are deleted you can’t get them back.
          </Typography>
          {additionalMessage}
        </>
      )}
      title={`Delete ${resourceName}?`}
      {...confirmationDialogProps}
    />
  );
};

export default DeleteResourceConfirmationDialog;
