import React from "react";

type CardGroupCarouselContextType = {
  currentStep?: number,
  maxStep?: number,
  onStep?: (step: number) => void,
  setCurrentStep?: (value: number) => void,
  setMaxStep?: (value: number) => void,
};

const CardGroupCarouselContext = React.createContext<CardGroupCarouselContextType>({
  currentStep: 0,
  maxStep: 0,
  setCurrentStep: null,
  setMaxStep: null,
});

export default CardGroupCarouselContext;
