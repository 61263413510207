import React from "react";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "./IconButton";

const AddButton = (buttonProps) => {
  return (
    <IconButton {...buttonProps} data-test-id="add-button">
      <AddIcon />
    </IconButton>
  );
};

export default AddButton;
