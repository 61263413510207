import React, { useContext } from 'react';
import { TripSiteContext, TripObjectState } from '../components/Document';

export const usePhasingContext = () => {
  const { datesState, destinationsState, staysState } = useContext(TripSiteContext);

  const areDatesInSuggestingPhase = () => {
    return datesState === TripObjectState.Suggesting;
  };

  const areDatesInVotingPhase = () => {
    return datesState === TripObjectState.Voting;
  };

  const areDatesFinalized = () => {
    return datesState === TripObjectState.Finalized;
  };

  const areDestinationsInSuggestingPhase = () => {
    return destinationsState === TripObjectState.Suggesting;
  };

  const areDestinationsInVotingPhase = () => {
    return destinationsState === TripObjectState.Voting;
  };

  const areDestinationsFinalized = () => {
    return destinationsState === TripObjectState.Finalized;
  };

  const areStaysInSuggestingPhase = () => {
    return staysState === TripObjectState.Suggesting;
  };

  const areStaysInVotingPhase = () => {
    return staysState === TripObjectState.Voting;
  };

  const areStaysFinalized = () => {
    return staysState === TripObjectState.Finalized;
  };

  return {
    areDatesInSuggestingPhase,
    areDatesInVotingPhase,
    areDatesFinalized,
    areDestinationsInSuggestingPhase,
    areDestinationsInVotingPhase,
    areDestinationsFinalized,
    areStaysInSuggestingPhase,
    areStaysInVotingPhase,
    areStaysFinalized,
    datesState,
    destinationsState,
    staysState,
  };
};

