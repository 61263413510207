import React from "react";
import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  Link,
  Typography,
} from '@mui/material';
import { makeStyles } from "@mui/styles";

type Link = {
  label: string,
  href: string,
};

export type BreadcrumbsProps = {
  active: string,
  links: Link[],
} & MuiBreadcrumbsProps;

const useStyles = makeStyles((theme) => ({
  breadcrumbBox: {
    '& .MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap',
    },
    '& .MuiBreadcrumbs-li:nth-child(3) ': {
      minWidth: theme.spacing(8),
    },
    '& .MuiBreadcrumbs-li ': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      boxOrient: 'vertical',
      lineClamp: 1,
    },
  },
}));

const Breadcrumbs = ({
  active,
  links,
  ...breadcrumbsProps
}: BreadcrumbsProps) => {
  const classes = useStyles();

  return (
    <div role="presentation">
      <MuiBreadcrumbs aria-label="breadcrumb" {...breadcrumbsProps} className={classes.breadcrumbBox}>
        {links.map((link, index) => (
          <Link
            key={`breadcrumb-link-${index}`}
            underline="hover"
            color="inherit"
            href={link.href}
            data-test-id={`breadcrumb-link-${index}`}
          >
            {link.label}
          </Link>
        ))}
        <Typography color="text.primary" data-test-id="active">
          {active}
        </Typography>
      </MuiBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
