import React from 'react';
import useSegment from '../../services/useSegment';
import { isCurrentlyNormalSearch, isCurrentlyInPublicSearch, isCurrentlySmartSearch } from '../windowLocation';

type StayAffiliateClickEventType = {
  listingUrl: string,
  targetClicked: 'card' | 'image',
};

export const useStayAffiliateClickEventRef = ({
  listingUrl,
  targetClicked,
}: StayAffiliateClickEventType) => {
  const { trackLinkSegment } = useSegment();
  const inPublicSearch = isCurrentlyInPublicSearch();

  const buttonRef = React.useCallback((element) => {
    if (element !== null) {
      const url = new URL(listingUrl);
      const values = url.href.includes('expedia') ? expediaValues({url}) : vrboValues({url})

      trackLinkSegment(
        element,
        'Stay Affiliate Link Clicked',
        {
          location: isCurrentlySmartSearch() ? 'aiTripSearch' : inPublicSearch ? 'Public Search' : isCurrentlyNormalSearch? 'SERP' : 'aiPublicSearch',
          listingUrl,
          targetClicked,
          method: window.location.href.includes('viewMode=map') ? 'expanded map' : 'list view',
          ...values,
        },
      );
    }
  }, []);

  return buttonRef;
};

const vrboValues = ({url}) => {
  const urlParams = url.searchParams;
  const hasDates = urlParams.has('arrival') && urlParams.has('departure');

  return {
      listingSource: 'VRBO',
      adultsCount: urlParams.get('adultsCount') || 1,
      dates: hasDates ? `${urlParams.get('arrival')} / ${urlParams.get('departure')}` : null,
      bedroomCount: urlParams.get('bedroomCount'),

  }
}

const expediaValues = ({url}) => {
  const regexDates = /(\d{4}-\d{2}-\d{2})\/(\d{4}-\d{2}-\d{2})/;
  const datesMatches = url.href.match(regexDates);
  const hasDates = datesMatches?.length === 3;

  const regexAdultsCount = /NumAdult-Room\d+=(\d+)/g;
  const adults = url.href.matchAll(regexAdultsCount);
  let adultsCount = 0;
  for(let match of adults) {
    adultsCount += Number(match[1])
  }

  return {
      listingSource: 'Expedia',
      adultsCount: adultsCount || 1,
      dates: hasDates ? `${datesMatches[1]} / ${datesMatches[2]}` : null,
      hotelRoomCount: url.searchParams.get('NumRooms'),
  }
}
