import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/Info";
import Chip from "../../../../../buttons/Chip";
import Tooltip from "../../../../../shared/Tooltip";

type RoomFiltersProps = {
  bathroomCount: string,
  bedroomCount: string,
  hotelRoomCount: string,
  updateBathroomCount : (value?:string) => void,
  updateBedroomCount : (value?:string) => void,
  updateHotelRoomCount: (value?:string) => void,
};

const useStyles = makeStyles((theme) => ({
  chip: {
    border: '1px solid #D9D9D9 !important',
    height: '40px !important',
    minWidth: '48px',
    borderRadius: '40px !important',
  },
  activeChip: {
    background: '#E5E7FF !important',
    border: '2px solid #545DFC !important',
    borderRadius: '40px !important',
    minWidth: '48px',
    height: '40px !important',
  },
  chipsRow: {
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
    flexWrap: 'nowrap',
    width: '100%',
    overflowX: 'auto',
  },
  infoIcon: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    fontSize: 24,
  },
}));

type RoomFilterProps = {
  count: string,
  onOptionClick: (value? : string) => void,
  options: string[],
  testId?: string,
  title: string,
  tooltip?: string,
};

export const RoomFilter : React.FC<RoomFilterProps> = ({
  count,
  onOptionClick,
  options,
  testId,
  title,
  tooltip,
}) => {
  const classes = useStyles();
  const isChipActive = (countLabel : string, index : number) => (parseInt(countLabel) || 0) === index;

  return (
    <Grid item width="100%">
      <Typography>
        {title}
        {tooltip && (
          <Tooltip
            placement="top"
            title={tooltip}
          >
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        )}
      </Typography>
      <Grid item container className={classes.chipsRow}>
        {options.map((option, index) => (
          <Grid item key={`${title}-${option}`} margin={0.5}>
            <Chip
              data-test-id={testId}
              className={isChipActive(count, index) ? classes.activeChip : classes.chip}
              onClick={() => onOptionClick(index === 0 ? '' : option)}
            >
              {option}
            </Chip>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
};

const RoomFilters : React.FC<RoomFiltersProps> = ({
  bathroomCount,
  bedroomCount,
  hotelRoomCount,
  updateBathroomCount,
  updateBedroomCount,
  updateHotelRoomCount,
}) => {
  return(
    <Grid item container direction='column' spacing={3} mb={5}>
      <Grid item>
        <Typography variant="h3">Rooms</Typography>
      </Grid>
      <RoomFilter
        count={bedroomCount}
        onOptionClick={updateBedroomCount}
        options={['Any', '1', '2', '3', '4', '5', '6+']}
        testId="bedroom-filter-chip"
        title="Bedrooms"
        tooltip="This bedroom count applies to vacation rental listings."
      />
      <RoomFilter
        count={bathroomCount}
        onOptionClick={updateBathroomCount}
        options={['Any', '1', '2', '3', '4', '5', '6+']}
        testId="bathroom-filter-chip"
        title="Bathrooms"
      />
        <RoomFilter
          count={hotelRoomCount}
          onOptionClick={updateHotelRoomCount}
          options={['Any', '1', '2', '3', '4', '5', '6', '7', '8']}
          title="Hotel rooms"
          testId="hotelRoom-filter-chip"
          tooltip="This room count applies to hotel listings."
        />
    </Grid>
  )
};

export default RoomFilters;
