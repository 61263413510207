import React from "react";
import clsx from "clsx";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StarIcon from "@mui/icons-material/Star";
import pluralize from 'pluralize';

type StayRatingProps = {
  stay: {
    reviewsCount: number,
    starRating: string,
  },
  className?: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    fontSize: 14,
    "& svg": {
      color: '#ffd13f',
      height: 18,
      marginBottom: 4,
      width: 18,
      verticalAlign: 'middle',
    },
    textAlign: 'left',
  },
}));

const StayRating = ({
  stay,
  className,
}: StayRatingProps) => {
  const classes = useStyles();
  const {
    reviewsCount,
    starRating,
  } = stay;

  const showRating = reviewsCount > 0 && !!starRating;
  const reviewWord = pluralize('review', reviewsCount)

  return (
    <>
      {showRating && (
        <Grid item xs container justifyContent='flex-end'>
          <Typography className={clsx(
            className,
            classes.root,
          )}>
            <StarIcon/>
            <strong>{starRating}</strong>
            <span>
              {` (${reviewsCount.toString()} ${reviewWord})`}
            </span>
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default StayRating;
