import React, { useEffect } from "react";
import StaysSearch, { CustomFiltersAppliedType, DateOptionProps } from "../stays_search/StaysSearch";
import { hocRailsAction } from "../../Document";
import { apiSearchAiListingsPath, aiSearchPath } from "../../../routes";
import makeStyles from "@mui/styles/makeStyles";
import { Container } from "@mui/material";
import SiteFooter from "../../shared/SiteFooter";
import ExternalListingCTA from "../stays_search/ExternalListingCTA";
import AiFilterBackground from "./AiFilterBackground";
import { CurrentAppliedFilters } from "../stays_search/Listings/Filters";
import * as Sentry from "@sentry/browser";

type AiStaysSearchPageProps = {
  currentAppliedFilters: CurrentAppliedFilters,
  customFiltersApplied: CustomFiltersAppliedType,
  dateOptions: DateOptionProps[],
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
  },
  pageContainer: {
    flex: 1,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
}));

const AiStaysSearchPage = ({
  currentAppliedFilters,
  customFiltersApplied,
  dateOptions,
}: AiStaysSearchPageProps) => {
  const classes = useStyles();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const encryptedUserEmail = urlSearchParams.get('ehash')

    if (encryptedUserEmail) {
      try {
        const userEmail = window.atob(encryptedUserEmail)
        analytics.identify({ waitlistEmail: userEmail })
      } catch (e) {
        Sentry.captureException(e)
      }
    }
  });

  useEffect(() => {
    const handlePopstate = () => window.location.reload();

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const basePath = aiSearchPath();
  const apiSearchPath = apiSearchAiListingsPath();

  return (
    <div className={classes.root}>
      <Container className={classes.pageContainer}>
        <StaysSearch
          currentAppliedFilters={currentAppliedFilters}
          customFiltersApplied={customFiltersApplied}
          dateOptions={dateOptions}
          topSuggestedDestinations={[]}
          basePath={basePath}
          apiSearchPath={apiSearchPath}
          renderCtaButton={({stay}) => (
            <ExternalListingCTA
              publicUrl={stay.publicUrl}
              provider={stay.provider}
              location='Ai Search'
            />
          )}
          SearchBackground={AiFilterBackground}
          aiSearch={true}
          aiSearchType="aiPublicSearch"
        />
      </Container>
      <SiteFooter />
    </div>
  );
};

export default hocRailsAction(AiStaysSearchPage);
