import React from "react";
import StaysSearch, { CustomFiltersAppliedType, DateOptionProps } from "../stays_search/StaysSearch";
import { hocRailsAction } from "../../Document";
import { DestinationType } from "../../shared/TripObjectTypes";
import { apiSearchListingsPath, searchPath } from "../../../routes";
import makeStyles from "@mui/styles/makeStyles";
import { Container } from "@mui/material";
import SiteFooter from "../../shared/SiteFooter";
import ExternalListingCTA from "../stays_search/ExternalListingCTA";
import FilterSearchedBackground from "./FilterSearchedBackground";
import FilterBackground from "./FilterBackground";

type PublicStaysSearchPageProps = {
  currentAppliedFilters: {
    locationName?: string,
  },
  customFiltersApplied: CustomFiltersAppliedType,
  dateOptions: DateOptionProps[],
  topSuggestedDestinations: DestinationType[],
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  pageContainer: {
    flex: 1,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
}));

const PublicStaysSearchPage = ({
  currentAppliedFilters,
  customFiltersApplied,
  dateOptions,
  topSuggestedDestinations,
}: PublicStaysSearchPageProps) => {
  const [hasSearched, setHasSearched] = React.useState(false);
  React.useEffect(() => {
    const handlePopstate = () => {
      // Detect back button click
      window.location.reload();
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
  const classes = useStyles();

  const basePath = searchPath();
  const apiSearchPath = apiSearchListingsPath();

  return (
    <div className={classes.root}>
      <Container className={classes.pageContainer}>
        <StaysSearch
          currentAppliedFilters={currentAppliedFilters}
          customFiltersApplied={customFiltersApplied}
          dateOptions={dateOptions}
          topSuggestedDestinations={topSuggestedDestinations}
          basePath={basePath}
          apiSearchPath={apiSearchPath}
          renderCtaButton={({stay}) => (
            <ExternalListingCTA
              publicUrl={stay.publicUrl}
              provider={stay.provider}
              location='Public Search'
            />
          )}
          SearchBackground={hasSearched ? FilterSearchedBackground : FilterBackground}
          showShortBackground={setHasSearched}
          hasSearched={hasSearched}
        />
      </Container>
      <SiteFooter />
    </div>
  );
};

export default hocRailsAction(PublicStaysSearchPage);
