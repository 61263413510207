import React from 'react';
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Carousel from "nuka-carousel";
import { Typography, ButtonBase } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .slider-control-centerright, .slider-control-centerleft': {
      zIndex: 2,
    },
  },
  image: {
    display: 'block',
    width: "100%",
    height: "100%",
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
    objectFit: 'cover',
    objectPosition: 'center',
    background: '#b4b9bc',
  },
  controlButtons: {
    '& button': {
      margin: ["20px","!important"],
    },
    '& div': {
      height: "32px",
      weight: "32px",
    },
    opacity: "100%",
    fontWeight: "bold",
    fontSize: "large",
  },
  searchPageImageSize: {
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(32),
    },
  },
  small: {
    maxHeight: 200,
  },
  nonSearchPageImageSize: {
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(60),
    },
  },
  imageText: {
    color: "#FFFFFF",
    fontSize: "14px",
  },
}));

type ImagesCarouselProps = {
  changeImageSize: boolean,
  images: string[],
  linkHref?: string,
  small?: boolean,
  segmentRef?: (element) => void,
};

const ImagesCarousel = ({
  linkHref,
  changeImageSize,
  images,
  segmentRef,
  small,
}: ImagesCarouselProps) => {
  const classes = useStyles();

  const renderBottomCenterControls = ({slideCount, currentSlide}) => (
    <Typography className={classes.imageText}>
      {currentSlide + 1}/{slideCount}
    </Typography>
  );

  const renderImage = image => {
    const imageTag = (
      <img
        data-test-id="tripStayCarouselPhoto"
        loading="lazy"
        src={image}
        key={image}
        className={clsx(classes.image,
          {[classes.small]: small},
          {[classes.searchPageImageSize]: changeImageSize},
          {[classes.nonSearchPageImageSize]: !changeImageSize},
        )}
      />
    );

    if (!linkHref) return imageTag;

    return (
      <ButtonBase
        disableTouchRipple
        onClick={() => window.open(linkHref, '_blank')}
        key={`a-${image}`}
        ref={segmentRef}
        sx={{ width: '100%' }}
      >
        {imageTag}
      </ButtonBase>
    )
  };

  return (
    <Carousel
      className={classes.root}
      wrapAround
      renderBottomCenterControls={renderBottomCenterControls}
      defaultControlsConfig={{
        nextButtonText: ">",
        prevButtonText: "<",
        prevButtonClassName: classes.controlButtons,
        nextButtonClassName: classes.controlButtons,
      }}
    >
      {images.map(image => (
        renderImage(image)
      ))}
    </Carousel>
  );
};

export default ImagesCarousel;
