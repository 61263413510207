import React from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { tripUpdatesPath } from '../../../../routes';
import ButtonBase from "./ButtonBase";

type ActivityButtonProps = {
  tripUpdatesNum: number,
  tripId: string,
  className?: string,
};

const TripUpdatesButton = ({
  tripUpdatesNum,
  tripId,
}: ActivityButtonProps) => {
  return (
    <ButtonBase
      href={tripUpdatesPath(tripId)}
      badgeContent={tripUpdatesNum}
      testId="notifications-icon"
      hoverState
      tooltipText={'Trip Updates'}
    >
      <NotificationsIcon />
    </ButtonBase>
  );
};

export default TripUpdatesButton;
