import React, { useCallback, useContext } from "react";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import Tooltip from "../../../../../shared/Tooltip";
import Button from "../../../../../buttons/Button";
import makeStyles from "@mui/styles/makeStyles";
import { track } from "../../../../../../services/analytics";
import { SegmentContext } from "../../../../../Document";
import * as routes from "../../../../../../routes";

const {
  tripAddActivitiesPath,
} = routes;

type AddActivityButtonCardProps = {
  activityGroupId: string,
  tooltipTitle: string,
  tripId: string,
  noActivitiesAvailable?: boolean,
}

type StyleProps = {
  buttonEnabled: boolean,
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    minHeight: '100px',
    height: '368px',
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover': {
      background: ({buttonEnabled}) => buttonEnabled ? theme.palette.general.hover : theme.palette.background.default,
    },
  },
  addActivityText: {
    fontSize: theme.spacing(2),
    fontWeight: 600,
    lineHeight: theme.spacing(2),
    letterSppacing: '0px',
    textAlign: 'center',
  },
  addActivitybutton: {
    padding: theme.spacing(1),
    backgroundColor: ({buttonEnabled}) => buttonEnabled ? theme.palette.link.main : theme.palette.link.disabled,
    color: 'white',
    width: '52px !important',
    height: '52px !important',
    borderRadius: `${theme.spacing(9)} !important`,
  },
}));

const AddActivityButtonCard = ({
  activityGroupId,
  tooltipTitle,
  tripId,
  noActivitiesAvailable = false,
}: AddActivityButtonCardProps) => {
  const classes = useStyles({buttonEnabled: !noActivitiesAvailable});
  const { isPlanner } = useContext(SegmentContext);

  const handleSegmentEvent = () => {
    track(
      'Add Group Activity Initiated',
      {
        tripId: tripId,
        isPlanner: isPlanner,
        page: 'groupsTab',
      },
    );
  }

  return (
    <Link
      href={noActivitiesAvailable ? null : tripAddActivitiesPath(tripId, activityGroupId)}
      underline="none"
      onClick={handleSegmentEvent}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.root}
        gap={2}
      >
        <Tooltip title={tooltipTitle}>
          <Grid item>
              <Button
                data-test-id='add-activity-button'
                href={tripAddActivitiesPath(tripId, activityGroupId)}
                className={classes.addActivitybutton}
                disabled={noActivitiesAvailable}
              >
                <AddRoundedIcon />
              </Button>
          </Grid>
        </Tooltip>
        <Grid item>
          <Typography className={classes.addActivityText}>Add activity</Typography>
        </Grid>
      </Grid>
    </Link>
  )
}

export default AddActivityButtonCard;
