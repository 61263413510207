import React, { useState } from "react";
import { Box } from "@mui/material";
import { hocRailsAction } from "../../../Document";
import { ItineraryType, ItineraryDateType, StayType } from "../../../shared/TripObjectTypes";
import Button from "../../../buttons/Button";
import EmptyStateModalForm from "../../../shared/EmptyStateModalForm";
import ModalForm from "../../../shared/ModalForm";
import {
  tripItineraryDatePath,
  tripStayEventsPath,
  tripStaysPath,
} from '../../../../routes';
import FirstStep from "./NewItineraryStayEventPage/FirstStep";
import LastStep from "./NewItineraryStayEventPage/LastStep";
import HorizontalStepper from "../../../shared/HorizontalStepper";
import useSegment from "../../../../services/useSegment";
import Errors from "../../../shared/Errors";
import makeStyles from "@mui/styles/makeStyles";
import { TravelerProps } from "../../../shared/TravelersVotingBox";
import {
  getSortedStaysFirstByFinalizedThenByRankings,
} from "../../../../services/RankingSystem";

type NewItineraryStayEventPageProps = {
  itinerary: ItineraryType,
  itineraryDate: ItineraryDateType,
  staysAvailable: StayType[],
  errors: string[],
  travelers: TravelerProps[],
};

const useStyles = makeStyles((theme) => ({
  errors: {
    paddingBottom: theme.spacing(2),
  },
}));

const NewItineraryStayEventPage = ({
  itinerary,
  itineraryDate,
  staysAvailable,
  errors = [],
}: NewItineraryStayEventPageProps) => {
  const classes = useStyles();
  const showEmptyState = staysAvailable.length === 0;
  const { trackSegment } = useSegment();

  const [selectedIds, setSelectedIds] = useState([]);
  const [lastStep, setLastStep] = useState(false);
  const [errorMessages, setErrorMessages] = useState(errors)

  const sortedStays = getSortedStaysFirstByFinalizedThenByRankings(staysAvailable);

  const onStaySelect = (stay) => {
    if (selectedIds.includes(stay.id)) {
      setSelectedIds(selectedIds.filter(id => id !== stay.id));
    } else {
      setSelectedIds(selectedIds => [...selectedIds, stay.id]);
    }
  };

  const bookedStays = (selectedIds) => {
    return selectedIds.map(stayId => {
      const stay = staysAvailable.find(stayAvailable => stayId === stayAvailable.id);

      return stay.stayBookings.length > 0
    });
  };

  const bookedStayCount = (selectedIds) => {
    return bookedStays(selectedIds).filter(booked => booked).length;
  };

  const onFormSubmit = (event) => {
    if (!lastStep) {
      event.preventDefault();
      trackSegment('Add Stay Event Selected', {
        stayCount: selectedIds.length,
        booked: bookedStays(selectedIds),
      });
      setLastStep(true);
      if(errorMessages) {
        setErrorMessages([])
      }
    } else {
      trackSegment('Stay Event Added', {
        stayCount: selectedIds.length,
        bookedStayCount: bookedStayCount(selectedIds),
      });
    }
  };

  const onBackButtonClick = () => {
    setLastStep(false);
    trackSegment('Stay Event Selection Back Selected', {
      stayCount: selectedIds.length,
      bookedStayCount: bookedStayCount(selectedIds),
    });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [lastStep]);

  const backButton = () => (
    <Button
      fullWidth
      onClick={onBackButtonClick}
      variant="secondary"
      data-test-id="back-button"
    >
      Back
    </Button>
  );

  const steps = ['Select stays', 'Add dates'];
  const formTitle = lastStep ? "Assign dates to stays" : "Select stays to add";
  const formSubtitle = (
    <div>
      {lastStep ? "Let travelers know when you’ll be staying at each accommodation." : "Select from your group’s suggested stays to start building out your day."}
      <Box mt={4} mb={2}>
        <HorizontalStepper activeStep={lastStep ? 1 : 0} steps={steps}/>
      </Box>
    </div>
  );

  return (
    <>
      {showEmptyState && (
        <EmptyStateModalForm
          buttonHref={tripStaysPath(itineraryDate.tripId)}
          buttonText='Go to stays'
          closePath={tripItineraryDatePath(itineraryDate.tripId, itineraryDate.id)}
          imgSrc='/assets/illustrations/camper.svg'
          subtitle='Head over to the Stays tab to add some stays first.'
          title='You don’t have any stays'
        />
      )}
      {!showEmptyState && (
        <ModalForm
          closePath={tripItineraryDatePath(itineraryDate.tripId, itineraryDate.id)}
          disabledSubmit={selectedIds.length === 0}
          extraSaveBarButton={lastStep ? backButton() : null}
          formActionPath={tripStayEventsPath(itineraryDate.tripId)}
          onClick={(event) => onFormSubmit(event)}
          saveButtonLabel={lastStep ? 'Save' : `Add stays (${selectedIds.length})`}
          saveButtonType={lastStep ? 'submit' : 'button'}
          subtitle={formSubtitle}
          title={formTitle}
          useDefaultSaveButton
        >
          {errorMessages.length > 0 && (
            <Errors errors={errorMessages} className={classes.errors}/>
          )}
          <input type="hidden" name="itinerary_date_id" value={itineraryDate.id} />
          {!lastStep &&
            <FirstStep
              onStaySelect={(stay) => onStaySelect(stay)}
              selectedIds={selectedIds}
              stays={sortedStays}
            />
          }
          {lastStep &&
            <LastStep
              defaultStartDate={itineraryDate.date}
              defaultEndDate={itinerary.endDate}
              itineraryEndDate={itinerary.endDate}
              itineraryStartDate={itinerary.startDate}
              selectedStays={sortedStays.filter( stay => selectedIds.includes(stay.id))}
            />
          }
        </ModalForm>
      )}
    </>
  );
};

export default hocRailsAction(NewItineraryStayEventPage);
