import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Link, Typography } from "@mui/material";
import { DateRange } from "@mui/icons-material";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ItineraryEventDialog, { renderButtons } from "./ItineraryEventDialog";
import { ItineraryEventType } from "../../../../shared/TripObjectTypes";
import CategoryLabel from "../shared/CategoryLabel";
import { editTripActivityEventPath } from "../../../../../routes";
import useSegment from "../../../../../services/useSegment";

type ItineraryActivityEventDialogProps = {
  itineraryDateId?: string,
  itineraryEvent: ItineraryEventType,
  renderTimeText: () => any,
};

const useStyles = makeStyles(theme => ({
  imageLabel: {
    top: '191px',
    position: 'absolute',
  },
  date: {
    fontSize: theme.spacing(2),
    display: 'flex',
    paddingRight: theme.spacing(2),
    color: '#4A6176',
  },
  dateIcon: {
    fontSize: '1.2rem',
    top: '2px',
    position: 'relative',
    marginRight: theme.spacing(1),
  },
  dateTimeBox: {
    color: '#4A6176',
  },
  addTimeLink: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#4A6176',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.link.main,
    },
  },
  addTimeText: {
    position: "relative",
    marginRight: '4px',
    bottom:' 4px',
  },
  timeIcon: {
    marginRight: '4px',
    position: 'relative',
    bottom:' 4px',
    fontSize: '1.2rem',
  },
}));

const ItineraryActivityEventDialog = ({
  itineraryDateId,
  itineraryEvent,
  renderTimeText,
}: ItineraryActivityEventDialogProps) => {
  const classes = useStyles();
  const price = parseFloat(itineraryEvent.metadata.price || '0').toFixed(2).replace(/\.00$/,'');
  const { trackSegment } = useSegment();

  const imageLabel = (
    <Grid item className={classes.imageLabel}>
      {itineraryEvent.metadata.category && (<CategoryLabel category={itineraryEvent.metadata.category} dialog/>)}
    </Grid>
  );

  const trackEditTimeInitiated = (method: string) => {
    trackSegment(
      'Activity Event Add Time Initiated',
      {
        method,
        objectId: itineraryEvent.id,
        objectType: itineraryEvent.schedulableType,
      },
    );
  };

  const onEditTimeClick = (method: string) => {
    trackEditTimeInitiated(method);
    window.location.href = `${editTripActivityEventPath(itineraryEvent.tripId, itineraryEvent.id)}?itinerary_date_id=${itineraryDateId}`;
  };

  return (
    <ItineraryEventDialog
      imageLabel={imageLabel}
      itineraryDateId={itineraryDateId}
      itineraryEvent={itineraryEvent}
      price={price}
    >
      {(itineraryEvent.formattedDate || itineraryEvent.startTime) && (
        <Grid container className={classes.dateTimeBox} pt={1}>
          {itineraryEvent.formattedDate && (
            <Grid item className={classes.date} data-test-id="itinerary-event-date">
              {<DateRange className={classes.dateIcon}/>} {itineraryEvent.formattedDate}
            </Grid>
          )}

          <Grid item className={classes.date} data-test-id="itinerary-event-time">
            {!itineraryEvent.startTime ? (
              <Link
                onClick={() => onEditTimeClick('add time link')}
                underline="hover"
                className={classes.addTimeLink}
                sx={{position: 'relative', top: '4px'}}
                data-test-id="add-time-link"
              >
                <Grid container alignItems="center">
                  <MoreTimeIcon className={classes.timeIcon}/>
                  <Typography className={classes.addTimeText}>Add a time</Typography>
                </Grid>
              </Link>
            ) : (
              <>
                {<AccessTimeIcon className={classes.dateIcon}/>}
                {renderTimeText()}
              </>
            )}
          </Grid>
        </Grid>
      )}

      {renderButtons(itineraryEvent)}
    </ItineraryEventDialog>
  );
};

export default ItineraryActivityEventDialog;
