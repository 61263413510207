import React, { useContext } from "react";
import { useTheme } from "@mui/styles";
import Button from "../../buttons/Button";
import { captureException } from "@sentry/minimal";
import { track as trackAnalyticsEvent } from '../../../services/analytics';
import { Typography } from "@mui/material";
import { SegmentContext } from "../../Document";
import useMediaQuery from "@mui/material/useMediaQuery";

type ShareSheetButtonProps = {
  className?: string,
  title: string,
  url: string,
};

const ShareSheetButton = ({
  className,
  url,
  title,
}: ShareSheetButtonProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { currentUserId, isPlanner, tripId } = useContext(SegmentContext);

  const trackSharesheetActivated = (success: boolean) => {
    trackAnalyticsEvent('Sharesheet Menu Activated', {
      planner: isPlanner,
      tripId,
      userId: currentUserId,
      success,
    });
  };

  const shareContent = () => {
    const text = `${url}\n I'm using Troupe to plan our upcoming trip! Create an account and RSVP to help the group decide what we should do.`;
    navigator
      .share({title, text})
      .then(() => {
        trackSharesheetActivated(true);
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          trackSharesheetActivated(false);
          console.log('Could not share', error);
        } else {
          captureException(error)
        }
      });
  };

  const trackShareSheetClickInSegment = () => {
    trackAnalyticsEvent('Invite Completed', {
      category: 'Invites',
      method: 'Sharesheet',
      tripId: tripId,
    });
  };

  const shareInviteLink = () => {
    trackShareSheetClickInSegment();
    shareContent();
  };

  return (
    <>
      { navigator['share'] &&
        <Button
          className={className}
          onClick={() => shareInviteLink()}
          data-test-id="share-sheet-button"
          variant="secondary"
        >
          <img
            height="30px"
            src="/assets/share_icon.svg"
            width="22px"
          />
          { isMobile &&
            <Typography variant='h3' pl={1}>
              Send
            </Typography>
          }
        </Button>
      }
    </>
  )
};

export default ShareSheetButton;
