import React from "react";
import withLayout from "./withLayout";
import MainContent from "./SuggestedActivityGroupsPage/MainContent";
import { TripType } from "../../../shared/TripTypes";
import { Counter } from "./shared/ActivitiesSectionNavigation";
import SideBarPage from "../../../shared/SideBarPage";
import { ActivityGroupType } from "../../../shared/TripObjectTypes";

type SuggestedActivityGroupsPageProps = {
  activityGroupList: ActivityGroupType[],
  sortingContextProps: SortingContextType,
  tabsCounter: Counter,
  trip: TripType,
};

type SortingType = {
  label: string,
  sortBy: string,
};

type SortingContextType = {
  currentSorting: string,
  currentSortingLabel: string,
  sortings?: SortingType[],
};

export const SortingContext = React.createContext<SortingContextType>({
  currentSorting: '',
  currentSortingLabel: '',
  sortings: null,
});

const SuggestedActivityGroupsPage = ({
  activityGroupList,
  sortingContextProps,
  tabsCounter,
  trip,
}: SuggestedActivityGroupsPageProps) => {
  return (
    <SortingContext.Provider value={sortingContextProps}>
      <SideBarPage
        mainContent={
          <MainContent
            tabsCounter={tabsCounter}
            tripId={trip.id}
            activityGroupList={activityGroupList}
          />
        }
      />
    </SortingContext.Provider>
  );
};

export default withLayout(SuggestedActivityGroupsPage, { fluid: true });
