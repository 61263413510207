import React from "react";
import { useTheme } from "@mui/styles";
import {
  Hidden,
  useMediaQuery,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListIcon from '@mui/icons-material/List';
import FloatingButton from "../../buttons/FloatingButton";
import useListViewEventRef from "../../../services/segmentEvents/useListViewEventRef";

type MobileListButtonProps = {
  onClick: () => void,
  source: string,
};

const MobileListButton = ({
  onClick,
  source,
}: MobileListButtonProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const listButtonPlacement = isMobile ? 'bottom' : 'top-left';
  const listViewButtonRef = useListViewEventRef(source);

  return (
    <FloatingButton onClick={onClick} placement={listButtonPlacement} ref={listViewButtonRef}>
      <Hidden mdDown><ChevronLeftIcon /> Back to list</Hidden>
      <Hidden mdUp><ListIcon /> List</Hidden>
    </FloatingButton>
  );
};

export default MobileListButton;
