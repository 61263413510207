import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

type RankingLabelProps = {
  ranking: number,
};

const useStyles = makeStyles((theme) => ({
  choiceLabel: {
    background: '#6f42c1',
    position: 'absolute',
    color: '#fff',
    borderRadius: '3px',
    display: 'inline',
    fontSize: theme.spacing(2),
    fontWeight: 600,
    lineHeight: 0.75,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 2,
    top: 18,
  },
  topChoice: {
    background: '#1ebe95',
  },
  crown: {
    paddingRight: theme.spacing(1),
  },
}))

export const RankingLabel = ({
  ranking,
}: RankingLabelProps ) => {
  const classes = useStyles();
  const isGroupFavorite = ranking === 1
  let labelText;

  switch (ranking) {
    case 1:
      labelText = "Group favorite"
      break;
    case 2:
      labelText = "2nd place"
      break;
    case 3:
      labelText = "3rd place"
      break
    default:
      labelText = null;
  }

  return (
    <Typography data-test-id="choiceLabel">
      <span className={clsx(
        classes.choiceLabel,
        { [classes.topChoice]: ranking === 1 },
      )}>
        { isGroupFavorite && (<img className={classes.crown} src="/assets/illustrations/Crown.svg"/>)}
        { labelText }
      </span>
    </Typography>
  );
};

export default RankingLabel;
