import React, { useState } from "react";
import {
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  ActivityCategoryType,
  ActivityType,
} from "../../shared/TripObjectTypes";
import { Theme } from '@mui/material/styles';
import makeStyles from "@mui/styles/makeStyles";
import { hocRailsAction } from "../../Document";
import Errors from "../../shared/Errors";
import ModalForm from "../../shared/ModalForm";
import InputWithCharacterCounter from '../../shared/InputWithCharacterCounter';
import useStateWithPromise from "../../../services/useStateWithPromise";
import { Place } from "../../shared/GooglePlacesAutocomplete";
import ImageUploader from '../../shared/ImageUploader';
import ActivityLocationField from "./ActivityLocationField";
import * as routes from '../../../routes';
import ActivityPriceField from "./ActivityForm/ActivityPriceField";
import {
  ObjectFit,
  ObjectFitProps,
} from "./NewActivityForm";
import CommentField from '../trips/microsite/shared/CommentField'
import CategoryLabel from "../trips/microsite/shared/CategoryLabel";

export const categoryList: Array<ActivityCategoryType> = [
  'Arts',
  'Classes',
  'Cruises',
  'Entertainment',
  'Food',
  'Landmarks',
  'Nightlife',
  'Outdoors',
  'Recreation',
  'Shopping',
  'Tours',
  'Wellness',
]

const {
  tripUpdateActivityPath,
  tripActivitiesPath,
} = routes;

type ActivityFormProps = {
  activity: ActivityType,
  errors?: string[],
  tripId: string,
  shouldFocusPrice: boolean,
  showCommentField?: boolean,
};

const useStyles = makeStyles<Theme, ObjectFitProps>((theme) => ({
  activityImage: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    '& img':{
      objectFit: ({objectFit}) => objectFit,
    },
  },
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  categorySelect: {
    height: '56px',
  },
}));

const ActivityForm = ({
  activity,
  errors,
  tripId,
  shouldFocusPrice = false,
  showCommentField = false,
}: ActivityFormProps) => {
  const [imageUrl, setImageUrl] = useState(activity.image);
  const [objectFit, setObjectFit] = useState<ObjectFit>('fill');

  const classes = useStyles({objectFit});
  const [location, setLocation] = useStateWithPromise({});
  const fallbackSource = "/assets/activity_fallback.svg";
  const [selectedCategory, setSelectedCategory] = useState<ActivityCategoryType | ''>(activity.category || '')
  const CHARACTER_LIMIT = 100;

  const handleLocationChange = (place: Place) => {
    if (place) {
      setLocation({
        location_name: place.name,
        google_place_id: place.id,
        google_place_url: place.url,
        image: place.image,
        latitude: place.latitude,
        longitude: place.longitude,
      });
    };
  };

  const handleCategoryClick = ( event ) => {
    if(event.target.textContent === selectedCategory) {
      setSelectedCategory("")
    }
  }

  const handleCategoryChange = ( event ) => {
    const category = event.target.value
    if (category) {
      setSelectedCategory(category)
    }
  }

  const handleImageUpload = (url) => {
    setImageUrl(url)
    setObjectFit(url === fallbackSource ? 'fill' : 'cover')
  }

  return (
    <ModalForm
      closePath={tripActivitiesPath(tripId)}
      formActionPath={tripUpdateActivityPath(tripId, activity.id)}
      formMethod="patch"
      title="Edit activity"
      data-test-id='edit-activity'
      subtitle="Edit the details for this activity."
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Errors errors={errors} />
        </Grid>
        <Grid item>
          <InputWithCharacterCounter
            defaultValue={activity.name}
            name="activity[name]"
            label="Activity name"
            focused
            fullWidth
            characterLimit={CHARACTER_LIMIT}
          />
        </Grid>
        <Grid item className={classes.activityImage} data-test-id='imageUploader'>
          <ImageUploader
            defaultImage={fallbackSource}
            inputName="activity[image]"
            image={activity.image}
            onImageUpload={handleImageUpload}
            onImageRestoreDefault={() => setObjectFit('fill')}
            originalImages={[activity.originalImage]}
          />
        </Grid >
        <Grid item>
          <Typography className={classes.label}>
            Link
          </Typography>
          <TextField
            fullWidth
            id="activity_link"
            variant="outlined"
            name="activity[link]"
            defaultValue={activity.link}
            placeholder={"Paste your link here"}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.label}>
            Google Maps location
          </Typography>
          <ActivityLocationField
            currentGooglePlaceId={activity.googlePlaceId}
            currentGooglePlaceUrl={activity.googlePlaceUrl}
            currentLocationName={activity.locationName}
            currentLatitude={activity.latitude}
            currentLongitude={activity.longitude}
            onChange={handleLocationChange}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.label}>
            Category
          </Typography>
          <Select
            displayEmpty
            fullWidth
            className={classes.categorySelect}
            value={selectedCategory}
            data-test-id="activity-category"
            name='activity[category]'
            onChange={handleCategoryChange}
            onClick={handleCategoryClick}
            input={<OutlinedInput />}
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) => {
              if (!selected || selectedCategory === '') {
                return <MenuItem disabled>Select an activity category</MenuItem>;
              } else {
                return <CategoryLabel selectBar category={selectedCategory}/>
              }
            }}
          >
            {categoryList.map((category) => {
              return (
                <MenuItem
                  value={category}
                  key={`${category}-category-option`}
                  data-test-id={`${category}-category-option`}
                >
                  {category}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>
        <ActivityPriceField price={activity.price} shouldFocusPrice={shouldFocusPrice}/>
        { showCommentField &&
          <CommentField
            fieldName='activity[comments_attributes][0][body]'
           />
        }
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(ActivityForm);
