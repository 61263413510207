import React, { useCallback } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Errors from "../../../shared/Errors";
import { hocRailsAction } from "../../../Document";
import Button from "../../../buttons/Button";
import ModalForm from "../../../shared/ModalForm";
import { trackLink as trackAnalyticsEvent } from '../../../../services/analytics';

type DestroyConfirmationChosenTripObjectPageType = {
  chosenTripObjectId: string,
  chosenTripObjectPath: string,
  errors: string[],
  indexPath: string,
  isSkippedVotingFinalized: boolean,
  type: 'stay' | 'destination' | 'date_option',
  tripId: string,
};

const usePageStyles = makeStyles(theme => ({
  errorsContainer: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(4),
  },
}));

const DestroyConfirmationChosenTripObjectPage : React.FC<DestroyConfirmationChosenTripObjectPageType> = ({
  chosenTripObjectId,
  chosenTripObjectPath,
  errors,
  indexPath,
  isSkippedVotingFinalized = false,
  type,
  tripId,
}) => {
  const classes = usePageStyles();
  const objectType = type === 'date_option' ? 'dates' : type;

  const refs = useCallback((element) => {
    if (element !== null) {
      trackAnalyticsEvent(
        element,
        'Transitioned back to voting',
        {
          tripId: tripId,
        },
      );
    }
  }, []);

  const subtitle = isSkippedVotingFinalized ?
    `Your confirmed ${objectType} will be reset and travelers will be able to suggest new options to the group.` :
    `Your confirmed ${objectType} will be reset and travelers will be able to vote on the options originally suggested to the group.`;

  return (
    <ModalForm
      closePath={indexPath}
      formActionPath={chosenTripObjectPath}
      formMethod="DELETE"
      subtitle={subtitle}
      title={`Are you sure you want to change your ${objectType}?`}
      useDefaultSaveButton={false}
      withChangeDetection={false}
    >
       <input type="hidden" value={chosenTripObjectId} name="trip_object_id" />
      <Errors className={classes.errorsContainer} errors={errors} />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12}>
          <Button
            href={indexPath}
            className={classes.button}
            fullWidth
            variant="secondary"
          >
            No, keep {objectType}
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            className={classes.button}
            type="submit"
            fullWidth
            variant="primary"
            data-test-id="confirmationButton"
            ref={(element) => refs(element)}
          >
            Yes, change {objectType}
          </Button>
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(DestroyConfirmationChosenTripObjectPage);
