import React from "react";
import StaysSearch, { CustomFiltersAppliedType, DateOptionProps } from "../../stays_search/StaysSearch";
import withAddStayPageLayout from "./withAddStayPageLayout";
import { DestinationType } from "../../../shared/TripObjectTypes";
import { addBySearchTripStaysPath, apiTripSearchListingsPath, tripStaysPath } from "../../../../routes";
import { SegmentContext } from "../../../Document";
import ExternalListingForm from "../../stays_search/ExternalListingForm";

type SearchFormProps = {
  currentAppliedFilters: {
    locationName?: string,
  },
  customFiltersApplied: CustomFiltersAppliedType,
  dateOptions: DateOptionProps[],
  topSuggestedDestinations: DestinationType[],
};

const SearchForm = ({
  currentAppliedFilters,
  customFiltersApplied,
  dateOptions,
  topSuggestedDestinations,
}: SearchFormProps) => {
  const { tripId } = React.useContext(SegmentContext);

  const basePath = addBySearchTripStaysPath(tripId);
  const apiSearchPath = apiTripSearchListingsPath(tripId);

  return (
    <StaysSearch
      currentAppliedFilters={currentAppliedFilters}
      customFiltersApplied={customFiltersApplied}
      dateOptions={dateOptions}
      topSuggestedDestinations={topSuggestedDestinations}
      basePath={basePath}
      apiSearchPath={apiSearchPath}
      renderCtaButton={({stay}) => {
        const resourcePath = tripStaysPath(tripId);

        return <ExternalListingForm
          staysPath={resourcePath}
          canAddToTrip={!stay.alreadyOnTrip}
          fullWidth
          id={stay.id}
          url={stay.originalUrl}
          source="SERP search"
        />
      }}
      SearchBackground={({children}) => <div>{children}</div>}
    />
  );
};

export default withAddStayPageLayout(SearchForm);
