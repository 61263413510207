import React from 'react';
import { useTrackSERPEvent } from "./useSERPEvents";

const useListViewEventRef = (vertical) => {
  const { trackSERPLinkEvent } = useTrackSERPEvent();

  const buttonRef = React.useCallback((element) => {
    if (element !== null) {
      trackSERPLinkEvent(
        element,
        'List View Clicked',
        {
          vertical,
        },
      );
    }
  }, []);

  return buttonRef;
};

export default useListViewEventRef;
