import React, { useContext, useState } from "react";
import {
  Collapse,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";
import { GlobalContext, TripSiteContext } from "../../Document";
import Button from "../../buttons/Button";
import { useMutation } from "@apollo/client";
import { AI_SEARCH_FEEDBACK_CREATE } from "../../../graphql/queries/aiSearchFeedbacks";
import SearchFeedbackDialog from "./SearchFeedbackDialog";
import useSegment from "../../../services/useSegment";
import { aiSearchType, CurrentAppliedFilters } from "../stays_search/Listings/Filters";
import {useTrackSERPEvent} from "../../../services/segmentEvents/useSERPEvents";

type SearchFeedbackBox = {
  modelRequestId: string,
  filters?: CurrentAppliedFilters,
  aiSearchType?: aiSearchType,
  resultCount?: number,
};

const useStyles = makeStyles((theme) => ({
  searchFeedbackBox: {
    width: '100%',
    padding: theme.spacing(1, 2),
    backgroundColor: '#EEEFFF',
    borderRadius: '8px',
    border: '1px solid #BEC9D4',
  },
  button: {
    borderRadius: '5px',
    border: '1px solid #545DFC',
    background: '#FFFFFF',
    color: '#545DFC',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '24px',
    width: '100%',
    height: 'auto',
    padding: '6px 8px',
  },
  copy: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
  },
  closeButton: {
    padding: 0,
    lineHeight: '20px',
    '& svg': {
      fontSize: 16,
      color: '#9DAFBE',
    },
  },
  shareLink: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

const SearchFeedbackBox = ({
  modelRequestId,
  filters,
  aiSearchType,
  resultCount,
}: SearchFeedbackBox) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true)
  const [responseId, setResponseId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { setSnackbar } = useContext(GlobalContext);
  const { tripGid : tripId, currentUserGid : userId } = useContext(TripSiteContext);
  const [createAiSearchFeedback] = useMutation(AI_SEARCH_FEEDBACK_CREATE);
  const { trackSERPEvent } = useTrackSERPEvent();

  const copy = responseId ? (
    <>
      Thanks for sharing your feedback! <br />
      Any suggestions? <Link data-test-id="shareLink" className={classes.shareLink} onClick={() => setDialogOpen(true)}>Share here.</Link>
    </>
  ) : "How’s your search experience with Troupie?";

  const onResponseButtonClick = (response : string) => {
    trackSERPEvent('Troupie Feedback CTA Clicked',
      {
        location: aiSearchType,
        ctaClicked: response,
        destination: filters.locationName,
        dates: filters.customDatesFilterApplied,
        guestCount: filters.minGuests,
        filtersApplied: filters,
        resultCount: resultCount,
      },
    )
    createAiSearchFeedback({
      variables: {
        userId,
        tripId,
        response,
        modelRequestId,
      },
      onCompleted: ({
        aiSearchFeedbackCreate: {
          aiSearchFeedback,
          errors,
        },
      }) => {
        if(aiSearchFeedback.id && errors.length === 0) {
          setResponseId(aiSearchFeedback.id);
        } else {
          setSnackbar('Something went wrong.', 3000, 'error');
        }
      },
    });
  }

  const onDialogSubmit = () => {
    setDialogOpen(false);
    setOpen(false);
    setSnackbar('Success! Your feedback was submitted.');
  };

  const responseButton = (response : string) => (
    <Grid item flexGrow={1}>
      <Button
        className={classes.button}
        data-test-id="responseButton"
        onClick={() => onResponseButtonClick(response)}
      >
        {response}
      </Button>
    </Grid>
  );

  return (
    <Collapse in={open}>
      <Grid container data-test-id='searchfeedback-box' direction="column" justifyContent="space-between"
        className={classes.searchFeedbackBox}
      >
        <Grid item container alignItems="flex-start" justifyContent="space-between" flexWrap="nowrap">
          <Grid item>
            <Typography className={classes.copy}>{copy}</Typography>
          </Grid>
          <Grid item>
            <IconButton className={classes.closeButton} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        {!responseId && (
          <Grid item container justifyContent="space-between" spacing={1} mt={2}>
            {responseButton('Helpful')}
            {responseButton('Somewhat helpful')}
            {responseButton('Not helpful')}
          </Grid>
        )}
      </Grid>
      <SearchFeedbackDialog
        onClose={() => setDialogOpen(false)}
        onSubmit={onDialogSubmit}
        open={dialogOpen}
        responseId={responseId}
      />
    </Collapse>
  );
};

export default SearchFeedbackBox;
