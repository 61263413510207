import React, { Component } from 'react';
import { withStyles } from "@mui/styles";
import Link from "./Link";
import clsx from "clsx";
import Linkify from "./Linkify";

type ReadMoreProps = {
  text: string,
  readMoreCharacterLimit: number,
  classes: {
    visibleText: string,
    hiddenText: string,
    readMoreButton: string,
    seeMoreLink: string,
  },
  showMoreText: string,
  showLessText: string,
  lineHeight: number,
};

type ReadMoreState = {
  showingAll: boolean,
};

const useStyles = (theme) => ({
  readMoreButton:  {
    display: 'inline',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 0,
    padding: 0,
    margin: 0,
  },
  hiddenText: {
   maxHeight: 0,
   fontSize: 0,
   opacity: 0,
  },
  visibleText: {
    maxHeight: '10em',
    opacity: 1,
    fontSize: 'inherit',
  },
  seeMoreLink: {
    bottom: '1.2px',
    fontWeight: 600,
    paddingLeft: theme.spacing(1),
  },
});

class ReadMore extends Component<ReadMoreProps, ReadMoreState> {
  static defaultProps = {
    showMoreText: 'see more',
    showLessText: 'see less',
    lineHeight: 1,
    readMoreCharacterLimit: 100,
  }

  constructor(props) {
    super(props);

    let { text, readMoreCharacterLimit } = this.props;

    this.state = { showingAll: text.length <= readMoreCharacterLimit };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { text, readMoreCharacterLimit } = this.props;

    if (readMoreCharacterLimit !== prevProps.readMoreCharacterLimit) {
      this.setState({
        showingAll: text.length <= readMoreCharacterLimit,
      });
    }
  }

  textExceedsLimit = () => {
    let { text, readMoreCharacterLimit } = this.props;

    return text.length > readMoreCharacterLimit;
  }

  toggleReadMore = () => {
    this.setState({
      showingAll: !this.state.showingAll,
    });
  }

  getReadMoreParts = ({text, readMoreCharacterLimit}) => {
    let teaserText;
    let remainingText;
    let remainingWordsArray = [];

    if (text) {
      const teaserWordsArray = text.split(' ');

      while (teaserWordsArray.join(' ').length > readMoreCharacterLimit) {
        remainingWordsArray.unshift(teaserWordsArray.pop());
      }

      teaserText = teaserWordsArray.join(' ');

      if (remainingWordsArray.length > 0) {
        remainingText = ' ' + remainingWordsArray.join(' ');
      }
    }

    return {
      teaserText,
      remainingText,
    };
  };

  getText = ({ showingAll, text, readMoreCharacterLimit }) => {
    const { classes } = this.props;
    let {
      teaserText,
      remainingText,
     } = this.getReadMoreParts({text, readMoreCharacterLimit});

    if (!showingAll && this.textExceedsLimit()) {
      return (
        <span>
          <Linkify>
            {teaserText.replace(/\s*$/, "… ")}
          </Linkify>
          <span className={classes.hiddenText}>
            <Linkify>
              {remainingText}
            </Linkify>
          </span>
        </span>
      );
    }

    return (
        <span>
          <Linkify>
            {teaserText}
          </Linkify>
          <span className={classes.visibleText}>
            <Linkify>
              {remainingText}
            </Linkify>
          </span>
        </span>
    );
  }

  getActionButton = ({ showingAll }) => {
    if (showingAll && !this.textExceedsLimit()) {
      return;
    }

    const { classes, showMoreText, showLessText } = this.props;

    const disableRippleEffectOnParent = (event) => {event.stopPropagation();}

    let linkText = showingAll ? showLessText : showMoreText;

    return <Link
      onClick={this.toggleReadMore}
      className={clsx(classes.readMoreButton, classes.seeMoreLink)}
      text={`${linkText}`}
      data-element="link"
      onMouseDown={disableRippleEffectOnParent}
    />;
  }

  render() {
    let {
      text,
      readMoreCharacterLimit,
      lineHeight,
    } = this.props;

    let { showingAll } = this.state;

    let style = { lineHeight };

    let textToDisplay = this.getText({
      showingAll,
      text,
      readMoreCharacterLimit,
    });

    let actionButton = this.getActionButton({
      showingAll,
    });

    return (
      <span style={style}>
        {textToDisplay}{actionButton}
      </span>
    )
  }
}

export default withStyles(useStyles)(ReadMore);
