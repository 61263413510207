import React, { useState } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { hocRailsAction } from "../../Document";
import Errors from "../../shared/Errors";
import ModalForm from "../../shared/ModalForm";
import ImageUploader from '../../shared/ImageUploader';
import RichTextEditor from '../../shared/RichTextEditor';
import InputWithCharacterCounter from '../../shared/InputWithCharacterCounter';
import * as routes from '../../../routes';
import { GlobalTrip } from "../../shared/TripTypes";
import { EstimatedTravelerCount, UpperImage } from "./NewCreationForm/ThirdStepTripForm";
import TripTypesSelection from './NewCreationForm/TripTypesSelection';

type RenewedTripFormEditPageProps = {
  trip?: GlobalTrip,
  currentUserId?: string,
  errors: string[],
};

const {
  tripPath,
} = routes;

const useStyles = makeStyles((theme) => ({
  headLine: {
    marginTop: theme.spacing(3),
  },
  title: {
    textAlign: 'center',
  },
  blurbWrapper: {
    marginTop: theme.spacing(2),
  },
  uploaderContainer: {
    marginTop: 12,
  },
  tripName: {
    lineHeight: '24px',
    marginBottom: theme.spacing(1),
  },
  tripNameInput : {
    borderWidth: '1px',
  },
}));

const RenewedTripFormEditPage = ({
  trip,
  errors = [],
}: RenewedTripFormEditPageProps) => {
  const [description, setDescription] = useState(trip?.blurb);
  const classes = useStyles();
  const closePath = () => ( tripPath(trip.id) );

  const formActionPath = tripPath(trip.id);

  const defaultTripName = trip.name || 'Untitled trip';
  const setDefaultName = (field) => {
    if (field.value.trim() === '') {
      field.value = defaultTripName;
    }
  };
  const CHARACTER_LIMIT = 100;

  return (
    <ModalForm
      title={"Trip details"}
      titleClassName={classes.title}
      closePath={closePath()}
      formActionPath={formActionPath}
      formMethod={"patch"}
      saveButtonLabel="Save trip"
      upperImage={<UpperImage />}
    >
      <Grid container spacing={2} direction="column">
        {errors?.length > 0 && (
          <Grid item>
            <Errors errors={errors} />
          </Grid>
        )}
        <Grid item>
          <Typography variant="h3" className={classes.tripName}>
            Trip name
          </Typography>
          <InputWithCharacterCounter
            characterLimit={CHARACTER_LIMIT}
            className={classes.tripNameInput}
            defaultBlurValue={defaultTripName}
            defaultValue={defaultTripName}
            focused
            fullWidth
            noLabel
            name="trip[name]"
            onTextFieldBlur={setDefaultName}
          />
        </Grid>
      </Grid>
      <div data-test-id="tripDescription" className={classes.blurbWrapper}>
        <RichTextEditor
          defaultValue={trip?.blurb}
          onChange={(data) => setDescription(data)}
          placeholder="What’s the plan for this trip? Feel free to add bullet points, drop in some links, or embed photos and videos right here for other travelers to see."
        />
        <input type="hidden" name="trip[blurb]" value={description} />
      </div>
      <TripTypesSelection tripTypes={trip.tripTypes} />
      <EstimatedTravelerCount estimatedTraveler={trip.estimatedTravelers} />
      <Grid container spacing={2} direction="column">
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h3" className={classes.headLine}>
              Add cover photo
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              Cover photos help your group get excited about where you’re headed.
            </Typography>
          </Grid>
          <Grid item className={classes.uploaderContainer}>
            <ImageUploader
              defaultImage='https://res.cloudinary.com/troupe/image/upload/c_fill,h_416,q_auto:good,w_1120/v1612306537/manual-trips/default/cover_agiagz.jpg'
              forcedAspectRatio={2.2}
              image={trip?.coverPhotos?.original}
              inputName="trip[image_url]"
              isFullSize
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalForm>
  );
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(RenewedTripFormEditPage);
