import React, { useContext, useRef, useReducer } from "react";
import {
  ButtonBase,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import withAddStayPageLayout from "./withAddStayPageLayout";
import Button from "../../../buttons/Button";
import { TripSiteContext } from '../../../Document';
import StayPreview from "../StayForm/StayPreview";
import useStateWithPromise from "../../../../services/useStateWithPromise";
import RailsForm from "../../../shared/RailsForm";
import { FormContext } from "../../../shared/RailsForm";
import {
  newTripBatchStaysPath,
  tripStaysPath,
} from '../../../../routes';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  centeredSection: {
    textAlign: 'center',
  },
  multipleLinksButton: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
  },
  noStayImage: {
    maxWidth: theme.spacing(50),
    width: '100%',
    paddingTop: theme.spacing(2),
  },
}));

export const UrlForm = () => {
  const [value, setValue] = useStateWithPromise({});
  const [addDetails, setAddDetails] = useStateWithPromise(false);
  const { tripId } = useContext(TripSiteContext);
  const inputRef = useRef(null);
  const classes = useStyles();

  const initialState = {
    hasAutomaticAvailability: false,
    showFetchErrorMessage: false,
    showActions: false,
  };

  const reducer = (state, action) => {
    switch(action.type) {
      case 'showFetchErrorMessage':
        return { showFetchErrorMessage: true, showActions: false }
      case 'showActions':
        return { showFetchErrorMessage: false, showActions: true, hasAutomaticAvailability: action.hasAutomaticAvailability }
      case 'hideAll':
        return { showFetchErrorMessage: false, showActions: false }
      case 'resetForm':
        setValue({}).then(() => inputRef.current.focus());
        return initialState;
      default:
        throw new Error();
    }
  };

  const [{ hasAutomaticAvailability, showFetchErrorMessage, showActions }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  let stayPreviewParams;

  if (value.url) {
    stayPreviewParams = { url: value.url };
  } else {
    stayPreviewParams = {};
  }

  const saveButtonLabel = hasAutomaticAvailability ? "Save & add details" : "Save & add pricing";

  return (
    <RailsForm
      action={tripStaysPath(tripId)}
      method="post"
    >
      <Grid container justifyContent='center'>
        {addDetails && (
          <input type="hidden" name="add_details" value="true" />
        )}
        <FormContext.Consumer>
          {({ onFormChange, submit }) => (
            <Grid
              container
              direction="column"
              spacing={2}
              sx={{ maxWidth: '800px' }}
            >
              <Grid item>
                <TextField
                  error={showFetchErrorMessage}
                  helperText={showFetchErrorMessage ? 'URL is invalid' : ''}
                  fullWidth
                  value={value.url}
                  id="stay_url"
                  inputRef={inputRef}
                  InputProps={{
                    endAdornment: !!value.url && (
                      <IconButton onClick={() => setValue({ url: "" }).then(() => onFormChange())}>
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  label="Link to listing"
                  name="stay[url]"
                  onChange={(event) => setValue({ url: event.target.value.trim() })}
                  placeholder="Paste your link here"
                  required
                  variant="outlined"
                  sx={{marginBottom: '8px'}}
                />
                <Grid pt={2}>
                  <StayPreview
                    onError={() => dispatch({ type: 'showFetchErrorMessage' })}
                    onFetch={(hasAutomaticAvailability) => dispatch({ type: 'showActions', hasAutomaticAvailability: hasAutomaticAvailability })}
                    onLoading={() => dispatch({ type: 'hideAll' })}
                    onRemove={() => dispatch({ type: 'resetForm' })}
                    resourcePath={tripStaysPath(tripId)}
                    tripId={tripId}
                    { ...stayPreviewParams }
                  />
                </Grid>
                <ButtonBase
                  data-test-id="multiple-links"
                  href={newTripBatchStaysPath(tripId)}
                  className={classes.multipleLinksButton}
                >
                  Add multiple links
                </ButtonBase>
                {showFetchErrorMessage && (
                  <Grid item className={classes.centeredSection}>
                    <img
                      className={classes.noStayImage}
                      alt="No preview found"
                      src={`/assets/illustrations/camper.svg`}
                    />
                    <Typography>
                      We can't preview that URL.
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {showActions && (
                <>
                  <Grid item>
                    <Button
                      data-test-id="save-and-add"
                      fullWidth
                      variant="primary"
                      onClick={() => setAddDetails(true).then(() => submit())}
                      type="button"
                    >
                      {saveButtonLabel}
                    </Button>
                  </Grid>
                  <Grid item pb={4}>
                    <Button
                      data-test-id="save-now"
                      fullWidth
                      variant="tertiary"
                      onClick={submit}
                      type="button"
                    >
                      Save now
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </FormContext.Consumer>
      </Grid>
    </RailsForm>
  );
};

export default withAddStayPageLayout(UrlForm);
