import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
  interface DefaultTheme extends Theme {}
}

const primaryTextColor = '#272727';
const primaryColor = '#545DFC';

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    success: {
      main: '#7dcab6',
    },
    general: {
      primary: primaryColor,
      danger: '#ab3e4a',
      dark: '#244599',
      gray: '#69737a',
      main: primaryColor,
      success: '#4fb494',
      background: {
        disabled: '#b4b9bc',
      },
      hover: '#F8F7FF',
    },
    background: {
      default: 'white',
    },
    link: {
      default: primaryColor,
      hover: '#3B41B0',
      main: primaryColor,
      pressed: '#244599',
      focused: '#395db2',
      disabled: '#b4b9bc',
    },
    text: {
      primary: primaryTextColor,
      secondary: '#555e64',
      disabled: '#b4b9bc',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: undefined,
      height: 40,
      fontWeight: 600,
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
  },
  breakpoints: { // Keep MUI v4 breakpoint values
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        button: {
          fontFamily: 'Poppins',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:disabled': {
            WebkitTextFillColor: '#b4b9bc',
            WebkitOpacity: 1,
            color: '#b4b9bc',
          },
        },
      },
    },
    MuiTypography: {
      variants: [{
        props: {
          color: "secondary",
        },
        style: {
          color: primaryTextColor,
        },
      }],
    },
  },
});

export default theme;
