import React from "react";
import HoverBorderIconButton from "../buttons/HoverBorderIconButton";
import { GlobalContext } from "../Document";
import useSegment from "../../services/useSegment";

type ShareButtonProps = {
  className?: string,
  url: string,
};

const ShareButton = ({
  className,
  url,
}: ShareButtonProps) => {
  const { shareStay } = React.useContext(GlobalContext);
  const { trackSegment } = useSegment();

  const onButtonClick = () => {
    const text = `Check out this cool stay!\n\n${url}`;
    navigator
      .share({ text })
      .then(() => {
        trackSegment('Stay Share Activated', { listingUrl: url, page: window.location.pathname });
      })
  };

  if (!shareStay || !navigator['share']) return null;

  return (
    <HoverBorderIconButton
      onClick={onButtonClick}
      className={className}
    >
      <img src="/assets/share_icon.svg" />
    </HoverBorderIconButton>
  );
};

export default ShareButton;
