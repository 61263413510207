import React from 'react';
import { makeStyles } from '@mui/styles';
import { track as trackAnalyticsEvent } from "../../services/analytics";
import {Grid, Typography} from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";

type LoadMoreCommentsButtonProps = {
  commentsPath: string,
  tripId: string,
};

const useLoadMoreCommentsButtonStyles = makeStyles((theme) => ({
  root: {
    color: '#69737a',
    fontSize: 16,
    fontWeight: 500,
    justifyContent: 'left',
    lineHeight: 1.29,
    whiteSpace: 'nowrap',
    padding: '0 8px 0 8px',
    '&:hover': {
      color: '#69737a',
    },
  },
  loadCommentsButton: {
    [theme.breakpoints.up("md")]: {
      padding: '14px',
    },
    height: '40px',
    '&:hover': {
      backgroundColor: '#F6F6F6',
    },
    borderRadius: '6px',
  },
}));

const LoadMoreCommentsButton = ({
  commentsPath,
  tripId,
}: LoadMoreCommentsButtonProps) => {
  const classes = useLoadMoreCommentsButtonStyles();
  const trackClickedComments = () => {
    trackAnalyticsEvent('Load More Comments Clicked', {
      property: {
        tripObjectId: tripId,
      },
    });
  };

  return (
    <Grid item xs={12} sm={3}>
      <ButtonBase
        data-test-id='loadMoreCommentsButton'
        href={commentsPath}
        className={classes.loadCommentsButton}
        onClick={() => trackClickedComments()}
      >
        <Typography
         className={classes.root}
        >
          Load more comments
        </Typography>
      </ButtonBase>
    </Grid>
  );
}

export default LoadMoreCommentsButton;
