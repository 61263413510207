import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CardGroupCarouselContext from "./CardGroupCarousel/Context";

export type Item = {
  card: any,
  renderCardFunc: (card) => React.ReactNode,
};

export type CardGroupCarouselProps = {
  items: Item[],
  fullWidth?: boolean,
};

type StyleProps = {
  cardsScrollContainerWidth: number,
  cardWidth: number,
  fullWidth: boolean,
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  cardsContainerOuter: {
    position: "relative",
    overflow: "hidden",
    overflowX: "scroll",
    msOverflowStyle: "none",
    '&::-webkit-scrollbar': {
      width: [[0, '!important']],
      display: 'none',
    },
    scrollbarWidth: "none",
    scrollBehavior: "smooth",
  },
  cardsContainerInner: {
    position: "relative",
    width: ({ cardsScrollContainerWidth, fullWidth }) => fullWidth ? '100%' : cardsScrollContainerWidth,
    marginLeft: "auto",
    marginRight: "auto",
  },
  card: {
    float: "left",
    width: ({ cardWidth, fullWidth }) => fullWidth ? '100%' : cardWidth,
    "&:last-child": {
      marginRight: 0,
    },
  },
  cardSafeArea: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    marginTop: theme.spacing(3.75),
    marginBottom: theme.spacing(3.75),
  },
}));

const CardGroupCarousel = ({
  items,
  fullWidth = false,
}: CardGroupCarouselProps) => {
  const { currentStep, setCurrentStep, setMaxStep } = useContext(CardGroupCarouselContext);
  const cardsContainerWrapperEl = React.useRef<HTMLDivElement>();

  const [width, setWidth] = React.useState<number>(0);

  const minCardWidth = 320;
  const numCardsVisible = Math.floor(width / minCardWidth) || 1;
  const cardWidth = width / numCardsVisible;
  const totalCardsCount = items.length;

  setMaxStep(totalCardsCount - numCardsVisible);

  const classes = useStyles({
    cardWidth,
    cardsScrollContainerWidth: cardWidth * totalCardsCount,
    fullWidth: fullWidth,
  });

  if (cardsContainerWrapperEl && cardsContainerWrapperEl.current) {
    cardsContainerWrapperEl.current.scrollLeft = currentStep * (cardWidth);
  };

  const handleTouchStart = (event) => {
    const newIndex = Math.round(cardsContainerWrapperEl.current.scrollLeft / cardWidth);
    setCurrentStep(newIndex);
    cardsContainerWrapperEl.current.scrollLeft = currentStep * (cardWidth);
  };

  const handleTouchEnd = (event) => {
    const newIndex = Math.round(cardsContainerWrapperEl.current.scrollLeft / cardWidth);
    setCurrentStep(newIndex);
  };

  useEffect(() => {
    const refreshWindowDimensions = () => {
      if (cardsContainerWrapperEl.current) {
        setWidth(cardsContainerWrapperEl.current.offsetWidth);
      };
    };

    refreshWindowDimensions();
    window.addEventListener('resize', refreshWindowDimensions);
    return () => window.removeEventListener('resize', refreshWindowDimensions);
  });

  return (
    <Box className={classes.cardsContainerOuter} ref={cardsContainerWrapperEl}>
      <Box
        className={classes.cardsContainerInner}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {items.map((item, index) => (
          <Box
            className={classes.card}
            key={`carousel-item-${index}`}
          >
            <div className={classes.cardSafeArea}>
              {item.renderCardFunc(item.card)}
            </div>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CardGroupCarousel;
