import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

type NoSuggestionsYetProps = {
  className?: string,
  heading?: string,
  illustration?: string,
  subheading?: string | React.ReactNode,
  listItems?: string[],
};

const useNoSuggestionsStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
    maxWidth: theme.spacing(52),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  image: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
    width: '100%',
  },
  title: {
    color: '#272727',
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  message: {
    fontSize: 16,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      '& br': {
        display: 'none',
      },
    },
  },
  listItems: {
    fontSize: 16,
    textAlign: 'center',
    padding: 0,
    '& li': {
      listStyleType: 'decimal',
      listStylePosition: 'inside',
    },
  },
}))

const NoSuggestionsYet = ({
  className,
  heading,
  illustration,
  subheading,
  listItems,
}: NoSuggestionsYetProps) => {
  const classes = useNoSuggestionsStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {illustration && (
        <img
          className={classes.image}
          alt="No suggestions"
          src={`/assets/illustrations/${illustration}.svg`}
          data-test-id='no-suggestions-yet-img'
        />
      )}
      <Typography className={classes.title}>
        {heading}
      </Typography>
      {subheading && (
        <Typography className={classes.message}>
          {subheading}
        </Typography>
      )}
      {listItems && (
        <ul className={classes.listItems}>
          {listItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NoSuggestionsYet;
