import React from 'react'
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

type linkifyProps = {
  children: string;
};

const useStyles = makeStyles((theme) => ({
  linkify: {
    wordBreak: 'break-word',
  },
}));

const Linkify = ({
  children,
}: linkifyProps) => {
  const classes = useStyles();
  const linkify = new LinkifyIt();
  linkify.tlds(tlds);

  const replaceLink = (string, url, key) => {
    return (
      <a
        href={url}
        className={classes.linkify}
        target="_blank"
        rel="noopener noreferrer"
        key={key}
        data-test-id="replacedLink"
        data-element='link'>
        {string}
      </a>
    )
  }

  const stringParser = () => {
    if(!children) return;

    const matches = linkify.match(children);
    if(!matches) return children

    const elements = [];
    let lastIndex = 0;

    matches.forEach((match, index) => {
      if (match.index > lastIndex) {
        elements.push(children.substring(lastIndex, match.index));
      }

      elements.push(replaceLink(match.text, match.url, index))
      lastIndex = match.lastIndex;
    })

    if (children.length > lastIndex) {
      elements.push(children.substring(lastIndex));
    }

    return (elements.length === 1) ? elements[0] : elements;
  }

  return <>{stringParser()}</>
}

export default Linkify;
