import React from "react";
import clsx from "clsx";
import { Grid, Typography } from "@mui/material";
import { DefaultTheme, makeStyles } from "@mui/styles";

type StayPropertiesProps = {
  className?: string,
  stay: {
    bedroomsCount: number,
    maxGuests: number,
    propertyType: string,
  },
};

const useStyles = makeStyles((theme) => ({
  stayProperties: {
    fontSize: theme.typography.h3.fontSize,
  },
  dividerMargin: {
    marginLeft: '5px',
    marginRight: '5px',
    bottom: '2px',
    position: 'relative',
    color: theme.palette.general.gray,
  },
  guestFontSize: {
    fontSize: '14px',
  },
}));

const StayProperties = ({
  className,
  stay,
}: StayPropertiesProps) => {
  const classes = useStyles();
  const {
    bedroomsCount,
    maxGuests,
  } = stay;
  const propertyType = stay.propertyType === 'Private Vacation Home' ? 'Private Home' : stay.propertyType;

  const guests = maxGuests ? `${maxGuests} Guests max.` : null;
  const bedrooms = !bedroomsCount ? null : bedroomsCount + " BR";

  const setPropertiesText = () => (
    [propertyType, bedrooms, guests].filter(attribute => attribute).join(' | ')
  );

  return (
    <Grid item>
      <Typography className={clsx(className, classes.stayProperties)} data-test-id='stay-properties'>
        {setPropertiesText()}
      </Typography>
    </Grid>
  );
};

export default StayProperties;
