import React, { useCallback, useContext } from "react";
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import CommentIcon from '@mui/icons-material/Comment';
import { Broadcast } from "./BroadcastView";
import CommentsLink from "../shared/CommentsLink";
import ContainerLink from "../../../../shared/ContainerLink";
import ReadMore from '../../../../shared/ReadMore';
import Button from "../../../../buttons/Chip";
import { tripBroadcastPath } from '../../../../../routes';
import Reactions, { ReactionCounts } from "../../../../shared/Reactions/Reactions";
import { TripSiteContext } from "../../../../Document";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_REACTION, GET_REACTIONS, REMOVE_REACTION } from "../../../../../graphql/queries/reactions";
import { GET_USER_DISPLAY_NAME } from "../../../../../graphql/queries/users";
import { getOperationName } from "../../../../../graphql/utilities";
import clsx from "clsx";

type BroadcastBodyProps = {
  broadcast: Broadcast,
  displayCommentButton?: boolean,
  setComment?: () => void,
  showLink?: boolean,
  tripId: string,
  showReactions?: boolean,
};

const useStyles = makeStyles((theme) => ({
  commentButton: {
    height: '32px !important',
  },
  commentButtonContainer: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
    zIndex: 2,
    maxWidth: '100%',
  },
  containerLink: {
    width: '100%',
  },
  messageContainer: {
    border: '1px solid #C4C4C4',
    borderRadius: 5,
    marginTop: theme.spacing(2),
  },
  message: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  reactions: {
    position: 'absolute',
    bottom: theme.spacing(4),
    left: theme.spacing(2),
    zIndex: 2,
  },
  indexPageReactions: {
    zIndex: 2,
    margin: theme.spacing(1),
  },
}));

const BroadcastBody = ({
  broadcast,
  displayCommentButton = false,
  setComment = () => {},
  showLink = true,
  tripId,
  showReactions = true,
}: BroadcastBodyProps) => {
  const classes = useStyles();
  const { message, id } = broadcast;
  const { tripGid, currentUserGid } = useContext(TripSiteContext);

  const { loading, data } = (useQuery(GET_REACTIONS, {
    variables: { reactableId: broadcast.gid },
  })) || {};

  const { data: userData } = (useQuery(GET_USER_DISPLAY_NAME, {
    variables: { id: currentUserGid },
  })) || {};

  const refetchQueries = [getOperationName(GET_REACTIONS)];
  const [addReaction, { loading: addReactionLoading }] = useMutation(ADD_REACTION, { refetchQueries });
  const [removeReaction, { loading: removeReactionLoading }] = useMutation(REMOVE_REACTION, { refetchQueries });

  const onAddReaction = useCallback(({emoji, name}) => {
    addReaction({
      variables: {
        emoji,
        humanReadableName: name,
        reactableId: broadcast.gid,
        tripId: tripGid,
      },
    })
  }, [broadcast, tripGid]);
  const onRemoveReaction = useCallback(({emoji}) => {
    removeReaction({
      variables: {
        emoji,
        reactableId: broadcast.gid,
        tripId: tripGid,
      },
    })
  }, [broadcast, tripGid]);

  const reactions = data?.node?.reactions ?? [];
  const reactionCounts : ReactionCounts = reactions.reduce((acc, reaction) => ({
    ...acc,
    [reaction.emoji]: {
      ...reaction,
      users : reaction.users.map(user => user.displayName),
      name : reaction.humanReadableName,
    },
  }), {});

  const currentUserDisplayName = userData?.node?.displayName;

  const onContainerLinkClicked = (event) => {
    if (event.target.dataset['element'] === 'link' || event.target instanceof SVGPathElement) {
      event.preventDefault();
    }
  }

  const disableRippleEffectOnParent = (event) => {event.stopPropagation();}

  return (
    <ContainerLink
      className={classes.containerLink}
      href={tripBroadcastPath(tripId, id)}
      onClick={onContainerLinkClicked}
      showLink={showLink}
    >
      <Grid container direction="column" className={classes.messageContainer}>
        <Grid item>
          <Box sx={{ p: 4 }}>
            <Typography
              className={classes.message}
              data-test-id="broadcast-message"
            >
              <ReadMore
                text={message}
                lineHeight={1.4}
                readMoreCharacterLimit={300}
                showLessButton={true}
              />
            </Typography>
          </Box>
        </Grid>
        <Grid className={clsx({
            [classes.reactions]: showLink,
            [classes.indexPageReactions]: !showLink,
          })}>
          {!loading && showReactions && (
            <Reactions
              currentUserDisplayName={currentUserDisplayName}
              initialReactions={reactionCounts}
              onAddReaction={onAddReaction}
              onRemoveReaction={onRemoveReaction}
              disabled={addReactionLoading || removeReactionLoading}
              reactableId={broadcast.id}
              reactableType='Broadcast'
              isSelfReaction={broadcast.author.displayName === currentUserDisplayName}
              permapage={!showLink}
            />
          )}
        </Grid>
        {displayCommentButton && (
          <Grid item container direction="column" alignItems="flex-end" sx={{ px: 4, pb: 4 }}>
            <Grid item>
              <CommentsLink
                commentsLink={broadcast.commentsPath}
                commentsCount={broadcast.commentsCount}
                segment={{
                  tripId: tripId,
                    tripObjectId: broadcast.id,
                    tripObjectType: 'broadcasts',
                }}
              />
            </Grid>
            <Grid item className={classes.commentButtonContainer}>
              <Button
                onClick={() => setComment()}
                onMouseDown={disableRippleEffectOnParent}
                className={classes.commentButton}
                data-test-id='commentButton'
                data-element='link'
                segmentProps={{title: 'Comment Field Initiated', tripObjectId: broadcast.id}}
              >
                <CommentIcon
                  data-element='link'
                />
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </ContainerLink>
  );
};

export default BroadcastBody;
