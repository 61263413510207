import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';
import { makeStyles } from "@mui/styles";
import SiteHeader from "../../shared/SiteHeader";
import SiteFooter from "../../shared/SiteFooter";
import { hocRailsAction } from "../../Document";
import * as routes from '../../../routes';

const { tripsPath } = routes;

type ErrorMessage = {
  title: string,
  subtitle: string,
  body: React.FC,
  status: string,
};

type ErrorMessagesDictionary = {
  invalidAuthenticityToken: ErrorMessage,
  404: ErrorMessage,
  default: ErrorMessage,
};

const MyTripsLink: React.FC = ({ children }) => (
  <Link data-test-id="goBackToTrips" href={tripsPath()} underline="none">{children}</Link>
);

const errorMessages: ErrorMessagesDictionary = {
  invalidAuthenticityToken: {
    title: 'Oops! 🐥',
    subtitle: 'Something went wrong while trying to process your request',
    body: function InvalidAuthenticityTokenErrorBody() {
      return <>
        Try going back to the previous page, hitting refresh, and{' '}
        trying again. If you're still having trouble, try starting{' '}
        fresh from the <MyTripsLink>My Trips</MyTripsLink> page.
      </>;
    },
    status: 'Invalid authenticity token',
  },
  404: {
    title: 'Oops! 🐥',
    subtitle: 'The page you’re looking for has moved or disappeared into the abyss.',
    body: function NotFoundErrorBody() {
      return <>
        Check to make sure you typed the URL correctly,{' '}
        go back to your previous page, or start planning{' '}
        your next adventure by heading to{' '}
        <MyTripsLink>My Trips</MyTripsLink>.
      </>;
    },
    status: '404 page not found',
  },
  default: {
    title: 'Blame our robots 🤖',
    subtitle: 'This page doesn’t look to be working at the moment. Sorry about that.',
    body: function DefaultErrorBody() {
      return <>
        Try again in a little while, {' '}
        <MyTripsLink>go back to My Trips</MyTripsLink>{' '}
        or kill some time by guessing which 10 countries have only four letters in their name.
      </>;
    },
    status: '500 internal server error',
  },
};

type ErrorsPageProps = {
  errorKey: string,
  currentUserAvatarUrl?: string,
  isUserLoggedIn?: boolean,
};

const useStyles = makeStyles(theme => ({
  messageContainerBox: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: theme.spacing(14),
    maxWidth: '677px',
    minHeight: '50%',
    padding: theme.spacing(2),
    '& h1': {
      color: theme.palette.text.primary,
      fontSize: theme.spacing(4),
      fontWeight: 600,
    },
    '& h1, & p': {
      marginBottom: theme.spacing(2),
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

const ErrorsPage = ({ errorKey, ...props }: ErrorsPageProps) => {
  const classes = useStyles();
  const errorMessage: ErrorMessage = errorMessages[errorKey] || errorMessages.default;
  const {
    currentUserAvatarUrl,
    isUserLoggedIn,
  } = props;

  return (
    <div className={classes.root}>
      <SiteHeader
        currentUserAvatarUrl={currentUserAvatarUrl}
        isTripUpdatesHidden
        isUserLoggedIn={isUserLoggedIn}
        showCoverPhoto={false}
      />
      <Grid container direction="row" alignItems="center" className={classes.messageContainerBox}>
        <Grid item >
            <Typography variant="h1">
              {errorMessage.title}
            </Typography>
            <Grid item>
              <Typography>
                {errorMessage.subtitle}
              </Typography>
              <Typography>
                <errorMessage.body />
              </Typography>
              <Typography>
                Keep running into the same problem? Email
                <Link href="mailto:help@troupe.com" underline="none"> help@troupe.com </Link>
                and we’ll lend a hand.
              </Typography>
            </Grid>
            <Typography variant='h3'>
              {`Message: ${errorMessage.status}`}
            </Typography>
          </Grid>
      </Grid>
      <SiteFooter />
    </div>
  );
};

export default hocRailsAction(ErrorsPage);
