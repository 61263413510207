import React from "react";
import { Grid } from "@mui/material";
import { Alert } from "@mui/material";

export type ErrorsProps = {
  errors: string[],
  className?: string,
};

const Errors = ({
  errors,
  className,
}: ErrorsProps) => {
  return (
    <Grid className={className} container spacing={2} data-test-id="errors" direction="column">
      {errors.length > 0 && (
        <Grid item>
          {errors.length > 0 &&
          errors.map((error, index) => (
            <Alert severity="error" key={error + index} data-test-id="displayed-error">
              {error.replace('Validation failed: ', '')}
            </Alert>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export default Errors;
