import React from "react";
import {
  Box,
} from "@mui/material";

import Button from "../../../../buttons/Button";
import NoSuggestionsYet from "../../../../shared/NoSuggestionsYet";
import SectionHeader from "../shared/SectionHeader";
import { newTripItineraryPath } from "../../../../../routes";

type MainContentProps = {
  tripId: string,
};

const MainContent = ({
  tripId,
}: MainContentProps) => {
  return <>
    <SectionHeader
      title='Itinerary'
      note={'Create an itinerary to start organizing your destinations, stays, and activities by day.'}
    />
    <NoSuggestionsYet
      illustration='itinerary_fallback'
    />
    <Box mb={5} sx={{display: 'flex', justifyContent: 'center' }}>
      <Button
        data-test-id="add-button"
        disabled={false}
        href={newTripItineraryPath(tripId)}
        variant="primary"
      >
        Create itinerary
      </Button>
    </Box>
   </>
}

export default MainContent
