import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Errors from '../../../shared/Errors';
import ModalForm from '../../../shared/ModalForm';
import { hocRailsAction } from "../../../Document";
import { tripBroadcastsPath } from "../../../../routes";
import InputWithCharacterCounter from "../../../shared/InputWithCharacterCounter";

type Broadcast = {
  message: string,
};

export type NewBroadcastFormProps = {
  broadcast?: Broadcast,
  errors?: string[],
  tripId: string,
};

const MINIMUM_MESSAGE_LENGTH = 1000;

const useStyles = makeStyles((theme) => ({
  messageInput: {
    '& textarea': {
      minHeight: 350,
      top: 1
    },
  },
  errors: {
    marginBottom: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
  },
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const NewBroadcastForm = ({
  broadcast,
  errors,
  tripId,
}: NewBroadcastFormProps) => {
  const classes = useStyles();

  return (
    <ModalForm
      title='Broadcast message'
      formActionPath={tripBroadcastsPath(tripId)}
      formMethod="post"
      closePath={tripBroadcastsPath(tripId)}
      withChangeDetection
      saveButtonLabel='Send'
    >
      {errors && (
        <Grid item className={classes.errors}>
          <Errors errors={errors} />
        </Grid>
      )}
      <Typography className={classes.subtitle}>
        Broadcasts will be sent to travelers “Interested” or “Going” on your trip.
      </Typography>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <InputWithCharacterCounter
            inputClassName={classes.messageInput}
            id='broadcast-message'
            characterLimit={MINIMUM_MESSAGE_LENGTH}
            data-test-id="message-text-field"
            defaultValue={broadcast?.message || ''}
            placeholder='Write your message...'
            multiline
            name='broadcast[message]'
            fullWidth
            withBorder
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(NewBroadcastForm);

