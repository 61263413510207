type LabelType = "Yes" | "Maybe" | "No" | "Missing";
type ValueType = "yes" | "maybe" | "no" | "missing";

type VoteSentiment = {
  icon: LabelType,
  label: LabelType,
  showIconWithoutLabel: boolean,
  value: ValueType,
  hideOnVoting?: boolean,
}

const voteSentiments: VoteSentiment[] = [{
  icon: 'Yes',
  label: 'Yes',
  showIconWithoutLabel: true,
  value: 'yes',
}, {
  icon: 'Maybe',
  label: 'Maybe',
  showIconWithoutLabel: true,
  value: 'maybe',
}, {
  icon: 'No',
  label: 'No',
  showIconWithoutLabel: true,
  value: 'no',
}, {
  icon: 'Missing',
  label: 'Missing',
  showIconWithoutLabel: true,
  value: 'missing',
  hideOnVoting: true,
}];

export default voteSentiments;
