import React, { useState } from "react";
import clsx from "clsx";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import makeStyles from "@mui/styles/makeStyles";
import PollPreview from "./NewPollForm/PollPreview";
import Button from "../../../buttons/Button";
import Errors from '../../../shared/Errors';
import HorizontalStepper from "../../../shared/HorizontalStepper";
import { track as trackAnalyticsEvent } from "../../../../services/analytics";
import ModalForm from '../../../shared/ModalForm';
import { hocRailsAction } from "../../../Document";
import { tripPollsPath } from "../../../../routes";
import TextButton from "../../../buttons/TextButton";
import { FormContext } from "../../../shared/RailsForm";

export type NewPollAnswer = {
  body: string,
  responsesPercentage?: number,
};

type NewPoll = {
  answers: Array<NewPollAnswer>,
  question: string,
};

const MINIMUM_REQUIRED_ANSWERS = 2;
const MAX_ANSWERS = 10

const useStyles = makeStyles((theme) => ({
  addMoreAnswerButton: {
    marginTop: theme.spacing(1),
  },
  answerInput: {
    height: 48,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  errors: {
    marginBottom: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
  preview: {
    width: '100%',
  },
  label: {
    fontWeight: 600,
  },
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  stepper: {
    margin: theme.spacing(2),
  },
  answersContainer: {
    marginTop: theme.spacing(1),
  },
}));

type NewPollFormProps = {
  errors?: string[],
  poll: NewPoll,
  currentUserId: string,
  isPlanner: boolean,
  question: string,
  tripId: string,
  maxAnswers: number,
};

const NewPollForm = ({
  errors,
  poll,
  currentUserId,
  tripId,
  isPlanner,
  maxAnswers,
}: NewPollFormProps) => {
  const classes = useStyles();
  const steps = ['Draft poll', 'Preview & Send'];
  const [previewStep, setPreviewStep] = useState(false);
  const [answers, setAnswers] = useState(Array.from(poll.answers));
  const setAnswer = (value, index) => {
    const newAnswers = Array.from(answers);
    newAnswers[index]['body'] = value;
    setAnswers(newAnswers);
  };
  const [question, setQuestion] = useState(poll.question);
  const validAnswers = answers.filter((answer) => !!answer.body);
  const disabledSubmit = !question || validAnswers.length < MINIMUM_REQUIRED_ANSWERS;
  const canAddMoreAnswers = answers.length < MAX_ANSWERS

  const addAnswerField = () => {
    if (!canAddMoreAnswers) return;

    setAnswers(answers.concat([{body: null, responsesPercentage: 0}]));
  };

  const onFormSubmit = (event) => {
    if (!previewStep) {
      event.preventDefault();

      setPreviewStep(true);

      trackAnalyticsEvent('Poll Previewed', {
        category: 'Polling',
        tripId: tripId,
        planner: isPlanner,
        userId: currentUserId,
      });
    };
  };

  const backButton = () => {
    return (
      <Button
        className={classes.backButton}
        fullWidth
        onClick={() => setPreviewStep(false)}
        variant="secondary"
      >
        Back
      </Button>
    );
  };

  return (
    <ModalForm
      title='Create poll'
      formActionPath={tripPollsPath(tripId)}
      formMethod="post"
      closePath={tripPollsPath(tripId)}
      disabledSubmit={disabledSubmit}
      onClick={(event) => onFormSubmit(event)}
      saveButtonLabel={previewStep ? "Send" : "Preview"}
      saveButtonType={previewStep ? "submit" : "button"}
      extraSaveBarButton={previewStep ? backButton() : null}
    >
      {errors && (
        <Grid item className={classes.errors}>
          <Errors errors={errors} />
        </Grid>
      )}
      <Box className={classes.stepper}>
        <HorizontalStepper activeStep={previewStep ? 1 : 0} steps={steps}/>
      </Box>
      <Typography className={classes.subtitle}>
        Write a custom question with up to {maxAnswers} answers travelers can choose from.
      </Typography>
      <Box className={clsx({ [classes.hidden]: previewStep })}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography className={classes.label} variant="subtitle1">
              Question
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              data-test-id="question-text-field"
              defaultValue={poll.question}
              label='Write your question...'
              name='poll[question]'
              fullWidth
              required
              onChange={(event) => setQuestion(event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={1} className={classes.answersContainer}>
          <Grid item>
            <Typography variant="subtitle1">
              <span className={classes.label}>Answer</span> (Add up to {maxAnswers})
            </Typography>
          </Grid>
          <Grid item container direction="column" spacing={2}>
            <FormContext.Consumer>
              {({ onFormChange }) => <>
                {answers.map((answer, index) => (
                  <Grid
                    item
                    key={index}
                    data-test-id={`answer-${index+1}`}
                  >
                    <TextField
                      value={answers[index].body || ''}
                      label={`Answer ${index + 1}`}
                      name='poll[answers_attributes][][body]'
                      fullWidth
                      onChange={(event) => {
                        setAnswer(event.target.value, index);
                        onFormChange();
                      }}
                      InputProps={{
                        className: classes.answerInput,
                        endAdornment: (
                          <IconButton
                            data-test-id="clearButton"
                            tabIndex={-1}
                            onClick={() => setAnswer('', index)}
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                ))}
              </>}
            </FormContext.Consumer>
          </Grid>
          {canAddMoreAnswers &&
            <Grid item container justifyContent="center">
              <Grid item>
                <TextButton
                  className={classes.addMoreAnswerButton}
                  data-test-id='add-answer-button'
                  detachedFromForm
                  onClick={() => addAnswerField()}
                >
                  + Add answer
                </TextButton>
              </Grid>
            </Grid>
          }
        </Grid>
      </Box>
      {previewStep && (
        <Grid container className={classes.preview}>
          <PollPreview
            question={question}
            answers={validAnswers}
          />
        </Grid>
      )}
    </ModalForm>
  );
};

export default hocRailsAction(NewPollForm);

