import React from "react";
import clsx from "clsx";
import { Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

type StayNameProps = {
  isClickable?: boolean,
  fullWidth?: boolean,
  name: string,
};

type StayNameWithLinkProps = {
  fullWidth?: boolean,
  isClickable?: boolean,
  isDisabled?: boolean,
  name: string,
  stayUrl: string,
  target?: string,
};

type StyleProps = {
  fullWidth?: boolean,
};

const useStyles = makeStyles<Theme, StyleProps>((theme): {} => ({
  root: {
    textAlign: 'left',
    lineHeight: '24px',
    color: 'black',
    fontSize: 18,
    fontWeight: 600,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: [18, '!important'],
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: ({ fullWidth }) => fullWidth ? '100%' : "calc(100% - 88px)",
      marginRight: theme.spacing(5),
    },
    maxWidth: '100%',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  disabled: {
    color: ["#C0C0C0", '!important'],
  },
  applyHover: {
    '&:hover': {
      color: '#3B41B0',
      textDecoration: 'none',
    },
  },
}));

const StayName = ({
  isClickable,
  fullWidth,
  name,
}: StayNameProps) => {
  const classes = useStyles({ fullWidth: fullWidth });

  return (
    <Typography
      className={clsx(
        classes.root,
        "stay-title",
        {[classes.applyHover]: isClickable },
      )}
    >
      {name}
    </Typography>
  );
};

export const StayNameWithLink = ({
  fullWidth,
  stayUrl,
  isClickable,
  isDisabled,
  name,
  target,
}: StayNameWithLinkProps) => {
  const classes = useStyles({ fullWidth: fullWidth });

  return (
    <Link
      data-test-id="stayTitle"
      href={stayUrl}
      target={target ? '_blank' : ''}
      underline="none"
      color="inherit"
      className={clsx(
        classes.root,
        { [classes.applyHover]: isClickable },
        { [classes.disabled]: isDisabled },
        "stay-title",
      )}
    >
      {name}
    </Link>
  )
};

export default StayName;
