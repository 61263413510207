import React from "react";
import {
  ActivityCategoryType,
  ActivityType,
} from "./TripObjectTypes";

export type ActivityFilterContextType = {
  allowedCategories: ActivityCategoryType[],
  categories: ActivityCategoryType[],
  currentFilters: {
    categories: ActivityCategoryType[],
    withVotes: boolean,
    withoutVotes: boolean,
  },
  activitiesWithoutVotesCount: number,
  activitiesWithVotesCount: number,
  currentFiltersCount: number,
};

export const ActivityFilterContext = React.createContext<ActivityFilterContextType>({
  allowedCategories: [],
  categories: [],
  currentFilters: { categories: [], withVotes: null, withoutVotes: null },
  currentFiltersCount: 0,
  activitiesWithoutVotesCount: 0,
  activitiesWithVotesCount: 0,
});

type SortingType = {
  label: string,
  sortBy: string,
};

export type ActivitySortingContextType = {
  currentSorting: string,
  currentSortingLabel: string,
  sortings?: SortingType[],
};

export const ActivitySortingContext = React.createContext<ActivitySortingContextType>({
  currentSorting: '',
  currentSortingLabel: '',
  sortings: null,
});
