import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DestinationType } from "../../shared/TripObjectTypes";
import makeStyles from "@mui/styles/makeStyles";
import { hocRailsAction } from "../../Document";
import Errors from "../../shared/Errors";
import ModalForm from "../../shared/ModalForm";
import ImageUploader from '../../shared/ImageUploader';
import * as routes from '../../../routes';

const {
  tripDestinationPath,
  tripDestinationsPath,
} = routes;

type DestinationFormProps = {
  destination: DestinationType,
  errors?: string[],
  tripId: string,
};

const useStyles = makeStyles((theme) => ({
  destinationImage: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

const DestinationForm = ({
  destination,
  errors,
  tripId,
}: DestinationFormProps) => {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState(destination.image);
  const fallbackSource = "/assets/destination_fallback.svg";

  return (
    <ModalForm
      closePath={tripDestinationsPath(tripId)}
      formActionPath={tripDestinationPath(tripId, destination.id)}
      formMethod="patch"
      title="Edit destination"
      data-test-id='edit-destination'
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Errors errors={errors} />
        </Grid>
        <Grid item>
          <Typography className={classes.subtitle}>
            Where to:
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="destination_name"
            label="Search country, state or city"
            variant="outlined"
            multiline
            disabled
            value={destination.name}
          />
        </Grid>
        <Grid className={classes.destinationImage} item>
          <ImageUploader
            defaultImage={fallbackSource}
            inputName="destination[image]"
            image={destination.image}
            onImageUpload={(url) => setImageUrl(url)}
            originalImages={[destination.originalImage]}
          />
        </Grid >
        {!destination && (
          <Grid item>
            <TextField
              fullWidth
              id="destination_comment"
              label="Comment"
              name="comment"
              variant="outlined"
            />
          </Grid>
        )}
      </Grid>
    </ModalForm>
  );
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(DestinationForm);
