import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Tooltip from "./Tooltip";

export type LabelProps = {
  label: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#EFF2F4',
    borderRadius: '4px',
    color: '#334351',
    fontSize: '14px',
    fontWeight: 500,
    height: '22px',
    lineHeight: '24px',
    maxWidth: 'fit-content',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const Label = ({
  label,
}: LabelProps) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const tooltipText = isTruncated ? label : '';

  React.useEffect(() => {
    const refreshWindowDimensions = () => {
      if (ref.current) {
        setIsTruncated(ref.current.offsetWidth < ref.current.scrollWidth);
      };
    };

    refreshWindowDimensions();
    window.addEventListener('resize', refreshWindowDimensions);
    return () => window.removeEventListener('resize', refreshWindowDimensions);
  });

  return (
    <Tooltip title={tooltipText}>
      <Box className={classes.root} px={1} ref={ref}>
        {label}
      </Box>
    </Tooltip>
  );
};

export default Label;
