import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import pluralize from 'pluralize';
import { rankLabels } from '../../../../shared/rankLabels';
import { getTravelersRankingsForTripObject, TripObjectRankingProps } from "../../../../../services/RankingSystem";
import TextButton from "../../../../buttons/TextButton";
import textButtonColors from "../../../../buttons/textButtonColors";

const useStyles = makeStyles((theme) => ({
  activeSentiments: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  icon: {
    verticalAlign: "bottom",
    width: theme.spacing(3),
    marginRight: theme.spacing(-3),
  },
  votesCount: {
    marginLeft: theme.spacing(4),
  },
  totalVotes: {
    position: 'relative',
    '&:first-child': {
      zIndex: 3,
    },
    '&:nth-child(2)': {
      zIndex: 2,
    },
    '&:not(:last-child)': {
      paddingRight: theme.spacing(2),
    },
  },
  text: {
    color: textButtonColors.link.default.color,
    fontWeight: textButtonColors.link.default.fontWeight,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const VoteResultsButton = ({
  className,
  objectId,
  onClick,
  rankingType,
  travelers,
}: TripObjectRankingProps & {className?: string, onClick: () => void}) => {
  const classes = useStyles();
  const votes = getTravelersRankingsForTripObject({travelers: travelers, objectId: objectId, rankingType: rankingType });
  const rankLabelsWithVotes = rankLabels.filter((rank) =>
    votes.filter(({ ranking }) => ranking === rank.value).length > 0);

  return (
    <TextButton
      className={className}
      variant="tertiary"
      data-test-id="see-votes-button"
      onClick={onClick}
    >
      <span className={classes.activeSentiments}>
        {rankLabelsWithVotes.map(({icon}, index) => (
          <span className={classes.totalVotes} key={index}>
            <img
              className={classes.icon}
              data-test-id={`${icon}-medal`}
              src={`/assets/illustrations/${icon}.svg`}
            />
          </span>
        ))}
      </span>
      <span className={clsx(classes.text, {[classes.votesCount]: votes.length > 0})}>
        View {votes.length} {pluralize('vote', votes.length)}
      </span>
    </TextButton>
  );
};

export default VoteResultsButton;
