import React from 'react';
import clsx from 'clsx';
import Tooltip from './Tooltip';
import { AvatarGroup as MuiAvatarGroup } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Avatar from "../avatars/Avatar";

export type AvatarType = {
  displayName: string,
  avatarUrl: string,
  plusOneCount?: number,
};

type OverflowItemType = {
  avatars: AvatarType[],
};

type AvatarGroupType = {
  avatars: AvatarType[],
  disabled?: boolean,
  max?: number,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  overflowCount: {
    fontSize: 12,
  },
  overflowContainer: {
    color: theme.palette.general.primary,
  },
  root: {
    alignItems: "center",
    '& > div:not(:last-child)': {
      marginLeft: '-8px',
    },
    '& .MuiAvatar-root': {
      marginLeft: 0,
    },
  },
  avatar: {
    fontSize: 11,
    fontWeight: "bold",
  },
  overflowAvatar: {
    border: '1px solid #CACACA !important',
    background: theme.palette.background.default,
    boxSizing: 'border-box',
  },
  tooltip: {
    backgroundColor: '#272727',
    marginTop: [theme.spacing(1), '!important'],
  },
  tooltipArrow: {
    color: '#272727',
  },
}));

const OverflowItem = ({ avatars }: OverflowItemType) => {
  const classes = useStyles();

  return (
    avatars.length > 0 &&
      <Tooltip
        key='overflow'
        arrow
        enterTouchDelay={0}
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.tooltipArrow,
        }}
        title={
          <span>
            {avatars.map(value => <p key={value.avatarUrl}>{value.displayName}</p>)}
          </span>
        }
      >
        <div>
          <Avatar
            className={clsx(classes.overflowAvatar, classes.avatar)}
            data-test-id='overflow-avatar'
            size="sm"
          >
            <span className={classes.overflowContainer}>
              {`+${avatars.length}`}
            </span>
          </Avatar>
        </div>
      </Tooltip>
  );
};

const AvatarGroup = ({ avatars, max, disabled }: AvatarGroupType) => {
  const classes = useStyles();
  const maxAvatars = Array.from(avatars).splice(0, max);
  const lastAvatars = Array.from(avatars).splice(max);

  return (
    <>
      <MuiAvatarGroup
        max={20}
        className={classes.root}
        classes={{
          avatar: classes.avatar,
        }}
      >
        {maxAvatars.map(({ displayName, avatarUrl, plusOneCount }) => (
          <Tooltip
            key={avatarUrl}
            arrow
            data-test-id="avatar-display-name"
            enterTouchDelay={0}
            title={displayName}
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
          >
            <div>
              <Avatar
                data-test-id="travelers-votes-avatar"
                disabled={disabled}
                key={avatarUrl}
                size="sm"
                src={avatarUrl}
                plusOnes={plusOneCount}
              />
            </div>
          </Tooltip>
        ))}
        <OverflowItem avatars={lastAvatars}/>
      </MuiAvatarGroup>
    </>
  );
};

export default AvatarGroup;
