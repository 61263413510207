import React, {useContext, useEffect} from "react";
import { FormContext } from "../../shared/RailsForm";
import GooglePlacesAutocomplete, { Place } from "../../shared/GooglePlacesAutocomplete";
import useStateWithPromise from "../../../services/useStateWithPromise";

type ActivityOption = 'address' | 'null';

type ActivityLocationFieldProps = {
  addActivityByOption?: ActivityOption,
  currentGooglePlaceId?: string,
  currentGooglePlaceUrl?: string,
  currentImage?: string,
  currentLocationName?: string,
  currentLongitude?: number,
  currentLatitude?: number,
  onChange?: (place: Place) => void,
  required?: boolean,
};

const ActivityLocationField = React.forwardRef(({
  addActivityByOption,
  currentGooglePlaceId,
  currentGooglePlaceUrl,
  currentImage,
  currentLocationName,
  currentLongitude,
  currentLatitude,
  onChange,
  required,
}: ActivityLocationFieldProps, externalInputRef) => {
  const { onFormChange } = useContext(FormContext);
  const [activityLocation, setActivityLocation] = useStateWithPromise({
    id: currentGooglePlaceId,
    image: currentImage,
    latitude: currentLatitude,
    longitude: currentLongitude,
    locationName: currentLocationName,
    url: currentGooglePlaceUrl,
  });

  const activityLocationName = activityLocation ? activityLocation.name : '';
  const googlePlaceId = activityLocation ? activityLocation.id : '';
  const googlePlaceUrl = activityLocation ? activityLocation.url : '';
  const googlePlaceLocation = (activityLocation && activityLocation.longitude && activityLocation.latitude) ? `POINT(${activityLocation.longitude} ${activityLocation.latitude})` : '';
  const googlePlaceImage = activityLocation ? activityLocation.image : '';

  const handleActivityChange = (value: Place) => {
    setActivityLocation(value).then(() => {
      onFormChange();
      if (onChange) onChange(value);
    });
  };

  const scrollToLocation = () => {
    document.getElementById('activity_location')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  };

  useEffect(() => {
    if (addActivityByOption) {
      scrollToLocation();
    };
  }, []);

  return (
    <>
      <input
        type="hidden"
        value={activityLocationName || currentLocationName || ""}
        name="activity[location_name]"
      />
      <input
        type="hidden"
        value={googlePlaceId || ""}
        name="activity[google_place_id]"
      />
      <input
        type="hidden"
        value={googlePlaceUrl || ""}
        name="activity[google_place_url]"
      />
      <input
        type="hidden"
        value={googlePlaceLocation || ""}
        name="activity[location]"
      />
      <input
        type="hidden"
        value={googlePlaceImage || ""}
        name="activity[original_image]"
      />
      <GooglePlacesAutocomplete
        id={'activity_location'}
        autoFocus={addActivityByOption === "address"}
        defaultValue={currentLocationName}
        fullWidth
        onSelect={(value) => handleActivityChange(value)}
        ref={externalInputRef}
        required={required}
        placeholder={"Search by name or address"}
      />
    </>
  );
});

export default ActivityLocationField;
