import React, { useContext } from "react";
import Stack from "@mui/material/Stack";
import { useQuery } from "@apollo/client";
import { StayType } from "../../../shared/TripObjectTypes";
import Divider from "../../../shared/Divider";
import StayPermapageCard from "../../trips/microsite/HousingPage/StayPermapageCard";
import SkeletonList from '../../../shared/SkeletonPage/SkeletonList';
import { TripSiteContext } from '../../../Document'
import SnackbarNotification, { SnackbarNotificationProp } from '../../../shared/SnackbarNotification';
import * as Sentry from "@sentry/browser";
import { STAY_QUERY } from "../../../../graphql/queries/stays";
import StayBookingDetails from "./StayBookingDetails";

export interface StayGid {
  gid: string,
}

type StayPageProps = {
  isPlanner: boolean,
  stay: StayGid,
  suggestionsCount,
  source?: "staysVertical" | "stayPermapage" | "itineraryPermapage" | "scheduleList",
};

const Snackbar = (props: Partial<SnackbarNotificationProp>) => (
  <SnackbarNotification
    autoHideDuration={props.autoHideDuration ?? null}
    message={props.message}
    open={!!props.message}
    severity={props.severity}
  />
);

const StayPage = ({
  stay: { gid },
}: StayPageProps) => {
  const { tripId } = useContext(TripSiteContext);

  const { data, loading, error } = useQuery(
    STAY_QUERY, {
      variables: {
        stayId: gid,
      },
    },
  );

  if (loading) return <SkeletonList itemLength={1} />

  if (error) {
    Sentry.captureException(error)
    return <>
      <Snackbar
        message='Sorry, something went wrong loading this stay. Please try again.'
        severity='error'
      />
      <SkeletonList itemLength={1} />
    </>
  }

  const stay: StayType = data.node;

  return (
    <Stack spacing={1}>
      <StayPermapageCard stay={stay} />
      {stay.stayBookings?.length > 0 &&
        <>
          <Divider />
          <StayBookingDetails
            booking={stay.stayBookings[0]}
            stayId={stay.uuid}
            tripId={tripId}
          />
        </>
      }
    </Stack>
  );
};

export default StayPage;
