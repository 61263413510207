import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

type InventoryBannerProps = {
  availability?: number,
};

type inventoryBannerType = 'unavailable' | 'available' | 'neutral';

export type inventoryBannerSegmentTextType = 'low inventory' | 'neutral inventory' | 'high inventory';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '100%',
    border: '1px solid #D4D4D4',
    padding: theme.spacing(0, 2),
    textAlign: 'center',
  },
  unavailableCase: {
    backgroundColor: '#FCF6EF',
  },
  availableCase: {
    background: '#E9F9F4',
  },
}));

const typeThresholds = (availability : number) : inventoryBannerType => {
  if(availability === undefined || availability >= 100) return 'neutral';
  if(availability < 10) return 'unavailable';
  if(availability < 30) return 'neutral';
  return 'available';
}

export const inventoryBannerSegmentText = (availability : number) => {
  const type = typeThresholds(availability);
  // eslint-disable-next-line no-unused-vars
  const segmentValues : {[key in inventoryBannerType] : inventoryBannerSegmentTextType} = {
    'available': 'high inventory',
    'unavailable': 'low inventory',
    'neutral': 'neutral inventory',
  };
  return segmentValues[type];
}

const InventoryBanner : React.FC<InventoryBannerProps> = ({availability}) => {
  const classes = useStyles();
  const typeValues = {
    'available': {
      text: <p>Most stays are available for your dates. Book now to lock in the best option!</p>,
      class: classes.availableCase,
    },
    'unavailable': {
      text: <p><span style={{fontWeight: 600}}>These dates are popular!</span> Only 10% of stays are available on our site.</p>,
      class: classes.unavailableCase,
    },
  };

  const type = typeThresholds(availability);
  if(type === 'neutral') return null;
  return(
    <Grid item container>
      <Grid item className={clsx(classes.container, typeValues[type].class)}>
        {typeValues[type].text}
      </Grid>
    </Grid>
  )
}

export default InventoryBanner;
