import React from 'react';
import { GlobalTrip } from '../../../../shared/TripTypes';
import RespondToInviteDropdownButton from './RespondToInviteDropdownButton';
import RespondToInviteButtons from './RespondToInviteButtons';

type RespondToInviteOptionsTypes = {
  trip: GlobalTrip,
  inviteId?: string,
}

const RespondToInviteOptions = ( {trip, inviteId} : RespondToInviteOptionsTypes ) => {
  return (
    <>
      {trip.currentTravelerStatus ? (
        <RespondToInviteDropdownButton
          currentStatus={trip.currentTravelerStatus}
          disabled={trip.isPlanner}
          travelerId={trip.travelerId}
          tripId={trip.id}
          tripInviteId={inviteId}
        />
      ) : (
        <RespondToInviteButtons tripId={trip.id} />
      )}
    </>
  );
};

export default RespondToInviteOptions;
