import React, { useState } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { ItineraryEventType } from "../../../../shared/TripObjectTypes";
import ActionsMenu from '../../../../shared/ActionsMenu';
import * as routes from '../../../../../routes';
import { ItineraryDialogModal } from "./ItineraryDialogModal";
import ItineraryStayEventDialog from "./ItineraryStayEventDialog";
import ItineraryEvent, {
  ClickArea,
  useStyles,
} from "./ItineraryEvent";
import moment from "moment";
import {
  useTrackDeletionInitiated,
  useTrackEventCardClicked,
  useTrackItineraryEventEditInitiated,
} from "../../../../../services/segmentEvents/useItineraryEvents";
import clsx from "clsx";

const {
  tripItineraryEventPath,
  tripStayPath,
  editTripStayEventPath,
  newTripStayBookingPath,
  editTripStayBookingPath,
  tripItineraryDatePath,
} = routes;

type ItineraryStayEventProps = {
  itineraryDateId?: string,
  stayEvent: ItineraryEventType,
  showAddTimeLink?: boolean,
  showActionsMenu?: boolean,
  isClickeable?: boolean,
  isInModal?: boolean,
  page: string,
};

const ItineraryStayEvent = ({
  itineraryDateId = null,
  stayEvent,
  showActionsMenu = false,
  isClickeable = false,
  isInModal = false,
  page,
}: ItineraryStayEventProps) => {
  const classes = useStyles();
  const fallbackSource = "/assets/activity_fallback_simple.svg";
  const image = "/assets/Stay_icon.svg";
  const { tripId } = stayEvent;
  const searchParams = new URLSearchParams(window.location.search);
  const startDialogOpen = searchParams.toString().includes(`eventId=${stayEvent.id}`);
  const [isDialogOpen, setIsDialogOpen ] = useState(startDialogOpen);
  const [imageSource, setImageSource] = useState(stayEvent.imageUrl || fallbackSource);
  const tripObjectPath = itineraryDateId ? tripStayPath(tripId, stayEvent.schedulableId, { itinerary_date_id: itineraryDateId, source: page }) : tripStayPath(tripId, stayEvent.schedulableId);
  const cardHeight = isInModal ? 80 : 48;
  const trackEventCardClicked = useTrackEventCardClicked();
  const trackItineraryEventEditInitiated = useTrackItineraryEventEditInitiated();
  const trackDeletionInitiated = useTrackDeletionInitiated();
  const showBookedIcon = isInModal || !stayEvent.isBooked
  const newBookingPath = `${newTripStayBookingPath(tripId, stayEvent.schedulableId)}?stay_event_id=${stayEvent.id}&source=${page}&itinerary_date_id=${itineraryDateId}`;
  const firstStayBooking = stayEvent.stayBookings?.at(0)
  const updateBookingPath = firstStayBooking ? `${editTripStayBookingPath(tripId, stayEvent.schedulableId, firstStayBooking.uuid)}?stay_event_id=${stayEvent.id}&source=${page}&itinerary_date_id=${itineraryDateId}` : undefined;

  const onButtonClick = () => {
    if(page === 'tripUpdates'){
      window.location.href = tripItineraryDatePath(tripId, itineraryDateId, {eventId: stayEvent.id});
    } else {
      setIsDialogOpen(!isDialogOpen);
    }
    trackEventCardClicked(stayEvent.schedulableType, stayEvent.schedulableId, page);
  };

  const onEditTimeClick = (method : string) => {
    trackItineraryEventEditInitiated(method, stayEvent.schedulableType, stayEvent.schedulableId, page);
    window.location.href = `${editTripStayEventPath(tripId, stayEvent.id)}?itinerary_date_id=${itineraryDateId}&source=${page}`;
  };

  const renderDateText = () => {
    const { startDate, endDate } = stayEvent;
    const formattedStartDate = moment(startDate, "YYYY-MM-DD").format('MM/DD/YYYY');
    const formattedEndDate = moment(endDate, "YYYY-MM-DD").format('MM/DD/YYYY');
    const text = (startDate && endDate) ? `${formattedStartDate} - ${formattedEndDate}` : "No date set";
    return(
      <Typography className={classes.time} data-test-id="itinerary-event-time">
        {text}
      </Typography>
  )}

  const renderActionsMenu = () => (
    <ActionsMenu
      onEditClick={() => onEditTimeClick('overflow')}
      deletePath={`${tripItineraryEventPath(tripId, stayEvent.id)}?itinerary_date_id=${itineraryDateId}&source=${page}`}
      resourceName="event"
      displayMarkAsBooked={stayEvent.stayBookings?.length === 0}
      onDeleteClick={() => trackDeletionInitiated(stayEvent.schedulableType, stayEvent.schedulableId, page)}
      newBookingPath={newBookingPath}
      updateBookingPath={updateBookingPath}
    />
  );

  const renderItineraryDialogModal = () => (
    <ItineraryDialogModal
      title={stayEvent.name}
      fullWidth
      maxWidth="xs"
      imgUrl={imageSource || fallbackSource}
      eventObjectPath={tripObjectPath}
      itineraryEvent={stayEvent}
      message={
        <ItineraryStayEventDialog
          itineraryDateId={itineraryDateId}
          itineraryEvent={stayEvent}
        />
      }
      isOpen={isDialogOpen}
      onClose={()=>setIsDialogOpen(false)}
    />
  )

  return (
    <ItineraryEvent
      actionsMenu={renderActionsMenu()}
      showActionsMenu={showActionsMenu}
      itineraryDialog={renderItineraryDialogModal()}
      sx={{ height: cardHeight }}
    >
      {
        isClickeable &&
        <ClickArea
          anchor={`itineraryEvent_${stayEvent.id}`}
          onTripObjectClick={onButtonClick}
          sx={{ height: cardHeight }}
        />
      }
      <Grid item className={clsx(
        {
          [classes.imageWrapper]: isInModal,
          [classes.stayImageWrapper]: !isInModal && !stayEvent.isBooked,
          [classes.bookedImage]: stayEvent.isBooked,
        },
      )}>
        {showBookedIcon ? (
          <img
            alt=""
            onError={() => setImageSource(fallbackSource)}
            src={isInModal ? imageSource : (image || fallbackSource)}
          />
        ) : (
          <img alt="check mark icon" src={"/assets/illustrations/Yes.svg"} style={{zIndex: 3}} />
        )}
      </Grid>
      <Grid
        item
        container
        direction="column"
        justifyContent="space-evenly"
        flexWrap="nowrap"
        flexGrow={2}
        sx={{ overflow: 'hidden' }}
      >
        <Grid item data-test-id="itinerary-event-name">
          <Typography className={classes.name}>{stayEvent.name}</Typography>
        </Grid>
        { isInModal && renderDateText() }
      </Grid>
    </ItineraryEvent>
  );
};

export default ItineraryStayEvent;
