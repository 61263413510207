import React, { useEffect, useState } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import { makeStyles, DefaultTheme } from "@mui/styles";

type SelectButtonProps = {
  isSelected?: boolean,
  onClick?: () => void,
};

const useStyles = makeStyles<DefaultTheme, { selected?: boolean }>((theme) => ({
  root: {
    zIndex: 2,
    height: theme.spacing(6),
    border: '1px solid',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.spacing(2),
    fontWeight: 600,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    width: '100%',
    marginTop: theme.spacing(2),
    borderRadius: '4px',
    background: ({ selected }) => selected ? '#334351' : 'white',
    borderColor: ({ selected }) => selected ? '#353535' : '#D6DEE4',
    color: ({ selected }) => selected ? 'white' : '#333333',
  },
}));

const SelectButton = ({
  isSelected = false,
  onClick = () => {},
  ...buttonProps
}: SelectButtonProps) => {
  const [selected, setSelected] = useState(isSelected);
  const classes = useStyles({ selected });

  const onButtonClick = () => {
    setSelected(!selected);
    onClick();
  };

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  return (
    <ButtonBase
      {...buttonProps}
      onClick={() => onButtonClick()}
      className={classes.root}
    >
      {selected ? 'Unselect' : 'Select'}
    </ButtonBase>
  );
};

export default SelectButton;
