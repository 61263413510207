import React from "react";
import {
  Box,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import withAddStayPageLayout from "./withAddStayPageLayout";
import StaysSearch, { CustomFiltersAppliedType, DateOptionProps } from "../../stays_search/StaysSearch";
import ExternalListingForm from "../../stays_search/ExternalListingForm";
import { apiSearchAiListingsPath, addBySmartSearchTripStaysPath, tripStaysPath } from "../../../../routes";
import { CurrentAppliedFilters } from "../../stays_search/Listings/Filters";
import { TripSiteContext } from "../../../Document";

type SmartSearchFormProps = {
  currentAppliedFilters: CurrentAppliedFilters,
  customFiltersApplied: CustomFiltersAppliedType,
  dateOptions: DateOptionProps[],
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    marginTop: theme.spacing(3),
  },
}));

export const SmartSearchForm : React.FC<SmartSearchFormProps> = ({
  currentAppliedFilters,
  customFiltersApplied,
  dateOptions,
}) => {
  const { tripId } = React.useContext(TripSiteContext);
  const classes = useStyles();
  const basePath = addBySmartSearchTripStaysPath(tripId);
  const apiSearchPath = apiSearchAiListingsPath();
  const [hasSearched, setHasSearched] = React.useState(false);

  React.useEffect(() => {
    const handlePopstate = () => window.location.reload();

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  return (
    <Box className={classes.root}>
      <StaysSearch
          currentAppliedFilters={currentAppliedFilters}
          customFiltersApplied={customFiltersApplied}
          dateOptions={dateOptions}
          topSuggestedDestinations={[]}
          basePath={basePath}
          apiSearchPath={apiSearchPath}
          renderCtaButton={({stay}) => {
            const resourcePath = tripStaysPath(tripId);

            return <ExternalListingForm
              staysPath={resourcePath}
              canAddToTrip={!stay.alreadyOnTrip}
              fullWidth
              id={stay.id}
              url={stay.originalUrl}
              source="Smart Search"
            />
          }}
          SearchBackground={({children}) => <>{children}</>}
          aiSearch
          aiSearchType='aiTripSearch'
          showShortBackground={setHasSearched}
          hasSearched={hasSearched}
        />
    </Box>
  );
};

export default withAddStayPageLayout(SmartSearchForm);
