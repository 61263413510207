import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontWeight: '500',
    fontSize: '0.875rem',
  },
}));

const ProviderName: React.FC = ({children}) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root}>
      {children}
    </Typography>
  );
};

export default ProviderName;
