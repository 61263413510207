import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TripNote } from './NotesListPage';
import Errors from '../../../shared/Errors';
import ModalForm from '../../../shared/ModalForm';
import { hocRailsAction } from "../../../Document";
import { tripNotePath, tripNotesPath } from "../../../../routes";
import InputWithCharacterCounter from "../../../shared/InputWithCharacterCounter";

type NoteFormProps = {
  tripNote?: TripNote,
  tripId: string,
  errors?: string[],
};

const CHARACTER_LIMIT = 100;

const useStyles = makeStyles((theme) => ({
  errors: {
    marginBottom: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
  },
  image: {
    height: '174px',
  },
  imageCopy: {
    color: '#303232',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
  },
}));

const NoteForm = ({
  tripNote,
  tripId,
  errors,
}: NoteFormProps) => {
  const classes = useStyles();

  return (
    <ModalForm
      title={tripNote ? 'Edit note' : 'Add new note'}
      formActionPath={tripNote ? tripNotePath(tripId, tripNote.id) : tripNotesPath(tripId)}
      formMethod={tripNote ? "put" : "post"}
      closePath={tripNote ? tripNotePath(tripId, tripNote.id) : tripNotesPath(tripId)}
      saveButtonLabel='Save'
      withChangeDetection
      subtitle={tripNote ?
        'Add a custom title, so travelers know what information to add to this note.'
        :
        'Create a new note to help organize information around a particular topic.'
      }
    >
      {errors && (
        <Grid item className={classes.errors}>
          <Errors errors={errors} />
        </Grid>
      )}
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography className={classes.label}>
            Note title
          </Typography>
        </Grid>
        <Grid item>
          <InputWithCharacterCounter
            defaultValue={tripNote?.title}
            characterLimit={CHARACTER_LIMIT}
            placeholder="Give your note a name"
            focused
            fullWidth
            noLabel
            name="trip_note[title]"
          />
        </Grid>
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ mt: 4 }}>
        <Grid item>
          <img
            className={classes.image}
            src="/assets/illustrations/notes.svg"
          />
        </Grid>
        <Grid item>
          <Typography className={classes.imageCopy}>
            You can add content to your note once you give it a name and press save.
          </Typography>
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(NoteForm);
