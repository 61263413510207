import React, { useState } from "react";
import { TextField, Grid, InputBase, InputAdornment} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import clsx from "clsx";

type InputWithCharacterCounterProps = {
  className?: string,
  characterLimit: number,
  defaultBlurValue?: string,
  defaultValue?: string,
  focused?: boolean,
  fullWidth?: boolean,
  id?: string,
  inputClassName?: string,
  label?: string,
  noLabel?: boolean,
  multiline?: boolean,
  name?: string,
  onChange?: (element) => void,
  onClick?: () => void,
  onTextFieldFocus?: (event: any) => void,
  onTextFieldBlur?: (event: any) => void,
  placeholder?: string,
  rows?: number,
  testId?: string,
  withBorder?: boolean,
  hasExtraHeight?: boolean,
};

const useStyles = makeStyles(theme => ({
  characterCountContainer: {
    fontSize: '12px',
    fontWeight: '200',
    marginTop: theme.spacing(0.5),
    color: '#69737a',
  },
  inputActive: {
    border: '2px solid #545DFC',
  },
  inputInactive: {
    border: '2px solid #b4b9bc',
  },
  inputContainer: {
    padding: theme.spacing(1),
    borderRadius: 6,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover': {
      border: '2px solid #69737a',
    },
  },
}));

const InputWithCharacterCounter = ({
  className,
  characterLimit,
  defaultBlurValue,
  defaultValue = '',
  focused,
  fullWidth,
  inputClassName,
  label,
  noLabel = false,
  name,
  onTextFieldFocus,
  onTextFieldBlur,
  rows,
  testId = 'inputField',
  withBorder,
  hasExtraHeight = false,
  ...props
}: InputWithCharacterCounterProps ) => {
  const classes = useStyles();
  const useTextField = !!label || noLabel;
  const { onChange, id, placeholder, ...textFieldProps } = props;
  const [value, setValue] = useState(defaultValue);
  const [isActive, setIsActive] = useState(focused);
  const labelText = noLabel ? "" : label
  const extraHeightProps = hasExtraHeight ? {multiline: true,  rows: 5} : null;
  const counterComponentPosition = hasExtraHeight ? {alignSelf: 'flex-end', paddingBottom: '5px'} : null;

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    };

    setValue(event.target.value);
  };

  const onBlur = event => {
    setIsActive(false);
    if (defaultBlurValue && event.currentTarget.value.trim() == '') setValue(defaultBlurValue);

    onTextFieldBlur(event.currentTarget);
  };

  const onFocus = event => {
    setIsActive(true)
    onTextFieldFocus(event.currentTarget)
  }

  const CounterComponent = () => {
    if(hasExtraHeight) return <></>

    return <InputAdornment
      data-test-id='textFieldCounter'
      sx={{ alignItems: "start", fontSize: '12px', ...counterComponentPosition }}
      position="end"
    >
      {value?.length || 0}/{characterLimit}
    </InputAdornment>;
  }

  return (
    <Grid
      className={clsx(className,
        {[classes.inputContainer]: !useTextField && withBorder,
          [classes.inputActive]: !useTextField && withBorder && isActive,
          [classes.inputInactive]: !useTextField && withBorder && !isActive,
        })}
      item
      xs={12}
    >
      {useTextField ?
        <TextField
          {...textFieldProps}
          {...extraHeightProps}
          data-test-id={testId}
          autoFocus={focused}
          id={id}
          label={labelText}
          name={name}
          onChange={handleChange}
          onBlur={onTextFieldBlur && onBlur}
          fullWidth={fullWidth}
          defaultValue={defaultValue}
          placeholder={placeholder}
          inputProps={{
            maxLength: characterLimit,
          }}
          InputProps={{
            endAdornment: CounterComponent(),
          }}
        /> :
        <>
          <InputBase
            className={inputClassName}
            id={id}
            {...textFieldProps}
            data-test-id={testId}
            name={name}
            onChange={handleChange}
            onBlur={(event) => onTextFieldBlur ? onBlur(event) : setIsActive(false)}
            fullWidth={fullWidth}
            defaultValue={defaultValue}
            inputProps={{
              maxLength: characterLimit,
            }}
            placeholder={placeholder}
            rows={rows}
            onFocus={(event) => onTextFieldFocus ? onFocus(event) : setIsActive(true)}
          />
          <Grid data-test-id="custom-counter-adornment" textAlign="end">
            <Box className={classes.characterCountContainer}>
              {value.length}/{characterLimit}
            </Box>
          </Grid>
        </>
      }
    </Grid>
  );
};

export default InputWithCharacterCounter;
