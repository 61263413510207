import React from "react";
import {
  Grid,
  Typography,
  Theme,
  useMediaQuery,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles, useTheme } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import pluralize from 'pluralize';

type VotingProgressBannerProps = {
  rankings: string[],
  suggestionsCount: number,
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: '#F2F3FF',
    border: '1px solid #E6E7E8',
    borderRadius: '0px 0px 5px 5px',
    height: '44px',
  },
  icon: {
    height: '20px',
    width: '20px',
  },
  checkCircleIcon: {
    color: '#1EBE95',
  },
  votesLeft: {
    fontSize: theme.spacing(2),
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  uncheckedIcon: {
    color: '#BEC9D4',
  },
}));

const VotingProgressBanner = ({
  rankings = [],
  suggestionsCount,
}: VotingProgressBannerProps) => {
  const classes = useStyles();

  const rankingsCopy = rankings.length === 0 ? Array(3).fill(null) : [...rankings];
  const rankingsSlice = rankingsCopy.sort().slice(0, suggestionsCount);
  const actualRankings = rankingsCopy.filter(vote => vote).length;
  const votesLeftCount = suggestionsCount < 3 ? rankingsSlice.length - actualRankings
    : rankingsCopy.length - actualRankings;

  const votesLeftText = `You have ${votesLeftCount} ${pluralize('vote', votesLeftCount)} left`
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const checkIcon = (checked: boolean): React.ReactNode => {
    if(!checked) {
      return <RadioButtonUncheckedIcon
        className={clsx(classes.icon, classes.uncheckedIcon)}
        data-test-id="unchecked-icon"
      />
    }

    return <CheckCircleIcon
      className={clsx(classes.icon, classes.checkCircleIcon)}
      data-test-id="check-circle-icon"
    />
  }

  if(votesLeftCount === 0) {
    return null
  }

  return <>
      <Grid
        container
        className={classes.root}
        data-test-id='voting-progress-banner'
        justifyContent={isMobile ? 'space-around' : 'center' }
        alignContent='center'
      >
        <Grid item xs={'auto'} mr={4}>
          <Typography className={classes.votesLeft}>{votesLeftText}</Typography>
        </Grid>
        <Grid item container xs={'auto'} spacing={2}>
          {rankingsSlice.map((ranking, index) => (
            <Grid item key={`voting-progress-banner-${index}`} data-test-id={`banner-checkmark-${index}`}>
              {checkIcon(ranking !== null)}
            </Grid>
          ))}
        </Grid>
      </Grid>
  </>
}

export default VotingProgressBanner;
