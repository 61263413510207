import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PollsList from './PollsList';
import { Poll } from './PollView/PollTypes';
import NoSuggestionsYet from "../../../../shared/NoSuggestionsYet";

type ClosedPollsSectionProps = {
  polls: Array<Poll>,
};

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(2),
  },
  pollsCount: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '24px',
    color: '#69737A',
  },
}));

const ClosedPollsSection = ({
  polls,
}: ClosedPollsSectionProps) => {
  const classes = useStyles();
  const hasClosedPolls = polls.length > 0;

  return <>
    <Grid
      container
      justifyContent="space-between"
      className={classes.header}
    >
      <Grid item>
        <Typography className={classes.pollsCount}>Closed polls ({polls.length})</Typography>
      </Grid>
    </Grid>
    {hasClosedPolls && (
      <PollsList polls={polls} />
    )}
    {!hasClosedPolls && <NoSuggestionsYet subheading="There are no closed polls." />}
  </>;
};

export default ClosedPollsSection;
