import React from 'react';
import pluralize from 'pluralize';
import {
  Avatar,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GlobalTrip } from '../../shared/TripTypes';

const useStyles = makeStyles(theme => ({
  traveler: {
    border: '1px solid white',
    background: '#0D2F45',
    marginLeft: `-${theme.spacing(2)}`,
    fontSize: 14,
    fontWeight: '600',
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  travelerCount: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    paddingTop: `${theme.spacing(3)} !important`,
  },
  travelerGroup: {
    marginTop: theme.spacing(2),
    display: 'flex',
    paddingLeft: theme.spacing(4),
  },
}));

type TravelersAvatarsProps = {
  trip: GlobalTrip,
}

const TravelersAvatars = ({
  trip,
}: TravelersAvatarsProps) => {
  const classes = useStyles();
  const tripDisplayedAvatars = ( trip : GlobalTrip ) : number => (
    trip.previewTravelers.length + trip.guests.length
  );

  const renderGuestAvatars = ( trip : GlobalTrip) =>{
    const guestToRender = trip.previewTravelers.length < 3 ? trip.guests.slice(0, 3 - trip.previewTravelers.length) : [];
    return(
      guestToRender.map( guest => (
        <Avatar
          className={classes.traveler}
          key={guest.uuid}
          data-test-id='guest-avatar'
        >
          <img
            alt="guest avatar"
            src='/assets/guest_avatar.svg'
            style={{maxWidth: '100%', maxHeight: '100%'}}
          />
        </Avatar>
      ))
    )
  };

  return (
    <Container>
      <Grid container spacing={1} direction="row">
        <Grid item className={classes.travelerGroup}>
          {trip.previewTravelers.map((traveler) => (
            <Avatar key={traveler.id} className={classes.traveler} src={traveler.avatarUrl} data-test-id='traveler-avatar'/>
          ))}
          {
            renderGuestAvatars(trip)
          }
          {trip.travelersCount > tripDisplayedAvatars(trip) && (
            <Avatar className={classes.traveler} data-test-id='overflow-avatar'>
              {'+' + (trip.travelersCount - tripDisplayedAvatars(trip))}
            </Avatar>
          )}
        </Grid>
        <Grid item className={classes.travelerCount} data-test-id='travelers-total'>
          <Typography>{trip.travelersCount} {pluralize('traveler', trip.travelersCount)} total</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TravelersAvatars;
