import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { hocRailsAction } from "../../Document";
import Errors, { ErrorsProps } from "../../shared/Errors";
import ModalForm from "../../shared/ModalForm";
import { FormContext } from "../../shared/RailsForm";
import { default as DateRangePicker } from "../../shared/CustomDateRangePicker";
import Button from "../../buttons/Button";
import TextButton from "../../buttons/TextButton";
import * as routes from '../../../routes';
import useStateWithPromise from "../../../services/useStateWithPromise";

const MIN_DATE_OPTIONS_ALLOWED = 1;
const MAX_DATE_OPTIONS_ALLOWED = 10;

const {
  tripDatesPath,
  tripDateOptionsPath,
} = routes;

const useStyles = makeStyles((theme) => ({
  addMoreDatesButton: {
    marginTop: theme.spacing(1),
  },
  error: {
    color: '#F44336',
    fontSize: 12,
    lineHeight: '18px',
  },
  removeButton: {
    display: 'flex !important',
    height: '56px !important',
    width: '60%',
    marginLeft: theme.spacing(1),
  },
  suggestion: {
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

type DateOptionType = {
  end_date: string | undefined,
  start_date: string | undefined,
}

type NewDateOptionPageProps = {
  dateOptions: DateOptionType[],
  existingDateOptions: DateOptionType[],
  errors: ErrorsProps['errors'],
  tripId: string,
}
const NewDateOptionPage = ({
  dateOptions,
  existingDateOptions,
  errors,
  tripId,
}: NewDateOptionPageProps) => {
  const classes = useStyles();
  const [dates, setDates] = useStateWithPromise(dateOptions);

  const canAddMoreDates = dates.length < MAX_DATE_OPTIONS_ALLOWED;
  const canRemoveDates = dates.length > MIN_DATE_OPTIONS_ALLOWED;

  const addDate = () => {
    if (!canAddMoreDates) return;

    setDates(dates.concat([{end_date: undefined, start_date: undefined}]));
  };

  const removeDate = (index, onFormChange) => {
    if (!canRemoveDates) return;

    const newArray = Array.from(dates);
    newArray.splice(index, 1);

    setDates(newArray).then(() => onFormChange());
  };

  const setSelectedDates = (index, selectedRange) => {
    const newArray = Array.from(dates);

    newArray[index]['start_date'] = selectedRange[0]?.format('MM/DD/YYYY');
    newArray[index]['end_date'] = selectedRange[1]?.format('MM/DD/YYYY');

    setDates(newArray);

    if (dates.length == 1 && !!dates[0]['start_date'] && !!dates[0]['end_date']) {
      addDate();
    }
  };

  const [alreadySuggested, setAlreadySuggested] = useState([]);
  const isDateAlreadySuggested = (date) => {
    return existingDateOptions.some((existingDate) => existingDate.start_date == date.start_date && existingDate.end_date == date.end_date);
  };
  const isDateAlreadySelected = (selectedDate) => {
    return validDates().filter((date) => selectedDate.start_date == date.start_date && selectedDate.end_date == date.end_date).length > 1;
  };

  useEffect(() => {
    let indexes = [];

    dates.forEach((date, index) => {
      if (isDateAlreadySuggested(date) || isDateAlreadySelected(date)) {
        indexes = indexes.concat(index);
      };
    });

    setAlreadySuggested(indexes);
  }, [dates]);

  const validDates = () => {
    return dates.filter((date) => date.start_date && date.end_date);
  };

  const invalidDates = () => {
    return dates.filter((date) => (!date.start_date && date.end_date) || (date.start_date && !date.end_date));
  };

  const shouldDisableForm = () => {
    return alreadySuggested.length > 0 || invalidDates().length > 0 || validDates().length == 0;
  };

  return (
    <ModalForm
      closePath={tripDatesPath(tripId)}
      disabledSubmit={shouldDisableForm()}
      formActionPath={tripDateOptionsPath(tripId)}
      title="Suggest dates"
      withChangeDetection
    >
      <Box paddingBottom={2}>
        <Typography>Suggest start and end dates for your group’s upcoming trip.</Typography>
      </Box>
      <Grid container spacing={2} direction="column">
        {errors.length > 0 && (
          <Grid item>
            <Errors errors={errors} />
          </Grid>
        )}
        <Grid item container spacing={3}>
          <FormContext.Consumer>
            {({ onFormChange }) => <>
              {dates.map((date, index) => (
                <Grid item container direction="column" spacing={1.5} key={index} data-test-id="suggestion">
                  <Grid item>
                    <Typography className={classes.suggestion}>Suggestion {index + 1}</Typography>
                  </Grid>
                  <Grid item container spacing={1} alignItems="center" flexWrap="nowrap">
                    <Grid item>
                      <DateRangePicker
                        id={`date-picker-${index}`}
                        autoComplete="off"
                        hasError={alreadySuggested.includes(index)}
                        endValue={date.end_date ? date.end_date : null}
                        startValue={date.start_date ? date.start_date : null}
                        helperText={false}
                        nameEnd={`date_option[${index}][end_date]`}
                        nameStart={`date_option[${index}][start_date]`}
                        onChange={(range) => {
                          setSelectedDates(index, range);
                          onFormChange();
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        className={classes.removeButton}
                        data-test-id={`remove-date-${index}`}
                        detachedFromForm
                        disabled={!canRemoveDates}
                        type='button'
                        variant='tertiary'
                        onClick={() => removeDate(index, onFormChange)}
                      >
                        <DeleteOutlineIcon fontSize='large'/>
                      </Button>
                    </Grid>
                  </Grid>
                  {alreadySuggested.includes(index) && (
                    <Grid item>
                      <Typography className={classes.error}>These dates have already been suggested</Typography>
                    </Grid>
                  )}
                </Grid>
              ))}
            </>}
          </FormContext.Consumer>
        </Grid>
        {canAddMoreDates && (
          <Grid item container justifyContent="center">
            <Grid item>
              <TextButton
                data-test-id='add-more-dates'
                detachedFromForm
                className={classes.addMoreDatesButton}
                onClick={() => addDate()}
              >
                + Add more dates
              </TextButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </ModalForm>
  );
};

NewDateOptionPage.defaultProps = {
  dateOptions: [{end_date: undefined, start_date: undefined}],
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(NewDateOptionPage);
