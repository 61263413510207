import React from "react";
import { makeStyles } from "@mui/styles";
import { Tab, Tabs } from "@mui/material";
import * as routes from '../../../../../routes';
import clsx from "clsx";

const {
  tripActivitiesPath,
  tripActivityGroupsPath,
} = routes;

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #b2b2b2',
  },
  label: {
    fontSize: theme.spacing(2),
    color: "#243060",
  },
  nonSelectedHover: {
    color: theme.palette.text.secondary,
    opacity: 1.0,
    '&:hover': {
      color: '#7D94A9',
    },
  },
  selectedHover: {
    '&:hover': {
      color: theme.palette.link.hover,
    },
  },
}));

export type Counter = {
  activities: number | '999+',
  groups: number | '999+',
};

type ActivitiesSectionNavigationProps = {
  active: 'All' | 'Activity groups',
  counter: Counter,
  tripId: string,
};

const ActivitiesSectionNavigation = ({
  active,
  counter,
  tripId,
}: ActivitiesSectionNavigationProps) => {
  const classes = useStyles();

  const tabs = [
    {
      label: 'All',
      count: counter.activities,
      path: tripActivitiesPath(tripId),
    },
    {
      label: 'Activity groups',
      count: counter.groups,
      path: tripActivityGroupsPath(tripId),
    },
  ];

  const activeValue = tabs.findIndex((tab) => tab.label === active);

  return (
    <Tabs
      classes={{
        root: classes.root,
      }}
      data-test-id='activitiesSectionNavigation'
      value={activeValue}
      textColor="inherit"
    >
      {tabs.map((tab, index) => (
        <Tab
          className={clsx(classes.label, {
            [classes.nonSelectedHover]: tab.label !== active,
            [classes.selectedHover]: tab.label === active,
          })}
          href={tab.path}
          key={`tab-${index}`}
          label={`${tab.label} (${tab.count})`}
          data-test-id={`${tab.label.replace(' ', '-')}-tab`}
        />
      ))}
    </Tabs>
  );
};

export default ActivitiesSectionNavigation;
