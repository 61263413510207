import axios, { AxiosError, AxiosResponse, Method } from 'axios';

type performAsyncRequestWithCancelTokenTypes = {
  method: Method,
  url: string,
  data: object,
  onSuccess: (response: AxiosResponse<any, any>) => void,
  onError: (response: AxiosError<any, any>) => void,
};

const performAsyncRequestWithCancelToken = ({
  method,
  url,
  data,
  onSuccess,
  onError,
}: performAsyncRequestWithCancelTokenTypes) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  setTimeout(() => {
    source.cancel();
  }, 8000);
  axios.request({
    method: method,
    cancelToken: source.token,
    url: url,
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    onSuccess(response);
  }).catch((response) => {
    onError(response);
  });
};

export default performAsyncRequestWithCancelToken;
