import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "../../../../../buttons/Button";
import { ActivityType } from "../../../../../shared/TripObjectTypes";
import ActivitiesMapView from './ActivitiesMapView';
import useExpandMapEventRef from "../../../../../../services/segmentEvents/useExpandMapEventRef";

type ActivitiesMapSectionProps = {
  activities: ActivityType[],
  onExpandMap?: () => void,
};

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  mapContainer: {
    marginBottom: theme.spacing(3),
  },
  mapWrapper: {
    width: '100%',
    height: `calc(80vh - ${theme.spacing(8)})`,
    left: 0,
  },
}));

const ActivitiesMapSection = ({
  activities,
  onExpandMap,
}: ActivitiesMapSectionProps) => {
  const classes = useStyles();

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const expandMapButtonRef = useExpandMapEventRef('activities', 'expand map');

  return !isMobile && (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.header}>
          Map view
        </Typography>
      </Grid>
      <Grid item className={classes.mapWrapper} data-test-id="side-bar-map">
        <ActivitiesMapView
          activities={activities}
        />
      </Grid>
      <Grid item>
        <Button
          data-test-id='expand-map'
          fullWidth
          ref={expandMapButtonRef}
          onClick={onExpandMap}
          variant="secondary"
        >
          Expand map
        </Button>
      </Grid>
    </Grid>
  );
};

export default ActivitiesMapSection;
