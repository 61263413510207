import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Label from "../../../../../shared/Label";
import Link from "../../../../../shared/Link";

type ActivityGroupsTitlesProps = {
  expanded?: boolean,
  titles: string[],
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#3689FF',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',
  },
  linkWrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: 'fit-content',
  },
  root: {
    zIndex: 2,
    cursor: 'initial',
    width: '100%',
    '& > div': {
      overflow: 'auto',
    },
  },
}));

const TitlesRow = ({ titles }: ActivityGroupsTitlesProps) => {
  return <>
    {titles.map((title, index) => (
      <Grid item key={index}>
        <Label label={title} />
      </Grid>
    ))}
  </>;
};

const ActivityGroupsTitles = ({
  expanded,
  titles,
}: ActivityGroupsTitlesProps) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(!expanded);
  const labelsPerRow = 2;

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      className={classes.root}
      flexWrap={collapsed ? "nowrap" : "wrap"}
    >
      {expanded && <TitlesRow titles={titles} />}
      {!expanded && <>
        <TitlesRow titles={titles.slice(0, labelsPerRow)} />
        {titles.length > labelsPerRow && (
          <>
            {!collapsed && <TitlesRow titles={titles.slice(labelsPerRow)} />}
            <Grid item className={classes.linkWrapper}>
              <Link
                className={classes.link}
                onClick={() => setCollapsed(!collapsed)}
                text={collapsed ? 'See groups' : 'Hide groups'}
              />
            </Grid>
          </>
        )}
      </>}
    </Grid>
  );
};

ActivityGroupsTitles.defaultProps = {
  expanded: false,
};

export default ActivityGroupsTitles;
