import React, { useState } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  rule: {
    background: '#c1c1c1',
    border: 'none',
    height: '1px',
    margin: 0,
  },
}));

const SectionDivider = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <hr className={classes.rule} />
    </Box>
  );
};

export default SectionDivider;
