import React from 'react';
import {
  Grid,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    textAlign: 'center'
  },
  message: {
    marginTop: theme.spacing(1),
  }
}));

const ErrorBoundaryMessage = ({

}) => {
  const classes = useStyles();

  return <>
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      className={classes.root}
    >
      <img
       src={`/assets/chat_alert_icon.svg`}
       data-test-id="alert-icon"
      />
      <div className={classes.message}>
        <Typography>
          Oops! Something went wrong.
        </Typography>
        <Typography>
          Try refreshing the page.
        </Typography>
      </div>
    </Grid>
  </>
}

export default ErrorBoundaryMessage;
