import React, { useContext } from "react";
import { FormContext } from "../../../shared/RailsForm";
import GooglePlacesAutocomplete, { Place } from "../../../shared/GooglePlacesAutocomplete";
import useStateWithPromise from "../../../../services/useStateWithPromise";

type AddressFieldProps = {
  required?: boolean,
  currentAddress?: string,
  currentGooglePlaceId?: string,
  currentGooglePlaceUrl?: string,
  currentImage?: string,
  currentLongitude?: number,
  currentLatitude?: number,
  onChange?: (address: Place) => void,
};

const AddressField = React.forwardRef(({
  currentAddress,
  currentGooglePlaceId,
  currentGooglePlaceUrl,
  currentImage,
  currentLongitude,
  currentLatitude,
  onChange,
  required,
}: AddressFieldProps, externalInputRef) => {
  const { onFormChange } = useContext(FormContext);
  const [address, setAddress] = useStateWithPromise({
    id: currentGooglePlaceId,
    image: currentImage,
    latitude: currentLatitude,
    longitude: currentLongitude,
    name: currentAddress,
    url: currentGooglePlaceUrl,
  });

  const addressValue = address ? address.name : '';
  const googlePlaceId = address ? address.id : '';
  const googlePlaceUrl = address ? address.url : '';
  const googlePlaceLocation = (address && address.longitude && address.latitude) ? `POINT(${address.longitude} ${address.latitude})` : '';
  const googlePlaceImage = address ? address.image : '';

  const handleAddressChange = (value: Place) => {
    setAddress(value).then(() => {
      onFormChange();
      if (onChange) onChange(value);
    });
  };

  return (
    <>
      <input
        type="hidden"
        value={addressValue || ""}
        name="stay[address]"
      />
      <input
        type="hidden"
        value={googlePlaceId || ""}
        name="stay[google_place_id]"
      />
      <input
        type="hidden"
        value={googlePlaceUrl || ""}
        name="stay[google_place_url]"
      />
      <input
        type="hidden"
        value={googlePlaceLocation || ""}
        name="stay[location]"
      />
      <input
        type="hidden"
        value={googlePlaceImage || ""}
        name="stay[google_place_image]"
      />
      <GooglePlacesAutocomplete
        defaultValue={currentAddress}
        onSelect={(value) => handleAddressChange(value)}
        fullWidth
        placeholder="Search by name or address"
        ref={externalInputRef}
        required={required}
      />
    </>
  );
});

export default AddressField;
