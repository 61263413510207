import React from 'react';
import { makeStyles } from "@mui/styles";
import {
  Grid,
} from '@mui/material';

type VotesCount = {
  yes: number,
  maybe: number,
  no: number,
};

export type VotingCountProps = {
  votesCount: VotesCount,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    border: '1px solid #C1C1C1',
    borderRadius: '5px',
    padding: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '22px',
  },
  yourVotes: {
    fontSize: 18,
    paddingRight: theme.spacing(1),
  },
  count: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '& img': {
      paddingRight: 12,
      verticalAlign: 'top',
    },
  },
}));

const VotesCount = ({ sentiment, count, icon }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.count} data-test-id={`${sentiment}Count`}>
      <img
        alt={sentiment}
        src={icon}
      />
      {count}
    </Grid>
  );
};

const VotingCount = ({
  votesCount,
}: VotingCountProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.yourVotes}>
        Your votes
      </Grid>
      <Grid item xs container>
        <VotesCount sentiment="yes" icon="/assets/illustrations/Yes.svg" count={votesCount.yes} />
        <VotesCount sentiment="maybe" icon="/assets/illustrations/Maybe.svg" count={votesCount.maybe} />
        <VotesCount sentiment="no" icon="/assets/illustrations/No.svg" count={votesCount.no} />
      </Grid>
    </Grid>
  );
};

export default VotingCount;
