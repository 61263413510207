import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Grid, TextField } from "@mui/material";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { GlobalContext } from "../../Document";
import Button from '../../buttons/Button';
import copyToClipboard from "copy-to-clipboard";
import {
  useFavoriteShareLinkCopiedEvent,
  useFavoriteShareSheetEvent,
} from "../../../services/segmentEvents/favoriteEvents";

type ShareFavoriteActionsProps = {
  listingCount?: number,
  shareUrl?: string,
};

const useStyles: () => any = makeStyles(() => ({
  copyField: {
    width: '100%',
  },
  buttonsContainer: {
    '& > div': {
      flexGrow: 1,
    },
    '& button': {
      height: '56px',
      width: '100%',
    },
  },
}));

const ShareFavoriteActions : React.FC<ShareFavoriteActionsProps> = ({
  listingCount,
  shareUrl,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [copied, setCopied] = React.useState(false);
  const { setSnackbar } = React.useContext(GlobalContext);
  const showSendButton = navigator['share'];
  const trackShareLinkCopiedEvent = useFavoriteShareLinkCopiedEvent(listingCount)
  const trackShareSheetEvent = useFavoriteShareSheetEvent(listingCount)

  const copyLink = () => {
    copyToClipboard(shareUrl);
    setCopied(true);
    setSnackbar && setSnackbar('Link copied to clipboard.', 3000, 'info');
    trackShareLinkCopiedEvent()
  };

  const sendLink = () => {
    const text = `${shareUrl}\n I'm using Troupe to find stays for our upcoming trip! Check out my favorites.`;
    navigator.share({ text });
    trackShareSheetEvent();
  };

  const renderButtons = () => (
    <Grid container spacing={1} justifyContent="center" className={classes.buttonsContainer}>
      {showSendButton && (
        <Grid item>
          <Button onClick={sendLink} variant="secondary" data-test-id="send-button">
            <img src="/assets/share_icon.svg" height="30px" width="22px" />
            {isMobile && <Box component="span" pl={1}> Send</Box>}
          </Button>
        </Grid>
      )}
      <Grid item>
        {copied ? (
          <Button variant="secondary" data-test-id="copied-button">
            <CheckCircleIcon />
          </Button>
        ) : (
          <Button onClick={copyLink} variant="secondary" data-test-id="copy-button">
            <FileCopyOutlinedIcon/>
            {isMobile && <Box component="span" pl={1}> Copy</Box>}
          </Button>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Grid item container justifyContent="space-between" spacing={1}>
      <Grid item xs={isMobile && 12} flexGrow={1}>
        <TextField
          className={classes.copyField}
          defaultValue={shareUrl}
          inputProps={{ readOnly: true }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={isMobile && 12}>
        {renderButtons()}
      </Grid>
    </Grid>
  );
};

export default ShareFavoriteActions;
