import React, { useEffect, useReducer} from "react";
import ModalForm from '../../shared/ModalForm'
import { hocRailsAction } from "../../Document";
import {
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import * as routes from "../../../routes";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import pluralize from 'pluralize';
import { makeStyles } from "@mui/styles";
import VotingDatesButtons, { Sentiments } from "./VotingDatesForm/VotingDatesButtons";
import VotingCount from "./VotingDatesForm/VotingCount";
import VotingProgressBar from "./VotingDatesForm/VotingProgressBar";
import { track as trackAnalyticsEvent } from "../../../services/analytics";
import InputWithCharacterCounter from "../../shared/InputWithCharacterCounter";

const {
  tripDatesPath,
  tripVotingPath,
} = routes;

type DateOptions = Array<{
    currentUserVote: Sentiments,
    endDate: string,
    endDateWeekday: string,
    id: string,
    startDate: string,
    startDateWeekday: string,
  }>;

type VotingDatesForm = {
  trip: {
    id: string,
    name: string,
  },
  dateOptions: DateOptions,
};

type Comments = {
  body: string,
  opened: boolean,
  commentDisabled: boolean,
};

type VotesCount = {
  yes: number,
  maybe: number,
  no: number,
  missing: number,
};

type VotesState = {
  votesCount: VotesCount,
  previousVotes: VotesCount,
  datesComments: Comments[],
};

type ReducerAction = {
  type: 'initialState' | 'voteChanged' | 'toggleCommentsFieldVisibility' | 'commentsFieldChanged' | 'updateSentimentCount',
  body?: string,
  commentDisabled?: boolean,
  dateOptions?: DateOptions,
  index?: number,
  newSentiment?: Sentiments,
  oldSentiment?: Sentiments,
};

const reducer = (state: VotesState, action: ReducerAction) => {
  const datesArray = Array.from(state.datesComments);
  switch (action.type) {
    case "initialState":
      const votes = { yes: 0, maybe: 0, no: 0, missing: 0 };
      action.dateOptions.map((dateOption) => {
        votes[dateOption.currentUserVote] = votes[dateOption.currentUserVote] + 1;
      });
      return { ...state, votesCount: votes, previousVotes: votes };
    case "voteChanged":
      datesArray[action.index] = {
        ...datesArray[action.index],
        commentDisabled: action.commentDisabled,
        opened: action.commentDisabled ? !action.commentDisabled : datesArray[action.index].opened,
      };
      return { ...state, datesComments: datesArray };
    case "toggleCommentsFieldVisibility":
      datesArray[action.index].opened =  !datesArray[action.index].opened;
      return { ...state, datesComments: datesArray };
    case "commentsFieldChanged":
      datesArray[action.index] = {
        ...datesArray[action.index],
        body: action.body,
        commentDisabled: action.body != '',
      };
      return { ...state, datesComments: datesArray };
    case "updateSentimentCount":
      const newVotes = {
        ...state.votesCount,
        [action.oldSentiment]: state.votesCount[action.oldSentiment] - 1,
        [action.newSentiment]: state.votesCount[action.newSentiment] + 1,
      };
      return { ...state, votesCount: newVotes};
  };
};

const useStyles = makeStyles(theme => ({
  arrow: {
    verticalAlign: 'bottom',
  },
  commentField: {
    marginTop: theme.spacing(2),
    '& input': {
      height: '14px',
    },
  },
  date: {
    fontSize: 18,
  },
  dateOptionGrid: {
    maxWidth: 175,
  },
  dateOptionSegment: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  divider: {
    borderColor: "#c1c1c1",
    width: "inherit",
    paddingTop: theme.spacing(3),
  },
  votingCount: {
    paddingTop: theme.spacing(2),
  },
  votingGridContainer: {
    paddingTop: theme.spacing(3),
  },
  weekday: {
    fontSize: 15,
    fontWeight: 600,
  },
}));

const VotingDatesForm = ({
  trip,
  dateOptions,
}: VotingDatesForm) => {
  const classes = useStyles();
  const datesCommentsInitialState = () => {
    let initialArray = Array(dateOptions.length);
    initialArray.fill({ opened: false, body: '', commentDisabled: null });
    return initialArray;
  };
  const initialVotesState = { yes: 0, maybe: 0, no: 0, missing: dateOptions.length };
  const initialState: VotesState = {
    datesComments: datesCommentsInitialState(),
    votesCount: initialVotesState,
    previousVotes: initialVotesState,
  };
  const [{ datesComments, previousVotes, votesCount }, dispatch] = useReducer(reducer, initialState);
  const sameAsPreviousVotes = (JSON.stringify(previousVotes) === JSON.stringify(votesCount));

  useEffect(() => {
    dispatch({ type: 'initialState', dateOptions: dateOptions })
  }, [dateOptions]);

  const currentVotes = () => {
    return votesCount.yes + votesCount.maybe + votesCount.no;
  };

  const allDatesVoted = () => {
    return votesCount.missing === 0;
  };

  const disableSubmitButton = () => {
    if (sameAsPreviousVotes && !datesComments.some((element) => element.body !== '')) {
      return true;
    };

    return !allDatesVoted();
  };

  const onFormSubmit = (event) => {
    const previouslyVoted = previousVotes.missing !== dateOptions.length;

    if (!sameAsPreviousVotes && previouslyVoted) {
      trackAnalyticsEvent('Date Vote Changed', {
        category: 'Dates',
        previousVotes: previousVotes,
        newVotes: votesCount,
        tripId: trip.id,
      });
    };
  };

  return (
    <ModalForm
      closePath={tripDatesPath(trip.id)}
      formActionPath={tripVotingPath(trip.id)}
      formMethod="post"
      onClick={(event) => onFormSubmit(event)}
      title={`Vote on ${dateOptions.length} ${pluralize('date', dateOptions.length)} for "${trip.name}"`}
      disabledSubmit={disableSubmitButton()}
      saveBarTopContent={
        <VotingProgressBar totalVotes={dateOptions.length} currentVotes={currentVotes()} />
      }
    >
      <Grid container direction="column">
        <Grid item>
          <Typography>
            For each date, select ‘Yes’, ‘Maybe’ or ‘No’ depending on your availability.
          </Typography>
        </Grid>
        <Grid item className={classes.votingCount}>
          <VotingCount votesCount={votesCount} />
        </Grid>
        <Grid item>
          {dateOptions.map((dateOption, index) => (
            <>
              <Grid
                alignItems="flex-start"
                className={classes.votingGridContainer}
                container
                data-test-id={`dateVoteOption${index}`}
                flexDirection='row'
                justifyContent="space-between"
                key={index}
              >
                <Grid
                  key={index}
                  container justifyContent="space-between"
                  alignItems="center"
                  flexDirection='row'
                  className={classes.dateOptionGrid}
                >
                  <Grid item className={classes.dateOptionSegment}>
                    <Typography className={classes.weekday}>{dateOption.startDateWeekday}</Typography>
                    <Typography className={classes.date}>{dateOption.startDate}</Typography>
                  </Grid>
                  <Grid item>
                    <ArrowForwardIcon className={classes.arrow}/>
                  </Grid>
                  <Grid item className={classes.dateOptionSegment}>
                    <Typography className={classes.weekday}>{dateOption.endDateWeekday}</Typography>
                    <Typography className={classes.date}>{dateOption.endDate}</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <VotingDatesButtons
                    currentUserVote={dateOption.currentUserVote}
                    dateOptionId={dateOption.id}
                    isCommentDisabled={datesComments[index].commentDisabled}
                    key={dateOption.id}
                    onCommentButtonClick={() => dispatch({ type: 'toggleCommentsFieldVisibility', index: index })}
                    onVoteChange={(commentDisabled) => dispatch({ type: 'voteChanged', index: index, commentDisabled: commentDisabled })}
                    updateSentimentCount={(oldSentiment, newSentiment) =>
                      dispatch({type: 'updateSentimentCount', oldSentiment: oldSentiment, newSentiment: newSentiment})}
                  />
                </Grid>
                {datesComments[index].opened &&
                  <InputWithCharacterCounter
                    className={classes.commentField}
                    testId="comment-field"
                    fullWidth
                    multiline
                    characterLimit={5000}
                    name={`comments[${dateOption.id}]`}
                    onChange={(event) => dispatch(
                      {
                        type: 'commentsFieldChanged',
                        body: event.target.value,
                        index: index,
                      }
                    )}
                    placeholder="Add a comment"
                    withBorder
                  />
                }
              </Grid>
              <Divider className={classes.divider}></Divider>
            </>
          ))}
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(VotingDatesForm);
