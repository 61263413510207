import React from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Stack } from '@mui/material';
import type { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import { makeStyles, DefaultTheme } from "@mui/styles";

export type PickerPosition = {
  horizontal: PickerHorizontalPosition,
  vertical: PickerVerticalPosition,
}
type EmojiPickerProps = {
  onEmojiSelect: (emoji: BaseEmoji, event) => void,
  pickerPosition: PickerPosition,
  isFullWidth?: boolean,
  permapage?: boolean,
}

const useStyles = makeStyles<DefaultTheme, {isTopHalf: boolean, isRightHalf: boolean}>((theme) => ({
  picker: {
    zIndex: 1002,
    [theme.breakpoints.up(470)]: {
      position: 'absolute',
      top: ({isTopHalf}) => isTopHalf ? '0' : 'auto',
      bottom: ({isTopHalf}) => isTopHalf ? 'auto' : '40px',
      left: 'auto',
      right: ({isRightHalf}) => isRightHalf ? theme.spacing(1) : 'auto',
    },
    [theme.breakpoints.down(470)]: {
      margin: '0 !important',
      bottom: 0,
      position: 'fixed',
      left: 0,
      right: 0,
      top: 'auto',
      '& em-emoji-picker' : {
        width: '100%',
      },
    },
  },
}));

export enum PickerHorizontalPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum PickerVerticalPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

const EmojiPicker = React.forwardRef(({
  onEmojiSelect,
  pickerPosition: { horizontal, vertical },
  isFullWidth = false,
  permapage = false,
} : EmojiPickerProps, ref) => {
  if(!ref) return null;

  const classes = useStyles({
    isTopHalf: vertical !== PickerVerticalPosition.TOP && !permapage,
    isRightHalf: horizontal === PickerHorizontalPosition.RIGHT,
  });

  return (
    <Stack className={classes.picker} ref={ref} data-test-id='picker-wrapper' onClick={(event) => event.preventDefault()}>
      <Picker
        data={data}
        onEmojiSelect={onEmojiSelect}
        data-test-id='picker'
        dynamicWidth={isFullWidth}
      />
    </Stack>
  )
})

EmojiPicker.displayName = 'EmojiPicker';

export default EmojiPicker;
