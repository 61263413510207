import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import Button from "../../../../buttons/Button";
import ConfirmationDialog from "../../../../shared/ConfirmationDialog";
import { Poll } from './PollView/PollTypes';

import {
  closeTripPollPath,
} from "../../../../../routes";
import Tooltip from "../../../../shared/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 500,
    display: 'inline',
  },
}));

type CloseButtonProps = {
  disableButton: boolean,
  poll: Poll,
};

const CloseButton = ({
  disableButton,
  poll,
}: CloseButtonProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const tooltipTitle = disableButton ? 'Only the planner or person who created this poll can close it.' : '';

  return <>
    <Tooltip
      data-test-id="close-poll-tooltip"
      title={tooltipTitle}
    >
      <span>
        <Button
          data-test-id="closePollButton"
          disabled={disableButton}
          fullWidth
          onClick={() => setDialogOpen(true)}
          variant="secondary"
        >
          Close Poll
        </Button>
      </span>
    </Tooltip>
    <ConfirmationDialog
      actionPath={closeTripPollPath(poll.tripId, poll.id)}
      actionMethod="PATCH"
      actionText="Close poll"
      buttonVariant="primary"
      isOpen={dialogOpen}
      message={(
        <Typography className={classes.root}>
          <span className={classes.bold}>Heads up! </span>
          This will close the poll for everyone on your trip and prevent anyone else from voting.
        </Typography>
      )}
      onClose={() => setDialogOpen(false)}
      title="Close this poll?"
    />
  </>;
};

export default CloseButton;
