import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from '@mui/styles';
import Avatar from "../../../avatars/Avatar";
import TravelerActionsButtonGroup from "./TravelerActionsButtonGroup";
import {
  TravelersType,
} from "../TravelersByStatusType";
import CreatePlusOneForm from "./CreatePlusOneForm";
import AdditionalTravelerActions from "./AdditionalTravelerActions";
import { TripSiteContext } from '../../../Document';
import Link from "../../../shared/Link";
import Tooltip from "../../../shared/Tooltip";
import HelpIcon from '@mui/icons-material/Help';
import { CREATE_PLUS_ONE } from '../../../../constants/queryParams';
import useSegment from "../../../../services/useSegment";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PLUS_ONE_DELETE } from "../../../../graphql/queries/plusOnes";
import { TRAVELERS_QUERY } from "../../../../graphql/queries/travelers";
import { PlusOneType } from "../../../../types/PlusOneTypes";

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: 'auto',
  },
  helpIcon: {
    color: '#9DAFBE',
  },
  info: {
    paddingTop: `8px !important`,
  },
  joinedTrip: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: '12px',
    marginTop: theme.spacing(0.5),
  },
  error: {
    color: '#e44444',
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  plusOnes: {
    '&:first-child': {
      marginTop: theme.spacing(1),
    },
  },
  treeViewButton: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
  avatarContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '0px !important',
    },
  },
}));

type TravelerInfoProps = {
  isCurrentUserPlanner: boolean,
  traveler: TravelersType,
  tripId: string,
  onRemoveTraveler: (travelers: TravelersType[]) => void,
  hidePlusOneAddButton?: boolean,
};

const TravelerInfo = ({
  isCurrentUserPlanner = false,
  traveler,
  tripId,
  onRemoveTraveler,
  hidePlusOneAddButton = false,
}: TravelerInfoProps) => {
  const classes = useStyles();
  const { trackSegment } = useSegment();
  const { currentUserId } = useContext(TripSiteContext);
  const isMobileApp = useMediaQuery(useTheme().breakpoints.down('sm'));
  const displayWhenQueryParam = (new URLSearchParams(window.location.search).get(CREATE_PLUS_ONE) === 'true')
  const currentTravelerOrPlanner = currentUserId === traveler?.user?.uuid || isCurrentUserPlanner;
  const displayFormInitialValue = displayWhenQueryParam && currentTravelerOrPlanner;
  const [removeTravelerError, setRemoveTravelerError] = useState('');
  const [plusOneFormAutoFocus, setPlusOneFormAutoFocus] = useState(true);

  const [displayPlusOnes, setDisplayPlusOnes] = useState(displayFormInitialValue);
  const [plusOneCount, setPlusOneCount] = useState(traveler.plusOnes?.length || 0);
  const [addPlusOneEnabled, setAddPlusOneEnabled] = useState(plusOneCount === 0);
  const [displayPlusOnesLink, setDisplayPlusOnesLink] = useState(addPlusOneEnabled && currentTravelerOrPlanner);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if(isMounted) {
      setPlusOneCount(traveler.plusOnes?.length || 0);
      setAddPlusOneEnabled(traveler.plusOnes?.length === 0);
      setDisplayPlusOnesLink((traveler.plusOnes?.length === 0) && currentTravelerOrPlanner)
      setDisplayPlusOnes(traveler.plusOnes?.length > 0);
    } else {
      setIsMounted(true);
    }
  }, [traveler]);

  const renderPlusOneActions : React.FC<PlusOneType> = (plusOne) => {
    return (
        currentTravelerOrPlanner ?
            <Grid item className={classes.actions}>
              <AdditionalTravelerActions additionalTraveler={plusOne} resourceName={'plus-one'} displayName={'Plus 1'}
                                         mutation={PLUS_ONE_DELETE} refetchOperation={TRAVELERS_QUERY}/>
            </Grid>
            :
            null
    );
  }

  const treeViewToggle = () => {
    setDisplayPlusOnes(!displayPlusOnes);
    setPlusOneFormAutoFocus(false);

    trackSegment('Traveler Details Toggled', {
      isMobileApp,
      travelerId: traveler.id,
      state: displayPlusOnes ? 'collapsed' : 'expanded',
    });
  };

  const onPlusOnesButtonClick = () => {
    setDisplayPlusOnes(true);
    setPlusOneFormAutoFocus(true);
  };

  const plusOnes = traveler.plusOnes?.map((plusOne: PlusOneType, index: number) => {
    return (
      <Grid container pl={5} ml={1} spacing={1} key={`plusOne-${index}`} className={classes.plusOnes}>
        <Grid item>
          <Avatar>
            <img src='/assets/plus_one_avatar.svg' width="40" height="40" />
          </Avatar>
        </Grid>
        <Grid item mt={1}>
          <Typography>
            {plusOne.displayName}
          </Typography>
        </Grid>
        {renderPlusOneActions( plusOne )}
      </Grid>
    )
  });

  return (
    <>
      <Grid container spacing={1}>
        {plusOneCount > 0 &&
          <Grid sx={{paddingLeft: '0 !important'}} item>
            <IconButton onClick={treeViewToggle} data-test-id='tree-view' className={classes.treeViewButton}>
              {displayPlusOnes ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon/>}
            </IconButton>
          </Grid>
        }
        <Grid item className={classes.avatarContainer}>
          <Avatar src={traveler.avatarUrl} plusOnes={plusOneCount} size={isMobileApp ? "sm" : "md"} />
        </Grid>
        <Grid item className={classes.info}>
          <Typography>
            {traveler.displayName}
          </Typography>
          <Typography className={classes.joinedTrip}>
            Joined trip {traveler.joinedAt}
          </Typography>
          {removeTravelerError !== '' && (
            <Grid item className={classes.error}>
              {removeTravelerError}
            </Grid>
          )}
        </Grid>
        {isCurrentUserPlanner && !traveler.isPlanner &&
          <Grid item className={classes.actions}>
            <TravelerActionsButtonGroup
              tripId={tripId}
              travelerId={traveler.uuid}
              onRemoveTraveler={onRemoveTraveler}
              onRemoveTravelerError={setRemoveTravelerError}
              plusOneCount={plusOneCount}
            />
          </Grid>
        }
      </Grid>
      <Grid>
        <Grid id={`traveler-${traveler.uuid}-plus-ones`} container>
          {displayPlusOnes && plusOnes}
        </Grid>
      </Grid>
      {displayPlusOnes && currentTravelerOrPlanner && !hidePlusOneAddButton &&
        <CreatePlusOneForm
          traveler={traveler}
          autoFocus={plusOneFormAutoFocus}
        onPlusOneFormClose={() => setDisplayPlusOnes(false)}/>
      }
      {!displayPlusOnes && displayPlusOnesLink && !hidePlusOneAddButton &&
        <Grid container alignItems="start" mx={3} my={1} spacing={1}>
          <Grid item>
           <Link
               className={classes.link}
               data-test-id="add-plus-one-button"
               onClick={onPlusOnesButtonClick}
               text='+ Add Plus-one'
           />
         </Grid>
          <Grid item mx={1}>
            <Tooltip
                placement="bottom-start"
                title={'Plus-ones are for headcount only. They will not be able to suggest, vote, or comment.'}
            >
              <HelpIcon className={classes.helpIcon} data-test-id="help-icon"/>
            </Tooltip>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default TravelerInfo;