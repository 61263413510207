import { gql } from "@apollo/client";
import { DESTINATION_PAGE_FIELDS } from "../fragments/destinations";

export const DESTINATIONS_LIST_QUERY = gql`
  ${DESTINATION_PAGE_FIELDS}
  query DestinationsList($tripId: ID!, $sortBy: DestinationSorting) {
    destinations(tripId: $tripId, sortBy: $sortBy) {
      id
      ...DestinationPageFields
    }
    travelers(tripId: $tripId) {
      id
      avatarUrl
      displayName
      status
      destinationRankings
      destinationMissingVotesCount
      userId
    }
  }
`;
