import React, { useState } from "react";
import { Grid } from "@mui/material";
import { track as trackAnalyticsEvent } from "../../../../../../services/analytics";
import { ActivitySentimentType } from '../../../../../shared/TripObjectTypes';
import { DialogBottomOnMobile } from '../../../../../shared/Dialog';
import VotesTable from "./ActivityVote/VotesTable";
import VoteResultsButton from "./ActivityVote/VoteResultsButton";

type ActivityVotingSummaryDialogProps = {
  activityId: string,
  isPlanner: boolean,
  votes: ActivitySentimentType,
  votesCount: number,
};

const ActivityVotingSummaryDialog = ({
  isPlanner,
  votes,
  votesCount,
  activityId,
}: ActivityVotingSummaryDialogProps) => {
  const [isDialogOpen, setIsDialogOpen ] = useState(false);

  const onButtonClick = () => {
    setIsDialogOpen(true);

    trackAnalyticsEvent('See All Votes Selected', {
      isPlanner: isPlanner,
      tripObjectId: activityId,
      vertical: 'activities',
    });
  };

  return (
    <>
      <DialogBottomOnMobile
        title="Voting results"
        fullWidth
        maxWidth="xs"
        message={
          <VotesTable votes={votes}/>
        }
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
      </DialogBottomOnMobile>
      <Grid item>
        <VoteResultsButton
          votesCount={votesCount}
          votes={votes}
          onClick={() => onButtonClick()}
        />
      </Grid>
    </>
  );
};

export default ActivityVotingSummaryDialog;
