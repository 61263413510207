import useSegment from '../useSegment';
import { CustomFiltersAppliedType } from '../../components/pages/stays_search/StaysSearch';
import { inventoryBannerSegmentTextType } from '../../components/pages/stays_search/Listings/InventoryBanner';
import moment from "moment";
import { ModelOutputs } from "../../components/pages/stays_search/Listings/ListingsBox";
import { searchLocation } from "../windowLocation";

export const useTrackSERPEvent = () => {
  const { trackSegment, trackLinkSegment } = useSegment();

  const location = searchLocation()

  return {
    trackSERPEvent: (title: string, props: {}) => {
      trackSegment(title, { ...props, location })
    },
    trackSERPLinkEvent: (element: HTMLElement, title: string, props: {}) => {
      trackLinkSegment(element, title, { ...props, location })
    },
  };
};

type SearchPerformedEvent = {
  arrival?: string,
  filtersApplied: CustomFiltersAppliedType,
  datesBanner: inventoryBannerSegmentTextType,
  departure?: string,
  destinationSearched: string,
  destinationLatLong: [number, number],
  guestsCount?: string,
  inventorySelected: string,
  resultCountPage: number,
  searchMethod?: string,
  totalResultCount: number,
  url: string,
  bedroomCount?: string,
  hotelRoomCount?: string,
  prompt?: string,
  modelOutputs?: ModelOutputs,
};

export const useTrackSearchPerformed = () => {
  const { trackSERPEvent } = useTrackSERPEvent();

  return (data: SearchPerformedEvent) => {
    const { arrival, departure, ...otherData } = data;

    const hasDates = !!arrival && !!departure;
    const nightCount = hasDates ? moment(departure).diff(moment(arrival), 'days') : 0;

    trackSERPEvent('Stays Search Performed', {
      ...(hasDates && { datesSelected: `${arrival} - ${departure}` }),
      nightCount,
      ...otherData,
    });
  };
};
