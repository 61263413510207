import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  ButtonGroup,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteResourceConfirmationDialog from "../../../../../shared/DeleteResourceConfirmationDialog";
import { Poll } from './PollTypes';

import {
  tripPollPath,
} from "../../../../../../routes";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 30,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: 36,
    },
  },
  buttonGroup: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #B4B9BC',
    color: theme.palette.text.primary,
  },
  root: {
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: '5px',
  },
}));

type DeleteButtonProps = {
  poll: Poll,
};

const DeleteButton = ({
  poll,
}: DeleteButtonProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Box className={classes.root}>
      <ButtonGroup
        classes={{
          groupedOutlined: classes.buttonGroup,
        }}
        disableElevation
        variant="outlined"
        size="small"
      >
        <Button
          data-test-id="deletePollButton"
          onClick={() => setDialogOpen(true)}
          className={classes.button}
        >
          <DeleteIcon fontSize="small" />
        </Button>
      </ButtonGroup>
      <DeleteResourceConfirmationDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        path={tripPollPath(poll.tripId, poll.id)}
        resourceName="poll"
      />
    </Box>
  );
};

export default DeleteButton;
