import React, {useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Checkbox,
  Grid,
  FormControlLabel,
  Typography,
  Stack,
} from "@mui/material";

export type PropertyType = {
  checked: boolean,
  label: string,
  name: string,
};

type PropertyTypesProps = {
  onPropertyTypeCheck: Function,
  propertyTypes: PropertyType[],
};

const useStyles = makeStyles((theme) => ({
  selectAllText: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const PropertyTypes = ({
  onPropertyTypeCheck,
  propertyTypes,
} : PropertyTypesProps) => {
  const classes = useStyles();
  const areAllSelected = () => propertyTypes.every((propertyType) => propertyType.checked);
  const [allSelected, setAllSelected] = useState(areAllSelected());

  const propertyTypesLength = propertyTypes.length;
  const firstColumnLimit = Math.ceil(propertyTypesLength / 2);
  const propertyTypesFirstColumn = propertyTypes.slice(0, firstColumnLimit);
  const propertyTypesSecondColumn = propertyTypes.slice(firstColumnLimit - propertyTypesLength);

  React.useEffect(() => {
    setAllSelected(areAllSelected());
  }, [propertyTypes]);

  const selectAll = () => {
    const checked = !areAllSelected();
    onPropertyTypeCheck(propertyTypes.map((propertyType) => ({...propertyType, checked})));
  }

  const onCheck = (index, event) => {
    onPropertyTypeCheck(propertyTypes.map((propertyType, i) => (i === index ? {...propertyType, checked : event.currentTarget.checked} : propertyType)));
  }

  const checkBoxes = (propertyType: PropertyType, index: number) => {
    return (
      <React.Fragment key={propertyType.label}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              data-test-id={`propertyType-${propertyType.name.replaceAll(' ', '-')}`}
              data-test-checked={propertyType.checked}
              id={propertyType.name}
              name="checkbox"
              checked={propertyType.checked}
              onChange={(event) => onCheck(index, event)}
            />
          }
          label={propertyType.label}
        />
      </React.Fragment>
    )
  }

  return <>
    <Grid
      item
      container
      justifyContent="space-between"
    >
      <Typography variant="h3">Property type</Typography>
      <Typography
        variant="h3"
        className={classes.selectAllText}
        data-test-id="selectAllText"
        onClick={() => selectAll()}
      >
        {allSelected ? "deselect all" : "select all"}
      </Typography>
    </Grid>
    <Stack direction='row' spacing={2} pr={2} mt={2} justifyContent='space-between'>
      <Stack>
        {propertyTypesFirstColumn.map((propertyType, index) =>
          checkBoxes(propertyType, index),
        )}
      </Stack>
      <Stack mt={2} mr={2}>
        {propertyTypesSecondColumn.map((propertyType, index) =>
          checkBoxes(propertyType, index + firstColumnLimit),
        )}
      </Stack>
    </Stack>
  </>;
};

export default PropertyTypes;
