export const destinationsByTripType = [
  {
    tripType: 'Friends',
    description: 'Discover the optimal adventure spot for your groups’ excursion.',
    destinations: [
      { name: 'Las Vegas, NV', image: '/assets/trip-type-destinations/Friends/Vegas.png', latitude: 36.171563, longitude: -115.1391009 },
      { name: 'Nashville, TN', image: '/assets/trip-type-destinations/Friends/Nashville.png', latitude: 36.1626638, longitude: -86.7816016 },
      { name: 'Orlando, FL', image: '/assets/trip-type-destinations/Friends/Orlando.png', latitude: 28.5383832, longitude: -81.3789269 },
      { name: 'Athens, Greece', image: '/assets/trip-type-destinations/Friends/Athens.png', latitude: 37.9838096, longitude: 23.7275388 },
      { name: 'London, UK', image: '/assets/trip-type-destinations/Friends/London.png', latitude: 51.5072178, longitude: -0.1275862 },
      { name: 'Boston, MA', image: '/assets/trip-type-destinations/Friends/Boston.png', latitude: 42.3600825, longitude: -71.0588801 },
      { name: 'Charleston, SC', image: '/assets/trip-type-destinations/Friends/Charleston.png', latitude: 32.7764749, longitude: -79.93105120000001 },
    ],
  },
  {
    tripType: 'Birthday',
    description: 'Find the ideal destination to celebrate your birthday getaway in style.',
    destinations: [
      { name: 'Montego Bay, Jamaica', image: '/assets/trip-type-destinations/Birthday/MontegoBay.png', latitude: 18.4762228, longitude: -77.8938895 },
      { name: 'Punta Cana, Dominican Republic', image: '/assets/trip-type-destinations/Birthday/PuntaCana.png', latitude: 18.5600761, longitude: -68.372535 },
      { name: 'New Orleans, LA', image: '/assets/trip-type-destinations/Birthday/NewOrleans.png', latitude: 29.95106579999999, longitude: -90.0715323 },
      { name: 'Cancún, Mexico', image: '/assets/trip-type-destinations/Birthday/Cancun.png', latitude: 21.161908, longitude: -86.8515279 },
      { name: 'Nassau, The Bahamas', image: '/assets/trip-type-destinations/Birthday/Nassau.png', latitude: 25.0443312, longitude: -77.3503609 },
      { name: 'Tokyo, Japan', image: '/assets/trip-type-destinations/Birthday/Tokyo.png', latitude: 35.6761919, longitude: 139.6503106 },
      { name: 'Cabo San Lucas, Mexico', image: '/assets/trip-type-destinations/Birthday/CaboSanLucas.png', latitude: 22.8905327, longitude: -109.9167371 },
    ],
  },
  {
    tripType: 'Bachelorette',
    description: 'Find the perfect destination for an unforgettable bachelorette trip.',
    destinations: [
      { name: 'Miami, FL', image: '/assets/trip-type-destinations/Bachelor/Miami.png', latitude: 25.7616798, longitude: -80.1917902 },
      { name: 'Las Vegas, NV', image: '/assets/trip-type-destinations/Bachelorette/Vegas.png', latitude: 36.171563, longitude: -115.1391009 },
      { name: 'Tulum, Mexico', image: '/assets/trip-type-destinations/Bachelorette/Tulum.png', latitude: 20.2114185, longitude: -87.46535019999999 },
      { name: 'San Diego, CA', image: '/assets/trip-type-destinations/Bachelorette/SanDiego.png', latitude: 32.715738, longitude: -117.1610838 },
      { name: 'Charleston, SC', image: '/assets/trip-type-destinations/Bachelorette/Charleston.png', latitude: 32.7764749, longitude: -79.93105120000001 },
      { name: 'Austin, TX', image: '/assets/trip-type-destinations/Bachelorette/Austin.png', latitude: 30.267153, longitude: -97.7430608 },
      { name: 'Scottsdale, AZ', image: '/assets/trip-type-destinations/Bachelorette/Scottsdale.png', latitude: 33.4948764, longitude: -111.9216734 },
    ],
  },
  {
    tripType: 'Bachelor',
    description: 'Discover the prime location for a one-in-a-lifetime bachelor celebration.',
    destinations: [
      { name: 'Scottsdale, AZ', image: '/assets/trip-type-destinations/Bachelor/Scottsdale.png', latitude: 33.4948764, longitude: -111.9216734 },
      { name: 'Miami, FL', image: '/assets/trip-type-destinations/Bachelor/Miami.png', latitude: 25.7616798, longitude: -80.1917902 },
      { name: 'New Orleans, LA', image: '/assets/trip-type-destinations/Bachelor/NewOrleans.png', latitude: 29.95106579999999, longitude: -90.0715323 },
      { name: 'Denver, CO', image: '/assets/trip-type-destinations/Bachelor/Denver.png', latitude: 39.7392358, longitude: -104.990251 },
      { name: 'Nashville, TN', image: '/assets/trip-type-destinations/Bachelor/Nashville.png', latitude: 36.1626638, longitude: -86.7816016 },
      { name: 'Philadelphia, PA', image: '/assets/trip-type-destinations/Bachelor/Philadelphia.png', latitude: 39.9525839, longitude: -75.1652215 },
      { name: 'Las Vegas, NV', image: '/assets/trip-type-destinations/Bachelor/Vegas.png', latitude: 36.171563, longitude: -115.1391009 },
    ],
  },
  {
    tripType: 'Family',
    description: "Seek out the perfect destination for your family's memorable retreat.",
    destinations: [
      { name: 'Orlando, FL', image: '/assets/trip-type-destinations/Family/Orlando.png', latitude: 28.5383832, longitude: -81.3789269 },
      { name: 'Paris, France', image: '/assets/trip-type-destinations/Family/Paris.png', latitude: 48.856614, longitude: 2.3522219 },
      { name: 'Rome, Italy', image: '/assets/trip-type-destinations/Family/Rome.png', latitude: 41.9027835, longitude: 12.4963655 },
      { name: 'London, UK', image: '/assets/trip-type-destinations/Family/London.png', latitude: 51.5072178, longitude: -0.1275862 },
      { name: 'San Juan, Puerto Rico', image: '/assets/trip-type-destinations/Family/SanJuan.png', latitude: 18.4670588, longitude: -66.1184593 },
      { name: 'Myrtle Beach, SC', image: '/assets/trip-type-destinations/Family/MyrtleBeach.png', latitude: 33.6890603, longitude: -78.8866943 },
      { name: 'Destin, FL', image: '/assets/trip-type-destinations/Family/Destin.png', latitude: 30.3935337, longitude: -86.4957833 },
    ],
  },
];

export default destinationsByTripType;
