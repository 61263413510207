import React from 'react';
import {
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles(theme => ({
  alertMessage: {
    fontSize: theme.spacing(2),
    fontWeight: 600,
    lineHeight: '24px',
  },
  missingVoteAlert: {
    backgroundColor: '#F4F5FF',
    height: '45px',
  },
  errorIcon: {
    color: '#7D94A9',
    marginRight: theme.spacing(1),
    verticalAlign: 'bottom',
  },
}));

const MissingVoteAlert = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      className={classes.missingVoteAlert}
      data-test-id="missing-vote-alert"
      alignItems="center"
      justifyContent="center">
      <Grid item >
         <ErrorIcon className={classes.errorIcon}/>
       </Grid>
       <Grid item>
         <Typography className={classes.alertMessage}>Missing your vote</Typography>
       </Grid>
    </Grid>
  );
};

export default MissingVoteAlert;
