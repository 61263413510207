import React from "react";
import ConfirmationDialog, { ConfirmationDialogProps } from "./ConfirmationDialog";

export type DeleteConfirmationDialogProps = ConfirmationDialogProps & {
  path: string,
  title: string,
  message: React.ReactNode,
};

const DeleteConfirmationDialog = ({
  path,
  title,
  message,
  ...confirmationDialogProps
}: DeleteConfirmationDialogProps) => {
  return (
    <ConfirmationDialog
      actionPath={path}
      actionMethod="DELETE"
      actionText="Delete"
      buttonVariant="danger"
      message={message}
      title={title}
      {...confirmationDialogProps}
    />
  );
};

export default DeleteConfirmationDialog;
