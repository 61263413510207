import React from "react";
import { Typography, Stack, Collapse } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { track } from '../../../../services/analytics';
import { SegmentContext } from "../../../Document";

const useStyles = makeStyles((theme) => ({
  ItineraryDatesChangeExplanation: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderTop: "1px solid #BEC9D4",
    borderBottom: "1px solid #BEC9D4",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#5C7993',
  },
  toggleButton: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#545DFC',
    cursor: 'pointer',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
    marginTop: theme.spacing(2),
  },
}));

const ItineraryDatesChangeExplanation = () => {
  const [isShowing, setIsShowing] = React.useState(false);
  const classes = useStyles();
  const { tripId, isPlanner } = React.useContext(SegmentContext);

  const onToggleClick = () => {
    setIsShowing( prevState => {
      track('Itinerary Edit Dates Tip Toggled', {
        tripId,
        planner: isPlanner,
        status: prevState ? 'hide' : 'visible',
      });
      return !prevState;
    } )
  }

  return (
  <div className={classes.ItineraryDatesChangeExplanation}>
    <Stack flexDirection="row" justifyContent="space-between">
      <Typography className={classes.title}>
        How will changing dates affect your itinerary?
      </Typography>
      <Typography 
        className={classes.toggleButton}
        onClick={onToggleClick}
      >
        {isShowing ? 'Hide' : 'Show'}
      </Typography>
    </Stack>
    <Collapse in={isShowing}>
      <Typography className={classes.text}>
        Events added to a numbered day on your itinerary will stay attached to that day,
        even if your trip’s start and end dates change. 
        <br />
        <br />
        Ex: Your original trip dates were May 1 - May 7 and a “Welcome lunch”
        event is scheduled for Day 1. Your trip dates then change to Oct 1 - Oct 7.
        The “Welcome lunch” will remain attached to Day 1, which is now Oct 1.
      </Typography>
    </Collapse>
  </div>
)};

export default ItineraryDatesChangeExplanation;