import React from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button, { ButtonProps, ButtonType, ButtonVariant } from "../buttons/Button";
import clsx from "clsx";

type FixedSaveBarProps = ButtonProps & {
  buttonContent: React.ReactNode,
  className?: string,
  extraButton?: React.ReactNode,
  onClick?: ((event: any) => void) | null,
  saveButtonVariant?: ButtonVariant,
  saveButtonType?: ButtonType,
  topContent?: React.ReactNode,
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    padding: theme.spacing(2),
    [`@supports (padding-bottom: env(safe-area-inset-bottom)) and (padding-bottom: max(${theme.spacing(2)}))`]: {
      paddingBottom: `max(env(safe-area-inset-bottom), ${theme.spacing(2)})`,
    },
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: '0px -1px 5px 0px rgba(0, 0, 0, 0.2)',
    zIndex: 3,
    display: 'flex',
  },
}));

const FixedSaveBar = ({
  buttonContent,
  className,
  extraButton,
  onClick,
  saveButtonVariant = "primary",
  saveButtonType = "submit",
  topContent,
  ...buttonProps
}: FixedSaveBarProps) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <Grid container direction="column" spacing={2}>
        {topContent && (
          <Grid item>
            {topContent}
          </Grid>
        )}
        <Grid item container spacing={2}>
          {extraButton && (
            <Grid item xs>
              {extraButton}
            </Grid>
          )}
          <Grid item xs>
            <Button
              fullWidth
              onClick={onClick ? onClick : null}
              variant={saveButtonVariant}
              type={saveButtonType}
              {...buttonProps}
            >
              {buttonContent}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FixedSaveBar;
