import React, { useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteResourceConfirmationDialog from "../../../shared/DeleteResourceConfirmationDialog";
import LeaveConfirmationDialog from "../../../shared/LeaveConfirmationDialog";
import * as routes from '../../../../routes';
import { track as trackAnalyticsEvent } from "../../../../services/analytics";
import { SegmentContext } from "../../../Document";

const {
  tripPath,
  tripTravelerPath,
} = routes;

type TripActionsProps = {
  deletable: boolean,
  isPlanner: boolean,
  travelerId: string,
  tripId: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1,
  },
  iconButton: {
    width: 32,
    height: 32,
    backgroundColor: "#FFFFFF",
    border: `1px solid ${theme.palette.general.background.disabled}`,
    "&:hover": {
      backgroundColor: '#F6F6F6',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  icon: {
    color: '#334351',
  },
  exitToAppIcon: {
    transform: 'scaleX(-1)',
    marginRight: theme.spacing(1),
    marginLeft: `-${theme.spacing(1)}`,
  },
  deleteIcon: {
    marginRight: theme.spacing(1),
    marginLeft: `-${theme.spacing(1)}`,
  },
}));

const TripActions = ({ deletable, isPlanner, travelerId, tripId }: TripActionsProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false)
  const handleCloseMenu = () => setAnchorEl(null);
  const { currentUserId } = useContext(SegmentContext);
  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
    handleCloseMenu()
  };
  const handleLeaveClick = () => {
    setLeaveDialogOpen(true);
    trackAnalyticsEvent('Leave Trip Initiated', {
      tripId: tripId,
      userId: currentUserId,
    });
    handleCloseMenu()
  };

  return (
    <Box className={classes.root}>
      <IconButton
        className={classes.iconButton}
        data-test-id="tripActionsButton"
        onClick={(event) => setAnchorEl(event.target)}
      >
        <MoreHorizIcon fontSize="large" className={classes.icon} />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleDeleteClick} dense disabled={!deletable}>
          <DeleteIcon fontSize="small" className={classes.deleteIcon}/>
          Delete trip
        </MenuItem>
        <MenuItem onClick={handleLeaveClick} dense disabled={isPlanner} data-test-id="leave-trip">
          <ExitToAppIcon fontSize="small" className={classes.exitToAppIcon}/>
          Leave trip
        </MenuItem>
      </Menu>
      <DeleteResourceConfirmationDialog
        confirmationToggleText="Yes, I still want to delete"
        fullWidthButtons
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        path={tripPath(tripId)}
        resourceName="trip"
      />
      <LeaveConfirmationDialog
        fullWidthButtons
        isOpen={leaveDialogOpen}
        onClose={() => setLeaveDialogOpen(false)}
        path={tripTravelerPath(tripId, travelerId)}
      />
    </Box>
  );
};

export default TripActions;
