import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import pluralize from 'pluralize';
import activitySentiments from './activitySentiments';
import { ActivitySentimentType } from '../../../../../../shared/TripObjectTypes';
import TextButton from "../../../../../../buttons/TextButton";
import SentimentIcon from "./SentimentIcon";
import textButtonColors from "../../../../../../buttons/textButtonColors";

type VoteResultsButtonProps = {
  votes: ActivitySentimentType,
  votesCount: number,
  onClick?: () => void,
};

type StyleProps = {
  activityHasNoVotes: boolean,
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  activeSentiments: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  votesCount: {
    marginLeft: ({activityHasNoVotes}) => activityHasNoVotes ? 0 : theme.spacing(4),
    color: textButtonColors.link.default.color,
    fontSize: theme.spacing(2),
    fontWeight: textButtonColors.link.default.fontWeight,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  votesContainer: {
    zIndex: 2,
  },
}));

const VoteResultsButton = ({
  votes,
  votesCount,
  onClick,
}: VoteResultsButtonProps) => {
  const isSentimentVoted = (sentimentValue) => votes[sentimentValue].length > 0;
  const activityHasNoVotes = Object.values(votes).flat().length === 0
  const classes = useStyles({activityHasNoVotes});

  return (
    <TextButton
      className={classes.votesContainer}
      data-test-id="see-votes-button"
      onClick={onClick}
    >
      <span className={classes.activeSentiments}>
        {activitySentiments.map(({icon, value}, index) => (
          (isSentimentVoted(value) &&
            <SentimentIcon
              icon={icon}
              index={index}
            />
          )
        ))}
      </span>
      <span className={classes.votesCount}>
        View {votesCount} {pluralize('vote', votesCount)}
      </span>
    </TextButton>
  );
};

export default VoteResultsButton;
