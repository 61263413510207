import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pl: 1 }}>
           {children}
        </Box>
      )}
    </div>
  );
};

type TabNavigationProps = {
  activeTab: number,
  tabLabels: string[],
  children: React.ReactNode;
  onTabChange: (event: React.SyntheticEvent, value: number) => void,
}

const TabNavigation = ({
  activeTab,
  tabLabels,
  children,
  onTabChange,
}: TabNavigationProps) => {

  return (
    <Box sx={{ width: '100%' }} data-test-id='tab-navigation'>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={onTabChange} data-test-id='tabs-list'>
          {tabLabels.map((tabLabel, index) => (
            <Tab
              data-test-id={`manage-${index}`}
              label={tabLabel}
              key={`tab-${index}`}
              sx={{fontSize: '1rem'}}
            />
          ))}
        </Tabs>
      </Box>
      {children}
    </Box>
  );
}

export default TabNavigation;
