export const TRIP_TYPES = {
  bachelorette_bachelor: '🎉 Bachelorette/Bachelor',
  cruise: '🛳️ Cruise',
  destination: '🏝️ Destination',
  friends: '👯 Friends',
  outdoors_ski: '🌲 Outdoors/Ski',
  birthday_milestone: '🎈 Birthday/Milestone',
  family: '🏡 Family',
  girls_trip: '💃️ Girls Trip',
  other: 'Other'
};
