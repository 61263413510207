import React from "react";
import TripObjectLink from "../../../../../shared/TripObjectLink";

type StayAddressProps = {
  googlePlaceUrl: string,
};

const StayAddress = ({
  googlePlaceUrl,
}: StayAddressProps) => {

  return (
    <TripObjectLink
      url={googlePlaceUrl}
      linkText='View on map'
      dataTestId='stay-address-link'
    />
  );
};

export default StayAddress;
