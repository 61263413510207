import React from "react";
import clsx from "clsx";
import { ButtonBase } from "@mui/material";
import { makeStyles } from "@mui/styles";

export type LinkProps = {
  className?: string,
  disabled?: boolean,
  href?: string,
  onClick?: () => void,
  onMouseDown?: (event) => void,
  target?: string,
  text: React.ReactNode,
};

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    color: theme.palette.link.default,
    '&:hover': {
      color: theme.palette.link.hover,
    },
    '&:focus': {
      color: theme.palette.link.focused,
    },
    '&:active': {
      color: theme.palette.link.pressed,
    },
  },
  disabled: {
    color: theme.palette.link.disabled,
  },
}));

const Link = ({
  className,
  disabled,
  text,
  onMouseDown,
  ...otherProps
}: LinkProps) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={clsx(classes.root, className, { [classes.disabled]: disabled })}
      disableRipple
      onMouseDown={onMouseDown}
      {...otherProps}
    >
      {text}
    </ButtonBase>
  );
};

export default Link;
