import React, {useContext, useEffect} from "react";
import {
  Box,
  Typography,
  Stack,
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from "@mui/styles";
import ConfirmationDialog from "./ConfirmationDialog";
import pluralize from "pluralize";
import Button from "../buttons/Button";
import useSegment from "../../services/useSegment";
import { GlobalContext } from "../Document";

type VotingProgressDialogProps = {
  totalVotes: number,
  votedCount: number,
  isOpen: boolean,
  closeDialog: () => void,
  vertical: string,
  voteCompleted?: boolean,
};

const useStyles = makeStyles( theme => ({
  dialogWrapper: {
    textAlign: 'center',
    maxWidth: 400,
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: 24,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  description: {
    paddingBottom: theme.spacing(3),
    color: theme.palette.text.primary,
    margin: '0 auto',
  },
  circleHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 43,
    width: 43,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  incompleteCircle: {
    width: '85%',
    height: '85%',
    borderRadius: '50%',
    border: '3px solid #BEC9D4',
    backgroundColor: '#F2F3FF',
  },
  completeCircle: {
    width: '100%',
    height: '100%',
    color: '#1EBE95',
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}));

const VotingProgressDialog = ({
  totalVotes,
  votedCount,
  isOpen,
  closeDialog,
  vertical,
  voteCompleted = false,
}: VotingProgressDialogProps) => {
  const classes = useStyles();
  const totalCircles = totalVotes > 3 ? 3 : totalVotes;
  const completedCircles = votedCount;
  const incompletedCircles = totalCircles - completedCircles;
  const { trackSegment } = useSegment();
  const { activateConfetti } = useContext(GlobalContext);

  React.useEffect(() => {
    if (isOpen) {
      trackSegment(`Voting ${voteCompleted ? 'Complete' : 'Progress'} Modal Viewed`,
        {
          vertical,
        },
      );
    }
  }, [isOpen]);

  const handleClose = () => {
    trackSegment(`Voting ${voteCompleted ? 'Complete' : 'Progress'} Modal Dismissed`,
      {
        vertical,
      },
    );
    closeDialog();
  };

  const renderCompleteCircles = () => {
    const displayCircles : React.ReactNode[] = Array.from(Array(totalCircles).keys()).map( i => (
      <Box key={`circle-${i}`} className={classes.circleHolder}>
        {i < completedCircles ? (
          <CheckCircleIcon
            className={classes.completeCircle}
            data-test-id="complete-circle"
          />
        ) : (
          <div
            className={classes.incompleteCircle}
            data-test-id="incomplete-circle"
          />
        )}
      </Box>
    ) );

    return(
      <Stack flexDirection="row" justifyContent="center">
        {displayCircles}
      </Stack>
    )
  };

  const title = voteCompleted ? 'Congrats!' : 'Success! 🎉';

  const description = voteCompleted
    ? "You've cast all your votes. Your group is one step closer to making a decision!"
    : `${incompletedCircles} ${pluralize('vote', incompletedCircles)} remaining. Make it count!`;

  const renderButton = () => (
    !voteCompleted &&
    <Button className={classes.button} onClick={handleClose}>{"Let's do it"}</Button>
  )

  useEffect(() => {
    if (isOpen && voteCompleted) {
      activateConfetti()
    }
  }, [isOpen, voteCompleted])

  return(
    <ConfirmationDialog
      hideCancelButton
      isOpen={isOpen}
      message={
        <Box className={classes.dialogWrapper}>
          <Typography className={classes.title}>
            {title}
          </Typography>
          <Box textAlign={voteCompleted ? "center" : "left"}>
            <Typography className={classes.description}>
              {description}
            </Typography>
            {renderCompleteCircles()}
            {renderButton()}
          </Box>
        </Box>
      }
      onClose={handleClose}
    />
  )
};

export default VotingProgressDialog;
