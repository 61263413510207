import React from "react";
import { makeStyles } from "@mui/styles";
import voteSentiments from "../../pages/trips/microsite/SuggestedDatesPage/voteSentiments";
import clsx from "clsx";
import AvatarGroup, { AvatarType } from "../AvatarGroup";

type VotesBreakdownType = {
  maxAvatarsLength?: number,
  votes: {
    maybe: AvatarType[],
    no: AvatarType[],
    yes: AvatarType[],
  },
};

const useVotesBreakdownStyles = makeStyles((theme) => ({
  root: {
    borderLeft: '2px solid #D4D5D6',
    listStyleType: 'none',
    paddingLeft: theme.spacing(3),
    '& span': {
      float: 'left',
    },
    '& ul': {
      listStyleType: 'none',
      padding: 0,
    },
    marginBottom: 0,
  },
  sentiment: {
    display: "flex",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginTop: 0,
    },
  },
  sentimentLabel: {
    display: "flex",
    alignItems: "center",
    height: theme.spacing(4),
    width: theme.spacing(17),
    [theme.breakpoints.down(350)]: {
      width: '75%',
    },
  },
  sentimentLabelText: {
    display: "flex-item",
    fontSize: 16,
    fontWeight: 600,
    marginRight: theme.spacing(0.5),
    verticalAlign: "middle",
    lineHeight: '16px',
  },
  sentimentLabelEmoji: {
    fontSize: 22,
    marginRight: theme.spacing(2),
    verticalAlign: 'center',
  },
  sentimentLabelCount: {
    color: '#272727',
    display: "flex-item",
    fontSize: 14,
    verticalAlign: "middle",
    lineHeight: '16px',
  },
  disabledItem: {
    color: `${theme.palette.general.background.disabled}!important`,
  },
}));

const VotesBreakdown = ({ votes, maxAvatarsLength }: VotesBreakdownType) => {
  const classes = useVotesBreakdownStyles();
  const isDisabled = (value, length) => { return value === 'missing' && length === 0};

  const renderVotesRow = (label, sentiment, icon) => {
    const sentimentVotes = votes[sentiment];
    const isSentimentDisabled = isDisabled(sentiment, sentimentVotes.length);

    const hasPlusOnes = sentimentVotes.filter(({ plusOneCount }) => plusOneCount > 0).length > 0;

    return (
      <li key={sentiment} data-test-id={`${sentiment}-votes`} className={classes.sentiment}>
        <span className={classes.sentimentLabel}>
          <span className={classes.sentimentLabelEmoji}>
            <img src={`/assets/illustrations/${icon}.svg`}/>
          </span>
          <section
            className={clsx({[classes.disabledItem]: isSentimentDisabled})}
            data-test-id={`${sentiment}-votes-row`}>
            <span className={classes.sentimentLabelText}>{label}{' '}</span>
            <span className={clsx(classes.sentimentLabelCount, {[classes.disabledItem]: isSentimentDisabled})}>
              ({sentimentVotes.length}){hasPlusOnes && '*'}
            </span>
          </section>
        </span>
        <AvatarGroup disabled={sentiment === 'missing'} max={maxAvatarsLength} avatars={sentimentVotes} />
      </li>
    );
  };

  return <>
    <ul data-test-id='votes-breakdown' className={classes.root}>
      {voteSentiments.map(({ label, value, icon }) => (
        renderVotesRow(label, value, icon)
      ))}
    </ul>
  </>;
};

export default VotesBreakdown;
