import React, { useContext, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FilteringAndSortingBar from "../shared/FilteringAndSortingBar";
import useStateWithPromise from "../../../../../services/useStateWithPromise";
import { FormContext } from "../../../../shared/RailsForm";
import {
  ActivityFilterContext,
  ActivitySortingContext,
} from "../../../../shared/ActivityFilterContext";

type ActivitiesFilteringAndSortingBarProps = {
  disabled?: boolean,
  formPath: string,
  tripId: string,
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    fontWeight: 300,
    transform: 'scale(1)',
  },
}));

const ActivitiesFilteringAndSortingBar = ({
  disabled,
  formPath,
  tripId,
}: ActivitiesFilteringAndSortingBarProps) => {
  const classes = useStyles();

  const {
    allowedCategories,
    categories,
    currentFilters,
    currentFiltersCount,
    activitiesWithoutVotesCount,
    activitiesWithVotesCount,
  } = useContext(ActivityFilterContext);

  const {
    currentSorting,
    sortings,
  } = useContext(ActivitySortingContext);

  const [
    appliedFiltersCount,
    setAppliedFiltersCount,
  ] = useState(currentFiltersCount);

  const onDrawerClose: () => void = () => {
    setAppliedFiltersCount(currentFiltersCount);
  };

  const [selectedCategories, setSelectedCategories] = useStateWithPromise(currentFilters.categories);
  const [selectedWithVotes, setSelectedWithVotes] = useState(currentFilters.withVotes)
  const [selectedWithoutVotes, setSelectedWithoutVotes] = useState(currentFilters.withoutVotes)
  const allCategoriesSelected = selectedCategories.length == allowedCategories.length;
  const disableWithVotesCheckbox = activitiesWithVotesCount === 0 && !currentFilters.withVotes
  const disableWithoutVotesCheckbox = activitiesWithoutVotesCount === 0 && !currentFilters.withoutVotes
  const isInterestSort = (sort) => ['interest_descending', 'interest_ascending'].includes(sort)
  const [selectedInterestLevel, setSelectedInterestLevel] = useState(isInterestSort(currentSorting))
  const selectOrDeselectAll = (onFormChange) => {
    const newArray = allCategoriesSelected ? [] : allowedCategories;
    setSelectedCategories(newArray).then(() => onFormChange());
  };

  const disableWithoutVotes = (sort) => {
    if (isInterestSort(sort)){
      setSelectedInterestLevel(true)
    } else {
      setSelectedInterestLevel(false)
    }
  }

  const handleCategoryCheck = (category) => {
    const newArray = Array.from(selectedCategories);

    if (newArray.includes(category)) {
      newArray.splice(newArray.indexOf(category), 1);
    } else {
      newArray.push(category);
    }

    setSelectedCategories(newArray);
    setAppliedFiltersCount(newArray.length);
  };

  return (
    <FilteringAndSortingBar
      currentFiltersCount={appliedFiltersCount}
      currentSorting={currentSorting}
      disabled={disabled && !(selectedWithVotes || selectedWithoutVotes)}
      formPath={formPath}
      onClose={onDrawerClose}
      sortByRadioGroupName="activity_filter[sort_by]"
      vertical="Activities"
      sortings={sortings}
      selectedWithoutVotes={selectedWithoutVotes}
      selectedInterestLevel={disableWithoutVotes}
      isInterestSort={isInterestSort}
      filters={
        <>
          <Grid item>
            <Typography variant="h2">
              Filters
            </Typography>
          </Grid>
          <Grid item container direction="column" data-test-id="activityVotesFilter">
            <Grid item container justifyContent="space-between">
              <InputLabel className={classes.label}>
                Votes
              </InputLabel>
            </Grid>
            <Grid item>
              <Box pt={1}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disableWithVotesCheckbox}
                        checked={selectedWithVotes}
                        color="primary"
                        value={selectedWithVotes}
                        name="activity_filter[with_votes]"
                        id="withVotesCheckbox"
                        data-test-id="withVotesCheckbox"
                      />
                    }
                    label={"Activities with votes"}
                    onChange={() => setSelectedWithVotes(!selectedWithVotes)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disableWithoutVotesCheckbox || selectedInterestLevel}
                        checked={selectedWithoutVotes}
                        color="primary"
                        value={selectedWithoutVotes}
                        name="activity_filter[without_votes]"
                        id="withoutVotesCheckbox"
                        data-test-id="withoutVotesCheckbox"
                      />
                    }
                    label={"Activities without votes"}
                    onChange={() => setSelectedWithoutVotes(!selectedWithoutVotes)}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item container direction="column" data-test-id="categoryFilter">
            <Grid item container justifyContent="space-between">
              <InputLabel className={classes.label}>
                Categories
              </InputLabel>
              <FormContext.Consumer>
                {({ onFormChange }) => (
                  <Link
                    component="button"
                    type="button"
                    onClick={() => selectOrDeselectAll(onFormChange)}
                    underline="none"
                    variant="h3"
                  >
                    {allCategoriesSelected ? "DESELECT ALL" : "SELECT ALL"}
                  </Link>
                )}
              </FormContext.Consumer>
            </Grid>
            <Grid item>
              <Box pt={1}>
                {currentFilters && categories.map(category => (
                  <Box key={category}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!allowedCategories.includes(category)}
                          checked={selectedCategories.includes(category)}
                          color="primary"
                          value={category}
                          name="activity_filter[categories][]"
                        />
                      }
                      data-test-id="categoryCheckbox"
                      label={category}
                      onChange={() => handleCategoryCheck(category)}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ActivitiesFilteringAndSortingBar;
