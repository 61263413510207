import React, { useContext, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  TextField,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";
import { GlobalContext } from "../../Document";
import Button from "../../buttons/Button";
import { useMutation } from "@apollo/client";
import { AI_SEARCH_FEEDBACK_ADD_MESSAGE } from "../../../graphql/queries/aiSearchFeedbacks";
import useSegment from "../../../services/useSegment";
import {searchLocation} from "../../../services/windowLocation";

type SearchFeedbackDialog = {
  onClose: () => void,
  onSubmit: () => void,
  open: boolean,
  responseId: string,
};

const useStyles = makeStyles((theme) => ({
  button: {
    height: 'auto',
    padding: theme.spacing(1),
    fontSize: 14,
  },
  copy: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
  },
  closeButton: {
    padding: 0,
    lineHeight: '20px',
    '& svg': {
      fontSize: 16,
      color: '#9DAFBE',
    },
  },
}));

const SearchFeedbackDialog = ({
  onClose,
  onSubmit,
  open,
  responseId,
}: SearchFeedbackDialog) => {
  const classes = useStyles();
  const [message, setMessage] = useState(null);
  const { setSnackbar } = useContext(GlobalContext);
  const [addMessageAiSearchFeedback] = useMutation(AI_SEARCH_FEEDBACK_ADD_MESSAGE);
  const { trackSegment } = useSegment();

  const onDialogSubmit = () => {
    if (!message) return;

    addMessageAiSearchFeedback({
      variables: {
        aiSearchFeedbackId: responseId,
        message,
      },
      onCompleted: ({ aiSearchFeedbackAddMessage: { errors }}) => {
        if(errors.length === 0) {
          trackSegment('Troupie Share Feedback Submitted',
            {
              comment: message,
              location: searchLocation(),
            },
          )
          onSubmit();
        } else {
          setSnackbar('Something went wrong.', 3000, 'error');
        }
      },
    });
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <Grid container direction="column" justifyContent="space-between" spacing={2}>
          <Grid item container alignItems="flex-start" justifyContent="space-between" flexWrap="nowrap">
            <Grid item>
              <Typography className={classes.copy}>
                How can we improve Troupie?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              autoFocus
              type="text"
              fullWidth
              multiline
              rows={5}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Grid>
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item flexGrow={1}>
              <Button variant="tertiary" fullWidth className={classes.button} onClick={onClose}>
                Close
              </Button>
            </Grid>
            <Grid item flexGrow={1}>
              <Button fullWidth data-test-id="submit" className={classes.button} onClick={onDialogSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SearchFeedbackDialog;
