import React, { useContext } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import Modal from "./Modal";
import Button from "../buttons/Button";
import { makeStyles } from "@mui/styles";
import { track as trackAnalyticsEvent } from "../../services/analytics";
import { SegmentContext } from "../Document";

const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    maxWidth: '215px',
  },
  emptyStateModalContainer: {
    height: '100%',
  },
}));

type EmptyStateModalFormProps = {
  buttonHref: string,
  buttonText: string,
  closePath: string,
  imgSrc: string,
  subtitle: string,
  title: string,
}

const EmptyStateModalForm = ({
  buttonHref,
  buttonText,
  closePath,
  imgSrc,
  subtitle,
  title,
}: EmptyStateModalFormProps) => {
  const classes = useStyles();
  const { tripId, isPlanner } = useContext(SegmentContext);
  const handleOnClick = () => {
    trackAnalyticsEvent(`${buttonText} Clicked`, {
      isPlanner: isPlanner,
      tripId: tripId,
    })
  }

  return (
    <Grid container justifyContent='center' className={classes.emptyStateModalContainer}>
      <Modal
        title=''
        subtitle=''
        closePath={closePath}
        saveBar={false}
        withDivider={false}
      >
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          pt={4}
          flexDirection='column'
          spacing={2}
        >
          <Grid item>
            <Typography variant="h2">
              {title}
            </Typography>
          </Grid>
          <Grid item >
            <img alt='' data-test-id='empty-state-modal-form-src' src={imgSrc} className={classes.img}/>
          </Grid>
          <Grid item>
            <Typography textAlign='center'>
              {subtitle}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              data-test-id="go-to-button"
              href={buttonHref}
              onClick={() => handleOnClick()}
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  )
};

export default EmptyStateModalForm;
