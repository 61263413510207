import React, { useContext } from "react";
import flowRight from "lodash/flowRight";
import {
  Box,
  ButtonBase,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TripSiteContext } from '../../../Document';
import {
  addBySearchTripStaysPath,
  addBySmartSearchTripStaysPath,
  addByURLTripStaysPath,
  addByGoogleMapsTripStaysPath,
  tripStaysPath,
} from '../../../../routes';
import withLayout from "../../trips/microsite/withLayout";

const useStyles = makeStyles((theme) => ({
  backButton: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
  },
  root: {
    borderBottom: '1px solid #b2b2b2',
    width: '100%',
  },
  tabsContainer: {
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  header: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '36px',
  },
  label: {
    fontSize: theme.spacing(2),
    color: "#243060",
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  nonSelectedHover: {
    color: theme.palette.text.secondary,
    opacity: 1.0,
    '&:hover': {
      color: '#7D94A9',
    },
  },
  selectedHover: {
    '&:hover': {
      color: theme.palette.link.hover,
    },
  },
  badge: {
    backgroundColor: '#D3302F',
    borderRadius: 4,
    color: '#FFFFFF',
    fontSize: 12,
    fontWeight: 600,
    padding: '2px 4px',
  },
}));

type TabProps = {
  label: string | React.ReactNode,
  path: string,
  testId: string,
};

export const withAddStayPageLayout = (Page) => (function withAddStayPageLayout(props) {
  const classes = useStyles();
  const { tripId } = useContext(TripSiteContext);

  const tabs : TabProps[] = [
    { label: 'By search', path: addBySearchTripStaysPath(tripId), testId: 'By-search-tab' },
    { label: 'By URL', path: addByURLTripStaysPath(tripId), testId: 'By-URL-tab' },
    { label: 'By Google Maps', path: addByGoogleMapsTripStaysPath(tripId), testId: 'By-Google-Maps-tab' },
  ];

  const smartSearchTab = {
    label: <div>By Smart Search <span className={classes.badge}>New!</span></div>,
    path: addBySmartSearchTripStaysPath(tripId),
    testId: 'By-Smart-Search-tab',
  };

  tabs.unshift(smartSearchTab);

  const isActiveTab = (tab) => tab.path === window.location.pathname;

  const activeValue = tabs.findIndex((tab) => isActiveTab(tab));

  return (
    <Stack alignItems="flex-start" mt={3} spacing={4}>
      <ButtonBase href={tripStaysPath(tripId)} className={classes.backButton}>
        <ArrowBackIosIcon fontSize="small" /> Back to stays
      </ButtonBase>

      <Typography data-test-id='add-stay-header' className={classes.header}>Add stays</Typography>

      <Tabs
        classes={{
          root: classes.root,
          flexContainer: classes.tabsContainer,
        }}
        value={activeValue === -1 ? 0 : activeValue}
        textColor="inherit"
      >
        {tabs.map((tab, index) => (
          <Tab
            className={clsx(classes.label, {
              [classes.nonSelectedHover]: !isActiveTab(tab),
              [classes.selectedHover]: isActiveTab(tab),
            })}
            href={tab.path}
            key={`tab-${index}`}
            label={tab.label}
            data-test-id={tab.testId}
          />
        ))}
      </Tabs>

      <Box sx={{ width: '100%' }}>
        <Page {...props} />
      </Box>
    </Stack>
  );
});

const fullWidthLayout = (Page, props) => {
  return withLayout(Page, { maxWidth: false, ...props })
};

export default flowRight(fullWidthLayout, withAddStayPageLayout);
