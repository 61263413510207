import React, { useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

type ActivityImageProps = {
  imageUrl: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: "100%",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(21),
    },
    objectFit: 'cover',
    objectPosition: 'center',
    background: '#F4F5FF',
  },
  small: {
    maxHeight: 200,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
}));

const ActivityImage = ({
  imageUrl,
}: ActivityImageProps) => {
  const classes = useStyles();
  const [imageSource, setImageSource] = useState(imageUrl);
  const fallbackSource = "/assets/activity_fallback.svg";

  return (
    <div className={classes.wrapper}>
      <img
        data-test-id='activity-image'
        className={clsx(
          classes.root,
          classes.small,
        )}
        onError={() => setImageSource(fallbackSource)}
        src={imageSource || fallbackSource}
      />
    </div>
  );
};

export default ActivityImage;
