import React from "react";
import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { ActivityCategoryType } from "../../../../shared/TripObjectTypes";

type CategoryLabelProps = {
  category: ActivityCategoryType,
  imageLabel?: boolean,
  selectBar?: boolean,
  dialog?: boolean,
};

type HEX = `#${string}`;

const useStyles = makeStyles((theme) => ({
  choiceLabel: {
    color: '#fff',
    fontWeight: 600,
  },
  imageLabel: {
    position: 'absolute',
    bottom: theme.spacing(-2),
    left: theme.spacing(2),
  },
  nonImageLabel: {
    bottom: theme.spacing(1),
    position: 'relative',
  },
  selectBar: {
    position: 'relative',
  },
}))

export const CategoryLabel = ({
  category,
  imageLabel,
  selectBar,
  dialog,
}: CategoryLabelProps ) => {
  const classes = useStyles();

  const categoryColors: Record<ActivityCategoryType, HEX> = {
    Arts: '#007C8D',
    Classes: '#1B896C',
    Cruises: '#235850',
    Entertainment: '#B15C69',
    Food: '#EC5E5E',
    Landmarks: '#F16719',
    Nightlife: '#272727',
    Outdoors: '#5C9914',
    Recreation: '#0595D2',
    Shopping: '#2264DF',
    Tours: '#A05AE5',
    Wellness: '#4C5BE9',
  }

  return (
      <Chip
        data-test-id="category-label"
        className={clsx(classes.choiceLabel,
          {
            [classes.imageLabel]: imageLabel,
            [classes.nonImageLabel]: !imageLabel && !selectBar && !dialog,
            [classes.selectBar]: selectBar,
          },
        )}
        label={category} style={ {background: categoryColors[category]}}
      />
  );
};

export default CategoryLabel;
