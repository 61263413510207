import React from "react";
import { default as ReactConfetti, ConfettiProps } from 'react-dom-confetti';
import { Box } from "@mui/material";

export type { ConfettiProps };

const styles = {
  wrapper: {
    position: "fixed",
    right: "50%",
    left: "50%",
    top: "20%",
    zIndex: 2147483647,
  },
}

const Confetti : React.FC<ConfettiProps> = (props) => {

  return (
      <Box sx={styles.wrapper} >
        <ReactConfetti {...props} />
      </Box>
  )
};

export const defaultColors = ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"];
export const defaultConfig = {
  angle: 90,
  colors: defaultColors,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  width: "10px",
  height: "10px",
  duration: 2000,
  stagger: 3,
  dragFriction: 0.12,
};

Confetti.defaultProps = {
  config: defaultConfig,
};

export default Confetti;

