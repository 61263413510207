import React from "react";
import {
  Badge,
  IconButton,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import clsx from "clsx";
import Tooltip from "../../Tooltip";

type RightButtonBaseProps = {
  badgeContent?: number,
  children?: React.ReactNode,
  href: string,
  iconButtonRef?: React.Ref<any>,
  id?: string,
  testId?: string,
  hoverState?: boolean,
  tooltipText?: string,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#ffffff",
    padding: 0,
    marginRight: theme.spacing(0.5),
  },
  badge: {
    '& .MuiBadge-dot': {
      height: '6px',
      minWidth: '6px',
      backgroundColor: '#ab3e4a',
    },
  },
  button: {
    backgroundColor: 'transparent',
    width: theme.spacing(5),
    height: theme.spacing(5),
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  },
}));

const ButtonBase = ({
  badgeContent,
  href,
  children,
  iconButtonRef,
  id,
  testId,
  hoverState = false,
  tooltipText = "",
}: RightButtonBaseProps) => {
  const classes = useStyles();
  const isMobile = window.matchMedia && window.matchMedia("(any-pointer: coarse)").matches

  return (
    <Tooltip title={!isMobile ? tooltipText : ""}>
      <IconButton
        className={clsx(classes.icon, {[classes.button]: hoverState === true})}
        data-test-id={testId}
        href={href}
        id={id}
        ref={iconButtonRef}
      >
        <Badge
          badgeContent={badgeContent}
          className={classes.badge}
          color="error"
          invisible={!badgeContent}
          max={9}
          overlap="circular"
        >
          {children}
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default ButtonBase;
