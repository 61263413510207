import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Link,
  Typography,
} from '@mui/material';
import ActivityImage from '../../ActivitiesPage/Activity/ActivityImage';

type ActivityNanoViewProps = {
  activityPath: string,
  name: string,
  image: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  activityName: {
    position: 'relative',
    left: '3px',
    textAlign: 'left',
    lineHeight: '24px',
    color: 'black',
    fontSize: 18,
    fontWeight: 600,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: [18, '!important'],
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
      marginRight: theme.spacing(5),
    },
    maxWidth: '100%',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  info: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const ActivityNanoView = ({
  image,
  name,
  activityPath,
}: ActivityNanoViewProps) => {
  const classes = useStyles();

  return (
    <Link
      href={activityPath}
      underline="none"
      data-test-id='activity-nano-view'
    >
      <Grid container>
        <ActivityImage
          imageUrl={image}
        />
        <Grid item className={classes.info}>
          <Grid item>
            <Typography
              className={classes.activityName}
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
};

export default ActivityNanoView;
