import React, { useContext, useReducer, FormEvent } from "react";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import { makeStyles } from "@mui/styles";
import Button from "../../../../buttons/Button";
import CollapsibleBox from "../../../../shared/CollapsibleBox";
import FixedSaveBar from "../../../../shared/FixedSaveBar";
import RailsForm from "../../../../shared/RailsForm";
import Drawer from "../../../../shared/Drawer";
import {track as trackAnalyticsEvent} from "../../../../../services/analytics";
import { SegmentContext } from "../../../../Document";


const useStyles = makeStyles((theme) => ({
  buttonText: {
    marginLeft: theme.spacing(1),
  },
  currentFiltersCounter: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.general.primary,
    color: 'white',
    minWidth: theme.spacing(4),
    height: theme.spacing(4),
  },
  form: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formContent: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    flex: 1,
  },
  label: {
    fontSize: 16,
    fontWeight: 300,
    transform: 'scale(1)',
  },
  sortBy: {
    paddingRight: theme.spacing(4/3),
  },
  sortByFormControl: {
    width: 200,
  },
}));

export type Sorting<T> = {
  label: string,
  sortBy: T,
}

type FilterVertical = 'Dates' | 'Stays' | 'Destinations' | 'Activities' | 'Activity groups' | 'Broadcasts' | 'Notes'

type FilterSubmitEvent = React.SyntheticEvent;

interface BaseFilterAndSortProps {
  sortByRadioGroupName: string,
  vertical: FilterVertical,
  currentFiltersCount?: number,
  currentSorting?: string,
  disabled?: boolean,
  filters?: React.ReactNode,
  onClose?: () => void,
  sortings?: Sorting<string>[],
  selectedWithoutVotes?: boolean,
  selectedInterestLevel?: (sort: string) => void,
  isInterestSort?: (sort: string) => boolean,
}

interface AsyncFilterAndSortProps extends BaseFilterAndSortProps {
  onSubmit: (event: FilterSubmitEvent) => void,
  formPath?: string,
}

interface SyncFilterAndSortProps extends BaseFilterAndSortProps {
  formPath: string,
  onSubmit?: (event: FilterSubmitEvent) => void,
}

type FilteringAndSortingBarProps = AsyncFilterAndSortProps | SyncFilterAndSortProps;

const FilteringAndSortingBar = ({
  currentFiltersCount = 0,
  currentSorting,
  disabled,
  filters,
  formPath,
  onClose,
  onSubmit,
  sortByRadioGroupName,
  sortings,
  vertical,
  selectedWithoutVotes,
  selectedInterestLevel,
  isInterestSort,
}: FilteringAndSortingBarProps) => {
  const classes = useStyles();
  const initialState = { sortBy: currentSorting, openDrawer: false };
  const { isPlanner, currentUserId, tripId } = useContext(SegmentContext);

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'openDrawer':
        return Object.assign({}, initialState, { openDrawer: true });
      case 'closeDrawerAndReset':
        onClose && onClose();
        return initialState;
      case 'closeDrawerWithoutReset':
        return { ...state, openDrawer: false };
      case 'changeSortBy':
        return Object.assign({}, state, { sortBy: action.sortBy });
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const sortingName = () => sortings ? sortings.find((sorting) => sorting.sortBy === state.sortBy)?.label : null;

  const handleOpenDrawClick = (event: any) => {
    dispatch({ type: 'openDrawer' })

    trackAnalyticsEvent(
      'Sort/Filter Initiated',
      {
        vertical: vertical,
        userId: currentUserId,
        tripId: tripId,
        planner: isPlanner,
      },
    );
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    dispatch({ type: 'closeDrawerWithoutReset' })
    if (onSubmit) onSubmit(event)
  }

  return (
    <>
      <Button
        disabled={disabled}
        onClick={(el) => handleOpenDrawClick(el)}
        variant="tertiary"
      >
        <TuneIcon />
        <span className={classes.buttonText}>Sort & Filter</span>
        {currentFiltersCount > 0 && <Chip label={currentFiltersCount} className={classes.currentFiltersCounter} />}
      </Button>
      <Drawer
        anchor="left"
        onClose={() => dispatch({ type: 'closeDrawerAndReset' })}
        open={state.openDrawer}
        data-test-id="filtersDrawer"
      >
        <RailsForm action={formPath as string} method="GET" className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            <Grid container direction="column" spacing={2}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h2">
                    Sort & filter
                  </Typography>
                </Grid>
                <Grid item data-test-id="CloseIcon">
                  <IconButton onClick={() => dispatch({ type: 'closeDrawerAndReset' })}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              {sortings && (
                <Grid item data-test-id="sortingGrid">
                  <CollapsibleBox
                    header={
                      <Typography variant="h2">Sort</Typography>
                    }
                    note={
                      <FormLabel className={classes.label}>{sortingName()}</FormLabel>
                    }
                    className={classes.sortBy}
                  >
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="sortBy"
                        defaultValue={currentSorting}
                        name={sortByRadioGroupName}
                        onChange={(event) => {
                          if (selectedInterestLevel) { selectedInterestLevel(event.target.value) }
                          dispatch({ type: 'changeSortBy', sortBy: event.target.value })
                        }}
                      >
                        {sortings.map((sorting) => (
                          <FormControlLabel
                            key={`sorting-menu-item-${sorting.sortBy}`}
                            value={sorting.sortBy}
                            control={<Radio color="primary"/>}
                            label={sorting.label}
                            data-test-id="sortingOptionRadio"
                            disabled={selectedWithoutVotes && isInterestSort && isInterestSort(sorting.sortBy)}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </CollapsibleBox>
                </Grid>
              )}
              {filters}
            </Grid>
          </Box>
          <FixedSaveBar buttonContent="Apply" saveButtonType="submit" />
        </RailsForm>
      </Drawer>
    </>
  );
};

export default FilteringAndSortingBar;
