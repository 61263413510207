import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, Theme, Typography } from '@mui/material';
import Avatar, { statusToVariant } from '../../../../avatars/Avatar';
import BroadcastButton from '../../../../shared/SiteHeader/BroadcastButton';
import ShareTripButton from '../../../../shared/SiteHeader/ShareTripButton';
import Tooltip from '../../../../shared/Tooltip';
import { TravelerChip } from '../../../trips/ListTripsPage';
import { TripType } from "../../../../shared/TripTypes";
import RsvpModal from '../../../../shared/RsvpModal';
import RespondToInviteOptions from './RespondToInviteOptions';

export type OverviewTripProps = {
  travelerId: string,
  currentTravelerStatus: string,
  plannerStatus: string,
  plannerAvatarUrl: string,
}

export type Invite = {
  id: string,
  inviterAvatarUrl: string,
  inviterName: string,
  inviterStatus: string,
}

type OverviewSubheaderProps = {
  currentUserId?: string,
  invite?: Invite,
  trip: TripType & OverviewTripProps,
};

type StyleProps = {
  isPlanner: boolean,
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  plannerStatus: {
    marginLeft: theme.spacing(3),
    backgroundColor: '#509CFF',
  },
  userAvatarContainer: {
    width: '100%',
    display: 'inline-flex',
    backgroundColor: 'rgba(49, 93, 205, 0.1)',
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    '& > div:first-child': {
      margin: 0,
    },
  },
  bold: {
    fontWeight: 500,
  },
  userName: {
    display: 'inline',
    marginLeft: theme.spacing(1),
  },
  shareTripButtonContainer: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down(990)]: {
      margin: '0px 4px',
    },
    [theme.breakpoints.down(400)]: {
      margin: 0,
    },
  },
}));

const OverviewSubheader = ({
  currentUserId,
  invite,
  trip,
}: OverviewSubheaderProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down(700));
  const inviteBtnVariant = trip.isPlanner ? 'primary' : 'tertiary';
  const classes = useStyles({ isPlanner: trip.isPlanner });

  const userName = invite ? invite.inviterName : trip.plannerName;
  const showRsvpButtons = !trip.isPlanner;

  const inviterInfo =
    <Grid item alignItems="center" className={classes.userAvatarContainer} px={2} py={1}>
      <Avatar
        borderVariant={statusToVariant[invite ? invite.inviterStatus : trip.plannerStatus]}
        src={invite ? invite.inviterAvatarUrl : trip.plannerAvatarUrl}
        size="xs"
      />
      <Typography className={classes.userName}>
        <span className={classes.bold}>{userName}</span> {invite && 'invited you'}
      </Typography>
      {!invite && <TravelerChip className={classes.plannerStatus} isPlanner={true}/>}
    </Grid>;

  const shareTripButtonWithTooltip =
    <Tooltip title={!trip.isTraveler ? "Join trip to invite travelers" : ""}>
      <span className={classes.shareTripButtonContainer}>
        <ShareTripButton
          collapseOnMobile={!trip.isPlanner}
          disabled={!trip.isTraveler}
          inviterId={currentUserId}
          tripId={trip.id}
          tripGid={trip.gid}
          tripName={trip.name}
          inviteBtnVariant={inviteBtnVariant}
          isPlanner={trip.isPlanner}
          segmentMethod='"Invite" button in trip navigation'
          shareLinkSource="Secondary Nav"
        />
      </span>
    </Tooltip>;

  return (
    <Grid container justifyContent="center" alignItems="center" data-test-id="overviewSubHeader" spacing={1} p={1}>
      <Grid item container={isMobile}>
        {inviterInfo}
      </Grid>
      <Grid item container={isMobile} justifyContent={isMobile && showRsvpButtons ? "space-between" : "center"} sx={{ display: 'inline-flex' }}>
        {showRsvpButtons && (
          <Grid item>
            <RespondToInviteOptions trip={trip} inviteId={invite?.id} />
          </Grid>
        )}
        <Grid item>
          {shareTripButtonWithTooltip}
          {currentUserId && (
            <BroadcastButton
              currentUserId={currentUserId}
              disabled={!trip.isTraveler}
              tripId={trip.id}
              collapseOnMobile={!trip.isPlanner}
            />
          )}
        </Grid>
      </Grid>
      {currentUserId && (
        <RsvpModal
          tripId={trip.id}
          tripName={trip.name}
          inviterId={currentUserId}
        />
      )}
    </Grid>
  );
};

export default OverviewSubheader;
