import React, { useContext, useReducer } from "react";
import clsx from "clsx";
import copyToClipboard from "copy-to-clipboard";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { InsertLink } from "@mui/icons-material";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ShareSheetButton from "./ShareSheetButton";
import { FormsContext, GlobalContext } from "../../Document";
import Button from '../../buttons/Button';
import * as routes from '../../../routes';
import { track as trackAnalyticsEvent } from '../../../services/analytics';
import setUTMparams from '../../../services/setUTMParams';
import performAsyncRequestWithCancelToken from "../../../services/performAsyncRequestWithCancelToken";
import useMediaQuery from "@mui/material/useMediaQuery";

const {
  fetchShortLinkApiURLShortenerPath,
  tripURL,
} = routes;

type ShareLinkSectionProps = {
  inviterId: string,
  source?: string,
  tripId: string,
  tripName: string,
};

const initialState = {
  copiedLink: false,
  createLabel: 'Create share link',
  creatingLink: false,
  errorMessage: null,
  shareLink: null,
};

const reducer = (state: any, action: any) => {
  const setState = (props: any): any => Object.assign({}, state, props);

  switch (action.type) {
    case 'initial':
      return initialState;
    case 'shortLinkCreated':
      return setState({ ...initialState, shareLink: action.shareLink, copyLink: action.copyLink })
    case 'creating':
      return setState({ createLabel: 'Creating share link...', creatingLink: true });
    case 'creationFailed':
      return setState({ ...initialState, errorMessage: action.message })
    case 'copiedLink':
      return setState({ copiedLink: true })
    default:
      throw new Error();
  }
};

const useStyles = makeStyles((theme) => ({
  copiedLinkButton: {
    height: `${theme.spacing(7)} !important`,
    width: 'auto',
  },
  copyLinkButton: {
    height: `${theme.spacing(7)} !important`,
    width: 'auto',
  },
  copyField: {
    width: '100%',
  },
  copyInviteSection: {
    display: 'inline-flex',
    textAlign: 'center',
  },
  createLinkSection: {
    flexDirection: 'column',
  },
  error: {
    color: '#e44444',
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  gridItem: {
    display: 'grid',
  },
  insertLink: {
    transform: "translate(-40%) rotate(-50deg)",
  },
  shareActionButtons: {
    display: 'flex',
    justifyContent: 'center',
  },
  shareButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

const ShareLinkSection = ({
  inviterId,
  source,
  tripId,
  tripName,
} : ShareLinkSectionProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const classes = useStyles();
  const [actionsState, dispatch] = useReducer(reducer, initialState);
  const { csrfToken } = useContext(FormsContext);
  const { setSnackbar } = useContext(GlobalContext);
  const genericError = 'Something went wrong, please try again.';
  const inviteUrl = `${tripURL(tripId)}?inviter=${inviterId}`;
  const shareUrlWithUTM = setUTMparams(inviteUrl, 'shared');
  const copyUrlWithUTM = setUTMparams(inviteUrl, 'copied');

  const fetchShortLink = () => {
    dispatch({ type: 'creating'});
    performAsyncRequestWithCancelToken({
      method: 'GET',
      url: fetchShortLinkApiURLShortenerPath({ shareUrl: shareUrlWithUTM, copyUrl: copyUrlWithUTM }),
      data: {
        authenticity_token: csrfToken,
      },
      onSuccess: (response) => {
        trackAnalyticsEvent('Invite Link Generated', {
          category: 'Invites',
          inviteLinkUrl: response.data.shareLink,
          tripId,
          source,
        });
        dispatch({ type: 'shortLinkCreated', shareLink: response.data.shareLink, copyLink: response.data.copyLink })
      },
      onError: (error) => dispatch({ type: 'creationFailed', message: error.response?.data?.errors ? error.response.data.errors : genericError }),
    });
  };

  const trackCopyInviteLinkEventInSegment = () => {
    trackAnalyticsEvent('Invite Completed', {
      category: 'Invites',
      method: 'Copy share link',
      tripId,
      source,
    });
  };

  const copyLink = () => {
    trackCopyInviteLinkEventInSegment();
    copyToClipboard(actionsState.copyLink);
    dispatch({ type: 'copiedLink' });
    setSnackbar && setSnackbar('Link copied to clipboard.', 3000, 'info');
  };

  return <>
    {!!actionsState.shareLink ? (
      <Grid>
        <Grid container spacing={2} className={classes.copyInviteSection}>
          <Grid sm={navigator['share'] ? 8 : 10} xs={12} item>
            <TextField
              data-test-id="copy-field"
              className={classes.copyField}
              defaultValue={actionsState.copyLink}
              inputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={navigator['share'] ? 4 : 1} xs={12} className={classes.shareActionButtons}>
            {navigator['share'] &&
              <Grid item className={classes.gridItem} xs={6}>
                <ShareSheetButton
                  className={clsx(classes.copyLinkButton, classes.shareButton)}
                  title={tripName}
                  url={actionsState.shareLink}
                />
              </Grid>
            }
            <Grid item className={classes.gridItem} xs={6}>
              {!actionsState.copiedLink ? (
                <Button
                  className={classes.copyLinkButton}
                  data-test-id='copy-link-button'
                  onClick={() => copyLink()} variant="secondary"
                >
                  <FileCopyOutlinedIcon/>
                  {isMobile && (
                    <Typography variant='h3' pl={1}>
                      Copy
                    </Typography>
                  )}
                </Button>
              ) : (
                <Button
                  className={classes.copiedLinkButton}
                  data-test-id='copied-link-button'
                  variant="secondary"
                >
                  <CheckCircleIcon />
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid
        className={classes.createLinkSection}
        container
        direction='row'
        justifyContent="center"
      >
        <Button
          data-test-id="create-share-link-button"
          onClick={() => fetchShortLink()}
          variant="secondary"
          disabled={actionsState.creatingLink}
        >
          <InsertLink className={classes.insertLink}/>
          {actionsState.createLabel}
        </Button>
        {actionsState.errorMessage &&
          <Grid item className={classes.error}>
            {actionsState.errorMessage}
          </Grid>
        }
      </Grid>
    )}
  </>;
};

export default ShareLinkSection;
