import React, { useState } from "react";
import clsx from "clsx";
import { NumericFormat } from 'react-number-format';
import { IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/Info";
import DateOptionsPricing from "./shared/DateOptionsPricing";
import { DateOptionWithAvailabilityType } from "../../../../../shared/TripObjectTypes";
import TextButton from "../../../../../buttons/TextButton";
import useSegment from "../../../../../../services/useSegment";

type StayPricingProps = {
  available: boolean,
  averagePricing?: boolean,
  className?: string,
  currency: string | null,
  dateOptions?: DateOptionWithAvailabilityType[],
  isDisabled?: boolean,
  hasAutomaticAvailability?: boolean,
  price: number | [number, number] | null,
  chosenDatePrice?: number | null,
  pricingType: 'night' | 'total',
  stayPath?: string,
  tripId?: string,
  stayId?: string,
};

type PriceProps = {
  value: number,
  className?: string,
};

const determineIfIsNumber = (
  toBeDetermined: number | [number, number],
): toBeDetermined is number => {
  if (Number.isFinite(toBeDetermined as number)) {
    return true;
  } else {
    return false;
  }
};

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: "#COCOCO",
  },
  editIcon: {
    '&:hover': {
      textDecoration: ["underline","!important"],
    },
    marginRight: theme.spacing(1),
    zIndex: 2,
  },
  iconButton: {
    color: theme.palette.general.primary,
    zIndex: 2,
    width: '20px',
    height: '20px',
    margin: '0px 8px',
  },
  priceText: {
    textAlign: 'left',
    fontSize: theme.spacing(2),
    color: '#272727',
  },
  addPriceText: {
    fontSize: theme.spacing(2),
    color: '#3689FF',
    '&:hover': {
      color: '#2C71D3',
    },
  },
  priceDetailsText: {
    textAlign: 'left',
    fontSize: '14px',
    color: '#697379',
    paddingTop: '4px',
  },
  suffix: {
    fontWeight: 400,
    fontSize: 16,
  },
  priceRange: {
    fontSize: 16,
  },
}));

const StayPricing = ({
  available,
  averagePricing,
  chosenDatePrice,
  className,
  currency,
  dateOptions,
  hasAutomaticAvailability,
  isDisabled,
  price,
  pricingType,
  stayPath,
  tripId,
  stayId,
}: StayPricingProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();
  const { trackSegment } = useSegment();
  const isNightlyPricing = pricingType === 'night';
  const pricingTypeText = isNightlyPricing ? ' / night' : ' total';
  const isRangePrice = Array.isArray(price);
  const isChosenDateZero = chosenDatePrice === 0;
  const isPriceZero = price === 0;

  const Price = ({ value, className }: PriceProps) => (
    <NumericFormat
      value={value}
      displayType={'text'}
      decimalScale={2}
      fixedDecimalScale={value % 1 !== 0}
      thousandSeparator={true}
      prefix={currency}
      className={className}
    />
  );

  const DateOptionsIcon = () => {
    const onDateOptionsClick = () => {
      trackSegment('Stays Tooltip Clicked', {
        category: 'Stays Pricing',
        property: 'buttonClicked',
      });

      setOpenDialog(true);
    };

    return(
      !isDisabled && dateOptions?.length ? (
        <>
          <IconButton
            className={classes.iconButton}
            data-test-id="stayDateOptionsPricing"
            onClick={() => onDateOptionsClick()}
          >
            <InfoIcon />
          </IconButton>
          <DateOptionsPricing
            dateOptions={dateOptions}
            stayHasAutomaticAvailability={hasAutomaticAvailability}
            isOpen={openDialog}
            onClose={() => setOpenDialog(false)}
            stayPath={stayPath}
            stayId={stayId}
          />
        </>
      ) : <span></span>
    );
  };

  const wrapWithLink = (component) => {
    if (stayPath && !hasAutomaticAvailability) {
      const onButtonClick = () => {
        trackSegment('Add Pricing Selected', {
          category: 'Stays Edit Pricing',
          property: 'buttonClicked',
          tripId: tripId,
        });

        window.location.replace(stayPath + '?focus=price');
      };

      return (
        <>
          <TextButton
            data-test-id="stay-edit-pricing"
            className={classes.editIcon}
            onClick={() => onButtonClick()}
          >
            {component}
          </TextButton>
          <DateOptionsIcon />
        </>
      );
    };

    return component;
  };

  return (
    <>
      {available && (
        <>
          <Typography className={clsx(className,
            {
              [classes.disabled]: isDisabled,
              [classes.priceText]: !isDisabled,
            })}
          >
            <>
              {!price && !isChosenDateZero && !isPriceZero && (
                <>
                  {(!averagePricing && (!stayPath || hasAutomaticAvailability)) && (
                    wrapWithLink(
                      <span data-test-id='no-price' className={classes.addPriceText}>
                        (no price)
                      </span>,
                    )
                  )}
                  {(!averagePricing && stayPath && !hasAutomaticAvailability) && (
                    wrapWithLink(
                      <span data-test-id='add-pricing' className={classes.addPriceText}>
                        <EditIcon fontSize="small" className={classes.editIcon}/>
                        Add pricing
                      </span>,
                    )
                  )}
                </>
              )}
              {(chosenDatePrice || isChosenDateZero) && (
                wrapWithLink(
                  <span data-test-id='chosen-date-price'>
                    <Price value={chosenDatePrice} />
                    <span className={classes.suffix}>{pricingTypeText}</span>
                  </span>,
                )
              )}
              {((price && !chosenDatePrice) || isPriceZero) &&
                <>
                {isRangePrice ? (
                  <span className={classes.priceRange}>
                    <Price value={price[0]} data-test-id='price-range-price-low'/>
                    <span> - </span>
                    <Price value={price[1]} data-test-id='price-range-price-high'/>
                    <span className={classes.suffix}> total</span>
                    {!hasAutomaticAvailability && <DateOptionsIcon />}
                  </span>
                ) : (
                  determineIfIsNumber(price) && (
                    <span data-test-id='single-stay-price'>
                      <Price value={price} />
                      <span className={classes.suffix}>{pricingTypeText}</span>
                      {!hasAutomaticAvailability && <DateOptionsIcon />}
                    </span>
                  )
                )}
              </>}
            </>
            {hasAutomaticAvailability && <DateOptionsIcon />}
          </Typography>
          {(!!price || isPriceZero) && (
            <Typography className={isDisabled ? classes.disabled : classes.priceDetailsText} data-test-id="taxes-fees-warning">
              Cost may not include taxes and fees
            </Typography>
          )}
        </>
      )}
      {!available && (
        <Typography>
          Unavailable for your dates
        </Typography>
      )}
    </>
  );
};

export default StayPricing;
