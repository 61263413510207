import React from 'react';
import { makeStyles } from "@mui/styles";
import FixedSaveBar from "./FixedSaveBar";
import Modal from "./Modal";
import RailsForm from "./RailsForm";
import { Grid, Typography } from "@mui/material";
import { ButtonType, ButtonVariant } from '../buttons/Button';
import Tooltip from "./Tooltip";
import clsx from 'clsx';

const TOOLTIP_COLOR = '#343435';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '720px',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    minHeight: '100%',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  tooltip: {
    backgroundColor: TOOLTIP_COLOR,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '400',
    top: '24px',
    padding: theme.spacing(1),
  },
  arrow: {
    color: TOOLTIP_COLOR,
  },
}));

type ModalFormProps = {
  children: Array<React.ReactElement> | React.ReactElement,
  closePath: string,
  formActionPath: string,
  title: string,
  disabledSubmit?: boolean,
  extraSaveBarButton?: React.ReactNode,
  formMethod?: string,
  onClick?: (event?: any) => void,
  saveBarTopContent?: React.ReactNode,
  saveButtonLabel?: string,
  saveButtonType?: ButtonType,
  saveButtonVariant?: ButtonVariant,
  subtitle?: React.ReactNode,
  useDefaultSaveButton?: boolean,
  withChangeDetection?: boolean,
  saveTooltipText?: string,
  onModalClose?: () => void,
  upperImage?: React.ReactNode,
  titleClassName?: string,
};

const ModalForm = ({
  children,
  closePath,
  disabledSubmit,
  extraSaveBarButton,
  formActionPath,
  formMethod,
  onClick,
  saveBarTopContent,
  saveButtonLabel,
  saveButtonType = 'submit',
  saveButtonVariant,
  subtitle = <></>,
  title,
  useDefaultSaveButton,
  withChangeDetection,
  saveTooltipText = '',
  upperImage,
  onModalClose=()=>{},
  titleClassName,
}: ModalFormProps) => {
  const classes = useStyles();

  const wrapWithTooltip = (children : React.ReactNode) => (
    <Tooltip
      title={saveTooltipText}
      arrow data-test-id="tooltip"
      classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
      placement="top"
    >
      <Grid>
        {children}
      </Grid>
    </Tooltip>
  )

  const renderDefaultSaveButton = () => (
    <FixedSaveBar
      data-test-id="submit-button"
      buttonContent={saveButtonLabel}
      disabled={disabledSubmit}
      onClick={saveButtonType !== 'submit' ? onClick : null}
      extraButton={extraSaveBarButton}
      saveButtonVariant={saveButtonVariant}
      saveButtonType={saveButtonType}
      topContent={saveBarTopContent}
    />
  )

  const renderSaveButton = () => (
    useDefaultSaveButton && (
      saveTooltipText ? wrapWithTooltip(renderDefaultSaveButton()) : renderDefaultSaveButton()
    )
  );

  return (
    <RailsForm
      action={formActionPath}
      className={classes.root}
      method={formMethod}
      onSubmit={saveButtonType === 'submit' ? onClick : null}
      withChangeDetection={withChangeDetection}
    >
      <Grid container className={classes.container} sx={{backgroundColor: upperImage ? '#FEF6E7' : 'white' }}>
        {upperImage}
        <Modal
          closePath={closePath}
          subtitle={subtitle}
          onClose={onModalClose}
          title={
            <Typography variant="h2" className={clsx(classes.title, titleClassName)}>
              {title}
            </Typography>
          }
          saveBar={renderSaveButton()}
          withDivider={true}
        >
          {children}
        </Modal>
      </Grid>
    </RailsForm>
  );
};

ModalForm.defaultProps = {
  useDefaultSaveButton: true,
  saveButtonLabel: "Save",
  withChangeDetection: false,
};

export default ModalForm;
