import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import {
  Button,
  Grid,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { NewPollAnswer } from "../NewPollForm";
import { PollAnswerType } from "../PollsPage/PollView/PollAnswersForm";
import Linkify from "../../../../shared/Linkify";

type PollAnswerButtonProps = {
  answer: NewPollAnswer | PollAnswerType,
  className?: string,
  disabled?: boolean,
  isClickable?: boolean,
  onClick?: () => void,
  selected?: boolean,
  showProgressBar?: boolean,
};

type StylesProps = {
  isClickable: boolean,
  responsesPercentage?: number,
};

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: {
    fontWeight: 400,
    height: 'auto',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    background: ({ responsesPercentage }) => {
      if (responsesPercentage > 0) {
        return `linear-gradient(to left, #EEEEEE ${100 - responsesPercentage}%, #D6D8FB 0%)`;
      } else {
        return '#EEEEEE';
      };
    },
    borderColor: '#C8C8C8',
    color: '#000000',
    '&:hover': {
      background: ({ isClickable, responsesPercentage }) => {
        if (responsesPercentage > 0) {
          return `linear-gradient(to left, #EEEEEE ${100 - responsesPercentage}%, ${isClickable ? '#9AA0FF' : 'D6D8FB'} 0%)`;
        } else {
          return isClickable ? '#D7DAFF' : '#EEEEEE';
        };
      }
    },
  },
  disabled: {
    borderRadius: theme.spacing(1.25),
    background: '#EEEEEE',
    borderColor: '#E6E7E8',
    color: '#979797',
  },
  checkmark: {
    '& img': {
      display: 'block',
      marginTop: '3px',
    },
  },
  unclickable: {
    cursor: 'initial',
  },
  selected: {
    border: '2px solid #545DFC',
  },
  answerBody: {
    textAlign: 'left',
    overflowWrap: "anywhere",
  },
}));

const PollAnswerButton = ({
  answer,
  className,
  disabled = false,
  isClickable = true,
  onClick,
  selected = false,
  showProgressBar = true,
}: PollAnswerButtonProps) => {
  const classes = useStyles({
    isClickable: isClickable,
    responsesPercentage: showProgressBar ? answer.responsesPercentage : 0,
  });

  const onContainerLinkClicked = (event) => {
    if (event.target.dataset['element'] !== 'link') {
      onClick && onClick();
    }
  };

  return (
    <Button
      className={clsx(
        className,
        classes.root,
        {[classes.disabled]: disabled},
        {[classes.unclickable]: !isClickable},
        {[classes.selected]: selected},
      )}
      data-test-id='poll-answer-button'
      disabled={disabled}
      disableRipple
      fullWidth
      onClick={onContainerLinkClicked}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography
            className={classes.answerBody}
          >
            <Linkify>{answer.body}</Linkify>
          </Typography>
        </Grid>
        {selected && (
          <Grid item data-test-id='checkMark' className={classes.checkmark}>
            <img src="/assets/illustrations/Yes.svg" />
          </Grid>
        )}
      </Grid>
    </Button>
  );
};

export default PollAnswerButton;
