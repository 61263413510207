import React, { useCallback, useEffect, useReducer } from "react";
import axios from "axios";
import debounce from 'lodash/debounce';
import { Box, Grid } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import BaseSkeleton from "@mui/material/Skeleton";
import ImageUploader from '../../shared/ImageUploader';

type LocationParams = {
  location: string,
  google_place_id: string,
  google_place_url: string,
  google_place_image: string,
};

type DestinationPreviewProps = {
  onError?: () => void,
  onFetch?: () => void,
  onLoading?: () => void,
  onRemove?: () => void,
  resourcePath: string,
  tripId: string,
  location?: LocationParams,
};

const useStyles = makeStyles((theme) => ({
  skeletonCard: {
    border: "2px solid #e6e7e8",
    borderRadius: 5,
    "&:focus": {
      outline: "none",
    },
    padding: theme.spacing(2),
  },
  destinationPreview: {
    paddingLeft: theme.spacing(1),
  },
}));

const reducer = (state, action) => {
  switch(action.type) {
    case 'loadDestination':
      return { destination: null, loading: true, fetched: false };
    case 'showDestination':
      return { destination: action.data, loading: false, fetched: true };
    case 'removeDestination':
      return { destination: null, loading: false, fetched: false }
    case 'changeImage':
      return Object.assign({}, state, { imageUrl: action.url });
    default:
      throw new Error();
  }
};

const Skeleton = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}))(BaseSkeleton);

const DestinationPreview = ({
  onError,
  onFetch,
  onLoading,
  onRemove,
  resourcePath,
  tripId,
  location,
}: DestinationPreviewProps) => {
  const classes = useStyles();

  const initialState = {
    destination: null,
    fetched: false,
    loading: false,
  };

  const [{ destination, fetched, loading }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const isParamsPresent = (location && Object.entries(location).length !== 0);

  const fetchDestination = (parameters) => {
    dispatch({ type: 'loadDestination' });

    axios.get(
      '/api/destinations/fetch_destination',
      { params: { trip_id: tripId, ...parameters } },
    ).then((response) => {
      dispatch({ type: 'showDestination', data: response.data });
    }).catch(() => {
      dispatch({ type: 'showDestination', data: null });
    });
  };

  const debouncedFetchDestination = useCallback(
    debounce((parameters) => fetchDestination(parameters), 500),
    [],
  );

  const missingDestination = !destination;
  const destinationIsLoaded = !missingDestination && fetched;
  const destinationCannotBeFetched = fetched && missingDestination;
  const fallbackSource = "/assets/destination_fallback.svg";

  useEffect(() => {
    if (location && Object.entries(location).length !== 0) {
      debouncedFetchDestination({ ...location });
    }
  }, [location?.google_place_id]);

  useEffect(() => {
    if (!isParamsPresent) {
      dispatch({ type: 'removeDestination' });
    }
  }, [location]);

  useEffect(() => {
    if (loading) {
      onLoading && onLoading();
    } else if (destinationIsLoaded) {
      onFetch && onFetch();
    } else if (destinationCannotBeFetched) {
      onError && onError();
    } else {
      onRemove && onRemove();
    }
  }, [loading, destination]);

  return (
    <>
      {loading && (
        <Grid item>
          <Box className={classes.skeletonCard}>
            <Skeleton height={140} variant="rectangular" />
            {[...Array(4)].map((element, index) => (
              <Skeleton height={30} variant="rectangular" key={index} />
            ))}
          </Box>
        </Grid>
      )}
      {destinationIsLoaded && (
        <Grid item className={classes.destinationPreview} data-test-id="destinationPreview">
          <ImageUploader
            defaultImage={destination.image ?? fallbackSource}
            inputName="destination[image]"
            image={destination.image}
            onImageUpload={(url) => dispatch({ type: 'changeImage', url })}
            originalImages={[destination.originalImage]}
          />
        </Grid>
      )}
    </>
  );
};

// noinspection JSUnusedGlobalSymbols
export default DestinationPreview;
