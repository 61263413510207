import React, { useContext } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import clsx from "clsx";
import { makeStyles, DefaultTheme } from "@mui/styles";
import buttonColors from "./buttonColors";
import { FormContext } from "../shared/RailsForm";

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'danger';
export type ButtonType = 'button' | 'submit' | 'reset';
type LinkTarget = '_blank' | '_self' | '_parent' | '_top' | null;

export type ButtonProps = {
  children?: React.ReactNode,
  className?: string,
  detachedFromForm?: boolean,
  disabled?: boolean,
  disableRipple?: boolean,
  fullWidth?: boolean,
  href?: string,
  onClick?: ((event: any) => void) | null,
  tabIndex?: number,
  target?: LinkTarget,
  type?: ButtonType,
  variant?: ButtonVariant,
};

const useStyles = makeStyles<DefaultTheme, { variant: ButtonVariant }>((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    height: theme.spacing(6),
    border: '1px solid',
    borderRadius: theme.spacing(0.75),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderColor: ({ variant }) => buttonColors[variant].default.border,
    color: ({ variant }) => buttonColors[variant].default.color,
    background: ({ variant }) => buttonColors[variant].default.fill,
    '&:hover': {
      borderColor: ({ variant }) => buttonColors[variant].hover.border,
      color: ({ variant }) => buttonColors[variant].hover.color,
      background: ({ variant }) => buttonColors[variant].hover.fill,
    },
    '&:focus': {
      borderColor: ({ variant }) => buttonColors[variant].focused.border,
      color: ({ variant }) => buttonColors[variant].focused.color,
      background: ({ variant }) => buttonColors[variant].focused.fill,
      boxShadow: '0 0 0 1px #80b4fb',
    },
    '&:active': {
      borderColor: ({ variant }) => buttonColors[variant].pressed.border,
      color: ({ variant }) => buttonColors[variant].pressed.color,
      background: ({ variant }) => buttonColors[variant].pressed.fill,
      boxShadow: 'none',
    },
  },
  disabled: {
    borderColor: ({ variant }) => `${buttonColors[variant].disabled.border} !important`,
    color: ({ variant }) => `${buttonColors[variant].disabled.color} !important`,
    background: ({ variant }) => `${buttonColors[variant].disabled.fill} !important`,
    boxShadow: 'none !important',
  },
  fullWidth: {
    width: '100%',
  },
}));

const SUBMIT_WITH_ONCLICK_MESSAGE =
  'Detected `button type="submit"` with onClick handler. ' +
  'Prefer <form> onSubmit handler to avoid race conditions!';

const Button = React.forwardRef(({
  children,
  className,
  detachedFromForm,
  disabled,
  fullWidth,
  variant,
  ...props
}: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  // React warning fix: throw away properties that ButtonGroup injects but are not implement by `Button`
  //   see: https://github.com/mui/material-ui/issues/17226#issuecomment-672980848
  // @ts-expect-error
  // eslint-disable-next-line no-unused-vars
  const { disableFocusRipple, disableElevation, ...buttonProps } = props;
  const classes = useStyles({ variant });
  const formContext = useContext(FormContext);
  const disabledForm = formContext.disabled && !buttonProps.href && !detachedFromForm;

  if (props.type === 'submit' && props.onClick && typeof props.onClick === 'function') {
    const message = SUBMIT_WITH_ONCLICK_MESSAGE + ` (button copy: "${children}", page: "${window.location.pathname}")`;
    throw new Error(message);
  }

  return (
    <ButtonBase
      {...buttonProps}
      disabled={disabled || disabledForm}
      ref={ref}
      className={clsx(
        classes.root,
        {
          [classes.disabled]: disabled || disabledForm,
          [classes.fullWidth]: fullWidth,
        },
        className,
      )}
    >
      {children}
    </ButtonBase>
  );
});

Button.defaultProps = {
  detachedFromForm: false,
  variant: 'primary',
};

export default Button;
