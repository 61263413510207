import React from "react";
import { makeStyles } from "@mui/styles";
import {
  ButtonBase,
  Grid,
  Button,
} from '@mui/material';
import StayImages from "../Stay/StayImages";
import StayName from "../Stay/StayName";
import { ExternalListingProps } from "../../../../../shared/TripObjectTypes";
import ProviderName from "../shared/ProviderName";
import { SegmentContext }from "../../../../../Document";
import TripObject, { Variation } from "../../../../../shared/TripObject";
import ListingPricing from "../../../../stays_search/Listings/ExternalListingCard/ListingPricing";
import StayRating from "../Stay/StayRating";
import ExternalListingForm from "../../../../stays_search/ExternalListingForm";
import { tripStaysPath } from "../../../../../../routes";
import { capitalize } from "../../../../../../utils/strings";
import { useStayAffiliateClickEventRef } from "../../../../../../services/segmentEvents/useStayAffiliateClickEvent";
import ShareButton from "../../../../../shared/ShareButton";

export type MapCardProps = {
  stay: ExternalListingProps,
  isSERP: boolean,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    borderRadius: '10px',
    maxWidth: '280px',
    border: 'none',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.15)',
    '& .slider-frame': {
      '& img': {
        height: '130px !important',
      },
      borderRadius: '10px 10px 0px 0px',
    },
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  clickArea: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  shareButton: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    zIndex: 3,
  },
}));

const MapCard = ({
  stay,
  isSERP,
}: MapCardProps) => {
  const classes = useStyles();
  const { tripId } = React.useContext(SegmentContext);
  const shouldRenderCarousel = stay.images.length > 1;

  const segmentRef = useStayAffiliateClickEventRef({
    listingUrl: stay.publicUrl,
    targetClicked: 'card',
  });

  const providerButton = (
    <Button
      href={stay.publicUrl}
      target='_blank'
      variant='text'
      ref={segmentRef}
      data-test-id='external-stay-listing-button'
      sx={{ zIndex: 2 }}
    >
      <ProviderName>
        {capitalize(stay.provider)}.com
      </ProviderName>
    </Button>
  );

  const cardContent = (
    <Grid container direction="column" px={2} py={1} spacing={1}>
      <Grid item>
        <StayName fullWidth name={stay.name} />
      </Grid>
      <Grid item>
        <ListingPricing
          available={stay.available}
          pricing={stay.pricing}
        />
      </Grid>
      <Grid item container justifyContent='space-between' alignItems="center">
        <Grid item>
          <StayRating stay={stay} />
        </Grid>
        <Grid item>
          {tripId && isSERP ? (
            <ExternalListingForm
              staysPath={tripStaysPath(tripId)}
              canAddToTrip={!stay.alreadyOnTrip}
              fullWidth
              id={stay.id}
              url={stay.originalUrl}
              source={isSERP ? 'SERP search' : 'Smart Search'}
            />
          ) : (
            <>{providerButton}</>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <TripObject
      className={classes.root}
      contentClassName={classes.content}
      testId='external-stay-listing'
      variation={Variation.Vertical}
      visual={<>
        <ShareButton className={classes.shareButton} url={stay.publicUrl} />
        <StayImages
          images={stay.images}
          isExternalListing
          linkHref={stay.publicUrl}
          shouldRenderCarousel={shouldRenderCarousel}
          small
        />
      </>}
      content={
        <>
          <ButtonBase
            className={classes.clickArea}
            disableRipple
            data-test-id='map-view-click-area'
            ref={segmentRef}
            href={stay.publicUrl}
            target='_blank'
          />
          {cardContent}
        </>
      }
    />
  );
};

export default MapCard;
