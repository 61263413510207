import React from "react";
import {
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CommentsLink from "../../shared/CommentsLink";
import pluralize from 'pluralize';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#555E64',
  },
}));

type PollVotesProps = {
  commentsCount: number,
  commentsPath: string,
  responsesCountTotal: number,
  tripId: string,
  tripObjectId: string,
};

const PollVotes = ({
  commentsCount,
  commentsPath,
  responsesCountTotal,
  tripId,
  tripObjectId,
}: PollVotesProps) => {
  const classes = useStyles();

  return <>
    <Grid container className={classes.root} justifyContent="space-between">
      <Grid item data-test-id="responsesCountTotal" >
        {pluralize('vote', responsesCountTotal, true)}
      </Grid>
      <Grid item>
        <CommentsLink
          commentsLink={commentsPath}
          commentsCount={commentsCount}
          segment={{
            tripId: tripId,
            tripObjectId: tripObjectId,
            tripObjectType: 'polls',
          }}
        />
      </Grid>
    </Grid>
  </>
};

export default PollVotes;
