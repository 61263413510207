import React from "react";
import { Grid, Typography } from "@mui/material";
import { ItineraryEventType } from "../../../../shared/TripObjectTypes";
import Divider from "../../../../shared/Divider";
import ItineraryActivityEvent from "./ItineraryActivityEvent";
import ItineraryStayEvent from "./ItineraryStayEvent";

type ItineraryEventsListProps = {
  itineraryDateId: string,
  itineraryEvents: ItineraryEventType[],
  page: string,
};

const ItineraryEventsList = ({
  itineraryDateId,
  itineraryEvents,
  page,
}: ItineraryEventsListProps) => {
  const unscheduledEvents = itineraryEvents.filter((event) => !event.scheduled && event.schedulableType !== "Stay");
  const scheduledEvents = itineraryEvents.filter((event) => event.scheduled && event.schedulableType !== "Stay");
  const stayItineraryEvents = itineraryEvents.filter((event) => event.schedulableType === "Stay");

  const renderStayEvents = (itineraryEvents : ItineraryEventType[], showAddTimeLink = false) => (
    itineraryEvents.map((event) => (
      <Grid item key={event.id} sx={{ width: '100%' }}>
        <ItineraryStayEvent
          itineraryDateId={itineraryDateId}
          stayEvent={event}
          showActionsMenu
          showAddTimeLink={showAddTimeLink}
          isClickeable
          page={page}
        />
      </Grid>
    ))
  );

  const renderActivityEvents = (itineraryEvents : ItineraryEventType[], showAddTimeLink = false) => (
    itineraryEvents.map((event) => (
      <Grid item key={event.id} sx={{ width: '100%' }}>
        <ItineraryActivityEvent
          itineraryDateId={itineraryDateId}
          activityEvent={event}
          showActionsMenu
          showAddTimeLink={showAddTimeLink}
          isClickeable
          page={page}
        />
      </Grid>
    ))
  );

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item container spacing={2} data-test-id="scheduled-events">
        {renderStayEvents(stayItineraryEvents)}
        {renderActivityEvents(scheduledEvents)}
      </Grid>
      {unscheduledEvents.length > 0 && (
        <Grid item container spacing={2} mt={1} data-test-id="unscheduled-events" flexDirection='column'>
          <Grid item>
            <Typography variant="h2">SCHEDULE A TIME</Typography>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          {renderActivityEvents(unscheduledEvents, true)}
        </Grid>
      )}
    </Grid>
  );
};

export default ItineraryEventsList;
