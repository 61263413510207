import React from "react";
import {
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { StayType } from "../../../../shared/TripObjectTypes";
import { default as DateRangePicker } from "../../../../shared/CustomDateRangePicker";
import InputWithCharacterCounter from '../../../../shared/InputWithCharacterCounter';
import { ItineraryEventType } from "../../../../shared/TripObjectTypes";
import ItineraryStayEvent from "../ItineraryDatePermapage/ItineraryStayEvent";

type LastStepProps = {
  defaultStartDate: string,
  defaultEndDate: string,
  selectedStays: StayType[],
  itineraryStartDate: string,
  itineraryEndDate: string,
};

const LastStep: React.FC<LastStepProps> = ({
  defaultStartDate,
  defaultEndDate,
  selectedStays,
  itineraryStartDate,
  itineraryEndDate,
}) => {
  const StayForm: React.FC<{ stay: StayType }> = ({ stay }) => {
    const itineraryEvent : ItineraryEventType = {
      id: stay.id,
      tripId: stay.tripId,
      itineraryDateId: undefined,
      name: stay.name,
      schedulableId: stay.id,
      imageUrl: stay.images[0],
      startTime: undefined,
      endTime: undefined,
      scheduled: false,
      formattedDate: undefined,
      schedulableType: 'Stay',
      metadata: {
        price: null,
      },
    };

    return (
      <Grid item container direction="column" spacing={3}>
        <input type="hidden" name="itinerary_event[][schedulable_id]" value={stay.id} />
        <input type="hidden" name="itinerary_event[][schedulable_type]" value="Stay" />
        <Grid item sx={{width: '100%'}}>
          <ItineraryStayEvent
            stayEvent={itineraryEvent}
            showActionsMenu={false}
            page={'last step'}
            isInModal
          />
        </Grid>

        <Grid item>
          <Typography variant="h3" pb={1}>Dates</Typography>
          <DateRangePicker
            autoComplete="off"
            defaultEndValue={defaultEndDate}
            defaultStartValue={defaultStartDate}
            helperText={false}
            nameEnd="itinerary_event[][end_date]"
            nameStart="itinerary_event[][start_date]"
            allowedMinDate={itineraryStartDate}
            allowedMaxDate={itineraryEndDate}
            shouldDisableDateEnabled
            disablePast={false}
          />
        </Grid>

        <Grid item>
          <Typography variant="h3" pb={1}>Note (optional)</Typography>
          <InputWithCharacterCounter
            name="itinerary_event[][notes]"
            label="Note"
            fullWidth
            withBorder
            noLabel
            characterLimit={250}
          />
        </Grid>
        <Grid item><Divider /></Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" spacing={2}>
      {selectedStays.map( stay => (
        <StayForm stay={stay} key={stay.id} />
      ))}
    </Grid>
  );
};

export default LastStep;
