import React, { useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { hocRailsAction } from "../../Document";
import * as routes from '../../../routes';
import SiteHeader from '../../../components/shared/SiteHeader'
import RailsForm from "../../shared/RailsForm";
import Button from "../../buttons/Button";
import Errors from '../../shared/Errors';

const {
  authReauthenticatePath,
  usersPath,
} = routes;

type NewUserFormProps = {
  errors?: string[],
  formType: 'name' | 'email',
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  newUserFormImage: {
    maxWidth: theme.spacing(50),
    padding: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
  },
  subText: {
    fontSize: 16,
    fontWeight: 400,
    paddingTop: theme.spacing(1),
    textAlign: 'center',
  },
  nameFields: {
    paddingTop: theme.spacing(2),
  },
  fieldsContainer: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('xs')]: {
      minWidth: '270px',
    },
  },
  buttonOuter: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(3),
  },
  errors: {
    paddingTop: theme.spacing(1),
  },
}));

const NewUserForm = ({
  errors,
  formType,
}: NewUserFormProps) => {
  const classes = useStyles();
  const formTypeHumanized = formType === 'email' ? 'email address' : 'name';
  const focusedInputRef = useRef(null);

  const textFieldDetails = () => {
    if (formType === 'email') {
      return {
        id: "user_email",
        name: "user[email_address]",
        label: "Email address",
        dataTestId: "user-email-field",
      };
    } else {
      return {
        id: "user_first_name",
        name: "user[first_name]",
        label: "First name",
        dataTestId: "user-first-name-field",
      };
    }
  };

  useEffect(() => {
    focusedInputRef.current?.focus();
  }, [focusedInputRef]);

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
    >
      <SiteHeader logoPath={authReauthenticatePath()} />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <img
          className={classes.newUserFormImage}
          alt="trips"
          src={`/assets/trips.svg`}
          data-test-id="new-user-form-img"
        />
        <Typography
          variant="h2"
          data-test-id="header-text"
        >
          Welcome to Troupe!
        </Typography>
        <Grid item>
          <Typography className={classes.subText}>
            To get started, what’s your {formTypeHumanized}?
          </Typography>
        </Grid>
        {errors && (
          <Grid item className={classes.errors}>
            <Errors errors={errors} />
          </Grid>
        )}
        <RailsForm
          action={usersPath()}
        >
          <Grid
            item
            className={classes.fieldsContainer}
          >
            <Grid item>
              <TextField
                id={textFieldDetails().id}
                name={textFieldDetails().name}
                label={textFieldDetails().label}
                variant="outlined"
                required={true}
                inputRef={focusedInputRef}
                fullWidth
                data-test-id={textFieldDetails().dataTestId}
              />
            </Grid>
            {formType === 'name' && (
              <Grid item className={classes.nameFields}>
                <TextField
                  id="user_last_name"
                  name="user[last_name]"
                  label="Last name"
                  variant="outlined"
                  fullWidth
                  data-test-id="user-last-name-field"
                />
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.buttonOuter}>
            <Button
              fullWidth
              type="submit"
              variant="primary"
              data-test-id="new-user-form-save-button"
            >
              Plan my first trip
            </Button>
          </Grid>
        </RailsForm>
      </Grid>
    </Grid>
  );
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(NewUserForm);
