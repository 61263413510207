import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  ButtonGroup,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteResourceConfirmationDialog from "../../../../shared/DeleteResourceConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 30,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: 36,
    },
  },
  buttonGroup: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #B4B9BC',
    color: theme.palette.text.primary,
  },
  root: {
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: '5px',
  },
}));

type DeleteButtonProps = {
  deletePath: string,
  resourceName: string,
};

const TripObjectDelete = ({
  deletePath,
  resourceName,
}: DeleteButtonProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Box className={classes.root}>
      <ButtonGroup
        classes={{
          groupedOutlined: classes.buttonGroup,
        }}
        disableElevation
        variant="outlined"
        size="small"
      >
        <Button
          data-test-id={`delete-${resourceName.replace(' ', '-')}-Button`}
          onClick={() => setDialogOpen(true)}
          className={classes.button}
        >
          <DeleteIcon fontSize="small" />
        </Button>
      </ButtonGroup>
      <DeleteResourceConfirmationDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        path={deletePath}
        resourceName={resourceName}
      />
    </Box>
  );
};

export default TripObjectDelete;
