import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Typography,
} from "@mui/material";
import AvatarUploader from "./AvatarUploader";
import { UserType } from "../../AccountPage/ProfileForm/BasicInfoFields";

type AvatarSectionProps = {
  user: UserType,
};

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: 20,
    fontWeight: 600,
    color: "#061722",
    paddingTop: theme.spacing(2),
  },
}));

const AvatarSection = ({
  user,
}: AvatarSectionProps) => {
  const classes = useStyles();
  const { avatarUrl, defaultAvatarUrl, fullName } = user;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <AvatarUploader
          currentAvatarUrl={avatarUrl}
          defaultAvatarUrl={defaultAvatarUrl}
        />
      </Grid>
      <Grid item>
        <Typography className={classes.name}>
          {fullName}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AvatarSection;
