import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import { searchPath } from "../../../routes";
import FavoritesButton from "../../buttons/FavoritesButton";

type FilterBackgroundProps = {
  children: React.ReactNode,
  logoPath: string,
};

const useStyles = makeStyles((theme) => ({
  FilterBackground: {
    display: 'flex',
    flexDirection: 'column',
  },
  background: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    width: '100%',
    backgroundColor: theme.palette.common.white,
    height: '100%',
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)}`,
    },
    padding: `${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(5)}`,
    maxWidth: '800px',
    alignSelf: 'center',
  },
  header: {
    fontWeight: '600',
    fontSize: '20px',
    marginBottom: theme.spacing(2),
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    width: '100%',
    objectFit: 'cover',
    height: '360px',
    [theme.breakpoints.down('xs')]: {
      height: '300px',
    },
    backgroundSize: 'contain',
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: theme.spacing(3.5),
    cursor: 'pointer',
  },
  logoFavorites: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const FilterBackground : React.FC<FilterBackgroundProps> = ({
  children,
  logoPath = searchPath(),
}) => {
  const classes = useStyles();

  return(
    <div className={classes.FilterBackground}>
      <img src="/assets/public_search_background_smaller.jpg" alt="" className={classes.backgroundImage} />
      <div className={classes.backgroundImage}></div>
      <div className={classes.logoFavorites}>
        <img
          src="/assets/logo_white.svg"
          alt="Troupe logo"
          className={classes.logo}
          onClick={() => window.location.href = logoPath}
        />
        <FavoritesButton />
      </div>
      <div className={classes.background}>
        <Typography className={classes.header}>
          Find the perfect stay
        </Typography>
        {children}
      </div>
    </div>
  )
}

export default FilterBackground;
