import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Typography,
} from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import CustomChip from '../../../../buttons/CustomChip';
import { tripUpdatesPath } from '../../../../../routes';
import { TripType } from "../../../../shared/TripTypes";

type FilteringBarProps = {
  countByCategory: {[key: string]: number},
  currentFilter?: string,
  currentUserId: string,
  isPlanner: boolean,
  trip: TripType,
};

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: 'auto',
    flexWrap: 'nowrap',
  },
  title: {
    color: '#303232',
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
}));

const FilteringBar = ({
  countByCategory,
  currentFilter,
  currentUserId,
  isPlanner,
  trip,
}: FilteringBarProps) => {
  const classes = useStyles();
  const filterPathFor = (category) => {
    return tripUpdatesPath(trip.id, { 'trip_event_filter': { 'category': category } })
  };

  const filteringOptions = [
    {
      active: !currentFilter,
      disabled: false,
      label: 'All',
      count: Object.values(countByCategory).reduce((sum, count) => sum + count),
      path: tripUpdatesPath(trip.id),
    },
    {
      active: currentFilter === 'reaction',
      disabled: false,
      label: 'Reactions',
      count: countByCategory['reaction'],
      path: filterPathFor('reaction'),
    },
    {
      active: currentFilter === 'broadcast',
      disabled: false,
      label: 'Broadcasts',
      count: countByCategory['broadcast'],
      path: filterPathFor('broadcast'),
    },
    {
      active: currentFilter === 'itinerary',
      disabled: false,
      label: 'Itinerary',
      count: countByCategory['itinerary'],
      path: filterPathFor('itinerary'),
    },
    {
      active: currentFilter === 'activity',
      disabled: false,
      label: 'Activities',
      count: countByCategory['activity'],
      path: filterPathFor('activity'),
    },
    {
      active: currentFilter === 'poll',
      disabled: false,
      label: 'Polls',
      count: countByCategory['poll'],
      path: filterPathFor('poll'),
    },
    {
      active: currentFilter === 'trip_date_option',
      disabled: false,
      label: 'Dates',
      count: countByCategory['trip_date_option'],
      path: filterPathFor('trip_date_option'),
    },
    {
      active: currentFilter === 'destination',
      disabled: false,
      label: 'Destinations',
      count: countByCategory['destination'],
      path: filterPathFor('destination'),
    },
    {
      active: currentFilter === 'stay',
      disabled: false,
      label: 'Stays',
      count: countByCategory['stay'],
      path: filterPathFor('stay'),
    },
    {
      active: currentFilter === 'note',
      disabled: false,
      label: 'Notes',
      count: countByCategory['note'],
      path: filterPathFor('note'),
    },
  ];

  const eventName = 'Trip Updates Filtered'
  const eventProperties = (label) => ({
    verticalFiltered: label,
    tripId: trip.id,
    userId: currentUserId,
    isPlanner: isPlanner,
  })

  return (
    <Grid container direction="column" justifyContent="center">
      <Grid item container spacing={1}>
        <Grid item>
          <TuneIcon />
        </Grid>
        <Grid item>
          <Typography className={classes.title}>
            Filter by
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={2} className={classes.container}>
        {filteringOptions.filter(option => !option.disabled).map((option, index) => (
          <Grid item key={index}>
            <CustomChip
              active={option.active}
              eventName={eventName}
              eventProperties={eventProperties(option.label)}
              href={option.path}
            >
              {option.label} ({option.count})
            </CustomChip>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default FilteringBar;
