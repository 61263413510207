import React from "react";
import ButtonBase from "@mui/material/ButtonBase";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

type ButtonPlacement = 'bottom' | 'top-left';
type Styles = (placement: ButtonPlacement) => (() => any);

export type FloatingButtonProps = {
  children: React.ReactNode,
  className?: string,
  onClick?: () => void,
  placement?: ButtonPlacement,
};

const buttonPlacements = {
  bottom: {
    bottom: '2%',
    left: '50%',
    position: 'fixed',
    top: 'auto',
    transform: 'translate(-50%, -50%)',
  },
  'top-left': {
    bottom: 'auto',
    left: '5%',
    position: 'absolute',
    top: '5%',
    transform: 'none',
  },
}

const useStyles: Styles = (
  placement: ButtonPlacement,
) => makeStyles((theme): {} => ({
  root: {
    backgroundColor: 'white',
    borderRadius: theme.spacing(0.75),
    bottom: buttonPlacements[placement].bottom,
    boxShadow: '0 2px 4px 0 rgba(60, 64, 67, 0.3)',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    height: theme.spacing(6),
    left: buttonPlacements[placement].left,
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    position: buttonPlacements[placement].position,
    top: buttonPlacements[placement].top,
    transform: buttonPlacements[placement].transform,
    zIndex: 5,
  },
}));

const FloatingButton = React.forwardRef(({
  children,
  className,
  placement = 'bottom',
  ...buttonProps
}: FloatingButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const classes = useStyles(placement)();

  return (
    <ButtonBase
      ref={ref}
      data-test-id='floating-button'
      {...buttonProps}
      className={clsx(
        classes.root,
        className,
      )}
    >
      {children}
    </ButtonBase>
  );
});

FloatingButton.displayName = 'FloatingButton';

export default FloatingButton;
