import React, { useRef } from "react";
import { makeStyles } from '@mui/styles';
import { Grid } from "@mui/material";
import LoadMoreCommentsButton from "../../../../buttons/LoadMoreCommentsButton";
import CommentForm from "../../../../shared/Comments/CommentForm";
import CommentBox from "../../../../shared/Comments/CommentBox";
import { SyncComment } from "../../../../shared/TripObjectTypes";
import { PageType } from "../../../../shared/Comments/CommentForm";

type MostRecentCommentBoxProps = {
  comment?: SyncComment,
  commentFormPath: string,
  commentsPath: string,
  page?: PageType,
  showCommentForm: boolean,
  showLoadMoreCommentsButton: boolean,
  tripId: string,
};

const useStyles = makeStyles((theme) => ({
  commentBox: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
  },
}));

const MostRecentCommentBox = ({
  comment,
  commentFormPath,
  commentsPath,
  page = 'Vertical',
  showCommentForm,
  showLoadMoreCommentsButton,
  tripId,
}: MostRecentCommentBoxProps) => {
  const classes = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const el = scrollRef.current;

    if (showCommentForm && el.scrollIntoView) {
      el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
      el.getElementsByTagName('textarea')[0].focus({preventScroll: true})
    }
  }, [showCommentForm])

  return (
    <Grid container direction="column" spacing={1}>
      {showLoadMoreCommentsButton && (
        <Grid item>
          <LoadMoreCommentsButton
            commentsPath={commentsPath}
            tripId={tripId}
          />
        </Grid>
      )}
      {comment && (
        <Grid item>
          <ul className={classes.commentBox}>
            <CommentBox comment={comment} />
          </ul>
        </Grid>
      )}
      {showCommentForm && (
        <Grid item ref={scrollRef} sx={{ mt: comment ? 2 : 0 }}>
          <CommentForm
            data-test-id='comment-form'
            commentsPath={commentFormPath}
            isUserLoggedIn
            page={page}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default MostRecentCommentBox;
