const ordinalize = (number) => {
  switch(number){
  case 1:
    return "1st";
  case 2:
    return "2nd";
  case 3:
    return "3rd";
  }
};

const humanize = (commentableType) => {
  switch(commentableType){
  case 'Activity':
    return "an activity";
  case 'Destination':
    return "a destination";
  case 'Stay':
    return 'a stay';
  case 'TripDateOption':
    return "a date";
  case 'Poll':
    return "a poll";
  case 'Broadcast':
    return "a broadcast";
  }
};

const getEditStatement = (edits) => {
  const categories = {
    start_time: 'time',
    end_time: 'time',
    start_date: 'date',
    end_date: 'date',
    notes: 'note',
  };

  const editedCategories = Array.from(new Set(Object.keys(edits).filter(key => categories[key]).map(key => categories[key])));

  const formattedCategories = (categoriesArray) => {
    return `${categoriesArray.slice(0, -1).join(', ')}${categoriesArray.length > 1 ? ' and' : ''} ${categoriesArray.slice(-1)}`;
  }

  return `edited the ${formattedCategories(editedCategories)} for an event`;
}

const actionsMap = {
  "activity_voted": ({ metadata: { sentiment } }) => (
    `voted "${sentiment}" for activities`
  ),
  "activity_created": () => (
    `added a new activity`
  ),
  "activity_group_created": () => (
    `added a new group`
  ),
  "broadcast_created": () => (
    `sent a broadcast`
  ),
  "date_option_created": () => (
    `added new dates`
  ),
  "destination_created": () => (
    `added a new destination`
  ),
  "destination_voted": ({ metadata: { rank } }) => (
    `voted for their "${ordinalize(rank)} choice" destination`
  ),
  "stay_created": () => (
    `added a new stay`
  ),
  "stay_voted": ({ metadata: { rank } }) => (
    `voted for their "${ordinalize(rank)} choice" stays`
  ),
  "date_option_voted": ({ metadata: { sentiment } }) => (
    `voted "${sentiment}" for dates`
  ),
  "commented": ({ eventObject: { commentableType } }) => (
    `commented on ${humanize(commentableType)}`
  ),
  "finished_voted_date_options": () => (
    `finished voting for dates`
  ),
  "finished_voted_destination": () => (
    `finished voting for destination`
  ),
  "finished_voted_stay": () => (
    `finished voting for stays`
  ),
  "poll_created": () => (
     `added a new poll`
   ),
  "poll_closed": () => (
     `closed a poll`
   ),
  "note_created": () => (
    `created a new note`
  ),
  "destination_transitioned_to_suggesting": () => (
    `transitioned to suggesting for destination`
  ),
  "destination_transitioned_to_voting": () => (
    `transitioned destinations to voting`
  ),
  "dates_transitioned_to_suggesting": () => (
    `transitioned dates to suggesting`
  ),
  "dates_transitioned_to_voting": () => (
    `transitioned dates to voting`
  ),
  "stay_transitioned_to_suggesting": () => (
    `transitioned stays to suggesting`
  ),
  "stay_transitioned_to_voting": () => (
    `transitioned stays to voting`
  ),
  "dates_transitioned_to_finalized": () => (
    "finalized the dates"
  ),
  "stay_transitioned_to_finalized": () => (
    "finalized the stay"
  ),
  "destination_transitioned_to_finalized": () => (
    "finalized the destination"
  ),
  "reaction_created": ({ eventObject: { emoji, reactableType }}) => (
    `reacted “${emoji}“ to a ${reactableType}`
  ),
  "itinerary_event_created": () => (
    "added an event to the itinerary"
  ),
  "itinerary_event_updated": ({ metadata}) => (
    getEditStatement(metadata)
  ),
};

export default actionsMap;
