import React from "react";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

type CommentFieldProps = {
  fieldId?: string,
  fieldName: string,
  subtitle?: string,
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CommentField = ({
  fieldId,
  fieldName,
  subtitle,
}: CommentFieldProps) => {
  const classes = useStyles();

  return (
    <Grid item data-test-id='add-comment'>
      <Grid item>
        <Typography className={classes.label}>
          Add a comment
        </Typography>
      </Grid>
      { subtitle &&
        <Grid item>
         <Typography>
           {subtitle}
         </Typography>
        </Grid>
      }
      <Grid item>
       <TextField
         data-test-id='comment-text-field'
         fullWidth
         id={fieldId}
         label="Comment"
         name={fieldName}
         variant="outlined"
       />
      </Grid>
    </Grid>
  );
};

export default CommentField;
