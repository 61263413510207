import React from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { hocRailsAction } from "../../Document";
import ProfileFormPage from "./AccountPage/ProfileForm";
import { UserType } from "./AccountPage/ProfileForm/BasicInfoFields";
import SiteHeader from "../../shared/SiteHeader";
import * as routes from '../../../routes';
import BackToButton from '../trips/microsite/shared/BackToButton';

const {
  accountPath,
  tripsPath,
} = routes;

type AccountPageProps = {
  errors?: string[],
  fieldFocus: string,
  isPushNotificationsEnabled: boolean,
  isTemporaryUser: boolean,
  isUserLoggedIn: boolean,
  successNotification?: string,
  updateToken?: string,
  user: UserType,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: "#061722",
    paddingTop: theme.spacing(2),
  },
  eventAvatar: {
    width: 68,
    height: 68,
  },
}));

const AccountPage = ({
  errors,
  fieldFocus,
  isPushNotificationsEnabled,
  isTemporaryUser,
  isUserLoggedIn,
  successNotification,
  updateToken,
  user,
}: AccountPageProps) => {
  const classes = useStyles();
  const { avatarUrl, fullName } = user;

  return (
    <div className={classes.root}>
      <SiteHeader
        currentUserAvatarUrl={avatarUrl}
        isTripUpdatesHidden
        isUserLoggedIn={isUserLoggedIn}
      />
      <Box pt={5} pl={2}>
        <BackToButton path={tripsPath()} text="Back to My Trips" />
      </Box>
      <Grid container>
        <Grid item>
          <Typography className={classes.headerTitle} sx={{ paddingLeft: '16px' }}>Account</Typography>
        </Grid>
      </Grid>
      <ProfileFormPage
        errors={errors}
        fieldFocus={fieldFocus}
        isPushNotificationsEnabled={isPushNotificationsEnabled}
        isTemporaryUser={isTemporaryUser}
        updatePath={accountPath({ token: updateToken })}
        successNotification={successNotification}
        user={user}
      />
    </div>
  );
};

export default hocRailsAction(AccountPage);
