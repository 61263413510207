import React, { useState } from "react";
import withMicrositeLayout from "./withLayout";
import { ActivityGroupType } from "../../../shared/TripObjectTypes";
import MainContent from "./ActivityGroupPage/MainContent";
import SideBarContent from "./ActivityGroupPage/SideBarContent";
import FullscreenMapView from './shared/ActivitiesMap/FullscreenMapView';
import SideBarPage from "../../../shared/SideBarPage";
import MobileMapButton from '../../../shared/MapView/MobileMapButton';
import {
  ActivityFilterContext,
  ActivityFilterContextType,
  ActivitySortingContext,
  ActivitySortingContextType,
} from "../../../shared/ActivityFilterContext";

type ActivityGroupPageProps = {
  activityGroup: ActivityGroupType,
  isPlanner: boolean,
  filterContextProps: ActivityFilterContextType,
  sortingContextProps: ActivitySortingContextType,
};

const ActivityGroupPage = ({
  activityGroup,
  isPlanner,
  filterContextProps,
  sortingContextProps,
}: ActivityGroupPageProps) => {
  const [fullscreenMap, setFullscreenMap] = useState(false);
  const activitiesWithCoords = activityGroup.activitiesSelected.filter((activity) => activity.longitude && activity.latitude);

  return <>
    <ActivityFilterContext.Provider value={filterContextProps}>
      <ActivitySortingContext.Provider value={sortingContextProps}>
        {fullscreenMap ? (
          <FullscreenMapView
            activities={activitiesWithCoords}
            onListButtonClick={() => setFullscreenMap(false)}
          />
        ) : (
          <>
            <SideBarPage
              mainContent={
                <MainContent
                  activityGroup={activityGroup}
                  isPlanner={isPlanner}
                />
              }
              sideBarContent={
                <SideBarContent
                  activities={activityGroup.activitiesSelected}
                  onExpandMap={() => setFullscreenMap(true)}
                />
              }
            />
            {activitiesWithCoords.length > 0 && (
              <MobileMapButton onClick={() => setFullscreenMap(true)} source='activities' />
            )}
          </>
        )}
      </ActivitySortingContext.Provider>
    </ActivityFilterContext.Provider>
  </>
};

export default withMicrositeLayout(ActivityGroupPage, { fluid: true });
