import React from "react";
import {
  Box,
  ButtonBase,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { DialogBottomOnMobile } from "../../../shared/Dialog";
import { makeStyles } from "@mui/styles";
import { Alternative } from "./AiResults";
import { ListingsContext } from "../StaysSearch";

type AlternativesDialogProps = {
  alternatives: Alternative[],
  onClose: () => void,
  open: boolean,
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#000',
    fontSize: 16,
    fontWeight: 600,
    marginRight: theme.spacing(3),
  },
  alternativeBox: {
    background: '#DDEBFF',
    border: '1px solid #BEC9D4',
    borderRadius: '5px',
    height: '88px',
    padding: '12px 16px',
    textAlign: 'left',
  },
  name: {
    color: '#000',
    fontSize: 14,
    fontWeight: 600,
  },
  rationale: {
    marginTop: '4px',
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
  },
  arrow: {
    width: '14px',
    verticalAlign: 'middle',
    float: 'right',
  },
}));

const AlternativesDialog : React.FC<AlternativesDialogProps> = ({
  alternatives,
  onClose,
  open,
}) => {
  const classes = useStyles();
  const { onAlternativeTileClick } = React.useContext(ListingsContext);

  const AlternativeBox = ({ alternative } : { alternative : Alternative }) => (
    <ButtonBase disableRipple onClick={() => onAlternativeTileClick(alternative)} className={classes.alternativeBox}>
      <Grid container direction="column" flexWrap="nowrap">
        <Grid item className={classes.name}>
          {alternative.name}
          <img src="/assets/right-arrow.svg" className={classes.arrow} />
        </Grid>
        <Grid item className={classes.rationale}>
          {alternative.rationale}
        </Grid>
      </Grid>
    </ButtonBase>
  );

  const title = (
    <Typography className={classes.title}>Based on your search, we think you might also like</Typography>
  );

  const content = (
    <Grid container spacing={2}>
      {alternatives.map((alternative, index) => (
        <Grid item xs={12} md={4} key={`alternative-${index}`}>
          <AlternativeBox alternative={alternative} />
        </Grid>
      ))}
    </Grid>
  );

  const loading = (
    <Grid container spacing={2}>
      {Array.from({ length: 3 }).map((_, index) => (
        <Grid item xs={12} md={4} key={`skeleton-${index}`}>
          <Box className={classes.alternativeBox}>
            <Grid container direction="column" flexWrap="nowrap">
              <Grid item>
                <Skeleton variant="text" />
              </Grid>
              <Grid item>
                <Skeleton variant="rectangular" height={40} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <DialogBottomOnMobile
      fullWidth
      isOpen={open}
      title={title}
      message={alternatives.length > 0 ? content : loading}
      onClose={onClose}
      maxWidth="md"
    />
  );
};

export default AlternativesDialog;
