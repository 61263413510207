import React from "react";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";

type ContainerLinkProps = {
  showLink: boolean,
  children: React.ReactNode,
  href?: string,
} & ButtonBaseProps;

const ContainerLink = ({
  showLink,
  children,
  ...linkProps
}: ContainerLinkProps) => {
  const RootComponent = showLink ? ButtonBase : React.Fragment;
  const props = showLink ? linkProps : {};

  return (
    <RootComponent {...props}>
      {children}
    </RootComponent>
  );
}

export default ContainerLink;
