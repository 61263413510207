import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import {
  DateOptionType,
  DestinationType,
} from "../../../../shared/TripObjectTypes";
import makeStyles from "@mui/styles/makeStyles";
import SectionDivider from "./SectionDivider";

type FinalizedDatesAndDestinationProps = {
  chosenDateOption?: DateOptionType,
  chosenDestination?: DestinationType,
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0px',
  },
  boldText: {
    color: '#545DFC',
    fontWeight: 700,
  },
}));

const FinalizedDatesAndDestination = ({
  chosenDateOption,
  chosenDestination,
}: FinalizedDatesAndDestinationProps) => {
  const classes = useStyles();

  const renderDestination = () => (
    <span className={classes.boldText}> {chosenDestination.name} </span>
  )

  const renderDates = () => (
    <span className={classes.boldText}> {chosenDateOption.startDate}-{chosenDateOption.endDate}, {chosenDateOption.endDateYear}</span>
  )

  const renderFinalizedCopy = () => (
    <Typography className={classes.root}>
      { chosenDestination && !chosenDateOption && <>
        You’re going to
        {renderDestination()}
      </>
      }
      { !chosenDestination && chosenDateOption && <>
        Your trip is
        {renderDates()}
      </>
      }
      { chosenDestination && chosenDateOption && <>
        You’re going to
        {renderDestination()}
        from
        {renderDates()}
      </>
      }
    </Typography>
  )

  if(!chosenDateOption && !chosenDestination) {
    return null
  }

  return <>
    <Grid
      container
      direction="column"
      spacing={3}
      alignItems="center"
      data-test-id='finalized-dates-and-destination'
      pt={3}
    >
      <Grid item textAlign='center'>
        {renderFinalizedCopy()}
      </Grid>
    </Grid>
    <SectionDivider />
  </>
}
export default FinalizedDatesAndDestination;
