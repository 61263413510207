import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import { Poll } from './PollView/PollTypes';
import PollView from "./PollView";
import { tripPollCommentsPath } from "../../../../../routes";
import MostRecentCommentBox from "../shared/MostRecentCommentBox";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  list: {
    width: '100%',
  },
}));

type PollsListProps = {
  polls: Array<Poll>,
};

const PollsList = ({
  polls,
}: PollsListProps) => {
  const classes = useStyles();
  const initialValue = Array(polls.length).fill(false);
  const [
    showCommentForm,
    setShowCommentForm,
  ] = useState(initialValue);

  const toggleCommentFormVisibility = (index) => {
    const newArray = Array.from(showCommentForm);
    newArray[index] = !showCommentForm[index];
    setShowCommentForm(newArray);
  };

  return (
    <Grid className={classes.root} container justifyContent='center' spacing={2}>
      {polls.map((poll, index) => (
        <Grid
          item
          container
          direction="column"
          spacing={1}
          data-test-id="poll"
          key={index}
          sx={{ mb: 3 }}
          id={`poll_${poll.id}`}
        >
          <Grid item className={classes.list} key={index}>
            <PollView
              displayCommentButton
              poll={poll}
              shouldRedirectTo='index'
              displayFormToggle={() => toggleCommentFormVisibility(index)}
              commentFormToggle={showCommentForm[index]}
            />
          </Grid>
          {(poll.mostRecentComment || showCommentForm) && (
            <Grid item>
              <MostRecentCommentBox
                comment={poll.mostRecentComment}
                commentFormPath={tripPollCommentsPath(poll.tripId, poll.id)}
                commentsPath={tripPollCommentsPath(poll.tripId, poll.id)}
                showCommentForm={showCommentForm[index]}
                showLoadMoreCommentsButton={poll.commentsCount > 1}
                tripId={poll.tripId}
              />
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default PollsList;
