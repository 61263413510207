import React from "react";
import {
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SyncComment } from "../TripObjectTypes";
import ContainerLink from "../ContainerLink";
import CardButton from "../../buttons/CardButton";
import Linkify from "../../shared/Linkify";

type CommentBodyProps = {
  comment: SyncComment,
};

const useStyles = makeStyles((theme) => ({
  commentWrapper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  commentBody: {
    fontStyle: 'italic',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#212121',
  },
  containerLink: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  editedText: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

const CommentBody = ({
  comment,
}: CommentBodyProps) => {
  const classes = useStyles();

  return <>
    <ContainerLink
      href={comment.commentsUrl}
      showLink
      disableTouchRipple
      className={classes.containerLink}
    >
      <CardButton className={classes.commentWrapper} disabled={false}>
        <Typography className={classes.commentBody}>
          <Linkify>{comment.body}</Linkify>
          {comment.editedAt && (
            <span className={classes.editedText}> (Edited)</span>
          )}
        </Typography>
      </CardButton>
    </ContainerLink>
  </>;
};

export default CommentBody;
