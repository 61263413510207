import React, {
  Component,
  ReactNode,
} from "react";
import ErrorBoundaryMessage from "./ErrorBoundary/ErrorBoundaryMessage";
import * as Sentry from "@sentry/browser";

interface Props {
  fallback?: ReactNode;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { ...error, hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError === false) return this.props.children;
    if (this.props.fallback) return this.props.fallback;

    return <ErrorBoundaryMessage />;
  }
}
