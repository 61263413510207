import React from "react";
import { Typography } from "@mui/material";
import DateOptionsAvailabilityFields from "./DateOptionsAvailability/DateOptionsAvailabilityFields";
import { DateOptionWithAvailabilityType } from "../../../shared/TripObjectTypes";

type DateOptionsAvailabilityProps = {
  dateOptions: DateOptionWithAvailabilityType[],
  hasChosenDate: boolean,
  manual: boolean,
};

const DateOptionsAvailability = ({
  dateOptions,
  hasChosenDate,
  manual,
}: DateOptionsAvailabilityProps) => {
  return (
    <>
      {manual && (
        <DateOptionsAvailabilityFields
          dateOptions={dateOptions}
          hasChosenDate={hasChosenDate}
          withTotalPriceField={manual}
        />
      )}
      {!manual && (
        <Typography>
          Troupe automatically updates this listing’s pricing and availability for you.
        </Typography>
      )}
    </>
  );
};

export default DateOptionsAvailability;
