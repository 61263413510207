import React, { useState } from 'react';
import pluralize from "pluralize";
import clsx from "clsx";
import { sortBy } from 'lodash'
import {
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from "@mui/styles";
import Link from "./Link";
import TablePaginationActions from "./TravelersVotingBox/TablePaginationActions";
import TravelerRow from "./TravelersVotingBox/TravelerRow";
import { track as trackAnalyticsEvent } from '../../services/analytics';
import { TypeTripObjectType } from './TripObjectTypes';
import titleize from 'titleize';
import { PlusOneType } from '../../types/PlusOneTypes';

export type TravelerProps = {
  id: string,
  avatarUrl: string,
  displayName: string,
  missingVotesCount?: number,
  name: string,
  status: string,
  destinationRankings?: string[],
  stayRankings?: string[],
  plusOneCount?: number,
  plusOnes: PlusOneType[],
  userId: string,
  stayMissingVotesCount?: number,
  destinationMissingVotesCount?: number,
};

type TravelersVotingBoxProps = {
  isEmptyState?: boolean,
  travelers: TravelerProps[],
  tripId: string,
  verticalType: TypeTripObjectType,
};

const TRAVELERS_PER_PAGE = 4;
const TRAVELER_ROW_HEIGHT = 64;

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '32px',
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  travelersList: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  travelersListHeight: {
    height: TRAVELERS_PER_PAGE * TRAVELER_ROW_HEIGHT,
  },
  paginationCaption: {
    fontSize: 16,
  },
  paginationCount: {
    fontWeight: 600,
  },
  paginationSpacer: {
    display: 'none',
  },
  paginationToolbar: {
    padding: `0px ${theme.spacing(0.5)}`,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const TravelersVotingBox = ({
  isEmptyState,
  travelers,
  tripId,
  verticalType,
}: TravelersVotingBoxProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'), {noSsr: true});
  const [collapsed, setCollapsed] = useState(isMobile);
  const buttonText = collapsed ? 'Show' : 'Hide';

  const sortedTravelers = sortBy(travelers, 'missingVotesCount')

  const formattedVerticalType = titleize(verticalType);

  const onToggleButtonClick = () => {
    setCollapsed(!collapsed);

    trackAnalyticsEvent('Traveler List Visibility Toggled', {
      category: 'Traveler List',
      tripId: tripId,
      verticalViewed: formattedVerticalType,
      visibility: collapsed ? 'collapsed' : 'expanded',
    });
  };

  const [page, setPage] = useState(0);

  const paginatedTravelers = (page) => {
    const array = [];
    for(let i = 0; i < sortedTravelers.length; i += TRAVELERS_PER_PAGE) {
      array.push(sortedTravelers.slice(i, i+TRAVELERS_PER_PAGE));
    }
    return array[page];
  };

  const labelDisplayedRows = ({ from, to, count }) => {
    return <>
      <span className={classes.paginationCount}>{from}–{to}</span> of {pluralize('traveler', count, true)}
    </> ;
  };

  const adjustTravelersListHeight = travelers.length > TRAVELERS_PER_PAGE;

  return (
    <Grid
      container
      direction="column"
      data-test-id="travelersVotingBox"
    >
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography className={classes.title}>Travelers ({travelers.length})</Typography>
        </Grid>
        <Grid item>
          <Link
            className={classes.button}
            onClick={() => onToggleButtonClick()}
            text={buttonText}
          />
        </Grid>
      </Grid>
      {!collapsed && <>
        <Grid
          item
          container
          direction="column"
          className={clsx(
            classes.travelersList,
            {
              [classes.travelersListHeight]: adjustTravelersListHeight,
            },
          )}
          spacing={2}
          data-test-id='travelers-list'
        >
          {paginatedTravelers(page).map((traveler, index) => (
            <TravelerRow key={index} isEmptyState={isEmptyState} traveler={traveler} verticalType={verticalType}/>
          ))}
        </Grid>
        <TablePagination
          classes={{
            displayedRows: classes.paginationCaption,
            spacer: classes.paginationSpacer,
            toolbar: classes.paginationToolbar,
          }}
          component="div"
          count={travelers.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={TRAVELERS_PER_PAGE}
          rowsPerPageOptions={[]}
          labelDisplayedRows={labelDisplayedRows}
          ActionsComponent={TablePaginationActions}
          data-test-id="pagination"
        />
      </>}
    </Grid>
  );
};

TravelersVotingBox.defaultProps = {
  isEmptyState: false,
};

export default TravelersVotingBox;
