import React, { useState, useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  MenuItem,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteResourceConfirmationDialog from "./DeleteResourceConfirmationDialog";
import BaseActionsMenu from "./BaseActionsMenu";
import BookingActionsMenuItems from "./BookingActionsMenuItems";

type ActionsButtonGroupProps = {
  deleteDialogAdditionalMessage?: string | React.ReactNode,
  deletePath: string,
  disableDeleteButton?: boolean,
  editPath: string,
  resourceName: 'stay' | 'destination' | 'activity' | 'activity group',
  testId?: string,
  onEditClick?: () => void | null,
  onMarkAsBookedClick?: () => void | null,
  newBookingPath?: string,
  updateBookingPath?: string,
  displayMarkAsBooked?: boolean,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 3,
  },
  icon: {
    color: '#334351',
  },
  iconImage: {
    height: "20px",
    width: "20px",
    marginRight: "5px",
  },
}));

const ActionsButtonGroup : React.FC<ActionsButtonGroupProps> = ({
  deleteDialogAdditionalMessage,
  deletePath,
  disableDeleteButton,
  editPath,
  resourceName,
  testId,
  onEditClick,
  onMarkAsBookedClick,
  newBookingPath,
  updateBookingPath,
  displayMarkAsBooked = false,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDeleteClick = () => {
    setDialogOpen(true);
  };

  const goToEditPath = () => {
    if(onEditClick) onEditClick();
    window.location.href = editPath;
  };

  return (
    <BaseActionsMenu
      testId={testId}
      dialogs={
        <DeleteResourceConfirmationDialog
          isOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
          path={deletePath}
          resourceName={resourceName}
          additionalMessage={deleteDialogAdditionalMessage}
        />
      }
      className={classes.root}
    >
      <BookingActionsMenuItems
        displayMarkAsBooked={displayMarkAsBooked}
        newBookingPath={newBookingPath}
        updateBookingPath={updateBookingPath}
        onMarkAsBookedClick={onMarkAsBookedClick}
      />
      <MenuItem data-test-id={`edit-${resourceName.replace(' ', '-')}-button`} onClick={goToEditPath} dense>
        <EditIcon fontSize="small" sx={{marginRight: '5px'}}/>
        Edit
      </MenuItem>
      <Tooltip
        data-test-id="disabled-delete"
        placement="bottom-start"
        title={disableDeleteButton ? `You cannot delete a finalized ${resourceName}` : ''}
      >
        <MenuItem
          data-test-id={`delete-${resourceName.replace(' ', '-')}-button`}
          onClick={!disableDeleteButton ? () => handleDeleteClick() : null}
          dense
        >
          <DeleteIcon fontSize="small" sx={{marginRight: '5px'}}/>
          Delete
        </MenuItem>
      </Tooltip>
    </BaseActionsMenu>
  );
};

export default ActionsButtonGroup;
