import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Avatar from "../../../avatars/Avatar";

type InviteeData = {
  avatarUrl?: string,
  name: string,
};

export type AcceptedInviteData = {
  respondedAt: string,
  createdAt: string,
  email: string,
  id: string,
  invitee: InviteeData,
  inviterName: string,
};

type AcceptedInviteProps = {
  invite: AcceptedInviteData,
};

const useStyles = makeStyles((theme) => ({
  respondedAtText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  info: {
    paddingTop: `0px !important`,
  },
}));

const AcceptedInvite = ({ invite }: AcceptedInviteProps) => {
  const classes = useStyles();
  const { avatarUrl, name } = invite.invitee;

  return (
    <Grid container data-test-id="acceptedInvite" spacing={1}>
      <Grid item>
        <Avatar src={avatarUrl} />
      </Grid>
      <Grid item className={classes.info}>
        <Typography>
          {name}
        </Typography>
        <Typography className={classes.respondedAtText}>
          Joined trip {invite.respondedAt}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AcceptedInvite;
