import { gql } from '@apollo/client'

export const PLUS_ONE_DELETE = gql`
  mutation TravelerPlusOneDelete($additionalTravelerId: ID!) {
    travelerPlusOneDelete(input: {
      plusOneId: $additionalTravelerId,
    }) {
      errors
    }
  }
`
