import React, { useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { Link as TitleLink, Typography } from "@mui/material";
import Linkify from "../../../../../shared/Linkify";

type ActivityNameProps = {
  activityName: string,
  linkToComment?: string,
  showLink?: boolean,
  onActivityClick?: () => void,
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    left: '3px',
    textAlign: 'left',
    lineHeight: '24px',
    color: 'black',
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: [18, '!important'],
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: "calc(100% - 88px)",
      marginRight: theme.spacing(5),
    },
    maxWidth: '100%',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    overflow: 'hidden',
    wordBreak: 'break-word',
    zIndex: 2,
  },
  activityNameLink: {
    '&:hover': {
      color: '#3B41B0',
      textDecoration: 'none',
    },
  },
}));

const ActivityName = ({
  activityName,
  linkToComment,
  showLink = true,
  onActivityClick,
}: ActivityNameProps) => {
  const classes = useStyles();

  return <>
    { showLink ?
      <TitleLink
        href={linkToComment}
        underline="none"
        color="inherit"
        className={clsx(classes.root, classes.activityNameLink)}
        data-test-id='activity-name'
      >
        <Linkify>{activityName}</Linkify>
      </TitleLink>
      :
      <Typography
        className={classes.root}
        data-test-id='activity-name-label'
        onClick={onActivityClick}
      >
        {activityName}
      </Typography>
    }
  </>
}
export default ActivityName;
