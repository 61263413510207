import React from 'react';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { Typography } from '@mui/material';
import ErrorBoundary from "./ErrorBoundary"
import moment from 'moment';
import Tooltip from './Tooltip';
import { makeStyles } from "@mui/styles";

const customLabels: any = {
  now: {
    past: "Just now",
  },
  minute: {
    past: {
      one: "{0}m",
      other: "{0}m",
    },
  },
  day: {
    past: {
      one: "Yesterday",
      other: "{0}d",
    },
  },
  hour: {
    past: {
      one: "{0}h",
      other: "{0}h",
    },
  },
  week: {
    past: {
      other: "{0}w",
    },
  },
  month: {
    past: {
      other: "{0}mo",
    },
  },
  year: {
    past: {
      other: "{0}y",
    },
  },
};

type TimeStampProps = {
  date: number,
  className?: string,
};

const customType: any = 'custom'
TimeAgo.addDefaultLocale(en);
TimeAgo.addLabels('en', customType, customLabels);

const customStyle: any = {
  labels: customType,
};

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      color: '#3B41B0',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}));

const CustomTooltip = ({ verboseDate, children, ...restOfProps }) => (
  <Tooltip {...restOfProps} title={verboseDate}>
    {children}
  </Tooltip>
);

const TimeStamp = ({ className, date }: TimeStampProps) => {
  const classes = useStyles();
  const dateTime = new Date(date);
  const formatDate = moment(dateTime).local().format('llll');

  return (
    <Typography className={className}>
      <ErrorBoundary fallback={formatDate}>
        <ReactTimeAgo
          className={classes.root}
          data-test-id='react-time-ago'
          date={dateTime}
          locale="en-US"
          timeStyle={customStyle}
          formatVerboseDate={() => dateTime.toLocaleString('en-US', { dateStyle: 'full', timeStyle: 'short', hour12: true })}
          wrapperComponent={CustomTooltip}
        />
      </ErrorBoundary>
    </Typography>
  );
};

export default TimeStamp;
