import React, { useState } from "react";
import {
  ActivityType,
} from "../../../../shared/TripObjectTypes";
import {
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TripObject, { Variation } from "../../../../shared/TripObject";
import ActionsButtonGroup from "../../../../shared/ActionsButtonGroup";
import Button from "../../../../buttons/Chip";
import CommentIcon from '@mui/icons-material/Comment';
import CommentsLink from "../shared/CommentsLink";
import CategoryLabel from "../shared/CategoryLabel";
import { useTheme } from "@mui/styles";
import TripObjectLink, { EventTrackerType } from "../../../../shared/TripObjectLink";
import SelectButton from "../../../../buttons/SelectButton";
import SentimentButtons from '../../microsite/shared/SentimentButtons';
import RemoveActivityFromGroupButton from "./Activity/RemoveActivityFromGroupButton";
import ActivityVotingSummaryDialog from './Activity/ActivityVotingSummaryDialog';
import { TravelerProps } from "../../../../shared/TravelersVotingBox";
import * as routes from '../../../../../routes';
import Header from "./Activity/Header";
import ActivityGroupsTitles from "./Activity/ActivityGroupsTitles";

const {
  tripDeleteActivityPath,
  editTripActivityPath,
  tripRemoveActivityPath,
  tripActivityVotesPath,
} = routes;

type StyleProps = {
  isCarouselItem: boolean,
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  activityCardImage: {
    display: 'block',
    height: "100%",
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '172px',
    },
    objectFit: 'contain',
    objectPosition: 'center',
    background: '#F4F5FF',
  },
  activityLink: {
    display: 'inline-block',
    zIndex: 2,
  },
  bottomInfoContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  googleLink: {
    display: 'inline-block',
    zIndex: 2,
  },
  infoContainer: {
    width: '100%',
    paddingTop: theme.spacing(1),
    minHeight: '100px',
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  fallbackImageObjectFit: {
    objectFit: 'fill',
  },
  nonFallbackImageObjectFit: {
    objectFit: 'cover',
  },
  commentButton: {
    zIndex: 2,
    height: '32px !important',
  },
  nonSearchPageImageSize: {
    [theme.breakpoints.up('sm')]: {
      height: '368px',
    },
  },
  root: {
    display: 'block',
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      minHeight: ({isCarouselItem}) => isCarouselItem ? 'auto' : '368px',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(21),
    },
    objectPosition: 'center',
    background: '#F4F5FF',
  },
  cardVariationImage: {
    maxHeight: 200,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  fullHeight: {
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  commentsContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    marginTop: 'auto',
  },
  voteBox: {
    justifyContent: 'space-between',
  },
  commentBox: {
    whiteSpace: 'nowrap',
    justifyContent: 'flex-end',
  },
  tripActivity: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '368px',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: ({isCarouselItem}) => isCarouselItem ? '368px' : '442px',
      height: ({isCarouselItem}) => isCarouselItem ? '368px' : 'auto',
    },
  },
  divider: {
    borderColor: '#e6e7e8',
    width:'100%',
    padding: theme.spacing(1),
  },
  externalButtons: {
    flexDirection: 'row',
    padding: '16px 0px 16px 0',
  },
  selectButtonWrapper: {
    position: 'absolute',
    bottom: 0,
    marginBottom: theme.spacing(2),
    width: '62%',
  },
  verticalActions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  commentButtonContainer: {
    textAlign: 'right',
  },
}));

type ActivityProps = {
  activity: ActivityType,
  activityGroupId?: string,
  activityTracker?: EventTrackerType,
  setActivityComment?: () => void,
  displayActivityLinks?: boolean,
  displayCommentButton?: boolean,
  displaySelectButton?: boolean,
  isCarouselItem?: boolean,
  isPlanner?: boolean,
  isSelectedActivity?: boolean,
  onActivityClick?: () => void,
  showActions?: boolean,
  linkToComment?: string,
  groupPermapage?: boolean,
  variation?: Variation,
  showSentiments?: boolean,
  showGroupsTitles?: boolean,
};

export const Activity = ({
  activity,
  activityGroupId,
  activityTracker,
  setActivityComment,
  displayCommentButton = false,
  displaySelectButton = false,
  displayActivityLinks = true,
  isCarouselItem = false,
  isPlanner,
  isSelectedActivity = false,
  onActivityClick,
  showActions = false,
  linkToComment,
  groupPermapage = false,
  variation = null,
  showSentiments = true,
  showGroupsTitles = false,
}: ActivityProps) => {
  const classes = useStyles({ isCarouselItem });
  const [imageSource, setImageSource] = useState(activity.image);
  const fallbackSource = "/assets/activity_fallback.svg";
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [dialogOpen, setDialogOpen] = useState(false);

  const activityRankLabels = [
    { icon: "HeartEyes", iconExt: 'png', label: "Love", shortLabel: "Love", value: "love" },
    { icon: "SlightSmile", iconExt: 'png', label: "Like", shortLabel: "Like", value: "like" },
    { icon: "ThumbsDown", iconExt: 'png', label: "Pass", shortLabel: "Pass", value: "pass" },
  ];

  const tripObjectHeader = (prop: { showActions: boolean, groupPermapage?: boolean }): JSX.Element => {
    if(!showActions) { return <></> }
    if(groupPermapage) {
      return <>
        <RemoveActivityFromGroupButton
          actionPath={tripRemoveActivityPath(activity.tripId, activityGroupId, activity.id)}
        />
      </>
    }

    const deleteDialogAdditionalMessage = (groupsTitles: string[], hasItineraryEvent: boolean) => {
      if(groupsTitles?.length === 0 && !hasItineraryEvent) { return <></> }

      return <>
        {hasItineraryEvent && (
          <Box pt={3} data-test-id='events-message'>
            <Typography>This activity will disappear from your itinerary.</Typography>
          </Box>
        )}
        {groupsTitles?.length > 0 && (
          <Box pt={3} data-test-id='groups-titles-message'>
            <Typography>This activity will also disappear from the following groups:</Typography>
            <Box pt={1}>
              <ActivityGroupsTitles expanded titles={groupsTitles} />
            </Box>
          </Box>
        )}
      </>
    }

    return (
      <ActionsButtonGroup
        deleteDialogAdditionalMessage={
          deleteDialogAdditionalMessage(activity.groupsTitles, activity.hasItineraryEvent)
        }
        deletePath={tripDeleteActivityPath(activity.tripId, activity.id)}
        editPath={editTripActivityPath(activity.tripId, activity.id)}
        resourceName="activity"
        testId="activity-actions"
      />
    )
  };

  return <>
    <TripObject
      anchor={`activity_${activity.id}`}
      noMargin
      className={classes.tripActivity}
      testId="trip-activity"
      isClickable={true}
      onTripObjectClick={onActivityClick}
      linkHref={linkToComment}
      header={tripObjectHeader({showActions, groupPermapage})}
      variation={variation}
      visual={
      <>
        <div className={classes.fullHeight}>
          <div className={classes.wrapper}>
            <img
              data-test-id="activityImage"
              onError={() => setImageSource(fallbackSource)}
              className={
                clsx(
                  classes.root,
                  classes.nonSearchPageImageSize,
                  {
                    [classes.fallbackImageObjectFit]: !activity.image,
                  },
                  {
                    [classes.nonFallbackImageObjectFit]: activity.image,
                    [classes.cardVariationImage]: variation == Variation.Vertical,
                  },
                )
              }
              src={imageSource || fallbackSource}
            />
          </div>
        </div>
        {activity.category && isMobile && (<CategoryLabel category={activity.category} imageLabel/>)}
      </>
      }
      content={
        <Grid container direction="column" className={classes.infoContainer} data-test-id={`activity_${activity.id}`}>
          <Grid item container>
            <Header
              activity={activity}
              displayActivityLinks={displayActivityLinks}
              linkToComment={linkToComment}
              onActivityClick={onActivityClick}
              showActions={showActions}
              showGroupsTitles={showGroupsTitles}
            />
          </Grid>
          {!isMobile && (
            <Grid item>
              <Divider className={classes.divider}/>
            </Grid>
          )}
          {displayActivityLinks &&
            <Grid container className={classes.externalButtons}>
              {activity.locationName &&
                <TripObjectLink
                  url={activity.googlePlaceUrl}
                  linkText='View on map'
                  dataTestId='activity-location-link'
                />
              }
              {activity.link &&
                <TripObjectLink
                  url={activity.link}
                  linkText='View details'
                  dataTestId='activity-link'
                  eventTracker={activityTracker}
                />
              }
            </Grid>
          }
          {displaySelectButton &&
            <Grid item className={clsx({[classes.selectButtonWrapper]: !isMobile})}>
              <SelectButton
                onClick={() => onActivityClick()}
                data-test-id='select-button'
                isSelected={isSelectedActivity}
              />
            </Grid>
          }
          {showActions &&
            <Grid item container className={classes.commentsContainer}>
              <Grid item mb={1} container className={classes.voteBox}>
                {showSentiments &&
                  <Grid item xs={8} container data-test-id='activity-voting-summary-dialog'>
                    <ActivityVotingSummaryDialog
                      isPlanner={isPlanner}
                      votes={activity.activityVotes}
                      votesCount={activity.votesCount}
                      activityId={activity.id}
                    />
                  </Grid>
                }
                {displayCommentButton &&
                  <Grid item xs={showSentiments ? 4 : 12} className={classes.commentBox} container>
                    <CommentsLink
                      commentsLink={activity.commentsPath}
                      commentsCount={activity.commentsCount}
                      segment={{
                        tripId: activity.tripId,
                        tripObjectId: activity.id,
                        tripObjectType: 'activities',
                      }}
                    />
                  </Grid>
                }
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.verticalActions}
              >
                {showSentiments && (
                  <Grid item xs={displayCommentButton ? 9 : 12} container justifyContent="center" data-test-id="SentimentButtons">
                    <SentimentButtons
                      activityId={activity.id}
                      currentUserSentiment={activity.currentUserVote}
                      disabled={false}
                      sentimentParamName="value"
                      sentimentSettingPath={tripActivityVotesPath(activity.tripId, activity.id)}
                      sentiments={activityRankLabels}
                      testId="tripActivityButtons"
                    />
                  </Grid>
                )}
                {displayCommentButton &&
                  <Grid item xs={showSentiments ? 3 : 12} className={classes.commentButtonContainer}>
                    <Button
                      className={classes.commentButton}
                      onClick={() => setActivityComment()}
                      segmentProps={{title: 'Comment Field Initiated', tripObjectId: activity.id}}
                      data-test-id='commentButton'
                    >
                      <CommentIcon/>
                    </Button>
                  </Grid>
                }
              </Grid>
            </Grid>
          }
        </Grid>
      }
    />
  </>;
};

export default Activity;
