import React, { useContext } from 'react';
import {
  Grid,
  Link,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  FormsContext,
  hocRailsAction,
  RoutesContext,
} from '../../Document';
import SiteHeader from "../../shared/SiteHeader";
import SiteFooter from "../../shared/SiteFooter";
import RailsForm from "../../shared/RailsForm";
import Button from "../../buttons/Button";

export const useStyles = makeStyles(theme => ({
  messageBox: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '580px',
    minHeight: '50%',
    padding: theme.spacing(2),
    '& h1': {
      color: theme.palette.text.primary,
      fontSize: theme.spacing(4),
      fontWeight: 600,
    },
    '& h1, & p': {
      marginBottom: theme.spacing(2),
    },
  },
  innerBox: {
    padding: theme.spacing(4),
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: 'linear-gradient(161.28deg, #C3C6FE 1.03%, #FFF6E6 96.33%)',
  },
}));

type HomePageProps = {
  alreadyHaveAccountWithDifferentProviderError: boolean,
  authFailureMessage: string,
  isUserLoggingOut: boolean,
  isUserRequestingAccountDeletion: boolean,
  isUserAccountDeleted: boolean,
  provider: Provider,
};

type PageContent<Props = {}> = {
  title: string,
  body: React.FC<Props>,
  heroImage?: React.ReactNode,
};

type PageNameToContent = {
  alreadyHaveAccountWithDifferentProvider: PageContent<AlreadyHaveAccountWithDifferentProviderProps>,
  authFailure: PageContent<AuthFailureProps>,
  justLoggedOut: PageContent,
  justRequestedAccountDeletion: PageContent,
  justDeletedAccount: PageContent,
  default: PageContent,
};

enum Provider {
  Apple = 'apple',
  Email = 'email',
  Facebook = 'facebook',
  Google = 'google',
};

type AlreadyHaveAccountWithDifferentProviderProps = {
  provider: Provider,
};

type AuthFailureProps = {
  message: string,
};

const accountWithDifferentProviderBody = (provider) => {
  const { triggerLogIn } = useContext(FormsContext);
  const capitalizedProvider = provider.charAt(0).toUpperCase() + provider.slice(1);
  const assistanceText = <> If you continue to run into issues, reach out to <Link href="mailto:help@troupe.com">help@troupe.com</Link> for more assistance.</>;

  switch(provider) {
    case Provider.Email:
      return <>
        <Typography>
          Looks like you created your account using our email and password option.
        </Typography>
        <Typography>
          You’ll need to sign in using the email and password fields at the bottom of
          the <Link href="#" onClick={triggerLogIn}>login page.</Link>
          {assistanceText}
        </Typography>
      </>
    default:
      return <>
        <Typography>
          Looks like you created your account using {capitalizedProvider}'s social sign on option.
        </Typography>
        <Typography>
          Try logging in via the “Continue with {capitalizedProvider}” button at the top of
          the <Link href="#" onClick={triggerLogIn}>login page.</Link>
          {assistanceText}
        </Typography>
      </>
    };
};

const steps = [
  {
    imageSrc: "/assets/party-popper.png",
    copy: <><b>RSVP</b> to your trip</>,
  },
  {
    imageSrc: "/assets/question-mark.png",
    copy: <><b>Poll</b> your group</>,
  },
  {
    imageSrc: "/assets/thumbs-up.png",
    copy: <><b>Vote</b> on your favorites</>,
  },
  {
    imageSrc: "/assets/airplane.png",
    copy: <><b>Build</b> your itinerary</>,
  },
];

const stepRow = (imageSrc: string, copy: React.ReactNode, index: number) => (
  <ListItem disablePadding key={`list-item-${index}`}>
    <ListItemIcon sx={{ minWidth: '40px', marginLeft: '20px' }}>
      <img src={imageSrc} width="20px" height="20px" />
    </ListItemIcon>
    <ListItemText>
      {copy}
    </ListItemText>
  </ListItem>
);

const pageNameToContent: PageNameToContent = {
  alreadyHaveAccountWithDifferentProvider: {
    title: 'Howdy, traveler 👋',
    body: ({ provider }) => accountWithDifferentProviderBody(provider),
  },
  justLoggedOut: {
    title: 'Au revoir! 🚀',
    body: () => (
      <>
        <Typography>
          You’ve been successfully logged out of your account. Log back in when you’re ready to continue planning your next adventure.
        </Typography>
      </>
    ),
  },
  justRequestedAccountDeletion: {
    title: 'We’re sorry to see you go',
    body: () => (
      <>
        <Typography>
          Your request has been sent to our engineering team.
        </Typography>
        <Typography>
          If you did so in error or have any additional questions, please reach out to <Link href="mailto:help@troupe.com">help@troupe.com</Link>.
        </Typography>
      </>
    ),
  },
  justDeletedAccount: {
    title: 'We’re sorry to see you go',
    body: () => (
      <>
        <Typography>
          Your Troupe account has been successfully deleted, along with any personal data associated with your account.
        </Typography>
        <Typography>
          If you did so in error or have any additional questions, please reach out to <Link href="mailto:help@troupe.com">help@troupe.com</Link>.
        </Typography>
      </>
    ),
  },
  authFailure: {
    title: 'Uh oh! 💥',
    body: ({message}) => (
      <>
        <Typography>
          Something went wrong with your login attempt. Please try again.
        </Typography>
        <Typography>
          { `Message: ${message}` }
        </Typography>
      </>
    ),
  },
  default: {
    title: 'Welcome to Troupe',
    heroImage: <img alt='logo_icon' src='/assets/logo_icon.svg' width='86px' height='86px' />,
    body: () => (
      <>
        <Typography>
          Log in or create an account to get started.
        </Typography>
        <Grid container justifyContent="center" mb={3}>
          <Grid item>
            <List>
              {steps.map((step, index) => stepRow(step.imageSrc, step.copy, index))}
            </List>
          </Grid>
        </Grid>
        <LogInButtonForm />
      </>
    ),
  },
}

const LogInButtonForm = () => {
  const { authPath } = useContext(RoutesContext);
  return <RailsForm action={authPath} withChangeDetection={false}>
    <Button
      type="submit"
      variant="primary"
      fullWidth
    >
      Get started
    </Button>
  </RailsForm>;
};

const propToPageNameMappings = {
  alreadyHaveAccountWithDifferentProviderError: 'alreadyHaveAccountWithDifferentProvider',
  isUserLoggingOut: 'justLoggedOut',
  isUserRequestingAccountDeletion: 'justRequestedAccountDeletion',
  isUserAccountDeleted: 'justDeletedAccount',
  authFailureMessage: 'authFailure',
}

const getPageName = (props: HomePageProps): keyof PageNameToContent => {
  for (const propName in propToPageNameMappings) {
    if (props[propName]) return propToPageNameMappings[propName];
  }

  return 'default';
}

const HomePage = (props: HomePageProps) => {
  const {
    authFailureMessage,
    provider,
  } = props;
  const classes = useStyles();

  const pageContent = pageNameToContent[getPageName(props)]
  const pageContentProps = { message: authFailureMessage, provider }

  return (
    <div className={classes.root}>
      <SiteHeader/>
      <Grid container direction="row" alignItems="center" className={classes.messageBox}>
        <Grid container direction="column" alignItems="center" className={classes.innerBox}>
          {
            pageContent.heroImage &&
            <Grid item mb={2}>
              {pageContent.heroImage}
            </Grid>
          }
          <Grid item textAlign="center">
            <Typography variant="h1">
              {pageContent.title}
            </Typography>
            <pageContent.body {...pageContentProps}/>
          </Grid>
        </Grid>
      </Grid>
      <SiteFooter />
    </div>
  );
};

export default hocRailsAction(HomePage);
