import React from 'react';
import { makeStyles } from "@mui/styles";
import { ButtonBase } from '@mui/material';
import Slider from "react-slick";
import {useStayAffiliateClickEventRef} from "../../../../../services/segmentEvents/useStayAffiliateClickEvent";

const useStyles = makeStyles((theme) => ({
  image: {
    display: 'block',
    width: "100%",
    height: "100%",
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
    objectFit: 'cover',
    objectPosition: 'center',
    background: '#b4b9bc',
    maxHeight: 200,
  },
  slider: {
    '& .slick-list': {
      borderRadius: '8px',
    },
    '& .slick-arrow': {
      zIndex: 2,
      background: 'rgba(0,0,0,0.4)',
      padding: '32px 16px',
      '&:before': {
        lineHeight: 0,
        margin: '-10px',
      },
    },
    '& .slick-prev': {
      left: 0,
    },
    '& .slick-next': {
      right: 0,
    },
  },
}));

type ImagesCarouselProps = {
  images: string[],
  linkHref: string,
};

const ImagesCarousel = ({
  linkHref,
  images,
}: ImagesCarouselProps) => {
  const classes = useStyles();
  const segmentRef = useStayAffiliateClickEventRef({
    listingUrl: linkHref,
    targetClicked: 'image',
  });

  const renderImage = image => {
    const imageTag = (
      <img
        data-test-id="tripStayCarouselPhoto"
        loading="lazy"
        src={image}
        key={image}
        className={classes.image}
      />
    );

    return (
      <ButtonBase
        disableTouchRipple
        onClick={() => window.open(linkHref, '_blank')}
        key={`a-${image}`}
        ref={segmentRef}
        sx={{ width: '100%' }}
      >
        {imageTag}
      </ButtonBase>
    )
  };

  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    className: classes.slider,
  };

  return (
    <Slider {...settings}>
      {images.map(image => (
        renderImage(image)
      ))}
    </Slider>
  );
};

export default ImagesCarousel;
