import React from "react";
import Activity from "../../trips/microsite/ActivitiesPage/Activity";
import {
  ActivityType,
} from "../../../shared/TripObjectTypes";

type ActivityPageProps = {
  activity: ActivityType,
  isPlanner: boolean,
};

const ActivityPage = ({
  activity,
  isPlanner,
}: ActivityPageProps) => {

  return (
    <Activity
      activity={activity}
      showActions
      showGroupsTitles
      isPlanner={isPlanner}
    />
  );
};

export default ActivityPage;
