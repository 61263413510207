import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PersonAddSharpIcon from '@mui/icons-material/PersonAdd';
import { DefaultTheme, makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { track as trackAnalyticsEvent } from '../../../services/analytics';
import InviteModal from "../InviteModal";
import Button from "../../buttons/Button";

export type ButtonVariant = 'primary' | 'tertiary';

type ShareTripConfirmationButtonProps = {
  collapseOnMobile?: boolean,
  disabled: boolean,
  inviteBtnVariant?: ButtonVariant,
  inviterId?: string,
  isPlanner?: boolean,
  tripId: string,
  tripGid: string,
  tripName: string,
  segmentMethod: string,
  shareLinkSource: string,
};

const useStyles = makeStyles<DefaultTheme, { isFullWidthBtn: boolean, variant: ButtonVariant }>((theme) => ({
  root: {
    height: '100%',
    maxHeight: '48px',
  },
  rootMobile: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '100%',
    [theme.breakpoints.down(400)]: {
      margin: '0px 2px',
    },
  },
  btnWidth: {
    width: ({ isFullWidthBtn }) => isFullWidthBtn ? '164px' : '52px',
    [theme.breakpoints.down(370)]: {
      width: ({ isFullWidthBtn }) => isFullWidthBtn ? '134px' : '32px',
      padding: ({ isFullWidthBtn }) => isFullWidthBtn ? '12px 24px' : '12px 20px',
    },
  },
  hidden: {
    display: 'none',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    color: ({variant}) => variant === 'tertiary' ? 'black' : 'white',
  },
}));

const ShareTripButton = ({
  collapseOnMobile = true,
  disabled,
  inviteBtnVariant,
  inviterId,
  isPlanner = false,
  tripId,
  tripGid,
  tripName,
  segmentMethod,
  shareLinkSource,
}: ShareTripConfirmationButtonProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const hideLabel = collapseOnMobile && isMobile;
  const [dialogOpened, setDialogOpened] = useState(false);

  const isFullWidthBtnForTraveler = (!isPlanner && !isMobile) || !collapseOnMobile;
  const isFullWidthBtn = isPlanner || isFullWidthBtnForTraveler;
  const classes = useStyles({variant: inviteBtnVariant, isFullWidthBtn: isFullWidthBtn});

  useEffect(() => {
    if(dialogOpened) {
      trackAnalyticsEvent('Invite Modal Opened', {
        category: 'Invites',
        method: segmentMethod,
        tripId: tripId,
      });
    }
  }, [dialogOpened])

  return (
    <>
      <Button
        onClick={() => setDialogOpened(true)}
        data-test-id="shareTripButton"
        disabled={disabled}
        variant={inviteBtnVariant}
        className={clsx(
          classes.root,
          {[classes.rootMobile]: isMobile},
          classes.btnWidth,
        )}>
        <PersonAddSharpIcon className={clsx({[classes.icon]: !disabled})} />
        <span className={clsx(
          classes.label,
          {[classes.hidden]: hideLabel},
        )}>
          Invite
        </span>
      </Button>
      {dialogOpened && (
        <InviteModal
          inviterId={inviterId}
          onClose={() => setDialogOpened(false)}
          tripId={tripId}
          tripGid={tripGid}
          tripName={tripName}
          shareLinkSource={shareLinkSource}
        />
      )}
    </>
  );
};

export default ShareTripButton;
