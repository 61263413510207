import React, { useState } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
  ButtonBase,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Radio,
  RadioProps,
} from '@mui/material';
import DeclineInviteMenuItem from "./RespondToInviteButton/DeclineInviteMenuItem";
import DropdownButton from "../../../../shared/DropdownButton";
import RailsForm from "../../../../shared/RailsForm";
import ConfirmationDialog from "../../../../shared/ConfirmationDialog";
import * as routes from '../../../../../routes';

type RespondToInviteDropdownButtonProps = {
  currentStatus: string,
  disabled?: boolean,
  travelerId: string,
  tripId: string,
  tripInviteId?: string,
};

const {
  tripTravelerPath,
  declineTripInvitePath,
} = routes;

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
  },
  listItem: {
    width: '100% !important',
  },
  listItemIcon: {
    color: 'currentColor',
    minWidth: 'auto',
    paddingRight: theme.spacing(2),
  },
  listDivider: {
    boxShadow: "0 1px 0 0 rgba(0, 0, 0, 0.2)",
    border: "1px solid white",
    padding: 0,
    marginBottom: theme.spacing(1),
  },
}));

const CustomRadio = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props: RadioProps) => <Radio color="default" {...props} />);

const RespondToInviteDropdownButton = ({
  currentStatus,
  disabled,
  travelerId,
  tripId,
  tripInviteId,
}: RespondToInviteDropdownButtonProps) => {
  const classes = useStyles();
  const formAction = tripTravelerPath(tripId, travelerId);
  const formMethod = 'PATCH';

  const labels = {
    'going': {
      icon: "Yes",
      label: "Going",
    },
    'interested': {
      icon: "Maybe",
      label: "Interested",
    },
    'cannot_go': {
      icon: "No",
      label: "Not going",
    },
  };

  const options = [
    {
      checked: currentStatus === 'going',
      dataTestId: "going-option",
      status: 'going',
      shouldDisplay: true,
    },
    {
      checked: currentStatus === 'interested',
      dataTestId: "interested-option",
      status: 'interested',
      shouldDisplay: true,
    },
    {
      checked: currentStatus === 'cannot_go',
      dataTestId: "cannot-go-option",
      status: 'cannot_go',
      shouldDisplay: currentStatus,
    },
  ];

  const buttonLabel = labels[currentStatus].label;
  const buttonIcon = labels[currentStatus].icon;

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDeclineClick = () => setDialogOpen(true);
  const closeDeclineConfirmationDialog = () => setDialogOpen(false);

  return (
    <>
      {tripInviteId && (
        <ConfirmationDialog
          actionMethod="PATCH"
          actionPath={declineTripInvitePath(tripId, tripInviteId)}
          actionText="Yes, decline this invite"
          fullWidthButtons
          isOpen={dialogOpen}
          onClose={closeDeclineConfirmationDialog}
          title="Decline this invite?"
          message="If you change your mind later on, the planner will have to re-invite you to this trip."
        />
      )}
      <DropdownButton
        disabled={disabled}
        icon={buttonIcon}
        label={buttonLabel}
        menuList={
          <MenuList
            className={classes.listItem}>
            {options.map((option, index) => (
              option.shouldDisplay && (
                <RailsForm
                  action={formAction}
                  key={index}
                  method={formMethod}
                  withChangeDetection={false}
                >
                  <input type="hidden" name="status" value={option.status} />
                  <input type="hidden" name="redirect_to_path" value={window.location.pathname} />
                  <ButtonBase
                    className={classes.button}
                    data-test-id={option.dataTestId}
                    disableRipple
                    type="submit"
                  >
                    <MenuItem
                      className={classes.listItem}
                      tabIndex={2}
                      key={index}
                    >
                      <ListItemIcon key={index} className={classes.listItemIcon}>
                        {<img src={`/assets/illustrations/${labels[option.status].icon}.svg`}/>}
                      </ListItemIcon>
                      <ListItemText primary={labels[option.status].label} />
                      <CustomRadio checked={option.checked} disableRipple disabled />
                    </MenuItem>
                  </ButtonBase>
                </RailsForm>
              )
            ))}
            {tripInviteId && (
              <>
                <hr className={classes.listDivider} />
                <DeclineInviteMenuItem onClick={handleDeclineClick} />
              </>
            )}
          </MenuList>
        }
      />
    </>
  );
};

export default RespondToInviteDropdownButton;
