import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

export type RecentlySearchedDestination = {
  name: string,
  imageUrl: string,
  dates: string,
}

type RecentlySearchedCardProps = {
  recentlySearchedDestination: RecentlySearchedDestination,
};

export type RecentlySearchedProps = {
  recentlySearchedDestinations?: RecentlySearchedDestination[],
}

const useStyles = makeStyles((theme) => ({
  recentlySearched: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  image: {
    width: '78px',
    height: '65px',
    borderRadius: theme.spacing(1),
  },
  destinationCard: {
    flexDirection: 'row',
    background: '#F5F9FF',
    border: '1px solid #D4D4D4',
    borderRadius: theme.spacing(1),
    gap: theme.spacing(2),
    alignItems: 'center',
    padding: theme.spacing(1),
    width: '275px',
    flexShrink: 0,
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: theme.spacing(2),
    overflowX: 'auto',
  },
  destinationName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '155px',
  },
}));

const RecentlySearchedCard : React.FC<RecentlySearchedCardProps> = ({
  recentlySearchedDestination,
}) => {
  const classes = useStyles();
  return(
    <Grid item container className={classes.destinationCard}>
      <img
        className={classes.image}
        src={recentlySearchedDestination.imageUrl}
      />
      <div>
        <Typography className={classes.destinationName}>
          {recentlySearchedDestination.name}
        </Typography>
        <Typography>
          {recentlySearchedDestination.dates}
        </Typography>
      </div>
    </Grid>
  )
}

const RecentlySearched : React.FC<RecentlySearchedProps> = ({
  recentlySearchedDestinations = [],
}) => {
  const classes = useStyles();
  if(!(recentlySearchedDestinations.length > 0)) return null;
  return(
    <Grid item container className={classes.recentlySearched}>
      <Grid item>
        <Typography variant="h2">
          Recently searched
        </Typography>
      </Grid>
      <Grid container className={classes.cardsContainer}>
        {
          recentlySearchedDestinations.map((recentlySearchedDestination) => (
            <RecentlySearchedCard
              recentlySearchedDestination={recentlySearchedDestination}
              key={`${recentlySearchedDestination.name}-${recentlySearchedDestination.dates}`}
            />
          ))
        }
      </Grid>
    </Grid>
  )
}

export default RecentlySearched;
