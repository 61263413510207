import React from "react";
import {
  Badge,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import Avatar from "../avatars/Avatar";

type UserAvatarProps = {
  avatarSrc: string,
  avatarClassName?: string,
  badgeContent?: object,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#FFFFFF',
    fontSize: 12,
  },
}))(Avatar);

const UserAvatar = ({
  avatarSrc,
  avatarClassName,
  badgeContent,
}: UserAvatarProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={badgeContent && <SmallAvatar>{badgeContent}</SmallAvatar>}
      >
        <Avatar src={avatarSrc} className={avatarClassName}/>
      </Badge>
    </div>
  );
};

export default UserAvatar;
