import React from 'react';
import { makeStyles } from "@mui/styles";
import { Alert, AlertColor } from "@mui/material";
import { Snackbar, SnackbarOrigin } from '@mui/material';
import clsx from 'clsx';
import {
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from "./SnackbarNotificationIcons";

export type SnackbarNotificationProp = {
  autoHideDuration?: number,
  message: string,
  onClose?: () => void,
  open: boolean,
  severity?: AlertColor,
};

const useStyles = makeStyles(theme => ({
  message: {
    fontFamily: 'Poppins',
    fontSize: 16,
    lineHeight: '24px',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  root: {
    '@supports (margin-top: env(safe-area-inset-top))': {
      marginTop: 'env(safe-area-inset-top)',
    },
    backgroundColor: '#323232',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  standardInfo: {
    '& .MuiAlert-icon': {
      marginRight: 0,
    },
  },
}));

const SnackbarNotification = ({
  autoHideDuration,
  message,
  onClose,
  open,
  severity,
}: SnackbarNotificationProp) => {
  const classes = useStyles();
  const isPublicSearch = window.location.pathname === '/search'

  const renderIcon = {
    error: <ErrorIcon />,
    info: <InfoIcon />,
    success: <SuccessIcon />,
    warning: <WarningIcon />,
  };

  return (
    <Snackbar
      data-test-id='snackbar'
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      open={open}
    >
    <Alert
      classes={{
        message: classes.message,
        root: classes.root,
        standardInfo: classes.standardInfo,
      }}
      data-test-id="snackbar-message"
      severity={severity}
      icon={!isPublicSearch && renderIcon[severity]}
    >
      {message}
    </Alert>
  </Snackbar>
  );
};

SnackbarNotification.defaultProps = {
  autoHideDuration: 6000,
  severity: 'success',
};

export default SnackbarNotification;
