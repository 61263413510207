import React from "react";
import {
  Grid,
  TextField,
  useMediaQuery,
  Typography,
  Box,
} from "@mui/material";
import { DefaultTheme, makeStyles, useTheme } from "@mui/styles";
import Button from "../../../buttons/Button";
import { DialogBottomOnMobile } from "../../../shared/Dialog";

const useStyles = makeStyles<DefaultTheme, { disabled: boolean, aiTripSearch: boolean }>((theme) => ({
  searchBox: {
    backgroundColor: ({ aiTripSearch }) => aiTripSearch ? '#F2F2F2' : "",
    borderRadius: 10,
    margin: 'auto',
    padding: theme.spacing(2, 4),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  promptPreview: {
    overflow: 'hidden',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    width: '100%',
  },
  promptPreviewText: {
    padding: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#5C7993',
  },
  input: {
    backgroundColor: '#FFFFFF',
  },
  poweredByText: {
    fontSize: 12,
    lineHeight: '24px',
  },
  icon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  dialogBackground: {
    backgroundColor: '#F2F2F2',
  },
  askTroupie: {
    fontWeight: 600,
    fontSize: 20,
  },
}));

const AiSearchPromptDialog: React.FC<{
  prompt: string,
  onSubmit: (prompt: string) => void,
  disabled?: boolean,
  aiTripSearch: boolean,
}> = ({
  prompt: initialPrompt,
  onSubmit,
  disabled = false,
  aiTripSearch,
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({ disabled, aiTripSearch });
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const inputRef = React.useRef(null);
  const focusInput = () => inputRef.current?.querySelector('textarea')?.focus();
  React.useEffect(() => {
    if (!isMobile) focusInput();
  }, [])

  const [prompt, setPrompt] = React.useState(initialPrompt ?? '');
  React.useEffect(() => {
    setPrompt(initialPrompt);
  }, [initialPrompt]);

  const handleSubmit = React.useCallback((event) => {
    event.preventDefault();
    onSubmit(prompt);
    setOpen(false);
  }, [prompt, onSubmit]);

  const handleEnterKey = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && !!prompt.trim()) handleSubmit(event)
  }

  const renderDialogContent = () => (
    <Grid container direction="column" spacing={2} data-test-id="dialogSearchBox">
      <Grid item>
        <TextField
          autoFocus
          className={classes.input}
          data-test-id="prompt-input"
          fullWidth
          multiline
          placeholder="Tell me what you’re looking for"
          type="search"
          onKeyDown={handleEnterKey}
          onChange={e => setPrompt(e.target.value)}
          value={prompt ?? ''}
          disabled={disabled}
          ref={inputRef}
          inputProps={{ maxLength: 500 }}
        />
      </Grid>
      <Grid item container direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Button
            data-test-id='searchButton'
            onClick={(prompt) => handleSubmit(prompt)}
            disabled={disabled || !prompt.trim()}
          >
            <img src="/assets/stars.svg" className={classes.icon} />
            Show me {!isMobile && 'results'}
          </Button>
        </Grid>
        <Grid item>
          <Typography align={isMobile ? "left" : "right"} className={classes.poweredByText}>Powered by ChatGPT API</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderPreviewText = () => (
    <Grid container className={classes.searchBox} data-test-id="searchBox">
      {aiTripSearch && (
        <Grid item>
          <Typography className={classes.askTroupie}>Ask Troupie</Typography>
        </Grid>
      )}
      <Grid item xs={!isMobile} className={classes.promptPreview}>
        <Typography
          onClick={() => setOpen(true)}
          className={classes.promptPreviewText}
          data-test-id='prompt-preview'
        >
          {prompt}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      {renderPreviewText()}
      <DialogBottomOnMobile
        fullWidth
        title="Ask Troupie"
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setPrompt(initialPrompt);
        }}
        maxWidth="md"
        message={renderDialogContent()}
        backgroundClass={classes.dialogBackground}
      />
    </Box>
  )
}

export default AiSearchPromptDialog;
