import React from "react";
import pluralize from 'pluralize';
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { NumericFormat } from 'react-number-format';
import {
  Grid,
  Typography,
} from "@mui/material";
import AvailabilityIcon from "./AvailabilityIcon";
import { DateOptionWithAvailabilityType } from "../../../../../../../shared/TripObjectTypes";

type DateOptionPriceProps = {
  dateOption: DateOptionWithAvailabilityType,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  date: {
    fontWeight: 600,
  },
  price: {
    fontWeight: 600,
    fontSize: theme.spacing(2),
  },
  priceContainer: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
    },
  },
  rightItem: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: theme.spacing(3),
    }
  },
  root: {
    width: '100%',
  },
  text: {
    fontSize: theme.spacing(2),
  },
  unavailable: {
    color: '#c0c0c0',
  },
}));

const DateFormatted = ({ dateOption }: DateOptionPriceProps) => {
  const classes = useStyles();
  const isCurrentYear = (year) => new Date().getFullYear().toString() === year;

  return (
    <Grid item>
      <AvailabilityIcon dateOption={dateOption} />
      <span className={classes.date}>
        {dateOption.startDate}{!isCurrentYear(dateOption.startDateYear) && (`, ${dateOption.startDateYear}`)}
        <span> — </span>
        {dateOption.endDate}{!isCurrentYear(dateOption.endDateYear) && (`, ${dateOption.endDateYear}`)}
      </span>
      {` / ${dateOption.numNights} ${pluralize('night', dateOption.numNights)}`}
    </Grid>
  );
};

const DateOptionPrice = ({ dateOption }: DateOptionPriceProps) => {
  const classes = useStyles();
  const price = dateOption.price || dateOption.estimatedPrice;
  const isUnavailable = dateOption.available === false;
  const isEstimated = !dateOption.price && dateOption.estimatedPrice;

  return (
    <Grid
      className={clsx(
        classes.root,
        {
          [classes.unavailable]: isUnavailable,
        },
      )}
      container
      data-test-id="dateOptionPricing"
      item
      justifyContent="space-between"
      spacing={1}
    >
      <Grid item direction='row' xs={12} sm={8} className={classes.text}>
        <DateFormatted dateOption={dateOption}/>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.priceContainer}>
        {isUnavailable && !price ? (
          <Typography className={clsx(classes.text, classes.rightItem)}>
            Unavailable
          </Typography>
        ) : (
          price ? (
            <Grid container>
              <Grid item className={clsx(classes.price, classes.rightItem)}>
                <NumericFormat
                  decimalScale={2}
                  displayType='text'
                  fixedDecimalScale={price % 1 !== 0}
                  prefix='$'
                  thousandSeparator={true}
                  value={price}
                />
              {isEstimated && '*'}
              </Grid>
            </Grid>
          ) : (
            <Typography className={clsx(classes.text, classes.rightItem)}>
              (no price)
            </Typography>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default DateOptionPrice;
