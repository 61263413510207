import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography, Theme } from "@mui/material";
import clsx from "clsx";
import { isMobile } from "react-device-detect";

type StyleProps = {
  isMobile: boolean;
}

type FirstStepTripFormType = {
  setOptionalData: (data: any) => void,
  optionalData: any,
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  cardButton: {
    justifyContent: "center",
    display: "flex",
    border: "1px dashed #545DFC",
    "&:hover": {
      backgroundColor: ({ isMobile }) => !isMobile ? '#EEEFFF' : null,
      border: ({ isMobile }) => !isMobile ? "1px solid #545DFC" : null,
    },
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '500px',
    minHeight: '60px',
    padding: theme.spacing(2),
  },
  cardLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  image: {
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: '300px',
    },
    [theme.breakpoints.up("md")]: {
      height: '200px',
    },
  },
  selectedVertical: {
    backgroundColor: '#EEEFFF',
    border: "1px solid #545DFC",
  },
}));

export const UpperImage = () => {
  const classes = useStyles({isMobile});
  return(
    <img src="/assets/trips.svg" alt="plane" className={classes.image} />
  )
};

type VerticalBoxType = {
  vertical: string,
  onClick: () => void,
  selectedVerticals: {}
};

const VerticalBox = ({
  vertical,
  onClick,
  selectedVerticals = {},
}: VerticalBoxType) => {
  const classes = useStyles({isMobile});
  return(
    <Box
      className={clsx(classes.cardButton, selectedVerticals[vertical.toLowerCase()] && classes.selectedVertical)}
      my={1}
      onClick={onClick}
      data-test-id={`vertical-box-${vertical.toLowerCase()}`}
    >
      <Typography variant="h3" textAlign='center' className={classes.cardLabel}>
        {vertical}
      </Typography>
    </Box>
  )
}

const FirstStepTripForm : React.FC<FirstStepTripFormType> = ({
  setOptionalData,
  optionalData,
}) => {
  const onButtonClick = (vertical) => {
    if(vertical === 'nothing')
      return setOptionalData(prevState => ({
        ...prevState,
        verticals: {
          dates: false,
          destinations: false,
          nothing: !prevState.verticals.nothing,
          stays: false,
        },
        disabledSubmit: prevState.verticals.nothing,
      }));
    setOptionalData(prevState => ({
      ...prevState,
      verticals: {
        ...prevState.verticals,
        nothing: false,
        [vertical]: !prevState.verticals[vertical],
      },
      disabledSubmit: !Object.values({...prevState.verticals, [vertical]: !prevState.verticals[vertical]}).some(v => v),
    }));
  };
  const options = ['Dates', 'Destinations', 'Stays', 'Nothing'];

  React.useEffect(() => {
    setOptionalData(prevState => ({
      ...prevState,
      disabledSubmit: !Object.values(prevState.verticals).some(v => v),
    }))
  }, []);

  return (
    <Grid container direction="column" alignContent="space-around">
      {options.map((option) => (
        <VerticalBox
          key={option}
          vertical={option}
          onClick={() => onButtonClick(option.toLowerCase())}
          selectedVerticals={optionalData.verticals}
        />
      ))}
    </Grid>
  )
};

export default FirstStepTripForm;
