import React from "react";
import { Grid, Typography } from "@mui/material";
import { hocRailsAction } from "../../../Document";
import Errors from "../../../shared/Errors";
import ModalForm from "../../../shared/ModalForm";
import { ItineraryType, ItineraryDateType, ItineraryEventType } from "../../../shared/TripObjectTypes";
import {
  tripItineraryDatePath,
  tripItineraryDatesPath,
  tripStayEventPath,
} from '../../../../routes';
import ItineraryStayEvent from "./ItineraryDatePermapage/ItineraryStayEvent";
import { default as DateRangePicker } from "../../../shared/CustomDateRangePicker";
import InputWithCharacterCounter from '../../../shared/InputWithCharacterCounter';

type EditItineraryStayEventFormProps = {
  errors?: string[],
  itinerary: ItineraryType,
  itineraryDate: ItineraryDateType,
  itineraryEvent: ItineraryEventType,
  source: 'permapage' | 'scheduleList',
};

const EditItineraryStayEventForm: React.FC<EditItineraryStayEventFormProps> = ({
  errors = [],
  itinerary,
  itineraryDate,
  itineraryEvent,
  source,
}) => {
  const { tripId } = itineraryEvent;
  const returnPath = source === 'scheduleList' ? tripItineraryDatesPath(tripId) : tripItineraryDatePath(tripId, itineraryDate.id);

  return (
    <ModalForm
      closePath={returnPath}
      formMethod="PATCH"
      formActionPath={`${tripStayEventPath(tripId, itineraryEvent.id)}?itinerary_date_id=${itineraryDate.id}&source=${source}`}
      subtitle="Let travelers know when you’ll be staying at each accommodation."
      title="Edit stay details"
      useDefaultSaveButton
    >
      <input type="hidden" name="itinerary_date_id" value={itineraryDate.id} />
      <Grid container direction="column" spacing={3}>
        {errors.length > 0 && (
          <Grid item>
            <Errors errors={errors} />
          </Grid>
        )}

        <Grid item sx={{width: '100%'}}>
          <ItineraryStayEvent stayEvent={itineraryEvent} isInModal page={'editForm'}/>
        </Grid>

        <Grid item>
          <Typography variant="h3" pb={1}>Dates</Typography>
          <DateRangePicker
            autoComplete="off"
            defaultEndValue={itineraryEvent.endDate}
            defaultStartValue={itineraryEvent.startDate}
            helperText={false}
            nameEnd="itinerary_event[end_date]"
            nameStart="itinerary_event[start_date]"
            allowedMinDate={itinerary.startDate}
            allowedMaxDate={itinerary.endDate}
            shouldDisableDateEnabled
            disablePast={false}
          />
        </Grid>

        <Grid item>
          <Typography variant="h3" pb={1}>Note (optional)</Typography>
          <InputWithCharacterCounter
            defaultValue={itineraryEvent.notes}
            name="itinerary_event[notes]"
            label="Note"
            fullWidth
            withBorder
            noLabel
            characterLimit={250}
          />
        </Grid>
      </Grid>
    </ModalForm>
  )
};

export default hocRailsAction(EditItineraryStayEventForm);
