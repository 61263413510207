import React, { useContext } from "react";
import { Skeleton, Stack } from "@mui/material";
import SectionHeader from "./SectionHeader";
import VerticalStatusCard from "./VerticalStatusCard";
import {
  TripSiteContext,
} from "../../../../Document";
import {
  tripActivitiesPath,
  tripDatesPath,
  tripDestinationsPath,
  tripStaysPath,
  addBySmartSearchTripStaysPath,
} from "../../../../../routes";
import { useQuery } from "@apollo/client";
import { TRIP_QUERY } from "../../../../../graphql/queries/trips";
import * as Sentry from "@sentry/browser";
import SnackbarNotification, { SnackbarNotificationProp } from '../../../../shared/SnackbarNotification';

const Snackbar = (props: Partial<SnackbarNotificationProp>) => (
  <SnackbarNotification
    autoHideDuration={props.autoHideDuration ?? null}
    message={props.message}
    open={!!props.message}
    severity={props.severity}
  />
);

const TripStatusSkeletonCards = () => {
  return (
    <Stack alignItems="center" sx={{ marginTop: '0px !important' }}>
      <Skeleton width={500} height={100} />
      <Skeleton width={500} height={100} />
      <Skeleton width={500} height={100} />
    </Stack>
  );
};

const TripStatus = () => {
  const { tripGid, tripId } = useContext(TripSiteContext);
  const staysSuggestingPath = addBySmartSearchTripStaysPath(tripId);

  const { data, loading, error } = useQuery(
    TRIP_QUERY, {
      variables: {
        tripId: tripGid,
      },
    },
  )

  if (error) {
    Sentry.captureException(error)
    return <>
      <Snackbar
        message='Sorry, something went wrong loading trip statuses. Please try again.'
        severity='error'
      />
      <TripStatusSkeletonCards />
    </>
  }

  const {
    datesState,
    destinationsState,
    staysState,
    chosenDateOption,
    chosenDestination,
    chosenStay,
    uuid,
  } = data?.node || {};

  const finalizedDates = `${chosenDateOption?.startDate} - ${chosenDateOption?.endDate}, ${chosenDateOption?.endDateYear}`;

  return (
    <Stack alignItems="center" spacing={2}>
      <SectionHeader
        heading="Trip Status"
        subheading="Collaborate with your group and keep track of decisions for this trip."
      />
      {loading ?
        <TripStatusSkeletonCards />
      : <>
        <VerticalStatusCard
          data-test-id='verticalStatusCardDates'
          finalizedDetails={finalizedDates}
          vertical="Dates"
          status={datesState}
          path={tripDatesPath(uuid)}
        />
        <VerticalStatusCard
          data-test-id='verticalStatusCardDestination'
          finalizedDetails={chosenDestination?.name}
          vertical="Destinations"
          status={destinationsState}
          path={tripDestinationsPath(uuid)}
        />
        <VerticalStatusCard
          data-test-id='verticalStatusCardStay'
          finalizedDetails={chosenStay?.name}
          vertical="Stays"
          status={staysState}
          path={staysState === 'suggesting' ? staysSuggestingPath : tripStaysPath(tripId)}
        />
        <VerticalStatusCard
          vertical="Activities"
          status="suggesting"
          path={tripActivitiesPath(uuid)}
        />
      </>}
    </Stack>
  );
};

export default TripStatus;
