import React, { useCallback } from "react";
import {
  Box,
  ButtonBase,
  Divider,
  Grid,
} from "@mui/material";
import withLayout from "./withLayout";
import SectionHeader from "./shared/SectionHeader";
import NoteRow from './NotesListPage/NoteRow';
import Button from "../../../buttons/Button";
import responsiveText from "../../../../services/responsiveText";
import {
  trackLink as trackLinkAnalyticsEvent,
  track as trackAnalyticsEvent,
} from "../../../../services/analytics";
import { TripType } from "../../../shared/TripTypes";
import { newTripNotePath, tripNotesPath } from "../../../../routes";
import CurrentSortingAndCount from "./shared/CurrentSortingAndCount";
import FilteringAndSortingBar from './shared/FilteringAndSortingBar'

export type TripNote = {
  body: string | null,
  id: string,
  title: string,
  tripId: string,
  updatedAt: number,
};

type SortingType = {
  label: string,
  sortBy: string,
};

type NotesListPageProps = {
  currentSorting: string,
  currentSortingLabel: string,
  currentUserId: string,
  isUserLoggedIn: boolean,
  notes: TripNote[],
  sortings?: SortingType[],
  trip: TripType,
};

const NotesListPage = ({
  currentSorting,
  currentSortingLabel,
  currentUserId,
  isUserLoggedIn,
  notes,
  sortings,
  trip,
}: NotesListPageProps) => {
  const addButtonCopy = responsiveText('+ Add', '+ Add note');

  const addNewNote = useCallback((element) => {
    if (element !== null) {
      trackLinkAnalyticsEvent(
        element,
        'New Note Initiated',
        {
          authorId: currentUserId,
          planner: trip.isPlanner,
          tripId: trip.id,
        },
      );
    }
  }, []);

  const onFilteringAndSortingBarSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);

    trackAnalyticsEvent(
      'Sort/Filter Saved',
      {
        vertical: 'Notes',
        userId: currentUserId,
        tripId: trip.id,
        sortType: formProps['trip_note_filter[sort_by]'],
      },
    );

    event.target.submit();
  };

  return (
    <Box pt={3} pb={4}>
      <SectionHeader
        title="Shared notes"
        note="Collaborate with fellow travelers in real-time to plan out your upcoming trip."
      />
      <Divider />
      <Grid pt={2} container justifyContent="space-between">
        <Grid item>
          <FilteringAndSortingBar
            vertical="Notes"
            currentSorting={currentSorting}
            disabled={notes.length === 0}
            formPath={tripNotesPath(trip.id)}
            sortByRadioGroupName="trip_note_filter[sort_by]"
            sortings={sortings}
            onSubmit={onFilteringAndSortingBarSubmit}
          />
        </Grid>
        <Grid item>
          <Button
            data-test-id='add-note-button'
            disabled={!isUserLoggedIn || !trip.isTraveler}
            href={newTripNotePath(trip.id)}
            ref={(element) => addNewNote(element)}
            >
              {addButtonCopy}
          </Button>
        </Grid>
      </Grid>
      {notes.length > 0 && (
        <Box pt={3}>
          <CurrentSortingAndCount
            count={notes.length}
            sorting={currentSortingLabel}
            type="note"
          />
        </Box>
      )}
      <Box pt={3}>
        <Grid container direction="column" spacing={3}>
          {notes.map((note, index) => (
            <Grid item key={index}>
              <NoteRow note={note} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default withLayout(NotesListPage);
