import React from "react";
import {
  Grid,
} from "@mui/material";
import { ItineraryDateType } from "../../../../shared/TripObjectTypes";
import ItineraryDate from "./ItineraryDate";

type ItineraryDatesListProps = {
  itineraryDates: ItineraryDateType[],
};

const ItineraryDatesList = ({
  itineraryDates,
}: ItineraryDatesListProps) => {
  return <>
    <Grid container direction="column" spacing={2} mb={3}>
      {itineraryDates.map((itineraryDate, index) => (
        <Grid item key={`itinerary-date-${index}`} sx={{ width: '100%' }}>
          <ItineraryDate itineraryDate={itineraryDate} />
        </Grid>
      ))}
    </Grid>
  </>;
};

export default ItineraryDatesList;
