import React from "react";
import clsx from "clsx";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";
import { makeStyles } from "@mui/styles";

export type IconButtonProps = ButtonBaseProps & {
  children: React.ReactNode,
  className?: string,
  disabled?: boolean,
  href?: string,
  onClick?: (event: any) => void,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    padding: theme.spacing(1),
    boxShadow: '0 2px 4px 0 rgba(60, 64, 67, 0.3)',
    backgroundColor: 'white',
    color: '#505050',
    background: 'white',
    borderColor: 'white',
    width: theme.spacing(5),
    borderRadius: theme.spacing(8),
    '&:hover': {
      color: '#353535',
      boxShadow: '0 2px 4px 0 rgba(60, 64, 67, 0.3)',
    },
    '&:focus': {
      color: '#69737a',
      boxShadow: '0 0 0 1px #80b4fb',
    },
    '&:active': {
      color: '#272727',
    },
  },
  disabled: {
    color: [['#d4d5d6'], '!important'],
  },
}));

const IconButton = React.forwardRef(({
  children,
  className,
  disabled,
  ...buttonProps
}: IconButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={clsx(classes.root, className, { [classes.disabled]: disabled })}
      disabled={disabled}
      ref={ref}
      {...buttonProps}
    >
      {children}
    </ButtonBase>
  );
});

export default IconButton;
