import React, { useState } from "react";
import ActivityMarker from "./ActivityMarker";
import MapView from "../../../../../shared/MapView/MapView";
import { updatePosition } from "../../../../../../services/mapPosition";
import { ActivityType } from "../../../../../shared/TripObjectTypes";

type ActivitiesMapViewProps = {
  activities: ActivityType[],
};

const ActivitiesMapView = ({
  activities,
}: ActivitiesMapViewProps) => {
  const markers = activities?.filter((activity) => activity.longitude && activity.latitude) || [];
  const [activeMarker, setActiveMarker] = useState(null);

  const handleMarkerClick = (index, lat, lng, map) => {
    setActiveMarker(index);
    updatePosition(lat, lng, map);
    map.setOptions({ disableDoubleClickZoom: true });
  };

  const handleClickOut = (map) => {
    setActiveMarker(null);
    map.setOptions({ disableDoubleClickZoom: false });
  };

  return <>
    {activities.length > 0 && (
      <MapView
        markers={markers}
        renderMarker={(map, activity) =>
          <ActivityMarker
            activity={activity}
            active={activeMarker === activity.id}
            lat={activity.latitude}
            lng={activity.longitude}
            key={activity.id}
            onMarkerClick={() => handleMarkerClick(activity.id, activity.latitude, activity.longitude, map)}
            onMarkerClickOut={() => handleClickOut(map)}
          />
        }
      />
    )}
  </>;
};

export default ActivitiesMapView;
