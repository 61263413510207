import React from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withMicrositeLayout from "./withLayout";
import FinalizedPageContentHeader from "./shared/FinalizedPageContentHeader";
import ChosenTripObjectHeader from "./shared/ChosenTripObjectHeader";
import Destination from "./DestinationPage/Destination";
import Button from "../../../buttons/Button";
import * as routes from "../../../../routes";
import pluralize from 'pluralize';
import DateOption from "../../../shared/DateOption";
import getMostPopularDateOptions from "../../../../services/getMostPopularDateOptions";
import VotingResultsButton from "./FinalizedTripObjectPage/VotingResultsButton";
import Tooltip from "../../../shared/Tooltip";
import { DateOptionType } from "../../../shared/TripObjectTypes";
import { TravelerProps } from "../../../shared/TravelersVotingBox";
import Stay from "./HousingPage/Stay";
import { ChoosingTypeTripObject } from "../shared/TripObjectChoosingModalForm";
import { usePhasingContext } from "../../../../services/phasing";
import ActivityAd, { AdContent } from "../../../shared/ActivityAd";

const {
  destroyConfirmationTripChosenDateOptionPath,
  destroyConfirmationTripChosenDestinationPath,
  destroyConfirmationTripChosenStayPath,
  tripChosenDateOptionNotePath,
  tripChosenDestinationNotePath,
  tripChosenStayNotePath,
  tripDestinationPath,
  tripStayPath,
} = routes;

type FinalizedTripObjectProps = {
  activityAd?: AdContent,
  chosenTripObject: any,
  hasChosenTripObject: boolean,
  hideVotes: boolean,
  trip: { id: string, dateOptions: DateOptionType[], travelers: TravelerProps[], isPlanner: boolean },
  typeTripObject: ChoosingTypeTripObject,
};

const useStyles = makeStyles((theme) => ({
  chooseFinalDifferentTripObjectButton: {
    marginBottom: theme.spacing(3),
  },
  chosenDateOptionActions :{
    marginTop: theme.spacing(2),
  },
  chosenTripObject: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  headerSeparator: {
    border: 'none',
    borderTop: '1px solid #c1c1c1',
    marginBottom: theme.spacing(3),
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 600,
    color: '#272727',
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 24,
    },
  },
  tripObject: {
    marginTop: theme.spacing(2),
  },
  votingResultsButton: {
    marginBottom: theme.spacing(4),
  },
}));

const FinalizedTripObjectPage = ({
  activityAd,
  chosenTripObject,
  hideVotes = false,
  hasChosenTripObject,
  typeTripObject,
  trip: { dateOptions, travelers, ...trip },
}: FinalizedTripObjectProps) => {
  const classes = useStyles();
  const mostPopularOptionsIds = ( typeTripObject === 'dates' && getMostPopularDateOptions(dateOptions).map(option => option.id));
  const { staysState } = usePhasingContext();
  const showSerpButtons = !['finalized', 'skipped_voting_finalized'].includes(staysState) && typeTripObject !== 'stay';
  const destinationSubheading = showSerpButtons ? "Your group’s destination has been set! Now find the perfect place to stay."
    : "Woo hoo! Your group’s destination has been set."

  let destroyConfirmationTripChosenObjectPath;
  let note = 'Add any additional details you want to share with the group on this page.'
  let subheading;
  let tripChosenObjectNotePath;

  switch(typeTripObject) {
    case 'stay':
      destroyConfirmationTripChosenObjectPath = destroyConfirmationTripChosenStayPath
      subheading = "Woo hoo! Your group’s stay has been set."
      tripChosenObjectNotePath = tripChosenStayNotePath
      break;
    case 'dates':
      destroyConfirmationTripChosenObjectPath = destroyConfirmationTripChosenDateOptionPath
      subheading = "Woo hoo! Your group’s dates have been set."
      tripChosenObjectNotePath = tripChosenDateOptionNotePath
      break;
    case 'destination':
      destroyConfirmationTripChosenObjectPath = destroyConfirmationTripChosenDestinationPath
      subheading = destinationSubheading
      tripChosenObjectNotePath = tripChosenDestinationNotePath
      if (showSerpButtons) {note = "Troupe can help you find a vacation rental by searching Stays."}
      break;
  };

  return (
    <>
      <FinalizedPageContentHeader
        heading={<span className={classes.title}>{pluralize(typeTripObject, 2)}</span> }
        subheading={subheading}
        note={note}
        showButtons={showSerpButtons}
        tripObjectType={typeTripObject}
      />
      <hr className={classes.headerSeparator} />
      {activityAd && (
        <ActivityAd
          content={activityAd}
          small
          vertical="Destinations"
        />
      )}
      <ChosenTripObjectHeader
        avatarUrl={chosenTripObject.chooserAvatarUrl}
        chosenByName={chosenTripObject.chooserName}
        chosenAt={chosenTripObject.timestamp}
        isPlanner={trip.isPlanner}
        note={chosenTripObject.note}
        objectPath={tripChosenObjectNotePath(trip.id) }
        hasChosenTripObject={hasChosenTripObject}
      />
      {typeTripObject === 'stay' &&
        <Stay
          stay={chosenTripObject}
          className={classes.chosenTripObject}
          imgPath={tripStayPath(trip.id, chosenTripObject.uuid)}
          isClickable
          linkNameToProvider
          hideVotes={hideVotes}
          travelers={travelers}
          displayBookButton
        />
      }
      {typeTripObject === 'destination' &&
        <Destination
          className={classes.chosenTripObject}
          destination={chosenTripObject}
          hideVotes={hideVotes}
          isClickable
          key={chosenTripObject.id}
          linkToComment={tripDestinationPath(trip.id, chosenTripObject.id)}
          travelers={travelers}
        />
      }
      {typeTripObject === 'dates' &&
        <DateOption
          endDate={chosenTripObject.endDate}
          endDateWeekday={chosenTripObject.endDateWeekday}
          endDateYear={chosenTripObject.endDateYear}
          isMostPopular={mostPopularOptionsIds.includes(chosenTripObject.id)}
          key={chosenTripObject.id}
          linkPath={chosenTripObject.tripObjectPath}
          numNights={chosenTripObject.numNights}
          showLink
          startDate={chosenTripObject.startDate}
          startDateWeekday={chosenTripObject.startDateWeekday}
          startDateYear={chosenTripObject.startDateYear}
          travelersVotes={chosenTripObject.travelersVotes}
        />
      }
      <Grid container justifyContent="center" alignItems="center" className={classes.chosenDateOptionActions}>
        <Tooltip title={trip.isPlanner ? '' : `Only planners can change ${pluralize(typeTripObject, 2)}`}>
          <Grid item xs={11} md={11}>
            <Button
              className={classes.chooseFinalDifferentTripObjectButton}
              data-test-id='choose-different'
              fullWidth
              href={destroyConfirmationTripChosenObjectPath(trip.id)}
              variant="secondary"
              disabled={!trip.isPlanner}
            >
              Change {typeTripObject}
            </Button>
          </Grid>
        </Tooltip>
        {!hideVotes &&
          <Grid data-test-id='voting-results-button' item xs={11} md={11}>
            {typeTripObject === 'dates' && (
              <VotingResultsButton
                className={classes.votingResultsButton}
                dateOptions={dateOptions}
              />
            )}
          </Grid>
        }
      </Grid>
    </>
  );
};

export default withMicrositeLayout(FinalizedTripObjectPage);
