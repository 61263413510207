import React from "react";
import {
  ButtonBase,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import truncateString from "../../../../helpers/truncateString";
import clsx from "clsx";
import { ListingsContext, SuggestedDestinationType } from "../StaysSearch";
import { shouldDisplayRankingLabel } from "../../../../services/displayRankingLabel";
import { useTrackSERPEvent } from "../../../../services/segmentEvents/useSERPEvents";
import { isCurrentlyInPublicSearch } from "../../../../services/windowLocation";

type TopSuggestedDestinationTileListProps = {
  topSuggestedDestinations: Array<SuggestedDestinationType>,
}

type SuggestedDestinationTileProps = {
  suggestedDestination: SuggestedDestinationType,
  position: number,
}

const useStyles = makeStyles((theme) => ({
  tile: {
    height: '200px !important',
    [theme.breakpoints.down("sm")]: {
      width: '150px',
    },
    [theme.breakpoints.up("sm")]: {
      width: '338px',
    },
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tileImage: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))',
  },
  tileText: {
    position: 'absolute',
    fontSize: 18,
    lineHeight: '24px',
    zIndex: 3,
    color: "white",
  },
  tileFilter: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
  },
  tileGrid: {
    width: '100%',
  },
  choiceLabel: {
    background: '#6f42c1',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '30px',
    padding: '4px',
    color: '#fff',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    fontSize: theme.spacing(2),
    fontWeight: 600,
    zIndex: 2,
  },
  topChoice: {
    background: '#1ebe95',
  },
  crown: {
    paddingRight: theme.spacing(1),
  },
}));

type RankingLabelProps = {
  ranking: number,
};

const RankingLabel = ({
  ranking,
}: RankingLabelProps ) => {
  const classes = useStyles();
  const isGroupFavorite = ranking === 1
  let labelText;

  switch (ranking) {
    case 1:
      labelText = "Group favorite"
      break;
    case 2:
      labelText = "2nd place"
      break;
    case 3:
      labelText = "3rd place"
      break
    default:
      labelText = null;
  }

  return (
    <Typography data-test-id="choiceLabel">
      <span className={clsx(
        classes.choiceLabel,
        { [classes.topChoice]: ranking === 1 },
      )}>
        { isGroupFavorite && (<img className={classes.crown} src="/assets/illustrations/Crown.svg"/>)}
        { labelText }
      </span>
    </Typography>
  );
}

const SuggestedDestinationTile = ({
  suggestedDestination,
  position,
}: SuggestedDestinationTileProps) => {
  const classes = useStyles();
  const fallbackSource = "/assets/airplane-fallback.jpg";
  const { onDestinationTileClick } = React.useContext(ListingsContext);
  const { trackSERPEvent } = useTrackSERPEvent();

  const onClick = () => {
    trackSERPEvent('SERP Destination Tile Clicked', {
      destinationTile: suggestedDestination.name,
      tilePosition: position,
    });

    onDestinationTileClick(suggestedDestination);
  };

  return (
    <Grid className={classes.tile} onClick={onClick} xs={'auto'} data-test-id={`destination-tile-${position}`}>
      <ButtonBase sx={{height: '100%', width: '100%'}}>
        <img src={suggestedDestination.image || fallbackSource} className={classes.tileImage} data-test-id={"tile-image"}/>
        <div className={classes.tileFilter}/>
        {shouldDisplayRankingLabel(suggestedDestination.ranking) && <RankingLabel ranking={suggestedDestination.ranking}/>}
        <Typography variant="h2" className={classes.tileText}>{truncateString(suggestedDestination.name, 30)}</Typography>
      </ButtonBase>
    </Grid>
  )
}

const TopSuggestedDestinationsTileList = ({
  topSuggestedDestinations,
}: TopSuggestedDestinationTileListProps) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      {isCurrentlyInPublicSearch() ? (
        <>
          <Grid item>
            <Typography variant="h2">Need more inspiration?</Typography>
          </Grid>
          <Grid item>
            <Typography>Check out other destinations Troupe users have explored.</Typography>
          </Grid>
        </>
      ) : (
        <Grid item mt={2}>
          <Typography variant="h2">Suggested destinations</Typography>
        </Grid>
      )}
      <Grid container item className={classes.tileGrid}>
        {topSuggestedDestinations.map((destination, position) => (
          <SuggestedDestinationTile suggestedDestination={destination} position={position} key={destination.id} />
        ))}
      </Grid>
    </Grid>
  )
}

export default TopSuggestedDestinationsTileList;
