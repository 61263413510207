import React, { useContext } from "react";
import withLayout from "./withLayout";
import { ItineraryDateType } from "../../../shared/TripObjectTypes";
import ItineraryMainContent from "./ItineraryDatePermapage/ItineraryMainContent";
import ActionsMenu from "../../../shared/ActionsMenu";
import SectionHeader from "./shared/SectionHeader";
import { SegmentContext } from "../../../Document";
import { track as trackAnalyticsEvent } from "../../../../services/analytics";
import * as routes from "../../../../routes";
import { Grid } from "@mui/material";
import { setScrollBarHorizontalPosition } from "../../../../services/setScrollBarHorizontalPosition";
import ItineraryDatesChipBar, {ITINERARY_DATE_CHIP_WIDTH} from "./ItineraryDatePermapage/ItineraryDatesChipBar";
import { makeStyles } from "@mui/styles";
import SideBarPage from "../../../shared/SideBarPage";

const {
  tripItineraryPath,
  editTripItineraryPath,
} = routes;

const useStyles = makeStyles(() => ({
  filterContainer: {
    overflowX: 'auto',
    flexWrap: 'nowrap',
  },
}));

type ItineraryScheduleListProps = {
  itineraryDates: ItineraryDateType[],
};

const ItineraryScheduleList = ({
  itineraryDates,
}: ItineraryScheduleListProps) => {
  const { currentUserId, tripId, isPlanner } = useContext(SegmentContext);
  const classes = useStyles();

  const trackDeletionInitiated = () => {
    trackAnalyticsEvent('Itinerary Deletion Initiated',
      {
        tripId: tripId,
        userId: currentUserId,
        planner: isPlanner,
      },
    )
  };

  const trackEditClick = () => {
    trackAnalyticsEvent('Itinerary Edit Dates Initiated',
      {
        tripId: tripId,
        planner: isPlanner,
      },
    )
  };

  const goToEditPage = () => {
    trackEditClick();
    window.location.href = editTripItineraryPath(tripId);
  };

  return (
    <Grid>
      <SideBarPage mainContent={
        <>
          <SectionHeader
            title='Itinerary'
            note='Build an itinerary to start organizing your destinations, stays, and activities by day.'
            rightElement={isPlanner && (
              <ActionsMenu
                deletePath={tripItineraryPath(tripId)}
                dense={false}
                resourceName="itinerary"
                onDeleteClick={trackDeletionInitiated}
                onEditClick={goToEditPage}
              />
            )}
          />
            <Grid
              container
              pb={1}
              className={classes.filterContainer}
              ref={(ele) => {setScrollBarHorizontalPosition(ele, ITINERARY_DATE_CHIP_WIDTH, 0)}}
            >
              <ItineraryDatesChipBar
                itineraryDates={itineraryDates}
                selectedDate={'All'}
              />
            </Grid>
            {itineraryDates.map((itineraryDate) => (
              <ItineraryMainContent
                itineraryDate={itineraryDate}
                itineraryDates={itineraryDates}
                availableActivitiesCount={itineraryDate.availableActivitiesCount}
                scheduleListView
                page={'scheduleList'}
                key={itineraryDate.id}
              />
            ))}
          </>
        }
      />
    </Grid>
  );
}

export default withLayout(ItineraryScheduleList, { fluid: true });
