import React, { useContext } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Dialog from "../shared/Dialog";
import EmailInviteForm from "../shared/EmailInviteForm";
import { TripSiteContext } from '../Document';

type EmailInviteModalProps = {
  title: string,
  tripId: string,
  resendEmail: string[],
  onModalClose: () => void,
  onResend?: (hasCustomMessage: boolean) => void,
};

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    textAlign: 'center',
    flexDirection: 'row',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      minWidth: 480,
    },
  },
}));

const EmailInviteModal = ({
  title,
  tripId,
  resendEmail,
  onResend,
  onModalClose,
}: EmailInviteModalProps) => {
  const classes = useStyles();
  const { tripGid } = useContext(TripSiteContext);

  return <>
    <Dialog
      fullWidth
      data-test-id="inviteModal"
      isOpen
      message={
        <>
          <Box className={classes.titleWrapper} mb={1}>
            <Typography className={classes.title}>
              {title}
            </Typography>
          </Box>
          <EmailInviteForm
            tripId={tripId}
            tripGid={tripGid}
            resendEmail={resendEmail}
            onResend={onResend}
          />
       </>
      }
      onClose={onModalClose}
    />
  </>
};

export default EmailInviteModal;
