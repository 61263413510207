import React from "react";
import {
  Box,
  Grid,
  Divider,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import StaysList from "./StaysList";
import AddStayButton from "./StaysSection/AddStayButton";
import HousingFilteringAndSortingBar, {
  DateOption,
  StaySorting,
} from "./StaysSection/HousingFilteringAndSortingBar";
import { Sorting } from "../shared/FilteringAndSortingBar";
import PhasingStepper from "../shared/PhasingStepper";
import SectionHeader from "../shared/SectionHeader";
import CurrentSortingAndCount from "../shared/CurrentSortingAndCount";
import NoSuggestionsYet from "../../../../shared/NoSuggestionsYet";
import LastUpdateFilterLink from "../../../../shared/LastUpdateFilterLink";
import Tooltip from "../../../../shared/Tooltip";
import {
  StayType,
} from "../../../../shared/TripObjectTypes";
import { usePhasingContext } from "../../../../../services/phasing";
import { TravelerProps } from "../../../../shared/TravelersVotingBox";
import { GlobalContext, TripSiteContext } from "../../../../Document";
import ActionCallout from "../SuggestedDatesPage/ActionCallout";
import SearchStaysButton from "./StaysSection/SearchStaysButton";

type StaysSectionProps = {
  stays: StayType[],
  staySortings: Sorting<StaySorting>[],
  currentStayFilters: {
    dateOptionIds: string[],
  },
  currentStaySorting: StaySorting,
  dateOptions: DateOption[],
  isPlanner: boolean,
  lastEventUpdatedAt: number,
  onSortAndFilterChange: (_: {
    sortBy?: StaySorting,
    dateOptionIds?: string[],
  }) => void,
  tripGid: string,
  tripId: string,
  travelers: TravelerProps[],
};

const useStyles: () => any = makeStyles((theme): {} => ({
  filters: {
    marginBottom: theme.spacing(2),
  },
}));

const StaysSection = ({
  staySortings,
  stays,
  currentStayFilters,
  currentStaySorting,
  dateOptions,
  isPlanner,
  lastEventUpdatedAt,
  onSortAndFilterChange,
  tripId,
  travelers,
}: StaysSectionProps) => {
  const classes = useStyles();
  const { currentUserId } = React.useContext(TripSiteContext);
  const { areStaysInSuggestingPhase, areStaysInVotingPhase, staysState } = usePhasingContext();
  const activeFilterCount = currentStayFilters?.dateOptionIds.length ?? 0
  const areFiltersActive = activeFilterCount > 0
  const noSuggestions = !stays.length && !areFiltersActive;
  const noFilteredSuggestions = !stays.length && areFiltersActive;
  const sortingName = staySortings.find((sorting) => sorting.sortBy === currentStaySorting).label;
  const addStayText = useMediaQuery(useTheme().breakpoints.down(470)) ? '+ Add' : '+ Add stay'
  const note = areStaysInVotingPhase() ? 'Vote for your favorite options from the list below.' : `Suggest new stays using the “${addStayText}” button below.`;
  const isSuggestingEnabled = areStaysInSuggestingPhase();

  const renderActionCallout = () => {
    const thisTraveler = travelers.find((traveler) => traveler.userId === currentUserId);
    const showActionCallout = areStaysInVotingPhase() && thisTraveler?.stayMissingVotesCount > 0;
    return showActionCallout && (
      <>
        <Box my={2}><Divider /></Box>
        <ActionCallout
          currentUserAvatarUrl={thisTraveler.avatarUrl}
          missingVotesCount={thisTraveler.stayMissingVotesCount}
        />
      </>
    )
  };

  return (
    <Box data-test-id="tripStayListings">
      <SectionHeader
        title="Stays"
        note={note}
        rightElement={ lastEventUpdatedAt && (
          <LastUpdateFilterLink
            date={lastEventUpdatedAt}
            tripId={tripId}
            tripObjectType="Stay"
          />
        )}
      />
      <PhasingStepper currentPhase={staysState} tripObjectType='stays' />
      { renderActionCallout() }
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.filters}
        rowSpacing={2}
      >
        {isSuggestingEnabled && (
          <Grid item xs={12} display="flex" alignItems="center" >
            <SearchStaysButton tripId={tripId} />
          </Grid>
        )}
        <Grid item>
          <HousingFilteringAndSortingBar
            currentFiltersCount={activeFilterCount}
            currentFilters={currentStayFilters}
            currentSorting={currentStaySorting}
            dateOptions={dateOptions}
            disabled={noSuggestions}
            sortings={staySortings}
            onSortAndFilterChange={onSortAndFilterChange}
          />
        </Grid>
        <Tooltip title={isSuggestingEnabled ? '' : 'Suggestions can’t be made once voting has begun'}>
          <Grid item>
            <AddStayButton tripId={tripId} disabled={!isSuggestingEnabled} labelText={addStayText}/>
          </Grid>
        </Tooltip>
      </Grid>
      {!noSuggestions && (
        <Box pt={1} pb={3}>
          <CurrentSortingAndCount
            count={stays.length}
            sorting={sortingName}
            type="stay"
          />
        </Box>
      )}
      <StaysList
        isPlanner={isPlanner}
        stays={stays}
        travelers={travelers}
      />
      {noSuggestions && (
        <Grid>
          <NoSuggestionsYet
            heading="Next steps"
            listItems={['Add stays', 'Get your group to vote', 'Finalize and book your stay!']}
            illustration='camper'
          />
        </Grid>
      )}
      {noFilteredSuggestions && (
        <Grid>
          <NoSuggestionsYet
            illustration='desert'
            subheading='Hmm…looks like no stays match your filters. Let’s try again.'
          />
        </Grid>
      )}
    </Box>
  );
};

export default StaysSection;
