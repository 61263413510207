type AvatarSize = {
  borderHeight: number,
  borderWidth: number,
  height: number,
  width: number,
};

type AvatarSizes = {
  xs: AvatarSize,
  sm: AvatarSize,
  md: AvatarSize,
  lg: AvatarSize,
  xl: AvatarSize,
};

const avatarSizes: AvatarSizes = {
  xs: {
    borderHeight: 32,
    borderWidth: 32,
    height: 28,
    width: 28,
  },
  sm: {
    borderHeight: 36,
    borderWidth: 36,
    width: 32,
    height: 32,
  },
  md: {
    borderHeight: 48,
    borderWidth: 48,
    width: 40,
    height: 40,
  },
  lg: {
    borderHeight: 56,
    borderWidth: 56,
    width: 48,
    height: 48,
  },
  xl: {
    borderHeight: 76,
    borderWidth: 76,
    width: 68,
    height: 68,
  },
};

export default avatarSizes;
