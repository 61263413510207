import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import BroadcastBody from "./BroadcastBody";
import TimeStamp from '../../../../shared/TimeStamp';
import UserAvatar from '../../../../shared/UserAvatar';
import { TravelerProps } from '../../../../shared/TravelersVotingBox';
import ActionsMenu from '../../../../shared/ActionsMenu';
import { SyncComment } from "../../../../shared/TripObjectTypes";
import NewTripUpdatesDot from "../TripUpdatesPage/NewTripUpdatesDot";
import { tripBroadcastPath } from '../../../../../routes';

export type Broadcast = {
  author: TravelerProps,
  gid?: string,
  commentsCount?: number,
  commentsPath?: string,
  id: string,
  message: string,
  mostRecentComment?: SyncComment,
  timeStamp: number,
  isCurrentUserAuthor: boolean,
  isUnviewed: boolean,
  type: 'Broadcast',
};

type BroadcastViewProps = {
  broadcast: Broadcast,
  displayCommentButton?: boolean,
  setComment?: () => void,
  showLink?: boolean,
  tripId: string,
};

const useStyles = makeStyles((theme) => ({
  authorName: {
    fontSize: 14,
  },
  bold: {
    fontWeight: 600,
  },
  descriptionContainer: {
    position: 'relative',
  },
  timestamp: {
    fontSize: 14,
  },
}));

const BroadcastView = ({
  broadcast,
  displayCommentButton = false,
  tripId,
  setComment,
  showLink,
}: BroadcastViewProps) => {
  const classes = useStyles();
  const { author, timeStamp, id, isUnviewed } = broadcast;

  return (
    <Grid item data-test-id="broadcast-item">
      <Grid item container spacing={1}>
        <Grid item>
          <UserAvatar avatarSrc={author.avatarUrl} />
        </Grid>
        <Grid item flex={1}>
          <Grid container direction="column">
            <TimeStamp className={classes.timestamp} date={timeStamp} />
            <Typography
              data-test-id="author-name"
              className={classes.authorName}
            >
              <span className={classes.bold}>{author.name}</span> sent a broadcast
            </Typography>
          </Grid>
        </Grid>
        {broadcast.isCurrentUserAuthor &&
          <Grid item alignSelf="center" data-test-id='display-actions-menu'>
            <ActionsMenu deletePath={tripBroadcastPath(tripId, id)} resourceName="broadcast"/>
          </Grid>
        }
        {isUnviewed &&
          <Grid item xs={2} md={2} className={classes.descriptionContainer}>
            <NewTripUpdatesDot />
          </Grid>
        }
      </Grid>
      <BroadcastBody
        broadcast={broadcast}
        displayCommentButton={displayCommentButton}
        setComment={setComment}
        showLink={showLink}
        tripId={tripId}
      />
    </Grid>
  );
};

BroadcastView.defaultProps = {
  showLink: true,
};

export default BroadcastView;
