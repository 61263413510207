import React from 'react';
import { Box } from "@mui/material";
import HorizontalStepper from "../../../../shared/HorizontalStepper";
import { TypeTripObjectType } from "../../../../shared/TripObjectTypes";

export type PhasingStepperProps = {
  currentPhase: 'suggesting' | 'voting' | 'finalized',
  tripObjectType: TypeTripObjectType,
};

const PhasingStepper = ({
  currentPhase,
  tripObjectType,
}: PhasingStepperProps) => {
  const steps = [
    `Suggest ${tripObjectType}`,
    'Vote',
    `Finalize ${tripObjectType}`,
  ];
  const activeStep = currentPhase == 'suggesting' ? 0 : 1;

  return (
    <Box paddingTop={2} paddingBottom={3} data-test-id="phasingStepper">
      <HorizontalStepper activeStep={activeStep} steps={steps} />
    </Box>
  );
};

export default PhasingStepper;
