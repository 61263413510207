import React from "react";
import pluralize from 'pluralize';
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Skeleton from "@mui/material/Skeleton";
import IconButton from "../../../buttons/IconButton";
import { ExternalListingProps } from "../../../shared/TripObjectTypes";
import { StayType } from "../../../shared/TripObjectTypes";
import ExternalListingCard from "./ExternalListingCard";
import { RenderCtaButtonType, SuggestedDestinationType } from "../StaysSearch";
import { ListingsMapContext } from "../../../../services/RenderWithOrWithoutSidebar";
import InventoryBanner from "./InventoryBanner";
import LoadingState from "./LoadingState";
import {aiSearchType, CurrentAppliedFilters} from "./Filters";
import AiResults from "./AiResults";
import ListingsEmptyState from "./ListingsEmptyState";
import ListingsErrorState from "./ListingsErrorState";
import AiFilters from "./AiFilters";

export type Pagination = {
  count: number,
  from: number,
  items: number,
  next: boolean,
  next_url: string,
  page: number,
  prev: boolean,
  prev_url: string,
  to: number,
};

export type ModelOutputs = {
  filters?: CurrentAppliedFilters | {},
  alternatives?: [{name: string, latitude: number, longitude: number}],
  requestId?: string | null,
  handleLocationChange: (location: {latitude: number, longitude: number}) => void,
  searchMethod: string,
}

type ListingsBoxProps = {
  fetchListings: (fetchArgs?: { pageUrl: string }) => void,
  isLoading: boolean,
  initialLoad: boolean,
  listings: ExternalListingProps[] | StayType[],
  locationName?: string,
  pagination: Pagination,
  error?: boolean,
  topSuggestedDestinations?: SuggestedDestinationType[],
  renderCtaButton: RenderCtaButtonType,
  availability?: number,
  aiSearch?: boolean,
  aiSearchType?: aiSearchType,
  modelOutputs?: ModelOutputs,
  currentAppliedFilters?: CurrentAppliedFilters,
  triggerFilterDialog: (value: boolean) => void,
  onFilter: (value: object) => void,
  prompt?: string,
};

const useStyles = makeStyles((theme) => ({
  costDisclaimer: {
    fontSize: 14,
    color: '#555E64',
  },
  listingsContainer: {
    marginTop: theme.spacing(),
  },
  pageListingsDescription: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const Loading = (aiSearch = false) => {
  const texts = [
    'Sending your request to Troupie...',
    'Selecting your filters...',
    'Analyzing your options...',
    'Preparing your results...',
    'Adding the finishing touches...',
  ];


  return aiSearch ? (
    <LoadingState texts={texts} interval={1.5} />
  ) : (
    Array.from({length: 3}, (element, index) => (
      <Grid item key={index} data-test-id={"Loading"}>
        <Skeleton variant="rectangular" height={140} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
      </Grid>
    ))
  );
};

const ListingsBox = ({
  fetchListings,
  isLoading,
  initialLoad,
  listings,
  locationName,
  pagination,
  error = false,
  topSuggestedDestinations,
  renderCtaButton,
  availability,
  aiSearch = false,
  aiSearchType,
  modelOutputs,
  currentAppliedFilters,
  triggerFilterDialog,
  onFilter,
  prompt,
}: ListingsBoxProps) => {
  const classes = useStyles();
  const { setHoveredListing } = React.useContext(ListingsMapContext);

  const showResults = listings && listings.length > 0;
  const showEmptyState = initialLoad && !locationName;

  const pageListingsDescription = () => {
    const isSinglePage = !pagination.next && !pagination.prev;
    const totalListingsCountLabel = pagination.count >= 300 ? '300+' : `${pagination.count}`

    if (isSinglePage) {
      return <span>{pagination.count}</span>;
    } else {
      return (
        <span>
          <strong>{`${pagination.from} - ${pagination.to}`} {` of ${totalListingsCountLabel} `}</strong>
        </span>
      );
    }
  };

  const hideRationaleAndAlternatives = () => {
    const NORMAL_SEARCH_METHODS = [
      'chipDismissed',
      'filtersButton',
      'searchCriteria',
      'inventoryButton',
      'troupieAlternativeSuggestions',
      'alternativeTile',
    ]

    return NORMAL_SEARCH_METHODS.includes(modelOutputs?.searchMethod);
  }

  const renderNavigation = () => (
    <Grid item container justifyContent="center" alignItems="center">
      <IconButton
        data-test-id="previousPage"
        disabled={!pagination.prev}
        onClick={() => fetchListings({pageUrl: pagination.prev_url})}
      >
        <NavigateBeforeIcon/>
      </IconButton>
      <Typography
        className={classes.pageListingsDescription}
        data-test-id="pageListingsDescription"
      >
        {pageListingsDescription()}
        <span>{` ${pluralize('listing', pagination.count)}`}</span>
      </Typography>
      <IconButton
        data-test-id="nextPage"
        disabled={!pagination.next}
        onClick={() => fetchListings({pageUrl: pagination.next_url})}
      >
        <NavigateNextIcon/>
      </IconButton>
    </Grid>
  );

  const renderResults = () => (
    <>
      <Grid item container direction="row" justifyContent="space-between" data-test-id="listings-results">
        {aiSearch ? (
          <AiResults
            hideRationaleAndAlternatives={hideRationaleAndAlternatives()}
            modelRequestId={modelOutputs?.requestId}
            pagination={pagination}
            currentAppliedFilters={currentAppliedFilters}
            triggerFilterDialog={triggerFilterDialog}
            aiSearchType={aiSearchType}
            onFilter={onFilter}
            prompt={prompt}
          />
        ) : (
          <Grid item>
            <Typography variant="h2">
              Results
            </Typography>
          </Grid>
        )}
      </Grid>
      {!aiSearch && (
        <>
          <Grid item>
            <Typography>
              {pageListingsDescription()}
              {locationName ? ` in ${locationName}` : ''}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.costDisclaimer}>
              Cost may not include taxes and fees
            </Typography>
          </Grid>
        </>
      )}
      <InventoryBanner availability={availability * 100} />
      <Grid container
        className={classes.listingsContainer}
        justifyContent='space-between'
        spacing={3}
      >
        {listings.map((listing) =>
          <Grid item
            xs={12} sm={6} mb={2}
            key={listing.id}
            onMouseEnter={() => setHoveredListing({ id: listing.id, latitude: listing.latitude, longitude: listing.longitude })}
            onMouseLeave={() => setHoveredListing(null)}
          >
            <ExternalListingCard
              stay={listing}
              renderCtaButton={renderCtaButton}
            />
          </Grid>,
        )}
        {renderNavigation()}
      </Grid>
    </>
  );

  const renderEmptyState = () => (
    <ListingsEmptyState
      aiSearchType={aiSearchType}
      topSuggestedDestinations={topSuggestedDestinations}
    />
  );

  const renderErrorState = () => (
    <>
      {aiSearch && (
        <AiFilters
          currentAppliedFilters={currentAppliedFilters}
          onHandleAiSearchFilterChange={(newValue) => onFilter({...currentAppliedFilters, ...newValue})}
          onFilter={onFilter}
          triggerFilterDialog={triggerFilterDialog}
          aiSearchType={aiSearchType}
          pagination={pagination}
        />
      )}
      <ListingsErrorState
        error={error}
        initialLoad={initialLoad}
        aiSearch={aiSearch}
        fetchListings={fetchListings}
      />
    </>
  );

  return (
    <Box mt={0}>
      <Grid container direction="column">
        {isLoading && Loading(aiSearch)}
        {!isLoading && showResults && renderResults()}
        {!isLoading && !showResults && (
          <>
            {showEmptyState ? renderEmptyState() : renderErrorState()}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ListingsBox;
