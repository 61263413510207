import { PollAnswerType } from "./PollAnswersForm";
import { SyncComment } from "../../../../../shared/TripObjectTypes";

export type Poll = {
  answers: Array<PollAnswerType>,
  authorName: string,
  authorAvatarUrl: string,
  commentsCount: number,
  commentsPath: string,
  currentUserChoice?: PollAnswerType,
  mostRecentComment?: SyncComment,
  responsesCountTotal: number,
  id: string,
  isAuthoredByCurrentUser?: boolean,
  question: string,
  state: PollState,
  tripId: string,
  type: 'Poll',
};

export enum PollState {
  Open = 'open',
  Closed = 'closed',
};
