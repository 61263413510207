import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
} from "@mui/material";
import { NewPollAnswer } from "../NewPollForm";
import PollAnswerButton from "../shared/PollAnswerButton";

type PollPreviewProps = {
  answers: Array<NewPollAnswer>,
  question: string,
};

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid #C1C1C1',
    borderRadius: 5,
    padding: theme.spacing(3),
    width: '100%',
  },
  item: {
    marginBottom: theme.spacing(2),
    wordBreak: 'break-word',
  },
  subtitle: {
    fontSize: '14px',
    color: '#697379',
  },
}));

const PollPreview = ({
  question,
  answers,
}: PollPreviewProps) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.container} alignItems="center" data-test-id="pollPreview">
      <Grid item container justifyContent="left" className={classes.item}>
        <Typography variant="h3" fontSize={18}>
          {question}
        </Typography>
      </Grid>
      <Grid item container justifyContent="left" className={classes.item}>
        <Typography className={classes.subtitle}>
          Select your answer below
        </Typography>
      </Grid>
      <Grid item container>
        <Grid item container direction="column">
          {answers.map((answer, index) =>
            <Grid item key={index}>
              <PollAnswerButton
                answer={answer}
                className={classes.item}
                disabled
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PollPreview;
