import React, { useCallback, useContext } from "react";
import {
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from '@mui/icons-material/Edit';
import AddEventDropdown from "./AddEventDropdown";
import AddEventFloatingButton from "./AddEventFloatingButton";
import { ItineraryDateType } from "../../../../shared/TripObjectTypes";
import NoSuggestionsYet from "../../../../shared/NoSuggestionsYet";
import Linkify from "../../../../shared/Linkify";
import { SegmentContext } from "../../../../Document";
import * as routes from "../../../../../routes";
import ItineraryDatesChipBar, { ITINERARY_DATE_CHIP_WIDTH } from "./ItineraryDatesChipBar";
import { setScrollBarHorizontalPosition } from "../../../../../services/setScrollBarHorizontalPosition";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import ItineraryEventsList from "./ItineraryEventsList";
import Divider from "../../../../shared/Divider";
import clsx from "clsx";
import useSegment from "../../../../../services/useSegment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ButtonBase from "@mui/material/ButtonBase";
import AddDescriptionLink from "../ItineraryDatesPage/AddDescriptionLink";

const {
  editTripItineraryDatePath,
  tripItineraryDatePath,
} = routes;

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    overflowX: 'auto',
    flexWrap: 'nowrap',
  },
  itineraryDateTitle: {
    textAlign: 'left',
    lineHeight: theme.spacing(4),
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 600,
    minWidth: '100%',
    overflow: 'hidden',
  },
  itineraryDateSubtitle: {
    fontSize: 20,
  },
  itineraryDaySubheader: {
    marginBottom: theme.spacing(2),
  },
  note: {
    fontSize: theme.spacing(2),
  },
  editButton: { // TODO: Refactor DRY
    width: 32,
    height: 32,
    backgroundColor: "#FFFFFF",
    border: `1px solid ${theme.palette.general.background.disabled}`,
    "&:hover": {
      backgroundColor: '#F6F6F6',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& > svg': {
      color: '#334351',
    },
  },
  itineraryHeader: {
    maxWidth: '250px',
  },
  dayAndEditSection: {
    justifyContent: 'space-between',
  },
  chevronRight: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.primary.main,
    fontWeight: 600,
    '& > svg': {
      fontSize: '2rem',
    },
  },
}));

type ItineraryMainContentType = {
  itineraryDate: ItineraryDateType,
  itineraryDates: Array<ItineraryDateType>,
  availableActivitiesCount: number,
  scheduleListView?: boolean,
  page: string,
};

const ItineraryMainContent = ({
  itineraryDate,
  itineraryDates,
  availableActivitiesCount,
  scheduleListView = false,
  page,
}: ItineraryMainContentType) => {
  const classes = useStyles();
  const { tripId } = useContext(SegmentContext);
  const { trackLinkSegment } = useSegment();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const refs = useCallback((element) => {
    if(element !== null) {
      trackLinkSegment(
        element,
        'Edit Itinerary Day Initiated ',
        {
          page: page,
        },
      );
    }
  }, []);

  return(
    <Grid data-test-id="itinerary-date-section">
      {!scheduleListView && (
        <>
          <Grid
            container
            pb={1}
            className={classes.filterContainer}
            ref={(ele) => {setScrollBarHorizontalPosition(ele, ITINERARY_DATE_CHIP_WIDTH, itineraryDate.dayNumber - 1)}}
          >
            <ItineraryDatesChipBar
              itineraryDates={itineraryDates}
              selectedDate={itineraryDate.date}
            />
          </Grid>
        </>
      )}
      <Grid container spacing={1} justifyContent="space-between" pt={5} data-test-id='itinerary-date-card' >
        <Grid
          className={clsx({
            [classes.itineraryHeader]: !isMobile,
          })}
          pt={2}
          container
          item
          wrap="nowrap"
        >
          <Grid container>
            <Grid container item className={clsx( {
              [classes.dayAndEditSection]: isMobile,
            })}>
                <Grid item>
                  <Typography className={classes.itineraryDateTitle}>
                    {`Day ${itineraryDate.dayNumber}`}
                  </Typography>
                </Grid>
              {!scheduleListView && itineraryDate.description && (
                <Grid item ml={2}>
                  <IconButton
                    className={classes.editButton}
                    data-test-id="edit-button"
                    href={editTripItineraryDatePath(itineraryDate.tripId, itineraryDate.id)}
                    ref={(element) => refs(element)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
              { scheduleListView && isMobile && (
                <Grid item>
                  <ButtonBase
                    href={tripItineraryDatePath(tripId, itineraryDate)}
                    className={classes.chevronRight}
                    data-test-id='view-button'
                  >
                    View
                    <ChevronRightIcon fontSize="small" />
                  </ButtonBase>
                </Grid>
              )}
              </Grid>
              <Grid item>
                <Typography pt={1} className={classes.itineraryDateSubtitle}>
                  {itineraryDate.formattedDate}
                </Typography>
              </Grid>
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item>
            { !scheduleListView && (
              <AddEventDropdown tripId={tripId} itineraryDateId={itineraryDate.id} />
            )}
            { scheduleListView && (
              <ButtonBase
                href={tripItineraryDatePath(tripId, itineraryDate)}
                className={classes.chevronRight}
                data-test-id='view-button'
              >
                View
                <ChevronRightIcon fontSize="small" />
              </ButtonBase>
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Grid item className={classes.note}>
          {
            itineraryDate.description ?
            <Linkify>{itineraryDate.description}</Linkify> :
            <AddDescriptionLink itineraryDate={itineraryDate} />
          }
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        {itineraryDate.itineraryEvents.length > 0 ? (
          <ItineraryEventsList
            itineraryDateId={itineraryDate.id}
            itineraryEvents={itineraryDate.itineraryEvents}
            page={page}
          />
        ) : (
          <NoSuggestionsYet
            illustration='itinerary_02'
            subheading='Start adding to your day.'
          />
        )}
      </Grid>
      {isMobile && !scheduleListView && <AddEventFloatingButton tripId={tripId} itineraryDateId={itineraryDate.id} />}
    </Grid>
  )
};

export default ItineraryMainContent;
