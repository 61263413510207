import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from "@mui/styles";
import withLayout from "./withLayout";
import SideBarPage from "../../../shared/SideBarPage";
import MainContent from "./SuggestedDestinationsPage/MainContent";
import VotingTopSideBarContent from "./shared/VotingTopSideBarContent";
import * as routes from "../../../../routes";
import Divider from "../../../shared/Divider";
import TravelersVotingBox, { TravelerProps } from "../../../shared/TravelersVotingBox";
import { Box } from "@mui/material";
import { usePhasingContext } from "../../../../services/phasing";
import SuggestingPhaseButtons from './shared/SuggestingPhaseButtons';
import { DESTINATIONS_LIST_QUERY } from "../../../../graphql/queries/destinations";
import SkeletonPage from "../../../shared/SkeletonPage";
import * as Sentry from "@sentry/browser";
import { DestinationType } from "../../../shared/TripObjectTypes";
import SnackbarNotification, { SnackbarNotificationProp } from "../../../shared/SnackbarNotification";
import ActivityAd from "../../../shared/ActivityAd";

const {
  newTripChosenDestinationPath,
  setSuggestingPhaseTripDestinationsPath,
  setVotingPhaseTripDestinationsPath,
} = routes;

type DestinationSortAndFilter = {
  sortBy: 'recently_added',
}

const Snackbar = (props: Partial<SnackbarNotificationProp>) => (
  <SnackbarNotification
    autoHideDuration={props.autoHideDuration ?? null}
    message={props.message}
    open={!!props.message}
    severity={props.severity}
  />
)

const SuggestedDestinationsPage = ({
  activityAd,
  currentSorting,
  currentSortingLabel,
  lastEventUpdatedAt,
  shouldDisplayTravelersList,
  sortings,
  travelersVoting,
  trip,
  userHasCompletedVotingOnDestinations,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { areDestinationsInSuggestingPhase, areDestinationsInVotingPhase } = usePhasingContext();

  const initialSortAndFilter = {
    sortBy: currentSorting,
  };

  const [activeSortAndFilter] = useState<DestinationSortAndFilter>(initialSortAndFilter);
  const { sortBy } = activeSortAndFilter;

  const { data, loading, error } = useQuery(
    DESTINATIONS_LIST_QUERY, {
      variables: {
        tripId: trip.gid,
        ...activeSortAndFilter,
      },
    },
  );

  if (loading) return <SkeletonPage />;

  if (error) {
    Sentry.captureException(error)
    return <>
      <Snackbar
        message='Sorry, something went wrong loading destinations. Please try again.'
        severity='error'
      />
      <SkeletonPage />
    </>
  }

  const destinations: DestinationType[] = data.destinations
  const travelers: TravelerProps[] = data.travelers.map(traveler => ({
    ...traveler,
    missingVotesCount: traveler.destinationMissingVotesCount,
    name: traveler.displayName,
  }));

  const showVotingBoxOnSidebar = shouldDisplayTravelersList && !isMobile && destinations.length > 0;
  const showVotingBoxOnMainContent = shouldDisplayTravelersList && isMobile && destinations.length > 0;
  const votingBox = (isSidebar) => {
    if (areDestinationsInVotingPhase() && ((isSidebar && showVotingBoxOnSidebar) || (!isSidebar && showVotingBoxOnMainContent))) {
      return <>
        {isSidebar && <Box mt={3}><Divider /></Box>}
        <Box mb={3} mt={isSidebar ? 3 : 0}>
          <TravelersVotingBox
            travelers={travelers}
            tripId={trip.gid}
            verticalType='destinations'
          />
        </Box>
        <Box mb={3}><Divider /></Box>
      </>;
    }
  };

  return (
    <SideBarPage
      mainContent={
        <>
          {votingBox(false)}
          <MainContent
            activityAd={activityAd}
            currentSorting={sortBy}
            currentSortingLabel={currentSortingLabel}
            destinations={destinations}
            isPlanner={trip.isPlanner}
            userHasCompletedVoting={userHasCompletedVotingOnDestinations}
            sortings={sortings}
            travelers={travelers}
            lastEventUpdatedAt={lastEventUpdatedAt}
            tripId={trip.id}
          />
        </>
      }
      sideBarContent={
        <>
          {!isMobile && activityAd && (
            <ActivityAd
              content={activityAd}
              vertical="Destinations"
            />
          )}
          {areDestinationsInVotingPhase() && (
            <VotingTopSideBarContent
              disabledActions={destinations.length === 0}
              finalizePath={newTripChosenDestinationPath(trip.id)}
              isCurrentUserPlanner={trip.isPlanner}
              transitionToSuggestingPath={setSuggestingPhaseTripDestinationsPath(trip.id)}
              type='destination'
            />
          )}
          {areDestinationsInSuggestingPhase() && (
            <SuggestingPhaseButtons
              hasSuggestions={destinations.length > 0}
              isPlanner={trip.isPlanner}
              transitionToVotingPath={setVotingPhaseTripDestinationsPath(trip.id)}
              tripId={trip.id}
              tripObjectType='destinations'
            />
          )}
          {votingBox(true)}
        </>
      }
    />
  );
};

export default withLayout(SuggestedDestinationsPage, { fluid: true });
