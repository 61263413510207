import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import { BatchHttpLink } from "@apollo/client/link/batch-http";

const createApolloClient = ({ graphqlPath, csrfToken }: {
  graphqlPath: string,
  csrfToken?: string,
}) => {
  const link = new BatchHttpLink({
    uri: graphqlPath,
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    batchInterval: 20,
    batchMax: 5,
  })

  const cache = new InMemoryCache({
    typePolicies: {
      DateOptionWithAvailability: {
        keyFields: ['id', 'availabilityDateId', 'available', 'estimatedPrice', 'price'],
      },
      ExternalListing: {
        keyFields: [
          'id',
          'pricing', ['price', 'type'],
        ],
      },
      Reaction: {
        keyFields: [
          'emoji',
          'reactable', ['id'],
        ],
      },
    },
  })

  const client = new ApolloClient({
    cache,
    link,
    name: 'troupe-web',
    connectToDevTools: false,
   });

  return client;
}

export { createApolloClient, ApolloProvider }
