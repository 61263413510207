type DateOptionSentimentForDecidingPopularity = 'yes' | 'maybe';

type DateOptionForDecidingPopularity = {
  travelersVotes: {
    yes: object[],
    maybe: object[],
  },
  id: any,
};

const getMaximumVotesByKind = (
  collection: DateOptionForDecidingPopularity[],
  kind: DateOptionSentimentForDecidingPopularity,
) => {
  return Math.max.apply(
    Math,
    collection.map(option => option.travelersVotes[kind].length),
  );
};

const getMostVotedWithKind = (
  collection: DateOptionForDecidingPopularity[],
  kind: DateOptionSentimentForDecidingPopularity,
) => {
  let maximum = getMaximumVotesByKind(collection, kind);

  if (maximum === 0) return [];

  return collection.filter(option => {
    return option.travelersVotes[kind].length === maximum;
  });
};

const getMostPopularDateOptions = (dateOptions: DateOptionForDecidingPopularity[]) => {
  let mostPopular = getMostVotedWithKind(dateOptions, 'yes');

  if (mostPopular.length > 1) {
    return getMostVotedWithKind(mostPopular, 'maybe');
  } else {
    return mostPopular;
  }
};

export default getMostPopularDateOptions;
