export const POPULAR_FILTERS = {
  AC: 'Air Conditioning',
  BREAKFAST_INCLUDED: 'Breakfast included',
  DRYER: 'Dryer',
  EVENTS_ALLOWED: 'Events allowed',
  FRONTDESK_24_HOUR: '24-hour front desk',
  INTERNET: 'Wifi',
  MICROWAVE: 'Microwave',
  POOL: 'Pool',
  TV: 'TV',
  WASHER: 'Washer',
};

export const FEATURES_FILTERS = {
  AIRPORT_TRANSPORTATION: 'Airport transportation',
  ARCADE: 'Arcade',
  BAR: 'Bar',
  BARBECUE: 'BBQ Grill',
  CHILDREN_WELCOME: 'Children Welcome',
  CRIB: 'Crib',
  EXTRA_BED: 'Extra bed',
  FIREPLACE: 'Fireplace',
  FITNESS_EQUIPMENT: 'Gym',
  FREE_PARKING: 'Free parking',
  HOT_TUB: 'Hot tub',
  HOUSEKEEPING: 'Housekeeping',
  KITCHEN: 'Kitchen',
  LIVING_AREA: 'Living area',
  OUTDOOR_SPACE: 'Outdoor space',
  PARKING_AVAILABLE: 'Parking available',
  PETS_ALLOWED: 'Pets allowed',
  RESTAURANT: 'Restaurant',
  ROOM_SERVICE: 'Room service',
  SMOKING_ALLOWED: 'Smoking allowed',
  SPA: 'Spa',
};

export const ACCESSIBILITY_AND_SAFETY_FILTERS = {
  CARBON_MONOXIDE_DETECTOR: 'Carbon-monoxide detector',
  ELEVATOR: 'Elevator',
  SMOKE_DETECTOR: 'Smoke detector',
  WHEELCHAIR_ACCESSIBLE: 'Wheelchair accessible',
};

const POPULAR_FILTERS_KEYS = Object.keys(POPULAR_FILTERS)
const FEATURES_FILTERS_KEYS = Object.keys(FEATURES_FILTERS)
const ACCESSIBILITY_AND_SAFETY_FILTERS_KEYS = Object.keys(ACCESSIBILITY_AND_SAFETY_FILTERS)

export const ALL_FILTERS_OBJECT = {
  ...POPULAR_FILTERS,
  ...FEATURES_FILTERS,
  ...ACCESSIBILITY_AND_SAFETY_FILTERS,
}

export const ALL_AMENITIES_FILTERS = [
  ...POPULAR_FILTERS_KEYS,
  ...FEATURES_FILTERS_KEYS,
  ...ACCESSIBILITY_AND_SAFETY_FILTERS_KEYS] as AmenitiesFiltersType[];

export type AmenitiesObjectFiltersType = typeof POPULAR_FILTERS | typeof FEATURES_FILTERS | typeof ACCESSIBILITY_AND_SAFETY_FILTERS;

export type AmenitiesFiltersType = keyof typeof POPULAR_FILTERS | keyof typeof FEATURES_FILTERS | keyof typeof ACCESSIBILITY_AND_SAFETY_FILTERS;
