// This component is a copy from the original TablePaginationActions component
// but we changed the IconButton and the arrow icons.

import React from 'react';
import { makeStyles, useTheme, createStyles } from '@mui/styles';
import IconButton from "../../buttons/IconButton";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    icon: {
      marginRight: theme.spacing(3),
    },
  }),
);

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const maxPageCount = Math.ceil(count / rowsPerPage) - 1;

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="previous page"
        className={classes.icon}
        data-test-id="previousPageButton"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <NavigateNextIcon /> : <NavigateBeforeIcon />}
      </IconButton>
      <IconButton
        aria-label="next page"
        data-test-id="nextPageButton"
        disabled={page >= maxPageCount}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
      </IconButton>
    </div>
  );
};

export default TablePaginationActions;
