import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import * as routes from '../../../../../routes';
import TuneIcon from "@mui/icons-material/Tune";
import {ActivityType, ItineraryDateType} from "../../../../shared/TripObjectTypes";
import ModalForm from "../../../../shared/ModalForm";
import { FormContext } from "../../../../shared/RailsForm";
import CustomChip from "../../../../buttons/CustomChip";
import Activity from "../ActivitiesPage/Activity";
import EmptyStateModalForm from "../../../../shared/EmptyStateModalForm";

const {
  tripActivitiesPath,
  tripActivityEventsPath,
  tripItineraryDatePath,
} = routes;

type ItineraryActivitiesChoosingFormProps = {
  itineraryDate: ItineraryDateType,
  activitiesAvailable: ActivityType[],
};

const useStyles = makeStyles((theme) => ({
  selectedActivity: {
    border: "2px solid #80b4fb",
  },
  container: {
    overflowX: 'auto',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(540)]: {
      width: 'calc(100vw - 16px)',
    },
  },
  title: {
    color: '#303232',
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
}));

type ActivityIdsInputProps = {
  selectedActivityIds: string[],
};

const ActivityIdsInput = ({
  selectedActivityIds,
}: ActivityIdsInputProps) => {
  return <>
    {selectedActivityIds.map((activityId) =>
      <input key={activityId} type="hidden" value={activityId} name="schedulable_ids[]" />,
    )}
  </>
}

const ItineraryActivitiesChoosingForm = ({
  itineraryDate,
  activitiesAvailable,
}: ItineraryActivitiesChoosingFormProps) => {
  const classes = useStyles();

  const [activityIds, setActivityIds] = useState([]);
  const [activeCategory, setActiveCategory] = useState('All')
  const [activityList, setActivityList] = useState(activitiesAvailable)
  const showEmptyState = activityList.length === 0;

  const isSelectedActivity = (activity) => {
    return activityIds.indexOf(activity.id) > -1
  }

  const onClick = (activity) => {
    if(isSelectedActivity(activity)) {
      setActivityIds(activityIds.filter(activityId => activityId !== activity.id));
    } else {
      setActivityIds(activityIds => [...activityIds, activity.id]);
    }
  }

  const categoryCount = category => {
    let count = 0
    activitiesAvailable.map(activity => {
      if (activity.category === category){ count++ }
    })
    return count
  }

  const availableCategories = () => {
    const categories = {
      All: activitiesAvailable.length,
    }
    activitiesAvailable.map(({category})=>{
      if(category) {
        categories[category] = categoryCount(category)
      }
    });
    return categories
  }

  const isActive = category => {
    return category === activeCategory
  }

  const filterActivities = (category) => {
    if (category === 'All') {return activitiesAvailable}
    return activitiesAvailable.filter( activity => {
      return activity.category === category
    })
  }

  const filterGroup = (category) => {
    setActiveCategory(category)
    setActivityList(filterActivities(category))
  }

  return (
    <>
      {showEmptyState && (
        <EmptyStateModalForm
          buttonHref={tripActivitiesPath(itineraryDate.tripId)}
          buttonText='Go to activities'
          closePath={tripItineraryDatePath(itineraryDate.tripId, itineraryDate)}
          imgSrc={'/assets/activity_fallback.svg'}
          subtitle='Head over to the Activities tab to create some activities first.'
          title='You don’t have any activities'
        />
      )}
      {!showEmptyState &&
        <ModalForm
          title={`Add activities to Day ${itineraryDate.dayNumber}`}
          subtitle={'Select activities to add to your itinerary for the day'}
          closePath={tripItineraryDatePath(itineraryDate.tripId, itineraryDate)}
          formActionPath={tripActivityEventsPath(itineraryDate.tripId)}
          useDefaultSaveButton={true}
          saveButtonLabel={`Add activities (${activityIds.length})`}
          disabledSubmit={activityIds.length === 0}
        >
          <input type="hidden" name="itinerary_date_id" value={itineraryDate.id} />
          <FormContext.Consumer>
            {({ onFormChange }) => (<>
              <input type="hidden" value={"activity"} name="schedulable_type" />
              <ActivityIdsInput selectedActivityIds={activityIds} />
              <Box mb={4} >
              {activityList.length > 0 && (
                <Grid container direction="column" justifyContent="center">
                  <Grid item container spacing={1}>
                  <Grid item>
                    <TuneIcon />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.title}>
                      Filter by
                    </Typography>
                  </Grid>
                </Grid>
                  <Grid item container spacing={2} className={classes.container}>
                    {Object.keys(availableCategories()).sort().map((option, index) => (
                      <Grid item key={index}>
                        <div
                          onClick={() => filterGroup(option)}
                          data-test-id={`${option}-filter-tab`}
                        >
                          <CustomChip
                            active={isActive(option)}
                            trackEventDisabled
                          >
                            {option} ({availableCategories()[`${option}`]})
                          </CustomChip>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              </Box>
              <Box>
                <Grid container direction="column">
                  {activityList.map((activity, index) =>
                    <React.Fragment key={activity.id}>
                      <Grid
                        item
                        key={index}
                        my={2}
                        className={clsx(
                          {[classes.selectedActivity]: isSelectedActivity(activity)},
                        )}
                      >
                        <Activity
                          activity={activity}
                          displaySelectButton
                          displayActivityLinks={false}
                          isSelectedActivity={isSelectedActivity(activity)}
                          onActivityClick={() => onClick(activity)}
                          showSentiments={false}
                        />
                      </Grid>
                    </React.Fragment>,
                  )}
                </Grid>
              </Box>
            </>)}
          </FormContext.Consumer>
        </ModalForm>
      }
    </>
  );
};

export default ItineraryActivitiesChoosingForm;
