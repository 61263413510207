import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Button from "../../../../buttons/Button";
import ModalForm from "../../../../shared/ModalForm";
import { FormContext } from "../../../../shared/RailsForm";
import Activity from "../ActivitiesPage/Activity";
import { ActivityGroupType, ActivityType } from "../../../../shared/TripObjectTypes";
import * as routes from '../../../../../routes';
import TuneIcon from "@mui/icons-material/Tune";
import CustomChip from "../../../../buttons/CustomChip";

const {
  tripActivityGroupPath,
  tripActivityGroupsPath,
  tripAddActivitiesUpdatePath,
} = routes;

type ActivityChoosingModalFormProps = {
  activityGroup: ActivityGroupType,
  refererPage: 'permapage' | 'groups_tab',
  title: string,
  subtitle: string,
};

const useStyles = makeStyles((theme) => ({
  selectedActivity: {
    border: "2px solid #80b4fb",
  },
  container: {
    overflowX: 'auto',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(540)]: {
      width: 'calc(100vw - 16px)',
    },
  },
  title: {
    color: '#303232',
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
}));

type ActivityIdsInputProps = {
  selectedActivityIds: string[],
};

const ActivityIdsInput = ({
  selectedActivityIds,
}: ActivityIdsInputProps) => {
  return <>
    {selectedActivityIds.map((activitId, index) =>
      <input type="hidden" value={activitId} name="activity_group[activity_ids][]" />
    )}
  </>
}

const ActivityChoosingModalForm = ({
  activityGroup,
  refererPage,
  subtitle,
  title,
}: ActivityChoosingModalFormProps) => {
  const classes = useStyles();

  const [activityIds, setActivityIds] = useState([]);
  const [activeCategory, setActiveCategory] = useState('All')
  const [activityList, setActivityList] = useState(activityGroup.activitiesAvailable)

  const isSelectedActivity = (activity) => {
    return activityIds.indexOf(activity.id) > -1
  }

  const onClick = (activity) => {
    if(isSelectedActivity(activity)) {
      setActivityIds(activityIds.filter(activityId => activityId !== activity.id));
    } else {
      setActivityIds(activityIds => [...activityIds, activity.id]);
    }
  }

  const pathTo = {
    permapage: {
      closePath: tripActivityGroupPath(activityGroup.tripId, activityGroup.id),
    },
    groupsTab: {
      closePath: tripActivityGroupsPath(activityGroup.tripId),
    }
  };

  const categoryCount = category => {
    let count = 0
    activityGroup.activitiesAvailable.map(activity => {
      if (activity.category === category){ count++ }
    })
    return count
  }

  const availableCategories = () => {
    const categories = {
      All: activityGroup.activitiesAvailable.length,
    }
    activityGroup.activitiesAvailable.map(({category})=>{
      if(category) {
        categories[category] = categoryCount(category)
      }
    });
    return categories
  }

  const isActive = category => {
    return category === activeCategory
  }

  const filterActivities = (category) => {
    if (category === 'All') {return activityGroup.activitiesAvailable}
    return activityGroup.activitiesAvailable.filter( activity => {
      return activity.category === category
    })
  }

  const filterGroup = (category) => {
    setActiveCategory(category)
    setActivityList(filterActivities(category))
  }

  return (
    <ModalForm
      title={title}
      subtitle={subtitle}
      formMethod='PATCH'
      closePath={pathTo[refererPage].closePath}
      formActionPath={tripAddActivitiesUpdatePath(activityGroup.tripId, activityGroup.id)}
      useDefaultSaveButton={true}
      saveButtonLabel={`Add (${activityIds.length})`}
      disabledSubmit={activityIds.length === 0}
    >
      <FormContext.Consumer>
        {({ onFormChange }) => (<>
          <input type="hidden" value={refererPage} name="refererPage" />
          <ActivityIdsInput selectedActivityIds={activityIds} />
          <Box mb={4} >
            <Grid container direction="column" justifyContent="center">
              <Grid item container spacing={1}>
                <Grid item>
                  <TuneIcon />
                </Grid>
                <Grid item>
                  <Typography className={classes.title}>
                    Filter by
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={2} className={classes.container}>
                {Object.keys(availableCategories()).sort().map((option, index) => (
                  <Grid item key={index}
                  >
                    <div
                      onClick={() => filterGroup(option)}
                      data-test-id={`${option}-filter-tab`}
                    >
                      <CustomChip
                        active={isActive(option)}
                        trackEventDisabled
                      >
                        {option} ({availableCategories()[`${option}`]})
                      </CustomChip>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container direction="column">
              {activityList.map((activity, index) =>
                <React.Fragment key={activity.id}>
                  <Grid
                    item
                    my={2}
                    className={clsx(
                      {[classes.selectedActivity]: isSelectedActivity(activity)},
                    )}
                  >
                    <Activity
                      activity={activity}
                      displaySelectButton
                      displayActivityLinks={false}
                      isSelectedActivity={isSelectedActivity(activity)}
                      onActivityClick={() => onClick(activity)}
                      showSentiments={false}
                    />
                  </Grid>
                </React.Fragment>,
              )}
            </Grid>
          </Box>
        </>)}
      </FormContext.Consumer>
    </ModalForm>
  );
};

export default ActivityChoosingModalForm;
