import React, { useContext, useState } from "react";
import axios from "axios";
import clsx from "clsx";
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from "@mui/styles";
import CheckIcon from '@mui/icons-material/Check';
import Button from "../../buttons/Button";
import { ListingsContext } from "./StaysSearch";
import { FormsContext } from "../../Document";
import { tripStayURL } from '../../../routes';
import useSegment from '../../../services/useSegment';
import {isCurrentlyInPublicSearch, isCurrentlySmartSearch} from "../../../services/windowLocation";

type ExternalListingFormProps = {
  staysPath: string,
  canAddToTrip: boolean,
  fullWidth: boolean,
  id: string,
  url: string,
  source: 'SERP search' | 'Smart Search'
};

const useStyles = makeStyles(theme => ({
  root: {
    float: 'right',
    whiteSpace: 'nowrap',
    width: '100%',
    height: '40px !important',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    zIndex: 2,
  },
  fullWidth: {
    width: '100%',
  },
  added: {
    cursor: 'initial',
    background: '#1EBE95 !important',
    borderColor: '#1EBE95 !important',
    color: 'white !important',
    boxShadow: 'none !important',
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    fontSize: 18,
  },
  addedText: {
    verticalAlign: 'middle',
  },
  spinner: {
    height: '30px !important',
    width: '30px !important',
    color: 'white',
  },
}));

export const ExternalListingForm = ({
  staysPath,
  canAddToTrip,
  fullWidth,
  id,
  url,
  source,
}: ExternalListingFormProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { onExternalListingAdd } = useContext(ListingsContext);
  const { csrfToken, triggerLogIn } = useContext(FormsContext);
  const { trackSegment } = useSegment();

  const axiosCreateStayRequest = () => (
    axios.request({
      method: 'post',
      url: staysPath,
      data: {
        url,
        after_create_redirect_path: window.location.href,
        authenticity_token: csrfToken,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
  );

  const createStay = () => {
    if (canAddToTrip) {
      setLoading(true);
      axiosCreateStayRequest()
        .then((response) => {
          trackStayAddedEvent(response.data);
          onExternalListingAdd(id);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            triggerLogIn()
          } else {
            throw error;
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const trackStayAddedEvent = (stay) => {
    trackSegment('Stay Added', {
      category: 'Stays',
      method: `"Add to trip" submitted successfully in ${source} in Stays`,
      name: stay.name,
      stayId: stay.uuid,
      url: tripStayURL(stay.tripId, stay.uuid),
      source: stay.provider,
      page: isCurrentlySmartSearch() ? 'Smart Search' :'SERP',
    });
  };

  return (
    <>
      <Button
        className={clsx(
          classes.root,
          {
            [classes.added]: !canAddToTrip,
            [classes.fullWidth]: fullWidth,
          },
        )}
        disabled={loading}
        disableRipple={!canAddToTrip}
        onClick={createStay}
        variant="primary"
      >
        { loading ?
          <CircularProgress className={classes.spinner} />
          : canAddToTrip ?
            "+ Add"
            : (
            <>
              <CheckIcon className={classes.icon} />
              <span className={classes.addedText}>Added</span>
            </>
          )
        }
      </Button>
    </>
  );
};

export default ExternalListingForm;
