import React, { useState } from "react";
import {
  Box,
  ButtonBase,
  Divider,
  IconButton,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import moment from "moment";
import DatesSection from "./SearchCriteriaDrawer/DatesSection";
import GuestsSection from "./SearchCriteriaDrawer/GuestsSection";
import { DateOptionProps } from "../StaysSearch";
import Drawer from "../../../shared/Drawer";
import FixedSaveBar from "../../../shared/FixedSaveBar";
import { useTrackSERPEvent } from "../../../../services/segmentEvents/useSERPEvents";
import { ListingsContext } from "../StaysSearch";
import { useTheme } from "@mui/styles";
import pluralize from "pluralize";
import { RoomFilter } from "./Filters/SortAndFilter/RoomFilters";
import {aiSearchType} from "./Filters";

type SearchCriteriaProps = {
  arrival?: string,
  departure?: string,
  minGuests?: number,
  dateOptions: DateOptionProps[],
  onChange: (value: any) => void,
  inventory?: string,
  bedroomCount?: string,
  hotelRoomCount?: string,
  aiSearchType?: aiSearchType,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  searchCriteriaBar: {
    width: '100%',
    border: '1px solid #D9D9D9',
    borderRadius: '5px',
    padding: '4px 16px',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.common.white,
  },
  selectedValue: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  noValueSelected: {
    color: '#979797',
  },
}));

const SearchCriteria = ({
  arrival = '',
  departure = '',
  minGuests = 1,
  inventory,
  dateOptions,
  onChange,
  bedroomCount = '',
  hotelRoomCount = '',
  aiSearchType,
}: SearchCriteriaProps) => {
  const { trackSERPEvent } = useTrackSERPEvent();
  const { openSearchCriteriaDrawer, closeSearchCriteriaDrawer, isSearchCriteriaDrawerOpen } = React.useContext(ListingsContext);
  const roomCountType = {hotels: hotelRoomCount, vacation_rentals: bedroomCount}
  const roomCount = roomCountType[inventory];
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const renderRoomCriteria = !isMobile && roomCount;


  const SearchCriteriaBar = () => {
    const classes = useStyles();

    const formatDate = (date) => moment(date).format('MMM DD');
    const areDatesSelected = !!arrival;
    const datesText = areDatesSelected ? `${formatDate(arrival)} - ${formatDate(departure)}` : 'Select dates';
    const guestsText = `${minGuests} ${renderRoomCriteria ? `${pluralize('guest', Number(minGuests))}, ${roomCount} ${pluralize('room', Number(roomCount))}` : ''}`

    return (
      <ButtonBase
        data-test-id="search-criteria-bar"
        disableTouchRipple
        onClick={() => openSearchCriteriaDrawer()}
        className={classes.searchCriteriaBar}
      >
        <Typography className={clsx(classes.selectedValue, { [classes.noValueSelected]: !areDatesSelected })}>
          <CalendarMonthIcon /> {datesText}
        </Typography>
        <Typography className={classes.selectedValue} ml={2}>
          <SupervisorAccountIcon /> {guestsText}
        </Typography>
      </ButtonBase>
    );
  };

  const SearchCriteriaDrawer = () => {
    const [selectedDates, setSelectedDates] = useState([arrival, departure]);
    const [selectedMinGuests, setSelectedMinGuests] = useState(minGuests);
    const [selectedBedrooms, setSelectedBedrooms] = useState(bedroomCount);
    const [selectedHotelRooms, setSelectedHotelRooms] = useState(hotelRoomCount);

    const onDrawerSubmit = () => {
      const nightCount = moment(selectedDates[1]).diff(moment(selectedDates[0]), 'days') || 0;

      trackSERPEvent('Search Criteria Submitted', {
        nightCount,
        bedroomCount: selectedBedrooms,
        hotelRoomCount: selectedHotelRooms,
        location: aiSearchType,
      });

      closeSearchCriteriaDrawer();

      if (canSubmitForm()) {
        onChange({
          arrival: selectedDates[0] || '',
          departure: selectedDates[1] || '',
          minGuests: selectedMinGuests,
          bedroomCount: selectedBedrooms,
          hotelRoomCount: selectedHotelRooms,
          searchMethod: 'searchCriteria',
        });
      }
    };

    const canSubmitForm: () => boolean = () => {
      return (
        (arrival !== selectedDates[0]) ||
        (departure !== selectedDates[1]) ||
        (minGuests !== selectedMinGuests) ||
        (bedroomCount !== selectedBedrooms) ||
        (hotelRoomCount !== selectedHotelRooms)
      );
    };

    return (
      <Drawer
        anchor="left"
        onClose={closeSearchCriteriaDrawer}
        open={isSearchCriteriaDrawerOpen}
      >
        <Box sx={{ flex: 1 }} p={2} pb={3}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2">Edit your search</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={closeSearchCriteriaDrawer}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider />

          <DatesSection
            dateOptions={dateOptions}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
          />
          <GuestsSection
            minGuests={Number(minGuests)}
            onGuestsSelected={setSelectedMinGuests}
          />

          <Grid container direction="column" spacing={2} mt={1}>
            <RoomFilter
              count={selectedBedrooms}
              onOptionClick={setSelectedBedrooms}
              options={['Any', '1', '2', '3', '4', '5', '6+']}
              title="Bedrooms"
              tooltip="This bedroom count applies to vacation rental listings."
            />
            <RoomFilter
              count={selectedHotelRooms}
              onOptionClick={setSelectedHotelRooms}
              options={['Any', '1', '2', '3', '4', '5', '6', '7', '8']}
              title="Hotel rooms"
              tooltip="This room count applies to hotel listings."
            />
          </Grid>
        </Box>
        <FixedSaveBar
          buttonContent="Update"
          onClick={onDrawerSubmit}
          saveButtonType="button"
        />
      </Drawer>
    );
  };

  return (
    <>
      <input type="hidden" value={arrival} name="arrival" />
      <input type="hidden" value={departure} name="departure" />
      <input type="hidden" value={minGuests} name="min_guests" />
      <SearchCriteriaBar />
      <SearchCriteriaDrawer />
    </>
  );
};

export default SearchCriteria;
