import type { Place } from "../components/shared/GooglePlacesAutocomplete";

type GooglePlaceGeometry = {
  location?: {
    lat: () => number,
    lng: () => number,
  },
};

type GooglePlacePhoto = {
  getUrl: () => string,
  height: number,
  html_attributions: string[],
  width: number,
};

type GooglePlaceApiResponse = {
  name?: string,
  formatted_address?: string,
  geometry?: GooglePlaceGeometry,
  place_id?: string,
  url?: string,
  photos?: GooglePlacePhoto[],
};

const getPlaceFromGooglePlacesApiResponse = (
  place: GooglePlaceApiResponse,
  formattedAddress: boolean,
): Place => {
  const nameOrEmptyString = isSearchableInput(place) ? place.name : '';
  const formattedAddressOrEmptyString = place.formatted_address ?? '';

  const id = place.place_id ?? '';
  const latitude = place.geometry ? place.geometry.location.lat() : '';
  const longitude = place.geometry ? place.geometry.location.lng() : '';
  const name = formattedAddress ? formattedAddressOrEmptyString : nameOrEmptyString;
  const url = place.url ?? '';
  const image = place.photos ? place.photos[0].getUrl() : undefined;

  return {
    id,
    image,
    latitude,
    longitude,
    name,
    url,
  };
};

function isSearchableInput(place: GooglePlaceApiResponse): boolean {
  return place.name && Object.keys(place).length > 1;
}

export default getPlaceFromGooglePlacesApiResponse;
