import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Truncate from 'react-truncate';
import {
  Grid,
  Hidden,
  Typography,
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from "@mui/styles";

type TripNameProps = {
  name: string,
};

const useStyles = makeStyles((theme) => ({
  namePositionMobile: {
    position: 'absolute',
    display: 'block',
    bottom: '5%',
    paddingBottom: theme.spacing(1),
  },
  namePositionDesktop: {
    position: 'relative',
    top: '75%',
    width: 'fill-available',
  },
  name: {
    fontSize: 24,
    fontWeight: 600,
    bottom: '10%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    lineHeight: '1.4',
    position: 'relative',
  },
  nameShort: {
    color: "white",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  nameLong: {
    color: "#243060",
    marginRight: '20vw',
    marginLeft: '20vw',
  },
}));

const TripName = ({ name }: TripNameProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down(600));

  return (
    <Grid container justifyContent='center' className={clsx({
        [classes.namePositionDesktop]: !isMobile,
        [classes.namePositionMobile]: isMobile,
      })}>
      <Typography
        className={clsx(
          classes.name,
          {
            [classes.nameShort]: isMobile,
            [classes.nameLong]: !isMobile,
          }
        )}
        data-test-id="tripHeaderName">
        <Hidden smUp><Truncate lines={3}>{name}</Truncate></Hidden>
        <Hidden smDown>{name}</Hidden>
      </Typography>
    </Grid>
  )
};

export default TripName;
