import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ErrorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="25" height="24" viewBox="0 0 25 24" fill="none">
      <circle cx="12.0637" cy="12" r="12" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.6011 17.9853C18.1454 17.4518 18.1542 16.5781 17.6208 16.0338L14.1575 12.4999L17.6208 8.96597C18.1542 8.42163 18.1454 7.54791 17.6011 7.01446C17.0567 6.481 16.183 6.48983 15.6495 7.03417L12.2253 10.5282L8.80077 7.03379C8.26732 6.48945 7.39359 6.48062 6.84926 7.01407C6.30492 7.54753 6.29609 8.42125 6.82954 8.96559L10.2931 12.4999L6.82954 16.0342C6.29609 16.5785 6.30492 17.4522 6.84926 17.9857C7.39359 18.5191 8.26732 18.5103 8.80077 17.966L12.2253 14.4715L15.6495 17.9656C16.183 18.5099 17.0567 18.5188 17.6011 17.9853Z" fill="#FB5D5D"/>
    </SvgIcon>
  );
};

export const InfoIcon = () => {
  return <></>
};

export const SuccessIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="25" height="24" viewBox="0 0 25 24" fill="none">
      <circle cx="12.0637" cy="12" r="12" fill="#1EBE95"/>
      <path d="M5.56372 14L9.56372 18L19.0637 8" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <polygon points="6.65,13 9.5,15.9 15.6,9.4" fill="#1EBE95"></polygon>
    </SvgIcon>
  );
};

export const WarningIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="#FFCA0D"/>
      <rect x="10.5" y="4" width="3" height="11" rx="1.5" fill="white"/>
      <circle cx="12" cy="18.5" r="1.5" fill="white"/>
    </SvgIcon>
  );
};
