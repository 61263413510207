import React, { useReducer } from "react";
import {
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AvailabilityAndPricingSection from "./StayForm/AvailabilityAndPricingSection";
import StayFormProps from "./StayForm/StayFormProps";
import AddressField from "./StayForm/AddressField";
import DetailsFields from "./StayForm/DetailsFields";
import { hocRailsAction } from "../../Document";
import Errors from "../../shared/Errors";
import ModalForm from "../../shared/ModalForm";
import ImageUploader from '../../shared/ImageUploader';
import * as routes from '../../../routes';

const {
  tripStayPath,
  tripStaysPath,
} = routes;

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

const StayForm = ({
  stay,
  addressOnly,
  errors,
  stayId,
  shouldFocusPrice,
  tripId,
}: StayFormProps) => {
  const classes = useStyles();
  const collectUrl = !addressOnly;

  const initialState = {
    images: stay.images,
    name: stay.name,
    originalImages: stay.originalImages,
    originalName: stay.originalName,
  };

  const stayReducer = (state, action) => {
    switch (action.type) {
      case 'changeName':
        return Object.assign({}, state, { name: action.name });
      case 'changeImage':
        return Object.assign({}, state, { imageUrl: action.url });
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(stayReducer, initialState);
  const shouldDisplayNameInput = state.originalName || addressOnly || stay.name;

  return (
    <ModalForm
      closePath={tripStaysPath(tripId)}
      formActionPath={tripStayPath(tripId, stayId)}
      formMethod="patch"
      title="Edit stay"
    >
      <input
        name="stay_type"
        type="hidden"
        value={collectUrl ? 'url' : 'address'}
      />
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Errors errors={errors} />
        </Grid>
        <Grid item>
          <Typography className={classes.subtitle}>
            Details
          </Typography>
        </Grid>
        {collectUrl && (
          <Grid item>
            <TextField
              fullWidth
              id="stay_url"
              label="Link to listing"
              name="stay[url]"
              required
              variant="outlined"
              disabled
              defaultValue={stay.publicUrl}
            />
          </Grid>
        )}
        <ImageUploader
          defaultImage="/assets/stay_fallback_smaller.svg"
          inputName="stay[images][]"
          image={state.images[0]}
          onImageUpload={(url) => dispatch({ type: 'changeImage', url })}
          originalImages={state.originalImages}
        />
        {shouldDisplayNameInput && (
          <Grid item>
            <input
              type="hidden"
              defaultValue={state.originalName}
              name="stay[original_name]"
            />
            <TextField
              fullWidth
              id="stay_name"
              label="Name"
              name="stay[name]"
              variant="outlined"
              multiline
              placeholder={!state.name ? "What's this place called?" : state.name}
              value={state.name}
              onChange={(event) => dispatch({ type: 'changeName', name: event.target.value })}
            />
          </Grid>
        )}
        <Grid item>
          <Typography className={classes.label}>
            Add to map view
          </Typography>
          <AddressField
            currentGooglePlaceId={stay && stay.googlePlaceId}
            currentGooglePlaceUrl={stay && stay.googlePlaceUrl}
            currentAddress={stay && stay.address}
            currentLatitude={stay && stay.latitude}
            currentLongitude={stay && stay.longitude}
            required={addressOnly}
          />
        </Grid>
        {!stay && (
          <Grid item>
            <TextField
              fullWidth
              id="stay_comment"
              label="Comment"
              name="comment"
              variant="outlined"
            />
          </Grid>
        )}
        {!state.provider && (
          <Grid item>
            <DetailsFields shouldFocusPrice={shouldFocusPrice} stay={stay} />
          </Grid>
        )}
        <Divider className={classes.divider} />
        <AvailabilityAndPricingSection stay={stay} />
      </Grid>
    </ModalForm>
  );
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(StayForm);
