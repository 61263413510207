import React, { useContext, useEffect } from "react";
import axios from 'axios';
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import {FormsContext, TripSiteContext} from "../../../Document";
import withLayout from "./withLayout";
import TripUpdateEntry from "./TripUpdatesPage/TripUpdateEntry";
import FilteringBar from "./TripUpdatesPage/FilteringBar";
import { TripEvent } from "./TripUpdatesPage/TripUpdateEntry/TripUpdateEntryAction";
import * as routes from '../../../../routes';
import * as Sentry from "@sentry/browser";
import { gql, useQuery } from "@apollo/client";
import { USER_QUERY } from "../../../../graphql/queries/users";
import ManageNotificationsCallout from "./TripUpdatesPage/ManageNotificationsCallout";
import { TripType } from "../../../shared/TripTypes";

export type TripUpdateEventType = TransitionEvent | PollEvent | FinalizedEvent | CommentEvent | VotedEvent | CreatedEvent;

export enum TransitionEvent {
  DatesSuggesting = 'dates_transitioned_to_suggesting',
  DatesVoting = 'dates_transitioned_to_voting',
  DestinationSuggesting = 'destination_transitioned_to_suggesting',
  DestinationVoting = 'destination_transitioned_to_voting',
  StaySuggesting = 'stay_transitioned_to_suggesting',
  StayVoting = 'stay_transitioned_to_voting',
}

export enum PollEvent {
  PollClosed = 'poll_closed',
  PollCreated = 'poll_created',
}

export enum FinalizedEvent {
  DatesFinalized = 'dates_transitioned_to_finalized',
  DestinationFinalized = 'destination_transitioned_to_finalized',
  StayFinalized = 'stay_transitioned_to_finalized',
}

export enum CommentEvent {
  Commented = 'commented',
}

export enum VotedEvent {
  DateOptionVoted = 'date_option_voted',
  DestinationVoted = 'destination_voted',
  FinalizedVotedDateOption = 'finished_voted_date_options',
  FinalizedVotedDestination = 'finished_voted_destination',
  FinishedVotedStay = 'finished_voted_stay',
  StayVoted = 'stay_voted',
}

export enum CreatedEvent {
  ActivityCreated = 'activity_created',
  DateOptionCreated = 'date_option_created',
  DestinationCreated = 'destination_created',
  StayCreated = 'stay_created',
  ItineraryEventCreated = 'itinerary_event_created',
}

type TripUpdatesPageProps = {
  currentUserId: string,
  currentFilter?: string,
  countByCategory: {[key: string]: number},
  isPlanner: boolean,
  isUserLoggedIn: boolean,
  tripEvents: TripEvent[],
  trip: TripType,
  showCallout: boolean,
};

const {
  tripUpdatesViewLogsPath,
} = routes;

export const DISMISS_CALLOUT = gql`
  mutation UserNotificationCalloutDismiss {
    userNotificationCalloutDismiss(input:{}) {
      errors
    }
  }
`

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    fontSize: 28,
    fontWeight: 600,
    color: "#061722",
    paddingBottom: theme.spacing(2),
  },
  headerSubtitle: {
    fontSize: 16,
    color: "#272727",
    paddingBottom: theme.spacing(2),
  },
  noDataText: {
    fontSize: 16,
    color: "#272727",
    fontWeight: 600,
    marginTop: 25,
    marginBottom: theme.spacing(2),
  },
  checkBackText: {
    fontSize: 16,
    color: "#272727",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  image: {
    width: '100%',
    maxWidth: '426px',
  },
  root: {
    paddingTop: theme.spacing(4),
  },
}));

const TripUpdatesPage = ({
  currentFilter,
  currentUserId,
  countByCategory,
  isUserLoggedIn,
  isPlanner,
  trip,
  tripEvents,
}: TripUpdatesPageProps) => {
  const classes = useStyles();
  const { csrfToken } = useContext(FormsContext);
  const { currentUserGid } = useContext(TripSiteContext);
  const { data, loading } = useQuery(
    USER_QUERY, {
      variables: {
        ID: currentUserGid,
      },
    },
  );

  useEffect(() => {
    let timer: any;

    if (isUserLoggedIn) {
      timer = setTimeout(() => {
        axios.post(
          tripUpdatesViewLogsPath(trip.id),
          { authenticity_token: csrfToken },
        ).catch((error) => {
          if(!error.response?.status) return;

          Sentry.captureException(error);
        })
      }, 3000);
    }

    return () => {
      clearTimeout(timer)
    };
  }, []);

  const renderManageNotificationsCallout = () => (
    loading ? <Skeleton variant="text" height={40} data-test-id='skeleton'/> : <ManageNotificationsCallout />
  );

  return (
    <>
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <Typography className={classes.headerTitle}>Trip Updates</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.headerSubtitle}>
            Here’s what’s happening with your upcoming trip plans.
          </Typography>
        </Grid>
        {!data?.node.dateSawNotificationCallout && renderManageNotificationsCallout()}
      </Grid>
      <Divider className={classes.divider} />
      <Box mb={4}>
        <FilteringBar
          countByCategory={countByCategory}
          currentFilter={currentFilter}
          currentUserId={currentUserId}
          trip={trip}
          isPlanner={isPlanner}
        />
      </Box>
      {!tripEvents.length && (
        <Grid container direction="column" alignItems="center">
          <img
            className={classes.image}
            alt="No suggestions"
            src={`/assets/illustrations/relaxation.svg`}
          />
          <Grid item>
            <Typography className={classes.noDataText}>
              Nothing to see here…yet
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.checkBackText}>
              Check back once your group has suggested some options for your upcoming trip.
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container direction="column" alignItems="center">
        {tripEvents.map((tripEvent) => (
          <TripUpdateEntry
            key={tripEvent.id}
            tripEvent={tripEvent}
          />
        ))}
      </Grid>
    </>
  );
};

export default withLayout(TripUpdatesPage);
