import React, { useCallback, useEffect, useReducer, useRef } from "react";
import axios from "axios";
import debounce from 'lodash/debounce';
import { Box, Grid } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import BaseSkeleton from "@mui/material/Skeleton";
import Activity from "../../trips/microsite/ActivitiesPage/Activity";
import { ActivityCategoryType } from "../../../shared/TripObjectTypes";

type AddressParams = {
  address: string,
  google_place_id: string,
  google_place_url: string,
  google_place_image: string,
};

type ActivityPreviewProps = {
  onError?: () => void,
  onFetch?: () => void,
  onLoading?: () => void,
  onRemove?: () => void,
  tripId: string,
  link?: string,
  address?: AddressParams,
  category?: ActivityCategoryType,
};

const useStyles = makeStyles((theme) => ({
  skeletonCard: {
    border: "2px solid #e6e7e8",
    borderRadius: 5,
    "&:focus": {
      outline: "none",
    },
    padding: theme.spacing(2),
  },
}));

const activityReducer = (state, action) => {
  switch(action.type) {
    case 'loadActivity':
      return { activity: null, loading: true, fetched: false };
    case 'showActivity':
      return { activity: action.data, loading: false, fetched: true };
    case 'removeActivity':
      return { activity: null, loading: false, fetched: false }
    default:
      throw new Error();
  }
};

const Skeleton = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}))(BaseSkeleton);

const ActivityPreview = ({
  onError,
  onFetch,
  onLoading,
  onRemove,
  tripId,
  link,
  address,
  category,
}: ActivityPreviewProps) => {
  const classes = useStyles();

  const initialState = {
    activity: null,
    fetched: false,
    loading: false,
  };

  const [{ activity, fetched, loading }, dispatch] = useReducer(
    activityReducer,
    initialState,
  );

  const isParamsPresent = link ||
    (address && Object.entries(address).length !== 0);

  const fetchActivity = (parameters) => {
    dispatch({ type: 'loadActivity' });

    axios.get(
      '/api/activities/fetch_activity',
      { params: { trip_id: tripId, location: `POINT(${parameters.longitude} ${parameters.latitude})`, ...parameters } },
    ).then((response) => {
      dispatch({ type: 'showActivity', data: response.data });
    }).catch(() => {
      dispatch({ type: 'showActivity', data: null });
    });
  };

  const debouncedFetchActivity = useCallback(
    debounce((parameters) => fetchActivity(parameters), 500),
    [],
  );

  const completeUrl = (newUrl) => {
    if (!newUrl || newUrl === "" || newUrl.startsWith("http")) {
      return newUrl;
    } else {
      return `https://${newUrl}`;
    }
  };

  const missingActivity = !activity;
  const activityIsLoaded = !missingActivity && fetched;
  const activityCannotBeFetched = fetched && missingActivity;

  useEffect(() => {
    if (link) {
      debouncedFetchActivity({ link: completeUrl(link), category: category });
    }
  }, [link, category]);


  useEffect(() => {
    if (address && Object.entries(address).length !== 0) {
      debouncedFetchActivity({ ...address, category: category });
    }
  }, [address?.google_place_id, category]);

  useEffect(() => {
    if (!isParamsPresent) {
      dispatch({ type: 'removeActivity' });
    }
  }, [link, address]);

  useEffect(() => {
    if (loading) {
      onLoading && onLoading();
    } else if (activityIsLoaded) {
      onFetch && onFetch();
    } else if (activityCannotBeFetched) {
      onError && onError();
    } else {
      onRemove && onRemove();
    }
  }, [loading, activity]);

  return (
    <>
      {loading && (
        <Grid item>
          <Box className={classes.skeletonCard}>
            <Skeleton height={140} variant="rectangular" />
            {[...Array(4)].map((element, index) => (
              <Skeleton height={30} variant="rectangular" key={index} />
            ))}
          </Box>
        </Grid>
      )}
      {activityIsLoaded && (
        <Grid item>
          <Activity
            activity={activity}
            showSentiments={false}
          />
        </Grid>
      )}
    </>
  );
};

// noinspection JSUnusedGlobalSymbols
export default ActivityPreview;
