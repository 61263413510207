import React, { useContext } from "react";
import RailsForm from "../RailsForm";
import Button from "../../buttons/Button";
import { RoutesContext } from "../../Document";

const LogInButtonForm = ({ className }: { className: string }) => {
  const { authPath } = useContext(RoutesContext);
  return <RailsForm action={authPath as string} withChangeDetection={false}>
    <Button
      type="submit"
      className={className}
      data-test-id="headerLogInButton"
    >
      Log in
    </Button>
  </RailsForm>;
};

export default LogInButtonForm;
