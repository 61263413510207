import React from "react";
import { DateOptionType } from "../../../shared/TripObjectTypes";
import DateOption from "../../../shared/DateOption";
import getMostPopularDateOptions from "../../../../services/getMostPopularDateOptions";

type DateOptionPageProps = {
  dateOption: DateOptionType,
};

const DateOptionPage = ({ dateOption }: DateOptionPageProps) => {
  const mostPopularOptionsIds = getMostPopularDateOptions(Array(dateOption)).map(option => option.id)

  return (
    <>
      <DateOption
        currentUserVote={dateOption.currentUserVote}
        dateOptionPath={dateOption.dateOptionPath}
        endDate={dateOption.endDate}
        endDateWeekday={dateOption.endDateWeekday}
        endDateYear={dateOption.endDateYear}
        isChosen={dateOption.isChosenDate}
        isMostPopular={mostPopularOptionsIds.includes(dateOption.id)}
        isPast={dateOption.isPast}
        key={dateOption.id}
        numNights={dateOption.numNights}
        showActions
        startDate={dateOption.startDate}
        startDateWeekday={dateOption.startDateWeekday}
        startDateYear={dateOption.startDateYear}
        travelersVotes={dateOption.travelersVotes}
        showMissingVoteAlert={dateOption.isCurrentUserVoteRequired}
      />
    </>
  );
};

export default DateOptionPage;
