import React, { useContext } from "react";
import { Grid } from "@mui/material";
import CustomChip from '../../../../buttons/CustomChip';
import * as routes from '../../../../../routes';
import { ItineraryDateType } from "../../../../shared/TripObjectTypes";
import { SegmentContext } from "../../../../Document";

type ItineraryDatesChipBarProps = {
  itineraryDates: Array<ItineraryDateType>,
  selectedDate: string,
};

export const ITINERARY_DATE_CHIP_WIDTH = 150;

const {
  tripItineraryDatePath,
  tripItineraryDatesPath,
} = routes;

const ItineraryDatesChipBar = ({
  itineraryDates,
  selectedDate,
}: ItineraryDatesChipBarProps) => {
  const { currentUserId, tripId, isPlanner } = useContext(SegmentContext);

  const active = (chipDate) => {
    return selectedDate === chipDate;
  };
  const eventProperties = {
    method: 'Date Chip',
    planner: isPlanner,
    tripId: tripId,
    userId: currentUserId,
  };

  return (
    <>
      <Grid px={1} item key={'All'}>
        <CustomChip
          active={active('All')}
          href={tripItineraryDatesPath(tripId)}
          eventName='Itinerary Day Permapage loaded'
          eventProperties={eventProperties}
          sx={{ width: 124 }}
        >
          {`All`}
        </CustomChip>
      </Grid>
      {itineraryDates.map((itineraryDate, index) => (
        <Grid px={1} item key={index}>
          <CustomChip
            active={active(itineraryDate.date)}
            href={tripItineraryDatePath(tripId, itineraryDate.id)}
            eventName='Itinerary Day Permapage loaded'
            eventProperties={eventProperties}
          >
            {itineraryDate.formattedDate}
          </CustomChip>
        </Grid>
      ))}
    </>
  )
};

export default ItineraryDatesChipBar;
