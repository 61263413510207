import React, { useCallback } from 'react';
import Button from "../../../../../buttons/Button";
import useSegment from '../../../../../../services/useSegment';
import { addBySmartSearchTripStaysPath } from '../../../../../../routes';

type AddStayButtonProps = {
  disabled: boolean,
  tripId: string,
  labelText: string | React.ReactNode,
  className?: string,
};

const AddStayButton = ({ tripId, disabled, labelText, className }: AddStayButtonProps) => {
  const { trackLinkSegment } = useSegment();

  const addButtonRefs = useCallback((element) => {
    if (element !== null) {
      trackLinkSegment(
          element,
          'Add Stay Link Clicked',
          {
            label: '"Add Stay" button',
          },
      );
    }
  }, []);

  if (disabled) return (<>
    <Button
      data-test-id="add-button"
      disabled
      variant="primary"
    >
      { labelText }
    </Button>
  </>)

  const addStayButton = () => (
    <Button
      className={className}
      href={addBySmartSearchTripStaysPath(tripId)}
      data-test-id='add-stay-button'
      variant="primary"
      ref={addButtonRefs}
    >
      { labelText }
    </Button>

  );

  return addStayButton();
};

export default AddStayButton;
