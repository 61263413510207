type ButtonColorVariant = {
  fill: string,
  border: string,
  color: string,
};

type ButtonColor = {
  default: ButtonColorVariant,
  hover: ButtonColorVariant,
  pressed: ButtonColorVariant,
  focused: ButtonColorVariant,
  disabled: ButtonColorVariant,
};

type ButtonColors = {
  primary: ButtonColor,
  secondary: ButtonColor,
  tertiary: ButtonColor,
  danger: ButtonColor,
};

const buttonColors: ButtonColors = {
  primary: {
    default: {
      fill: "#545DFC",
      border: "#545DFC",
      color: "white",
    },
    hover: {
      fill: "#3B41B0",
      border: "#395db2",
      color: "white",
    },
    pressed: {
      fill: "#3B41B0",
      border: "#3B41B0",
      color: "white",
    },
    focused: {
      fill: "#545DFC",
      border: "#545DFC",
      color: "white",
    },
    disabled: {
      fill: "#b4b9bc",
      border: "#b4b9bc",
      color: "white",
    },
  },
  secondary: {
    default: {
      fill: "white",
      border: "#545DFC",
      color: "#545DFC",
    },
    hover: {
      fill: "white",
      border: "#3B41B0",
      color: "#3B41B0",
    },
    pressed: {
      fill: "white",
      border: "#244599",
      color: "#244599",
    },
    focused: {
      fill: "white",
      border: "#395db2",
      color: "#395db2",
    },
    disabled: {
      fill: "white",
      border: "#98aee5",
      color: "#98aee5",
    },
  },
  tertiary: {
    default: {
      fill: "white",
      border: "#212121",
      color: "#212121",
    },
    hover: {
      fill: "white",
      border: "#353535",
      color: "#353535",
    },
    pressed: {
      fill: "white",
      border: "#272727",
      color: "#272727",
    },
    focused: {
      fill: "white",
      border: "#353535",
      color: "#353535",
    },
    disabled: {
      fill: "white",
      border: "#b4b9bc",
      color: "#b4b9bc",
    },
  },
  danger: {
    default: {
      fill: "#bd2031",
      border: "#bd2031",
      color: "white",
    },
    hover: {
      fill: "#b64a57",
      border: "#b64a57",
      color: "white",
    },
    pressed: {
      fill: "#8f1724",
      border: "#8f1724",
      color: "white",
    },
    focused: {
      fill: "#b64a57",
      border: "#b64a57",
      color: "white",
    },
    disabled: {
      fill: "#b4b9bc",
      border: "#b4b9bc",
      color: "white",
    },
  },
};

export default buttonColors;
