import React from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PollsList from './PollsList';
import AddPollButton from "./AddPollButton";
import { Poll } from './PollView/PollTypes';
import NoSuggestionsYet from "../../../../shared/NoSuggestionsYet";

type OpenedPollsSectionProps = {
  maxOpenPollCount: number,
  polls: Array<Poll>,
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  pollsCount: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '24px',
    color: '#69737A',
  },
}));

const OpenedPollsSection = ({
  maxOpenPollCount,
  polls,
}: OpenedPollsSectionProps) => {
  const classes = useStyles();
  const hasMoreThanThreeOpenedPolls = polls.length >= maxOpenPollCount;
  const hasNoPolls = polls.length === 0;

  return <>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography className={classes.pollsCount}>Open polls ({polls.length})</Typography>
      </Grid>
      <Grid item>
        <AddPollButton hasMoreThanThreeOpenedPolls={hasMoreThanThreeOpenedPolls} />
      </Grid>
    </Grid>
    <Box className={classes.root}>
      {hasNoPolls ?
        <NoSuggestionsYet
          subheading="There are no open polls."
          illustration="polling"
        />
      :
        <PollsList polls={polls} />
      }
    </Box>
  </>;
};

export default OpenedPollsSection;
