import React from 'react';
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import {
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';

export type VotingProgressBarProps = {
  currentVotes: number,
  deadline?: string,
  totalVotes: number,
};

type StyleProps = {
  value: number,
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  bar: {
    borderRadius: 14,
    background: ({ value }) => `linear-gradient(270deg, #545DFC 0%, #509CFF ${value}%)`,
  },
  colorPrimary: {
    backgroundColor: '#DCDCDC',
  },
  deadline: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    '& span': {
      fontWeight: 600,
    },
  },
  root: {
    borderRadius: 14,
    height: 8,
  },
  voted: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

const VotingProgressBar = ({
  currentVotes,
  deadline,
  totalVotes,
}: VotingProgressBarProps) => {
  const value = currentVotes * 100 / totalVotes;
  const classes = useStyles({ value: value });

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container justifyContent="space-between">
        <Grid item>
          <Typography className={classes.voted}>Voted for {currentVotes} of {totalVotes}</Typography>
        </Grid>
        {deadline && (
          <Grid item>
            <Typography className={classes.deadline}>
              Voting closes <span>{deadline}</span>
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <LinearProgress
          classes={{
            bar: classes.bar,
            colorPrimary: classes.colorPrimary,
            root: classes.root,
          }}
          data-test-id="progressBar"
          value={value}
          variant="determinate"
        />
      </Grid>
    </Grid>
  );
};

export default VotingProgressBar;
