import React, { useContext } from "react";
import SectionHeader from "../shared/SectionHeader";
import ActivitiesSectionNavigation, { Counter } from "../shared/ActivitiesSectionNavigation";
import AddActivityButton from "../TripActivitiesPage/ActivitiesSection/AddActivityButton";
import { Box, Grid } from "@mui/material";
import { ActivityType } from "../../../../shared/TripObjectTypes";
import { TripType } from "../../../../shared/TripTypes";
import ActivitiesList from "./ActivitiesList";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import NoSuggestionsYet from "../../../../shared/NoSuggestionsYet";
import ActivitiesFilteringAndSortingBar from "./ActivitiesFilteringAndSortingBar";
import CurrentSortingAndCount from "../shared/CurrentSortingAndCount";
import { ActivitySortingContext } from "../../../../shared/ActivityFilterContext";
import * as routes from '../../../../../routes';
import ActivityAd, { AdContent } from "../../../../shared/ActivityAd";

const { tripActivitiesPath } = routes;

type MainContentProps = {
  activityAd: AdContent,
  activities: ActivityType[],
  tabsCounter: Counter,
  trip: TripType,
};

const MainContent = ({
  activityAd,
  activities,
  tabsCounter,
  trip,
}: MainContentProps) => {
  const addActivityText = useMediaQuery(useTheme().breakpoints.down(470)) ? '+ Add' : '+ Add activity'
  const noSuggestions = !activities.length;
  const { currentSortingLabel } = useContext(ActivitySortingContext);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  return <>
    <SectionHeader
      title='Activities'
      note={`Suggest new activities using the “${addActivityText}” button below.`}
    />
    {isMobile && activityAd && (
      <ActivityAd
        content={activityAd}
        small
        vertical="Activities"
      />
    )}
    <Box mb={2}>
      <ActivitiesSectionNavigation
        active='All'
        counter={tabsCounter}
        tripId={trip.id}
      />
    </Box>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item data-test-id="activities-filter">
        <ActivitiesFilteringAndSortingBar
          disabled={noSuggestions}
          tripId={trip.id}
          formPath={tripActivitiesPath(trip.id)}
        />
      </Grid>
      <Grid item>
        <AddActivityButton disabled={false} tripId={trip.id} />
      </Grid>
    </Grid>
    {!noSuggestions && (
      <Box pt={3}>
        <CurrentSortingAndCount
          count={activities.length}
          sorting={currentSortingLabel}
          type="activity"
        />
      </Box>
    )}
    <ActivitiesList
      activities={activities}
      trip={trip}
    />
    {noSuggestions && (
      <Grid>
        <NoSuggestionsYet
          illustration='activity_fallback'
          heading="Next steps"
          listItems={['Add activities', 'Get your group to comment and weigh in', 'Book the activities you like best']}
        />
      </Grid>
    )}
  </>;
};

export default MainContent;
