import { snakeCase } from "lodash";

export const createFormDataForFiltering = (filters: object, formData = new FormData()): FormData => {
  Object.keys(filters).forEach((key) => {
    const value = filters[key]
    if (value) {
      if(key === 'amenities') {
        formData.append(snakeCase(key), value.join());
      } else if (key === 'propertyTypes') {
        formData.append(snakeCase(key), value.filter((type) => type.checked).map((type) => type.name).join());
      } else if (key === 'instantBook' || key === 'freeCancellation') {
        formData.append(snakeCase(key), value ? 'TRUE' : '');
      } else {
        formData.append(snakeCase(key), value);
      }
    }
  });

  return formData
};

