import React from "react";
import makeStyles from "@mui/styles/makeStyles";

type SentimentIconProps = {
  icon: string,
  index: number,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: "bottom",
    width: theme.spacing(3),
    marginRight: theme.spacing(-3),
    borderRadius: '24px',
    border: '2px solid white',
    backgroundColor: 'white',
  },
  totalVotes: {
    position: 'relative',
    '&:first-child': {
      zIndex: 3,
    },
    '&:nth-child(2)': {
      zIndex: 2,
    },
    '&:not(:last-child)': {
      paddingRight: theme.spacing(2),
    },
    '&:last-child > img': {
      borderTopRightRadius: 0,
    },
  },
}));

const SentimentIcon = ({
  icon,
  index,
}: SentimentIconProps) => {
  const classes = useStyles();

  return <>
    <span className={classes.totalVotes} key={index}>
      <img
        className={classes.icon}
        data-test-id={`${icon}-medal`}
        src={`/assets/illustrations/${icon}.png`}
      />
    </span>
  </>
};

export default SentimentIcon;
