import React, { useCallback } from 'react';
import { Link, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import useSegment from "../../../../../../services/useSegment";
import { addBySmartSearchTripStaysPath } from '../../../../../../routes';

type SearchStayButtonProps = {
  tripId: string,
};

const SearchStaysButton = ({ tripId }: SearchStayButtonProps) => {
  const { trackLinkSegment } = useSegment()

  const refs = useCallback((element) => {
    if (element !== null) {
      trackLinkSegment(
          element,
          'Add Stay Link Clicked',
          {
            label: '"Explore stays" search bar',
          },
      );
    }
  }, []);

  return (
    <Link data-test-id="search-stays-button"
        sx={{color: "rgb(150, 150, 150)", border: "1px solid rgb(217, 216, 216)", width: "100%", padding: "6px", borderRadius: "5px", textDecoration: "none", display: "flex", flexDirection: "row", alignItems: "center" }}
        href={addBySmartSearchTripStaysPath(tripId)}
        ref={refs}
    >
      <SearchIcon sx={{ fontSize: 30 }} />
      <Typography sx={{ marginLeft: 0.5 }}>
        Explore stays
      </Typography>
    </Link>
  );
};

export default SearchStaysButton;
