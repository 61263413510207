import React from 'react';
import pluralize from 'pluralize';
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
} from "@mui/material";
import AvatarGroup from "../../../../shared/AvatarGroup";
import { PollAnswerType } from "../PollsPage/PollView/PollAnswersForm";

type PollAnswerResponsesProps = {
  answer: PollAnswerType,
};

const useStyles = makeStyles((theme) => ({
  responsesCount: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '36px',
    textAlign: 'right',
  },
  responsesInfo: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

const PollAnswerResponses = ({
  answer,
}: PollAnswerResponsesProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      data-test-id='PollAnswerResponses'
      justifyContent="space-between"
      className={classes.responsesInfo}
    >
      <Grid item>
        <AvatarGroup max={3} avatars={answer.responses} />
      </Grid>
      <Grid item>
        <Typography className={classes.responsesCount}>
          {answer.responsesCount} {pluralize('votes', answer.responsesCount)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PollAnswerResponses;
