import React from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExamplePrompt from "./AiPublicSearchEmptyState/ExamplePrompt";

const useStyles = makeStyles((theme) => ({
  examplePrompts: {
    color: '#4A6176',
    fontSize: 18,
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  root: {
    paddingTop: '90px',
  },
  header: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: '50px',
  },
  headerText: {
    color: '#4A6176',
    fontSize: 22,
    fontWeight: 600,
    marginLeft: theme.spacing(2),
    maxWidth: '660px',
    [theme.breakpoints.down(600)]: {
      fontSize: 18,
    },
  },
  listTitle: {
    color: "#7D94A9",
    fontSize: 18,
    fontWeight: 600,
    marginTop: '70px',
    marginBottom: theme.spacing(2),
  },
  listItem: {
    color: "#7D94A9",
    fontSize: 14,
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 1),
    '& > img': {
      paddingTop: '4px',
    },
  },
}));

const prompts = [
  {
    prompt: "Somewhere tropical and fun for spring break with the girls—we need a grill and pool!",
    image: "/assets/example-prompts/avatar-1.svg",
    backgroundColor: "#F4F5FF",
    borderColor: "#C3C6FE",
  },
  {
    prompt: "Seeking the coolest place for the Art Basel squad, not too far from the convention center but away from chaos.",
    image: "/assets/example-prompts/avatar-2.svg",
    backgroundColor: "#FFF6E6",
    borderColor: "#FEE0A6",
  },
  {
    prompt: "Celebrating Mardi Gras and need a place for 10 people in New Orleans with a pool!",
    image: "/assets/example-prompts/avatar-3.svg",
    backgroundColor: "#E9F9F4",
    borderColor: "#A5E5D5",
  },
  {
    prompt: "Looking for a nice property in Viejo, San Juan for our family reunion. Must have air conditioning!",
    image: "/assets/example-prompts/avatar-4.svg",
    backgroundColor: "#ECF4FF",
    borderColor: "#C0DAFF",
  },
  {
    prompt: "A fancy place with parking for when we visit Nashville for the first time during CMA Fest.",
    image: "/assets/example-prompts/avatar-5.svg",
    backgroundColor: "#FFF6E6",
    borderColor: "#FEE0A6",
  },
  {
    prompt: "Brother's wedding! 7-guy Vegas bachelor party. Need a place with pool, grill, near strip.",
    image: "/assets/example-prompts/avatar-6.svg",
    backgroundColor: "#F4F5FF",
    borderColor: "#C3C6FE",
  },
];

const thingsToKnow = [
  "Troupie currently provides recommended filters and location suggestions, explaining the reasons behind its choices.",
  "Troupie understands many languages but might not perfectly interpret/translate.",
  "For accurate pricing, Troupie requires your travel dates.",
  "Troupie aims to help but may occasionally provide inaccurate information. Double-check with other sources before important planning decisions!",
];

const AiPublicSearchEmptyState = () => {
  const classes = useStyles();

  const ListItem = ({ text }) => (
    <Grid item xs={12} md={6} className={classes.listItem}>
      <img src="/assets/star.svg" />
      <Typography ml={1}>
        {text}
      </Typography>
    </Grid>
  );

  return (
    <Box className={classes.root} data-test-id="ai-public-search-empty-state">
      <Box className={classes.header}>
        <img src="/assets/stars-2.svg" />
        <Typography className={classes.headerText}>
          Tell Troupie what you’re looking for and let our powerful AI search engine do the rest!
        </Typography>
      </Box>

      <Typography className={classes.examplePrompts}>Example prompts</Typography>

      <Grid container spacing={4}>
        {prompts.map((prompt, index) => (
          <Grid item xs={12} md={6} key={`example-prompt-${index}`}>
            <ExamplePrompt {...prompt} />
          </Grid>
        ))}
      </Grid>

      <Typography className={classes.listTitle}>Things to know</Typography>

      <Grid container>
        {thingsToKnow.map((item, index) => (
          <ListItem text={item} key={`list-item-${index}`} />
        ))}
      </Grid>
    </Box>
  );
};

export default AiPublicSearchEmptyState;
