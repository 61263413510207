import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DateOptionPrice from "./DateOptionsPricing/DateOptionPrice";
import { DialogBottomOnMobile } from "../../../../../../shared/Dialog";
import {
  DateOptionWithAvailabilityType,
} from "../../../../../../shared/TripObjectTypes";
import Button from "../../../../../../buttons/Button";
import useSegment from "../../../../../../../services/useSegment";
 
type DateOptionsPricingProps = {
  dateOptions: DateOptionWithAvailabilityType[],
  isOpen: boolean,
  onClose: () => void,
  stayHasAutomaticAvailability: boolean,
  stayPath?: string,
  tripId?: string,
  stayId?: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  editButton: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(4),
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2),
    },
  },
  estimatedPriceText: {
    color: '#555e64',
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
}));

const DateOptionsPricing = ({
  dateOptions,
  stayHasAutomaticAvailability,
  isOpen,
  onClose,
  stayPath,
  tripId,
  stayId,
}: DateOptionsPricingProps) => {
  const classes = useStyles();
  const { trackSegment } = useSegment();

  const onEditStayClick = () => {
    trackSegment('Stay Edit Opened', {
      category: 'Stays Pricing',
      property: 'buttonClicked',
      objectId: stayId,
      method: 'tooltip',
    });
  };

  return (
    <DialogBottomOnMobile
      fullWidth
      data-test-id="dialog"
      isOpen={isOpen}
      maxWidth="xs"
      message={<>
        <Grid
          container
          data-test-id="dateOptionsPricing"
          direction="column"
          spacing={2}
        >
          {dateOptions.map(dateOption =>
            <DateOptionPrice
              dateOption={dateOption}
              key={dateOption.id}
            />,
          )}
        </Grid>
        <Grid container>
          <Grid item>
            <Typography className={classes.estimatedPriceText} data-test-id="estimated-price-disclaimer">
              * This cost is an estimate and may not include taxes and/or fees
            </Typography>
          </Grid>
        </Grid>
      </>}
      onClose={() => onClose()}
      title="Pricing and availability"
      >
      {(stayPath && !stayHasAutomaticAvailability) &&
        <Button
          className={classes.editButton}
          data-test-id="edit-stay-button"
          onClick={() => onEditStayClick()}
          href={stayPath + '?focus=price'}
          variant='secondary'
        >
          Edit
        </Button>
      }
    </DialogBottomOnMobile>
  );
};

export default DateOptionsPricing;
