import React from "react";
import { Box, Button, ButtonGroup, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import DeleteResourceConfirmationDialog from "../../../../components/shared/DeleteResourceConfirmationDialog";
import clsx from "clsx";
import buttonColors from "../../../buttons/buttonColors";

type EditDeleteButtonGroupProps = {
  disableDeleteButton: boolean,
  handleOpenDialog: () => void,
  dialogOpen: boolean,
  setDialogOpen: (dialogOpen: boolean) => void,
  snackbarMessage: string,
  onConfirmClick: () => void,
  tooltipText: string,
  deleteMessageBody: React.ReactNode,
  resourceName: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 3,
    alignSelf: 'end',
  },
  button: {
    height: 30,
    [theme.breakpoints.down('sm')]: {
      height: 36,
    },
    '&:hover': {
      borderColor: '#545DFC',
      borderRightColor: ['#545DFC', '!important'],
      background: theme.palette.general.hover,
      zIndex: 4,
    },
  },
  buttonGroup: {
    backgroundColor: buttonColors.primary.default.color,
    border: '1px solid #B4B9BC',
    color: theme.palette.text.primary,
  },
  buttonDisabled: {
    color: `${theme.palette.general.background.disabled} !important`,
    '&:focus': {
      boxShadow: 'none !important',
      outline: '0 !important',
    },
  },
}));

const EditDeleteButtonGroup : React.FC<EditDeleteButtonGroupProps> = ( {
  disableDeleteButton,
  handleOpenDialog,
  dialogOpen,
  setDialogOpen,
  snackbarMessage,
  onConfirmClick,
  tooltipText,
  deleteMessageBody,
  resourceName,
} ) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} data-test-id='traveler-actions-button-group'>
      <ButtonGroup
        classes={{
          groupedOutlined: classes.buttonGroup,
        }}
        disableElevation
        variant="outlined"
        size="small"
      >
        <Button
          data-test-id={'edit-button'}
          className={classes.button}
          disabled={true}
        >
          <EditIcon fontSize="small" />
        </Button>
        <Tooltip
          data-test-id="disabled-delete"
          placement="bottom-start"
          title={disableDeleteButton ? tooltipText : ''}
        >
          <Button
            data-test-id={'delete-button'}
            onClick={!disableDeleteButton ? () => handleOpenDialog() : null}
            className={clsx(classes.button, {
              [classes.buttonDisabled]: disableDeleteButton,
            })}
          >
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <DeleteResourceConfirmationDialog
        isOpen={dialogOpen}
        resourceName={resourceName}
        onClose={() => setDialogOpen(false)}
        onConfirm={onConfirmClick}
        path=''
        message={deleteMessageBody}
        snackbarMessage={snackbarMessage}
      />
    </Box>
  );
}

export default EditDeleteButtonGroup;
