import React, { useState, useContext } from "react";
import SectionHeader from "../shared/SectionHeader";
import ItineraryDatesList from "./ItineraryDatesList";
import { ItineraryType } from "../../../../shared/TripObjectTypes";
import ActionsMenu from '../../../../shared/ActionsMenu';
import * as routes from '../../../../../routes';
import { SegmentContext } from "../../../../Document";
import { track as trackAnalyticsEvent } from "../../../../../services/analytics";

const {
  tripItineraryPath,
  editTripItineraryPath,
} = routes;

type MainContentProps = {
  itinerary: ItineraryType,
};

const MainContent = ({
  itinerary,
}: MainContentProps) => {
  const { currentUserId, tripId, isPlanner } = useContext(SegmentContext);

  const trackDeletionInitiated = () => {
    trackAnalyticsEvent('Itinerary Deletion Initiated',
      {
        tripId: tripId,
        userId: currentUserId,
        planner: isPlanner,
      },
    )
  };

  const trackEditClick = () => {
    trackAnalyticsEvent('Itinerary Edit Dates Initiated',
      {
        tripId: tripId,
        planner: isPlanner,
      },
    )
  };

  const goToEditPage = () => {
    trackEditClick();
    window.location.href = editTripItineraryPath(tripId);
  };

  return <>
    <SectionHeader
      title='Itinerary'
      note='Build an itinerary to start organizing your destinations, stays, and activities by day.'
      rightElement={isPlanner && (
        <ActionsMenu
          deletePath={tripItineraryPath(tripId)}
          dense={false}
          resourceName="itinerary"
          onDeleteClick={trackDeletionInitiated}
          onEditClick={goToEditPage}
        />
      )}
    />
    <ItineraryDatesList itineraryDates={itinerary.itineraryDates} />
  </>;
};

export default MainContent;
