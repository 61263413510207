import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#5C7993',
    fontSize: 12,
    lineHeight: '24px',
  },
}));

const ExcludesPlusOnesMessage = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.root}>*Excludes plus ones</Typography>
  );
};

export default ExcludesPlusOnesMessage;
