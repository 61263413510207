import React, { useCallback } from "react";
import { Link } from "@mui/material";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { makeStyles } from "@mui/styles";
import { trackLink as trackAnalyticsEvent } from '../../../services/analytics';
import * as Routes from "../../../routes"

type ManageInvitesLinkProps = {
  tripId: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  link: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.general.dark,
      textDecoration: 'none',
    },
    marginLeft: 0,
    marginRight: 0,
  },
  text: {
    paddingLeft: theme.spacing(2),
    fontWeight: 600,
    fontSize: 16,
  },
}));

const ManageInvitesLink = ({
  tripId,
}: ManageInvitesLinkProps) => {
  const classes = useStyles();
  const { tripInvitesPath } = Routes;

  const refs = useCallback((element) => {
    if (element !== null) {
      trackAnalyticsEvent(
        element,
        'Manage Invites Opened',
        {
          category: 'Invites',
          tripId: tripId,
        },
      );
    }
  }, []);

  return (
    <section className={classes.root}>
      <Link
        data-test-id='manage-invites-link'
        className={classes.link}
        href={tripInvitesPath(tripId)}
        ref={(el) => refs(el)}
      >
        <SupervisorAccountIcon/>
        <span className={classes.text}>
          Manage invites
        </span>
      </Link>
    </section>
  );
};

export default ManageInvitesLink;
