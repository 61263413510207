import { gql } from "@apollo/client";

export const USER_QUERY = gql`
  query GetUserNotificationCallout($ID: ID!) {
    node (id: $ID){
      id
      ... on User {
        dateSawNotificationCallout
      }
    }
  }
`;

export const GET_USER_DISPLAY_NAME = gql`
  query GetUser($id: ID!) {
    node(id: $id) {
      id
      ... on User {
        displayName
      }
    }
  }
`;
