import React, {useContext} from "react";
import Destination from "../../trips/microsite/DestinationPage/Destination";
import { DestinationType } from "../../../shared/TripObjectTypes";
import { TravelerProps } from "../../../shared/TravelersVotingBox";
import { useQuery } from "@apollo/client";
import SnackbarNotification, {SnackbarNotificationProp} from "../../../shared/SnackbarNotification";
import {TripSiteContext} from "../../../Document";
import SkeletonList from "../../../shared/SkeletonPage/SkeletonList";
import * as Sentry from "@sentry/browser";
import VotingProgressDialog from "../../../shared/VotingProgressDialog";
import { DESTINATIONS_LIST_QUERY } from "../../../../graphql/queries/destinations";
import { RANKING_SIZE } from "../../../../services/RankingSystem";

export interface DestinationGid {
  gid: string,
}

type DestinationPageProps = {
  destination: DestinationGid,
  isPlanner: boolean,
  suggestionsCount,
};

const Snackbar = (props: Partial<SnackbarNotificationProp>) => (
  <SnackbarNotification
    autoHideDuration={props.autoHideDuration ?? null}
    message={props.message}
    open={!!props.message}
    severity={props.severity}
  />
);

const DestinationPage = ({
  isPlanner,
  destination: { gid },
  suggestionsCount,
}: DestinationPageProps) => {
  const { tripGid } = useContext(TripSiteContext)
  const { data, loading, error } = useQuery(
    DESTINATIONS_LIST_QUERY, {
      variables: {
        tripId: tripGid,
      },
    },
  );
  const [displayDialog, setDisplayDialog] = React.useState(false);

  if (loading) return <SkeletonList itemLength={1} />

  if (error) {
    Sentry.captureException(error)
    return <>
      <Snackbar
        message='Sorry, something went wrong loading this destination. Please try again.'
        severity='error'
      />
      <SkeletonList itemLength={1} />
    </>
  }

  const destinations: DestinationType[] = data.destinations;
  const votedCount = destinations.reduce((count, destination) => (destination.currentUserRanking !== null ? count + 1 : count), 0);
  const voteCompleted = votedCount === destinations.length || votedCount === RANKING_SIZE;
  const destination = destinations.find(destination => destination.id === gid);

  const travelers: TravelerProps[] = data.travelers.map(traveler => ({
    ...traveler,
    missingVotesCount: traveler.destinationMissingVotesCount,
    name: traveler.displayName,
  }))

  return (
    <>
      <Destination
        destination={destination}
        displayActions
        hideVotes={false}
        isClickable={false}
        isPlanner={isPlanner}
        showActions
        travelers={travelers}
        openVotingProgress={() => setDisplayDialog(true)}
        suggestionsCount={suggestionsCount}
        showVotingProgressBanner
      />
      <VotingProgressDialog
        isOpen={displayDialog}
        closeDialog={() => setDisplayDialog(false)}
        totalVotes={destinations.length}
        votedCount={votedCount}
        vertical="Destinations"
        voteCompleted={voteCompleted}
      />
    </>
  );
};

export default DestinationPage;
