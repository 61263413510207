import React, { useState } from "react";
import Box from "@mui/material/Box";
import CardGroupCarousel from '../../../../shared/CardGroupCarousel';
import CardGroupCarouselContext from '../../../../shared/CardGroupCarousel/Context';
import {
  PreviousStepButton,
  NextStepButton,
} from '../../../../shared/CardGroupCarousel/CarouselStepButton';
import { DateOptionType, StayType } from '../../../../shared/TripObjectTypes';

type Item = {
  card: DateOptionType[] | StayType[],
  renderCardFunc: (tripObject) => React.ReactNode,
};

type CardsOverviewProps = {
  items: Item[],
};

const CardsOverview = ({
  items,
}: CardsOverviewProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [maxStep, setMaxStep] = useState<number>(0);
  const showStepButtons = items.length > 1;

  return (
    <CardGroupCarouselContext.Provider value={{
      currentStep,
      maxStep,
      setCurrentStep,
      setMaxStep,
    }}>
      <Box position="relative" mb={3}>
        {showStepButtons && (
          <Box position="absolute" top="50%" left={0}>
            <PreviousStepButton />
          </Box>
        )}
        <Box px={4}>
          <CardGroupCarousel items={items} />
        </Box>
        {showStepButtons && (
          <Box position="absolute" top="50%" right={0}>
            <NextStepButton />
          </Box>
        )}
      </Box>
    </CardGroupCarouselContext.Provider>
  );
};

export default CardsOverview;
