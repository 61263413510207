import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Link,
} from '@mui/material';
import { StayNameWithLink } from '../Stay/StayName';
import StayImages from '../Stay/StayImages';
import ProviderName from '../shared/ProviderName';

type StayNanoViewProps = {
  stayPath: string,
  stayUrl: string,
  isDisabled: boolean,
  name: string,
  images?: string[],
  provider?: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  info: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  providerName: {
    paddingBottom: theme.spacing(1),
  },
}));

const StayNanoView = ({
  stayPath,
  stayUrl,
  images,
  isDisabled,
  name,
  provider,
}: StayNanoViewProps) => {
  const classes = useStyles();

  return (
    <Link
      href={stayPath}
      underline="none"
    >
      <Grid container>
        <StayImages
          isExternalListing={false}
          images={images}
          isDisabled={isDisabled}
          shouldRenderCarousel={images.length > 1 && !isDisabled}
          small={true}
        />
        <Grid item className={classes.info}>
          <Grid item className={classes.providerName}>
            <ProviderName>
              {provider}
            </ProviderName>
          </Grid>
            <Grid className={classes.name} item>
              <StayNameWithLink
                fullWidth
                stayUrl={stayUrl}
                isDisabled={isDisabled}
                name={name}
              />
            </Grid>
        </Grid>
      </Grid>
    </Link>
  );
};

export default StayNanoView;
