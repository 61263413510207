import React from "react";
import { hocRailsAction } from "../../../Document";
import {ActivityType, ItineraryDateType} from "../../../shared/TripObjectTypes";
import ItineraryActivitiesChoosingForm from "./NewItineraryActivityEventPage/ItineraryActivitiesChoosingForm";

type NewItineraryActivityEventPageProps = {
  itineraryDate: ItineraryDateType,
  activitiesAvailable: ActivityType[],
};

const NewItineraryActivityEventPage = ({
  itineraryDate,
  activitiesAvailable,
}: NewItineraryActivityEventPageProps) => {
  return (
    <ItineraryActivitiesChoosingForm
      itineraryDate={itineraryDate}
      activitiesAvailable={activitiesAvailable}
    />
  );
};

export default hocRailsAction(NewItineraryActivityEventPage);
