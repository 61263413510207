import { gql } from "@apollo/client";
import { STAY_PAGE_FIELDS, STAY_PERMAPAGE_FIELDS } from "../fragments/stays";

export const STAYS_LIST_QUERY = gql`
  ${STAY_PAGE_FIELDS}
  query StaysList($tripId: ID!, $sortBy: StaySorting, $dateOptionIds: [Uuid!]!) {
    stays(tripId: $tripId, sortBy: $sortBy, dateOptionIds: $dateOptionIds) {
      id
      ...StayPageFields
    }
    travelers(tripId: $tripId) {
      id
      avatarUrl
      displayName
      plusOneCount
      status
      stayRankings
      stayMissingVotesCount
      userId
    }
  }
`

export const STAY_QUERY = gql`
  ${STAY_PERMAPAGE_FIELDS}
  query fetchStay($stayId: ID!) {
    node(id: $stayId) {
      id
      ...StayPermapageFields
    }
  }
`;
