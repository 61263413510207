import { TravelerProps } from "../components/shared/TravelersVotingBox";
import { StayType } from "../components/shared/TripObjectTypes"

type TripObjectRanking = 'stayRankings' | 'destinationRankings';

export type TripObjectRankingProps = {
  travelers: TravelerProps[],
  rankingType: TripObjectRanking,
  objectId: string,
};

export const RANKING_SIZE = 3;

export const getTravelersRankingsForTripObject = ({
  travelers, rankingType, objectId,
}: TripObjectRankingProps) => (
  travelers
    .map((traveler) => ({ ranking: traveler[rankingType].indexOf(objectId), ...traveler }))
    .filter((traveler) => traveler[rankingType].indexOf(objectId) !== -1)
    .sort(({ ranking: a }, { ranking: b }) => a - b)
);

export const getSortedStaysFirstByFinalizedThenByRankings = (stays : StayType[]) => {
  return stays?.slice(0)
    .sort((accA, accB) => {
      if (accA.ranking === undefined) return 1;
      if (accB.ranking === undefined) return -1;
      if (accA.ranking < accB.ranking) return -1;
      if (accA.ranking > accB.ranking) return 1;

      return 0;
    })
    .sort((accA, accB) => {
      if (accA.isChosen) return -1;
      if (accB.isChosen) return 1;

      return 0;
    })
    .sort((accA, accB) => {
      if ((accA.stayBookings?.length || 0) > 0) return -1;
      if ((accB.stayBookings?.length || 0) > 0) return 1;

      return 0;
    });
};
