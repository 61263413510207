type ButtonColorVariant = {
  color: string,
  border?: string,
  fontWeight?: number,
};

type ButtonColor = {
  default: ButtonColorVariant,
  hover: ButtonColorVariant,
  pressed: ButtonColorVariant,
  focused: ButtonColorVariant,
  disabled: ButtonColorVariant,
};

type ButtonColors = {
  primary: ButtonColor,
  secondary: ButtonColor,
  tertiary: ButtonColor,
  link: ButtonColor,
};

const textButtonColors: ButtonColors = {
  primary: {
    default: {
      color: "#545DFC",
      fontWeight: 600,
    },
    hover: {
      color: "#3B41B0",
    },
    pressed: {
      color: "#244599",
    },
    focused: {
      color: "#395db2",
      border: "#80b4fb",
    },
    disabled: {
      color: "#b4b9bc",
    },
  },
  secondary: {
    default: {
      color: "#505050",
      fontWeight: 600,
    },
    hover: {
      color: "#353535",
    },
    pressed: {
      color: "#272727",
    },
    focused: {
      color: "#353535",
      border: "#80b4fb",
    },
    disabled: {
      color: "#b4b9bc",
    },
  },
  tertiary: {
    default: {
      color: "#555E64",
      fontWeight: 200,
    },
    hover: {
      color: "#353535",
    },
    pressed: {
      color: "#272727",
    },
    focused: {
      color: "#353535",
      border: "#80b4fb",
    },
    disabled: {
      color: "#b4b9bc",
    },
  },
  link: {
    default: {
      color: "#5C7993",
      fontWeight: 600,
    },
    hover: {
      color: "#353535",
    },
    pressed: {
      color: "#272727",
    },
    focused: {
      color: "#353535",
    },
    disabled: {
      color: "#b4b9bc",
    },
  },
};

export default textButtonColors;
