import React, { useCallback, useContext, useEffect, useReducer } from "react";
import ListingMarker from "./ListingMarker";
import MapView from "./../../../shared/MapView/MapView";
import { updatePosition } from "../../../../services/mapPosition";
import SearchCheckBox from "./SearchCheckBox";
import debounce from 'lodash/debounce';
import { ListingsContext } from "../StaysSearch";
import { ListingsMapContext } from "../../../../services/RenderWithOrWithoutSidebar";
import { ExternalListingProps } from "../../../shared/TripObjectTypes";
import {Grid, useMediaQuery} from "@mui/material";
import Button from "../../../buttons/Button";
import useExpandMapEventRef from "../../../../services/segmentEvents/useExpandMapEventRef";
import {useTheme} from "@mui/styles";
import {aiSearchType} from "./Filters";
import { searchLocation } from "../../../../services/windowLocation";

type ListingsMapViewProps = {
  latitude?: number,
  listings: ExternalListingProps[],
  longitude?: number,
  radius: number,
  listView?: boolean,
  onExpandMapClick?: () => void,
  sidebar?: boolean,
  aiSearch?: boolean,
  aiSearchType?: aiSearchType,
};

const reducer = (state, action) => {
  const setState = (props) => Object.assign({}, state, props);

  switch (action.type) {
    case 'setMap':
      return setState({ map: action.map });
    case 'loadListings':
      return setState({ activeMarker: null, isLoadingListings: true });
    case 'loadFinished':
      return setState({ isLoadingListings: false });
    case 'setMarker':
      return setState({ activeMarker: action.activeMarker });
    case 'unsetMarker':
      return setState({ activeMarker: null });
    case 'setSearchMovingMap':
      return setState({ isLoadingListings: action.checked, searchMovingMap: action.checked });
    default:
      throw new Error();
  }
};

const ListingsMapView = ({
  radius,
  listView,
  listings,
  onExpandMapClick,
  sidebar = false,
  aiSearch,
  aiSearchType,
}: ListingsMapViewProps) => {
  const { latitude, longitude, onMapPositionChange } = useContext(ListingsContext);
  const { hoveredListing } = useContext(ListingsMapContext);
  const locationName = searchLocation()
  const expandMapButtonRef = useExpandMapEventRef('search page', 'expand map', locationName, aiSearchType)
  const isMobileApp = useMediaQuery(useTheme().breakpoints.down('sm'));
  const initialState = {
    activeMarker: null,
    isLoadingListings: false,
    map: null,
    searchMovingMap: true,
  };

  const [{activeMarker, isLoadingListings, map, searchMovingMap}, dispatch] = useReducer(reducer, initialState);

  const fetchListings = () => {
    if (map) {
      const center = map.getCenter();
      const bounds = map.getBounds();
      const visiblePoints = [
        bounds.getNorthEast(),
        bounds.getSouthWest(),
        new google.maps.LatLng(center.lat(), bounds.getNorthEast().lng()),
        new google.maps.LatLng(center.lat(), bounds.getSouthWest().lng()),
      ];
      const visibleDistances = visiblePoints.map(point => google.maps.geometry.spherical.computeDistanceBetween(center, point));
      const radius = Math.min(...visibleDistances);

      onMapPositionChange(center.lat(), center.lng(), radius);
    }
  };

  useEffect(() => {
    dispatch({ type: 'loadFinished' });
  }, [listings]);

  const debouncedFetchListings = useCallback(
    debounce(() => fetchListings(), 500),
    [map],
  );

  const handleMarkerClick = (listingId, lat, lng) => {
    dispatch({ type: 'setMarker', activeMarker: listingId })
    if(sidebar || isMobileApp) updatePosition(lat, lng, map);
    map.setOptions({ disableDoubleClickZoom: true });
  };

  const handleMarkerClickOut = () => {
    dispatch({ type: 'unsetMarker' })
    map.setOptions({ disableDoubleClickZoom: false });
  };

  const handleMapChange = () => {
    if (searchMovingMap) {
      dispatch({ type: 'loadListings' });
    }
  };

  const handleSearchCheckBoxChange = (checked) => {
    dispatch({ type: 'setSearchMovingMap', checked: checked });
  };

  useEffect(() => {
    if (isLoadingListings && searchMovingMap && !activeMarker && !aiSearch) {
      debouncedFetchListings();
    }
  }, [isLoadingListings]);

  const listingLabel = (listing: ExternalListingProps) => (
    listing.pricing.price ? `${listing.pricing.currency}${listing.pricing.price}` : '-'
  );

  useEffect(() => {
    if (hoveredListing && map) {
      const newLatlng = new google.maps.LatLng(hoveredListing.latitude, hoveredListing.longitude);
      map.panTo(newLatlng);
    }
  }, [hoveredListing]);

  const markers = listings?.filter((listing) => listing.longitude && listing.latitude) || [];

  return (
    <>
      {!listView && (
        <SearchCheckBox
          hideSearch={aiSearch}
          isChecked={searchMovingMap}
          isLoading={isLoadingListings}
          onChange={(checked) => handleSearchCheckBoxChange(checked)}
        />
      )}
      <MapView
        latitude={latitude}
        longitude={longitude}
        markers={markers}
        radius={radius}
        renderMarker={(map, listing) =>
          <ListingMarker
            active={activeMarker === listing.id}
            alreadyOnTrip={listing.alreadyOnTrip}
            key={`marker-${listing.id}`}
            label={listingLabel(listing)}
            lat={listing.latitude}
            listing={listing}
            lng={listing.longitude}
            onClick={() => handleMarkerClick(listing.id, listing.latitude, listing.longitude)}
            onInfoWindowClickOut={() => handleMarkerClickOut()}
            sidebar={sidebar}
          />
        }
        onMapLoaded={(map) => dispatch({ type: 'setMap', map })}
        onDragEnd={() => handleMapChange()}
        onZoomChange={() => handleMapChange()}
      />
      {listView && (
        <Grid
          item
          pt={2}
        >
          <Button
            data-test-id='expand-map'
            variant="secondary"
            fullWidth
            onClick={onExpandMapClick}
            ref={expandMapButtonRef}
          >
            Expand map
          </Button>
        </Grid>
      )}
    </>
  );
};

export default ListingsMapView;
