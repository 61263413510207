import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Typography,
} from "@mui/material";
import Tooltip from "../../../../../shared/Tooltip";

const useStyles = makeStyles( theme => ({
  icon: {
    height: '16px',
    width: '16px',
  },
  tooltip: {
    padding: theme.spacing(1),
  },
  tooltipTitle: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '21px',
    marginLeft: theme.spacing(0.5),
  },
  tooltipDescription: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
  },
}));

const LiveStayIcon : React.FC = () => {
  const classes = useStyles();

  const renderVerifiedIcon = () => (
    <img src="/assets/verified_icon.svg" className={classes.icon} />
  );

  const tooltipText = () => {
    return (
      <Grid container direction="column" spacing={1} className={classes.tooltip}>
        <Grid item container alignItems="center">
          {renderVerifiedIcon()}
          <Typography className={classes.tooltipTitle}>Troupe Preferred Partner</Typography>
        </Grid>
        <Grid item className={classes.tooltipDescription}>
          Price, rating, and availability for this stay will
          automatically update if things change.
        </Grid>
      </Grid>
    );
  };

  return (
    <Tooltip title={tooltipText()}>
      {renderVerifiedIcon()}
    </Tooltip>
  );
};

export default LiveStayIcon;
