import React from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Errors from '../../../shared/Errors';
import ModalForm from '../../../shared/ModalForm';
import { hocRailsAction } from "../../../Document";
import { tripItineraryDatesPath, tripItineraryDatePath } from "../../../../routes";
import { ItineraryDateType } from "../../../shared/TripObjectTypes";
import InputWithCharacterCounter from "../../../shared/InputWithCharacterCounter";

export type EditItineraryDateFormProps = {
  backTo: 'itineraryDatesList' | 'itineraryDatePermapage',
  itineraryDate: ItineraryDateType,
  errors?: string[],
  tripId: string,
};

const useStyles = makeStyles((theme) => ({
  dayNumber: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '32px',
  },
  date: {
    color: theme.palette.text.primary,
    fontSize: 16,
    lineHeight: '24px',
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 600,
  },
}));

const EditItineraryDateForm = ({
  backTo,
  itineraryDate,
  errors,
  tripId,
}: EditItineraryDateFormProps) => {
  const classes = useStyles();

  const closePath = {
    itineraryDatesList: tripItineraryDatesPath(tripId),
    itineraryDatePermapage: tripItineraryDatePath(tripId, itineraryDate.id),
  };

  return (
    <ModalForm
      closePath={closePath[backTo]}
      formActionPath={tripItineraryDatePath(tripId, itineraryDate.id)}
      formMethod="patch"
      saveButtonLabel='Save'
      subtitle="Add a description of what you'll be doing, so travelers know what to expect."
      title='Edit day'
      withChangeDetection
    >
      {errors && (
        <Box mb={2}>
          <Errors errors={errors} />
        </Box>
      )}
      <Grid container direction="column" my={3}>
        <Grid item>
          <Typography className={classes.dayNumber}>
            Day {itineraryDate.dayNumber}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.date}>
            {itineraryDate.formattedDate}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography className={classes.label}>Add a description</Typography>
        </Grid>
        <Grid item>
          <input type="hidden" value={document.referrer} name="refererPage" />
          <InputWithCharacterCounter
            defaultValue={itineraryDate?.description}
            name="itinerary_date[description]"
            noLabel
            focused
            fullWidth
            characterLimit={100}
            placeholder="What’s the plan for the day?"
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(EditItineraryDateForm);
