import React from "react";
import { makeStyles } from "@mui/styles";
import ActivityNanoView from './ActivityNanoView';
import InfoWindow from "../../../../../shared/MapView/InfoWindow";
import { ActivityType } from "../../../../../shared/TripObjectTypes";
import useMapClickEventRef from "../../../../../../services/segmentEvents/useMapClickEventRef";

type ActivityMarkerProps = {
  activity: ActivityType,
  active: boolean,
  lat: number,
  lng: number,
  onMarkerClick: () => void,
  onMarkerClickOut: () => void,
};

const useStyles = makeStyles(({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const ActivityMarker = ({
  activity,
  active,
  // eslint-disable-next-line no-unused-vars
  lat,
  // eslint-disable-next-line no-unused-vars
  lng,
  onMarkerClickOut,
  onMarkerClick,
}: ActivityMarkerProps) => {
  const classes = useStyles();
  const mapClickRef = useMapClickEventRef('activities', activity.id);

  return (
    <>
      {active &&
        <InfoWindow onClickOut={onMarkerClickOut} position='absolute'>
          <ActivityNanoView
            activityPath={activity.tripObjectPath}
            image={activity.image}
            name={activity.name}
          />
        </InfoWindow>
      }
      <img
        className={classes.root}
        alt='activity_marker'
        data-test-id="activity-marker"
        height="30px"
        onClick={onMarkerClick}
        src="/assets/location_marker.png"
        width="22px"
        ref={mapClickRef}
      />
    </>
  );
};

export default ActivityMarker;
