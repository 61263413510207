import React, { useContext, useEffect, useState } from 'react';
import {
  createApolloClient,
  ApolloProvider,
} from '../services/apolloClient';
import { AlertColor } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import RailsForm from "./shared/RailsForm";
import SnackbarNotification from './shared/SnackbarNotification';
import * as routes from "../routes";
import TroupeApp from "../services/TroupeApp";
import theme from "./theme";
import Confetti from "./shared/Confetti";
import { gql } from "@apollo/client";
export { theme };
import { ENABLED_ALL_PUSH } from "../constants/queryParams";
import * as Sentry from '@sentry/browser';

export const GlobalContext = React.createContext<GlobalContextType>({});

export const MaxPrice = 1000000000000

export type GlobalContextType = {
  isMobileApp?: boolean,
  isTestEnv?: boolean,
  setSnackbar?: (message: string, duration?: number, severity?: AlertColor) => void,
  activateConfetti?: () => void,
  shareStay?: boolean,
  troupieAlternatives?: boolean,
  rationaleTroupieApi?: boolean,
};

export enum TripObjectState {
  Suggesting = 'suggesting',
  Voting = 'voting',
  Finalized = 'finalized',
}

export type TripSiteContextType = {
  currentUserId?: string,
  datesState?: TripObjectState,
  destinationsState?: TripObjectState,
  staysState?: TripObjectState,
  tripId?: string,
  tripGid?: string,
  currentUserGid?: string,
  travelerId?: string,
};

export const TripSiteContext = React.createContext<TripSiteContextType>({});

type FormsContextType = {
  csrfToken: string,
  triggerLogIn: (() => void) | null,
};

type SegmentContextType = {
  isPlanner?: boolean | null,
  currentUserId?: string,
  tripId?: string,
}

export const FormsContext = React.createContext<FormsContextType>({
  csrfToken: '',
  triggerLogIn: null,
});

export const SegmentContext = React.createContext<SegmentContextType>({
  isPlanner: null,
  currentUserId: undefined,
  tripId: undefined,
});

type RoutesContextType = {
  authPath?: string,
};

export const RoutesContext = React.createContext<RoutesContextType>({});

export const ImageUploaderContext = React.createContext({
  fileStackAPIKey: '',
});

const TriggerableLogInForm = React.forwardRef((_props, ref) => {
  const { authPath } = useContext(RoutesContext)

  return (
    <RailsForm
      ref={ref}
      action={authPath as string}
      withChangeDetection={false}
    />
  )
});

const csrfToken = document
  .querySelector('meta[name=csrf-token]')
  ?.getAttribute('content');

const graphqlPath = routes.graphqlPath();
const client = createApolloClient({
  graphqlPath,
  csrfToken,
});

export const hocRailsAction = (Page) => (props) => {
  const {
    csrfToken,
    globalContextProps,
    imageUploaderProps,
    routesFromRails,
    snackbarNotification,
    datesState,
    destinationsState,
    staysState,
    isPlanner,
    currentUserId,
    tripId,
    tripGid,
    currentUserGid,
    travelerId,
    ...restOfProps
  } = props;

  useEffect(() => {
    TroupeApp.User?.identify(currentUserId, props.currentUserIdOneSignalAuthHash);
    currentUserId && Sentry.setUser({ id: currentUserId });
  }, [])

  const ENABLE_ALL_PUSH = gql`
    mutation UserAllPushNotificationEnable {
      userAllPushNotificationEnable(input:{}) {
        errors
      }
    }
  `

   const enableAllPushNotifications = async () => await client.mutate({ mutation: ENABLE_ALL_PUSH })

   const handleMessageEvent = async (event: MessageEvent) => {
    try {
      const jsonData = (typeof event.data === "string") && JSON.parse(event.data)
      if (jsonData["targetOrigin"] === "troupeApp") {
        if (jsonData.status === "authorized") {
          const { data: { userAllPushNotificationEnable }} = await enableAllPushNotifications()
          if (userAllPushNotificationEnable.errors.length === 0) {
            const urlSearchParams = new URLSearchParams(window.location.search)
            urlSearchParams.set(ENABLED_ALL_PUSH, 'true')
            window.location.search = urlSearchParams.toString()
          } else {
            setSnackbar({ message: 'Something went wrong, please try again.', duration: 5000, severity: 'error' })
          }
        } else {
          window.location.reload()
        }
      }
    } catch {
      //no-op
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    return () => window.removeEventListener('message', handleMessageEvent);
  }, []);

  const triggerableLogInFormRef = React.useRef<HTMLFormElement>();
  const formsContextValue = {
    csrfToken,
    triggerLogIn: () => triggerableLogInFormRef.current?.submit(),
  };

  type SnackbarMessage = {
    message: string,
    duration?: number,
    severity?: AlertColor,
  }

  const snackbarInitialState = {
    message: snackbarNotification?.message || '',
    duration: snackbarNotification?.duration,
    severity: snackbarNotification?.severity,
  };
  const [snackbar, setSnackbar] = useState<SnackbarMessage>(snackbarInitialState);

  const [confettiActive, setConfettiActive] = useState<boolean>(false);

  function activateConfetti() {
    setConfettiActive(true)
    setTimeout(() => {
      setConfettiActive(false)
    }, 2000)
  }

  const globalContextValue = Object.assign(
    {},
    globalContextProps,
    {
      setSnackbar: (message: string, duration?: number, severity?: AlertColor) => setSnackbar({ message, duration, severity }),
      activateConfetti: activateConfetti,
    },
  );

  const tripSiteContextValue = {
    currentUserId,
    datesState,
    destinationsState,
    staysState,
    tripId,
    tripGid,
    currentUserGid,
    travelerId,
  }

  const segmentContextValue = {
    currentUserId,
    isPlanner,
    tripId,
  }

  const pageProps = Object.assign(restOfProps, { currentUserId, isPlanner, tripId })

  return (
    <StyledEngineProvider injectFirst>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RoutesContext.Provider value={routesFromRails}>
            <GlobalContext.Provider value={globalContextValue}>
              <TripSiteContext.Provider value={tripSiteContextValue}>
                <FormsContext.Provider value={formsContextValue}>
                  <ImageUploaderContext.Provider value={imageUploaderProps}>
                    <TriggerableLogInForm ref={triggerableLogInFormRef} />
                    <SegmentContext.Provider value={segmentContextValue}>
                      {!!snackbar.message && (
                        <SnackbarNotification
                          autoHideDuration={snackbar.duration}
                          message={snackbar.message}
                          onClose={() => setSnackbar({ message: '', duration: undefined, severity: undefined })}
                          open={!!snackbar.message}
                          severity={snackbar.severity}
                        />
                      )}
                      <Confetti active={confettiActive && !globalContextValue.isTestEnv} />
                      <Page {...pageProps} />
                    </SegmentContext.Provider>
                  </ImageUploaderContext.Provider>
                </FormsContext.Provider>
              </TripSiteContext.Provider>
            </GlobalContext.Provider>
          </RoutesContext.Provider>
        </ThemeProvider>
      </ApolloProvider>
    </StyledEngineProvider>
  );
};
