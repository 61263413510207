import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Button from "../../../../buttons/Chip";
import RailsForm from "../../../../shared/RailsForm";
import Tooltip from "../../../../shared/Tooltip";

export type SentimentType = {
  icon?: string,
  iconExt?: string,
  hideOnVoting?: boolean,
  label: string,
  shortLabel?: string,
  showIconWithoutLabel?: boolean,
  value: number | string,
};

type SentimentButtonsProps = {
  activityId?: string,
  currentUserSentiment: number | string,
  disabled?: boolean,
  formMethod?: string,
  sentimentParamName?: string,
  sentimentSettingPath: string,
  sentiments: SentimentType[],
  shouldRedirectTo?: string,
  testId?: string,
  page?: string,
};

export const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginLeft: theme.spacing(2),
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  indexGridItem: {
    [theme.breakpoints.up(1450)]: {
      marginRight: '8px',
      marginLeft: '8px',
    },
    [theme.breakpoints.between('lg', 1450)]: {
      marginRight: ['auto', "!important"],
      marginLeft: ['auto', "!important"],
    },
  },
  emoji: {
    height: '26px',
    [theme.breakpoints.down('md')]: {
      fontSize: [24, '!important'],
      lineHeight: 1,
      verticalAlign: 'center',
    },
  },
  icon:{
    height: 'inherit',
  },
  disabledEmoji: {
    filter: 'grayscale(100%)',
  },
  button: {
    zIndex: 2,
    height: '32px !important',
    width: '64px',
  },
  sentimentButtonsContainer: {
    justifyContent: 'space-evenly',
    maxWidth: '100%',
    flexWrap: 'nowrap',
  },
  toolTip: {
    zIndex: 4,
  },
}));

const SentimentButtons = ({
  activityId,
  currentUserSentiment,
  disabled,
  formMethod = 'post',
  sentimentParamName,
  sentimentSettingPath,
  sentiments,
  shouldRedirectTo,
  testId,
  page,
}: SentimentButtonsProps) => {
  const classes = useStyles();
  const defaultIconExt = 'svg';

  const isVotedSentiment = (value, currentUserSentiment) => {
    return value === currentUserSentiment;
  };

  return (
    <Grid
      container
      justifyContent="center"
      data-test-id={testId}
      className={classes.sentimentButtonsContainer}
    >
      {sentiments.map(({ icon, iconExt, hideOnVoting, label, shortLabel, showIconWithoutLabel, value }) => (
        (!hideOnVoting &&
          <Grid item className={clsx(classes.gridItem, {[classes.indexGridItem]: page !== 'index'})} key={label}>
            <RailsForm
              action={sentimentSettingPath}
              method={isVotedSentiment(value,currentUserSentiment) ? 'DELETE' : formMethod}
              withChangeDetection={false}
            >
              <input
                type="hidden"
                name={sentimentParamName || "sentiment"}
                value={value}
              />
              {shouldRedirectTo &&
                <input
                  type="hidden"
                  name="should_redirect_to"
                  value={shouldRedirectTo}
                />
              }
              <Tooltip
                title={label}
                className={classes.toolTip}
                data-test-id={`${label}-sentiment-tooltip`}
              >
                <div>
                  <Button
                    active={isVotedSentiment(value, currentUserSentiment)}
                    className={classes.button}
                    data-test-id={`${value}-button`}
                    disabled={disabled}
                    type="submit"
                  >
                    <span className={clsx(
                      classes.emoji,
                      { [classes.disabledEmoji]: disabled },
                    )}>
                      {<img
                        className={classes.icon}
                        data-test-id={icon}
                        src={`/assets/illustrations/${icon}.${iconExt || defaultIconExt}`}
                      />}
                    </span>
                    {shortLabel && page === 'index' && (
                      <Hidden only={['xl', 'lg', 'xs']}>
                        {' '}{shortLabel}
                      </Hidden>
                    )}
                  </Button>
                </div>
              </Tooltip>
            </RailsForm>
          </Grid>
        )
      ))}
    </Grid>
  );
}

export default SentimentButtons;
