import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
} from "@mui/material";
import actionsMap from "../../../../../../services/tripUpdatePageActions";
import TimeStamp from "../../../../../shared/TimeStamp";
import { TripUpdateEventType } from "../../TripUpdatesPage";
import {ItineraryDateType} from "../../../../../shared/TripObjectTypes";

export type TripEvent = {
  authorAvatarUrl: string,
  authorName: string,
  id: string,
  isUnviewedEvent: boolean,
  timestamp: number,
  tripId: string,
  eventObject: any,
  eventObjectType: string,
  eventType: TripUpdateEventType,
  metadata: { sentiment: string, rank: number },
};

export type ActivityEntryActionProps = {
  tripEvent: TripEvent,
};

const useStyles = makeStyles((theme) => ({
  eventTimestamp: {
    fontSize: 14,
    color: '#697379',
    fontWeight: 500,
  },
  eventTitle: {
    fontSize: 16,
  },
  username: {
    fontWeight: 600,
  },
}));

const TripUpdateEntryAction = ({
  tripEvent,
}: ActivityEntryActionProps) => {
  const classes = useStyles();

  return (
    <>
      <TimeStamp className={classes.eventTimestamp} date={tripEvent.timestamp}/>
      <Typography component="div" className={classes.eventTitle}>
        <Box className={classes.username} display="inline" data-test-id='authorName'>
          {tripEvent.authorName + " "}
        </Box>
        <Box display="inline" data-test-id='tripEventAction'>
          {actionsMap[tripEvent.eventType](tripEvent)}
        </Box>
      </Typography>
    </>
  )
};

export default TripUpdateEntryAction;
