import React, { useContext, useEffect, useReducer } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormContext } from "./RailsForm";
import clsx from "clsx";
import CameraDropdownButton from "./ImageUploader/CameraDropdownButton";
import ImagePicker from "./ImageUploader/ImagePicker";

const reducer = (state, action) => {
  const setState = (props) => Object.assign({}, state, props);

  switch (action.type) {
    case 'changeImage':
      return setState({ currentImage: action.currentImage, pickerOpened: false, restoreDefault: false });
    case 'restoreDefault':
      return setState({ currentImage: action.currentImage, pickerOpened: false, restoreDefault: true });
    case 'togglePicker':
      return setState({ ...state, pickerOpened: action.pickerOpened });
    default:
      throw new Error();
  };
};

type ImageUploaderProps = {
  defaultImage: string,
  inputName: string,
  forcedAspectRatio?: number,
  image?: string,
  isFullSize?: boolean,
  onImageUpload?: (string) => void,
  onImageRestoreDefault?: () => void,
  originalImages?: string[],
};

const useStyles = makeStyles((theme) => ({
  cameraDropdownIcon: {
    zIndex: 1,
    position: 'absolute',
    padding: theme.spacing(2),
    width: theme.spacing(3),
    height: theme.spacing(3),
    right: '36px',
  },
  cameraIconWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  defaultImage: {
    height: '300px !important',
    width: '100%',
    size: '200px',
  },
  image: {
    objectFit: 'cover',
    border: "1px solid #e6e7e8",
    boxShadow: '0 1px 2px 0 rgba(60, 64, 67, 0.3)',
    height: 200,
    width: '100%',
  },
  instruction: {
    fontSize: 14,
  },
  fullSize: {
    height: '100%',
    width: '100%',
  },
}));

const ImageUploader = ({
  defaultImage,
  forcedAspectRatio,
  image,
  inputName,
  isFullSize,
  onImageUpload,
  onImageRestoreDefault,
  originalImages = [],
}: ImageUploaderProps) => {
  const classes = useStyles();

  const initialState = {
    currentImage: image || defaultImage,
    pickerOpened: false,
    restoreDefault: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentImage, restoreDefault, pickerOpened } = state;
  const { onFormChange } = useContext(FormContext);

  const onImageChange = (url) => {
    if (currentImage !== url) {
      dispatch({type: 'changeImage', currentImage: url});
      if (onImageUpload) onImageUpload(url);
    }
  };

  const onRestoreDefault = () => {
    dispatch({type: 'restoreDefault', currentImage: imageToRestore})
    if (onImageRestoreDefault) onImageRestoreDefault();
  }

  useEffect(() => {
    if (currentImage && currentImage !== image) onFormChange();
  }, [currentImage]);

  const imageToRestore = originalImages.length > 0 ? originalImages[0] : defaultImage;
  const canRestoreDefault = (!!imageToRestore) && (currentImage !== imageToRestore);

  return (
    <Grid item>
      <Grid item
        className={classes.cameraIconWrapper}
        container
        justifyContent="center"
        onDragOver={() => dispatch({ type: 'togglePicker', pickerOpened: true })}
      >
        <CameraDropdownButton
          className={classes.cameraDropdownIcon}
          onChoosePhoto={() => dispatch({ type: 'togglePicker', pickerOpened: true })}
          onRestoreDefault={onRestoreDefault}
          canRestoreDefault={canRestoreDefault}
        />
        <img
          src={currentImage}
          className={clsx(
            classes.image,
            {[classes.fullSize]: isFullSize},
            {[classes.defaultImage]: ((imageToRestore === defaultImage) && (defaultImage === currentImage))},
          )}
          data-test-id="image-preview"
        />
      </Grid>
      <Typography
        color="textSecondary"
        className={classes.instruction}
        textAlign='center'
      >
        Click the camera icon to add a custom photo
      </Typography>
      <ImagePicker
        forcedAspectRatio={forcedAspectRatio}
        onClose={() => dispatch({ type: 'togglePicker', pickerOpened: false })}
        onSuccess={(url) => onImageChange(url)}
        opened={pickerOpened}
      />
      <input
        data-test-id='image-input'
        name={inputName}
        value={(currentImage === defaultImage || restoreDefault) ? '' : currentImage}
        type="hidden"
      />
    </Grid>
  );
};

export default ImageUploader;
