import React from "react";
import DrawerBase, { DrawerProps } from '@mui/material/Drawer';
import { makeStyles } from "@mui/styles";

const useDrawerClasses = makeStyles((theme) => ({
  paper: {
    '@supports (padding-top: env(safe-area-inset-top))': {
      padding: 'env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left)',
    },
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    },
    [theme.breakpoints.up("sm")]: {
      width: 'min(50%, 475px)',
    },
  },
}));

const Drawer = ({
  children,
  anchor,
  open,
  onClose,
  ...otherProps
}: DrawerProps) => {
  return (
    <DrawerBase
      anchor={anchor}
      classes={useDrawerClasses()}
      onClose={onClose}
      open={open}
      {...otherProps}
    >
      {children}
    </DrawerBase>
  );
}

export default Drawer;
