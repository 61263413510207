import React, {FC} from "react";
import { Box } from "@mui/material";

interface SelectedWrapperProps {
  children: any,
  selected: boolean,
}

const SelectedWrapper: FC<SelectedWrapperProps> = ({ children, selected }) => {

  const sx = {
    borderRadius: "8px",
    border: '2px solid rgba(255,255,255,0)',
  }
  if (selected) {
    sx["border"] = '2px solid #80b4fb'
  }

  return (
    <Box sx={sx} data-test-id={selected ? "selected-wrapper" : "unselected-wrapper"}>
      {children}
    </Box>
  )
}

export default SelectedWrapper
