import React, { useContext, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, TextField, Button, Stack, useMediaQuery } from '@mui/material';
import { GlobalContext, SegmentContext, TripSiteContext } from '../../../Document';
import { TravelersType } from '../TravelersByStatusType';
import { Done, Close } from '@mui/icons-material';
import { gql, useMutation } from "@apollo/client";
import { getOperationName } from "../../../../graphql/utilities";
import { TRAVELERS_QUERY } from "../../../../graphql/queries/travelers";
import useSegment from "../../../../services/useSegment";
import Errors from "../../../shared/Errors";
import clsx from "clsx";

type CreatePlusOneFormProps = {
  traveler: TravelersType,
  autoFocus?: boolean,
  onPlusOneFormClose?: () => void,
}

export const PLUS_ONE_CREATE = gql`
  mutation TravelerPlusOneCreate($tripId: ID!, $firstName: String!, $lastName: String, $travelerId: ID!) {
    travelerPlusOneCreate(input: {
      tripId: $tripId,
      firstName: $firstName,
      lastName: $lastName,
      travelerId: $travelerId
    }) {
      travelerPlusOne {
        firstName
        lastName
        id
      }
      errors
    }
  }
`

const useStyles = makeStyles(() => ({
  input: {
    minWidth: '115px',
    height: '24px',
    padding: '8px 12px',
  },
  mobileInput: {
    minWidth: '50px',
  },
  doneButton: {
    minWidth: '42px',
    width: '42px',
  },
  cancelButton: {
    minWidth: '42px',
    width: '42px',
    color: '#4A6176',
  },
}));

const CreatePlusOneForm : React.FC<CreatePlusOneFormProps> = ({
  traveler,
  autoFocus = true,
  onPlusOneFormClose,
}) => {
  const classes = useStyles();
  const { setSnackbar } = useContext(GlobalContext);
  const { tripId } = React.useContext(SegmentContext);
  const { tripGid } = React.useContext(TripSiteContext);
  const [errors, setErrors] = useState<string[]>([]);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const textRef = useRef<HTMLFormElement>();

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const { trackSegment } = useSegment();

  const onCancelClick = () => {
    onPlusOneFormClose && onPlusOneFormClose()
    trackSegment('Add Plus 1 Dismissed',
      {
        tripId: tripId,
        page: 'Manage Travelers',
      },
    );
  };

  const onPlusOneSave = (firstName: string) => {
    trackSegment('Plus 1 Added',
      {
        tripId: tripId,
        page: 'Manage Travelers',
        parentStatus: traveler.status,
        firstName: firstName,
      },
    );
  };

  const [
    createPlusOneMutation,
    { loading },
  ] = useMutation(PLUS_ONE_CREATE, {refetchQueries: [getOperationName(TRAVELERS_QUERY)]});

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createPlusOneMutation({
      variables: {
        tripId: tripGid,
        firstName: firstName,
        lastName: lastName,
        travelerId: traveler.id,
      },
      onCompleted: ({travelerPlusOneCreate: {
        travelerPlusOne,
        errors,
      }}) => {
        if(travelerPlusOne && errors.length === 0) {
          setSnackbar && setSnackbar('Your plus-one has been added.')
          setFirstName('')
          setLastName('')
          onPlusOneSave(firstName)
          textRef.current.focus();
        } else {
          setErrors(errors);
        }
      },
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item mt={2}>
        <form onSubmit={onFormSubmit} data-test-id="create-plus-one-form">
          <Stack direction="row" spacing={1}>
            <TextField
              id="plus-one-name"
              variant="outlined"
              name="first-name"
              placeholder="First name*"
              autoFocus={autoFocus}
              inputRef={textRef}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              inputProps={{className: clsx(classes.input, {
                [classes.mobileInput]: isMobile,
              })}}
              required
            />
            <TextField
              id="plus-one-last-name"
              variant="outlined"
              name="last-name"
              placeholder="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              inputProps={{className: classes.input}}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={!firstName || loading}
              className={classes.doneButton}
              data-test-id="submit-plus-one"
            >
              <Done/>
            </Button>
            <Button
              className={classes.cancelButton}
              data-test-id="cancel-plus-one"
              onClick={() => {
                setFirstName('');
                setLastName('');
                onCancelClick();
              }}
            >
              <Close />
            </Button>
          </Stack>
        </form>
        {errors && (
          <Grid pt={2} item>
            <Errors errors={errors} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CreatePlusOneForm;
