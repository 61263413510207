import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Destination from "../DestinationPage/Destination";
import { AsyncComment, DestinationType, SyncComment } from "../../../../shared/TripObjectTypes";
import {
  tripDestinationPath,
  tripDestinationCommentPath,
  tripDestinationCommentsPath,
  tripDestinationCommentsURL,
} from "../../../../../routes";
import { TravelerProps } from "../../../../shared/TravelersVotingBox";
import MostRecentCommentBox from "../shared/MostRecentCommentBox";
import VotingProgressDialog from "../../../../shared/VotingProgressDialog";
import {
  useVotingProgressReducer,
  OPEN_PROGRESS_DIALOG,
  CLOSE_PROGRESS_DIALOG,
} from "../../../../../services/useVotingProgressReducer";

type DestinationsListProps = {
  destinations: DestinationType[],
  isPlanner: boolean,
  travelers: TravelerProps[],
  tripId: string,
};

type MostRecentCommentProps = {
  comment: AsyncComment | null,
  commentsCount: number,
  showForm: boolean,
  destinationId: string,
  tripId: string,
};

type CommentPathProps = {} | {
  commentPath: string,
  commentsUrl: string,
};

const MostRecentComment = ({
  comment,
  commentsCount,
  showForm,
  destinationId,
  tripId,
}: MostRecentCommentProps) => {
  const pathProps: CommentPathProps = !comment ? {} : {
    commentPath: tripDestinationCommentPath(tripId, destinationId, comment.uuid),
    commentsUrl: tripDestinationCommentsURL(tripId, destinationId, { anchor: `comment_${comment.uuid}` }),
  }
  const { author, ...mostRecentComment } = comment ?? {}

  return (
    <Grid item>
      <MostRecentCommentBox
        comment={comment && {
          ...mostRecentComment,
          ...pathProps,
          id: comment.uuid,
          gid: comment.id,
          authorAvatarUrl: author.avatarUrl,
          authorName: author.displayName,
          timestamp: parseInt(comment.timestamp, 10),
        } as SyncComment}
        commentFormPath={tripDestinationCommentsPath(tripId, destinationId)}
        commentsPath={tripDestinationPath(tripId, destinationId)}
        showCommentForm={showForm}
        showLoadMoreCommentsButton={commentsCount > 1}
        tripId={tripId}
      />
    </Grid>
  )}

const DestinationsList = ({
  destinations,
  isPlanner,
  travelers,
  tripId,
}: DestinationsListProps) => {
  const { displayDialog, voteCompleted, dispatch } = useVotingProgressReducer();

  const votedCount = destinations.reduce((count, destination) => (destination.currentUserRanking !== null ? count + 1 : count), 0);

  const sortedDestinationsByRanking = [...destinations].sort((a, b) => {
    if(a.ranking === null || b.ranking === null) return 1;
    return (a.ranking > b.ranking ? 1 : -1);
  });

  const initialValue = Array(sortedDestinationsByRanking.length).fill(false);
  const [
    showCommentForm,
    setShowCommentForm,
  ] = useState(initialValue);

  const toggleCommentFormVisibility = (index) => {
    const newArray = Array.from(showCommentForm);
    newArray[index] = !showCommentForm[index];
    setShowCommentForm(newArray);
  };

  useEffect(() => {
    const { hash } = window.location;
    const elementToScroll = document.getElementById(hash?.replace("#", ""));

    if (!elementToScroll) return;

    window.scrollTo({
      top: elementToScroll.getBoundingClientRect().top,
    });
  }, []);

  const votingProgress = (voteCompleted: boolean) => {
    dispatch({ type: OPEN_PROGRESS_DIALOG, voteCompleted });
  };

  return <>
    {sortedDestinationsByRanking.map((destination, index) => (
      <Box mt={3} mb={4} key={destination.id}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Destination
              destination={destination}
              ranking={destination.ranking}
              isClickable
              isPlanner={isPlanner}
              hideVotes={false}
              linkToComment={tripDestinationPath(tripId, destination.id)}
              showActions
              suggestionsCount={sortedDestinationsByRanking.length}
              travelers={travelers}
              setDestinationComment={() => toggleCommentFormVisibility(index)}
              showCommentButton
              showVotingProgressBanner
              displayActions
              page={'index'}
              openVotingProgress={votingProgress}
            />
          </Grid>
          {(destination.mostRecentComment || showCommentForm[index]) && (
            <Grid item>
              <MostRecentComment
                comment={destination.mostRecentComment as AsyncComment}
                commentsCount={destination.commentsCount}
                showForm={showCommentForm[index]}
                destinationId={destination.uuid}
                tripId={tripId}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    ))}
    <VotingProgressDialog
      isOpen={displayDialog}
      closeDialog={() => dispatch({ type: CLOSE_PROGRESS_DIALOG, displayDialog: false })}
      totalVotes={destinations.length}
      votedCount={votedCount}
      vertical="Destinations"
      voteCompleted={voteCompleted}
    />
  </>;
};

export default DestinationsList;
