import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Typography,
} from "@mui/material";
import pluralize from "pluralize";
import Button from "../../../../buttons/Button";;
import ConfirmationDialog from "../../../../shared/ConfirmationDialog";
import { TypeTripObjectType } from "../../../../shared/TripObjectTypes";
import Tooltip from "../../../../shared/Tooltip";
import SkipVotingButton from "./SuggestingPhaseButtons/SkipVotingButton"
import * as routes from '../../../../../routes';

const {
  newTripChosenDateOptionPath,
  newTripChosenDestinationPath,
  newTripChosenStayPath,
} = routes;

type SuggestingPhaseButtonsProps = {
  hasSuggestions: boolean,
  isPlanner: boolean,
  transitionToVotingPath: string,
  tripId: string,
  tripObjectType: TypeTripObjectType,
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: 360,
    },
  },
  header: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '32px',
    marginBottom: theme.spacing(1),
  },
  subheader:{
    paddingLeft: theme.spacing(2),
  },
}));

const SuggestingPhaseButtons = ({
  hasSuggestions,
  isPlanner,
  transitionToVotingPath,
  tripId,
  tripObjectType,
}: SuggestingPhaseButtonsProps) => {
  const classes = useStyles();
  const votingCopy = 'Start collecting votes from travelers you’ve invited to the trip.';
  const suggestionsCopy = `Suggest some ${tripObjectType} before opening up voting.`;
  const header = hasSuggestions ? "open voting" : `add ${tripObjectType}`;
  const subheader = hasSuggestions ? votingCopy : suggestionsCopy;
  const disabledSkipVotingButton = !hasSuggestions || !isPlanner;
  const finalizePath = {
    dates: newTripChosenDateOptionPath(tripId),
    destinations: newTripChosenDestinationPath(tripId),
    stays: newTripChosenStayPath(tripId),
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Grid container className={classes.root} direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.header}>
          Next step: {header}
        </Typography>
      </Grid>
      <Typography className={classes.subheader}>{subheader}</Typography>
      <Grid item>
        <ConfirmationDialog
          actionMethod="PATCH"
          actionPath={transitionToVotingPath}
          actionText="Open voting"
          disabled={!hasSuggestions}
          isOpen={dialogOpen}
          message={`Opening voting will notify your group it's time to vote on ${tripObjectType}. Ready to continue?`}
          onClose={() => setDialogOpen(false)}
          title="Open voting"
        />
        <Tooltip data-test-id='open-voting-tooltip' title={isPlanner ? '' : `Only planners can open voting`}>
          <Grid item>
            <Button
              aria-label='open-voting-button'
              disabled={!hasSuggestions || !isPlanner}
              data-test-id='open-voting-button'
              fullWidth
              onClick={() => setDialogOpen(true)}
            >
              Open voting
            </Button>
          </Grid>
        </Tooltip>
      </Grid>
      <Grid item>
        <SkipVotingButton
          disabled={disabledSkipVotingButton}
          tripObjectType={pluralize.singular(tripObjectType)}
          finalizePath={finalizePath[tripObjectType]}
          isPlanner={isPlanner}
        />
      </Grid>
    </Grid>
  );
};

export default SuggestingPhaseButtons;
