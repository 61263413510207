import React from "react";
import Grid from "@mui/material/Grid";
import Stay from "../HousingPage/Stay";
import { StayType } from "../../../../shared/TripObjectTypes";
import BookedWrapper from "../HousingPage/BookedWrapper";
import SelectedWrapper from "../HousingPage/SelectedWrapper";

type FirstStepProps = {
  onStaySelect: (stay: StayType) => void,
  selectedIds: string[],
  stays: StayType[],
};

const FirstStep: React.FC<FirstStepProps> = ({
  onStaySelect,
  selectedIds,
  stays,
}) => {

  return (
    <Grid container direction="column" spacing={2}>
      {stays.map((stay, index) => {
        return (
        <Grid item key={index}>
          <SelectedWrapper selected={selectedIds.indexOf(stay.id) > -1}>
            <BookedWrapper booked={stay.stayBookings?.length > 0} onClick={() => onStaySelect(stay)}>
              <Stay
                displaySelectButton
                hideAddPricingLink
                hideButtons
                hideCommentsLink
                hideDateOptionsAvailability
                isClickable
                isNameClickable={false}
                isSelected={selectedIds.indexOf(stay.id) > -1}
                onSelect={() => onStaySelect(stay)}
                onStayClick={() => onStaySelect(stay)}
                stay={stay}
              />
            </BookedWrapper>
          </SelectedWrapper>
        </Grid>
      )})}
    </Grid>
  );
};

export default FirstStep;
