import React from "react";
import StayImage from "./StayImages/StayImage";
import ImagesCarousel from "../../../../../shared/ImagesCarousel";
import {useStayAffiliateClickEventRef} from "../../../../../../services/segmentEvents/useStayAffiliateClickEvent";

type StayImagesProps = {
  images: string[],
  isDisabled?: boolean,
  isExternalListing?: boolean,
  linkHref?: string,
  shouldRenderCarousel?: boolean,
  small?: boolean,
};

const StayImages = ({
  images,
  isDisabled,
  isExternalListing,
  linkHref,
  shouldRenderCarousel,
  small,
}: StayImagesProps) => {
  const segmentRef = useStayAffiliateClickEventRef({
    listingUrl: linkHref,
    targetClicked: 'image',
  });

  return (
    shouldRenderCarousel ? (
      <ImagesCarousel
        changeImageSize={isExternalListing}
        images={images}
        linkHref={linkHref}
        small={small}
        segmentRef={isExternalListing && segmentRef}
      />
    ) : (
      <StayImage
        disabled={isDisabled}
        imageUrl={images[0]}
        small={small}
      />
    )
  );
};

export default StayImages;
