import { gql } from "@apollo/client";

export const GET_REACTIONS = gql`
  query GetReactions($reactableId: ID!) {
    node(id: $reactableId) {
      id
      ... on Comment {
        reactions {
          count
          emoji
          humanReadableName
          hasCurrentUserSelected
          reactable {
            ... on Node {
              id
            }
          }
          users {
            id
            displayName
          }
        }
      }
      ... on Broadcast {
        reactions {
          count
          emoji
          humanReadableName
          hasCurrentUserSelected
          reactable {
            ... on Node {
              id
            }
          }
          users {
            id
            displayName
          }
        }
      }
    }
  }
`;

export const ADD_REACTION = gql`
  mutation AddReaction($reactableId: ID!, $tripId: ID!, $emoji: String!, $humanReadableName: String!) {
    reactionAdd(input: { reactableId: $reactableId, emoji: $emoji, tripId: $tripId, humanReadableName: $humanReadableName }) {
      errors
      reaction {
        users {
          displayName
          id
        }
        count
        emoji
        hasCurrentUserSelected
        humanReadableName
        reactable {
          ... on Node {
            id
          }
        }
      }
    }
  }
`;

export const REMOVE_REACTION = gql`
  mutation RemoveReaction($reactableId: ID!, $tripId: ID!, $emoji: String!) {
    reactionRemove(input: { reactableId: $reactableId, emoji: $emoji, tripId: $tripId }) {
      errors
    }
  }
`;
