import { Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import { DialogBottomOnMobile } from '../../../../shared/Dialog';
import { TravelerProps } from '../../../../shared/TravelersVotingBox';
import { humanizedStatus } from '../../../../../helpers/statusToHuman';
import { makeStyles } from '@mui/styles';
import Avatar from '../../../../avatars/Avatar';
import { SegmentContext } from '../../../../Document';
import { useDismissDialogEvent } from '../../../../../services/segmentEvents/useTravelerPlusOnesDialogEvents';
import ManageTripButton from '../../../../shared/SiteHeader/ManageTripButton';
import pluralize from 'pluralize';

type TravelerDetailsDialogProps = {
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  traveler?: TravelerProps
};

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
    color: 'white',
    borderRadius: '5px',
  },
  travelersGroup: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  travelersPlusOnes: {
    fontSize: '16px',
    marginBottom: theme.spacing(1),
  },
  travelersNote: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#4A6176',
    marginBottom: theme.spacing(3),
  },
  plusOneName: {
    fontSize: '14px',
    paddingTop: theme.spacing(0.5),
  },
  manageButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  plusOnesContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    overflowX: 'auto',
  },
}));

const chipBackgroundColors = {
  going: '#1EBE95',
  interested: '#FBA500',
  cannot_go: '#C94A4A',
}

const getFirstName = (name: string) : string => name?.split(" ")[0];

const TravelerDetailsDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  traveler,
}: TravelerDetailsDialogProps) => {
  const { currentUserId, tripId } = React.useContext(SegmentContext);
  const classes = useStyles();

  const dismissDialogEvent = useDismissDialogEvent();

  const onClose = () => {
    setIsDialogOpen(false);
    dismissDialogEvent(traveler)
  };

  const renderTitle = () => (
    <Stack flexDirection="row" alignItems="center">
      <Typography
        className={classes.travelersGroup}
      >
        {`${getFirstName(traveler?.name)}'s group`}
      </Typography>
      <Chip
        className={classes.chip}
        sx={{ backgroundColor: chipBackgroundColors[traveler?.status] }}
        size="small"
        label={humanizedStatus(traveler?.status)}
      />
    </Stack>
  );

  const renderManageButton = () => (
    isTravelerCurrentUser && (
      <ManageTripButton
        tripId={tripId}
        segmentMethod='Plus 1 modal'
        disabled={false}
        collapseOnMobile={false}
        className={classes.manageButton}
      />
    )
  );

  const isTravelerCurrentUser = currentUserId === traveler?.userId;
  const plusOneText = `${pluralize('plus-one', traveler?.plusOnes.length)}`;
  const currentUserTitle = `You're traveling with ${traveler?.plusOnes.length} ${plusOneText}.`;
  const anotherUserTitle = `${getFirstName(traveler?.name)} is traveling with ${traveler?.plusOnes.length} ${plusOneText}.`
  const titleText = isTravelerCurrentUser ? currentUserTitle : anotherUserTitle;

  const renderMessage = () => (
    <Stack>
      <Typography className={classes.travelersPlusOnes}>
        {titleText}
      </Typography>
      <Typography className={classes.travelersNote}>
        *Note: Plus-ones are for visibility only
      </Typography>
      <div className={classes.plusOnesContainer}>
        {
          traveler?.plusOnes.map((plusOne) => (
            <Stack key={plusOne.displayName} mr={3} alignItems='center'>
              <Avatar>
                <img src='/assets/plus_one_avatar.svg' width="40" height="40" />
              </Avatar>
              <Typography className={classes.plusOneName}>
                {plusOne.displayName}
              </Typography>
            </ Stack>
          ))
        }
      </div>
      {renderManageButton()}
    </Stack>
  );

  return(
    <DialogBottomOnMobile
      title={renderTitle()}
      fullWidth
      maxWidth="xs"
      message={renderMessage()}
      isOpen={isDialogOpen}
      onClose={onClose}
    />
  )
};

export default TravelerDetailsDialog;
