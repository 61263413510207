import React from "react";
import SkeletonCard from './SkeletonCard';

type SkeletonListProps = {
  itemLength: number,
}
const SkeletonList = ({
  itemLength = 1,
}: SkeletonListProps) => {

  return <>
    {Array.from({ length: itemLength }, (_, index) => {
      return <React.Fragment key={`skeleton-card-${index}`}>
          <SkeletonCard />
      </React.Fragment>
    })}
  </>
}

export default SkeletonList;
