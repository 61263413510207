import React from "react";
import clsx from "clsx";
import {
  makeStyles,
  Typography,
} from "@mui/material";

type TruncatedTypographyProps = {
  text: string,
  className?: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const TruncatedTypography = ({
  text,
  className,
 }: TruncatedTypographyProps) => {
  const classes = useStyles();

  return (
    <Typography className={clsx(classes.root, className)}>
      {text}
    </Typography>
  );
};

export default TruncatedTypography;
