import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import pluralize from "pluralize";
import dayjs from "dayjs";
import { Pagination } from "./ListingsBox";
import {useTrackSERPEvent} from "../../../../services/segmentEvents/useSERPEvents";
import {aiSearchType} from "./Filters";

type AiSearchPaginationTextProps= {
  arrival?: string,
  departure?: string,
  locationName?: string,
  minGuests?: string,
  pagination: Pagination,
  triggerFilterDialog: (value: boolean) => void,
  aiSearchType?: aiSearchType,
};

const useStyles = makeStyles(() => ({
  text: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

const AiSearchPaginationText = ({
  arrival,
  departure,
  locationName,
  minGuests,
  pagination,
  triggerFilterDialog,
  aiSearchType,
}: AiSearchPaginationTextProps) => {
  const classes = useStyles();
  const { trackSERPEvent } = useTrackSERPEvent();
  const onFilterClick = (text) => {
    triggerFilterDialog(true)
    trackSERPEvent('Search Criteria Drawer Initiated', {
      location: aiSearchType,
      entry: text,
    });
  }

  const totalListingsCountLabel = () => (
    pagination.count >= 300 ? '300+' : `${pagination.count}`
  );

  const renderDates = () => {
    const arrivalDate = arrival ? dayjs(arrival) : null;
    const departureDate = departure ? dayjs(departure) : null;

    if (arrivalDate && departureDate) {
      const isSameMonth = arrivalDate.month() === departureDate.month();
      const formatDate = (date, format) => date.format(format);

      const datesText = isSameMonth ? ` ${formatDate(arrivalDate, 'MMMM D')} - ${formatDate(departureDate, 'D')}`
        : ` ${formatDate(arrivalDate, 'MMMM D')} - ${formatDate(departureDate, 'MMMM D')}`

      return (
        <span>
          from
          <span
            onClick={() => onFilterClick('Troupie Dates')}
            className={classes.text}
          >
            {datesText}
          </span>
        </span>
      )
    }

    return '';
  };

  const renderListingsCount = () => {
    const isSinglePage = !pagination.next && !pagination.prev;
    if (isSinglePage) {
      return <span>{pagination.count}</span>;
    } else {
      return (
        <span>
          <strong>{`${pagination.from} - ${pagination.to}`}</strong> {` of ${totalListingsCountLabel()} `}
        </span>
      );
    }
  };

  const renderWithDialogClick = (text, tracker) => {
    return (
      <span
        data-test-id={`clickable-text-for-filter-${tracker.split(' ').join('-')}`}
        className={classes.text}
        onClick={() => onFilterClick(tracker)}
      >
        {text}
      </span>
    )
  }
  const guestsText = `${minGuests} ${pluralize('guest', Number(minGuests))}`

  return(
    <span style={{ marginTop: '28px' }} data-test-id='pagination-text'>
      {renderListingsCount()} in {renderWithDialogClick(locationName, 'Troupie Destination')} for {renderWithDialogClick(guestsText, 'Troupie Guest Count')} {renderDates()}
    </span>
  )
};

export default AiSearchPaginationText;
