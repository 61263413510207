import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { castArray } from 'lodash'
import serializeForm from "../../../../../../services/serializeForm";
import FilteringAndSortingBar, { Sorting } from "../../shared/FilteringAndSortingBar";

export type DateOption = {
  endDate: string,
  endDateYear: string,
  id: string,
  startDate: string,
  startDateYear: string,
};

export type StaySorting = |
  'recently_added' |
  'bedrooms_count' |
  'rating' |
  'price_per_night_descending' |
  'price_per_night_ascending';

type SortAndFilterProps = {
  sortBy: StaySorting,
  dateOptionIds: string[],
}
type HousingFilteringAndSortingBarProps = {
  currentFiltersCount: number,
  currentFilters: {
    dateOptionIds: string[],
  },
  currentSorting: StaySorting,
  dateOptions: DateOption[],
  disabled?: boolean,
  onSortAndFilterChange: (_: SortAndFilterProps) => void,
  sortings: Sorting<StaySorting>[],
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    fontWeight: 300,
    transform: 'scale(1)',
  },
  dateOptions: {
    paddingTop: theme.spacing(1),
  },
}));

const HousingFilteringAndSortingBar = ({
  currentFiltersCount,
  currentFilters,
  currentSorting,
  dateOptions,
  disabled,
  onSortAndFilterChange,
  sortings,
}: HousingFilteringAndSortingBarProps) => {
  const classes = useStyles();

  const [
    appliedFiltersCount,
    setAppliedFiltersCount,
  ] = useState(currentFiltersCount);

  const onDrawerClose: () => void = () => {
    setAppliedFiltersCount(currentFiltersCount);
  };

  const handleDateOptionCheck = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setAppliedFiltersCount(appliedFiltersCount + 1);
    } else {
      setAppliedFiltersCount(appliedFiltersCount - 1);
    }
  };

  return (
    <FilteringAndSortingBar
      currentFiltersCount={appliedFiltersCount}
      currentSorting={currentSorting}
      disabled={disabled}
      onSubmit={(e) => {
        e.preventDefault();

        const values = serializeForm(e.target as HTMLFormElement) as SortAndFilterProps
        // NOTE: a single-checked value does not get turned into an array.
        if(typeof(values.dateOptionIds) === 'string') {
          values.dateOptionIds = castArray<string>(values.dateOptionIds)
        }
        onSortAndFilterChange(values)
        return false
      }}
      onClose={onDrawerClose}
      sortByRadioGroupName="sortBy"
      vertical="Stays"
      sortings={sortings}
      filters={
        <>
          <Grid item>
            <Typography variant="h2">
              Filters
            </Typography>
          </Grid>
          <Grid item data-test-id="stayFilteringByDateOptions">
            <InputLabel className={classes.label}>
              Available dates
            </InputLabel>
            <Box className={classes.dateOptions}>
              {currentFilters && dateOptions.map(dateOption => (
                <Box key={dateOption.id} data-test-id="dateOptionCheckbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={currentFilters.dateOptionIds.includes(dateOption.id)}
                        color="primary"
                        value={dateOption.id}
                        name="dateOptionIds"
                      />
                    }
                    label={`${dateOption.startDate}, ${dateOption.startDateYear} - ${dateOption.endDate}, ${dateOption.endDateYear}`}
                    onChange={handleDateOptionCheck}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }
    />
  );
};

export default HousingFilteringAndSortingBar;
