import React, { useContext } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import clsx from "clsx";
import { makeStyles, DefaultTheme } from "@mui/styles";
import buttonColors from "./textButtonColors";
import { FormContext } from "../shared/RailsForm";

type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

export type TextButtonProps = {
  children: React.ReactNode,
  className?: string,
  detachedFromForm?: boolean,
  disabled?: boolean,
  href?: string,
  onClick?: () => void,
  type?: 'button' | 'submit' | 'reset',
  variant?: ButtonVariant,
};

const useStyles = makeStyles<DefaultTheme, { variant: ButtonVariant }>((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: ({ variant }) => buttonColors[variant].default.fontWeight,
    color: ({ variant }) => buttonColors[variant].default.color,
    '&:hover': {
      color: ({ variant }) => buttonColors[variant].hover.color,
    },
    '&:focus': {
      borderColor: ({ variant }) => buttonColors[variant].focused.border,
      color: ({ variant }) => buttonColors[variant].focused.color,
      border: '1px solid',
      borderRadius: theme.spacing(0.75),
      boxShadow: '0 0 0 1px #80b4fb',
    },
    '&:active': {
      color: ({ variant }) => buttonColors[variant].pressed.color,
      border: 'none',
      boxShadow: 'none',
    },
  },
  disabled: {
    color: ({ variant }) => `${buttonColors[variant].disabled.color} !important`,
    boxShadow: 'none !important',
  },
}));

const TextButton = ({
  children,
  className,
  detachedFromForm,
  disabled,
  variant = 'primary',
  ...buttonProps
}: TextButtonProps) => {
  const classes = useStyles({ variant });
  const formContext = useContext(FormContext);
  const disabledForm = formContext.disabled && !buttonProps.href && !detachedFromForm;

  return (
    <ButtonBase
      className={clsx(
        classes.root,
        {
          [classes.disabled]: disabled || disabledForm,
        },
        className,
      )}
      disabled={disabled || disabledForm}
      {...buttonProps}
    >
      {children}
    </ButtonBase>
  );
};

export default TextButton;
