const compareArrays = (array1: any[], array2: any[]): boolean => {
  const first = [array1].flat().sort();
  const second = [array2].flat().sort();

  if (first.length !== second.length) {
    return false;
  }

  return first.every((value, index) => value === second[index]);
};

const compareElements = (element1: any, element2: any): boolean => {
  if (Array.isArray(element1) || Array.isArray(element2)) {
    return compareArrays(element1, element2);
  }

  if (element1 !== element2) {
    return false;
  }

  return true;
};

const compareObjects = (object1: {[k: string]: any}, object2: {[k: string]: any}): boolean => {
  const compareObjectProperty = (property: string): boolean => {
    return compareElements(object1[property], object2[property]);
  };

  for(let property in object1) {
    if (!compareObjectProperty(property)) {
      return false;
    }
  }

  for(let property in object2) {
    if (!compareObjectProperty(property)) {
      return false;
    }
  }

  return true;
};

export default compareObjects;
