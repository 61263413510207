import React from 'react';
import {
  Box,
  Grid,
  Link,
} from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  lowerDiv: {
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
  lowerFooter: {
    height: theme.spacing(6),
    backgroundColor: '#303742',
    textDecoration: 'none',
    color:  '#ffffff',
    fontSize: '14px',

    '@supports (padding-bottom: env(safe-area-inset-bottom))': {
      height: `calc(env(safe-area-inset-bottom) + ${theme.spacing(6)})`,
      paddingBottom: 'env(safe-area-inset-bottom)',
      paddingRight: 'env(safe-area-inset-right)',
      paddingLeft: 'env(safe-area-inset-left)',
    },
  },
  linkTag: {
    color: '#ffffff',
    '&:hover': {
      color: '#A6C0FF',
      textDecoration: 'none',
    },
  },
  bottomText: {
    maxWidth: '262px',
  },
}));

const SiteFooter = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear().toString();

  return (
    <Box>
      <div className={classes.lowerFooter} >
        <Grid container direction="row" className={classes.lowerDiv}>
          <Grid className={classes.bottomText} container justifyContent="space-between">
            <Grid item >© {currentYear} Troupe</Grid>
            <Grid item>
              <Link className={classes.linkTag} href="https://troupe.com/privacy" target="_blank">Privacy Policy</Link>
            </Grid>
            <Grid item>
              <Link className={classes.linkTag} href="https://troupe.com/terms" target="_blank">Terms</Link>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default SiteFooter;
