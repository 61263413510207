import React, { useContext, useEffect } from "react";
import { Theme } from "@mui/material/styles";
import clsx from "clsx";
import reactStringReplace from "react-string-replace";
import flowRight from "lodash/flowRight";
import { Alert, AlertTitle, ButtonBase, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { hocRailsAction, RoutesContext } from "../../../Document";
import RailsForm from "../../../shared/RailsForm";
import SiteHeader from "../../../shared/SiteHeader";
import NavigationBar, { NavItem } from "../../../trip-page/NavigationBar";
import SiteFooter from "../../../shared/SiteFooter";
import OverviewSubheader, { Invite } from "./OverviewPage/OverviewSubheader";
import { GlobalTrip } from "../../../shared/TripTypes";
import { group as analyticsGroup } from "../../../../services/analytics";

type StyleProps = {
  maxWidth: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  logInLinkForm: {
    display: 'inline',
  },
  logInLink: {
    display: 'inline',
    fontFamily: theme.typography.fontFamily,
    textDecoration: 'underline',
  },
  pageContainer: {
    flex: 1,
    maxWidth: ({maxWidth}) => maxWidth ? theme.spacing(96) : 'auto',
    "& .anchor": {
      position: "relative",
      "& > a": {
        position: "absolute",
        top: `-${theme.spacing(17)}`,
        left: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  fluid: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      paddingLeft: theme.spacing(9),
      paddingRight: theme.spacing(9),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(13.5),
      paddingRight: theme.spacing(13.5),
    },
    [theme.breakpoints.up(1440)]: {
      paddingLeft: theme.spacing(20),
      paddingRight: theme.spacing(20),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(25),
      paddingRight: theme.spacing(25),
    },
  },
  alert: {
    marginTop: theme.spacing(3),
  },
}));

interface WithLayoutProps {
  tripUpdatesNum: number,
  broadcastNum: number,
  currentUserAvatarUrl: string,
  currentUserId?: string,
  infoMessage?: string,
  isUserLoggedIn: boolean,
  hideCoverPhoto?: boolean,
  hideNavBar?: boolean,
  navItems: NavItem[],
  shouldRequestPhoneNumber?: boolean,
  invite: Invite,
  hideOverviewHeader: boolean,
  trip?: GlobalTrip,
}

export const withLayout = (
    Page, { fluid, maxWidth }: { fluid?: boolean, maxWidth?: boolean } = { fluid: false, maxWidth: true },
  ) => (function withLayout(props: WithLayoutProps) {
  const classes = useStyles({ maxWidth });
  const { authPath } = useContext(RoutesContext);
  const { trip } = props;

  useEffect(() => {
    if (trip && trip.travelersCount) {
      analyticsGroup(trip.id, {
        tripId: trip.id,
        createdAt: trip.createdAt,
        name: trip.name,
        travelersCount: trip.travelersCount,
        tripTypes: trip.tripTypes,
      })
    }
  }, [trip])

  return (
    <div className={classes.root}>
      <SiteHeader
        tripUpdatesNum={props.tripUpdatesNum}
        broadcastNum={props.broadcastNum}
        currentUserAvatarUrl={props.currentUserAvatarUrl}
        currentUserId={props.currentUserId}
        isUserPlanner={props.trip?.isPlanner}
        isUserLoggedIn={props.isUserLoggedIn}
        showCoverPhoto={!props.hideCoverPhoto}
        trip={trip}
      />
      {!props.hideOverviewHeader && trip && (
        <span>
          <OverviewSubheader
            invite={props.invite}
            trip={trip}
            currentUserId={props.currentUserId}
          />
        </span>
      )}
      {!props.hideNavBar && (
        <NavigationBar items={props.navItems} />
      )}
      <Container className={clsx(
        classes.pageContainer,
        {
          [classes.fluid]: fluid,
        },
      )}>
        {props.infoMessage && (
          <Alert severity="info" className={classes.alert}>
            <AlertTitle>Heads up</AlertTitle>
            {reactStringReplace(props.infoMessage, /(log in)/g, (match) => (
            <RailsForm action={authPath} className={classes.logInLinkForm} withChangeDetection={false}>
              <ButtonBase type="submit" className={classes.logInLink}>{match}</ButtonBase>
            </RailsForm>
          ))}</Alert>
        )}
        <Page {...props} />
      </Container>
      <SiteFooter />
    </div>
  );
});

export default flowRight(hocRailsAction, withLayout);
