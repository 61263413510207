import React, { useState } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import ConfirmationDialog from "./ConfirmationDialog";
import { track as trackAnalyticsEvent } from '../../services/analytics';
import ShareLinkSection from "./InviteModal/ShareLinkSection";
import AddPlusOneButton from "../buttons/AddPlusOneButton";

type RsvpModalProps = {
  tripId: string,
  tripName: string,
  inviterId: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  titleWrapper: {
    textAlign: 'center',
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: 24,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  description: {
    paddingBottom: theme.spacing(3),
    color: theme.palette.text.primary,
    margin: '0 auto',
  },
  subtitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
}));

const RsvpModal = ({
  tripId,
  tripName,
  inviterId,
}: RsvpModalProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(true);
  const isMobileApp = useMediaQuery(useTheme().breakpoints.down('sm'));

  const trackCloseButtonClickedEventInSegment = () => {
    trackAnalyticsEvent('Invite Success Modal Dismissed', {
      tripId,
      isMobileApp,
      label: 'Done Button',
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
    location.replace(location.pathname);
  };

  const handleCloseButtonClick = () => {
    trackCloseButtonClickedEventInSegment();
    handleClose();
  };

  const displayRSVP = (new URLSearchParams(window.location.search).get('display_rsvp_modal') === 'true')

  return <>
    {displayRSVP && (
      <ConfirmationDialog
        actionText="Done"
        fullWidthButtons
        hideCancelButton
        isOpen={dialogOpen}
        message={
          <Box className={classes.titleWrapper}>
            <Typography className={classes.title}>
              Success! 🍾
            </Typography>
            <Box textAlign="left">
              <Typography className={classes.description}>
                We’ve let the group know you’ve officially joined this trip.
              </Typography>
              <Typography className={classes.subtitle}>
                Want to invite others to join?
              </Typography>
              <ShareLinkSection
                inviterId={inviterId}
                tripName={tripName}
                tripId={tripId}
                source="Success Modal"
              />
              <AddPlusOneButton tripId={tripId} />
            </Box>
          </Box>
        }
        onClose={handleCloseButtonClick}
        onConfirm={handleCloseButtonClick}
      />
    )}
  </>;
};

export default RsvpModal;
