import React from "react";
import withLayout from "./withLayout";
import MainContent from "./ItineraryPage/MainContent";
import SideBarPage from "../../../shared/SideBarPage";
import { TripType } from "../../../shared/TripTypes";

type ItineraryPageProps = {
  trip: TripType,
}
const ItineraryPage = ({
  trip,
}: ItineraryPageProps) => {
  return <>
    <SideBarPage
      mainContent={
        <MainContent tripId={trip.id} />
      }
    />
  </>
}

export default withLayout(ItineraryPage, { fluid: true });
