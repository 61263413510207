import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import ActivitiesMapSection from '../shared/ActivitiesMap/ActivitiesMapSection';
import { ActivityType } from "../../../../shared/TripObjectTypes";
import ActivityAd, { AdContent } from "../../../../shared/ActivityAd";

type SideBarContentProps = {
  activityAd: AdContent,
  activities: ActivityType[],
  onExpandMap?: () => void,
};

const SideBarContent = ({
  activityAd,
  activities,
  onExpandMap,
}: SideBarContentProps) => {
  const activitiesWithCoords = activities.filter((activity) => activity.longitude && activity.latitude);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  return <>
    {!isMobile && activityAd && (
      <ActivityAd
        content={activityAd}
        vertical="Activities"
      />
    )}
    {activitiesWithCoords.length > 0 && (
      <ActivitiesMapSection
        activities={activitiesWithCoords}
        onExpandMap={onExpandMap}
      />
    )}
  </>;
};

export default SideBarContent;
