import React from 'react';
import {
  ClickAwayListener,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DropdownBase from './DropdownButton/DropdownBase';
import MenuToggle, { MenuIconToggle } from './DropdownButton/MenuToggle';
import clsx from "clsx";

type DropdownProps = {
  className?: string,
  conciseLabel?: string,
  disabled?: boolean,
  icon?: string | object,
  label?: string | object,
  menuList: React.ReactElement,
  testId?: string,
  onClick?: () => void,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingTop: theme.spacing(0.5),
  },
  button: {
    textAlign: 'right',
  },
}));


const DropdownButton = ({
  className,
  conciseLabel,
  disabled,
  icon,
  label,
  menuList,
  testId,
  onClick,
}: DropdownProps) => {
  const classes = useStyles();
  icon = icon ? <img className={classes.icon} src={`/assets/illustrations/${icon}.svg`}/> : null
  return (
    <DropdownBase
      segmentEventOnDropdownClick={onClick}
      className={clsx(className, classes.button)}
      menuComponent={(open, anchorRef, handleToggle) =>
        <MenuToggle
          conciseLabel={conciseLabel}
          disabled={disabled}
          icon={icon}
          isOpen={open}
          label={label}
          onClick={handleToggle}
          ref={anchorRef}
          testId={testId}
        />
      }
      menuList={menuList}
    />
  );
};

export const DropdownIconButton = ({
  className,
  disabled,
  icon,
  menuList,
  testId,
}: DropdownProps) => {
  return (
    <DropdownBase
      className={className}
      customPaper={(handleClose) =>
        <Paper
          sx={{
            overflow: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0.5,
            ml: -14.5,
            '& .MuiAvatar-root': {
              width: 'inherit',
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              zIndex: 0,
            },
          }
        }>
          <ClickAwayListener onClickAway={handleClose}>
            {menuList}
          </ClickAwayListener>
        </Paper>
      }
      menuComponent={(open, anchorRef, handleToggle) =>
        <MenuIconToggle
          icon={icon}
          isOpen={open}
          onClick={handleToggle}
          ref={anchorRef}
          testId={testId}
        />
      }
      menuList={menuList}
    />
  );
};

export default DropdownButton;
