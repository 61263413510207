import React from "react";
import { makeStyles } from "@mui/styles";
import DateOption from "../../shared/DateOption";
import { hocRailsAction } from "../../Document";
import TripObjectChoosingModalForm, { ChoosingTypeTripObject } from "./shared/TripObjectChoosingModalForm";
import getMostPopularDateOptions from "../../../services/getMostPopularDateOptions";
import isSkipVoting from "../../../services/skipVoting";
import * as routes from '../../../routes';
import { DateOptionType } from "../../shared/TripObjectTypes";

const {
  tripChosenDateOptionPath,
  tripDatesPath,
} = routes;

type NewChosenDateOptionPageProps = {
  errors: string[],
  tripId: string,
  tripObjects: DateOptionType[],
};

const useStyles = makeStyles((theme) => ({
  dateOption: {
    marginTop: theme.spacing(2),
  },
}));

const NewChosenDateOptionPage = ({
  errors,
  tripId,
  tripObjects,
}: NewChosenDateOptionPageProps) => {
  const classes = useStyles();
  const mostPopularOptions = getMostPopularDateOptions(tripObjects).map(option => option.id);

  return (
    <TripObjectChoosingModalForm
      title="Which date option do you want to finalize?"
      subtitle="Don’t worry, you can always edit your dates at a later point."
      tripSitePath={tripDatesPath(tripId)}
      newChosenTripObjectPath={tripChosenDateOptionPath(tripId)}
      errors={errors}
      tripObjects={tripObjects}
      type={ChoosingTypeTripObject.Dates}
      isSkipVoting={isSkipVoting()}
      renderTripObject={({ tripObject, isChosen, chooseFinalTripObject }) => {
        const dateOption = tripObject as DateOptionType;
        return <DateOption
          className={classes.dateOption}
          currentUserVote={dateOption.currentUserVote}
          endDate={dateOption.endDate}
          endDateWeekday={dateOption.endDateWeekday}
          endDateYear={dateOption.endDateYear}
          highlightAsChosen={isChosen}
          isClickable={!dateOption.isPast}
          isMostPopular={mostPopularOptions.includes(dateOption.id)}
          isPast={dateOption.isPast}
          key={dateOption.id}
          numNights={dateOption.numNights}
          onDateOptionClick={() => !dateOption.isPast && chooseFinalTripObject(dateOption.id)}
          startDate={dateOption.startDate}
          startDateWeekday={dateOption.startDateWeekday}
          startDateYear={dateOption.startDateYear}
          travelersVotes={dateOption.travelersVotes}
          noVotesBreakdown={isSkipVoting()}
        />
      }}
    />
  );
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(NewChosenDateOptionPage);
