import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import SectionHeader from "../shared/SectionHeader";
import ActivitiesSectionNavigation, { Counter } from "../shared/ActivitiesSectionNavigation";
import NoSuggestionsYet from "../../../../shared/NoSuggestionsYet";
import Button from "../../../../buttons/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import * as routes from '../../../../../routes';
import ActivityGroupList from "./ActivityGroupList";
import RailsForm from "../../../../shared/RailsForm";
import {ActivityGroupType} from "../../../../shared/TripObjectTypes";
import CurrentSortingAndCount from "../shared/CurrentSortingAndCount";
import { SortingContext } from "../SuggestedActivityGroupsPage";
import FilteringAndSortingBar from "../shared/FilteringAndSortingBar";

const {
  tripActivityGroupsPath,
} = routes;

type MainContentProps = {
  tabsCounter: Counter,
  tripId: string,
  activityGroupList: ActivityGroupType[],
};

const MainContent = ({
  tabsCounter,
  tripId,
  activityGroupList,
}: MainContentProps) => {
  const addGroupsText = useMediaQuery(useTheme().breakpoints.down(470)) ? '+ Add' : '+ Add group'
  const noSuggestions = !activityGroupList.length;
  const {
    currentSorting,
    currentSortingLabel,
    sortings,
  } = useContext(SortingContext);

  return <>
    <SectionHeader
      title='Activities'
      note='Create a group to organize suggestions by day, theme, or activity type.'
    />
    <Box mb={2}>
      <ActivitiesSectionNavigation
        active='Activity groups'
        counter={tabsCounter}
        tripId={tripId}
      />
    </Box>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <FilteringAndSortingBar
          currentSorting={currentSorting}
          disabled={noSuggestions}
          formPath={tripActivityGroupsPath(tripId)}
          sortByRadioGroupName="activity_group_filter[sort_by]"
          vertical="Activity groups"
          sortings={sortings}
        />
      </Grid>
      <Grid item>
        <RailsForm
          action={tripActivityGroupsPath(tripId)}
          method="POST"
          withChangeDetection={false}
        >
          <Button
            data-test-id="add-button"
            variant="primary"
            disabled={false}
            type="submit"
          >
            {addGroupsText}
          </Button>
        </RailsForm>
      </Grid>
    </Grid>
    {!noSuggestions && (
      <>
        <Box pt={3} pb={3}>
          <CurrentSortingAndCount
          count={activityGroupList.length}
          sorting={currentSortingLabel}
          type="group"
          />
        </Box>
        <ActivityGroupList activityGroupList={activityGroupList}/>
      </>
    )}
    {noSuggestions && (
      <Grid>
        <NoSuggestionsYet
          illustration='activity_groups'
          subheading="Create a group to start organizing activities"
        />
      </Grid>
    )}
  </>;
};

export default MainContent;
