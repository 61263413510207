import React from 'react';
import {
  Grid,
  Typography,
  Checkbox,
  Collapse,
  Link,
  useMediaQuery,
  FormControlLabel,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import {
  POPULAR_FILTERS,
  FEATURES_FILTERS,
  ACCESSIBILITY_AND_SAFETY_FILTERS,
  ALL_AMENITIES_FILTERS,
  AmenitiesFiltersType,
  AmenitiesObjectFiltersType,
} from '../../../../../../constants/amenitiesFilters';
import useSegment from "../../../../../../services/useSegment";
import useStateWithPromise from "../../../../../../services/useStateWithPromise";
type AmenitiesFiltersProps = {
  amenitiesFilters: AmenitiesFiltersType[],
  updateAmenitiesFilters: (amenities: AmenitiesFiltersType[]) => void,
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
  },
  subtitle: {
    fontWeight: 600,
  },
  selectedCount: {
    color: '#747474',
    fontSize: '12px',
  },
  amenityFilters: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
  },
  selectAllText: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const AmenitiesFilters : React.FC<AmenitiesFiltersProps> = ({
  amenitiesFilters,
  updateAmenitiesFilters,
}) => {
  const classes = useStyles();
  const allSelected = ALL_AMENITIES_FILTERS.length === amenitiesFilters.length
  const [checked, setChecked] = useStateWithPromise(true);
  const { trackSegment } = useSegment();
  const isMobileApp = useMediaQuery(useTheme().breakpoints.down('sm'));
  const collapseText = checked ? 'See less' : `+${Object.keys(FEATURES_FILTERS).length + Object.keys(ACCESSIBILITY_AND_SAFETY_FILTERS).length} more`;
  const isPublicSearch = window.location.pathname === '/search'
  const location = isPublicSearch ? 'Public Search' : 'SERP';

  const handleChange = () => {
    setChecked(!checked).then((value) => {
      trackSegment(value ? 'Filters Expanded' : 'Filters Collapsed', {
       ...(!value) && {selectionsHidden: true},
       isMobileApp,
       location,
      });
    });
  };

  const updateFilter = (checked : boolean, filter) => {
    const newAmenitiesFilters = checked ?
      [...amenitiesFilters, filter]
      : amenitiesFilters.filter((amenity : AmenitiesFiltersType) => amenity !== filter);

    updateAmenitiesFilters(newAmenitiesFilters);
  }

  const selectAll = () => {
    updateAmenitiesFilters(!allSelected ? ALL_AMENITIES_FILTERS : []);
  };

  const renderAmenities = (definedAmenitiesFilters : AmenitiesObjectFiltersType) => (
    Object.entries(definedAmenitiesFilters).map((filter) => (
      <Grid
        item
        container
        key={filter[0]}
        xs={6}
        direction='row'
        alignItems='flex-start'
        flexWrap='nowrap'
      >
        <FormControlLabel
          control={
            <Checkbox
              sx={{height: '12px', paddingLeft: 0}}
              checked={!!amenitiesFilters.find(f => f === filter[0])}
              onChange={(e : React.ChangeEvent<HTMLInputElement>) => updateFilter(e.target.checked, filter[0])}
            />
        }
        label={filter[1]}
        />
      </Grid>
    ))
  );

  const selectedFilters = (definedAmenitiesFilters : AmenitiesObjectFiltersType) => (
    amenitiesFilters.reduce((t,v) => t += Number(Object.keys(definedAmenitiesFilters).includes(v)), 0)
  );

  const renderSelectedFilters = (definedAmenitiesFilters : AmenitiesObjectFiltersType) => {
    const selectedCount = selectedFilters(definedAmenitiesFilters);
    return ( selectedCount > 0 ? (
        <Grid item ml={1}>
          <Typography
            data-test-id='selected-filters'
            className={classes.selectedCount}
           >
             ({selectedCount} selected)
           </Typography>
        </Grid>
      ) : null
    )
  };

  const renderSection = (title : string, definedAmenitiesFilters : AmenitiesObjectFiltersType, marginTop = 1 ) => (
    <Grid item container data-test-id={`${title}-section`} mt={marginTop}>
      <Grid item container direction='row' alignItems='center'>
        <Grid item my={1}>
          <Typography className={classes.subtitle}>{title}</Typography>
        </Grid>
        {renderSelectedFilters(definedAmenitiesFilters)}
      </Grid>
      <Grid item container mt={1} rowGap={3}>
        {renderAmenities(definedAmenitiesFilters)}
      </Grid>
    </Grid>
  );

  return(
    <Grid item container direction='column' spacing={4} mb={4}>
      <Grid
        item
        container
        justifyContent="space-between"
      >
        <Typography variant="h3" className={classes.title}>Amenities</Typography>
        <Typography
          variant="h3"
          className={classes.selectAllText}
          data-test-id="select-all-amenities"
          onClick={() => selectAll()}
        >
          {allSelected ? "Deselect all" : "Select all"}
        </Typography>
      </Grid>
      {renderSection('Popular', POPULAR_FILTERS)}
      <Collapse in={checked} sx={{marginLeft: '32px'}}>
        {renderSection('Features', FEATURES_FILTERS, 2)}
        {renderSection('Accessibility and Safety', ACCESSIBILITY_AND_SAFETY_FILTERS, 2)}
      </Collapse>
      <Grid item>
        <Link
          onClick={handleChange}
          data-test-id='collapse-amenities-link'
          sx={{cursor: 'pointer'}}
          underline="none"
          className={classes.amenityFilters}
        >
          {collapseText}
        </Link>
      </Grid>
    </Grid>
  )
};

export default AmenitiesFilters;
