import React from "react";
import { makeStyles } from "@mui/styles";
import Destination from "./microsite/DestinationPage/Destination";
import { hocRailsAction } from "../../Document";
import TripObjectChoosingModalForm, { ChoosingTypeTripObject } from "./shared/TripObjectChoosingModalForm";
import isSkipVoting from "../../../services/skipVoting";
import * as routes from '../../../routes';
import { DestinationType } from "../../shared/TripObjectTypes";

const {
  tripDestinationsPath,
  tripChosenDestinationPath,
} = routes;

type NewChosenDestinationPageProps = {
  errors: string[],
  tripId: string,
  tripObjects: DestinationType[],
};

const usePageStyles = makeStyles(theme => ({
  destination: {
    marginTop: theme.spacing(2),
  },
}));

const NewChosenDestinationPage = ({
  errors,
  tripId,
  tripObjects,
}: NewChosenDestinationPageProps) => {
  const classes = usePageStyles();

  return (
    <TripObjectChoosingModalForm
      title="Which destination do you want to finalize?"
      subtitle="Don’t worry, you can always edit your choice at a later point."
      tripSitePath={tripDestinationsPath(tripId)}
      newChosenTripObjectPath={tripChosenDestinationPath(tripId)}
      errors={errors}
      tripObjects={tripObjects}
      type={ChoosingTypeTripObject.Destination}
      isSkipVoting={isSkipVoting()}
      renderTripObject={({ tripObject, isChosen, chooseFinalTripObject }) => (
        <Destination
          className={classes.destination}
          destination={tripObject as DestinationType}
          hideVotes={true}
          isChosen={isChosen}
          isClickable
          key={tripObject.id}
          onDestinationClick={() => chooseFinalTripObject(tripObject.id)}
        />
      )}
    />
  );
};

export default hocRailsAction(NewChosenDestinationPage);
