import React, { useState, useContext } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import DeleteButton from './PollView/DeleteButton';
import PollAnswersForm from "./PollView/PollAnswersForm";
import { Poll, PollState } from './PollView/PollTypes';
import CommentIcon from '@mui/icons-material/Comment';
import Button from "../../../../buttons/Chip";
import PollAnswerButton from '../shared/PollAnswerButton';
import PollAnswerResponses from "../shared/PollAnswerResponses";
import PollVotes from "./PollView/PollVotes";
import Linkify from "../../../../shared/Linkify";
import CloseButton from "./CloseButton";
import { SegmentContext } from "../../../../Document";
import ObjectAuthor from "../../../../shared/ObjectAuthor";

import {
  tripPollCommentsPath,
  tripPollResponsesPath,
} from "../../../../../routes";

const useStyles = makeStyles((theme) => ({
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  clickable: {
    cursor: 'pointer',
  },
  header: {
    position: 'relative',
  },
  pollAnswers: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  pollQuestion: {
    width: '100%',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: theme.spacing(3),
  },
  root: {
    border: '1px solid #C1C1C1',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  divider: {
    paddingTop: theme.spacing(3),
  },
  verticalActions: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  commentButton: {
    height: '32px !important',
  },
  commentButtonContainer: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
    zIndex: 2,
    maxWidth: '100%',
  },
  objectAuthorWrapper: {
    overflow: 'auto',
  },
}));

type PollViewProps = {
  commentFormToggle?: boolean,
  displayFormToggle?: (displayForm: boolean) => void,
  displayCommentButton?: boolean,
  displayDeleteButton?: boolean,
  hideAnswers?: boolean,
  poll: Poll,
  shouldRedirectTo?: string,
  wrapWithLink?: boolean,
  isExpanded?: boolean,
};

const PollView = ({
  commentFormToggle,
  displayFormToggle,
  displayCommentButton = false,
  displayDeleteButton = true,
  hideAnswers = false,
  poll,
  shouldRedirectTo,
  wrapWithLink = false,
  isExpanded = false,
}: PollViewProps) => {
  const classes = useStyles();
  const { isPlanner } = useContext(SegmentContext)
  const isOpenForResponses = poll.state === PollState.Open;
  const [collapsed, setCollapsed] = useState(!isExpanded && !isOpenForResponses);

  const onContainerLinkClicked = (event) => {
    if (event.target.dataset['element'] !== 'link') {
      setCollapsed(!collapsed);
    };
  };

  const disableClosedButton = !(poll.isAuthoredByCurrentUser || isPlanner)

  const wrapQuestionWithLink = (component) => {
    if (wrapWithLink) {
      return (
        <Link href={tripPollCommentsPath(poll.tripId, poll.id)}>
          {component}
        </Link>
      );
    } else {
      return component;
    };
  };

  return (
    <Box data-test-id="pollView" className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
        <Grid item className={classes.objectAuthorWrapper}>
          <ObjectAuthor authorName={poll.authorName} avatarUrl={poll.authorAvatarUrl} />
        </Grid>
        {poll.isAuthoredByCurrentUser && displayDeleteButton && (
          <Grid item>
            <DeleteButton poll={poll} />
          </Grid>
        )}
      </Grid>
      <Box pt={2} className={classes.header}>
        {wrapQuestionWithLink(
          <Typography
            className={clsx(
              classes.pollQuestion,
              {[classes.clickable]: !hideAnswers},
            )}
            data-test-id="question-header"
            onClick={onContainerLinkClicked}
          >
          <Linkify>{poll.question}</Linkify>
          </Typography>
        )}
      {!collapsed && !hideAnswers && <Divider className={classes.divider}/>}
      {collapsed && (
        <>
          <Divider sx={{mb: 2, pt: 3}}/>
          <PollVotes
            commentsCount={poll.commentsCount}
            commentsPath={poll.commentsPath}
            responsesCountTotal={poll.responsesCountTotal}
            tripId={poll.tripId}
            tripObjectId={poll.id}
           />
        </>
      )}
      </Box>
      {!hideAnswers && !collapsed && (<>
        <Grid container direction="column" id={poll.id} className={classes.pollAnswers} spacing={2}>
          <Grid item>
            {isOpenForResponses && (
              <PollAnswersForm
                currentUserChoice={poll.currentUserChoice}
                pollAnswers={poll.answers}
                path={tripPollResponsesPath(poll.tripId, poll.id)}
                shouldRedirectTo={shouldRedirectTo}
              />
            )}
            {!isOpenForResponses && poll.answers.map((answer, index) => (
              <React.Fragment key={index}>
                <PollAnswerButton
                  answer={answer}
                  isClickable={false}
                  selected={poll.currentUserChoice?.id == answer.id}
                />
                <PollAnswerResponses answer={answer} />
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <>
          {displayCommentButton && (
          <>
            <Divider sx={{mb: 2, pt: 3}}/>
            <PollVotes
              commentsCount={poll.commentsCount}
              commentsPath={poll.commentsPath}
              responsesCountTotal={poll.responsesCountTotal}
              tripId={poll.tripId}
              tripObjectId={poll.id}
            />
          </>
          )}
          {displayCommentButton && (
            <>
              <Divider sx={{mb: 2, mt: -1}} className={classes.divider}/>
              <Grid container direction="row" justifyContent='end'>
                {isOpenForResponses && (
                  <Grid item xs={6} sm={3}>
                    <CloseButton poll={poll} disableButton={disableClosedButton} />
                  </Grid>
                )}
                <Grid item xs={6} sm={9} className={classes.commentButtonContainer}>
                  <Button
                    onClick={() => displayFormToggle(!commentFormToggle)}
                    className={classes.commentButton}
                    segmentProps={{title: 'Comment Field Initiated', tripObjectId: poll.id}}
                    data-test-id='commentButton'
                  >
                    <CommentIcon />
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </>
      </>
      )}
    </Box>
  );
};

export default PollView;
