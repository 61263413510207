import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  ButtonBase,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material';
import RailsForm from "../../../../shared/RailsForm";
import {
  tripTravelersPath,
} from '../../../../../routes';

type RespondToInviteButtonsProps = {
  tripId: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "row",
    height: '48px',
    '& form:first-of-type': {
      marginRight: theme.spacing(2),
      [theme.breakpoints.between(960, 990)]: {
        marginRight: '4px',
      },
      [theme.breakpoints.down(400)]: {
        marginRight: '4px',
      },
    },
  },
  button: {
    width: '100%',
    height: '100%',
    maxHeight: '48px',
    borderRadius: "6px",
    padding: "12px 24px",
    [theme.breakpoints.down('md')]: {
      padding: "12px",
    },
    [theme.breakpoints.down(370)]: {
      padding: "12px 6px",
    },
  },
  goingButton:{
    backgroundColor: "#E9F8F5",
    border: "1px solid #1EBE95",
  },
  interestedButton:{
    backgroundColor: "#FFF6E6",
    border: "1px solid #FCBC40",
  },
  listItemIcon: {
    color: 'currentColor',
    minWidth: 'auto',
  },
  buttonText: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
  },
}));

const RespondToInviteButtons = ( {
  tripId,
} : RespondToInviteButtonsProps ) => {
  const classes = useStyles();

  const options = {
    going: {
      dataTestId: "going-option",
      status: 'going',
      icon: "Yes",
      label: "Going",
    },
    interested: {
      dataTestId: "interested-option",
      status: 'interested',
      icon: "Maybe",
      label: "Interested",
    },
  };

  const renderButton = (option, stylesClass) => (
    <RailsForm
      action={tripTravelersPath(tripId)}
      method='POST'
      withChangeDetection={false}
    >
      <input type="hidden" name="status" value={option.status} />
      <input type="hidden" name="redirect_to_path" value={window.location.pathname} />
      <ButtonBase
        className={[classes.button, stylesClass].join(' ')}
        data-test-id={option.dataTestId}
        type="submit"
      >
        <ListItemIcon className={classes.listItemIcon}>
          {<img src={`/assets/illustrations/${option.icon}.svg`}/>}
        </ListItemIcon>
        <Typography className={classes.buttonText}>
          {option.label}
        </Typography>
      </ButtonBase>
    </RailsForm>
  );

  return (
    <Stack className={classes.root}>
      {renderButton(options.going, classes.goingButton)}
      {renderButton(options.interested, classes.interestedButton)}
    </Stack>
  );
};

export default RespondToInviteButtons;
