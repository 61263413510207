import React, { useCallback, useContext, useReducer } from "react";
import clsx from "clsx";
import {
  Grid,
  Typography,
} from "@mui/material";
import reactStringReplace from "react-string-replace";
import { makeStyles } from "@mui/styles";
import AvatarGridItem from "./AvatarGridItem";
import CommentEditForm from "./CommentEditForm";
import TimeStamp from "../TimeStamp";
import ActionsMenu from "../ActionsMenu";
import Linkify from "../Linkify";
import { SyncComment } from "../TripObjectTypes";
import Reactions, { ReactionCounts } from "../Reactions/Reactions";
import { TripSiteContext } from "../../Document";
import { useQuery, useMutation } from "@apollo/client";
import { getOperationName } from "../../../graphql/utilities";
import {
  GET_REACTIONS,
  ADD_REACTION,
  REMOVE_REACTION,
} from "../../../graphql/queries/reactions";
import { GET_USER_DISPLAY_NAME } from "../../../graphql/queries/users";

type CommentBoxProps = {
  comment: SyncComment,
};

const useStyles = makeStyles((theme) => ({
  commentAuthor: {
    color: '#3953e2',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: parseInt(theme.spacing(1)) / 2,
    paddingRight: theme.spacing(1),
  },
  commentAuthorWhenCurrentUser: {
    color: '#272727',
  },
  commentBody: {
    backgroundColor: '#fbfbfb ',
    boxShadow: '0 0 2px 0 rgba(36, 48, 96, 0.3)',
    borderRadius: 5,
    lineHeight: 1.43,
    padding: theme.spacing(1),
    position: 'relative',
    width: `calc(100% - 54px)`,
    marginLeft: '14px',
  },
  commentBodyByCurrentUser: {
    background: '#F3FAFF',
  },
  commentEditFormWrapper: {
    width: `calc(100% - ${theme.spacing(5)})`,
  },
  commentTimestamp: {
    color: "#697379",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.29,
    marginTop: theme.spacing(0.3),
    marginRight: theme.spacing(1),
    textAlign: 'center',
  },
  editedText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    marginLeft: theme.spacing(1),
  },
  textBody: {
    whiteSpace: 'pre-wrap',
  },
}));

const CommentBox = ({ comment }: CommentBoxProps) => {
  const classes = useStyles();
  const initialState = {
    commentBody: comment.body,
    editionState: false,
    wasEdited: !!comment.editedAt,
  };

  const { tripGid, currentUserGid } = useContext(TripSiteContext);

  const commentReducer = (state, action) => {
    switch (action.type) {
      case 'changeComment':
        return {
          commentBody: action.commentBody,
          editionState: false,
          wasEdited: true,
        };
      case 'hideEditForm':
        return Object.assign({}, state, { editionState: false });
      case 'showEditForm':
        return Object.assign({}, state, { editionState: true });
      default:
        throw new Error();
    }
  }
  const [state, dispatch] = useReducer(commentReducer, initialState);
  const commentBody = reactStringReplace(state.commentBody, /(\n)/g, (match, i) => <br key={i}/>).map((each, index) => (
    <Linkify key={`comment-link-${comment.id}-${index}`}>{each as string}</Linkify>
  ));

  const { loading, data } = useQuery(GET_REACTIONS, {
    variables: { reactableId: comment.gid },
  }) || {};

  const { data: userData } = useQuery(GET_USER_DISPLAY_NAME, {
    variables: { id: currentUserGid },
  }) || {};

  const refetchQueries = [getOperationName(GET_REACTIONS)];
  const [addReaction, { loading: addReactionLoading }] = useMutation(ADD_REACTION, { refetchQueries });
  const [removeReaction, { loading: removeReactionLoading }] = useMutation(REMOVE_REACTION, { refetchQueries });

  const onAddReaction = useCallback(({emoji, name}) => {
    addReaction({
      variables: {
        emoji,
        humanReadableName: name,
        reactableId: comment.gid,
        tripId: tripGid,
      },
    })
  }, [comment, tripGid]);
  const onRemoveReaction = useCallback(({emoji}) => {
    removeReaction({
      variables: {
        emoji,
        reactableId: comment.gid,
        tripId: tripGid,
      },
    })
  }, [comment, tripGid]);

  const reactions = data?.node?.reactions ?? [];
  const reactionCounts : ReactionCounts = reactions.reduce((acc, reaction) => ({
    ...acc,
    [reaction.emoji]: {
      ...reaction,
      users : reaction.users.map(user => user.displayName),
      name : reaction.humanReadableName,
    },
  }), {});

  const currentUserDisplayName = userData?.node?.displayName;

  return <li
    key={comment.id}
    data-test-id="comment"
    data-test-state={
      comment.isAuthoredByCurrentUser
        ? "comment-by-current-user"
        : "comment-by-other-user"
    }
  >
    <a id={`comment_${comment.id}`}/>
    <Grid container>
      {!comment.isAuthoredByCurrentUser && <AvatarGridItem avatarUrl={comment.authorAvatarUrl} />}
      {!state.editionState && (
        <Grid
          item
          container
          direction="column"
          className={clsx(
            classes.commentBody,
            { [classes.commentBodyByCurrentUser]: comment.isAuthoredByCurrentUser },
          )}
        >
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography className={clsx(
                classes.commentAuthor,
                { [classes.commentAuthorWhenCurrentUser]: comment.isAuthoredByCurrentUser },
              )}>
                {comment.authorName}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <TimeStamp className={classes.commentTimestamp} date={comment.timestamp} />
                </Grid>
                <Grid item>
                  {comment.isAuthoredByCurrentUser && (
                    <ActionsMenu
                      deletePath={comment.commentPath}
                      onEditClick={() => dispatch({ type: 'showEditForm' })}
                      resourceName="comment"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <span className={classes.textBody} data-test-id="comment-body">
              {commentBody}
            </span>
            {state.wasEdited && (
              <span className={classes.editedText}> (Edited)</span>
            )}
          </Grid>
          {!loading && (
            <Reactions
              currentUserDisplayName={currentUserDisplayName}
              initialReactions={reactionCounts}
              onAddReaction={onAddReaction}
              onRemoveReaction={onRemoveReaction}
              disabled={addReactionLoading || removeReactionLoading}
              reactableId={comment.id}
              reactableType='Comment'
              isSelfReaction={comment.authorName === currentUserDisplayName}
            />
          )}
        </Grid>
      )}
      {state.editionState && (
        <Grid item className={classes.commentEditFormWrapper}>
          <CommentEditForm
            commentBody={state.commentBody}
            commentPath={comment.commentPath}
            onCancelClick={() => dispatch({ type: 'hideEditForm' })}
            onCommentChange={(commentBody) => dispatch({ type: 'changeComment', commentBody })}
          />
        </Grid>
      )}
      {comment.isAuthoredByCurrentUser && <AvatarGridItem byCurrentUser avatarUrl={comment.authorAvatarUrl} />}
    </Grid>
  </li>
};

export default CommentBox;
