import * as React from 'react';
import SideBarPage from "../components/shared/SideBarPage";
import { Grid, Hidden, useMediaQuery, Box } from "@mui/material";
import FloatingButton from "../components/buttons/FloatingButton";
import MapIcon from "@mui/icons-material/Map";
import ListingsMapView from "../components/pages/stays_search/Listings/ListingsMapView";
import useSERPMapClickEventRef from "./segmentEvents/useSERPMapClickEventRef";
import { makeStyles, useTheme } from "@mui/styles";
import { ExternalListingProps } from "../components/shared/TripObjectTypes";
import { Theme } from "@mui/material/styles";
import { isCurrentlyInPublicSearch } from './windowLocation';
import SearchFeedbackBox from "../components/pages/stays_search/SearchFeedbackBox";
import { ModelOutputs } from "../components/pages/stays_search/Listings/ListingsBox";
import {aiSearchType, CurrentAppliedFilters} from "../components/pages/stays_search/Listings/Filters";

type RenderWithOrWithoutSidebarPropTypes = {
  radius: number,
  component: React.ReactNode,
  listings: ExternalListingProps[],
  locationName: string | null,
  onClick: () => void,
  showWithSidebar: boolean,
  hasSearched: boolean,
  aiSearchType: aiSearchType,
  modelOutputs: ModelOutputs,
  currentAppliedFilters?: CurrentAppliedFilters,
}

type HoveredListing = {
  id: string,
  latitude: number,
  longitude: number,
};

export const ListingsMapContext = React.createContext({
  hoveredListing: null,
  setHoveredListing: (listing: HoveredListing | null) => {},
});

const useStyles = makeStyles<Theme, { inPublicSearch: boolean, hasSearched: boolean }>((theme): {} => ({
  buttonText: {
    marginLeft: theme.spacing(1),
  },
  sidebarMapWrapper: {
    width: '100%',
    height: ({ inPublicSearch }) => inPublicSearch ? '66vh' : '85vh',
    left: 0,
    position: 'sticky',
    top: ({ inPublicSearch }) => inPublicSearch ? '140px' : '48px',
    marginTop: ({ inPublicSearch }) => inPublicSearch && '116px',
    paddingTop: ({ inPublicSearch }) => !inPublicSearch && '99px',
  },
  wrapper: {
    marginTop: ({ inPublicSearch }) => inPublicSearch && '110px',
    [theme.breakpoints.down(1078)]: {
      marginTop: ({ inPublicSearch, hasSearched }) => inPublicSearch && hasSearched ? '200px' : '100px',
    },
    [theme.breakpoints.down(701)]: {
      marginTop: ({ inPublicSearch }) => inPublicSearch && '174px',
    },
    [theme.breakpoints.down(451)]: {
      marginTop: ({ inPublicSearch, hasSearched }) => inPublicSearch && hasSearched ? '200px' : '16px',
    },
  },
}));

const useAiSearchStyles = makeStyles<Theme, { aiSearchType: aiSearchType }>((theme): {} => ({
  buttonText: {
    marginLeft: theme.spacing(1),
  },
  sidebarMapWrapper: {
    width: '100%',
    height: '85vh',
    left: 0,
    position: 'sticky',
    top: '48px',
    paddingTop: '99px',
  },
  wrapper: {
    marginTop: ({ aiSearchType }) => (aiSearchType === 'aiPublicSearch') && '20px',
    [theme.breakpoints.down(1078)]: {
      marginTop: ({ aiSearchType }) => (aiSearchType === 'aiTripSearch') && '20px',
    },
    [theme.breakpoints.down(451)]: {
      marginTop: ({ aiSearchType }) => (aiSearchType === 'aiTripSearch') && '16px',
    },
  },
  background: {
    background: '#FFF',
    zIndex: -1,
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '-webkit-fill-available',
  },
}));

const RenderWithOrWithoutSidebar = ({
  radius,
  component,
  listings,
  locationName,
  onClick,
  showWithSidebar,
  hasSearched = false,
  aiSearchType,
  modelOutputs,
  currentAppliedFilters,
}: RenderWithOrWithoutSidebarPropTypes) => {
  const inPublicSearch = isCurrentlyInPublicSearch();
  const showSidebarMap = useMediaQuery(useTheme().breakpoints.up('md'));
  const floatingMapButtonRef = useSERPMapClickEventRef('floating map');

  const [hoveredListing, setHoveredListing] = React.useState<HoveredListing | null>(null);

  const classes = aiSearchType ? useAiSearchStyles({ aiSearchType }) : useStyles({ inPublicSearch, hasSearched });

  return (
    <ListingsMapContext.Provider value={{ hoveredListing, setHoveredListing }}>
      <Box className={classes.wrapper}>
        <div className={classes.background} />
        {(showWithSidebar && <SideBarPage
          hideSideBarBorder
          mainContent={
            <>
              {component}
              <Hidden mdUp>
                <FloatingButton
                  onClick={onClick}
                  ref={element => floatingMapButtonRef(element, locationName)}
                >
                  <MapIcon/>
                  <span className={classes.buttonText}>Map</span>
                </FloatingButton>
              </Hidden>
            </>
          }
          sideBarContent={showSidebarMap && <>
            {modelOutputs?.requestId &&
              <SearchFeedbackBox
                modelRequestId={modelOutputs?.requestId}
                aiSearchType={aiSearchType}
                filters={currentAppliedFilters}
                resultCount={listings.length}
              />}
            <Grid className={classes.sidebarMapWrapper}>
              <ListingsMapView
                radius={radius}
                listView
                listings={listings}
                onExpandMapClick={onClick}
                sidebar
                aiSearchType={aiSearchType}
              />
            </Grid>
          </>}
        />)}
        {!showWithSidebar && component}
      </Box>
    </ListingsMapContext.Provider>
  )
};

export default RenderWithOrWithoutSidebar;
