import React from "react";
import {
  Link,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import HotelIcon from '@mui/icons-material/Hotel';
import DropdownButton from "../../../../shared/DropdownButton";
import useSegment from "../../../../../services/useSegment";
import * as routes from "../../../../../routes";

type AddEventDropdownProps = {
  tripId: string,
  itineraryDateId: string,
};

type AddLinkProps = {
  icon: React.ReactNode,
  label: string,
  path: string,
};

const {
  newTripActivityEventPath,
  newTripStayEventPath,
} = routes;

const AddEventDropdown: React.FC<AddEventDropdownProps> = ({
  tripId,
  itineraryDateId,
}) => {
  const { trackSegment } = useSegment();

  const AddLink: React.FC<AddLinkProps> = ({ icon, label, path }) => (
    <Link
      color="inherit"
      underline="none"
      href={path}
    >
      <MenuItem>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={label} />
      </MenuItem>
    </Link>
  );

  return (
    <DropdownButton
      onClick={() => trackSegment('Add Itinerary Event Initiated', {})}
      testId='dropdown-add-btn'
      label="+ Add"
      menuList={
        <MenuList>
          <AddLink
            icon={<HotelIcon/>}
            label="Add stay"
            path={`${newTripStayEventPath(tripId)}?itinerary_date_id=${itineraryDateId}`}
          />
          <AddLink
            icon={<CalendarTodayOutlinedIcon/>}
            label="Add activity"
            path={`${newTripActivityEventPath(tripId)}?itinerary_date_id=${itineraryDateId}`}
          />
        </MenuList>
      }
    />
  );
};

export default AddEventDropdown;
