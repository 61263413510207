import React, { useState, useContext } from "react";
import { hocRailsAction, MaxPrice, TripSiteContext } from "../../../Document";
import {
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from "dayjs";
import * as dayjs from "dayjs";
import { makeStyles, useTheme } from "@mui/styles";
import { FormContext } from "../../../shared/RailsForm";
import DateRangePicker from "../../../shared/CustomDateRangePicker";
import ModalForm from "../../../shared/ModalForm";
import Errors from "../../../shared/Errors";
import InputWithCharacterCounter from '../../../shared/InputWithCharacterCounter';
import {ItineraryEventType, RefundabilityType, StayBookingType} from '../../../shared/TripObjectTypes';
import useSegment from "../../../../services/useSegment";
import useStateWithPromise from "../../../../services/useStateWithPromise";
import * as routes from '../../../../routes';
import { tripStayBookingPath } from "../../../../routes";

const {
  tripStayBookingsPath,
  tripStaysPath,
  tripItineraryDatesPath,
  tripItineraryDatePath
} = routes;

type StayBookingsFormProps = {
  errors: string[],
  tripId: string,
  stayId: string,
  stayEvent?: ItineraryEventType,
  source?: string,
  itineraryDateId?: string,
  stayBooking?: StayBookingType,
  verticalPageMethod: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  datePicker: {
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23) !important",
    },
  },
}));

const refundabilityList: Array<RefundabilityType> = [
  'refundable',
  'non_refundable',
];

const StayBookingsForm = ({
  errors,
  tripId,
  stayId,
  stayBooking,
  verticalPageMethod,
  stayEvent,
  source = "",
  itineraryDateId,
}: StayBookingsFormProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const refundabilityInitialValue = stayBooking?.refundability
  const { staysState } = useContext(TripSiteContext);
  const { trackSegment } = useSegment();
  const [confirmationText, setConfirmationText] = useStateWithPromise(stayBooking?.confirmationCode || '');
  const [checkInDate, setCheckInDate] = useStateWithPromise(stayBooking?.checkInDate || "");
  const [checkOutDate, setCheckOutDate] = useStateWithPromise(stayBooking?.checkOutDate || "");
  const [refundabilityValue, setRefundabilityValue] = useStateWithPromise(refundabilityInitialValue);
  const [refundDeadLineValue, setRefundDeadLineValue] = useState<Dayjs | null>(stayBooking?.refundableUntil ? dayjs(stayBooking.refundableUntil) : null);
  const isRefundabable = refundabilityValue === 'refundable';
  const closePath = itineraryDateId ? tripItineraryDatePath(tripId, itineraryDateId) : tripStaysPath(tripId);
  const disabledSubmit = !confirmationText || (isRefundabable && !refundDeadLineValue) || (checkInDate === null || checkOutDate === null);

  const actionRoute = !stayBooking?.uuid ? tripStayBookingsPath(tripId, stayId) : tripStayBookingPath(tripId, stayId, stayBooking.uuid)
  const formMethod = !stayBooking?.uuid ? "post" : "patch";

  const refundabilityText = {
    'refundable': 'Yes',
    'non_refundable': 'No',
    'none': 'None',
  };

  const handleRefundDeadLine = (newValue: Dayjs | null) => {
    setRefundDeadLineValue(newValue);
  };

  const handleFormDismiss = () => {
    trackSegment('Booking form Dismissed', {
      verticalStatus: staysState,
      method: verticalPageMethod,
      isMobileApp: isMobile,
    });
  };

  const formatNumber = (value?: string): string => {
    if (!value) {
      return undefined
    }
    if (Number.parseFloat(value) - Number.parseInt(value) === 0) {
      return Number.parseInt(value).toString()
    } else {
      return Number.parseFloat(value).toFixed(2)
    }
  }

  return (
    <ModalForm
      closePath={closePath}
      formActionPath={actionRoute}
      formMethod={formMethod}
      saveButtonLabel={stayBooking ? "Update booking" : "Save booking"}
      title="Booking details"
      withChangeDetection
      data-test-id='stay-booking-form'
      onModalClose={handleFormDismiss}
      disabledSubmit={disabledSubmit}
    >
      <FormContext.Consumer>
        {({ onFormChange }) => (
          <Grid container direction="column" spacing={2}>
            <input name='isMobile' value={`${isMobile}`} type="hidden" />
            { errors.length > 0 && (
              <Grid item><Errors errors={errors} /></Grid>
            )}
            <Grid item>
              <input
                type="hidden"
                value={source}
                name={'source'}
              />
              <input
                type="hidden"
                value={itineraryDateId}
                name={'itinerary_date_id'}
              />
              <Typography className={classes.label}>
                Confirmation code*
              </Typography>
              <TextField
                fullWidth
                id='confirmation_code'
                label='Enter confirmation code'
                name='stay_booking[confirmation_code]'
                variant='outlined'
                value={confirmationText}
                onChange={(e) => {
                  setConfirmationText(e.target.value);
                  onFormChange();
                }}
              />
            </Grid>
            <Grid item>
              <DateRangePicker
                autoComplete="off"
                defaultStartValue={stayBooking?.checkInDate || stayEvent?.startDate}
                defaultEndValue={stayBooking?.checkOutDate || stayEvent?.endDate}
                startValue={checkInDate}
                endValue={checkOutDate}
                defaultStayBookingCheckInTimeValue={stayBooking?.checkInTime}
                defaultStayBookingCheckOutTimeValue={stayBooking?.checkOutTime}
                isStayBookingForm
                helperText={false}
                nameStart="stay_booking[check_in_date]"
                nameEnd="stay_booking[check_out_date]"
                onChange={(e) => {
                  onFormChange();
                  setCheckInDate(e[0]);
                  setCheckOutDate(e[1]);
                }}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.label}>
                Total cost (USD)
              </Typography>
              <TextField
                fullWidth
                id='total_cost'
                placeholder='Enter an amount'
                name='stay_booking[total_cost]'
                type="number"
                variant='outlined'
                defaultValue={formatNumber(stayBooking?.totalCost?.toString())}
                inputProps={{
                  min: 0,
                  step: 0.01,
                  max: MaxPrice,
                  inputMode: "decimal",
                }}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.label}>
                Is the payment refundable?
              </Typography>
              <Select
                displayEmpty
                fullWidth
                value={refundabilityValue}
                data-test-id="stay-booking-refundability"
                name='stay_booking[refundability]'
                onChange={(e) => {
                  setRefundabilityValue(e.target.value).then(() => onFormChange());
                }}
                renderValue={(selectedValue) => {
                  if (!selectedValue || selectedValue === null) {
                    return <span style={{color: '#b4b9bc'}}>Select answer</span>
                  } else {
                    return refundabilityText[selectedValue]
                  }
                }}
              >
                <MenuItem value='none'>None</MenuItem>
                {refundabilityList.map((refundability) => {
                  return (
                    <MenuItem
                      value={refundability}
                      key={`${refundability}-option`}
                      data-test-id={`${refundability}-option`}
                    >
                      {refundabilityText[refundability]}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            { isRefundabable &&
              <Grid item data-test-id='refund-deadline'>
                <Typography className={classes.label}>
                  When is the refund deadline?*
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={classes.datePicker}
                    data-test-id='start-date'
                    inputFormat="MM/DD/YYYY"
                    value={refundDeadLineValue}
                    showToolbar={false}
                    onChange={(e) => {
                      handleRefundDeadLine(e);
                      onFormChange();
                    }}
                    renderInput={(params) =>
                      <TextField
                        name='stay_booking[refundable_until]'
                        {...params}
                      />
                    }
                  />
                </LocalizationProvider>
              </Grid>
            }
            <Grid item>
              <Typography className={classes.label}>Additional notes</Typography>
              <InputWithCharacterCounter
                name="stay_booking[notes]"
                defaultValue={stayBooking?.notes}
                placeholder='Include other information about the booking you’d want travelers to know, like booking date, booking source, stay address, etc.'
                fullWidth
                withBorder
                noLabel
                hasExtraHeight
                characterLimit={5000}
              />
            </Grid>
          </Grid>
        )}
      </FormContext.Consumer>
    </ModalForm>
  );
};

export default hocRailsAction(StayBookingsForm);
