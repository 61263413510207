import React, { Ref } from 'react';
import clsx from "clsx";
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ButtonGroup } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from "../../buttons/Button";
import IconButton from '../../buttons/IconButton';

const useStyles: () => any = makeStyles((theme): {} => ({
  buttonGroup: {
    width: '100%',
  },
  buttonGroupItem: {
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    '&:not(:last-of-type)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&:not(:first-of-type)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  expandButton: {
    padding: [[0], '!important'],
  },
  icon: {
    paddingRight: theme.spacing(1.5),
  },
}));

type MenuToggleProps = {
  conciseLabel?: string,
  disabled?: boolean,
  icon?: any,
  isOpen: boolean,
  label?: string | object,
  onClick: () => void,
  testId?: string,
};

const MenuButtonToggle = React.forwardRef((
  {
    conciseLabel,
    disabled,
    icon,
    isOpen,
    label,
    onClick,
    testId,
  }: MenuToggleProps,
  ref: Ref<HTMLDivElement>,
) => {
  const classes = useStyles();
  const showConciseButton = !!conciseLabel && useMediaQuery(useTheme().breakpoints.down(470));

  return (
    <ButtonGroup
      aria-label="split button"
      className={classes.buttonGroup}
      color="primary"
      disabled={disabled}
      ref={ref}
      variant='contained'
    >
      <Button
        className={classes.buttonGroupItem}
        onClick={onClick}
        variant='primary'
        aria-controls={isOpen ? "split-button-menu" : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        aria-haspopup="menu"
        data-test-id={testId}
      >
        {icon && <span className={classes.icon}>{icon}</span>}
        {showConciseButton ? conciseLabel : label}
      </Button>
      {!showConciseButton && (
        <Button
          className={clsx(classes.expandButton, classes.buttonGroupItem)}
          onClick={onClick}
        >
          <ExpandMoreIcon fontSize={"large"}/>
        </Button>
      )}
    </ButtonGroup>
  );
});

export const MenuIconToggle = React.forwardRef((
  {
    icon,
    isOpen,
    onClick,
    testId,
  }: MenuToggleProps,
  ref: Ref<HTMLButtonElement>,
) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.buttonGroupItem}
      aria-controls={isOpen ? "split-button-menu" : undefined}
      aria-expanded={isOpen ? "true" : undefined}
      aria-haspopup="menu"
      data-test-id={testId}
      onClick={onClick}
      ref={ref}
    >
      {icon}
    </IconButton>
  );
});

export default MenuButtonToggle;