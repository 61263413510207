import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { searchPath } from "../../../routes";
import FavoritesButton from "../../buttons/FavoritesButton";
import { useMediaQuery } from "@mui/material";
import { DefaultTheme } from "@mui/styles";
import TroupeLogoWithFavoritesHeader from "../../shared/TroupeLogoWithFavoritesHeader";

type FilterSearchedBackgroundProps = {
  children: React.ReactNode,
  logoPath: string,
};

const useStyles = makeStyles<DefaultTheme>((theme) => ({
  FilterSearchedBackground: {
    display: 'flex',
    gap: '0 8%',
    flexWrap: 'wrap',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    justifyContent: 'center',
    zIndex: '3',
    padding: theme.spacing(2),
  },
  backgroundColor: {
    backgroundColor: '#262A71',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    width: '100%',
    height: '116px',
    [theme.breakpoints.down(1078)]: {
      height: '195px',
    },
    [theme.breakpoints.down(701)]: {
      height: '200px',
    },
    [theme.breakpoints.down(451)]: {
      height: '226px',
    },
  },
  background: {
    marginTop: '11px',
    flex: '1 1 300px',
    maxWidth: '1000px',
    [theme.breakpoints.up(954)]: {
      paddingRight: 0,
    },
  },
  logoContainer: {
    display: 'flex',
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: theme.spacing(3.5),
    marginLeft: '3%',
    cursor: 'pointer',
    [theme.breakpoints.down(701)]: {
      marginTop: 0,
      marginBottom: 0,
      height: theme.spacing(2),
    },
  },
  favoritesButton: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down(700)]: {
      marginTop: 0,
    },
  },
  mobileHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const FilterSearchedBackground : React.FC<FilterSearchedBackgroundProps> = ({
  children,
  logoPath = searchPath(),
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down(700));
  const renderWebView = () => (
    <>
      <div>
        <img
          src="/assets/logo_white.svg"
          alt="Troupe logo"
          className={classes.logo}
          onClick={() => window.location.href = logoPath}
        />
      </div>
      <div className={classes.background}>
        {children}
      </div>
      <div className={classes.favoritesButton}>
        <FavoritesButton />
      </div>
    </>
  );

  const renderMobileView = () => (
    <>
      <TroupeLogoWithFavoritesHeader />
      <div className={classes.background}>
        {children}
      </div>
    </>
  );

  return(
    <div className={classes.FilterSearchedBackground}>
      <div className={classes.backgroundColor} />
      {isMobile ? renderMobileView() : renderWebView()}
    </div>
  )
}

export default FilterSearchedBackground;
