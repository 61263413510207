import React, { useCallback } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "../buttons/Button";
import { trackLink as trackAnalyticsEvent } from '../../services/analytics';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    paddingTop: theme.spacing(3),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  upperGrid: {
    margin: '0 0 0 0',
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.2)',
    textAlign: 'left',
  },
  body: {
    marginBottom: theme.spacing(3),
  },
  image: {
    width: '100%',
    maxWidth: '426px',
  },
  boldText: {
    margin: '0 0 8px',
    fontSize: '18px',
    fontWeight: '600',
  },
}));

type EmptySectionContentWithCTAProps = {
  body: string,
  message1: string,
  message2: string,
  illustration: string,
  ctaHREF: string,
  ctaText: string,
  heading: string,
  ctaTrackingProps?: null | { eventName: string, properties: object }
}

const EmptySectionContentWithCTA = ({
  body,
  message1,
  message2,
  illustration,
  ctaHREF,
  ctaText,
  ctaTrackingProps,
}: EmptySectionContentWithCTAProps) => {
  const classes = useStyles();
  const ctaRef = useCallback((element) => {
    if (element !== null && !!ctaTrackingProps) {
      trackAnalyticsEvent(
        element,
        ctaTrackingProps?.eventName,
        {
          ...ctaTrackingProps?.properties,
        },
      );
    };
  }, []);

  return (
    <Grid container className={classes.root} direction="column" spacing={1} justifyContent="center" alignItems="center">
      <Grid className={classes.upperGrid} item>
        <Typography className={classes.body}>{body}</Typography>
      </Grid>
      <img
        className={classes.image}
        alt="No suggestions"
        src={`/assets/illustrations/${illustration}.svg`}
      />
      <Grid item>
        <Typography className={classes.boldText}>{message1}</Typography>
        <Typography>{message2}</Typography>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button
          data-test-id="ctaButton"
          href={ctaHREF}
          ref={(el) => ctaRef(el)}
        >
          {ctaText}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmptySectionContentWithCTA;
