import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ButtonBase from "@mui/material/ButtonBase";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DestinationType } from "./TripObjectTypes";
import { usePhasingContext } from "../../services/phasing";
import { track as trackAnalyticsEvent } from "../../services/analytics";

type ExploreStaysButtonProps = {
  destination: DestinationType,
  url: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 2,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: 15,
    height: '40px',
    color: theme.palette.primary.main,
  },
  openNewTabIcon: {
    fontSize: '16px',
    marginLeft: theme.spacing(0.5),
  },
}));

const ExploreStaysButton = ({
  destination,
  url,
}: ExploreStaysButtonProps) => {
  const classes = useStyles();
  const { destinationsState } = usePhasingContext();

  const onButtonClick = () => {
    trackAnalyticsEvent('Explore Stays Clicked',
    {
      verticalPhase: destinationsState,
      rank: destination.ranking ? destination.ranking : 'no rank',
    });

    window.location.replace(url)
  };

  return (
    <ButtonBase
      className={classes.root}
      onClick={()=> onButtonClick()}
      data-test-id='destinationLink'
    >
      Explore stays <ChevronRightIcon fontSize="medium"/>
    </ButtonBase>
  );
};

export default ExploreStaysButton;
