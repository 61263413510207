import React, { useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Grid } from "@mui/material";
import MostRecentCommentBox from "../shared/MostRecentCommentBox";
import DateOption from "../../../../shared/DateOption";
import { DateOptionType } from "../../../../shared/TripObjectTypes";
import getMostPopularDateOptions from "../../../../../services/getMostPopularDateOptions";

type DatesListProps = {
  dateOptions: DateOptionType[],
};

const useStyles: () => any = makeStyles((theme): {} => ({
  dateOptionCard: {
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const DatesList = ({
  dateOptions,
}: DatesListProps) => {
  const classes = useStyles();
  const mostPopularOptionsIds = getMostPopularDateOptions(dateOptions).map(option => option.id);

  const initialValue = Array(dateOptions.length).fill(false);
  const [
    showCommentForm,
    setShowCommentForm,
  ] = useState(initialValue);

  const toggleCommentFormVisibility = (index) => {
    const newArray = Array.from(showCommentForm);
    newArray[index] = !showCommentForm[index];
    setShowCommentForm(newArray);
  };

  return <>
    {dateOptions.map((dateOption, index) => (
      <Box data-test-id="date-option-container" className={clsx("anchor", classes.dateOptionCard)} key={dateOption.id} mt={1} mb={3}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <a id={`date_option_${dateOption.id}`} />
            <DateOption
              commentsPath={dateOption.commentsPath}
              commentsCount={dateOption.commentsCount}
              className={classes.dateOption}
              currentUserVote={dateOption.currentUserVote}
              dateOptionPath={dateOption.dateOptionPath}
              endDate={dateOption.endDate}
              endDateWeekday={dateOption.endDateWeekday}
              endDateYear={dateOption.endDateYear}
              isMostPopular={mostPopularOptionsIds.includes(dateOption.id)}
              isPast={dateOption.isPast}
              key={dateOption.id}
              linkPath={dateOption.tripObjectPath}
              numNights={dateOption.numNights}
              showActions
              showLink
              showMissingVoteAlert={dateOption.isCurrentUserVoteRequired}
              startDate={dateOption.startDate}
              startDateWeekday={dateOption.startDateWeekday}
              startDateYear={dateOption.startDateYear}
              travelersVotes={dateOption.travelersVotes}
              setDateOptionComment={() => toggleCommentFormVisibility(index)}
              displayCommentButton={true}
              tripObjectId={dateOption.id}
            />
          </Grid>
          {(dateOption.mostRecentComment || showCommentForm[index]) && (
            <Grid item>
              <MostRecentCommentBox
                comment={dateOption.mostRecentComment}
                commentFormPath={dateOption.commentsPath}
                commentsPath={dateOption.tripObjectPath}
                showCommentForm={showCommentForm[index]}
                showLoadMoreCommentsButton={dateOption.commentsCount > 1}
                tripId={dateOption.tripId}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    ))}
  </>;
};

export default DatesList;
