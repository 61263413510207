import React from "react";
import TooltipBase, { TooltipProps } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: 'black',
  },
  tooltip: {
    backgroundColor: 'black',
    fontSize: 14,
    maxWidth: 330,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 270,
      textAlign: 'center',
    },
  },
}));

const Tooltip : React.FC<TooltipProps> = ({ children, title, ...otherProps }) => {
  const classes = useStyles();

  return (
    <TooltipBase enterTouchDelay={0} arrow classes={classes} title={title} {...otherProps}>
      {children}
    </TooltipBase>
  );
}

export default Tooltip;
