import React from "react";
import {
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import StepperInput from "../../../../shared/StepperInput";

type GuestsSectionProps = {
  minGuests: number,
  onGuestsSelected: (value: number) => void,
};

const GuestsSection = ({
  minGuests,
  onGuestsSelected,
}: GuestsSectionProps) => {

  return (
    <Grid container direction="column" spacing={1} mt={2}>
      <Grid item>
        <Typography variant="h2">Guests</Typography>
      </Grid>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography>Adults</Typography>
        </Grid>
        <Grid item data-test-id="minGuestsValue">
          <StepperInput
            defaultValue={minGuests}
            minValue={1}
            maxValue={20}
            onChange={(value) => onGuestsSelected(value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GuestsSection;
