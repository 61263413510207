import { Grid, TextField, Typography } from "@mui/material";
import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MaxPrice } from "../../../Document";

type ActivityPriceFieldProps = {
  price?: string,
  shouldFocusPrice?: boolean,
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ActivityPriceField = ({
  price,
  shouldFocusPrice,
  }: ActivityPriceFieldProps) => {
  const classes = useStyles();
  const priceText = parseFloat(price).toFixed(2)
  const [activityPrice, setActivityPrice] = useState(priceText);

  const checkMaxPrice = (activityPrice) => {
    if (activityPrice < MaxPrice) {
      setActivityPrice(activityPrice)
    }
  }

  const scrollToPrice = () => {
    document.getElementById('activity_price')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  };

  useEffect(() => {
    if (shouldFocusPrice) {
      scrollToPrice();
    };
  }, []);

  return(
    <Grid item>
      <Typography className={classes.label} id="activity_price"
      >
        Cost
      </Typography>
      <TextField
        data-test-id="activity_price"
        defaultValue={priceText}
        autoFocus={shouldFocusPrice}
        label={"Price per person (USD)"}
        name="activity[price]"
        type="number"
        variant="outlined"
        value={activityPrice}
        onChange={event => checkMaxPrice(event.target.value)}
        fullWidth
        inputProps={{
          min: 0,
          step: 0.01,
          max: MaxPrice,
          inputMode: "decimal",
        }}
      />
    </Grid>
  )
};

export default ActivityPriceField;
