import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextButton from "../../../../../buttons/TextButton";
import * as routes from '../../../../../../routes';
import { TripUpdateEventType } from "../../TripUpdatesPage";

const {
  tripDatesPath,
  tripDestinationsPath,
  tripStaysPath,
} = routes;

const useStyles = makeStyles((theme) => ({
  goToButton: {
    textAlign: 'center',
    width: '100%',
  },
}));

type TransitionedTripEventLinkProps = {
  eventType: TripUpdateEventType,
  tripId: string,
};

const buttonAttrs = {
  destination_transitioned_to_suggesting: {
    tripObjectListPath: (tripId) => {return tripDestinationsPath(tripId)},
    text: 'Go to Destinations',
  },
  destination_transitioned_to_voting: {
    tripObjectListPath: (tripId) => {return tripDestinationsPath(tripId)},
    text: 'Go to Destinations',
  },
  stay_transitioned_to_suggesting: {
    tripObjectListPath: (tripId) => {return tripStaysPath(tripId)},
    text: 'Go to Stays',
  },
  stay_transitioned_to_voting: {
    tripObjectListPath: (tripId) => {return tripStaysPath(tripId)},
    text: 'Go to Stays',
  },
  dates_transitioned_to_suggesting: {
    tripObjectListPath: (tripId) => {return tripDatesPath(tripId)},
    text: 'Go to Dates',
  },
  dates_transitioned_to_voting: {
    tripObjectListPath: (tripId) => {return tripDatesPath(tripId)},
    text: 'Go to Dates',
  },
};

const TransitionedTripEventLink = ({
  eventType,
  tripId,
}: TransitionedTripEventLinkProps ) => {
  const classes = useStyles();
  const tripObjectListPath = buttonAttrs[eventType]['tripObjectListPath'](tripId);
  const linkButtonText = buttonAttrs[eventType]['text'];

  return (<>
    <TextButton
      href={tripObjectListPath}
      className={classes.goToButton}
    >
      {linkButtonText}
    </TextButton>
  </>)
};

export default TransitionedTripEventLink;
