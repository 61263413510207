import React from "react";
import {Grid, TextField, Typography} from "@mui/material";
import { ActivityGroupType } from "../../../shared/TripObjectTypes";
import InputWithCharacterCounter from "../../../shared/InputWithCharacterCounter";
import ModalForm from "../../../shared/ModalForm";
import { hocRailsAction } from "../../../Document";
import * as routes from "../../../../routes"
import Errors from "../../../../components/shared/Errors";
import makeStyles from "@mui/styles/makeStyles";

const {
  tripActivityGroupPath,
  tripActivityGroupsPath,
} = routes;

type ActivityGroupEditFormProps = {
  activityGroup: ActivityGroupType,
  errors?: string[],
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const ActivityGroupEditForm = ({
  activityGroup,
  errors,
}: ActivityGroupEditFormProps) => {
  const classes = useStyles();

  return (
    <ModalForm
      closePath={tripActivityGroupsPath(activityGroup.tripId)}
      formActionPath={tripActivityGroupPath(activityGroup.tripId, activityGroup.id)}
      withChangeDetection
      formMethod="patch"
      title="Edit activity group"
      data-test-id='edit-activity-group'
      subtitle="Edit the details for this activity group."
    >
      <Grid container spacing={2} direction="column">
        {errors && (
          <Grid item>
            <Errors errors={errors} />
          </Grid>
        )}
        <Grid item>
          <Typography className={classes.label}>
            Group Name
          </Typography>
          <InputWithCharacterCounter
            defaultValue={activityGroup.title}
            name="activity_group[title]"
            noLabel
            focused
            fullWidth
            characterLimit={100}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.label}>
            Description
          </Typography>
          <InputWithCharacterCounter
            defaultValue={activityGroup.description}
            name="activity_group[description]"
            placeholder="Add a group description"
            noLabel
            characterLimit={150}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(ActivityGroupEditForm);
