import React from "react";
import { Typography, Stack, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChangeEvent } from "react";

type ItineraryDatesChangeExplanationProps = {
  switchChecked: boolean,
  // eslint-disable-next-line no-unused-vars
  onSwitchClick: (e: ChangeEvent<HTMLInputElement>) => void,
};

const useStyles = makeStyles((theme) => ({
  ItineraryDatesReduction: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: '#EEEFFF',
    borderRadius: '8px',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#262A71',
  },
  text: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  confirmSubtitle: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#262A71',
  },
}));

const ItineraryDatesReduction = ({switchChecked, onSwitchClick} : ItineraryDatesChangeExplanationProps) => {
  const classes = useStyles();

  return (
  <div className={classes.ItineraryDatesReduction}>
    <Typography className={classes.title}>
      Shorten itinerary?
    </Typography>
    <Typography className={classes.text}>
      You’ve shortened your itinerary. Events associated with days you’ve
        removed from your itinerary will need to be re-added.
    </Typography>
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography className={classes.confirmSubtitle}>
        Confirm changes
      </Typography>
      <Switch
        checked={switchChecked}
        onChange={onSwitchClick}
      />
    </Stack>
  </div>
)};

export default ItineraryDatesReduction;
