import React, { useContext, useEffect, useReducer } from "react";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import { FormContext } from "../../../../shared/RailsForm";
import CameraDropdownButton from "../../../../shared/ImageUploader/CameraDropdownButton";
import Avatar from "../../../../avatars/Avatar";
import ImagePicker from "../../../../shared/ImageUploader/ImagePicker";

type AvatarUploaderProps = {
  currentAvatarUrl: string,
  defaultAvatarUrl: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  icon: {
    bottom: 0,
    position: 'absolute',
    right: 0,
    zIndex: 1,
    '& > button': {
      width: 32,
      height: 32,
    },
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case 'changeAvatar':
      return { ...state, avatarUrl: action.avatarUrl, pickerOpened: false };
    case 'togglePicker':
      return { ...state, pickerOpened: action.pickerOpened };
    default:
      throw new Error();
  };
};

const AvatarUploader = ({
  currentAvatarUrl,
  defaultAvatarUrl,
}: AvatarUploaderProps) => {
  const classes = useStyles();
  const initialState = { avatarUrl: currentAvatarUrl, pickerOpened: false }
  const [{ avatarUrl, pickerOpened }, dispatch] = useReducer(reducer, initialState);
  const { submit } = useContext(FormContext);

  const onImageChange = (url) => {
    if (avatarUrl !== url) dispatch({type: 'changeAvatar', avatarUrl: url});
  };

  useEffect(() => {
    if (avatarUrl !== currentAvatarUrl) submit();
  }, [avatarUrl]);

  return <>
    <Box className={classes.root} data-test-id='avatar-uploader'>
      <Avatar
        borderVariant="primary"
        src={avatarUrl}
        size="xl"
      />
      <CameraDropdownButton
        className={classes.icon}
        onRestoreDefault={() => dispatch({type: 'changeAvatar', avatarUrl: defaultAvatarUrl})}
        onChoosePhoto={() => dispatch({ type: 'togglePicker', pickerOpened: true })}
        canRestoreDefault={currentAvatarUrl !== defaultAvatarUrl}
      />
    </Box>
    <ImagePicker
      onClose={() => dispatch({ type: 'togglePicker', pickerOpened: false })}
      onSuccess={(url) => onImageChange(url)}
      opened={pickerOpened}
    />
    <input name='user[avatar_url]' value={avatarUrl} type="hidden" />
  </>;
};

export default AvatarUploader;
