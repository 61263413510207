import React, { useState, useContext } from "react";
import { Box, Grid, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Activity from "../ActivitiesPage/Activity";
import { ActivityGroupType } from "../../../../shared/TripObjectTypes";
import AddActivityButtonCard from "./ActivityGroupCarousel/AddActivityButtonCard";
import CardGroupCarousel, { Item } from "../../../../shared/CardGroupCarousel";
import CardGroupCarouselContext from "../../../../shared/CardGroupCarousel/Context";
import {
  PreviousStepButton,
  NextStepButton,
} from '../../../../shared/CardGroupCarousel/CarouselStepButton';
import { Variation } from "../../../../shared/TripObject";
import Link from "../../../../shared/Link";
import textButtonColors from "../../../../buttons/textButtonColors";
import { track as trackAnalyticsEvent } from '../../../../../services/analytics';
import { SegmentContext } from "../../../../Document";
import pluralize from 'pluralize';
import * as routes from "../../../../../routes";

const {
  tripActivityGroupPath,
} = routes;

type ActivityGroupCarouselProps = {
  activityGroup: ActivityGroupType,
}

const useStyles = makeStyles((theme) => ({
  customLink: {
    color: textButtonColors.link.default.color,
    display: 'inline-block',
    zIndex: 2,
    fontSize: theme.spacing(2),
    fontWeight: textButtonColors.link.default.fontWeight,
    marginTop: theme.spacing(1),
    '&:hover': {
      color: textButtonColors.link.default.color,
      textDecoration: 'underline',
    },
  },
  stepButtonMargin: {
    marginLeft: theme.spacing(1),
    marginRigth: theme.spacing(1),
  },
  bottomCardRow: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(4),
  }
}));

const ActivityGroupCarousel = ({
  activityGroup,
}: ActivityGroupCarouselProps) => {
  const classes = useStyles();
  const { isPlanner, tripId } = useContext(SegmentContext);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [maxStep, setMaxStep] = useState<number>(0);

  const activitiesSelectedCount = activityGroup.activitiesSelected.length;
  const activitiesSelectedText = `View ${activitiesSelectedCount} ${pluralize('activity', activitiesSelectedCount)}`;

  const renderCardFunc = (activity) => {
    return <Activity
      displayActivityLinks={false}
      isCarouselItem
      activity={activity}
      variation={Variation.Vertical}
      onActivityClick={() => window.location.href = tripActivityGroupPath(activityGroup.tripId, activityGroup.id)}
    />
  };

  const renderButtonCardFunc = () => {
    const tooltipTitle = () => {
      const noActivitiesCreated = activityGroup.activitiesAvailable.length === 0 && activityGroup.activitiesSelected.length === 0
      if(noActivitiesCreated) {
        return "Add activities to get this group rolling."
      }

      const allActivitiesAdded = activityGroup.activitiesAvailable.length === 0 && activityGroup.activitiesSelected.length !== 0
      if(allActivitiesAdded) {
        return "All activities from the list have been added."
      }

      return "";
    }

    return <AddActivityButtonCard
      activityGroupId={activityGroup.id}
      tripId={activityGroup.tripId}
      noActivitiesAvailable={activityGroup.activitiesAvailable.length === 0}
      tooltipTitle={tooltipTitle()}
    />
  }

  let carouselItems: Item[] = [{
    card: {},
    renderCardFunc: renderButtonCardFunc,
  }];

  activityGroup.activitiesSelected.map((activity) => {
    carouselItems.push({
      card: activity,
      renderCardFunc: renderCardFunc,
    })
  });

  const trackClick = (eventName) => {
    trackAnalyticsEvent(
      eventName,
      {
        tripId: activityGroup.tripId,
        planner: isPlanner,
        objectId: activityGroup.id,
      },
    )
  }

  return <CardGroupCarouselContext.Provider value={{
      currentStep,
      maxStep,
      setCurrentStep,
      setMaxStep,
    }}>
      <Box data-test-id="carousel-box">
        <CardGroupCarousel
          items={carouselItems}
          fullWidth={carouselItems.length === 1}
        />
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.bottomCardRow}
        mx={2}
        pr={4}
      >
        <Grid item>
          <Link
            className={classes.customLink}
            data-test-id={'activity-group-count-link'}
            text={activitiesSelectedText}
            href={tripActivityGroupPath(activityGroup.tripId, activityGroup.id)}
            onClick={() => trackClick('Activity Group Count Clicked')}
          />
        </Grid>
        <Grid item>
          <span className={classes.stepButtonMargin}>
            <PreviousStepButton />
          </span>
          <span className={classes.stepButtonMargin}>
            <NextStepButton />
          </span>
        </Grid>
      </Grid>
  </CardGroupCarouselContext.Provider>
}

export default ActivityGroupCarousel;
