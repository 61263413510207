import React, { useCallback, useContext } from "react";
import {
  Typography,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Button from "../../../../buttons/Button";
import { SegmentContext } from "../../../../Document";
import useSegment from "../../../../../services/useSegment";
import { addBySmartSearchTripStaysPath } from "../../../../../routes";

type FinalizedPageContentHeaderProps = {
  heading: React.ReactNode,
  note: string,
  subheading: string,
  showButtons?: boolean,
  tripObjectType: string,
};

const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    color: "#3953e2",
    fontSize: 32,
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  subheading: {
    fontSize: 16,
    fontWeight: 600,
  },
  exploreStaysButton: {
    marginTop: `${theme.spacing(2)} !important`,
    maxWidth: '300px',
    alignSelf: "center",
  },
}));

const FinalizedPageContentHeader = ({
  heading,
  note,
  subheading,
  showButtons = false,
  tripObjectType,
}: FinalizedPageContentHeaderProps) => {
  const classes = useStyles();
  const { tripId, currentUserId } = useContext(SegmentContext)
  const { trackLinkSegment } = useSegment();

  const buttonRef = useCallback((element) => {
    if (element !== null) {
      trackLinkSegment(
        element,
        'Search Stays Clicked',
        {
          travelerId: currentUserId,
          vertical: tripObjectType,
        },
      );
    }
  }, []);

  return (
    <Stack spacing={1} mt={2} mb={4}>
      <Typography className={classes.sectionHeading}>{heading}</Typography>
      <Typography className={classes.subheading}>{subheading}</Typography>
      <Typography>{note}</Typography>
      {showButtons && (
        <Button
          className={classes.exploreStaysButton}
          fullWidth
          href={addBySmartSearchTripStaysPath(tripId)}
          ref={buttonRef}
        >
          Explore stays
        </Button>
      )}
    </Stack>
  );
};

export default FinalizedPageContentHeader;
