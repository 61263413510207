import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

type DividerProps = {
  className?: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    borderTop: '1px solid #C1C1C1',
    margin: 0,
  },
}))

const Divider = ({
  className,
}: DividerProps) => {
  const classes = useStyles();
  return <hr className={clsx(classes.root, className)} />;
}

export default Divider;
