import React, { useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

type StayImageProps = {
  disabled: boolean,
  imageUrl?: string,
  small?: boolean,
};

const useStyles = makeStyles((theme) => ({
  disabledGradient: {
    bottom: 0,
    left: 0,
    display: 'block',
    width: "100%",
    height: "100%",
    zIndex: 2,
    position: 'absolute',
    background: "rgba(216, 216, 216, 0.6)",
  },
  root: {
    display: 'block',
    width: "100%",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
    objectFit: 'cover',
    objectPosition: 'center',
    background: '#F4F5FF',
  },
  small: {
    maxHeight: 200,
  },
  unavailable: {
    zIndex: 3,
    fontSize: theme.spacing(3),
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#d43e53",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: "translate(-50%, -50%) rotate(-34deg)",
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
}));

const StayImage = ({
  disabled,
  imageUrl,
  small,
}: StayImageProps) => {
  const classes = useStyles();
  const [imageSource, setImageSource] = useState(imageUrl);
  const fallbackSource = "/assets/stay_fallback.svg";

  return (
    <div className={classes.wrapper}>
      <img
        className={clsx(
          classes.root,
          {[classes.small]: small},
        )}
        data-test-id="tripStayPhoto"
        onError={() => setImageSource(fallbackSource)}
        src={imageSource || fallbackSource}
      />
      {disabled && <>
        <div className={classes.disabledGradient} />
        <Typography className={classes.unavailable}>
          UNAVAILABLE
        </Typography>
      </>}
    </div>
  );
};

export default StayImage;
