import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { track as trackAnalyticsEvent } from "../../../../../services/analytics";
import pluralize from 'pluralize';
import CustomLink from '../../../../shared/Link';
import SegmentType from '../../../../shared/SegmentType';
import textButtonColors from "../../../../buttons/textButtonColors";

type CommentsLinkProps = {
  commentsCount: number,
  commentsLink: string,
  segment: SegmentType,
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: textButtonColors.link.default.color,
    zIndex: 2,
    fontSize: 16,
    fontWeight: textButtonColors.link.default.fontWeight,
    '&:hover': {
      color: textButtonColors.link.default.color,
      textDecoration: 'underline',
    },
  },
}));

const CommentsLink = ({
  commentsCount,
  commentsLink,
  segment,
}: CommentsLinkProps) => {
  const classes = useStyles();
  const commentsText = `See ${commentsCount} ${pluralize('comment', commentsCount)}`;

  const trackClickedComments = () => {
    trackAnalyticsEvent('Comment Count Clicked', {
      property: {
        tripId: segment.tripId,
        tripObjectId: segment.tripObjectId,
        tripObjectType: segment.tripObjectType
      },
    });
  };

  return (
    <CustomLink
      className={classes.root}
      href={commentsLink}
      text={commentsText}
      data-test-id='CommentsLink'
      onClick={() => trackClickedComments()}
    />
  );
};

export default CommentsLink;
