import React from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Errors from '../../../shared/Errors';
import ModalForm from '../../../shared/ModalForm';
import { hocRailsAction } from "../../../Document";
import { tripItineraryPath } from "../../../../routes";
import { default as DateRangePicker } from "../../../shared/CustomDateRangePicker";
import { FormContext } from "../../../shared/RailsForm";
import { ItineraryType } from "../../../shared/TripObjectTypes";

export type ItineraryFormProps = {
  itinerary?: ItineraryType,
  errors?: string[],
  tripId: string,
  isEditing?: boolean,
};

type FormOption = {
  formMethod: 'post',
  subtitle: string,
  title: string,
  labelText: string,
};

type FormOptions = {
  create: FormOption,
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
  },
}));

const ItineraryForm = ({
  itinerary,
  errors,
  tripId,
}: ItineraryFormProps) => {

  const classes = useStyles();

  const itineraryFormOptions:FormOptions = {
    create: {
      formMethod: 'post',
      subtitle: 'Enter your start and end dates to create a day-by day itinerary for your trip.',
      title: 'Create itinerary',
      labelText: 'What are your trip dates?',
    },
  }

  return (
    <ModalForm
      closePath={tripItineraryPath(tripId)}
      formActionPath={tripItineraryPath(tripId)}
      formMethod={itineraryFormOptions['create'].formMethod}
      saveButtonLabel='Save'
      subtitle={itineraryFormOptions['create'].subtitle}
      title={itineraryFormOptions['create'].title}
      withChangeDetection={!itinerary}
    >
      {errors && (
        <Box mb={2}>
          <Errors errors={errors} />
        </Box>
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography className={classes.label}>{itineraryFormOptions['create'].labelText}</Typography>
        </Grid>
        <Grid item>
          <FormContext.Consumer>
            {({ onFormChange }) => (
              <DateRangePicker
                autoComplete="off"
                defaultEndValue={itinerary?.endDate}
                defaultStartValue={itinerary?.startDate}
                helperText={false}
                nameEnd="itinerary[end_date]"
                nameStart="itinerary[start_date]"
                onChange={() => onFormChange()}
              />
            )}
          </FormContext.Consumer>
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(ItineraryForm);
