import React from 'react';
import useSegment from '../useSegment';
import { TripSiteContext } from "../../components/Document";

export const useBookButtonClickedEvent = (page, provider) => {
  const { staysState } = React.useContext(TripSiteContext);
  const { trackLinkSegment } = useSegment();

  const buttonRef = React.useCallback((element) => {
    if (element !== null) {
      trackLinkSegment(
        element,
        'Book CTA Clicked',
        {
          VRBO: provider === 'Vrbo',
          page,
          stayVerticalStatus: staysState,
          listingUrl: element.href,
          Expedia: provider === 'Expedia',
          buttonName: 'Learn more',
        },
      )
    }
  }, []);

  return buttonRef;
};
