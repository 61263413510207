import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ConfirmationDialog from "../../../../../shared/ConfirmationDialog";
import CloseIcon from '@mui/icons-material/Close';

type RemoveActivityFromGroupButtonProps = {
  actionPath: string,
};
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 2,
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  button: {
    height: 32,
    width: 32,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: 36,
    },
  },
  buttonGroup: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #B4B9BC',
    color: theme.palette.text.primary,
  },
}));

const RemoveActivityFromGroupButton = ({
  actionPath,
}: RemoveActivityFromGroupButtonProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  return <>
      <Box className={classes.root}>
      <ButtonGroup
        classes={{
          groupedOutlined: classes.buttonGroup,
        }}
        disableElevation
        variant="outlined"
        size="small"
      >
        <Button
          data-test-id="deletePollButton"
          onClick={() => setDialogOpen(true)}
          className={classes.button}
        >
          <CloseIcon fontSize="small"/>
        </Button>
      </ButtonGroup>
      <ConfirmationDialog
        actionMethod="PATCH"
        actionPath={actionPath}
        actionText="Remove"
        fullWidthButtons
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title="Remove this activity?"
        message="This will remove the activity from the group, but not delete the activity itself."
      />
    </Box>
  </>
};

export default RemoveActivityFromGroupButton;
