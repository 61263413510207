import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup, IconButton, Menu, MenuItem, Tooltip,
} from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationDialog from "../../../../shared/DeleteConfirmationDialog";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

type ActivityGroupActionsButtonGroupProps = {
  deletePath: string,
  disableDeleteButton?: boolean,
  editPath: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  button: {
    height: 30,
    [theme.breakpoints.down('sm')]: {
      height: 36,
    },
    '&:hover': {
      backgroundColor: '#F6F6F6',
      border: '1px solid #B4B9BC',
    },
  },
  buttonGroup: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #B4B9BC',
    color: theme.palette.text.primary,
  },
  buttonDisabled: {
    color: `${theme.palette.general.background.disabled} !important`,
    '&:focus': {
      boxShadow: 'none !important',
      outline: '0 !important',
    },
  },
  iconButton: {
    width: 32,
    height: 32,
    backgroundColor: "#FFFFFF",
    border: `1px solid ${theme.palette.general.background.disabled}`,
    "&:hover": {
      backgroundColor: '#F6F6F6',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  icon: {
    color: '#334351',
  },
}));

const ActivityGroupActionsButtonGroup = ({
  deletePath,
  disableDeleteButton,
  editPath,
}: ActivityGroupActionsButtonGroupProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseMenu = () => setAnchorEl(null);
  const handleDeleteClick = () => {
    handleCloseMenu()
    setDialogOpen(true);
  };

  const goToEditPath = () => {
    window.location.href = editPath;
  };

  return (
    <Box className={classes.root} data-test-id='activity-group-actions-button-group'>
      <IconButton
        size="small"
        onClick={(event) => setAnchorEl(event.target)}
        className={classes.iconButton}
        data-test-id="actionsMenu"
      >
        <MoreHorizIcon fontSize="large" className={classes.icon}/>
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
      >
        <MenuItem data-test-id={'edit-activity-group-button'} onClick={goToEditPath} dense>
          <EditIcon fontSize="small"/>
          Edit
        </MenuItem>
        <MenuItem
          data-test-id={'delete-activity-group-button'}
          onClick={handleDeleteClick}
          dense
          disabled={disableDeleteButton}
        >
          <DeleteIcon fontSize="small"/>
          Delete
        </MenuItem>
      </Menu>
      <DeleteConfirmationDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        path={deletePath}
        title='Delete this group'
        message='This will delete the group itself, but not any activities saved to the group.'
      />
    </Box>
  )
};

export default ActivityGroupActionsButtonGroup;
