import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Box,
  Grid,
  Theme,
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from "@mui/styles";
import withMicrositeLayout from "./withLayout";
import StaysSection from "./HousingPage/StaysSection";
import Divider from "../../../shared/Divider";
import TravelersVotingBox from "../../../shared/TravelersVotingBox";
import SideBarPage from "../../../shared/SideBarPage";
import * as routes from '../../../../routes';
import StaysMapView from './HousingPage/MapView/StaysMapView';
import VotingTopSideBarContent from './shared/VotingTopSideBarContent';
import { usePhasingContext } from "../../../../services/phasing";
import SuggestingPhaseButtons from './shared/SuggestingPhaseButtons';
import { HEADER_BAR_HEIGHT } from "../../../shared/SiteHeader";
import { NAV_BAR_HEIGHT } from "../../../trip-page/NavigationBar";
import MobileListButton from '../../../shared/MapView/MobileListButton';
import MobileMapButton from '../../../shared/MapView/MobileMapButton';
import { Sorting } from "./shared/FilteringAndSortingBar";
import { StaySorting } from "../microsite/HousingPage/StaysSection/HousingFilteringAndSortingBar";
import { TravelerProps } from "../../../shared/TravelersVotingBox";
import { TripType } from "../../../shared/TripTypes";
import { StayType } from "../../../shared/TripObjectTypes";
import SkeletonPage from '../../../shared/SkeletonPage';
import SnackbarNotification, { SnackbarNotificationProp } from '../../../shared/SnackbarNotification';
import { STAYS_LIST_QUERY } from "../../../../graphql/queries/stays";
import * as Sentry from "@sentry/browser";
import StaysMapSection from "./HousingPage/StaysSection/StaysMapSection";

const {
  newTripChosenStayPath,
  setSuggestingPhaseTripStaysPath,
  setVotingPhaseTripStaysPath,
} = routes;

const useStyles = makeStyles<Theme, { fullScreenMapTop: number }>((theme) => ({
  header: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  mapContainer: {
    marginBottom: theme.spacing(3),
  },
  mapWrapper: {
    width: '100%',
    height: `calc(80vh - ${theme.spacing(8)})`,
    left: 0,
  },
  fullscreenMap: {
    position: 'absolute',
    width: '100%',
    left: 0,
    height: ({ fullScreenMapTop }) => `calc(100% - ${fullScreenMapTop}px)`,
    '@supports (height: env(safe-area-inset-top))': {
      height: ({ fullScreenMapTop }) => `calc(100% - env(safe-area-inset-top) - ${fullScreenMapTop}px)`,
    },
  },
}));

const Snackbar = (props: Partial<SnackbarNotificationProp>) => (
  <SnackbarNotification
    autoHideDuration={props.autoHideDuration ?? null}
    message={props.message}
    open={!!props.message}
    severity={props.severity}
  />
);

type SuggestedHousingPageProps = {
  staySortings: Sorting<StaySorting>[],
  currentStayFilters?: {
    dateOptionIds: string[],
  },
  currentStaySorting?: StaySorting,
  shouldDisplayTravelersList: boolean,
  trip: TripType,
  lastEventUpdatedAt: number,
}

type StaySortAndFilter = {
  sortBy: StaySorting,
  dateOptionIds: string[],
}

const SuggestedHousingPage = ({
  staySortings,
  currentStayFilters,
  currentStaySorting,
  shouldDisplayTravelersList,
  trip,
  lastEventUpdatedAt,
}: SuggestedHousingPageProps) => {
  const fullScreenMapTop = HEADER_BAR_HEIGHT + NAV_BAR_HEIGHT;
  const classes = useStyles({ fullScreenMapTop });

  const { areStaysInSuggestingPhase, areStaysInVotingPhase } = usePhasingContext();

  const [fullscreenMap, setFullscreenMap] = useState(false);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const initialSortAndFilter = {
    sortBy: currentStaySorting,
    dateOptionIds: currentStayFilters?.dateOptionIds ?? [],
  };

  const [activeSortAndFilter, setSortAndFilter] = useState<StaySortAndFilter>(initialSortAndFilter);
  const { sortBy, dateOptionIds } = activeSortAndFilter;

  const { data, loading, error } = useQuery(
    STAYS_LIST_QUERY, {
      variables: {
        tripId: trip.gid,
        ...activeSortAndFilter,
      },
    },
  );

  if (loading) return <SkeletonPage />;

  if (error) {
    Sentry.captureException(error)
    return <>
      <Snackbar
        message='Sorry, something went wrong loading stays. Please try again.'
        severity='error'
      />
      <SkeletonPage />
    </>
  }

  const stays: StayType[] = data.stays
  const travelers: TravelerProps[] = data.travelers.map(traveler => ({
    ...traveler,
    missingVotesCount: traveler.stayMissingVotesCount,
    name: traveler.displayName,
  }));

  const shouldShowMapView = stays.some((stay) => stay.longitude && stay.latitude);
  const showVotingBoxOnSidebar = shouldDisplayTravelersList && !isMobile && stays.length > 0;
  const showVotingBoxOnMainContent = shouldDisplayTravelersList && isMobile && stays.length > 0;
  const votingBox = (isSidebar) => {
    if ((areStaysInVotingPhase()) && ((isSidebar && showVotingBoxOnSidebar) || (!isSidebar && showVotingBoxOnMainContent))) {
      return <>
        {isSidebar && <Box mt={3}><Divider /></Box>}
        <Box mb={3} mt={isSidebar ? 3 : 0}>
          <TravelersVotingBox
            travelers={travelers}
            tripId={trip.id}
            verticalType='stays'
          />
        </Box>
        <Box mb={3}><Divider /></Box>
      </>;
    }
  };

  const handleFilterAndSortChange = (newSortAndFilter: Partial<StaySortAndFilter>) => {
    const defaultSortAndFilter: StaySortAndFilter = { sortBy: 'recently_added', dateOptionIds: [] }

    setSortAndFilter({ ...defaultSortAndFilter, ...newSortAndFilter })
  };

  return <>
    {fullscreenMap ? (
      <Grid item className={classes.fullscreenMap} data-test-id="fullscreenMap">
        <StaysMapView
          fullscreen={fullscreenMap}
          stays={stays}
        />
        <MobileListButton onClick={() => setFullscreenMap(false)} source='stays' />
      </Grid>
    ) : (
      <>
        <SideBarPage
          mainContent={
            <>
              {votingBox(false)}
              <StaysSection
                stays={stays}
                staySortings={staySortings}
                onSortAndFilterChange={handleFilterAndSortChange}
                currentStayFilters={{ dateOptionIds }}
                currentStaySorting={sortBy}
                dateOptions={trip.dateOptions}
                isPlanner={trip.isPlanner}
                lastEventUpdatedAt={lastEventUpdatedAt}
                travelers={travelers}
                tripGid={trip.gid}
                tripId={trip.uuid}
              />
            </>
          }
          sideBarContent={
            <>
              <div className={classes.mapContainer}>
                {areStaysInVotingPhase() && (
                  <>
                    <VotingTopSideBarContent
                      disabledActions={stays.length === 0}
                      finalizePath={newTripChosenStayPath(trip.uuid)}
                      isCurrentUserPlanner={trip.isPlanner}
                      transitionToSuggestingPath={setSuggestingPhaseTripStaysPath(trip.uuid)}
                      type='stay'
                    />
                  </>
                )}
                {areStaysInSuggestingPhase() && (
                  <SuggestingPhaseButtons
                    hasSuggestions={stays.length > 0}
                    isPlanner={trip.isPlanner}
                    transitionToVotingPath={setVotingPhaseTripStaysPath(trip.uuid)}
                    tripObjectType='stays'
                    tripId={trip.uuid}
                  />
                )}
                </div>
                {votingBox(true)}
                {shouldShowMapView &&
                  <StaysMapSection
                    stays={stays}
                    onExpandMapClick={() => setFullscreenMap(true)}
                  />
                }
              </>
          }
        />
        {shouldShowMapView && <MobileMapButton onClick={() => setFullscreenMap(true)} source='stays' />}
      </>
    )}
  </>;
};

// noinspection JSUnusedGlobalSymbols
export default withMicrositeLayout(SuggestedHousingPage, { fluid: true });
