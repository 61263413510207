import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TextButton from "../buttons/TextButton";

type StrategyType = 'hide' | 'remove';
type CollapsibleBoxProps = {
  centeredHeader?: boolean,
  children?: React.ReactNode,
  className?: string,
  header: React.ReactNode,
  hideArrows?: boolean,
  note: React.ReactNode,
  startCollapsed?: boolean,
  strategy?: StrategyType,
  titleWhileCollapsed?: React.ReactNode,
  titleWhileExpanded?: React.ReactNode,
};

const useStyles = makeStyles((theme) => ({
  centeredHeader: {
    textAlign: 'center',
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: theme.spacing(1.25),
  },
  note: {
    fontSize: 16,
  },
  arrowIcon: {
    color: '#272727',
  },
  expansionToggleButton: {
    padding: 0,
    '&:focus': {
      border: [['none'], '!important'],
      boxShadow: [['none'], '!important'],
    },
  },
  expandedContent: {
    marginTop: theme.spacing(2),
  },
  hiddenContent: {
    display: 'none',
  },
}));

const CollapsibleBox = ({
  centeredHeader,
  children,
  className,
  header,
  hideArrows,
  note,
  startCollapsed,
  strategy = 'hide',
  titleWhileCollapsed,
  titleWhileExpanded,
}: CollapsibleBoxProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(!startCollapsed);
  const toggleExpansion = () => setExpanded(!expanded);

  const TitleLink = hideArrows ? TextButton : IconButton;

  return (
    <Grid container
      className={className}
      direction="column"
      justifyContent="space-between"
    >
      <Grid container item justifyContent="space-between">
        <Grid container
          className={clsx(
            {
              [classes.centeredHeader]: centeredHeader,
            },
          )}
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          {header && (
            <Grid item xs={10}>
              <Typography className={classes.header} onClick={toggleExpansion}>
                {header}
              </Typography>
            </Grid>
          )}
          <Grid item xs={header ? 1 : 12}>
            <TitleLink
              data-test-id="toggleCollapsibleButton"
              className={classes.expansionToggleButton}
              disableRipple={hideArrows}
              onClick={toggleExpansion}
              size="medium"
            >
              {expanded ? titleWhileExpanded : titleWhileCollapsed}
              {!hideArrows && expanded && <KeyboardArrowUpIcon fontSize="large" className={classes.arrowIcon} />}
              {!hideArrows && !expanded && <KeyboardArrowDownIcon fontSize="large" className={classes.arrowIcon} />}
            </TitleLink>
          </Grid>
        </Grid>
      </Grid>
      {note && (
        <Grid item>
          <Typography className={classes.note}>
            {note}
          </Typography>
        </Grid>
      )}
      {(expanded || strategy === 'hide') && (
        <Grid item className={clsx(
            classes.expandedContent,
            {
              [classes.hiddenContent]: !expanded,
            },
          )}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default CollapsibleBox;
