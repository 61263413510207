import React, { useState } from "react";
import clsx from "clsx";
import {
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { hocRailsAction } from "../../Document";
import AvailabilityAndPricingSection from "./StayForm/AvailabilityAndPricingSection";
import DetailsFields from "./StayForm/DetailsFields";
import AddressField from "./StayForm/AddressField";
import Button from "../../buttons/Button";
import CommentField from "../trips/microsite/shared/CommentField"
import ImageUploader from "../../shared/ImageUploader";
import ModalForm from "../../shared/ModalForm";
import { Place } from "../../shared/GooglePlacesAutocomplete";
import useStateWithPromise from "../../../services/useStateWithPromise";
import * as routes from '../../../routes';

const {
  tripStayPath,
  tripStaysPath,
} = routes;

type StayDetailsFormProps = {
  stay: any,
  stayId: string,
  onlyComments: boolean,
  tripId: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  hidden: {
    display: 'none',
  },
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
  },
  addressLabel: {
    marginBottom: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const StayDetailsForm = ({
  stay,
  stayId,
  onlyComments,
  tripId,
}: StayDetailsFormProps) => {
  const [value, setValue] = useStateWithPromise({});
  const classes = useStyles();
  const maxSteps = onlyComments ? 1 : 2;
  const [step, setStep] = useState(1);
  const [imageUrl, setImageUrl] = useState(stay.imageUrl);

  const onFormSubmit = (event) => {
    if (step !== maxSteps) {
      setStep(step + 1);
      event.preventDefault();
    }
  };

  const handleAddressChange = (address: Place) => {
    if (address) {
      setValue({
        address: address.name,
        google_place_id: address.id,
        google_place_url: address.url,
        google_place_image: address.image,
        latitude: address.latitude,
        longitude: address.longitude,
      });
    }
  };

  const subtitle = onlyComments ? undefined : `Step ${step} of ${maxSteps}`;

  const hideDetailsStep = onlyComments || (step === maxSteps);
  const canGoBack = step === maxSteps && !onlyComments;

  return (
    <ModalForm
      closePath={tripStaysPath(tripId)}
      formActionPath={tripStayPath(tripId, stayId)}
      formMethod="patch"
      onClick={(event) => onFormSubmit(event)}
      saveButtonLabel={step === maxSteps ? "Finish" : "Next"}
      saveButtonType={step === maxSteps ? 'submit' : 'button'}
      subtitle={subtitle}
      title="Add a stay"
      withChangeDetection={false}
      extraSaveBarButton={canGoBack ? (
        <Button
          className={classes.backButton}
          fullWidth
          onClick={() => setStep(step - 1)}
          variant="secondary"
        >
          Back
        </Button>
      ) : null}
    >
      {!onlyComments && (
        <Grid container spacing={2} direction="column" className={clsx({
          [classes.hidden]: hideDetailsStep,
        })}>
          <Grid item>
            <Typography className={classes.label}>
              Name this place
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              defaultValue={stay.name}
              fullWidth
              id="stay_name"
              label="Name"
              multiline
              name="stay[name]"
              placeholder="What's this place called?"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <ImageUploader
              defaultImage="/assets/stay_fallback_smaller.svg"
              image={stay.images[0]}
              inputName="stay[images][]"
              onImageUpload={(url) => setImageUrl(url)}
              originalImages={stay.originalImages}
            />
          </Grid>
          <Grid item>
            <Typography className={clsx(classes.label, classes.addressLabel)}>
              Add to map view
            </Typography>
            <AddressField
              currentGooglePlaceId={stay?.googlePlaceId}
              currentGooglePlaceUrl={stay?.googlePlaceUrl}
              currentAddress={stay?.address}
              currentLatitude={stay?.latitude}
              currentLongitude={stay?.longitude}
              currentImage={stay?.googlePlaceImage}
              onChange={handleAddressChange}
              required={!!stay.address}
            />
          </Grid>
          <Grid item>
            <DetailsFields stay={stay} />
          </Grid>
          <Divider className={classes.divider} />
          <AvailabilityAndPricingSection stay={stay} />
        </Grid>
      )}
      <Grid container spacing={2} direction="column" className={clsx({
        [classes.hidden]: step !== maxSteps,
      })}>
        <CommentField
          subtitle='Leave a comment for the group if there are any details we’ve missed.'
          fieldId='stay_comment'
          fieldName='stay[comments_attributes][0][body]'
        />
      </Grid>
    </ModalForm>
  );
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(StayDetailsForm);
