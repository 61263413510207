import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Divider,
} from "@mui/material";
import Comments from "../../shared/Comments";
import { SyncComment } from "../../shared/TripObjectTypes";
import {
  DateOptionType,
  DestinationType,
  StayType,
  ActivityType,
  ActivityGroupType,
} from "../../shared/TripObjectTypes";
import withLayout from "../trips/microsite/withLayout";
import * as routes from '../../../routes';
import DateOptionPage from "./IndexPage/DateOptionPage";
import DestinationPage, { DestinationGid } from "./IndexPage/DestinationPage";
import StayPage, { StayGid } from "./IndexPage/StayPage";
import ActivityPage from "./IndexPage/ActivityPage";
import PollView from '../trips/microsite/PollsPage/PollView';
import { Poll } from '../trips/microsite/PollsPage/PollView/PollTypes';
import { TravelerProps } from "../../shared/TravelersVotingBox";
import BroadcastView, { Broadcast } from "../trips/microsite/BroadcastsPage/BroadcastView";
import Breadcrumbs from "../../shared/Breadcrumbs";
import BackToButton from "../trips/microsite/shared/BackToButton";

const {
  tripBroadcastsPath,
  tripDatesPath,
  tripStaysPath,
  tripDestinationsPath,
  tripPollsPath,
  tripActivitiesPath,
  tripActivityGroupPath,
  tripItineraryDatePath,
  tripItineraryDatesPath,
} = routes;

export type Commentable =
| Broadcast
| Poll
| DestinationType
| DateOptionType
| StayType
| ActivityType;

type IndexPageProps = {
  commentable: Commentable,
  comments: Array<SyncComment>,
  commentCreationError: string,
  isUserPlanner: boolean,
  isUserLoggedIn: boolean,
  tripId: string,
  travelers: TravelerProps[],
  userHasCompletedVoting: boolean,
  activityGroup?: ActivityGroupType,
  itineraryDateId?: string,
  suggestionsCount: number,
  source?: "staysVertical" | "stayPermapage" | "itineraryPermapage" | "scheduleList",
};

const useStyles = makeStyles((theme) => ({
  groupBackLink: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  subHeader: {
    fontSize: '1em',
  },
}))

const IndexPage = ({
  commentable,
  comments,
  commentCreationError,
  isUserPlanner,
  isUserLoggedIn,
  tripId,
  activityGroup,
  itineraryDateId,
  suggestionsCount,
  source,
}: IndexPageProps) => {
  type commentableAttributeReturnType = {
    backLinkText: string,
    tripPath: any,
    commentHeaderText: string,
  };

  type commentableReturnType = {
    Stay: commentableAttributeReturnType,
    DateOption: commentableAttributeReturnType,
    Destination: commentableAttributeReturnType,
    Poll: commentableAttributeReturnType,
    Broadcast: commentableAttributeReturnType,
    Activity: commentableAttributeReturnType,
  }

  const classes = useStyles();

  const commentableType : commentableReturnType = {
    Stay: {
      backLinkText: itineraryDateId ? "Back to itinerary" : "Back to all stays",
      tripPath: itineraryDateId ? tripItineraryDatePath : tripStaysPath,
      commentHeaderText: "Here’s what people have to say about the listing.",
    },
    DateOption: {
      backLinkText: "Back to all dates",
      tripPath: tripDatesPath,
      commentHeaderText: "Here’s what people have to say about the date option above.",
    },
    Destination: {
      backLinkText: "Back to all destinations",
      tripPath: tripDestinationsPath,
      commentHeaderText: "Here’s what people are saying about this destination.",
    },
    Poll: {
      backLinkText: "Back to polls",
      tripPath: tripPollsPath,
      commentHeaderText: "Here’s what people are saying about this poll.",
    },
    Broadcast: {
      backLinkText: "Back to broadcasts",
      tripPath: tripBroadcastsPath,
      commentHeaderText: "Here’s what people are saying about this broadcast.",
    },
    Activity: {
      backLinkText: itineraryDateId ? "Back to itinerary" : "Back to all activities",
      tripPath: itineraryDateId ? tripItineraryDatePath : tripActivitiesPath,
      commentHeaderText: "Here’s what people are saying about this activity.",
    },
  }

  const { backLinkText, tripPath, commentHeaderText } = commentableType[commentable.type];

  let breadcrumbs;
  if (activityGroup) {
    const activityGroupTitle = () => {
      const title = activityGroup.title
      return title.length > 25 ? activityGroup.title.substring(0, 25) + '…' : activityGroup.title
    }
   breadcrumbs = [
    {
      label: 'All activities',
      href: tripActivitiesPath(tripId),
    },
    {
      label: activityGroupTitle(),
      href: tripActivityGroupPath(tripId, activityGroup.id),
    },
   ];
  }

  return <>
    {activityGroup &&
      <Box className={classes.groupBackLink} data-test-id="activity-group-breadcrumbs">
        <Breadcrumbs
          active={(commentable as ActivityType).name}
          links={breadcrumbs}
        />
      </Box>
    }
    {!activityGroup && !itineraryDateId &&
      <Box py={3}>
        <BackToButton path={tripPath(tripId)} text={backLinkText} />
      </Box>
    }
    {itineraryDateId &&
      <Box py={3}>
        <BackToButton
          path={source === 'scheduleList' ? tripItineraryDatesPath(tripId) : tripItineraryDatePath(tripId, itineraryDateId)}
          text={backLinkText}
        />
      </Box>
    }
    {commentable.type === 'Stay' && (
      <StayPage
        isPlanner={isUserPlanner}
        stay={commentable as StayGid}
        source={source}
        suggestionsCount={suggestionsCount}
       />
    )}
    {commentable.type === 'DateOption' && (
      <DateOptionPage
        dateOption={commentable as DateOptionType}
      />
    )}
    {commentable.type === 'Destination' && (
      <DestinationPage
        destination={commentable as DestinationGid}
        isPlanner={isUserPlanner}
        suggestionsCount={suggestionsCount}
      />
    )}
    {commentable.type === 'Poll' && (
      <PollView
        poll={commentable as Poll}
        wrapWithLink={false}
        isExpanded={true}
      />
    )}
    {commentable.type === 'Broadcast' && (
      <BroadcastView
        broadcast={commentable as Broadcast}
        tripId={tripId}
        showLink={false}
      />
    )}
    {commentable.type === 'Activity' && (
      <ActivityPage
        activity={commentable as ActivityType}
        isPlanner={isUserPlanner}
      />
    )}
    <Divider className={classes.divider} />
    <Comments
      commentable={commentable}
      comments={comments}
      commentCreationError={commentCreationError}
      isUserLoggedIn={isUserLoggedIn}
      headerText={commentHeaderText}
    />
  </>;
}

// noinspection JSUnusedGlobalSymbols
export default withLayout(IndexPage);
