import React from 'react';
import { makeStyles, DefaultTheme } from "@mui/styles";
import ExternalListingContent from './ExternalListingCard/ExternalListingContent';
import FavoriteToggleButton from './ExternalListingCard/FavoriteToggleButton';
import ListingPricing from "./ExternalListingCard/ListingPricing";
import TripObject, { Variation } from '../../../shared/TripObject';
import ImagesCarousel from './ExternalListingCard/ImagesCarousel';
import { ExternalListingProps } from "../../../shared/TripObjectTypes";
import { RenderCtaButtonType } from "../StaysSearch";
import { isCurrentlyInPublicSearch } from "../../../../services/windowLocation";
import ShareButton from "../../../shared/ShareButton";

type ExternalListingCardProps = {
  stay: ExternalListingProps,
  renderCtaButton: RenderCtaButtonType,
  footer?: React.ReactNode,
  displayToggleFavoriteButton?: boolean,
  favoriteListingId?: string,
};

const useStyles = makeStyles<DefaultTheme, { displayFavoriteButton: boolean }>(() => ({
  root: {
    border: 'none',
    '& .slider-frame': {
      borderRadius: '10px',
    },
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  shareButton: {
    position: 'absolute',
    top: '16px',
    right: ({ displayFavoriteButton }) => displayFavoriteButton ? '56px' : '16px',
    zIndex: 1,
  },
}));

const ExternalListingCard = ({
  stay,
  renderCtaButton,
  footer,
  displayToggleFavoriteButton = false,
  favoriteListingId,
}: ExternalListingCardProps) => {
  const displayFavoriteButton = displayToggleFavoriteButton || isCurrentlyInPublicSearch();
  const classes = useStyles({ displayFavoriteButton });

  const ctaButton = renderCtaButton({stay});
  const pricing =
    <ListingPricing
      available={stay.available}
      pricing={stay.pricing}
      isHotel={stay.provider?.toLowerCase() === 'expedia'}
    />;

  const contentForListView =
    <ExternalListingContent
      stay={stay}
      nameLink={stay.publicUrl}
      pricing={pricing}
      ctaButton={ctaButton}
    />;

  const stayImages =
    <ImagesCarousel
      images={stay.images}
      linkHref={stay.publicUrl}
    />

  return React.useMemo(() => (
    <TripObject
      className={classes.root}
      contentClassName={classes.content}
      testId='external-stay-listing'
      variation={Variation.Vertical}
      visual={
        <>
          <ShareButton className={classes.shareButton} url={stay.publicUrl} />
          {displayFavoriteButton && <FavoriteToggleButton favoriteListingId={favoriteListingId} stay={stay} />}
          {stayImages}
        </>
      }
      content={contentForListView}
      footer={footer}
    />
  ), [stay]);
};

export default ExternalListingCard;
