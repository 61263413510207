import React from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material"
import SendIcon from "@mui/icons-material/Send";
import RailsForm from "../../../../../shared/RailsForm";

type NoteFormProps = {
  objectPath: string,
};

const NoteForm = ({
  objectPath,
}: NoteFormProps) => (
  <RailsForm action={objectPath} method="patch">
    <Typography variant='h3' mb={2}>Add a note</Typography>
    <TextField
      label="Any additional info to share?"
      variant="outlined"
      name="note"
      maxRows={10}
      fullWidth
      multiline
      data-test-id='note-field'
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton type="submit">
              <SendIcon />
            </IconButton>
          </InputAdornment>,
      }}
    />
  </RailsForm>
);

export default NoteForm;
