import React from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Avatar, { statusToVariant } from "../../../../avatars/Avatar";
import Link from "../../../../shared/Link";
import { TravelerProps } from '../../../../shared/TravelersVotingBox';
import TravelerDetailsDialog from './TravelerDetailsDialog';
import { humanizedStatus } from '../../../../../helpers/statusToHuman';
import { useTravelerClickEvent } from '../../../../../services/segmentEvents/useTravelerPlusOnesDialogEvents';
import clsx from 'clsx';
import { GuestType } from '../../../../../types/GuestType';

type TravelersRowProps = {
  travelers: (TravelerProps | GuestType)[];
  onTravelerClick: (traveler: TravelerProps) => void;
};

type AttendeesAvatarsProps = {
  travelers: (TravelerProps | GuestType)[];
  expanded: boolean,
  onToggleClick: () => void,
  guests?: GuestType[],
};

const useStyle = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    maxWidth: 660,
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  travelerContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  travelerName: {
    fontSize: 16,
    color: '#272727',
    marginTop: theme.spacing(1),
    textAlign: 'center',
    lineHeight: '24px',
    maxWidth: '88px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  travelerStatus: {
    color: '#505050',
    fontStyle: 'italic',
    fontSize: 14,
    lineHeight: '24px',
    textAlign: 'center',
  },
  avatarsToggle: {
    color: theme.palette.primary.main,
    display: 'block',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.spacing(2),
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  travelerContainerPointer: {
    cursor: 'pointer',
  },
}));

export const TravelersRow = ({
  travelers,
  onTravelerClick,
}: TravelersRowProps) => {
  const classes = useStyle();

  const travelerHasPlusOnes = (traveler :TravelerProps ) => (
    traveler.plusOnes?.length > 0
  )

  const renderUserTraveler = (traveler: TravelerProps) => (
    <Grid
      key={`user_${traveler.id}`}
      item
      className={clsx(classes.travelerContainer, { [classes.travelerContainerPointer]: travelerHasPlusOnes(traveler) })}
      data-test-id="userTripTraveler"
      onClick={() => travelerHasPlusOnes(traveler) && onTravelerClick(traveler)}
    >
      <Grid item>
        <Avatar
          borderVariant={statusToVariant[traveler.status]}
          src={traveler.avatarUrl}
          size="lg"
          plusOnes={traveler.plusOnes?.length || 0}
        />
      </Grid>
      <Grid item>
        <Typography className={classes.travelerName}>
          {traveler.name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.travelerStatus}>
          {humanizedStatus(traveler.status)}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderGuestTraveler = (traveler: GuestType) => (
    <Grid
      key={`guest_${traveler.uuid}`}
      item
      className={classes.travelerContainer}
      data-test-id="guestTripTraveler"
    >
      <Grid item>
        <Avatar
          borderVariant='success'
          size="lg"
        >
          <img
            alt="guest avatar"
            src='/assets/guest_avatar.svg'
            style={{maxWidth: '100%', maxHeight: '100%'}}
          />
        </Avatar>
      </Grid>
      <Grid item>
        <Typography className={classes.travelerName}>
          {traveler.displayName}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.travelerStatus}>
          Going
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid container justifyContent="center" wrap="nowrap">
      {travelers.map( (traveler : any) => (
        traveler.userId ? renderUserTraveler(traveler as TravelerProps) : renderGuestTraveler(traveler as GuestType)
      ))}
    </Grid>
  )
}

const collapsedTravelersRows = (travelers, avatarsPerLine, onTravelerClick) => {
  let rows = [];

  for (let i = avatarsPerLine; i < travelers.length; i+=avatarsPerLine) {
    let rowTravelers = travelers.slice(i, i+avatarsPerLine);
    rows.push(<TravelersRow travelers={rowTravelers} onTravelerClick={onTravelerClick} />);
  }

  return rows;
}

const AttendeesAvatars = ({
  travelers,
  expanded,
  onToggleClick,
}: AttendeesAvatarsProps) => {
  const classes = useStyle();
  const avatarsPerLine = 4;
  const seeLessMoreText = `See ${expanded ? 'less' : `${travelers.length - avatarsPerLine} more`}`;
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [clickedTraveler, setClickedTraveler] = React.useState<TravelerProps | undefined>(undefined);

  const travelerClickEvent = useTravelerClickEvent();

  const onTravelerClick = (traveler : TravelerProps) => {
    setClickedTraveler(traveler);
    setIsDialogOpen(true);
    travelerClickEvent(traveler);
  };

  return (
    <Box className={classes.root} data-test-id="tripTravelers">
      <TravelersRow travelers={travelers.slice(0, avatarsPerLine)} onTravelerClick={onTravelerClick}/>

      {(travelers.length > avatarsPerLine) && (
        <>
          {expanded && collapsedTravelersRows(travelers, avatarsPerLine, onTravelerClick) }
          <Link
            className={classes.avatarsToggle}
            onClick={onToggleClick}
            text={seeLessMoreText}
          />
        </>
      )}

      <TravelerDetailsDialog
        setIsDialogOpen={setIsDialogOpen}
        isDialogOpen={isDialogOpen}
        traveler={clickedTraveler}
      />
    </Box>
  );
};

export default AttendeesAvatars;
