import React from "react";
import RichTextEditor from "./RichTextEditor";

type RichTextProps = {
  value: string,
};

const RichText = ({
  value,
}: RichTextProps) => {
  return (
    <div data-test-id="richText">
      <RichTextEditor defaultValue={value} readOnly={true} disabled />
    </div>
  );
};

export default RichText;
