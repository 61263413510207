import React from "react";
import { makeStyles } from '@mui/styles';
import {
  Box,
  ButtonBase,
  Grid,
  Typography,
} from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll'
import CustomChip from '../../buttons/CustomChip';
import destinationsByTripType from './destinationsByTripType';
import { ListingsContext } from "./StaysSearch";
import { useTrackSERPEvent } from "../../../services/segmentEvents/useSERPEvents";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'inline-block',
  },
  scroll: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  destination: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 204,
    height: 192,
    position: 'relative',
    borderRadius: '5px',
    marginRight: '14px',
    padding: '5px',
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 600,
    whiteSpace: 'break-spaces',
  },
  chipsContainer: {
    overflowX: 'auto',
    flexWrap: 'nowrap',
  },
  destinationsContainer: {
    backgroundColor: '#EFF2F4',
    borderRadius: '10px',
    padding: theme.spacing(2),
  },
}))

type DestinationCardProps = {
  destination: {
    image: string,
    name: string,
    latitude: number,
    longitude: number,
  },
  index: number,
  tripType: string,
};

const DestinationCard: React.FC<DestinationCardProps> = ({destination, index, tripType}) => {
  const classes = useStyles({});
  const { trackSERPEvent } = useTrackSERPEvent();
  const { onDestinationTileClick } = React.useContext(ListingsContext);

  const onClick = () => {
    trackSERPEvent('Trip Type Destination Tile clicked', {
      destination: destination.name,
      position: index,
      tripType,
    });

    onDestinationTileClick(destination);
  };

  return (
    <ButtonBase
      disableRipple
      className={classes.destination}
      sx={{ background: `url("${destination.image}") no-repeat center/cover` }}
      onClick={onClick}
    >
      {destination.name}
    </ButtonBase>
  );
};

const TripTypeDestinations = () => {
  const classes = useStyles();
  const preFilteredTripType = new URLSearchParams(window.location.search).get('trip_type');
  const preFilteredTripTypeIndex = destinationsByTripType.findIndex(obj => obj.tripType.toLowerCase() === preFilteredTripType);

  const [selected, setSelected] = React.useState(preFilteredTripTypeIndex > -1 ? preFilteredTripTypeIndex : 0);
  const selectedTripType = destinationsByTripType[selected];
  const { trackSERPEvent } = useTrackSERPEvent();
  const scrollRef = React.useRef(null);

  const onChipClick = (index) => {
    trackSERPEvent('Trip Type Filtered', { tripType: destinationsByTripType[index].tripType });
    setSelected(index);
  };

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.container.current.scrollLeft = 0;
    }
  }, [selected])

  const renderChips = () => (
    <Grid container spacing={2} className={classes.chipsContainer}>
      {destinationsByTripType.map(({ tripType }, index) => (
        <Grid item key={tripType}>
          <CustomChip
            active={selected === index}
            onClick={() => onChipClick(index)}
            trackEventDisabled
          >
            {tripType}
          </CustomChip>
        </Grid>
      ))}
    </Grid>
  );

  const renderDestinations = () => (
    <Box className={classes.destinationsContainer}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3">{selectedTripType.tripType}</Typography>
        </Grid>
        <Grid item>
          <Typography>{selectedTripType.description}</Typography>
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <ScrollContainer className={classes.scroll} ref={scrollRef}>
            <Box className={classes.wrapper}>
              {selectedTripType.destinations.map((destination, index) =>
                <DestinationCard
                  destination={destination}
                  index={index}
                  tripType={selectedTripType.tripType}
                  key={destination.name}
                />
              )}
            </Box>
          </ScrollContainer>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h2">Top destinations for Group Trips</Typography>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        {renderChips()}
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        {renderDestinations()}
      </Grid>
    </Grid>
  );
};

export default TripTypeDestinations;
