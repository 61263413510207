import React, {useEffect, useReducer, useState } from "react";
import axios from 'axios';
import {
  Box, Grid,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Filters, { CurrentAppliedFilters, aiSearchType } from "./Listings/Filters";
import ListingsBox from "./Listings/ListingsBox";
import ListingsMapView from "./Listings/ListingsMapView";
import { ExternalListingProps } from "../../shared/TripObjectTypes";
import {
  useTrackSERPEvent,
  useTrackSearchPerformed,
} from "../../../services/segmentEvents/useSERPEvents";
import RenderWithOrWithoutSidebar from "../../../services/RenderWithOrWithoutSidebar";
import { inventoryBannerSegmentText } from "./Listings/InventoryBanner";
import RecentlySearched from "./Listings/RecentlySearched";
import { TripSiteContext } from "../../Document"
import {
  createFormDataForFiltering,
} from "../../../services/createFormDataForFiltering";

export const ListingsContext = React.createContext({
  onAlternativeTileClick: (alternative) => {},
  onDestinationTileClick: (destination) => {},
  onPromptTileClick: (prompt, position) => {},
  onPromptExampleClick: (prompt) => {},
  onExternalListingAdd: null,
  onMapPositionChange: (lat: number, lng: number, radius: number) => {},
  toggleView: () => {},
  latitude: null,
  longitude: null,
  openFiltersDrawer: () => {},
  closeFiltersDrawer: () => {},
  isFiltersDrawerOpen: false,
  openSearchCriteriaDrawer: () => {},
  closeSearchCriteriaDrawer: () => {},
  isSearchCriteriaDrawerOpen: false,
  filters: null,
});

export type SuggestedDestinationType = {
  id: string,
  name: string,
  image: string,
  ranking?: number,
  longitude: string,
  latitude: string,
}

export type DateOptionProps = {
  endDate: string,
  formattedEndDate: string,
  formattedStartDate: string,
  isChosenDate: boolean,
  isPast: boolean,
  startDate: string,
};

export type CustomFiltersAppliedType = {
  dates: boolean,
  minGuests: boolean,
  propertyTypes: boolean,
  bedroomCount: boolean,
  bathroomCount: boolean,
  rating: boolean,
};

export type RenderCtaButtonType = (_: {stay: ExternalListingProps}) => React.ReactNode;

type StaysSearchProps = {
  currentAppliedFilters: CurrentAppliedFilters,
  customFiltersApplied: CustomFiltersAppliedType,
  dateOptions: DateOptionProps[],
  topSuggestedDestinations: SuggestedDestinationType[],
  basePath: string,
  apiSearchPath: string,
  renderCtaButton: RenderCtaButtonType,
  SearchBackground: any,
  showShortBackground?: (value: boolean) => void,
  hasSearched?: boolean,
  aiSearch?: boolean,
  aiSearchType?: aiSearchType,
};

type ListingsRequestConfig = {
  historyMethod?: HistoryStateMethod | null
  pageUrl?: string,
  retrySameSearch?: boolean,
  skipCache?: boolean,
};

type HistoryStateMethod = 'pushState' | 'replaceState';

const reducer = (state, action) => {
  const setState = (props) => Object.assign({}, state, props);

  switch (action.type) {
    case 'openSearchCriteriaDrawer':
      return setState({ isSearchCriteriaDrawerOpen: true });
    case 'closeSearchCriteriaDrawer':
      return setState({ isSearchCriteriaDrawerOpen: false });
    case 'openDrawer':
      return setState({ isFiltersDrawerOpen: true });
    case 'closeDrawer':
      return setState({ isFiltersDrawerOpen: false });
    case 'toggleView': {

      const viewMode = state.viewMode === 'list' ? 'map' : 'list';
      return setState({ viewMode: viewMode });
    }
    case 'filterListings': {
      const filtering = !!action.filterData.get('location_name')?.trim() ||
        !!action.filterData.get('prompt')?.trim();

      const filters = { prompt: action.filterData.prompt };
      return setState({ filters, filtering, filterData: action.filterData, initialLoad: action.initialLoad });
    }
    case 'modifyFilter': {
      const formData = createFormDataForFiltering(action.value)

      return setState({ filterData: formData, filtering: true })
    }
    case 'loadListings': {
      const { arrival, departure, inventory, location_name: locationName, min_guests: minGuests } = action.params;
      const searchCriteria = { arrival, departure, inventory, locationName, minGuests }
      const filters = { ...state.filters, ...searchCriteria };
      return setState({ loading: true, listings: [], filters });
    }
    case 'showListings':
      return setState({
        customFiltersApplied: action.customFiltersApplied,
        filters: action.filters,
        filterData: action.filterData,
        filtering: false,
        listings: action.listings,
        loading: false,
        pagination: action.pagination,
        availability: action.availability,
      });
    case 'error':
      return setState({
        customFiltersApplied: action.customFiltersApplied,
        filters: action.filters,
        filterData: action.filterData,
        filtering: false,
        listings: action.listings,
        loading: false,
        error: true,
      });
    case 'updateListings':
      return setState({ listings: action.listings });
    default:
      throw new Error();
  }
};

const useStyles = makeStyles((theme) => ({
  buttonText: {
    marginLeft: theme.spacing(1),
  },
  formContent: {
    flex: 1,
    paddingBottom: theme.spacing(9),
  },
  mapWrapper: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    overflowY: 'hidden',
    zIndex: 1001,
    top: '0%',
    left: 0,
  },
  sidebarMapWrapper: {
    width: '100%',
    height: `calc(80vh - ${theme.spacing(8)})`,
    left: 0,
  },
}));

const StaysSearch = ({
  currentAppliedFilters,
  customFiltersApplied,
  dateOptions,
  topSuggestedDestinations,
  basePath,
  apiSearchPath,
  renderCtaButton,
  SearchBackground,
  showShortBackground = () => {},
  hasSearched = false,
  aiSearch = false,
  aiSearchType,
}: StaysSearchProps) => {
  const { tripId } = React.useContext(TripSiteContext);
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { trackSERPEvent } = useTrackSERPEvent();
  const trackSearchPerformed = useTrackSearchPerformed();

  const searchParams = new URLSearchParams(window.location.search);
  const { viewMode = 'list', ...initialFilterData } = Object.fromEntries(searchParams.entries());
  const initialState = {
    filtering: false,
    filterData: new URLSearchParams(initialFilterData),
    filters: currentAppliedFilters,
    customFiltersApplied: customFiltersApplied,
    listings: null,
    loading: false,
    viewMode: viewMode,
    pagination: {},
    initialLoad: true,
    availability: undefined,
    isFiltersDrawerOpen: false,
    isSearchCriteriaDrawerOpen: false,
    prompt: undefined,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [locationNameState, setLocationNameState] = useState(currentAppliedFilters.locationName || null);
  const [prompt, setPrompt] = useState(currentAppliedFilters.prompt);
  const [modelOutputs, setModelOutputs] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const openFiltersDrawer = () => {
    trackSERPEvent('SERP Sort/Filter Initiated', {});
    dispatch({ type: 'openDrawer' })
  };
  const closeFiltersDrawer = () => dispatch({ type: 'closeDrawer' });
  const openSearchCriteriaDrawer = () => {
    trackSERPEvent('Search Criteria Drawer Initiated', {
      location: aiSearchType,
    });
    dispatch({ type: 'openSearchCriteriaDrawer' })
  };
  const closeSearchCriteriaDrawer = () => {
    trackSERPEvent('Search Criteria Drawer Dismissed', {location: aiSearchType});
    dispatch({ type: 'closeSearchCriteriaDrawer' })
  };

  useEffect(() => {
    if (locationName() || currentPrompt()) {
      setModelOutputs(null)
      fetchListings({ historyMethod: 'replaceState' });
    } else {
      dispatchEmptyState();
    }

    window.addEventListener('popstate', (event) => {
      const {
        pagination = {},
        viewMode = 'list',
        ...filterParams
      } = event.state || {};
      const pageUrl = buildUrl(apiSearchPath, { ...filterParams, page: pagination?.page, viewMode: viewMode });

      setLocationNameState(filterParams.location_name)
      setPrompt(filterParams.prompt)
      setModelOutputs(null)

      if (filterParams.location_name || filterParams.prompt) {
        fetchListings({ pageUrl, historyMethod: null });
      } else {
        dispatchEmptyState();
      }
    });
  }, []);

  useEffect(() => {
    if (!state.filtering) return

    if (locationName() || currentPrompt()) {
      setModelOutputs(null)
      fetchListings();
    } else {
      dispatchEmptyState()
      const pathParams = new URLSearchParams({ ...state.filterData });
      const newUrl = buildUrl(basePath, pathParams);
      history.pushState({ ...state.filterData, pagination: {} }, '', newUrl);
    }
  }, [state.filtering]);

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(window.location.search));

    if (params.viewMode !== state.viewMode && (locationName() || currentPrompt())) {
      const newParams = new URLSearchParams({ ...params, viewMode: state.viewMode });
      const newUrl = buildUrl(basePath, newParams);

      history.pushState(Object.assign({}, history.state, { viewMode: state.viewMode }), '', newUrl);
    }
  }, [state.viewMode]);

  const onExternalListingAdd = (listingId) => {
    const updatedListings = state.listings.map((listing) =>
      listing.id === listingId ? { ...listing, alreadyOnTrip: true } : listing,
    );
    dispatch({ type: 'updateListings', listings: updatedListings });
  };

  const toggleView = () => {
    dispatch({ type: 'toggleView' });
  };

  const onMapPositionChange = (lat, lng, radius) => {
    const filterData = Object.assign(
      initialFilterData,
      { latitude: lat, longitude: lng, radius, location_name: 'Custom Map Area' },
    );

    analytics.page();

    if(state.viewMode === 'map') {
      dispatch({ type: 'filterListings', filterData: new URLSearchParams(filterData) })
    }
  };

  const fetchListings = ({
    historyMethod = 'pushState',
    pageUrl = apiSearchPath,
    retrySameSearch = false,
    skipCache = false,
  }: ListingsRequestConfig = {}): void => {
    const queryString = new URL(pageUrl, window.location.origin).searchParams
    const params = Object.assign(
      { page: 1 },
      Object.fromEntries(state.filterData),
      Object.fromEntries(queryString),
      { viewMode: state.viewMode, isMobile: `${isMobile}` },
      tripId ? { trip_id: tripId } : {},
    );
    const newUrl = buildUrl(basePath, params);

    if (state.viewMode === 'list') dispatch({ type: 'loadListings', initialLoad: false, params })
    showShortBackground(true);
    axios.get(pageUrl, { params: { ...params, skipCache } })
      .then(response => {
        const {data} = response;

        dispatch({
          type: 'showListings',
          listings: data.listings,
          pagination: data.pagy,
          filterData: state.filterData,
          filters: data.currentAppliedFilters,
          customFiltersApplied: data.customFiltersApplied,
          initialLoad: false,
          availability: data.availability,
        })

        if (aiSearch) {
          setPrompt(data.currentAppliedFilters.prompt)
          if (params.search_method !== 'troupieAlternativeSuggestions') {
            const { filters, id: requestId } = data.modelOutputs ?? {};

            setModelOutputs({ filters, requestId, searchMethod: params.search_method });
          }
        }

        trackSearchPerformed({
          arrival: data.currentAppliedFilters.arrival,
          bedroomCount: data.currentAppliedFilters.bedroomCount,
          filtersApplied: data.customFiltersApplied,
          datesBanner: inventoryBannerSegmentText(data.availability * 100),
          departure: data.currentAppliedFilters.departure,
          destinationSearched: data.currentAppliedFilters.locationName,
          destinationLatLong: [data.currentAppliedFilters.latitude, data.currentAppliedFilters.longitude],
          guestsCount: data.currentAppliedFilters.minGuests,
          hotelRoomCount: data.currentAppliedFilters.hotelRoomCount,
          inventorySelected: data.currentAppliedFilters.inventory,
          resultCountPage: data.pagy.to - data.pagy.from + 1,
          searchMethod: retrySameSearch ? 'Try Again CTA' : data.currentAppliedFilters.searchMethod,
          totalResultCount: data.pagy.count,
          prompt: data.currentAppliedFilters.prompt,
          modelOutputs: data.modelOutputs,
          url: newUrl,
        });

        if (historyMethod) {
          history[historyMethod]({ ...params, pagination: data.pagy }, '', newUrl);
        }

        if (state.viewMode !== data.viewMode) toggleView();
      })
      .catch(error => {
        if (error.response) {
          const { data } = error.response;
          dispatch({
            type: 'error',
            listings: data?.listings ?? [],
            filterData: state.filterData,
            filters: !aiSearch && (data?.currentAppliedFilters || { prompt: params.prompt }),
            customFiltersApplied: data?.customFiltersApplied,
            initialLoad: false,
          })

          if (historyMethod) {
            const pathParams = new URLSearchParams({ ...params, page: state.pagination?.page ?? 1 });
            const newUrl = buildUrl(basePath, pathParams);
            history[historyMethod]({ ...params, pagination: state.pagination }, '', newUrl);
          }
        }
      })

    window.scrollTo(0, 0);
  };

  const buildUrl = (path, params = {}) => {
    return `${path}?${new URLSearchParams(params).toString()}`;
  };

  const filterParams = Object.fromEntries(state.filterData);
  const locationName = () => state.filters?.locationName || currentAppliedFilters.locationName || filterParams.location_name?.trim() || locationNameState;
  const currentPrompt = () => filterParams.prompt?.trim() || currentAppliedFilters.prompt || prompt || '';

  const dispatchEmptyState = () => {
    dispatch({
      type: 'showListings',
      listings: [],
      pagination: {},
      filterData: state.filterData,
      filters: currentAppliedFilters,
      customFiltersApplied: customFiltersApplied,
      initialLoad: false,
    })
  }

  const withResults = (state.listings && (state.listings.length > 0));
  const showFiltersButton = withResults || !!filterParams.location_name;

  const onPromptTileClick = (suggestion, position) => {
    const prompt = suggestion.prompt;
    const filterData = {
      ...Object.fromEntries(state.filterData),
      prompt,
      search_method: 'promptTile',
    };

    trackSERPEvent('AI Search Prompt Tile Clicked', {
      promptTile: prompt,
      tilePosition: position,
    });
    dispatch({type: 'filterListings', filterData: new URLSearchParams(filterData)});
  }

  const onPromptExampleClick = (prompt) => {
    const filterData = {
      ...Object.fromEntries(state.filterData),
      prompt,
      search_method: 'promptExample',
    };

    dispatch({type: 'filterListings', filterData: new URLSearchParams(filterData)});
  };

  const onDestinationTileClick = (destination) => {
    const filterData = {
      ...Object.fromEntries(state.filterData),
      latitude: destination.latitude,
      longitude: destination.longitude,
      location_name: destination.name,
      search_method: 'destinationTile',
    };

    dispatch({type: 'filterListings', filterData: new URLSearchParams(filterData)});
  };

  const onAlternativeTileClick = (alternative) => {
    const filterData = {
      ...state.filters,
      latitude: alternative.latitude,
      longitude: alternative.longitude,
      location_name: alternative.name,
      search_method: 'alternativeTile',
    };

    dispatch({type: 'modifyFilter', value: filterData});
  };

  const listingsContextValue = {
    onAlternativeTileClick,
    onDestinationTileClick,
    onPromptExampleClick,
    onPromptTileClick,
    onExternalListingAdd,
    onMapPositionChange,
    toggleView,
    latitude: state.filters?.latitude,
    longitude: state.filters?.longitude,
    openFiltersDrawer,
    closeFiltersDrawer,
    isFiltersDrawerOpen: state.isFiltersDrawerOpen,
    openSearchCriteriaDrawer,
    closeSearchCriteriaDrawer,
    isSearchCriteriaDrawerOpen: state.isSearchCriteriaDrawerOpen,
    filters: state.filters,
  };

  return (
    <ListingsContext.Provider value={listingsContextValue}>
      {state.viewMode === 'map' && (
        <Box className={classes.mapWrapper} data-test-id="mapView">
          <ListingsMapView
            radius={Object.fromEntries(state.filterData).radius}
            listings={state.listings}
            aiSearch={aiSearch}
            aiSearchType={aiSearchType}
          />
        </Box>
      )}
      <Box className={classes.formContent} data-test-id="listView">
        <SearchBackground logoPath={basePath} hasPrompt={!!currentPrompt()}>
          <Filters
            currentAppliedFilters={state.filters}
            customFiltersApplied={state.customFiltersApplied}
            filterFormPath={apiSearchPath}
            dateOptions={dateOptions}
            hasSearched={hasSearched}
            onFilter={(filterData) => dispatch({ type: 'filterListings', filterData })}
            searchPath={basePath}
            showFiltersButton={showFiltersButton}
            isLoading={state.loading}
            aiSearch={aiSearch}
            prompt={currentPrompt()}
            aiSearchType={aiSearchType}
            isDialogOpen={isDialogOpen}
            onDialogClose={() => setIsDialogOpen(false)}
          />
        </SearchBackground>
        <RecentlySearched />
        <RenderWithOrWithoutSidebar
          showWithSidebar={withResults}
          radius={Object.fromEntries(state.filterData).radius}
          hasSearched={hasSearched}
          modelOutputs={modelOutputs}
          currentAppliedFilters={state.filters}
          component={
            <Grid mt={0}>
              <ListingsBox
                fetchListings={fetchListings}
                initialLoad={state.initialLoad}
                isLoading={state.loading}
                listings={state.listings}
                locationName={locationName()}
                pagination={state.pagination}
                error={state.error}
                topSuggestedDestinations={topSuggestedDestinations}
                renderCtaButton={renderCtaButton}
                availability={state.availability}
                aiSearch={aiSearch}
                aiSearchType={aiSearchType}
                modelOutputs={modelOutputs}
                currentAppliedFilters={state.filters}
                triggerFilterDialog={(value) => setIsDialogOpen(value)}
                onFilter={(value) => dispatch({ type: 'modifyFilter', value })}
                prompt={currentPrompt()}
              />
            </Grid>
          }
          listings={state.listings}
          onClick={() => dispatch({ type: 'toggleView' })}
          locationName={locationName()}
          aiSearchType={aiSearchType}
        />
      </Box>
    </ListingsContext.Provider>
  );
};

export default StaysSearch;
