import React from "react";
import clsx from "clsx";
import ButtonBase from "@mui/material/ButtonBase";
import { makeStyles } from "@mui/styles";

export type FlatButtonProps = {
  children: React.ReactNode,
  className?: string,
  disabled?: boolean,
  onClick?: () => void,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    border: '1px solid #979797',
    backgroundColor: 'white',
    color: '#000000',
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(8),
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: 'rgba(84, 93, 252, 0.04)',
    },
  },
  disabled: {
    borderColor: '#d4d5d6',
    color: '#d4d5d6 !important',
  },
}));

const FlatButton = ({
  children,
  className,
  disabled,
  ...buttonProps
}: FlatButtonProps) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={clsx(classes.root, className, { [classes.disabled]: disabled })}
      disabled={disabled}
      {...buttonProps}
    >
      {children}
    </ButtonBase>
  );
};

export default FlatButton;
