import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { makeStyles, DefaultTheme } from "@mui/styles";

type InventoryRadioGroupProps = {
  hasSearched: boolean,
  onChange: (value: any) => void,
  value: string,
  isLoading: boolean,
};

const useStyles = makeStyles<DefaultTheme, { hasSearched: boolean }>((theme) => ({
  label: {
    fontSize: 14,
    color: ({ hasSearched }) => hasSearched ? '#FFFFFF' : theme.palette.text.primary,
    [theme.breakpoints.down(360)]: {
      fontSize: 12,
    },
  },
}));

const InventoryRadioGroup = ({
  hasSearched,
  onChange,
  value = 'all',
  isLoading,
}: InventoryRadioGroupProps) => {
  const classes = useStyles({ hasSearched });

  const RadioButton : React.FC<{value: string, label: string, disabled: boolean}> = ({
    value, label, disabled,
  }) => (
    <FormControlLabel
      classes={{ label: classes.label }}
      control={<Radio size="small" />}
      label={label}
      value={value}
      disabled={disabled}
    />
  );

  return (
    <FormControl>
      <RadioGroup
        sx={{ flexWrap: 'nowrap' }}
        row
        name="inventory"
        defaultValue={value}
        onChange={(_, value) => onChange({inventory: value, searchMethod: 'inventoryButton'})}
      >
        <RadioButton disabled={isLoading} value="all" label="All" />
        <RadioButton disabled={isLoading} value="vacation_rentals" label="Vacation rentals" />
        <RadioButton disabled={isLoading} value="hotels" label="Hotels" />
      </RadioGroup>
    </FormControl>
  );
};

export default InventoryRadioGroup;
