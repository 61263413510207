import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
} from "@mui/material";
import Link from "../../../../shared/Link";
import ConfirmationDialog from "../../../../shared/ConfirmationDialog";
import { TypeTripObjectType } from '../../../../shared/TripObjectTypes';

type BackToSuggestingButtonProps = {
  transitionToSuggestingPath: string,
  tripObjectType: TypeTripObjectType,
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

const BackToSuggestingButton = ({
  transitionToSuggestingPath,
  tripObjectType,
}: BackToSuggestingButtonProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Grid className={classes.root} container spacing={2}>
      <ConfirmationDialog
        actionMethod="PATCH"
        actionPath={transitionToSuggestingPath}
        actionText='Yes, go back'
        isOpen={dialogOpen}
        message={`Your group will be able to suggest and delete ${tripObjectType}.`}
        onClose={() => setDialogOpen(false)}
        title="Go back to suggesting?"
      />
      <Link
        className={classes.link}
        data-test-id="transition-to-suggesting-link"
        onClick={() => setDialogOpen(true)}
        text='Go back to suggesting'
      />
    </Grid>
  );
};

export default BackToSuggestingButton;
