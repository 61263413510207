import React from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { withStyles } from '@mui/styles';

const StyledCheckbox = withStyles({
  root: {
    color: "#555e64",
    '&$checked': {
      color: "#545DFC",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox {...props} />);

export default StyledCheckbox;
