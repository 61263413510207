export const isCurrentlyInPublicSearch = () => window.location.pathname === '/search';
export const isCurrentlySmartSearch = () => window.location.href.includes('/add_by_smart_search');
export const isCurrentlyNormalSearch = () => window.location.href.includes('/add_by_search');
export const isCurrentlyAiSearch = () => window.location.href.includes('/ai_search');

export const searchLocation = () => {
  return isCurrentlyNormalSearch() ? 'SERP' :
    isCurrentlyAiSearch() ? 'Public Search Troupie' :
      isCurrentlySmartSearch() ? 'Smart Search' : 'Public Search';
}

