import React from 'react';
import { TripSiteContext } from '../../components/Document';
import useSegment from '../useSegment';

export const useTrackEditStayClick = (stayId : string) => {
  const { trackSegment } = useSegment();
  return () => {
    trackSegment('Stay Edit Opened',
      {
        category: 'Stays Pricing',
        property: 'buttonClicked',
        objectId: stayId,
        method: 'overflow',
      },
    )
  }
};

export const useHandleMarkAsBookedClick = (method : string) => {
  const { trackSegment } = useSegment();
  const { staysState } = React.useContext(TripSiteContext);
  return () => {
    trackSegment('Mark as booked Initiated',
      {
        method,
        verticalStatus: staysState,
      },
    )
  }
};
