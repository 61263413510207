import React, { useContext } from "react";
import {
  Grid,
  Link,
  Theme,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import BookButton from "./Stay/BookButton";
import StayAddress from "./Stay/StayAddress";
import StayDetails from "./Stay/StayDetails";
import StayImages from "./Stay/StayImages";
import StayPricing from "./Stay/StayPricing";
import VotingSection from "./Stay/VotingSection";
import Divider from "../../../../shared/Divider";
import { StayType } from "../../../../shared/TripObjectTypes";
import RankingLabel from "../shared/RankingLabel";
import VotingSummaryDialog from '../../../../shared/VotingSummaryDialog';
import { usePhasingContext } from "../../../../../services/phasing";
import { TravelerProps } from "../../../../shared/TravelersVotingBox";
import StayContent from "./StayContent";
import CommentsLink from "../shared/CommentsLink";
import TripObject, { Variation } from "../../../../shared/TripObject";
import VotingProgressBanner from '../../../../shared/VotingProgressBanner';
import { TripSiteContext } from "../../../../Document";
import ActionsButtonGroup from "../../../../shared/ActionsButtonGroup";
import CommentIcon from '@mui/icons-material/Comment';
import Button from "../../../../buttons/Chip";
import SelectButton from '../../../../buttons/SelectButton';
import {
  editTripStayPath,
  tripStayPath,
  newTripStayBookingPath,
  editTripStayBookingPath,
} from '../../../../../routes';
import {
  useTrackEditStayClick,
  useHandleMarkAsBookedClick
} from "../../../../../services/segmentEvents/useStayCardEvents";
import { RANKING_SIZE } from "../../../../../services/RankingSystem";
import ShareButton from "../../../../shared/ShareButton";

type StyleProps = {
  showActions: boolean,
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  imgLink: {
    height: '100%',
  },
  commentButton: {
    height: '32px !important',
    zIndex: 2,
  },
  commentButtonContainer: {
    textAlign: 'right',
  },
  divider: {
    borderColor: '#e6e7e8',
    width:'100%',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  shareButton: {
    position: 'absolute',
    top: '11px',
    right: ({showActions}) => showActions ? '56px' : '16px',
    zIndex: 3,
  },
}));

type StayProps = {
  stay: StayType,
  cardGoesToProvider?: boolean,
  className?: string,
  displayActions?: boolean,
  displayBookButton?: boolean,
  displaySelectButton?: boolean,
  hideButtons?: boolean,
  hideCommentsLink?: boolean,
  hideDateOptionsAvailability?: boolean,
  hideDetails?: boolean,
  hideVotes?: boolean,
  showVotingProgressBanner?: boolean,
  imgPath?: string,
  isClickable?: boolean,
  isNameClickable?: boolean,
  isSelected?: boolean,
  isPlanner?: boolean,
  linkNameToProvider?: boolean,
  noCarousel?: boolean,
  onStayClick?: () => void,
  onSelect?: () => void,
  showActions?: boolean,
  suggestionsCount?: number,
  travelers?: TravelerProps[],
  variation?: Variation,
  setStayComment?: () => void,
  showCommentButton?: boolean,
  page?: 'index' | 'permapage' | null,
  openVotingProgress?: (voteCompleted: boolean) => void,
  hideAddPricingLink?: boolean,
  stayMethod?: "staysVertical" | "stayPermapage" | "itineraryPermapage" | "scheduleList" | null,
};

export const Stay = ({
  stay,
  cardGoesToProvider,
  className,
  displayBookButton = false,
  displaySelectButton = false,
  hideButtons = false,
  hideCommentsLink = false,
  hideDateOptionsAvailability,
  hideDetails,
  hideVotes = true,
  showVotingProgressBanner = false,
  imgPath,
  isClickable,
  isNameClickable = isClickable,
  isSelected = false,
  isPlanner,
  linkNameToProvider,
  noCarousel,
  onStayClick,
  onSelect = () => {},
  showActions,
  suggestionsCount,
  travelers,
  variation,
  displayActions = false,
  showCommentButton = false,
  setStayComment,
  page,
  openVotingProgress,
  hideAddPricingLink = false,
  stayMethod,
}: StayProps) => {
  const { areStaysInVotingPhase, areStaysFinalized } = usePhasingContext();
  const { currentUserId, tripId } = useContext(TripSiteContext);
  const stayId = stay.uuid ?? stay.id
  const chosenDate = stay.dateOptions.filter(date => date.isChosenDate);
  const hasChosenDate = chosenDate.length > 0;
  const dateOptionsAvailability = hasChosenDate ? chosenDate : stay.dateOptions;
  const chosenDatePrice = hasChosenDate ? chosenDate[0].price : null;
  const shouldRenderCarousel = noCarousel === undefined && !stay.isDisabled && stay.images.length > 1
  const providerLink = cardGoesToProvider ? stay.publicUrl : undefined;
  const commentsLink = isClickable && !onStayClick && stayId ? tripStayPath(tripId, stayId) : undefined;
  const shouldDisplayVotes = !hideVotes && (areStaysInVotingPhase() || areStaysFinalized());
  const stayNameLink = commentsLink ? commentsLink : stay.publicUrl;
  const shouldDisplayRankingLabel = !!(stay.ranking && stay.ranking <= RANKING_SIZE && areStaysInVotingPhase());
  const classes = useStyles({ showActions });
  const firstStayBooking = (stay.stayBookings || []).at(0)

  const displayVotingProgressBanner = showVotingProgressBanner && areStaysInVotingPhase();
  const stayRankings = travelers?.find(traveler => traveler.userId === currentUserId )?.stayRankings

  const onEditStayClick = useTrackEditStayClick(stayId);

  const handleMarkAsBookedClick = useHandleMarkAsBookedClick(stayMethod);

  const maybeWrapWithLink = (component, withLink) => {
    if (withLink) {
      return (
        <Link
          className={classes.imgLink}
          href={imgPath}
          target={cardGoesToProvider ? '_blank' : ''}
        >
          {component}
        </Link>
      )
    }
    return component
  };

  const stayPricing = (stay) => {
    const price = stay.price?.price || stay.price?.prices;
    if( !hideAddPricingLink || (hideAddPricingLink && price) ) {
      return (
        <StayPricing
          available={stay.available}
          averagePricing={stay.averagePricing}
          currency={stay.currency}
          dateOptions={dateOptionsAvailability}
          hasAutomaticAvailability={stay.hasAutomaticAvailability}
          isDisabled={stay.isDisabled}
          price={price}
          chosenDatePrice={chosenDatePrice}
          pricingType={stay.pricingType}
          stayPath={stayId ? editTripStayPath(tripId, stayId) : ''}
          stayId={stayId}
          tripId={tripId}
        />
      )
    }
  };

  return <div>
    <div className="anchor">
      <a id={`stay_${stayId}`} />
    </div>
    <TripObject
      noMargin
      isClickable={isClickable}
      linkTargetBlank={cardGoesToProvider}
      linkHref={providerLink || commentsLink}
      onTripObjectClick={onStayClick}
      className={className}
      testId="tripStay"
      variation={variation}
      header={<>
        <ShareButton className={classes.shareButton} url={stay.publicUrl} />
        {showActions && (
          <ActionsButtonGroup
            deletePath={tripStayPath(tripId, stayId)}
            editPath={editTripStayPath(tripId, stayId)}
            disableDeleteButton={stay.isChosen}
            testId="stayActions"
            resourceName="stay"
            onEditClick={onEditStayClick}
            onMarkAsBookedClick={handleMarkAsBookedClick}
            displayMarkAsBooked={!firstStayBooking}
            newBookingPath={newTripStayBookingPath(tripId, stayId)}
            updateBookingPath={firstStayBooking ? editTripStayBookingPath(tripId, stayId, firstStayBooking.uuid) : undefined}
          />
        )}
      </>}
      visual={
        <>
          {shouldDisplayRankingLabel && (<RankingLabel ranking={stay.ranking}/>)}
          {maybeWrapWithLink(
            <StayImages
              images={stay.images}
              isDisabled={stay.isDisabled}
              shouldRenderCarousel={shouldRenderCarousel}
              small={variation === Variation.Vertical}
            />,
            imgPath,
          )}
        </>
      }
      content={
        <>
          <Grid container direction="column" pb={2}>
            <StayContent
              stay={stay}
              className={className}
              hideDetails={hideDetails}
              isNameClickable={isNameClickable}
              isDisabled={stay.isDisabled}
              linkNameToProvider={linkNameToProvider}
              nameLink={stayNameLink}
              nameTargetLink={commentsLink ? null : 'blank'}
              pricing={stayPricing(stay)}
              variation={variation}
              onClick={onStayClick}
            />

            <Divider className={classes.divider} />

            {!hideButtons && (
              <Grid container>
                {!hideDateOptionsAvailability && <StayDetails page={page} publicUrl={stay.publicUrl} provider={stay.provider} />}
                {stay.googlePlaceUrl && <StayAddress googlePlaceUrl={stay.googlePlaceUrl}/>}
              </Grid>
            )}

            {displaySelectButton && (
              <Grid item>
                <SelectButton onClick={onSelect} isSelected={isSelected} />
              </Grid>
            )}

            {displayBookButton && stay.bookable && (
              <Grid item pt={1}>
                <BookButton url={stay.publicUrl} page={page} provider={stay.provider} />
              </Grid>
            )}

            {(shouldDisplayVotes || isClickable) && (
              <Grid
                item
                container
                justifyContent="space-between"
                pt={2}
              >
                <Grid item sx={{ zIndex: 2 }}>
                  {shouldDisplayVotes && (
                    <VotingSummaryDialog
                      isPlanner={isPlanner}
                      objectType='stays'
                      objectId={stayId}
                      travelers={travelers}
                    />
                  )}
                </Grid>
                <Grid item sx={{ zIndex: 2 }}>
                  {!hideCommentsLink && isClickable && (
                    <CommentsLink
                      commentsLink={commentsLink}
                      commentsCount={stay.commentsCount}
                      segment={{
                        tripId: tripId,
                        tripObjectId: stayId,
                        tripObjectType: 'stays',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            )}

            {displayActions && (
              <Grid
                item
                container
                spacing={1}
                pt={2}
              >
                {areStaysInVotingPhase() && (
                  <Grid item xs={showCommentButton ? 9 : 12} container justifyContent="center">
                    <VotingSection stay={stay} page={page} openVotingProgress={openVotingProgress} />
                  </Grid>
                )}
                {showCommentButton && (
                  <Grid
                    item
                    xs={areStaysInVotingPhase() ? 3 : 12}
                    className={classes.commentButtonContainer}
                  >
                    <Button
                      className={classes.commentButton}
                      onClick={() => setStayComment()}
                      segmentProps={{title: 'Comment Field Initiated', tripObjectId: stayId}}
                      data-test-id='commentButton'
                    >
                      <CommentIcon/>
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </>
      }
      footer={displayVotingProgressBanner &&
        <VotingProgressBanner
          rankings={stayRankings}
          suggestionsCount={suggestionsCount}
        />
      }
    />
  </div>;
};

export default Stay;
