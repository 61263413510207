import React from 'react';
import pluralize from "pluralize";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Avatar from '../../../../avatars/Avatar';

export type ActionCalloutProps = {
  currentUserAvatarUrl: string,
  missingVotesCount: number,
};
const useStyles = makeStyles((theme) => ({
  actionCalloutTitle: {
    color: '#FB5D5D',
    paddingLeft: theme.spacing(1),
  },
  actionCalloutTitleWrapper: {
    alignItems: 'center',
  },
  actionCalloutWrapper: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: '#FDF6EE',
    border: '1px solid #CBCBCB',
  },
  bold: {
    fontWeight: 600,
  },
}));

const ActionCallout = ({
  currentUserAvatarUrl,
  missingVotesCount,
}: ActionCalloutProps) => {
  const classes = useStyles();

  const missingMessage = () => (
    <Typography>
      <span className={classes.bold}>
        {missingVotesCount} {pluralize('vote', missingVotesCount)} remaining.
      </span> Cast your votes so your group can decide and book.
    </Typography>
  );

  return (
    <Box my={2} className={classes.actionCalloutWrapper}>
      <Grid item container spacing={1}>
        <Grid item container className={classes.actionCalloutTitleWrapper}>
          <Avatar src={currentUserAvatarUrl} size="xs" />
          <Typography
            variant="h2"
            className={classes.actionCalloutTitle}
          >
            Action needed
          </Typography>
        </Grid>
        <Grid item>
          {missingMessage()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActionCallout;
