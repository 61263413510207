import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from '@mui/material';
import StayImages from "../Stay/StayImages";
import { StayNameWithLink } from "../Stay/StayName";
import StayProperties from "../Stay/StayProperties";
import StayPricing from "../Stay/StayPricing";
import StayRating from "../Stay/StayRating";
import Button from "../../../../../buttons/Button";
import { StayType } from "../../../../../shared/TripObjectTypes";
import ProviderName from "../shared/ProviderName";
import * as routes from "../../../../../../routes"
import { TripSiteContext } from "../../../../../Document";

type StayMiniViewProps = {
  stay: StayType,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  dataContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  pricing: {
    fontSize: 16,
  },
  disabled: {
    color: ["#C0C0C0", '!important'],
  },
}));

const {
  tripStayPath,
} = routes

const StayMiniView = ({
  stay,
}: StayMiniViewProps) => {
  const classes = useStyles();
  const { tripId } = useContext(TripSiteContext)
  const shouldRenderCarousel = stay.images.length > 1 && !stay.isDisabled;
  const chosenDate = (stay.dateOptions || []).filter(date => date.isChosenDate);
  const hasChosenDate = chosenDate.length > 0;
  const chosenDatePrice = hasChosenDate ? chosenDate[0].price : null;

  return (
    <Grid container>
      <StayImages
        isExternalListing={false}
        images={stay.images}
        isDisabled={stay.isDisabled}
        shouldRenderCarousel={shouldRenderCarousel}
        small
      />
      <Grid container item className={classes.dataContainer} direction="column" spacing={1}>
        <Grid item>
          <ProviderName>
            {stay.provider}
          </ProviderName>
        </Grid>
        <Grid className={classes.name} item>
          <StayNameWithLink
            fullWidth
            isDisabled={stay.isDisabled}
            name={stay.name}
            stayUrl={stay.publicUrl}
          />
        </Grid>
        <Grid item>
          <StayPricing
            available={stay.available}
            averagePricing={stay.averagePricing}
            className={classes.pricing}
            chosenDatePrice={chosenDatePrice}
            currency={stay.currency}
            isDisabled={stay.isDisabled}
            price={stay.price?.price || stay.price?.prices}
            pricingType={stay.pricingType}
          />
        </Grid>
        <Grid
          item
          className={stay.isDisabled ? classes.disabled : ""}
          container
        >
          <StayProperties stay={stay} />
        </Grid>
        <Grid item>
          <StayRating stay={stay} />
        </Grid>
        <Grid item>
          <Button fullWidth href={tripStayPath(tripId, stay.uuid)} >
            Cast your vote
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StayMiniView;
