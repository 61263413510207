import SideBarPage from "../../../shared/SideBarPage";
import React from "react";
import { ItineraryDateType, ItineraryType } from "../../../shared/TripObjectTypes";
import ItineraryMainContent from "./ItineraryDatePermapage/ItineraryMainContent";
import withLayout from "./withLayout";


type ItineraryDatePermapageProps = {
  itinerary: ItineraryType,
  itineraryDate: ItineraryDateType,
  availableActivitiesCount: number,
};

const ItineraryDatePermapage = ({
  itineraryDate,
  itinerary,
  availableActivitiesCount,
}: ItineraryDatePermapageProps) => {

  return (
    <SideBarPage
      mainContent={
        <ItineraryMainContent
          itineraryDate={itineraryDate}
          itineraryDates={itinerary.itineraryDates}
          availableActivitiesCount={availableActivitiesCount}
          page={'permapage'}
        />
      }
    />
  )
}

export default withLayout(ItineraryDatePermapage, { fluid: true });
