import React, { useCallback } from 'react';
import clsx from "clsx";
import {
  Chip,
  Container,
  Grid,
  Typography,
  ButtonBase,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from "../../buttons/IconButton";
import { hocRailsAction } from '../../Document';
import TripActions from "./ListTripsPage/TripActions";
import CardButton from "../../buttons/CardButton";
import EmptySectionContentWithCTA from '../../shared/EmptySectionContentWithCTA';
import DismissibleAlert from "../../shared/DismissibleAlert";
import SiteHeader from "../../shared/SiteHeader";
import SiteFooter from "../../shared/SiteFooter";
import { newTripPath, tripPath } from '../../../routes';
import { trackLink as trackAnalyticsEvent } from '../../../services/analytics';
import { GlobalTrip } from '../../shared/TripTypes';
import TravelersAvatars from './TravelersAvatars';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  trip: {
    position: 'relative',
  },
  plannerChip: {
    color: '#ffffff',
    fontWeight: 600,
    fontSize: 14,
    height: theme.spacing(3),
    borderRadius: 3,
    backgroundColor: '#4dadb1',
    '& .MuiChip-label': {
      padding: '6px 9px 6px 15px',
    },
  },
  travelerChip: {
    backgroundColor: '#509cff',
  },
  tripBox: {
    borderRadius: theme.spacing(1),
    height: theme.spacing(40),
    width: '100%',
  },
  tripBoxInnerGrid: {
    height: '100%',
    padding: theme.spacing(2),
  },
  tripTitleSection: {
    display: "flex",
    position: "absolute",
    bottom: theme.spacing(2),
  },
  tripTitle: {
    color: 'white',
    fontSize: 24,
    maxWidth: '80%',
    lineHeight: '1.4',
  },
  sectionHeaderGridItem: {
    height: theme.spacing(3),
  },
  sectionHeaderItemButton: {
    marginTop: `-${theme.spacing(1)}`,
  },
  sectionContainer: {
    flex: 1,
    padding: theme.spacing(2),
  },
  pageContainer: {
    maxWidth: theme.spacing(96),
  },
  myTripsHeader: {
    fontSize: theme.spacing(3),
  },
  topContainer: {
    paddingTop: theme.spacing(4),
  },
}));

type TravelerChipProps = {
  isPlanner: boolean,
  className?: string,
 }

export const TravelerChip = ({ isPlanner, className }: TravelerChipProps) => {
  const classes = useStyles();

  return (
    <Chip
      label={isPlanner ? 'Planner' : 'Traveler' }
      className={clsx(
        classes.plannerChip,
        className,
        {
          [classes.travelerChip]: !isPlanner,
        },
      )}
    />
  )
};

type ListTripsPageProps = {
  currentUserAvatarUrl: string,
  errorNotification: string,
  isUserLoggedIn: boolean,
  successNotification: string,
  trips: GlobalTrip[],
}

const ListTripsPage = ({
  currentUserAvatarUrl,
  errorNotification,
  isUserLoggedIn,
  successNotification,
  trips,
}: ListTripsPageProps) => {
  const classes = useStyles();

  const addTripButtonRef = useCallback((element) => {
    if (element !== null) {
      trackAnalyticsEvent(
        element,
        'Trip Creation Initiated',
        {
          category: 'Trip Creation',
          buttonClicked: '"+" in My Trips',
        },
      );
    }
  }, []);

  return (
    <div className={classes.root}>
      <SiteHeader
        currentUserAvatarUrl={currentUserAvatarUrl}
        hideMyTripsButton
        isTripUpdatesHidden
        isUserLoggedIn={isUserLoggedIn}
      />
      <Container maxWidth={trips.length === 0 ? false : "sm"} className={clsx(
        classes.sectionContainer,
        {
          [classes.pageContainer]: trips.length === 0,
        },
      )}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container className={classes.topContainer} justifyContent="space-between">
              <Grid item className={classes.sectionHeaderGridItem}>
                <Typography variant='h2' className={classes.myTripsHeader}>My trips</Typography>
              </Grid>
              <Grid item className={classes.sectionHeaderGridItem}>
                <IconButton
                  className={classes.sectionHeaderItemButton}
                  data-test-id="+TripButton"
                  href={newTripPath()}
                  ref={(el) => addTripButtonRef(el)}
                >
                  <AddIcon data-test-id="addTripButton"/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {successNotification && (
            <Grid item>
              <DismissibleAlert>
                <> {successNotification} </>
              </DismissibleAlert>
            </Grid>
          )}
          {errorNotification && (
            <Grid item>
              <DismissibleAlert severity="error">
                <> {errorNotification} </>
              </DismissibleAlert>
            </Grid>
          )}
          {trips.map((trip) => (
            <Grid item key={trip.id} className={classes.trip} data-test-id="tripCard">
              <CardButton>
                <TripActions
                  travelerId={trip.travelerId}
                  deletable={trip.deletable}
                  isPlanner={trip.isPlanner}
                  tripId={trip.id}
                />
                <ButtonBase
                  id={`trip-${trip.id}`}
                  href={tripPath(trip.id)}
                  className={classes.tripBox}
                  style={{background: `linear-gradient(to bottom, rgba(14, 51, 71, 0) 1%, rgba(9, 26, 35, 0.3) 46%, rgba(4, 6, 7, 0.9)), url(${trip.coverPhotos.small}), center`}}
                >
                  <Grid container justifyContent="space-between" direction="column" className={classes.tripBoxInnerGrid}>
                    <Grid item>
                      <TravelerChip isPlanner={trip.isPlanner}/>
                      <TravelersAvatars trip={trip} />
                      <Grid item
                        container
                        direction="row"
                        className={classes.tripTitleSection}
                      >
                        <Typography variant="h3" className={classes.tripTitle}>{trip.name}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ButtonBase>
              </CardButton>
            </Grid>
          ))}
          {trips.length === 0 && (
            <Grid item>
              <EmptySectionContentWithCTA
                heading="Time to schedule some PTO"
                body="Press the “+” button to create your tripsite and start planning your next adventure with Troupe."
                message1="Americans forfeit over 212 million vacation days a year."
                message2="Don’t let that hard-earned PTO go to waste!"
                illustration="mountains_01"
                ctaText="Plan your first trip"
                ctaHREF={newTripPath()}
                ctaTrackingProps={{
                  eventName: 'Trip Creation Initiated',
                  properties: {
                    category: 'Trip Creation',
                    buttonClicked: '"Plan your first trip" in empty My Trips',
                  },
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      <SiteFooter />
    </div>
  );
};

export default hocRailsAction(ListTripsPage);
