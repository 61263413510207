import React, { FC } from "react";
import { Box, Link, Stack, Theme, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  bookedBorder: {
    border: `3px solid #3b4452`,
    borderRadius: theme.spacing(.75),
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
  },
  bookedText: {
    fontWeight: "600",
    marginLeft: theme.spacing(1),
  },
  bookedHeader: {
    color: "#ffffff",
    backgroundColor: "#3b4452",
    padding: `${theme.spacing(.8)} ${theme.spacing(1)}`,
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'space-between',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  bookingDetails: {
    fontSize: 14,
    fontWeight: 600,
  },
}));
interface BookedWrapperProps {
  children: any,
  booked: boolean,
  linkUrl?: string,
  onClick?: () => void,
}

const BookedWrapper: FC<BookedWrapperProps> = ({ children, booked, linkUrl, onClick }) => {
  const classes = useStyles();

  if (!booked) {
    return children
  }
  return (
    <Box className={classes.bookedBorder} data-test-id="booked-wrapper">
      <Link href={linkUrl} onClick={onClick} sx={{ cursor: linkUrl || onClick ? 'pointer' : 'auto'}} className={classes.bookedHeader}>
        <Stack direction="row" alignItems="center">
          <img alt="check mark icon" src={"/assets/illustrations/Yes.svg"} />
          <Typography className={classes.bookedText}>
            Booked
          </Typography>
        </Stack>
        {linkUrl && (
          <Stack direction="row" alignItems="center">
            <Typography className={classes.bookingDetails}>Booking Details</Typography>
            <ChevronRightIcon fontSize="medium" />
          </Stack>
        )}
      </Link>
      {children}
    </Box>
  )
}

export default BookedWrapper
