import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { DefaultTheme, makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { track as trackAnalyticsEvent } from '../../../services/analytics';
import Button from "../../buttons/Button";
import * as routes from "../../../routes"

const {
  tripTravelersPath,
} = routes;

export type ButtonVariant = 'primary' | 'tertiary';

type ManageTripConfirmationButtonProps = {
  collapseOnMobile?: boolean,
  disabled: boolean,
  inviteBtnVariant?: ButtonVariant,
  inviterId?: string,
  isPlanner?: boolean,
  tripId: string,
  segmentMethod: string,
  className?: string,
};

const useStyles = makeStyles<DefaultTheme, { isFullWidthBtn: boolean, variant: ButtonVariant }>((theme) => ({
  root: {
    height: '100%',
  },
  btnWidth: {
    width: ({ isFullWidthBtn }) => isFullWidthBtn ? '164px' : '52px',
    [theme.breakpoints.down(360)]: {
      width: ({ isFullWidthBtn }) => isFullWidthBtn ? '134px' : '52px',
    },
  },
  hidden: {
    display: 'none',
  },
}));

const ManageTripButton = ({
  collapseOnMobile = true,
  disabled,
  inviteBtnVariant,
  isPlanner = false,
  tripId,
  segmentMethod,
  className,
}: ManageTripConfirmationButtonProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const hideLabel = collapseOnMobile && isMobile;
  const [dialogOpened, setDialogOpened] = useState(false);

  const isFullWidthBtnForTraveler = (!isPlanner && !isMobile) || !collapseOnMobile;
  const isFullWidthBtn = isPlanner || isFullWidthBtnForTraveler;
  const classes = useStyles({variant: inviteBtnVariant, isFullWidthBtn: isFullWidthBtn});

  useEffect(() => {
    if(dialogOpened) {
      trackAnalyticsEvent('Invite Modal Opened', {
        category: 'Invites',
        method: segmentMethod,
        tripId: tripId,
      });
    }
  }, [dialogOpened])

  return (
    <>
      <Button
        onClick={() => setDialogOpened(true)}
        data-test-id="manage-trip-button"
        disabled={disabled}
        variant={inviteBtnVariant}
        href={tripTravelersPath(tripId)}
        className={clsx(
          classes.root,
          classes.btnWidth,
          className,
        )}>
        <span className={clsx(
          {[classes.hidden]: hideLabel},
        )}>
          Manage
        </span>
      </Button>
    </>
  );
};

export default ManageTripButton;
