import React, { useState } from "react";
import withLayout from "./withLayout";
import { Counter } from "./shared/ActivitiesSectionNavigation";
import SideBarPage from "../../../shared/SideBarPage";
import MainContent from "./SuggestedActivitiesPage/MainContent";
import SideBarContent from "./SuggestedActivitiesPage/SideBarContent";
import FullscreenMapView from './shared/ActivitiesMap/FullscreenMapView';
import MobileMapButton from '../../../shared/MapView/MobileMapButton';
import { TripType } from "../../../shared/TripTypes";
import TravelersVotingBox, { TravelerProps } from "../../../shared/TravelersVotingBox";
import {
  ActivityType,
} from "../../../shared/TripObjectTypes";
import {
  ActivityFilterContext,
  ActivityFilterContextType,
  ActivitySortingContext,
  ActivitySortingContextType,
} from "../../../shared/ActivityFilterContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { Box, Divider } from "@mui/material";
import { AdContent } from "../../../shared/ActivityAd";

type SuggestedActivitiesPageProps = {
  activityAd: AdContent,
  activities: ActivityType[],
  filterContextProps: ActivityFilterContextType,
  sortingContextProps: ActivitySortingContextType,
  tabsCounter: Counter,
  trip: TripType,
  travelersVoting?: Array<TravelerProps>,
};

const SuggestedActivitiesPage = ({
  activityAd,
  activities,
  filterContextProps,
  sortingContextProps,
  tabsCounter,
  trip,
  travelersVoting,
}: SuggestedActivitiesPageProps) => {
  const [fullscreenMap, setFullscreenMap] = useState(false);
  const activitiesWithCoords = activities.filter((activity) => activity.longitude && activity.latitude);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const showVotingBoxOnSidebar = !isMobile && activities.length > 0;
  const showVotingBoxOnMainContent = isMobile && activities.length > 0;
  const votingBox = (isSidebar) => {
    if (((isSidebar && showVotingBoxOnSidebar) || (!isSidebar && showVotingBoxOnMainContent))) {
      return <>
        {isSidebar && <Box mt={3}><Divider /></Box>}
        <Box mb={3} mt={isSidebar ? 3 : 0}>
          <TravelersVotingBox
            travelers={travelersVoting}
            tripId={trip.id}
            verticalType='activities'
          />
        </Box>
        <Box mb={3}><Divider /></Box>
      </>;
    }
  };

  return (
    <>
      <ActivityFilterContext.Provider value={filterContextProps}>
        <ActivitySortingContext.Provider value={sortingContextProps}>
          {fullscreenMap ? (
            <FullscreenMapView
              activities={activitiesWithCoords}
              onListButtonClick={() => setFullscreenMap(false)}
            />
          ) : (
            <>
              <SideBarPage
                mainContent={
                <>
                  {votingBox(false)}
                  <MainContent
                    activityAd={activityAd}
                    activities={activities}
                    tabsCounter={tabsCounter}
                    trip={trip}
                  />
                </>
                }
                sideBarContent={
                <>
                  <SideBarContent
                    activityAd={activityAd}
                    activities={activities}
                    onExpandMap={() => setFullscreenMap(true)}
                  />
                  {votingBox(true)}
                </>
                }
              />
              {activitiesWithCoords.length > 0 && (
                <MobileMapButton onClick={() => setFullscreenMap(true)} source='activities' />
              )}
            </>
          )}
        </ActivitySortingContext.Provider>
      </ActivityFilterContext.Provider>
    </>
  );
};

export default withLayout(SuggestedActivitiesPage, { fluid: true });
