import React, { useState } from "react";
import { ButtonBase, Grid, Stack, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Tooltip from '../Tooltip';
import EmojiPicker, { PickerPosition, PickerHorizontalPosition, PickerVerticalPosition } from "./EmojiPicker";
import type { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import { ClickAwayListener } from '@mui/base';
import clsx from "clsx";

type EmojiCounterType = {
  emoji: BaseEmoji['native'],
  count: number,
  hoverLabel: string,
  hasCurrentUserSelected?: boolean,
  name: BaseEmoji['name'],
  permapage?: boolean,
};

type EmojiCountersProps = {
  reactions: EmojiCounterType[],
  onAdd: (event, emoji: BaseEmoji) => void,
  onSelect: (event, nativeEmoji: BaseEmoji['native']) => void,
  disabled: boolean,
  isUserLoggedIn: boolean,
  permapage?: boolean,
};

const useStyles = makeStyles((theme) => ({
  emojiBorder: {
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    border: '1px solid #BEC9D4',
    borderRadius: '8px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: theme.spacing(4),
    alignItems: 'center',
    textAlign: 'center',
    minWidth: theme.spacing(4),
  },
  isSelected: {
    backgroundColor: '#DDEBFF',
    border: '1px solid #3689FF',
  },
  isDisabled: {
    '& img': {
      // use filter to take color to: '#BEC9D4' calculated with https://codepen.io/sosuke/pen/Pjoqqp
      filter: 'invert(84%) sepia(10%) saturate(272%) hue-rotate(169deg) brightness(96%) contrast(86%)',
    },
  },
}));

const EmojiCounters : React.FC<EmojiCountersProps> = ({
  reactions = [],
  onAdd,
  onSelect,
  disabled,
  isUserLoggedIn,
  permapage = false,
}) => {
  const classes = useStyles();
  const { spacing, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down(470));

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [pickerPosition, setPickerPosition] = useState<PickerPosition>({
    horizontal: PickerHorizontalPosition.LEFT,
    vertical: PickerVerticalPosition.BOTTOM,
  });

  const updatePickerPosition: (point: { x: number, y: number }) => void = ({ x, y }) => {
    if(isMobile) return;
    const { width = 0, height = 0} = window.visualViewport ?? {};
    setPickerPosition({
      horizontal: x > width / 2 ? PickerHorizontalPosition.RIGHT : PickerHorizontalPosition.LEFT,
      vertical: y < height / 2 ? PickerVerticalPosition.BOTTOM : PickerVerticalPosition.TOP,
    })
  }

  return(
    <Grid container direction='row' spacing={1} mt={1} flexWrap='wrap' width='auto' onMouseDown={(event) => event.stopPropagation()}>
      {reactions.map((reaction) => (
        <Grid item key={reaction.emoji}>
          <Tooltip title={reaction.hoverLabel} placement="top" data-test-id="emoji-tooltip" enterTouchDelay={400} enterDelay={500} >
            <Stack
              direction='row'
              justifyContent='space-between'
              minWidth='50px'
              className={clsx(classes.emojiBorder, {[classes.isSelected] : reaction.hasCurrentUserSelected})}
              onClick={(event) => !disabled && onSelect(event, reaction.emoji)}
              data-test-id='emoji-box'
            >
              <Stack>{reaction.emoji}</Stack>
              <Stack justifyContent='flex-end' minWidth={spacing(1.5)}>{reaction.count}</Stack>
            </Stack>
          </Tooltip>
        </Grid>
      ))}
      <Grid item>
        <Tooltip title={!isUserLoggedIn ? 'Join the trip to add a reaction' : ''} placement="top" data-test-id="picker-tooltip" >
          <span>
            <ButtonBase
              className={clsx(classes.emojiBorder, {[classes.isDisabled] : disabled || !isUserLoggedIn})}
              onClick={(event) => {
                event.preventDefault()
                updatePickerPosition({ x: event.clientX, y: event.clientY })
                setIsPickerOpen(prevState => !prevState)
              }}
              data-test-id='emoji-picker'
              disabled={disabled || !isUserLoggedIn}
            >
              <img src="/assets/smiley_plus.svg" alt="Add Emoji" />
            </ButtonBase>
          </span>
        </Tooltip>
        { isPickerOpen && (
          <ClickAwayListener onClickAway={() => setIsPickerOpen(false)} onClick={(event) => event.preventDefault()}>
            <EmojiPicker
              onEmojiSelect={(emoji, event) => {
                if(disabled) return;
                onAdd(event, emoji);
                setIsPickerOpen(false);
              }}
              pickerPosition={pickerPosition}
              isFullWidth={isMobile}
              permapage={permapage}
            />
          </ ClickAwayListener>
        ) }
      </Grid>
    </Grid>
  )
}

export default EmojiCounters;
