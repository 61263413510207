import React from "react";
import Button from "../../../../../buttons/Button";
import { makeStyles, DefaultTheme } from "@mui/styles";
import { useBookButtonClickedEvent } from "../../../../../../services/segmentEvents/useBookButtonEvents";
import clsx from "clsx";

type BookButtonProps = {
  fullWidth?: boolean,
  url: string,
  page: string,
  provider: string,
};

const useStyles = makeStyles<DefaultTheme>((theme) => ({
  root: {
    zIndex: 2,
    height: '40px',
    maxWidth: '75%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  fullWidth: {
    maxWidth: '100%',
  },
}));

const BookButton = ({
  fullWidth = false,
  url,
  page,
  provider,
}: BookButtonProps) => {
  const classes = useStyles();
  const providerText = {
    'EXPEDIA': 'Expedia',
    'expedia.com': 'Expedia',
    'expedia': 'Expedia',
    'VRBO': 'Vrbo',
    'vrbo.com': 'Vrbo',
    'vrbo': 'Vrbo',
  }
  const trackButtonClicked = useBookButtonClickedEvent(page, providerText[provider]);

  return (
    <Button
      className={clsx(classes.root,
        {
          [classes.fullWidth]: fullWidth,
        },
      )}
      href={url}
      target="_blank"
      ref={trackButtonClicked}
    >
      Learn more on {providerText[provider]}
    </Button>
  );
};

export default BookButton;
