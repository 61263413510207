const setUTMParams = (inviteUrl: string, medium: string) => {
  const url = new URL(inviteUrl);
  const urlParams = new URLSearchParams(url.search);

  const appendParams = () => {
    urlParams.append('utm_medium', medium);
    urlParams.append('utm_source', 'invite');
    url.search = urlParams.toString();

    return url.href
  }

  return appendParams();
}

export default setUTMParams;
