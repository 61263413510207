import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DateOptionsAvailability from "./DateOptionsAvailability";
import NoSuggestionsYet from "../../../shared/NoSuggestionsYet";
import { DateOptionWithAvailabilityType } from '../../../shared/TripObjectTypes';

type AvailabilityAndPricingSectionProps = {
  stay: {
    dateOptions: DateOptionWithAvailabilityType[],
    hasAutomaticAvailability: boolean,
  },
};

const useStyles = makeStyles((theme) => ({
  noSuggestedDateOptions: {
    padding: 0,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

const AvailabilityAndPricingSection = ({
  stay,
}: AvailabilityAndPricingSectionProps) => {
  const classes = useStyles();
  const dateOptions = stay.dateOptions;
  const chosenDateOption = dateOptions.find(date => date.isChosenDate);
  const dateOptionsList = chosenDateOption ? [chosenDateOption] : dateOptions;
  const mustDisplayAvailabilityTitle = !stay.hasAutomaticAvailability;

  return (
    <Grid item>
      <Grid item>
        <Typography className={classes.subtitle}>
          Availability & pricing
        </Typography>
        {mustDisplayAvailabilityTitle && !chosenDateOption && (<Typography>
          Which of the suggested date options is the property available for?
        </Typography>)}
        {mustDisplayAvailabilityTitle && chosenDateOption && (<Typography>
          Is this listing available for your group’s finalized dates?
        </Typography>)}
      </Grid>
      <Grid item>
        <DateOptionsAvailability
          hasChosenDate={chosenDateOption !== undefined}
          dateOptions={dateOptionsList}
          manual={!stay.hasAutomaticAvailability}
        />
      </Grid>
      {dateOptions.length === 0 && (
        <Grid item container justifyContent="center">
          <Grid item xs={12} sm={8}>
            <NoSuggestionsYet
              className={classes.noSuggestedDateOptions}
              illustration="calendar_question"
              subheading="Hmm…no one’s suggested any dates yet for your trip."
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AvailabilityAndPricingSection;
