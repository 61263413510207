import React from "react";
import clsx from "clsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ContainerLink from "./ContainerLink";
import DateOptionActions from "./DateOption/DateOptionActions";
import MissingVoteAlert from "./MissingVoteAlert";
import VotesBreakdown from "./DateOption/VotesBreakdown";
import ExcludesPlusOnesMessage from "./ExcludesPlusOnesMessage";
import CardButton from "../buttons/CardButton";
import GroupFavoriteChip from "./DateOption/GroupFavoriteChip";
import pluralize from 'pluralize';
import { usePhasingContext } from "../../services/phasing";
import { DateOptionType } from './DateOption/DateOptionTypes';
import CommentIcon from '@mui/icons-material/Comment';
import Button from "../buttons/Chip";
import titleize from "titleize";
import CommentsLink from "../pages/trips/microsite/shared/CommentsLink";

const useStyles: () => any = makeStyles((theme): {} => ({
  cardButton: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  cardLink: {
    display: 'flex',
    flexDirection: 'column',
  },
  currentUserVoteIcon: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    verticalAlign: 'middle',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      height: 32,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  dateOption: {
    position: 'relative',
    boxSizing: 'border-box',
    fontSize: 16,
    padding: theme.spacing(2),
    marginBottom: '0 !important',
  },
  headerGrid: {
    position: 'relative',
  },
  chosenDateOption: {
    border: `2px solid ${theme.palette.general.primary}!important`,
  },
  dateOptionGrid: {
    maxWidth: theme.spacing(31),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    marginTop: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  dateOptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dateOptionSegment: {
    padding: theme.spacing(1),
    textAlign: 'center',
    width: `calc(50% - ${theme.spacing(2)})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      width: theme.spacing(14),
    },
  },
  divider: {
    borderColor: '#e6e7e8',
    width:'100%',
  },
  votes: {
    marginLeft: theme.spacing(2)
  },
  optionTitle: {
    marginBottom: theme.spacing(1),
  },
  numberOfNights: {
    fontWeight: 500,
  },
  date: {
    fontSize: 24,
    fontWeight: 700,
  },
  weekday: {
    fontSize: 16,
    lineHeight: 1,
  },
  year: {
    fontSize: 16,
  },
  addPadding: {
    padding: theme.spacing(2),
  },
  disabled: {
    color: ["#C0C0C0", '!important'],
    filter: 'grayscale(100%)',
  },
  sentimentEmoji: {
    textAlign: 'center',
  },
  centerDates: {
    flexDirection: "column",
  },
  verticalActions: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  commentButton: {
    height: '32px !important',
  },
  commentButtonContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: 'right',
    maxWidth: '100%',
  },
}));

const DateOption = ({
  className,
  commentsPath,
  commentsCount,
  currentUserVote = 'missing',
  dateOptionPath,
  displayCommentButton = false,
  endDate,
  endDateWeekday,
  endDateYear,
  highlightAsChosen,
  isClickable,
  isChosen,
  isMostPopular,
  isPast,
  linkPath,
  noVotesBreakdown,
  numNights,
  onDateOptionClick,
  setDateOptionComment,
  startDate,
  startDateWeekday,
  startDateYear,
  showActions,
  showLink,
  showMissingVoteAlert = false,
  travelersVotes,
  tripObjectId,
  tripId,
}: DateOptionType) => {
  const classes = useStyles();
  const { areDatesInVotingPhase, areDatesFinalized } = usePhasingContext();
  const numOfNights = `${numNights} ${pluralize('Night', numNights)}`;
  const currentUserVoted = currentUserVote !== 'missing';
  const shouldDisplayVotes = !noVotesBreakdown && ((currentUserVoted && areDatesInVotingPhase()) || areDatesFinalized());
  const shouldDisplayGroupFavorite = shouldDisplayVotes && isMostPopular;
  const showCommentsNumber = commentsPath && !isNaN(commentsCount);

  const onContainerLinkClicked = (event) => {
    if (event.target.dataset['element'] === 'link' || event.target instanceof SVGPathElement) {
      event.preventDefault();
    };
  };

  const showPlusOnesMessage = shouldDisplayVotes && Object.values(travelersVotes).flat().filter(({ plusOneCount }) => plusOneCount > 0).length > 0;

  return (
    <>
      <CardButton
        className={clsx(
          classes.cardButton,
          {[classes.chosenDateOption]: highlightAsChosen},
        )}
        disabled={isPast || (!showLink && !isClickable)}
      >
        <Box
          data-test-id="date-option"
          className={clsx(
            classes.dateOption,
            className,
            {[classes.addPadding]: !showLink && !isClickable},
            {[classes.disabled]: isPast},
          )}
          onClick={() => onDateOptionClick && onDateOptionClick()}
        >
          <ContainerLink
            showLink={!isPast && showLink}
            className={classes.cardLink}
            href={linkPath || commentsPath}
            onClick={onContainerLinkClicked}
            disableTouchRipple
          >
            <Grid container direction="row" alignItems="center" className={classes.headerGrid}>
              <Grid item className={classes.optionTitle}>
                <Typography className={classes.numberOfNights}>{numOfNights}</Typography>
              </Grid>
              {shouldDisplayGroupFavorite && (
                <GroupFavoriteChip />
              )}
            </Grid>
            <Divider className={classes.divider}/>
            <Grid container alignItems="center" justifyContent={shouldDisplayVotes ? 'flex-start' : 'center'}>
              <Grid item className={classes.dateOptionWrapper}>
                {currentUserVoted && shouldDisplayVotes && (
                  <img src={`/assets/illustrations/${titleize(currentUserVote)}.svg`} className={classes.currentUserVoteIcon} />
                )}
                <Grid item container justifyContent="space-between" alignItems="center" className={classes.dateOptionGrid}>
                  <Grid item className={classes.dateOptionSegment}>
                    <Typography className={classes.weekday}>{startDateWeekday}</Typography>
                    <Typography className={classes.date}>{startDate}</Typography>
                    <Typography className={classes.year}>{startDateYear}</Typography>
                  </Grid>
                  <Grid item>
                    <ArrowForwardIcon color="primary" />
                  </Grid>
                  <Grid item className={classes.dateOptionSegment}>
                    <Typography className={classes.weekday}>{endDateWeekday}</Typography>
                    <Typography className={classes.date}>{endDate}</Typography>
                    <Typography className={classes.year}>{endDateYear}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {shouldDisplayVotes && (
                <Grid item>
                  <Grid className={classes.votes}>
                    <Grid container item>
                      <Grid item>
                        <VotesBreakdown maxAvatarsLength={3} votes={travelersVotes} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {showCommentsNumber && (
              <Grid container direction="row">
                <Grid item xs={12} container justifyContent="flex-end">
                  <CommentsLink
                    commentsLink={commentsPath}
                    commentsCount={commentsCount}
                    segment={{
                      tripId: tripId,
                      tripObjectId: tripObjectId,
                      tripObjectType: 'dates',
                    }}
                  />
                </Grid>
            </Grid>)}
            {(showPlusOnesMessage || displayCommentButton) && (
              <Grid container justifyContent="space-between" alignItems="center" my={1}>
                <Grid item>
                  {showPlusOnesMessage && <ExcludesPlusOnesMessage />}
                </Grid>
                <Grid item>
                  {displayCommentButton && (
                    <Button
                      onClick={() => setDateOptionComment()}
                      className={classes.commentButton}
                      data-test-id='commentButton'
                      data-element='link'
                      segmentProps={{title: 'Comment Field Initiated', tripObjectId: tripObjectId}}
                    >
                      <CommentIcon
                        data-element='link'
                      />
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </ContainerLink>
          {showActions && dateOptionPath && (
            <DateOptionActions
              dateOptionPath={dateOptionPath}
              disableDeleteButton={isChosen}
            />
          )}
        </Box>
        {areDatesInVotingPhase() && showMissingVoteAlert && (
          <MissingVoteAlert />
        )}
      </CardButton>
    </>
  );
};

export default DateOption;
