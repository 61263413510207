import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { hocRailsAction } from "../../Document";
import Errors, { ErrorsProps } from "../../shared/Errors";
import ModalForm from "../../shared/ModalForm";
import * as routes from '../../../routes';

const {
  tripPath,
  tripTravelersPath,
} = routes;

type NewTripPageProps = {
  errors: ErrorsProps['errors'],
  tripId: string,
}

const NewTripPage = ({ errors, tripId }: NewTripPageProps) => {
  return (
    <ModalForm
      closePath={tripPath(tripId)}
      formActionPath={tripTravelersPath(tripId)}
      title="Grow your Troupe"
      saveButtonLabel="Invite traveler"
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography>Invite additional travelers by email</Typography>
        </Grid>
        <Grid item>
          <Errors errors={errors} />
        </Grid>
        <Grid item>
          <TextField
            id="traveler_email_address"
            name="traveler[email_address]"
            label="Email"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(NewTripPage);
