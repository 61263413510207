import React, { useCallback } from "react";
import {
  Box,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SectionHeader from "./shared/SectionHeader";
import CurrentSortingAndCount from "./shared/CurrentSortingAndCount";
import withMicrositeLayout from "./withLayout";
import ActionCallout from "./SuggestedDatesPage/ActionCallout";
import DatesList from "./SuggestedDatesPage/DatesList";
import SuggestingPhaseButtons from './shared/SuggestingPhaseButtons';
import PhasingStepper from "./shared/PhasingStepper";
import Button from "../../../buttons/Button";
import Tooltip from "../../../shared/Tooltip";
import Divider from "../../../shared/Divider";
import NoSuggestionsYet from "../../../shared/NoSuggestionsYet";
import FilteringAndSortingBar from "./shared/FilteringAndSortingBar";
import SideBarPage from "../../../shared/SideBarPage";
import * as routes from '../../../../routes';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import {
  trackLink as trackLinkAnalyticsEvent,
  track as trackAnalyticsEvent,
} from '../../../../services/analytics';
import VotingTopSideBarContent from "./shared/VotingTopSideBarContent";
import TravelersVotingBox from "../../../shared/TravelersVotingBox";
import LastUpdateFilterLink from "../../../shared/LastUpdateFilterLink";
import { usePhasingContext } from '../../../../services/phasing';
import FixedSaveBar from "../../../shared/FixedSaveBar";
import titleize from "titleize";

const {
  newTripDateOptionPath,
  newTripChosenDateOptionPath,
  newTripVotingPath,
  setSuggestingPhaseTripDatesPath,
  setVotingPhaseTripDatesPath,
  tripDatesPath,
} = routes;

const useStyles = makeStyles((theme) => ({
  datesCtaButton: {
    marginBottom: theme.spacing(3),
  },
  dateOptionsTitle: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  filters: {
    marginBottom: theme.spacing(2),
  },
  header: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    lineHeight: '32px',
    marginBottom: theme.spacing(2),
  },
  subheader: {
    marginBottom: theme.spacing(3),
  },
  saveBar: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
}));

const SuggestedDatesPage = ({
  trip: { dateOptions, ...trip },
  currentDateOptionSorting,
  currentUserAvatarUrl,
  currentUserFirstName,
  dateOptionsSortings,
  isUserLoggedIn,
  plannerCanSkipVotingEnabled,
  lastEventUpdatedAt,
  shouldDisplayTravelersList,
  travelersVoting,
}) => {
  const classes = useStyles();
  const { areDatesInSuggestingPhase, areDatesInVotingPhase, datesState } = usePhasingContext();
  const sortingName = dateOptionsSortings.find((sorting) => sorting.sortBy === currentDateOptionSorting).label;
  const addDatesText = useMediaQuery(useTheme().breakpoints.down(470)) ? '+ Add' : '+ Add dates'
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const validDateOptions = dateOptions.filter((dateOption) => !dateOption.isPast);
  const isCurrentUserVoteRequired = dateOptions.some((dateOption) => dateOption.isCurrentUserVoteRequired)
  const canUserStillVoteOnDates = validDateOptions.length > 0

  const currentDateOptions = dateOptions.filter(date => (!date.isPast))
  const currentUserMissingVotesCount = currentDateOptions.filter((dateOption) => {
    return dateOption.currentUserVote === 'missing';
  }).length;
  const hasUserStartedVoting = currentUserMissingVotesCount < validDateOptions.length;
  const focusedVotingText =
    isCurrentUserVoteRequired ?
      (hasUserStartedVoting ? 'Finish voting' : 'Start voting') :
      (currentUserFirstName ? 'Change votes' : 'Start voting')

  const buttonVariant = focusedVotingText === 'Change votes' ? 'secondary' : 'primary';
  const suggestDateRef = useCallback((element) => {
    if (element !== null) {
      trackLinkAnalyticsEvent(
        element,
        'Add Dates Form Opened',
        {
          category: 'Dates',
          buttonClicked: '"Add Dates" in Dates',
        },
      );
    }
  }, []);

  const note = areDatesInVotingPhase() ? 'Vote for your favorite options from the list below.' : `Suggest new dates using the “${addDatesText}” button below.`;

  const showVotingBoxOnSidebar = shouldDisplayTravelersList && !isMobile && canUserStillVoteOnDates;
  const showVotingBoxOnMainContent = shouldDisplayTravelersList && isMobile && canUserStillVoteOnDates;
  const displayVotingBtn = areDatesInVotingPhase() && !isMobile;

  const votingBox = (isSidebar) => {
    if (areDatesInVotingPhase() && ((isSidebar && showVotingBoxOnSidebar) || (!isSidebar && showVotingBoxOnMainContent))) {
      return <>
        {isSidebar && <Box mt={3}><Divider /></Box>}
        <Box mb={3} mt={isSidebar ? 3 : 0}>
          <TravelersVotingBox
            travelers={travelersVoting}
            tripId={trip.id}
            verticalType='dates'
          />
        </Box>
        <Box mb={3}><Divider /></Box>
      </>;
    }
  };

  const toolTipText = (displayVotingBtn && !canUserStillVoteOnDates) ? 'Votes cannot be changed for past dates.' : '';
  const showActionCallout = isUserLoggedIn && areDatesInVotingPhase() && canUserStillVoteOnDates && currentUserMissingVotesCount > 0;

  const focusedVotingButtonClicked = () => {
    trackAnalyticsEvent(`Dates ${titleize(focusedVotingText)} Selected`, {
      tripId: trip.id,
      source: 'primary button',
    });
  };

  return (
    <>
      <SideBarPage
        mainContent={
          <>
            {votingBox(false)}
            <SectionHeader
              title="Dates"
              note={note}
              rightElement={ lastEventUpdatedAt && (
                <LastUpdateFilterLink
                  date={lastEventUpdatedAt}
                  tripId={trip.id}
                  tripObjectType="TripDateOption"
                />
              )}
            />
            <PhasingStepper currentPhase={datesState} tripObjectType='dates' />
            {showActionCallout && (
              <>
                <Box my={2}><Divider /></Box>
                <ActionCallout
                  currentUserAvatarUrl={currentUserAvatarUrl}
                  missingVotesCount={currentUserMissingVotesCount}
                />
              </>
            )}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.filters}
            >
              <Grid item>
                <FilteringAndSortingBar
                  vertical='Dates'
                  currentSorting={currentDateOptionSorting}
                  disabled={dateOptions.length === 0}
                  formPath={tripDatesPath(trip.id)}
                  sortByRadioGroupName="date_option_filter[sort_by]"
                  sortings={dateOptionsSortings}
                />
              </Grid>
              <Tooltip data-test-id='tool-tip' title={toolTipText}>
                <Grid item>
                  {areDatesInSuggestingPhase() && (
                    <Button
                      data-test-id="add-button"
                      disabled={!areDatesInSuggestingPhase()}
                      href={newTripDateOptionPath(trip.id)}
                      ref={(el) => suggestDateRef(el)}
                      variant="primary"
                    >
                      {addDatesText}
                    </Button>
                  )}
                  {displayVotingBtn && (
                    <Button
                      data-test-id="focused-voting-button"
                      href={newTripVotingPath(trip.id)}
                      onClick={focusedVotingButtonClicked}
                      ref={(el) => suggestDateRef(el)}
                      variant={buttonVariant}
                      disabled={!canUserStillVoteOnDates}
                    >
                      {focusedVotingText}
                    </Button>
                  )}
                </Grid>
              </Tooltip>
            </Grid>
            {dateOptions.length === 0 && (
              <NoSuggestionsYet
                heading="Next steps"
                listItems={['Add dates', 'Get your group to vote', 'Finalize and move to destinations']}
                illustration='calendar'
              />
            )}
            {dateOptions.length > 0 && (
              <Box pt={1}>
                <CurrentSortingAndCount
                  count={dateOptions.length}
                  sorting={sortingName}
                  type="date"
                />
              </Box>
            )}
            <DatesList dateOptions={dateOptions} />
          </>
        }
        sideBarContent={
          <>
            {areDatesInVotingPhase() && (
              <VotingTopSideBarContent
                disabledActions={!canUserStillVoteOnDates}
                finalizePath={newTripChosenDateOptionPath(trip.id)}
                isCurrentUserPlanner={trip.isPlanner}
                transitionToSuggestingPath={setSuggestingPhaseTripDatesPath(trip.id)}
                type='dates'
              />
            )}
            {areDatesInSuggestingPhase() && (
              <SuggestingPhaseButtons
                hasSuggestions={canUserStillVoteOnDates}
                isPlanner={trip.isPlanner}
                transitionToVotingPath={setVotingPhaseTripDatesPath(trip.id)}
                tripId={trip.id}
                tripObjectType='dates'
              />
            )}
            {votingBox(true)}
          </>
        }
      />
      { isMobile && areDatesInVotingPhase() &&
        <FixedSaveBar
          buttonContent={focusedVotingText}
          className={classes.saveBar}
          data-test-id='fixed-save-bar-button'
          href={newTripVotingPath(trip.id)}
          onClick={focusedVotingButtonClicked}
          saveButtonVariant={buttonVariant}
          saveButtonType="button"
        />
      }
  </>
);
};

// noinspection JSUnusedGlobalSymbols
export default withMicrositeLayout(SuggestedDatesPage, { fluid: true });
