import React, { useContext, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import {hocRailsAction, TripSiteContext} from "../../Document";
import Modal from "../../shared/Modal";
import TabNavigation, { TabPanel } from "../../shared/TabNavigation";
import PendingInvite, {
  PendingInviteData,
} from "../trip_invites/IndexPage/PendingInvite";
import AcceptedInvite, {
  AcceptedInviteData,
} from "../trip_invites/IndexPage/AcceptedInvite";
import DeclinedInvite, {
  DeclinedInviteData,
} from "../trip_invites/IndexPage/DeclinedInvite";
import IndexPage from "../trip_invites/IndexPage";
import ManageTravelers from "./ManageTravelers";
import {
  TravelersType,
} from "./TravelersByStatusType";
import * as routes from '../../../routes';
import ShareLinkSection from "../../shared/InviteModal/ShareLinkSection";
import { useQuery } from "@apollo/client";
import { TRAVELERS_QUERY } from "../../../graphql/queries/travelers";
import { GUESTS_QUERY } from "../../../graphql/queries/guests";

const { tripPath } = routes;

type ManageInvitesAndTravelersModalPageProps = {
  activeTab: number,
  currentUserId: string,
  isCurrentUserPlanner: boolean,
  travelers: TravelersType[],
  acceptedInvites: AcceptedInviteData[],
  declinedInvites: DeclinedInviteData[],
  pendingInvites: PendingInviteData[],
  tripId: string,
  tripGid: string,
  tripName: string,
};

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '720px',
    flex: 1,
    width: '100%',
  },
  root: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    minHeight: '100%',
  },
  inviteSubText: {
    paddingBottom: theme.spacing(1),
  },
  inviteStatusSection: {
    paddingTop: theme.spacing(3),
  },
  invitesWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  outerContainer: {
    flexWrap: 'nowrap',
  },
}));

const ManageInvitesAndTravelersModalPage = ({
  acceptedInvites,
  activeTab,
  currentUserId,
  declinedInvites,
  isCurrentUserPlanner = false,
  pendingInvites,
  travelers,
  tripId,
  tripName,
}: ManageInvitesAndTravelersModalPageProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(activeTab);
  const [travelersList, setTravelersList] = useState(travelers);
  const { tripGid } = useContext(TripSiteContext);
  const { data: travelers_data } = useQuery(
    TRAVELERS_QUERY, {
      variables: {
        tripId: tripGid,
      },
    },
  )

  const { data: guests_data } = useQuery(
    GUESTS_QUERY, {
      variables: {
        tripId: tripGid,
      },
    },
  )

  const totalPlusOnesCount = travelers_data?.travelers?.reduce((total: number, traveler: TravelersType) => total + (traveler.plusOneCount || 0), 0);
  const totalGuestsCount = guests_data?.guests.length || 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabLabels =[
    `Invites (${pendingInvites.length})`,
    `Travelers (${travelers_data?.travelers?.length ? travelers_data.travelers.length + totalPlusOnesCount + totalGuestsCount : 0})`,
  ];

  return(
    <Grid container className={classes.root}>
      <Grid item className={classes.container}>
        <Modal
          closePath={tripPath(tripId)}
          subtitle={
            <Typography>
              Track trip invites and manage who is coming on your trip.
            </Typography>
          }
          title={
            <Typography variant="h2">
              Manage travelers
            </Typography>
          }
          withDivider={false}
        >
          <Box mb={2} mt={-3}>
            <ShareLinkSection
              source="manageTravelersPage"
              inviterId={currentUserId}
              tripId={tripId}
              tripName={tripName}
            />
          </Box>
          <TabNavigation
            activeTab={value}
            tabLabels={tabLabels}
            onTabChange={handleChange}
          >
            <TabPanel value={value} index={0}>
              <IndexPage
                tripId={tripId}
                acceptedInvites={acceptedInvites}
                declinedInvites={declinedInvites}
                pendingInvites={pendingInvites}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ManageTravelers
                isCurrentUserPlanner={isCurrentUserPlanner}
                tripId={tripId}
                onRemoveTraveler={setTravelersList}
              />
            </TabPanel>
          </TabNavigation>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default hocRailsAction(ManageInvitesAndTravelersModalPage);
