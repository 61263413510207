import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import { DateOptionWithAvailabilityType } from "../../../../../../../shared/TripObjectTypes";

type AvailabilityIconProps = {
  dateOption: DateOptionWithAvailabilityType,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  dateOptionIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  availableDateOption: {
    color: '#1EBE95',
  },
  unavailableDateOption: {
    color: '#FB5D5D',
  },
  unknownDateOption: {
    color: '#7D94A9',
  },
}));

const AvailabilityIcon = ({ dateOption }: AvailabilityIconProps) => {
  const classes = useStyles();

  return <>
    {dateOption.available === true && (
      <CheckCircleIcon
        className={clsx(classes.dateOptionIcon, classes.availableDateOption)}
        data-test-id="availableDateOption"
      />
    )}
    {dateOption.available === false && (
      <CancelIcon
        className={clsx(classes.dateOptionIcon, classes.unavailableDateOption)}
        data-test-id="unavailableDateOption"
      />
    )}
    {dateOption.available === null && (
      <ErrorIcon
        className={clsx(classes.dateOptionIcon, classes.unknownDateOption)}
        data-test-id="unknownDateOption"
      />
    )}
  </>
};

export default AvailabilityIcon;
