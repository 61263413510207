import React, { useState } from "react";
import {
  Box,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FloatingActionButton from "../shared/FloatingActionButton";
import { DialogBottomOnMobile } from "../../../../shared/Dialog";
import * as routes from "../../../../../routes";
import useSegment from "../../../../../services/useSegment";

type AddEventFloatingButtonProps = {
  itineraryDateId: string,
  tripId: string,
};

type AddLinkProps = {
  imageSrc: string,
  path: string,
  resourceName: string,
};

const {
  newTripActivityEventPath,
  newTripStayEventPath,
} = routes;

const useStyles = makeStyles((theme) => ({
  cardButton: {
    display: "flex",
    border: "1px dashed #545DFC",
    borderRadius: '15px',
    overflow: "hidden",
    "&:hover": {
      backgroundColor: '#F8F7FE',
    },
  },
  cardLabel: {
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "middle",
  },
  imageContainer: {
    backgroundColor: '#E5E7FF',
    minWidth: '80px',
    minHeight: '80px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AddEventFloatingButton: React.FC<AddEventFloatingButtonProps> = ({
  itineraryDateId,
  tripId,
}) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { trackSegment } = useSegment();

  const handleFloatingBtnOnClick = () => {
    setIsDialogOpen(true)
    trackSegment('Add Itinerary Event Initiated', {})
  };

  const AddLink: React.FC<AddLinkProps> = ({ imageSrc, path, resourceName }) => (
    <Link
      className={classes.cardButton}
      color="inherit"
      href={path}
      underline="none"
      data-test-id={`add-${resourceName}`}
    >
      <Box className={classes.imageContainer}>
        <img height="60px" width="60px" alt={`add ${resourceName}`} src={imageSrc} />
      </Box>
      <Box className={classes.cardLabel}>
        <Typography variant="h3" textAlign='center'>
          {`Add ${resourceName}`}
        </Typography>
      </Box>
    </Link>
  );

  return (
    <>
      <FloatingActionButton
        onClick={() => handleFloatingBtnOnClick()}
        testId="floating-action-button"
      />
      <DialogBottomOnMobile
        fullWidth
        isOpen={isDialogOpen}
        keepMounted
        maxWidth="xs"
        message={
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <AddLink
                path={`${newTripStayEventPath(tripId)}?itinerary_date_id=${itineraryDateId}`}
                imageSrc="/assets/illustrations/camper_icon.svg"
                resourceName="stay"
              />
            </Grid>
            <Grid item>
              <AddLink
                path={`${newTripActivityEventPath(tripId)}?itinerary_date_id=${itineraryDateId}`}
                imageSrc="/assets/illustrations/sailboat_icon.svg"
                resourceName="activity"
              />
            </Grid>
          </Grid>
        }
        onClose={() => setIsDialogOpen(false)}
        title='Add to itinerary'
        subtitle='Select a category to add to your itinerary'
      >
      </DialogBottomOnMobile>
    </>
  );
};

export default AddEventFloatingButton;
