import useSegment from '../useSegment';

export const useCalloutDismissed = () => {
  const { trackSegment } = useSegment();
  return () => {
    trackSegment('Trip Updates Notification Callout Dismissed',
      {},
    )
  }
};

export const useNotificationCalloutClicked = () => {
  const { trackSegment } = useSegment();
  return () => {
    trackSegment('Trip Updates Notification Callout Clicked',
      {},
    )
  }
};
