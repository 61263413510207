import React from "react";
import { hocRailsAction } from "../../../Document";
import { ActivityGroupType } from "../../../shared/TripObjectTypes";
import ActivityChoosingModalForm from "./AddActivityToActivityGroupsPage/ActivityChoosingModalForm";

type AddActivityToActivityGroupsPageProps = {
  activityGroup: ActivityGroupType,
  refererPage: 'permapage' | 'groups_tab',
};

const AddActivityToActivityGroupsPage = ({
  activityGroup,
  refererPage,
}: AddActivityToActivityGroupsPageProps) => {

  return (
    <ActivityChoosingModalForm
      activityGroup={activityGroup}
      title="Add activities to"
      refererPage={refererPage}
      subtitle={`"${activityGroup.title}"`}
    />
  );
};

export default hocRailsAction(AddActivityToActivityGroupsPage);
