
export const convertSnakeCaseToNormalEnglish = objEntries => objEntries.map(([key, value]) => {
  key = key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

  if (key === 'Amenities') {
    value = value.split(',').map(item => item.toLowerCase().split('_').join(' ')).join(', ');
  }
  return [key, value];
});

export const camelToSnake = str => {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const omitFalsyAndUnchecked = obj => {
  return Object.entries(obj)
      .filter(([key, value]) => {
        if (key === 'amenities' && Array.isArray(value) && value.length === 0) {
          return false;
        }
        return value !== null && value !== false;
      })
      .reduce((acc, [key, value]) => {
        // Convert the key to snake_case
        const snakeKey = camelToSnake(key);

        // Convert the 'amenities' array to a string joined on ', '
        if (key === 'amenities' && Array.isArray(value)) {
          value = value.join(', ');
        }
        // For the 'propertyTypes' key, transform it to a string of labels where 'checked' is true
        else if (key === 'propertyTypes' && Array.isArray(value)) {
          value = value.filter(item => item.checked).map(item => item.label).join(', ');
        }
        // Omit the keys 'searchMethod', 'prompt', and 'inventory'
        if (!['searchMethod', 'prompt', 'inventory'].includes(key)) {
          acc[snakeKey] = value;
        }
        return acc;
      }, {});
};
