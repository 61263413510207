import React, { useCallback, useEffect, useMemo, useState } from "react";
import EmojiCounter from "./EmojiCounters";
import filterObject from "../../../services/filterObject";
import {
  useAddReactionEvent,
  useRemoveReactionEvent,
} from "../../../services/segmentEvents/useReactionsEvents";

export type ReactionType = {
  emoji: string,
  count: number,
  users: string[],
  hasCurrentUserSelected?: boolean,
  name: string,
};

export type ReactionCounts = {
  [emojiKey: ReactionType['emoji']]: ReactionType,
}

export type ReactionsProps = {
  initialReactions?: ReactionCounts,
  currentUserDisplayName: string,
  onAddReaction?: (reaction: ReactionType) => void,
  onRemoveReaction?: (reaction: ReactionType) => void,
  disabled: boolean,
  reactableId: string,
  reactableType: string,
  isSelfReaction: boolean,
  permapage?: boolean,
};

const hoverLabel = (reaction: ReactionType, currentUserDisplayName: ReactionsProps['currentUserDisplayName']) => {
  const { hasCurrentUserSelected, name, users } = reaction
  const userNames = hasCurrentUserSelected ? ['You', ...users.filter(user => user !== currentUserDisplayName)] : users;
  const usersString = userNames.length > 1
    ? `${userNames.slice(0, -1).join(', ')} and ${userNames.slice(-1)}`
    : userNames;
  return `${usersString} reacted with ‘${name}’`
};

const Reactions: React.FC<ReactionsProps> = ({
  initialReactions = {},
  currentUserDisplayName,
  onAddReaction = () => {},
  onRemoveReaction = () => {},
  disabled,
  reactableId,
  reactableType,
  isSelfReaction,
  permapage = false,
}) => {
  const [reactions, setReactions] = useState<ReactionCounts>(initialReactions);
  const trackAddReaction = useAddReactionEvent();
  const trackRemoveReaction = useRemoveReactionEvent();

  useEffect(() => {
    setReactions(initialReactions)
  }, [initialReactions]);

  const sortedReactions = useMemo(() => (
    Object.values(reactions).map(reaction => ({
      ...reaction,
      hoverLabel: hoverLabel(reaction, currentUserDisplayName),
    }))
  ), [reactions, currentUserDisplayName]);

  const handleReaction = useCallback((event: React.MouseEvent<HTMLButtonElement>, emoji: string, reactionName?: string) => {
    event?.preventDefault?.();
    const previousReaction = reactions[emoji];
    const isNewReaction = !previousReaction;
    const isAdd = isNewReaction || !previousReaction.hasCurrentUserSelected;

    const count = isAdd
      ? isNewReaction ? 1 : previousReaction.count + 1
      : previousReaction.count - 1;
    const users = isAdd
      ? [...(previousReaction?.users ?? []), currentUserDisplayName]
      : previousReaction.users.filter(user => user !== currentUserDisplayName);

    const updatedReaction = {
      emoji,
      count,
      users,
      hasCurrentUserSelected: isAdd,
      name: isNewReaction ? reactionName : previousReaction.name,
    };

    const newReactions = count > 0
      ? { ...reactions, [emoji]: updatedReaction }
      : filterObject(reactions, ([key]) => key !== emoji);

    setReactions(newReactions);

    if (isAdd) {
      trackAddReaction({
        isUnique: isNewReaction,
        source: reactionName ? 'Add Button' : 'Existing Reaction',
        objectType: reactableType,
        objectId: reactableId,
        reaction: emoji,
        selfReaction: isSelfReaction,
      });
      onAddReaction(updatedReaction);
    } else {
      trackRemoveReaction({
        objectType: reactableType,
        objectId: reactableId,
        reaction: emoji,
        selfReaction: isSelfReaction,
      });
      onRemoveReaction(updatedReaction);
    }
  }, [reactions, currentUserDisplayName, onAddReaction, onRemoveReaction]);

  return (
    <EmojiCounter
      reactions={sortedReactions}
      onSelect={(event, emoji) => handleReaction(event, emoji)}
      onAdd={(event,{ native: emoji, name }) => handleReaction(event, emoji, name)}
      disabled={disabled}
      isUserLoggedIn={!!currentUserDisplayName}
      permapage={permapage}
    />
  );
};

export default Reactions;
