import React, { useContext, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { PickerOverlay } from "filestack-react";
import { GlobalContext, ImageUploaderContext } from "../../Document";

type ImagePickerProps = {
  forcedAspectRatio?: number,
  onClose: () => void,
  onSuccess: (url: string) => void,
  opened: boolean,
};

type PickerOptions = {
  accept: string,
  fromSources: string[],
  maxFiles: number,
  maxSize: number,
  onClose: () => void,
  transformations?: {},
};

const useStyles = makeStyles((theme) => ({
  pickerWrapper: {
    '& .fsp-picker': {
      '@supports (-webkit-overflow-scrolling: touch)': {
        '& input': {
          fontSize: 16,
        },
      },
      '@supports (top: env(safe-area-inset-top))': {
        top: 'env(safe-area-inset-top)',
      },
    },
    '& .fsp-footer--appeared': {
      '@supports (bottom: env(safe-area-inset-bottom))': {
        bottom: 'env(safe-area-inset-bottom)',
      },
    },
    '& .fst-sidebar': {
      '@media screen and (max-width: 768px)': {
        '@supports (bottom: env(safe-area-inset-bottom))': {
          bottom: 'calc(54px + env(safe-area-inset-bottom))',
        },
      },
    },
  },
}));

const ImagePicker = ({
  forcedAspectRatio,
  onClose,
  onSuccess,
  opened,
}: ImagePickerProps) => {
  const basicOptions: PickerOptions = {
    accept: "image/*",
    fromSources: ["local_file_system", "imagesearch", "unsplash", "url"],
    maxFiles: 1,
    maxSize: 25 * 1024 * 1024,
    onClose: () => onClose(),
  };

  if (forcedAspectRatio) {
    basicOptions.transformations = {
      crop: {
        aspectRatio: forcedAspectRatio,
        force: true,
      },
    };
  };

  const classes = useStyles();
  const { fileStackAPIKey } = useContext(ImageUploaderContext);
  const { isTestEnv } = useContext(GlobalContext);
  const pickerWrapper = useRef(null);

  const handlePickerChanges = (mutations, _observer) => {
    for (const { target } of mutations) {
      if (!target['tagName']) continue; // ensure target is an Element

      const inputs = target.querySelectorAll('.fsp-picker form input');
      if (inputs.length === 0) continue;

      for (const el of inputs) {
        if (el.getAttribute('inputmode')) continue;

        el.setAttribute('inputmode', 'search');
      }
    }
  };

  useEffect(() => {
    if (!pickerWrapper.current || !opened) return;
    if (typeof MutationObserver === 'undefined') return;

    const observer = new MutationObserver(handlePickerChanges);
    observer.observe(
      pickerWrapper.current, {
        childList: true,
        subtree: true,
      },
    );
    return () => {
      observer?.disconnect()
    };
  }, [pickerWrapper.current, opened]);

  return <>
    {opened && (
      <>
        {isTestEnv ? (
          <input
            onClick={() => onSuccess("https://cdn.filestackcontent.com/lKRl0DgDSkeBgX43AXKI")}
            value="Upload Test Image"
            type="button"
            data-test-id='picker-test'
          />
        ) : (
          <Grid container className={classes.pickerWrapper} ref={pickerWrapper}>
            <PickerOverlay
              apikey={fileStackAPIKey}
              pickerOptions={basicOptions}
              onUploadDone={({ filesUploaded }) => onSuccess(filesUploaded[0].url)}
            />
          </Grid>
        )}
      </>
    )}
  </>;
};

export default ImagePicker;
