import React, { useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@mui/material';

type DropdownBaseProps = {
  className?: string,
  customPaper?: (handleClose) => React.ReactElement,
  menuComponent: (open: boolean, anchorRef, handleToggle) => React.ReactElement,
  menuList: React.ReactElement,
  segmentEventOnDropdownClick?: () => void,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  menuListWrapper: {
    zIndex: 5,
  },
}));

const DropdownBase = ({
  className,
  customPaper,
  menuComponent,
  menuList,
  segmentEventOnDropdownClick,
}: DropdownBaseProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    };

    setOpen(false);
  };

  const handleToggle = () => {
    if(!open && segmentEventOnDropdownClick) { segmentEventOnDropdownClick() }

    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box className={className}>
      {menuComponent(open, anchorRef, handleToggle)}
      <Popper
        anchorEl={anchorRef.current}
        className={classes.menuListWrapper}
        keepMounted
        open={open}
        role={undefined}
        transition
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            {!!customPaper ?
              customPaper(handleClose)
            :
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  {menuList}
                </ClickAwayListener>
              </Paper>
            }
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default DropdownBase;
