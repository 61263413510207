import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import StayMiniView from './StayMiniView';
import StayNanoView from './StayNanoView';
import InfoWindow from "../../../../../shared/MapView/InfoWindow";
import { StayType } from "../../../../../shared/TripObjectTypes";
import { tripStayPath } from "../../../../../../routes";
import { TripSiteContext } from "../../../../../Document";
import useMapClickEventRef from "../../../../../../services/segmentEvents/useMapClickEventRef";
import { searchLocation } from "../../../../../../services/windowLocation";

type StayMarkerProps = {
  stay: StayType,
  active: boolean,
  lat: number,
  lng: number,
  onMarkerClick: () => void,
  onMarkerClickOut: () => void,
  variant: "mini" | "nano",
};

const useStyles = makeStyles(({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const StayMarker = ({
  stay,
  active,
  // eslint-disable-next-line no-unused-vars
  lat,
  // eslint-disable-next-line no-unused-vars
  lng,
  onMarkerClickOut,
  onMarkerClick,
  variant,
}: StayMarkerProps) => {
  const classes = useStyles();
  const { tripId } = useContext(TripSiteContext);
  const location = searchLocation()

  const mapClickRef = useMapClickEventRef('stays', stay.uuid, location);


  const InfoWindowContent = () => (
    variant === "nano" ? (
      <StayNanoView
        stayPath={tripStayPath(tripId, stay.uuid)}
        stayUrl={stay.publicUrl}
        images={stay.images}
        isDisabled={stay.isDisabled}
        name={stay.name}
        provider={stay.provider}
      />
    ) : (
      <StayMiniView stay={stay} />
    )
  );

  return (
    <>
      {active &&
        <InfoWindow onClickOut={onMarkerClickOut} position='absolute'>
          <InfoWindowContent />
        </InfoWindow>
      }
      <img
        className={classes.root}
        alt='stay_marker'
        data-test-id="stayMarker"
        height="30px"
        onClick={onMarkerClick}
        src="/assets/location_marker.png"
        width="22px"
        ref={mapClickRef}
      />
    </>
  );
};

export default StayMarker;
