import { gql } from '@apollo/client'

export const AI_SEARCH_FEEDBACK_CREATE = gql`
  mutation AiSearchFeedbackCreate($tripId: ID, $userId: ID, $response: String!, $modelRequestId: String!) {
    aiSearchFeedbackCreate(input: {
      tripId: $tripId,
      userId: $userId,
      response: $response,
      modelRequestId: $modelRequestId,
    }) {
      aiSearchFeedback {
        id
      }
      errors
    }
  }
`

export const AI_SEARCH_FEEDBACK_ADD_MESSAGE = gql`
  mutation AiSearchFeedbackAddMessage($aiSearchFeedbackId: ID!, $message: String!) {
    aiSearchFeedbackAddMessage(input: {
      aiSearchFeedbackId: $aiSearchFeedbackId,
      message: $message,
    }) {
      errors
    }
  }
`
