import React from 'react';
import { SegmentContext } from "../../components/Document";
import { trackLink } from '../analytics';

const useMapClickEventRef = (vertical, objectId, aiSearchType: string | null = null) => {
  const { tripId, currentUserId, isPlanner } = React.useContext(SegmentContext);

  const buttonRef = React.useCallback((element) => {
    if (element !== null) {
      trackLink(
        element,
        'Map Object Clicked',
        {
          tripId,
          userId: currentUserId,
          planner: isPlanner,
          vertical,
          objectId,
          location: aiSearchType,
        },
      );
    }
  }, []);

  return buttonRef;
};

export default useMapClickEventRef;
