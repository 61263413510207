import useSegment from '../useSegment';

export const useClickedFavoritesEvent = () => {
  const { trackSegment } = useSegment();
  const isFavoritesPage = window.location.pathname === '/favorites';
  const isSearchResultPage = window.location.href.includes('/search?');

  return(() =>
    trackSegment(
      'Favorites Clicked',
      {
        page: isFavoritesPage ? 'favorites' : isSearchResultPage ? 'listing results' : 'search',
      },
    )
  )
};

export const useListingFavoriteEvent = (listingURL) => {
  const { trackSegment } = useSegment();

  return(() =>
    trackSegment(
      'Listing Favorited',
      {
        listingURL: listingURL,
      },
    )
  )
};

export const useListingFavoriteRemovedEvent = (stayURL) => {
  const { trackSegment } = useSegment();
  const isFavoritesPage = window.location.pathname === '/favorites';

  return(() =>
    trackSegment(
      'Listing Favorite Removed',
      {
        listingURL: stayURL,
        page: isFavoritesPage ? 'favorites' : 'listing results',
      },
    )
  )
};

export const useShareFavoritesInitiatedEvent = (favoritesCount) => {
  const { trackSegment } = useSegment();
  const isSharedFavorites = window.location.href.includes('/favorites/');

  return(() =>
    trackSegment(
      'Share Favorites Initiated',
      {
        page: isSharedFavorites ? 'shared favorites' : 'favorites',
        listingCount: favoritesCount,
      },
    )
  )
};

export const useFavoriteShareLinkCopiedEvent = (favoritesCount) => {
  const { trackSegment } = useSegment();
  const isSharedFavorites = window.location.href.includes('/favorites/');

  return(() =>
    trackSegment(
      'Favorite Share Link Copied',
      {
        page: isSharedFavorites ? 'shared favorites' : 'favorites',
        listingCount: favoritesCount,
      },
    )
  )
};

export const useFavoriteShareSheetEvent = (favoritesCount) => {
  const { trackSegment } = useSegment();
  const isSharedFavorites = window.location.href.includes('/favorites/');

  return(() =>
    trackSegment(
      'Favorite Share Sheet Menu Activated',
      {
        page: isSharedFavorites ? 'shared favorites' : 'favorites',
        listingCount: favoritesCount,
      },
    )
  )
};
