import { useContext } from 'react';
import { SegmentContext } from '../components/Document';
import { trackLink, track } from '../services/analytics';

const useSegment = () => {
  const segmentProps = useContext(SegmentContext);

  return {
    trackSegment: (eventTitle: string, customProps: {}) => {
      track(eventTitle, Object.assign({}, segmentProps, customProps))
    },
    trackLinkSegment: (element: HTMLElement, eventTitle: string, customProps: {}) => {
      trackLink(element, eventTitle, Object.assign({}, segmentProps, customProps))
    },
  }
};

export default useSegment;
