import React from "react";
import { NumericFormat } from 'react-number-format';
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExternalListingPricing } from "../../../../shared/TripObjectTypes";
import { ListingsContext } from "../../StaysSearch";
import pluralize from 'pluralize';

type ListingPricingProps = {
  available: boolean,
  pricing: ExternalListingPricing,
  isHotel?: boolean,
};

type PriceProps = {
  value: number,
};

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'left',
    fontSize: 16,
    color: '#272727',
  },
  addDatesText: {
    color: '#3689FF',
    zIndex: 10000000,
  },
  totalPrice: {
    color: '#555E64',
  },
  nightlyPrice: {
    fontWeight: 600,
  },
}));

const ListingPricing = ({
  available,
  pricing: {
    currency,
    pricePerNight,
    price,
    type,
    hotelRoomCount,
  },
  isHotel,
}: ListingPricingProps) => {
  const classes = useStyles();
  const isTotalPrice = type === 'total';
  const { openSearchCriteriaDrawer } = React.useContext(ListingsContext);

  const onAddPricingClick = (e) => {
    e.preventDefault();
    openSearchCriteriaDrawer();
  }

  const Price = ({ value }: PriceProps) => (
    <NumericFormat
      value={value}
      displayType={'text'}
      decimalScale={2}
      fixedDecimalScale={value % 1 !== 0}
      thousandSeparator={true}
      prefix={currency}
    />
  );

  const renderNumRooms = () => isHotel ? <> • {pluralize('room', Number(hotelRoomCount || 1), true)}</> : null;

  const renderNightlyPrice = <span className={classes.nightlyPrice}>
    <Price value={Math.round(pricePerNight)} /> night {!isTotalPrice ? renderNumRooms() : null}
  </span>;

  const renderTotalPrice = <span className={classes.totalPrice}>
    (<Price value={price} /> total{renderNumRooms()})
  </span>;

  return <>
    {available ? (
      <Typography className={classes.root}>
        {price ? (
          <span>
            {renderNightlyPrice}{isTotalPrice && renderTotalPrice}
          </span>
        ) : (
          <span className={classes.addDatesText} onClick={onAddPricingClick}>
            (Add dates for pricing)
          </span>
        )}
      </Typography>
    ) : (
      <Typography>
        Unavailable for your dates
      </Typography>
    )}
  </>;
};

export default ListingPricing;
