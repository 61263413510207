import React, { useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import ButtonBase from "@mui/material/ButtonBase";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useSegment from "../../services/useSegment";

export type EventTrackerType = {
  category: 'Activity' | 'Stay',
  vertical: 'Itinerary' | 'Activities',
};

export type ViewDetailsTrackerType = {
  category: 'Activity' | 'Stay',
  page: 'Itinerary' | 'Stays',
  objectId: string,
  link: string,
};

export type TripObjectLinkProps = {
  url: string,
  linkText: string,
  eventTracker?: EventTrackerType,
  dataTestId?: string,
  viewDetailsTracker?: ViewDetailsTrackerType,
  onClick?: () => void,
};

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 2,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: 15,
    height: '40px',
    color: theme.palette.primary.main,
  },
  openNewTabIcon: {
    fontSize: '16px',
    marginLeft: theme.spacing(0.5),
  },
}));

const TripObjectLink = ({
  url,
  linkText,
  eventTracker,
  viewDetailsTracker,
  dataTestId,
  onClick = () => {},
}: TripObjectLinkProps) => {
  const classes = useStyles();
  const { trackLinkSegment } = useSegment();

  const refs = useCallback((element) => {
    if(eventTracker) {
      trackLinkSegment(
        element,
        'Details Viewed',
        {...eventTracker},
      );
    }

    if(viewDetailsTracker) {
      trackLinkSegment(
        element,
        'View Details Clicked',
        {...viewDetailsTracker},
      );
    }
  }, []);

  const onButtonClick = () => {
    onClick();
    window.open(url, '_blank')
  };

  return (
    <ButtonBase
      className={classes.root}
      onClick={()=> onButtonClick()}
      data-test-id={dataTestId}
      ref={(element) => refs(element)}
    >
      {linkText} <OpenInNewIcon className={classes.openNewTabIcon}/>
    </ButtonBase>
  );
};

export default TripObjectLink;
