import React, { useState } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ConfirmationDialog from "./ConfirmationDialog";
import { track as trackAnalyticsEvent } from '../../services/analytics';

type InviteDialogProps = {
  isInviteValid?: boolean,
  inviterName?: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    color: theme.palette.text.primary,
  },
  bold: {
    fontWeight: 500,
    display: 'inline',
  },
  titleWrapper: {
    textAlign: 'center',
  },
  titleEmoji: {
    fontSize: 48,
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  confirmationDescription: {
    paddingBottom: theme.spacing(3),
    color: theme.palette.text.primary,
    maxWidth: '300px',
    margin: '0 auto',
  },
  confirmationButtonText: {
    marginLeft: theme.spacing(1),
  },
  copiedLinkButton: {
    background: [[theme.palette.general.dark], '!important'],
  },
  howThisWorks: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: '#244599',
    textTransform: 'uppercase',
  },
  bodyWrapper: {
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    lineHeight: '24px',
  },
  bodyEmoji: {
    fontSize: 28,
    paddingRight: 16,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 28,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
    },
  },
  invalidDescription: {
    marginTop: theme.spacing(3),
  },
  inviteDescription: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    fontSize: 16,
  },
}));

const trackCloseButtonClickedEventInSegment = () => {
  trackAnalyticsEvent('Welcome Modal Closed', {
    category: 'Welcome Modal',
    label: 'Dismissed',
  });
};

const trackConfirmButtonClickedEventInSegment = () => {
  trackAnalyticsEvent('Welcome Modal Closed', {
    category: 'Welcome Modal',
    label: '"Got it" button',
  });
};

const InviteDialog = ({
  inviterName,
  isInviteValid,
}: InviteDialogProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(true);

  const handleClose = () => {
    setDialogOpen(false);
    location.replace(location.pathname);
  };

  const handleCloseButtonClick = () => {
    trackCloseButtonClickedEventInSegment();
    handleClose();
  };

  const handleConfirmButtonClick = () => {
    trackConfirmButtonClickedEventInSegment();
    handleClose();
  };

  return (
    <ConfirmationDialog
      actionText="Got it"
      fullWidthButtons
      hideCancelButton
      isOpen={dialogOpen}
      message={
        <>
          <div className={classes.titleWrapper}>
            {isInviteValid ? (
              <Box>
                <Typography className={classes.titleEmoji}>
                  👋
                </Typography>
                <Typography className={classes.title}>
                  Welcome to Troupe!
                </Typography>
                <Typography className={classes.confirmationDescription}>
                  {`${inviterName} invited you to join an upcoming trip`}
                </Typography>
                <Typography className={classes.howThisWorks}>
                  * How This Works *
                </Typography>
              </Box>
              ) : (
              <Box>
                <Typography className={classes.titleEmoji}>
                  📭
                </Typography>
                <Typography className={classes.title}>
                  Something is missing...
                </Typography>
              </Box>
            )}
          </div>
          <div className={classes.bodyWrapper}>
            {isInviteValid ? (
              <Box>
                <Typography className={classes.inviteDescription}>
                  <span className={classes.bodyEmoji}>
                    🤓
                  </span>
                  Take a look around
                </Typography>
                <Typography className={classes.inviteDescription}>
                  <span className={classes.bodyEmoji}>
                    🛏️
                  </span>
                  Explore the <b>Stays</b> tab
                </Typography>
                <Typography className={classes.inviteDescription}>
                  <span className={classes.bodyEmoji}>
                    🎉
                  </span>
                  Hit the <b>Respond to invite</b> button
                </Typography>
              </Box>) : (
              <Box>
                <Typography className={classes.invalidDescription}>
                  Looks like that invite link is no longer valid for some reason.
                </Typography>
                <Typography className={classes.invalidDescription}>
                  Don’t worry, you can still join this trip by hitting “Join trip” on the Overview page.
                </Typography>
              </Box>
            )}
          </div>
        </>
      }
      onClose={handleCloseButtonClick}
      onConfirm={handleConfirmButtonClick}
    />
  );
};

export default InviteDialog;
