import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import StarIcon from '@mui/icons-material/Star';

type RatingFilterProps = {
  rating?: string,
  updateRatingFilter: (value?:string) => void;
};

const useStyles = makeStyles((theme) => ({
  button: {
    border: '1px solid #D9D9D9',
    borderRadius: 5,
    color: '#272727',
    fontSize: 14,
    height: theme.spacing(4),
  },
  activeButton: {
    color: '#272727',
    background: '#E5E7FF !important',
    border: '2px solid #545DFC !important',
    borderRadius: 5,
    height: theme.spacing(4),
    textColor: '#272727',
  },
  star: {
    fill: '#D9D9D9',
    paddingLeft: theme.spacing(0.5),
    verticalAlign: 'top',
  },
  activeStar: {
    fill: '#FBA500',
    paddingLeft: theme.spacing(0.5),
    verticalAlign: 'top',
  },
  ratingRow: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
}));

const RatingFilter : React.FC<RatingFilterProps> = ({
  rating,
  updateRatingFilter,
}) => {
  const classes = useStyles();
  const listOfRatings = ['Any', '3.5', '4.0', '4.5'];
  const isButtonActive = (index: number) => {
    switch (index) {
      case 1:
        return rating === '3.5';
      case 2:
        return rating === '4.0';
      case 3:
        return rating === '4.5';
      default:
        return rating === '';
    }
  }

  return(
    <Grid item container direction='column' spacing={1}>
      <Grid item mb={1}>
        <Typography variant="h3">Rating</Typography>
      </Grid>
      <Grid item container direction='row' spacing={1} className={classes.ratingRow}>
          <Grid item>
            At least
          </Grid>
          <Grid item container spacing={1} flexWrap='nowrap'>
            {listOfRatings.map((ratingLabel, index) => (
              <Grid item key={ratingLabel} justifyContent="flex-start" alignItems="center">
                <Grid item>
                <Button
                  data-test-id='rating-filter-button'
                  variant="outlined"
                  className={isButtonActive(index) ? classes.activeButton : classes.button}
                  onClick={() => {
                    updateRatingFilter(index === 0 ? '' : ratingLabel);
                  }}>
                  <Grid item>
                    <Typography data-test-id='rating-filter-button'>
                      {ratingLabel}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {index !== 0 && (
                      <StarIcon
                        data-test-id='star-icon'
                        className={isButtonActive(index) ? classes.activeStar : classes.star}/>
                    )}
                  </Grid>
                </Button>
                </Grid>
              </Grid>
            ))}
          </Grid>
      </Grid>
    </Grid>
  )
};

export default RatingFilter;
