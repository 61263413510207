import React from "react";
import pluralize from "pluralize";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { makeStyles } from '@mui/styles';
import CommentBox from "./Comments/CommentBox";
import { SyncComment as Comment } from "./TripObjectTypes";
import CommentForm from "./Comments/CommentForm";
import Divider from "./Divider";
import { Commentable } from '../pages/comments/IndexPage';

type CommentsProps = {
  commentable: Commentable,
  comments: Comment[],
  commentCreationError?: string,
  isUserLoggedIn: boolean,
  headerText: string,
};

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
  },
  headerCommentsCount: {
    fontSize: '1em',
  },
  instruction: {
    fontSize: '1em',
  },
  commentsList: {
    listStyleType: "none",
    padding: 0,
    '& li': {
      marginTop: theme.spacing(3),
    },
    '&:last-child': { paddingBottom: theme.spacing(1.25) },
    '&:empty': { paddingBottom: 0 },
  },
}));

const Comments = ({
  commentable,
  comments,
  commentCreationError,
  isUserLoggedIn,
  headerText,
}: CommentsProps) => {
  const classes = useStyles();

  return <>
    <Grid container className={classes.header} justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant='h2'>Comments</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.headerCommentsCount}>
          {comments.length} {pluralize('Comment', comments.length)}
        </Typography>
      </Grid>
    </Grid>
    <Typography className={classes.instruction}>{headerText}</Typography>
    {commentCreationError && (<Alert severity="error">{commentCreationError}</Alert>)}
    <Box sx={{ my: 3 }}>
      <a id="new_comment_form" />
      <CommentForm
        commentsPath={commentable.commentsPath}
        isUserLoggedIn={isUserLoggedIn}
      />
    </Box>
    <Divider />
    <ul className={classes.commentsList} data-test-id="comment-list">
      {comments.map((comment) => (
        <CommentBox key={comment.id} comment={comment} />
      ))}
    </ul>
  </>;
}

export default Comments;
