import React from 'react';
import { SegmentContext } from "../../components/Document";
import { TravelerProps } from '../../components/shared/TravelersVotingBox';
import useSegment from '../useSegment';

export const useTravelerClickEvent = () => {
  const { currentUserId } = React.useContext(SegmentContext);
  const { trackSegment } = useSegment();

  return( (traveler: TravelerProps) =>
    trackSegment(
      'Traveler Avatar Clicked',
      {
        plusOneCount: traveler?.plusOnes.length || 0,
        creator: traveler?.userId === currentUserId,
      },
    )
  )
};

export const useDismissDialogEvent = () => {
  const { currentUserId } = React.useContext(SegmentContext);
  const { trackSegment } = useSegment();

  return( (traveler: TravelerProps) =>
    trackSegment(
      'Traveler Avatar Modal Dismissed',
      {
        plusOneCount: traveler?.plusOnes.length || 0,
        creator: traveler?.userId === currentUserId,
      },
    )
  )
};
