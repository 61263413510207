import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "../../buttons/Button";
import useSegment from "../../../services/useSegment";

type ExternalListingCTAProps = {
  publicUrl: string,
  provider: string,
  location: 'Public Search' | 'Ai Search'
};

const useStyles = makeStyles(theme => ({
  root: {
    float: 'right',
    whiteSpace: 'nowrap',
    width: '100%',
    height: '40px !important',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

export const ExternalListingCTA = ({
  publicUrl,
  provider,
  location,
}: ExternalListingCTAProps) => {
  const classes = useStyles();
  const { trackLinkSegment } = useSegment();

  const buttonRef = React.useCallback((element) => {
    if (element !== null) {
      trackLinkSegment(
        element,
        'Stay Listing CTA Clicked',
        {
          location,
          ctaName: 'view',
          source: provider,
        },
      );
    }
  }, []);

  return (
    <Button
      className={classes.root}
      onClick={() => window.open(publicUrl, '_blank')}
      variant='primary'
      ref={buttonRef}
    >
      View
    </Button>
  );
};

export default ExternalListingCTA;
