import React, { useState, useContext } from "react";
import { Box, Grid } from "@mui/material";
import MostRecentCommentBox from "../shared/MostRecentCommentBox";
import { StayType } from "../../../../shared/TripObjectTypes";
import {
  tripStayCommentPath,
  tripStayCommentsPath,
  tripStayCommentsURL,
  tripStayPath,
} from '../../../../../routes'
import { TripSiteContext } from "../../../../Document";
import { TravelerProps } from "../../../../shared/TravelersVotingBox";
import { AsyncComment, SyncComment } from "../../../../shared/TripObjectTypes";
import Stay from "./Stay";
import VotingProgressDialog from "../../../../shared/VotingProgressDialog";
import {
  useVotingProgressReducer,
  OPEN_PROGRESS_DIALOG,
  CLOSE_PROGRESS_DIALOG,
} from "../../../../../services/useVotingProgressReducer";
import BookedWrapper from "./BookedWrapper";

type StaysListProps = {
  isPlanner: boolean,
  stays: StayType[],
  travelers: TravelerProps[],
};

type MostRecentCommentProps = {
  comment: AsyncComment | null,
  commentsCount: number,
  showForm: boolean,
  stayId: string,
  tripId: string,
};

type CommentPathProps = {} | {
  commentPath: string,
  commentsUrl: string,
};

const MostRecentComment = ({
  comment,
  commentsCount,
  showForm,
  stayId,
  tripId,
}: MostRecentCommentProps) => {
  const pathProps: CommentPathProps = !comment ? {} : {
    commentPath: tripStayCommentPath(tripId, stayId, comment.uuid),
    commentsUrl: tripStayCommentsURL(tripId, stayId, { anchor: `comment_${comment.uuid}` }),
  }
  const { author, ...mostRecentComment } = comment ?? {}

  return (
    <Grid item>
      <MostRecentCommentBox
        comment={comment && {
          ...mostRecentComment,
          ...pathProps,
          id: comment.uuid,
          gid: comment.id,
          authorAvatarUrl: author.avatarUrl,
          authorName: author.displayName,
          timestamp: parseInt(comment.timestamp, 10),
        } as SyncComment}
        commentFormPath={tripStayCommentsPath(tripId, stayId)}
        commentsPath={tripStayPath(tripId, stayId)}
        showCommentForm={showForm}
        showLoadMoreCommentsButton={commentsCount > 1}
        tripId={tripId}
      />
    </Grid>
)}

const StaysList = ({
  isPlanner,
  stays,
  travelers,
}: StaysListProps) => {

  const { tripId } = useContext(TripSiteContext);
  const { displayDialog, voteCompleted, dispatch } = useVotingProgressReducer();

  const votedCount = stays.reduce((count, stay) => (stay.currentUserRanking !== null ? count + 1 : count), 0);

  const staysWithRankings = [...stays].sort((a, b) => {
    if(a.ranking === null || b.ranking === null) return 1;
    return (a.ranking > b.ranking ? 1 : -1);
  });

  const initialValue = Array<boolean>(2).fill(false)
  const [
    showCommentForm,
    setShowCommentForm,
  ] = useState<boolean[]>(initialValue);

  const toggleCommentFormVisibility = (index) => {
    const newArray = Array.from(showCommentForm);
    newArray[index] = !showCommentForm[index];
    setShowCommentForm(newArray);
  };

  const votingProgress = (voteCompleted: boolean) => {
    dispatch({ type: OPEN_PROGRESS_DIALOG, voteCompleted });
  };

  return (
    <>
      {staysWithRankings.map((stay, index) => (
      <React.Fragment key={stay.uuid}>
        <Box mb={6} >
          <BookedWrapper booked={(stay.stayBookings?.length || 0) > 0} linkUrl={tripStayPath(tripId, stay.uuid)}>
            <Stay
              stay={stay}
              hideVotes={false}
              imgPath={tripStayPath(tripId, stay.uuid)}
              isPlanner={isPlanner}
              isClickable
              showActions
              suggestionsCount={staysWithRankings.length}
              linkNameToProvider
              travelers={travelers}
              setStayComment={() => toggleCommentFormVisibility(index)}
              displayActions
              showCommentButton
              showVotingProgressBanner
              page={'index'}
              openVotingProgress={votingProgress}
              stayMethod='staysVertical'
              displayBookButton
            />
          </BookedWrapper>
          {(stay.mostRecentComment || showCommentForm[index]) && (
            <Grid pt={2}>
              <MostRecentComment
                comment={stay.mostRecentComment as AsyncComment}
                commentsCount={stay.commentsCount}
                showForm={showCommentForm[index]}
                stayId={stay.uuid}
                tripId={tripId}
              />
            </Grid>
          )}
        </Box>
      </React.Fragment>
      ))}
      <VotingProgressDialog
        isOpen={displayDialog}
        closeDialog={() => dispatch({ type: CLOSE_PROGRESS_DIALOG, displayDialog: false })}
        totalVotes={staysWithRankings.length}
        votedCount={votedCount}
        vertical="Stays"
        voteCompleted={voteCompleted}
      />
    </>
  );
};

export default StaysList;
