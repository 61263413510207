import React from 'react';
import { makeStyles } from "@mui/styles";
import { Grid, Chip } from '@mui/material';

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  crown: {
    paddingRight: theme.spacing(1),
  },
  mostPopularDateOption: {
    backgroundColor: '#1ebe95',
    borderRadius: '3px',
    color: 'white',
    cursor: 'inherit',
    fontSize: 14,
    fontWeight: 600,
    height: 24,
    "& span": {
      padding: 6,
    },
  },
}));

const GroupFavoriteChip = () => {
  const classes = useStyles();

  return (
    <Grid item className={classes.root}>
      <Chip
        data-test-id="most-popular"
        label={
          <>
            <img className={classes.crown} src="/assets/illustrations/Crown.svg"/>
            Group favorite
          </>
        }
        className={classes.mostPopularDateOption}/>
    </Grid>
  )
};

export default GroupFavoriteChip;
