import React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Errors from '../../../shared/Errors';
import ModalForm from '../../../shared/ModalForm';
import { hocRailsAction } from "../../../Document";
import { tripItineraryDatePath, tripActivityEventPath } from "../../../../routes";
import { ItineraryEventType, ItineraryDateType } from "../../../shared/TripObjectTypes";
import moment from "moment";
import { isSafari, isDesktop } from "react-device-detect";
import ItineraryActivityEvent from "./ItineraryDatePermapage/ItineraryActivityEvent";

export type EditItineraryEventFormProps = {
  itineraryDate: ItineraryDateType,
  itineraryEvent: ItineraryEventType,
  errors?: string[]
  withChangeDetection?: boolean,
  source: 'itineraryPermapage' | 'scheduleList',
};

type StyleProps = {
  isSafariDesktop: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  label: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
  },
  timeInput: {
    width: 191,
    height: 56,
    [theme.breakpoints.down('sm')]: {
      width: 131,
    },
    '& input': {
      // ensure placeholder text is visible on Safari desktop and that we don't break styles on iOS and other devices
      backgroundColor: ({ isSafariDesktop }) => isSafariDesktop ? theme.palette.background.default : null,
    },
  },
}));

const EditItineraryEventForm = ({
  itineraryDate,
  itineraryEvent,
  errors,
  withChangeDetection = true,
  source,
}: EditItineraryEventFormProps) => {
  const classes = useStyles({ isSafariDesktop: isSafari && isDesktop });

  const errorText = "End time can't come before start time";

  const [startTime, setStartTime] = React.useState <string> (itineraryEvent.startTime);
  const [endTime, setEndTime] = React.useState <string> (itineraryEvent.endTime);
  const [errorsState, setErrors] = React.useState <string[]> (errors);

  const disableSubmit = () : boolean => {
    const isStartTimeSelected = !!startTime;
    const isEndTimeSelected = !!endTime;
    return isStartTimeSelected !== isEndTimeSelected;
  }

  const areValidTimes = () => {
    const startTimeMoment = moment(startTime, "HH:mm");
    const endTimemoment = moment(endTime, "HH:mm");
    if (startTimeMoment > endTimemoment) {
      setErrors([errorText]);
      return false;
    }

    return true;
  }

  const onSubmit = e => {
    e.preventDefault();
    areValidTimes() && e.target.submit();
  }

  return (
    <ModalForm
      closePath={tripItineraryDatePath(itineraryEvent.tripId, itineraryDate.id)}
      formActionPath={tripActivityEventPath(itineraryEvent.tripId, itineraryEvent.id) + `?source=${source}`}
      formMethod="patch"
      saveButtonLabel='Save'
      subtitle='Let travelers know when this activity will be happening.'
      title='Edit time'
      disabledSubmit={disableSubmit()}
      onClick={onSubmit}
      withChangeDetection={withChangeDetection}
    >
      <input type="hidden" name="itinerary_date_id" value={itineraryDate.id} />
      {errorsState && (
        <Box mb={2}>
          <Errors errors={errorsState} />
        </Box>
      )}
      <Grid mb={2} >
        <ItineraryActivityEvent
          itineraryDateId={itineraryDate.id}
          activityEvent={itineraryEvent}
          isClickeable
          page={'editForm'}
        />
      </Grid>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography className={classes.label}>Add a time</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
          <TextField
            id="start_time"
            label="Start time"
            type="time"
            value={startTime}
            onChange={e => setStartTime(e.target.value)}
            name="itinerary_event[start_time]"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.timeInput}
          />
          </Grid>
          <Grid item>
          <TextField
            id="end_time"
            label="End time"
            type="time"
            value={endTime}
            onChange={e => setEndTime(e.target.value)}
            name="itinerary_event[end_time]"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.timeInput}
          />
          </Grid>
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(EditItineraryEventForm);
