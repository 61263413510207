import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TripTypeDestinations from "../TripTypeDestinations";
import TopSuggestedDestinationsTileList from "./TopSuggestedDestinationsTileList";
import { SuggestedDestinationType } from "../StaysSearch";
import { isCurrentlyInPublicSearch } from "../../../../services/windowLocation";
import { aiSearchType } from "./Filters";
import AiPublicSearchEmptyState from "./AiPublicSearchEmptyState";

type ListingsEmptyStateProps = {
  aiSearchType?: aiSearchType,
  topSuggestedDestinations?: SuggestedDestinationType[],
};

const useStyles = makeStyles((theme) => ({
  image: {
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '400px',
    },
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  emptyStateText: {
    textAlign: 'center',
    fontSize: '18px',
  },
  emptyStateHeader: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 600,
    color: '#272727',
    marginTop: theme.spacing(2),
  },
}));

const ListingsEmptyState : React.FC<ListingsEmptyStateProps> = ({
  aiSearchType,
  topSuggestedDestinations = [],
}) => {
  const classes = useStyles();
  const showTopSuggestedDestinations = topSuggestedDestinations.length > 0
  const showEnterDestinationCopy = !aiSearchType && !showTopSuggestedDestinations;

  const renderEnterDestinationCopy = () => (
    <Grid container direction="column" justifyContent="center" data-test-id="enter-destination">
      <img
        className={classes.image}
        alt="Initial load"
        src="/assets/illustrations/Search_03.svg"
      />
      <Typography className={classes.emptyStateHeader}>
        Enter your destination to get started
      </Typography>
      <Typography className={classes.emptyStateText}>
        and leave the search to us.
      </Typography>
    </Grid>
  );

  return (
    <>
      {showEnterDestinationCopy && renderEnterDestinationCopy()}
      <Grid container direction="column" spacing={5}>
        {isCurrentlyInPublicSearch() && (
          <Grid item sx={{ width: '100%'}}>
            <TripTypeDestinations />
          </Grid>
        )}
        {showTopSuggestedDestinations && (
          <Grid item sx={{ width: '100%'}}>
            <TopSuggestedDestinationsTileList topSuggestedDestinations={topSuggestedDestinations} />
          </Grid>
        )}
      </Grid>
      {aiSearchType === 'aiPublicSearch' && <AiPublicSearchEmptyState />}
    </>
  )
}

export default ListingsEmptyState;
