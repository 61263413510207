import React, { useCallback, useContext } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import chipColors from "./chipColors";
import { FormContext } from "../shared/RailsForm";
import { trackLink as trackAnalyticsEvent } from "../../services/analytics";

type ChipVariant = 'primary' | 'success' | 'successLighter' | 'danger' ;
type Styles = (variant: ChipVariant) => (() => any);

export type ChipProps = {
  children: React.ReactNode,
  active?: boolean,
  className?: string,
  disabled?: boolean,
  onClick?: () => void,
  onMouseDown?: (event) => void,
  type?: 'button' | 'submit' | 'reset',
  variant?: ChipVariant,
  segmentProps?: SegmentProps,
};

type SegmentProps = {
  title: string,
  tripObjectId: string,
}

const useStyles: Styles = (
  variant: ChipVariant,
) => makeStyles((theme): {} => ({
  root: {
    height: theme.spacing(6),
    border: '1px solid',
    borderRadius: theme.spacing(3),
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '&:focus': {
      boxShadow: '0 0 0 1px #80b4fb',
    },
  },
  nonActive: {
    borderColor: chipColors[variant].default.border,
    color: chipColors[variant].default.color,
    background: chipColors[variant].default.fill,
    '&:hover': {
      borderColor: chipColors[variant].hover.border,
      color: chipColors[variant].hover.color,
      background: chipColors[variant].hover.fill,
    },
  },
  active: {
    borderColor: chipColors[variant].active.border,
    color: chipColors[variant].active.color,
    background: chipColors[variant].active.fill,
    boxShadow: 'none',
    '&:hover': {
      borderColor: chipColors[variant].activeHover.border,
      color: chipColors[variant].activeHover.color,
      background: chipColors[variant].activeHover.fill,
    },
  },
  disabled: {
    borderColor: [[chipColors[variant].disabled.border], '!important'],
    color: [[chipColors[variant].disabled.color], '!important'],
    background: [[chipColors[variant].disabled.fill], '!important'],
    boxShadow: [['none'], '!important'],
  },
}));

const Chip = ({
  active,
  children,
  className,
  disabled,
  variant,
  segmentProps,
  ...buttonProps
}: ChipProps) => {
  const classes = useStyles(variant)();
  const formContext = useContext(FormContext);

  const isDisabled = () => (
    disabled || (buttonProps.type === 'submit' && formContext.disabled)
  );

  const refs = useCallback((element) => {
    if (element !== null && segmentProps !== undefined) {
      trackAnalyticsEvent(
        element,
        segmentProps.title,
        {tripObjectId: segmentProps.tripObjectId}
      );
    }
  }, []);

  return (
    <ButtonBase
      {...buttonProps}
      disabled={isDisabled()}
      className={clsx(
        classes.root,
        {
          [classes.active]: active,
          [classes.nonActive]: !active,
          [classes.disabled]: isDisabled(),
        },
        className,
      )}
      data-test-state={active ? 'active' : undefined}
      ref={(element) => refs(element)}
    >
      {children}
    </ButtonBase>
  );
};

Chip.defaultProps = {
  variant: 'primary',
};

export default Chip;
