import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Autosave from "@ckeditor/ckeditor5-autosave/src/autosave";
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import CloudServicesConfig from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import PendingActions from '@ckeditor/ckeditor5-core/src/pendingactions';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import * as Sentry from "@sentry/browser";

type RichTextEditorProps = {
  defaultValue?: string,
  disabled?: boolean,
  onChange?: (text: string) => void,
  onAutosave?: (text: string) => void,
  placeholder?: string,
  readOnly?: boolean,
};

const useStyles = makeStyles((theme) => ({
  ckWidgetSelectionHandlerReadOnly: {
    '& div.ck-widget__selection-handle': {
      display: 'none',
    },
    '& figure.ck-widget_selected': {
      outline: 'initial !important',
    },
  },
  // NOTE: workaround via ckeditor issue about embedded media:
  // * https://github.com/ckeditor/ckeditor5/issues/2743#issuecomment-794520947
  embedMediaFix: {
    '& .ck-editor__editable:not(.ck-read-only) .ck-widget_selected .ck-media__wrapper>:not(.ck-media__placeholder)': {
      pointerEvents: 'initial !important',
    },
  },
}));

const buildEditorConfiguration = ({
  disabled,
  onAutosave,
  placeholder,
}) => {
  const toolbars = disabled ? {} : {
    items: [
      'heading',
      'bold',
      'underline',
      'link',
      '|',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'italic',
      'strikethrough',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      'insertTable',
      '|',
      'indent',
      'outdent',
      '|',
      'blockQuote',
      'mediaEmbed',
      'uploadImage',
      '|',
      'undo',
      'redo',
    ],
  };

  return {
    autosave: onAutosave ? {
      save(editor) {
        onAutosave(editor.getData());
      },
    } : {},
    cloudServices: {
      tokenUrl: '/api/ck_editor_token',
      uploadUrl: 'https://80233.cke-cs.com/easyimage/upload/',
    },
    placeholder: placeholder,
    plugins: [
      Heading,
      Autosave,
      Table,
      TableToolbar,
      TableProperties,
      TableCellProperties,
      BlockQuote,
      Bold,
      CloudServicesConfig,
      EasyImage,
      Essentials,
      Font,
      Image,
      ImageUpload,
      Indent,
      Italic,
      Link,
      List,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      PendingActions,
      Strikethrough,
      TextTransformation,
      TodoList,
      Underline,
    ],
    shouldNotGroupWhenFull: true,
    toolbar: toolbars,
    table: {
      contentToolbar: [
          'tableColumn', 'tableRow', 'mergeTableCells',
          'tableProperties', 'tableCellProperties'
      ],
    }
  };
};

const RichTextEditor = ({
  defaultValue,
  disabled,
  onAutosave,
  onChange,
  placeholder,
  readOnly,
}: RichTextEditorProps) => {
  const classes = useStyles();
  let editorInstance = null;

  const onEditorReady = (editor) => {
    editorInstance = editor;
  };

  const onEditorError = (error, { willEditorRestart }) => {
    if (error.message !== 'Abort') {
      Sentry.captureException(error);
    }

    // If the editor is restarted, the toolbar element will be created once again.
    // The `onReady` callback will be called again and the new toolbar will be added.
    // This is why you need to remove the older toolbar.
    if (willEditorRestart) {
       editorInstance.ui.view.toolbar.element.remove();
    };
  };

  return (
    <div
      className={clsx(
        "ck-editor-wrapper",
        classes.embedMediaFix,
        {
          [classes.ckWidgetSelectionHandlerReadOnly]: readOnly,
          ["ck-disabled-editor"]: disabled,
        },
        )
      }
    >
      <CKEditor
        editor={ClassicEditor}
        config={buildEditorConfiguration({ disabled, onAutosave, placeholder })}
        data={defaultValue}
        disabled={disabled}
        onChange={(event, editor) => {
          onChange && onChange(editor.getData());
        }}
        onReady={onEditorReady}
        onError={onEditorError}
      />
    </div>
  );
};

export default RichTextEditor;
