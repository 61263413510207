import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  ActivityType,
} from "../../../../shared/TripObjectTypes";
import { TripType } from "../../../../shared/TripTypes";
import Activity from "../ActivitiesPage/Activity";
import MostRecentCommentBox from "../shared/MostRecentCommentBox";
import { tripGetActivityPath } from '../../../../../routes';
import { EventTrackerType } from "../../../../shared/TripObjectLink";

type ActivitiesListProps = {
  activities: ActivityType[],
  trip?: TripType,
  groupPermapage?: boolean,
  activityGroupId?: string,
};

const ActivitiesList = ({
  activities,
  trip,
  groupPermapage = false,
  activityGroupId,
}: ActivitiesListProps) => {

  const initialValue = Array(activities.length).fill(false);
  const [
    showCommentForm,
    setShowCommentForm,
  ] = useState(initialValue);

  const toggleCommentFormVisibility = (index) => {
    const newArray = Array.from(showCommentForm);
    newArray[index] = !showCommentForm[index];
    setShowCommentForm(newArray);
  };

  const activityTracker: EventTrackerType = {
    category: 'Activity',
    vertical: 'Activities',
  };

  const linkToComment = activity => groupPermapage ?
    tripGetActivityPath(activity.tripId, activity.id, { groupId: activityGroupId })
    :
    tripGetActivityPath(activity.tripId, activity.id);

  return <>
    {activities.map((activity, index) => (
      <Box mt={3} mb={4} key={index} id={`activity_${activity.id}`}>
        <Grid container direction="column" spacing={2}>
          <Grid item container>
            <Activity
              activity={activity}
              activityGroupId={activityGroupId}
              activityTracker={activityTracker}
              displayCommentButton
              groupPermapage={groupPermapage}
              isPlanner={trip?.isPlanner}
              linkToComment={linkToComment(activity)}
              setActivityComment={() => toggleCommentFormVisibility(index)}
              showActions
              showGroupsTitles
            />
          </Grid>
          {(activity.mostRecentComment || showCommentForm[index]) && (
            <Grid item>
              <MostRecentCommentBox
                comment={activity.mostRecentComment}
                commentFormPath={activity.commentsPath}
                commentsPath={activity.tripObjectPath}
                page={groupPermapage ? 'ActivityGroupPermapage' : 'Vertical'}
                showCommentForm={showCommentForm[index]}
                showLoadMoreCommentsButton={activity.commentsCount > 1}
                tripId={activity.tripId}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    ))}
  </>;
};

export default ActivitiesList;
