import React, { useContext, useReducer } from "react";
import axios from 'axios';
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Avatar from "../../../avatars/Avatar";
import Button from '../../../buttons/Button';
import EmailInviteModal from "../../../shared/EmailInviteModal";
import { FormsContext, GlobalContext, SegmentContext } from '../../../Document';
import * as routes from '../../../../routes';
import performAsyncRequestWithCancelToken from "../../../../services/performAsyncRequestWithCancelToken";
import { track as trackAnalyticsEvent } from "../../../../services/analytics";

const { resendApiTripInvitePath, apiTripInvitePath } = routes;

export type PendingInviteData = {
  createdAt: string,
  email: string,
  id: string,
  inviterName: string,
  lastResendAttemptedAt?: string,
};

type PendingInviteProps = {
  invite: PendingInviteData,
  tripId: string,
  onInviteDelete: (invites: PendingInviteData[]) => void,
  disableResendButton?: boolean,
};

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(1.5),
  },
  actionItem: {
    marginRight: theme.spacing(1.5),
  },
  createdAtText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  divider: {
    marginTop: theme.spacing(2.5),
  },
  error: {
    color: '#e44444',
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  info: {
    paddingTop: `0px !important`,
  },
  titleWrapper: {
    textAlign: 'center',
    flexDirection: 'row',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      minWidth: 480,
    },
  },
}));

const initialState = {
  resendLabel: 'Resend',
  deleteLabel: 'Delete invite',
  resendEnabled: true,
  loading: false,
  actionError: null,
  resendModalOpened: false,
};

const reducer = (state, action) => {
  const setState = (props) => Object.assign({}, state, props);

  switch (action.type) {
    case 'initial':
      return initialState;
    case 'deleting':
      return setState({ actionError: null, deleteLabel: 'Deleting...', loading: true })
    case 'sending':
      return setState({ actionError: null, resendLabel: 'Sending...', resendEnabled: false, loading: true });
    case 'sent':
      return setState({ resendLabel: 'Sent!', resendEnabled: false, loading: false });
    case 'setError':
      return setState({...initialState, actionError: action.message });
    case 'resendModalOpen':
      return setState({...initialState, resendModalOpened: true});
    case 'resendModalSent':
      return setState({resendModalOpened: false, resendLabel: 'Sent!', resendEnabled: false, loading: false});
    case 'resendModalClose':
      return setState({...initialState, resendModalOpened: false});
    default:
      throw new Error();
  }
};

const PendingInvite = ({
  invite,
  onInviteDelete,
  tripId,
  disableResendButton = false,
}: PendingInviteProps) => {
  const classes = useStyles();
  const { currentUserId, isPlanner } = useContext(SegmentContext);
  const { csrfToken } = useContext(FormsContext);
  const isMobileApp = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [actionsState, dispatch] = useReducer(reducer, initialState);
  const timeoutError = 'Something went wrong, please try again.';
  const segmentProps = {
    userId: currentUserId,
    tripId: tripId,
    planner: isPlanner,
    isMobileApp: isMobileApp,
  };
  const [lastResendAttemptedAt, setLastResendAttemptedAt] = React.useState(invite.lastResendAttemptedAt);

  const deleteInvite = (invite) => {
    dispatch({ type: 'deleting' })
    axios.request({
      method: 'DELETE',
      url: apiTripInvitePath(tripId, invite.id),
      data: {
        authenticity_token: csrfToken,
      },
    }).then((response) => {
      trackAnalyticsEvent('Invite Deleted', {
        category: 'Invites',
        emailAddress: invite.email,
        tripId: tripId,
      });
      dispatch({ type: 'deleting' });
      onInviteDelete(response.data.pendingInvites);
    })
    .catch((error) => {
      dispatch({ type: 'setError', message: error.response?.data?.errors ? error.response.data.errors : timeoutError });
    });
  };

  const onResendInviteButtonCliked = () => {
    openResendInviteModal()
  };

  const openResendInviteModal = () => {
    trackAnalyticsEvent('Invite Resend Initiated', segmentProps);
    dispatch({type: 'resendModalOpen'});
  };

  const resendInviteSuccess = (hasCustomMessage) => {
    trackAnalyticsEvent('Invite Resent', Object.assign(segmentProps, {message: hasCustomMessage}));
    setLastResendAttemptedAt(new Date()
      .toLocaleDateString("en-US", { month: 'long', day: 'numeric', year: 'numeric' }),
    );
    dispatch({type: 'resendModalSent'});
  };

  const resendInvite = (invite) => {
    dispatch({ type: 'sending' });

    performAsyncRequestWithCancelToken({
      method: 'POST',
      url: resendApiTripInvitePath(tripId, invite.id),
      data: {
        authenticity_token: csrfToken,
        invite_id: invite.id,
      },
      onSuccess: (response) => {
        trackAnalyticsEvent('Invite Resent', {
          category: 'Invites',
          emailAddress: invite.email,
          tripId: tripId,
        });
        dispatch({ type: 'sent' })
      },
      onError: (error) => dispatch({ type: 'setError', message: error.response?.data?.errors ? error.response.data.errors : timeoutError }),
    });
  };

  const renderPendingInviteText = () => {
    return(
    lastResendAttemptedAt ? (
      `Last invited ${lastResendAttemptedAt}`
      ) : (
      `Invited ${invite.createdAt}`
    )
  )};

  return (
    <>
      <Grid container data-test-id="pendingInvite" spacing={1}>
        <Grid item>
          <Avatar />
        </Grid>
        <Grid item className={classes.info}>
          <Grid item>
            <Typography>
              {invite.email}
            </Typography>
            <Typography className={classes.createdAtText}>
              {renderPendingInviteText()}
            </Typography>
          </Grid>
          <Grid item container className={classes.actions}>
            <Grid item className={classes.actionItem}>
              <Button
                disabled={actionsState.loading}
                onClick={() => deleteInvite(invite)}
                variant='tertiary'
              >
                {actionsState.deleteLabel}
              </Button>
            </Grid>
            <Grid item>
              <Button
                data-test-id='resend-invite-button'
                disabled={!actionsState.resendEnabled || actionsState.loading || disableResendButton}
                onClick={() => onResendInviteButtonCliked()}
                variant='secondary'
              >
                {actionsState.resendLabel}
              </Button>
            </Grid>
          </Grid>
          {actionsState.actionError && (
            <Grid item className={classes.error}>
              {actionsState.actionError}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider className={classes.divider}/>
      {actionsState.resendModalOpened &&
        <EmailInviteModal
          title='Resend invite'
          tripId={tripId}
          resendEmail={[invite.email]}
          onResend={resendInviteSuccess}
          onModalClose={() => dispatch({ type: 'resendModalClose' })}
        />
      }
    </>
  );
};

export default PendingInvite;
