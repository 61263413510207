import useSegment from '../useSegment';

export const useAddReactionEvent = () => {
  const { trackSegment } = useSegment();

  return( ({
    isUnique,
    source,
    objectType,
    objectId,
    reaction,
    selfReaction,
  }) => trackSegment(
    'Reaction Added',
    {
      isUnique,
      source,
      objectType,
      objectId,
      reaction,
      selfReaction,
    },
  ))
};

export const useRemoveReactionEvent = () => {
  const { trackSegment } = useSegment();

  return( ({
    objectType,
    objectId,
    reaction,
    selfReaction,
  }) => trackSegment(
    'Reaction Removed',
    {
      objectType,
      objectId,
      reaction,
      selfReaction,
    },
  ))
};
