import React from "react";
import { Box } from "@mui/material";
import Button, { ButtonProps } from "../../../../buttons/Button";
import { theme } from "../../../../Document"

type SectionCtaButtonProps = ButtonProps & {
  children: React.ReactNode,
}
const SectionCtaButton = React.forwardRef(({
  children,
  ...buttonProps
}: SectionCtaButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  return (
    <Box sx={{marginBottom: theme.spacing(4), textAlign: 'center'}}>
      <Button
        ref={ref}
        {...buttonProps}
      >
        {children}
      </Button>
    </Box>
  );
});

SectionCtaButton.displayName = 'SectionCtaButton'

export default SectionCtaButton;
