import { gql } from "@apollo/client";

export const TRAVELERS_QUERY = gql`
  query fetchTravelerPlusOnesByTripId($tripId: ID!) {
    travelers(tripId: $tripId) {
      avatarUrl
      displayName
      joinedAt
      plusOneCount
      status
      isPlanner
      uuid
      user {
        uuid
      }
      plusOnes {
        id
        uuid
        displayName
      }
      id
    }
  }
`;

