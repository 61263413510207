import React, { useContext, useEffect, useReducer } from "react";
import { gql, useMutation } from "@apollo/client";
import { makeStyles, useTheme } from "@mui/styles";
import {
  CircularProgress,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import Button from "../buttons/Button";
import { GlobalContext, SegmentContext } from "../Document";
import InputWithCharacterCounter from './InputWithCharacterCounter';
import { track as trackAnalyticsEvent } from "../../services/analytics";
import pluralize from 'pluralize';
import * as Sentry from "@sentry/browser";

type EmailInviteFormProps = {
  tripId: string,
  tripGid: string,
  resendEmail?: string[],
  onClose?: () => void,
  onResend?: (hasCustomMessage: boolean) => void,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  spinner: {
    color: 'white',
    verticalAlign: 'middle',
  },
  title: {
    color: theme.palette.text.primary,
  },
  emailField: {
    width: '100%',
    '& p': {
      fontSize: 14,
    },
  },
  messageField: {
    '&:hover fieldset': {
      borderColor: 'grey',
    },
  },
}));

const emailBlankErrorMessage = "Email can't be blank";
const genericErrorMessage = "Something went wrong, please try again";

type State = {
  customMessage?: string,
  disableActions: boolean,
  emailError: boolean,
  errorMessage: string | null,
  initialInviteesEmails: string[],
  inviteesEmails: string[],
  snackbarMessage: string | null,
  resendInvite: boolean,
}

const initialReducerState: State = {
  customMessage: null,
  disableActions: false,
  emailError: false,
  errorMessage: null,
  initialInviteesEmails: [],
  inviteesEmails: [],
  snackbarMessage: null,
  resendInvite: false,
};

const scrollToError = () => {
  document.getElementById('invite_emails')
    .scrollIntoView(
      { behavior: 'smooth', block: 'start' },
    );
};

type Action =
  | { type: 'DEFAULT_STATE' }
  | {
      type: 'INVITE_SEND_SUCCESSFUL',
      message: string,
    }
  | { type: 'EMAIL_BLANK' }
  | {
      type: 'EMAIL_ERROR',
      message: string,
      invalidEmails: string[],
      disableActions: boolean,
    }
  | {
      type: 'CHANGED_INVITEE',
      emails: string,
    }
  | {
      type: 'SET_CUSTOM_MESSAGE',
      payload: string,
    }
  | {
      type: 'RESEND_INVITE',
      email: string[],
    }
;

const inviteReducer = (state: State, action: Action) => {
  const setState = (props: Partial<State>) => Object.assign({}, state, props);

  switch (action.type) {
    case 'DEFAULT_STATE':
      return setState(initialReducerState);
    case 'INVITE_SEND_SUCCESSFUL':
      return setState(Object.assign({}, initialReducerState, { snackbarMessage: action.message }));
    case 'EMAIL_BLANK':
      return setState({ emailError: true, errorMessage: emailBlankErrorMessage });
    case 'EMAIL_ERROR':
      return setState({ emailError: true, errorMessage: action.message, inviteesEmails: action.invalidEmails, initialInviteesEmails: action.invalidEmails, disableActions: action.disableActions });
    case 'CHANGED_INVITEE': {
      const emails = action.emails
        .split(/,|:|;/)
        .map(value => value.trim());

      const emailsWithoutDuplicates = emails
        .filter((value, index) => emails.indexOf(value) === index);

      return setState({ inviteesEmails: emailsWithoutDuplicates, snackbarMessage: null });
    }
    case 'SET_CUSTOM_MESSAGE': {
      return { ...state, customMessage: action.payload };
    }
    case 'RESEND_INVITE': {
      return setState({...state, inviteesEmails: action.email, snackbarMessage: null, resendInvite: true });
    }
    default:
      throw new Error(`Unknown action type: ${JSON.stringify(action)}`);
  }
};

const emailRegexp = new RegExp(/\S+@\S+\.\S+/);

export const TRIP_INVITES_CREATE = gql`
  mutation TripInvitesCreate($tripId: ID!, $emailAddresses: [String!]!, $customMessage: String, $resendInvite: Boolean) {
    tripInvitesCreate(input: {
      tripId: $tripId,
      emailAddresses: $emailAddresses
      customMessage: $customMessage
      resendInvite: $resendInvite
    }) {
      tripInvites {
        email
      }
      errors
      invalidRecipientsEmails
    }
  }
`
const EmailInviteForm = ({
  tripId,
  tripGid,
  resendEmail,
  onClose,
  onResend,
}: EmailInviteFormProps) => {
  const [state, dispatch] = useReducer(inviteReducer, initialReducerState);
  const classes = useStyles();
  const { setSnackbar } = useContext(GlobalContext);
  const { currentUserId, isPlanner } = useContext(SegmentContext);
  const isMobileApp = useMediaQuery(useTheme().breakpoints.down('sm'));
  const isResendInvite = !!resendEmail;

  const [
    sendInvitesMutation,
    { loading, error },
  ] = useMutation(TRIP_INVITES_CREATE)

  const inviteesEmailsCount = state.inviteesEmails.filter((value) => {
    return value.match(emailRegexp);
  }).length;

  const actualInviteesEmails = state.inviteesEmails.filter(email => email && email.length);
  const canSendInvites = !state.disableActions && actualInviteesEmails.length && !loading &&
    JSON.stringify(actualInviteesEmails) !== JSON.stringify(state.initialInviteesEmails);

  const trackInvitesEvent = (tripInvites) => {
    if(!isResendInvite) {
      return trackAnalyticsEvent('Invite Completed', {
        category: 'Invites',
        method: 'Email',
        emailList: tripInvites.map(invite => invite.email),
        tripId: tripId,
        emailCount: inviteesEmailsCount,
        message: !!state.customMessage,
      });
    }

    if(resendEmail.length > 1) {
      return trackAnalyticsEvent('All Invites Resent', {
        userId: currentUserId,
        tripId: tripId,
        planner: isPlanner,
        isMobileApp: isMobileApp,
        count: inviteesEmailsCount,
        message: !!state.customMessage,
      });
    }
  };

  const sendEmail = () => {
    if (inviteesEmailsCount === 0) {
      return dispatch({ type: 'EMAIL_BLANK' });
    }
    sendInvitesMutation({
      variables: {
        tripId: tripGid,
        emailAddresses: state.inviteesEmails,
        customMessage: state.customMessage,
        resendInvite: isResendInvite,
      },
      onCompleted: ({tripInvitesCreate: { tripInvites, errors, invalidRecipientsEmails }}) => {
        if (tripInvites && errors.length === 0) {
          trackInvitesEvent(tripInvites)
        }
        if (errors.length !== 0) {
          const invalidEmails = invalidRecipientsEmails ?? state.inviteesEmails
          dispatch({
            type: 'EMAIL_ERROR',
            message: errors.join(', '),
            invalidEmails,
            disableActions: errors.includes('You need to log in to do that.'),
          });
        } else if (tripInvites) {
          // NOTE: only trigger success toast in event of no errors.
          dispatch({
            type: 'INVITE_SEND_SUCCESSFUL',
            message: `Success! Your ${pluralize('invite', inviteesEmailsCount)} ${pluralize('was', inviteesEmailsCount)} sent.`,
          });
          if (onClose) onClose();

          if(isResendInvite) {
            const hasCustomMessage = !!state.customMessage
            onResend(hasCustomMessage);
          }
        } else {
          Sentry.captureException(
            new Error(`missing both errors and tripInvites: ${tripInvites}, ${errors}, ${invalidRecipientsEmails}}`),
          )
        }
      },
      onError: (error) => {
        dispatch({
          type: 'EMAIL_ERROR',
          message: genericErrorMessage,
          invalidEmails: state.inviteesEmails,
          disableActions: error.toString().includes('You need to log in to do that.'),
        });
      },
    })
  };

  const sendInviteButtonLabel = isResendInvite ? `Resend (${inviteesEmailsCount})` : `Send invites (${inviteesEmailsCount})`;
  const emailInputLabel = isResendInvite ? 'Email' : 'Add email addresses separated by commas';

  useEffect(() => {
    if (state.emailError || error) scrollToError();
  }, [state.emailError]);

  useEffect(() => {
    if (state.snackbarMessage) { setSnackbar(state.snackbarMessage, 3000) }
  }, [state.snackbarMessage]);

  useEffect(() => {
    if(isResendInvite) {
      dispatch({ type: 'RESEND_INVITE', email: resendEmail })
    }
  }, []);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography data-test-id='invite-modal-input' variant={isResendInvite ? 'h3' : 'body1'} className={classes.title}>
          {emailInputLabel}
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          id="invite_emails"
          data-test-id='invite-emails-field'
          error={state.emailError}
          value={state.inviteesEmails}
          onChange={(event) => dispatch({ type: 'CHANGED_INVITEE', emails: event.target.value })}
          helperText={state.errorMessage}
          className={classes.emailField}
          placeholder="Email addresses"
          variant="outlined"
          disabled={isResendInvite}
        />
      </Grid>
      <Grid item>
        <Typography variant='h3' py={1}>
          Include a message (optional)
        </Typography>
        <InputWithCharacterCounter
          className={classes.messageField}
          placeholder="Add a message that travelers will see in your email invitation."
          fullWidth
          withBorder
          multiline
          rows={5}
          characterLimit={250}
          onChange={(event) => dispatch({ type: 'SET_CUSTOM_MESSAGE', payload: event.target.value})}
        />
      </Grid>
      <Grid item>
        <Button
          data-test-id="submit"
          disabled={!canSendInvites}
          fullWidth
          onClick={() => sendEmail()}
        >
          {loading ? (
            <CircularProgress className={classes.spinner} />
          ) : (
            sendInviteButtonLabel
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmailInviteForm;
