import React, { useContext } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FlatButton from "../../buttons/FlatButton";
import CardGroupCarouselContext from '../CardGroupCarousel/Context';

enum Direction {
  Previous = 'previous',
  Next = 'next',
}

type CarouselStepButtonProps = {
  direction: Direction,
  dataTestId: string,
};

const CarouselStepButton = ({
  direction,
  dataTestId,
}: CarouselStepButtonProps) => {
  const { currentStep, setCurrentStep, maxStep } = useContext(CardGroupCarouselContext);

  const isPrevious = direction === Direction.Previous;
  const icon = isPrevious ? ChevronLeftIcon : ChevronRightIcon;
  const disabled = (isPrevious && currentStep === 0) || (!isPrevious && currentStep === maxStep) || (!isPrevious && maxStep === -1);
  const nextStep = isPrevious ? -1 : 1;

  const onStep = () => {
    let newIndex = currentStep + nextStep;
    if (newIndex > maxStep) newIndex = maxStep;
    if (newIndex < 0) newIndex = 0;

    setCurrentStep(newIndex);
  };

  return (
    <FlatButton
      onClick={onStep}
      disabled={disabled}
      data-test-id={dataTestId}
    >
      {React.createElement(icon)}
    </FlatButton>
  );
};

export const PreviousStepButton = () => <CarouselStepButton direction={Direction.Previous} dataTestId="previous-step-button" />;
export const NextStepButton = () => <CarouselStepButton direction={Direction.Next} dataTestId="next-step-button" />;

export default CarouselStepButton;
