import React, { useContext, useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Grid,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TripNote } from './NotesListPage';
import withLayout from "./withLayout";
import SectionHeader from "./shared/SectionHeader";
import { FormsContext } from '../../../Document';
import RichTextEditor from '../../../shared/RichTextEditor';
import RichText from '../../../shared/RichText';
import * as routes from '../../../../routes';
import { HEADER_BAR_HEIGHT } from '../../../../components/shared/SiteHeader';
import { NAV_BAR_HEIGHT } from '../../../../components/trip-page/NavigationBar'
import CircularProgress from '@mui/material/CircularProgress';
import clsx from "clsx";
import Breadcrumbs from "../../../shared/Breadcrumbs";
import TimeStamp from "../../../shared/TimeStamp";
import ActionButtons from "./NotesPage/ActionButtons";

type NotesPageProps = {
  isUserLoggedIn: boolean,
  showDeleteButton: boolean,
  tripNote: TripNote,
  trip: { id: string, isTraveler: boolean },
};

const {
  tripNotePath,
  tripNotesPath,
} = routes;

type StyleProps = {
  fullHeaderHeight: number;
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  editor: {
    width: 'fill-available',
    '& .ck.ck-sticky-panel .ck-sticky-panel__content_sticky': {
      top: ({ fullHeaderHeight }) => fullHeaderHeight,
    },
  },
  indicatorImage: {
    marginTop: theme.spacing(-0.2),
  },
  indicatorIcon: {
    marginRight: theme.spacing(1),
  },
  savingIndicationWrapper: {
    height: 20,
    fontSize: 16,
    fontWeight: 600,
    color: '#5F6368',
    '& .MuiCircularProgress-colorPrimary': {
      color: theme.palette.primary.main,
    },
  },
  updatedAt: {
    color: '#334351',
    display: 'inline',
    fontWeight: 400,
    fontSize: '16px',
    fontStyle: 'italic',
    lineHeight: '24px',
  },
}));

const NotesPage = ({
  isUserLoggedIn,
  showDeleteButton,
  tripNote,
  trip,
}: NotesPageProps) => {
  const fullHeaderHeight = HEADER_BAR_HEIGHT + NAV_BAR_HEIGHT;
  const classes = useStyles({ fullHeaderHeight });
  const [saveIndicatorLabel, setSaveIndicatorLabel] = useState('Saved');
  const { csrfToken } = useContext(FormsContext);
  const breadcrumbs = [
    {
      label: 'All notes',
      href: tripNotesPath(trip.id),
    },
  ];

  const onAutosave = (body: string) => {
    axios.put(
      tripNotePath(trip.id, tripNote.id),
      { trip_note: { body: body }, authenticity_token: csrfToken, format: 'json' },
    ).then(() => setSaveIndicatorLabel('Saved'))
    .catch(() => setSaveIndicatorLabel('Try again'));
  };

  const savingIndicatorIcon = (icon: 'Yes' | 'No', testId: string) =>
    <img
      data-test-id={testId}
      className={clsx(classes.indicatorIcon, classes.indicatorImage)}
      src={`/assets/illustrations/${icon}.svg`}
    />

  const saveIndicatorItem = () =>
    <Grid className={classes.savingContainer} container justifyContent="end">
      <Grid item container direction="row" className={classes.savingIndicationWrapper}>
        {saveIndicatorLabel === 'Saving' && (<CircularProgress data-test-id='saving-icon' className={classes.indicatorIcon} size={24} />)}
        {saveIndicatorLabel === 'Saved' && (savingIndicatorIcon('Yes', 'saved-img'))}
        {saveIndicatorLabel === 'Try again' && (savingIndicatorIcon('No', 'error-img'))}
        {saveIndicatorLabel}
      </Grid>
    </Grid>

  return (
    <Container className={classes.root}>
      <Box pb={3}>
        <Breadcrumbs
          active={tripNote.title}
          links={breadcrumbs}
        />
      </Box>
      <SectionHeader
        title={tripNote.title}
        rightElement={
          <>
            {isUserLoggedIn && trip.isTraveler &&
              <ActionButtons tripNote={tripNote} showDeleteButton={showDeleteButton}/>}
          </>
        }
        note={
          <>
            {
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item className={classes.updatedAt}>
                  Last updated <TimeStamp date={tripNote.updatedAt} className={classes.updatedAt} />
                </Grid>
                <Grid item>
                  {saveIndicatorItem()}
                </Grid>
              </Grid>
            }
            {!isUserLoggedIn && (
              <span>
                <b> Log in</b> to be able to update this trip's notes.
              </span>
            )}
          </>
        }
      />
      <Grid container direction="column" spacing={2}>
        <Grid item className={classes.editor}>
          {isUserLoggedIn && trip.isTraveler ? (
            <RichTextEditor
              defaultValue={tripNote.body}
              onAutosave={onAutosave}
              onChange={() => setSaveIndicatorLabel('Saving')}
              placeholder="Use this section of the tripsite to: share important links, include your flight or car rental details, estimate your group’s shared costs, and so much more!"
            />
          ) : (
            <RichText value={tripNote.body} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};


// noinspection JSUnusedGlobalSymbols
export default withLayout(NotesPage);
