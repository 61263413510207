import useSegment from '../useSegment';

export const useRankingChangeEvent = () => {
  const { trackSegment } = useSegment();

  return( (vertical) =>
    trackSegment(
      'Voting Updated',
      {
        vertical,
      },
    )
  )
};
