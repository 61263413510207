type notificationsMapType = {
  label: string,
  emailProp: string,
  smsProp: string,
  pushProp: string,
  emailName: string,
  smsName: string,
  pushName: string,
};

export const notificationsMap: notificationsMapType[] = [
    {
      label: "When travelers vote",
      emailProp: "emailVotes",
      smsProp: "smsVotes",
      pushProp: "pushVotes",
      emailName: "email_votes",
      smsName: "sms_votes",
      pushName: "push_votes",
    },
    {
      label: "When new suggestions are made",
      emailProp: "emailSuggestions",
      smsProp: "smsSuggestions",
      pushProp: "pushSuggestions",
      emailName: "email_suggestions",
      smsName: "sms_suggestions",
      pushName: "push_suggestions",
    },
    {
      label: "When travelers comment",
      emailProp: "emailComments",
      smsProp: "smsComments",
      pushProp: "pushComments",
      emailName: "email_comments",
      smsName: "sms_comments",
      pushName: "push_comments",
    },
    {
      label: "When travelers add reactions",
      emailProp: "emailTravelerReaction",
      smsProp: "smsTravelerReaction",
      pushProp: "pushTravelerReaction",
      emailName: "email_traveler_reaction",
      smsName: "sms_traveler_reaction",
      pushName: "push_traveler_reaction",
    },
    {
      label: "When decisions are finalized",
      emailProp: "emailFinalChoices",
      smsProp: "smsFinalChoices",
      pushProp: "pushFinalChoices",
      emailName: "email_final_choices",
      smsName: "sms_final_choices",
      pushName: "push_final_choices",
    },
    {
      label: "When travelers join my trip",
      emailProp: "emailNewTraveler",
      smsProp: "smsNewTraveler",
      pushProp: "pushNewTraveler",
      emailName: "email_new_traveler",
      smsName: "sms_new_traveler",
      pushName: "push_new_traveler",
    },
    {
      label: "When planners request suggestions",
      emailProp: "emailSuggestingOpened",
      smsProp: "smsSuggestingOpened",
      pushProp: "pushSuggestingOpened",
      emailName: "email_suggesting_opened",
      smsName: "sms_suggesting_opened",
      pushName: "push_suggesting_opened",
    },
    {
      label: "When planners open voting",
      emailProp: "emailVotingOpened",
      smsProp: "smsVotingOpened",
      pushProp: "pushVotingOpened",
      emailName: "email_voting_opened",
      smsName: "sms_voting_opened",
      pushName: "push_voting_opened",
    },
    {
      label: "When broadcasts are sent",
      emailProp: "emailBroadcastSent",
      pushProp: "pushBroadcastSent",
      smsProp: "smsBroadcastSent",
      emailName: "email_broadcast_sent",
      pushName: "push_broadcast_sent",
      smsName: "sms_broadcast_sent",
    },
    {
      label: "When travelers open or close a poll",
      emailProp: "emailPollOpenedClosed",
      pushProp: "pushPollOpenedClosed",
      smsProp: "smsPollOpenedClosed",
      emailName: "email_poll_opened_closed",
      pushName: "push_poll_opened_closed",
      smsName: "sms_poll_opened_closed",
    },
  ];

export default notificationsMap;
