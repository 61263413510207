import React from "react";
import { Grid, Typography } from "@mui/material";
import { NumericFormat } from 'react-number-format';
import { makeStyles } from "@mui/styles";
import { ItineraryEventType } from "../../../../shared/TripObjectTypes";
import { ViewDetailsTrackerType } from "../../../../shared/TripObjectLink";
import TripObjectLink, { EventTrackerType } from "../../../../shared/TripObjectLink";
import useSegment from "../../../../../services/useSegment";

type ItineraryEventDialogProps = {
  children: React.ReactNode,
  imageLabel?: React.ReactNode,
  itineraryDateId?: string,
  itineraryEvent: ItineraryEventType,
  price?: string,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(1),
    minHeight: '100px',
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  divider: {
    borderColor: '#e6e7e8',
    width: '100%',
    padding: theme.spacing(1),
  },
  priceText: {
    fontSize: theme.spacing(2),
    color: '#272727',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  name: {
    position: 'relative',
    left: '3px',
    textAlign: 'left',
    lineHeight: '24px',
    color: 'black',
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: [18, '!important'],
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: "calc(100% - 88px)",
      marginRight: theme.spacing(5),
    },
    maxWidth: '100%',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    overflow: 'hidden',
    wordBreak: 'break-word',
    zIndex: 2,
  },
  externalButtons: {
    flexDirection: 'row',
    padding: '16px 0px 16px 0',
    '& button:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      '& button': {
        marginRight: '8px',
      },
    },
  },
}
));

export const renderButtons = (itineraryEvent: ItineraryEventType) => {
  const classes = useStyles();
  const { trackSegment } = useSegment();
  const provider = itineraryEvent.provider?.toLowerCase().replace(/\.com$/, "");
  const linkText = () => {
    switch (provider) {
      case 'vrbo':
        return 'View on Vrbo';
      case 'expedia':
        return 'View on Expedia';
      default:
        return 'View on source';
    }
  };

  const viewDetailsTracker = () : ViewDetailsTrackerType => ({
    category: itineraryEvent.schedulableType,
    page: 'Itinerary',
    objectId: itineraryEvent.id,
    link: itineraryEvent.url,
  });

  const trackViewOnMap = () => {
    trackSegment('View Map Clicked', { objectId: itineraryEvent.id });
  };

  const eventTracker = () : EventTrackerType => ({
    category: itineraryEvent.schedulableType,
    vertical: 'Itinerary',
  });

  return <>
    {(itineraryEvent.url || itineraryEvent.metadata.googlePlaceUrl) && (
      <Grid container className={classes.externalButtons}>
        <TripObjectLink
          url={itineraryEvent.url || itineraryEvent.metadata.googlePlaceUrl}
          linkText={linkText()}
          eventTracker={eventTracker()}
          viewDetailsTracker={viewDetailsTracker()}
          dataTestId='view-details-link'
        />
        {itineraryEvent.metadata.googlePlaceUrl &&
          <div onClick={trackViewOnMap}>
            <TripObjectLink
              url={itineraryEvent.metadata.googlePlaceUrl}
              linkText='View on map'
              dataTestId='view-map-link'
              viewDetailsTracker={viewDetailsTracker()}
            />
          </div>
        }
      </Grid>
    )}
  </>;
};

const ItineraryEventDialog = ({
  children,
  imageLabel,
  itineraryEvent,
  price,
}: ItineraryEventDialogProps) => {
  const classes = useStyles();

  return (
    <Grid container direction="column"
      className={classes.root}
      data-test-id={`itinerary_dialog_${itineraryEvent.id}`}
    >
      {imageLabel}

      <Grid item data-test-id="event-name">
        <Typography
          className={classes.name}
        >
          {itineraryEvent.name}
        </Typography>
      </Grid>

      {price && (
        <Grid item pt={1}>
          <NumericFormat
            className={classes.priceText}
            value={price}
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale={Number(price) % 1 !== 0}
            thousandSeparator={true}
            prefix={'$'}
            suffix={' per person'}
          />
        </Grid>
      )}

      {children}
    </Grid>
  )
}

export default ItineraryEventDialog;
