import React, { useReducer, useRef, useState } from "react";
import pick from "lodash/pick";
import {
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { hocRailsAction } from "../../Document";
import Button from "../../buttons/Button";
import Errors from "../../shared/Errors";
import ModalForm from "../../shared/ModalForm";
import { Place } from "../../shared/GooglePlacesAutocomplete";
import { FormContext } from "../../shared/RailsForm";
import useStateWithPromise from "../../../services/useStateWithPromise";
import { track as trackAnalyticsEvent } from '../../../services/analytics';
import * as routes from '../../../routes';
import ActivityLocationField from "./ActivityLocationField";
import ActivityPreview from "./ActivityForm/ActivityPreview";
import CategoryLabel from "../trips/microsite/shared/CategoryLabel";
import { categoryList } from "./ActivityForm";
import { ActivityCategoryType } from "../../shared/TripObjectTypes";

const {
  tripActivitiesPath,
} = routes;

type RadioButtonProps = {
  label: string,
  value: string,
};

type ActivityLinkOrAddressForm = {
  addActivityByOption: string,
  errors: string[],
  tripId: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  addressTypeWrapper: {
    paddingTop: 0,
  },
  title: {
    fontWeight: 600,
    paddingBottom: theme.spacing(1),
  },
  centeredSection: {
    textAlign: 'center',
  },
  noActivityImage: {
    maxWidth: theme.spacing(50),
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  emptyStateImage: {
    maxWidth: theme.spacing(50),
    width: '100%',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  multipleLinksButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  categorySelect: {
    height: '56px',
  },
}));

const RadioButton = ({ label, value }: RadioButtonProps) => (
  <FormControlLabel
    value={value}
    control={<Radio color="primary" />}
    label={label}
  />
);

const ActivityLinkOrAddressForm = ({
  addActivityByOption,
  errors,
  tripId,
}: ActivityLinkOrAddressForm) => {
  const [value, setValue] = useStateWithPromise({});
  const [byAddressState, setByAddressState] = useStateWithPromise(addActivityByOption === 'address');
  const [addDetails, setAddDetails] = useStateWithPromise(false);
  const [selectedCategory, setSelectedCategory] = useState<ActivityCategoryType | ''>(value.category || '')
  const inputRef = useRef(null);
  const classes = useStyles();

  const initialState = {
    showFetchErrorMessage: false,
    showActions: false,
    showEmptyState: true,
  };

  const reducer = (state, action) => {
    switch(action.type) {
      case 'showFetchErrorMessage':
        return { showFetchErrorMessage: true, showActions: false, showEmptyState: false }
      case 'showActions':
        return { showFetchErrorMessage: false, showActions: true, showEmptyState: false }
      case 'hideAll':
        return { showFetchErrorMessage: false, showActions: false, showEmptyState: false }
      case 'resetForm':
        setValue({}).then(() => inputRef.current.focus());
        return initialState;
      default:
        throw new Error();
    }
  };

  const [{ showFetchErrorMessage, showActions, showEmptyState }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  let activityPreviewParams;

  if (value.link) {
    activityPreviewParams = { link: value.link, category: selectedCategory };
  } else if (value.location_name) {
    activityPreviewParams = {
      address: pick(value, ['location_name', 'google_place_id', 'google_place_image', 'google_place_url', 'longitude', 'latitude']),
      category: selectedCategory,
    };
  } else {
    activityPreviewParams = {};
  }

  const handleAddressChange = (address: Place) => {
    if (address) {
      setValue({
        location_name: address.name,
        google_place_id: address.id,
        google_place_url: address.url,
        google_place_image: address.image,
        latitude: address.latitude,
        longitude: address.longitude,
      });
    } else {
      dispatch({ type: 'resetForm' });
    }
  };

  const handleCategoryChange = ( event ) => {
    const category = event.target.value
    if (category) {
      setSelectedCategory(category)
    }
  }

  const handleCategoryClick = ( event ) => {
    if(event.target.textContent === selectedCategory) {
      setSelectedCategory("")
    }
  }

  const handleLinkChange = ( event ) => {
    if (event.target.value.trim()) {
      setValue({ link: event.target.value.trim(), category: selectedCategory })
    }
  }

  const trackAddMethodSwitched = (method) => {
    const newMethod = method === 'address' ? 'Add by Address' : 'Add by URL';

    trackAnalyticsEvent('Add Activities Method Switched', {
      category: 'Activities',
      newMethod: newMethod,
      tripId: tripId,
    });
  };

  const selectUrlOrAddress = (event) => {
    setByAddressState(event.target.value === 'address').then(() => {
      dispatch({ type: 'resetForm' });
    });
    trackAddMethodSwitched(event.target.value);
  };

  const saveButtonLabel = "Save & add details";

  return (
    <ModalForm
      closePath={tripActivitiesPath(tripId)}
      formActionPath={tripActivitiesPath(tripId)}
      formMethod="post"
      saveButtonLabel="Save now"
      saveButtonVariant="tertiary"
      subtitle="Suggest an activity for your group to do on your trip."
      title="Add activity"
      data-test-id='add-activity'
      withChangeDetection
    >
      <FormContext.Consumer>
        {({ onFormChange, submit }) => (
          <Grid container direction="column" spacing={2}>
            {!showActions && errors.length > 0 && (
              <Grid item><Errors errors={errors} /></Grid>
            )}
            <Grid item className={classes.addressTypeWrapper}>
              <Typography className={classes.title}>Add activity by...</Typography>
              <RadioGroup name="method" defaultValue={addActivityByOption} onChange={selectUrlOrAddress} data-test-id='radioGroup'>
                <RadioButton label="Link or URL" value="url" />
                <RadioButton label="Searching Google Maps" value="address" />
              </RadioGroup>
            </Grid>
            <Grid item>
              {byAddressState ? (
                <ActivityLocationField
                  currentGooglePlaceId={value.googlePlaceId}
                  currentGooglePlaceUrl={value.googlePlaceUrl}
                  currentLocationName={value.locationName}
                  currentLatitude={value.latitude}
                  currentLongitude={value.longitude}
                  currentImage={value.googlePlaceImage}
                  onChange={(address) => handleAddressChange(address)}
                  ref={inputRef}
                  required
                />
              ) : (
                <TextField
                  fullWidth
                  value={value.link}
                  id="activity_url"
                  inputRef={inputRef}
                  InputProps={{
                    endAdornment: value && (
                      <IconButton onClick={() => setValue({ link: "" }).then(() => onFormChange())}>
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  label="Link to activity"
                  name="activity[link]"
                  onChange={handleLinkChange}
                  placeholder="Paste your link here"
                  required
                  variant="outlined"
                />
              )}
              &nbsp;
              <Typography className={classes.title}>
                Set activity category
              </Typography>
              <Select
                displayEmpty
                fullWidth
                className={classes.categorySelect}
                value={selectedCategory}
                data-test-id="activity-category"
                name='activity[category]'
                onChange={handleCategoryChange}
                onClick={handleCategoryClick}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Select a category' }}
                renderValue={(selected) => {
                  if (!selected || selectedCategory === '') {
                    return <MenuItem disabled>Select an activity category</MenuItem>;
                  } else {
                    return <CategoryLabel selectBar category={selectedCategory}/>
                  }
                }}
                >
                {categoryList.map((category) => {
                  return (
                    <MenuItem
                      value={category}
                      key={`${category}-category-option`}
                      data-test-id={`${category}-category-option`}
                    >
                      {category}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <ActivityPreview
              onError={() => dispatch({ type: 'showFetchErrorMessage' })}
              onFetch={() => dispatch({ type: 'showActions' })}
              onLoading={() => dispatch({ type: 'hideAll' })}
              onRemove={() => dispatch({ type: 'resetForm' })}
              resourcePath={tripActivitiesPath(tripId)}
              tripId={tripId}
              { ...activityPreviewParams }
            />
            {showEmptyState && (
              <>
                <Grid item className={classes.centeredSection}>
                  <img
                    data-test-id='search-for-activities'
                    className={classes.emptyStateImage}
                    alt="Search for activities"
                    src={`/assets/${byAddressState ? 'illustrations/globe' : 'activity_fallback'}.svg`}
                  />
                </Grid>
              </>
            )}
            {showFetchErrorMessage && (
              <Grid item className={classes.centeredSection}>
                <img
                  data-test-id='no-preview-found'
                  className={classes.noStayImage}
                  alt="No preview found"
                  src={`/assets/activity_fallback.svg`}
                />
                <Typography>
                  We can't preview that URL but you can still save if you like.
                </Typography>
              </Grid>
            )}
            {showActions && (
              <Grid item>
                <Button
                  data-test-id="save-and-add"
                  fullWidth
                  variant="primary"
                  onClick={() => setAddDetails(true).then(() => submit())}
                  type="button"
                >
                  {saveButtonLabel}
                </Button>
              </Grid>
            )}
            {addDetails && (
              <input type="hidden" name="add_details" value="true" />
            )}
          </Grid>
        )}
      </FormContext.Consumer>
    </ModalForm>
  );
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(ActivityLinkOrAddressForm);
