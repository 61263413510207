import useSegment from '../useSegment';

export const useEditTripInitiated = () => {
  const { trackSegment } = useSegment();

  return(() =>
    trackSegment(
      'Edit Trip Initiated',
      {},
    )
  )
};
