import React, {useContext} from "react";
import clsx from "clsx";
import {
  Box,
  Divider,
  Grid,
  Hidden,
  IconButton,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from '@mui/material/styles';
import Tooltip from "./Tooltip";
import HeaderBar from "./SiteHeader/HeaderBar";
import TripName from "../pages/trips/microsite/shared/TripName";
import * as routes from '../../routes';
import useMediaQuery from "@mui/material/useMediaQuery";
import { TripType, PublicTripType, TripCoverPhotos } from "./TripTypes";
import { useEditTripInitiated } from "../../services/segmentEvents/tripEvents";

const {
  editTripPath,
} = routes;

enum CoverPhotoHeight {
  Desktop = 416,
  Mobile = 320,
}
export const HEADER_BAR_HEIGHT = 64;
const EDIT_ICON_TOP = 64;

type SiteHeaderProps = {
  tripUpdatesNum?: number,
  broadcastNum?: number,
  currentUserAvatarUrl?: string,
  currentUserId?: string,
  hideMyTripsButton?: boolean,
  isTripUpdatesHidden?: boolean,
  isUserPlanner?: boolean,
  isUserLoggedIn?: boolean,
  logoPath?: string,
  showCoverPhoto?: boolean,
  trip?: TripType | PublicTripType,
}

type StyleProps = {
  tripCoverPhotos: TripCoverPhotos,
  showCoverPhoto: boolean,
  coverPhotoHeight: CoverPhotoHeight,
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  headerBox: {
    position: 'relative',
    paddingBottom: theme.spacing(5),
    '@supports (margin-top: env(safe-area-inset-top))': {
      marginTop: 'env(safe-area-inset-top)',
    },
  },
  withCoverPhotoOnly: {
    height: ({ coverPhotoHeight }) => coverPhotoHeight,
  },
  headerCover: {
    position: 'absolute',
    width: '100%',
    top: () =>  HEADER_BAR_HEIGHT,
    height: theme.spacing(44),
    [theme.breakpoints.down('sm')]: {
      backgroundImage: ({ tripCoverPhotos, showCoverPhoto }) =>
        showCoverPhoto && tripCoverPhotos?.small ? `url(${tripCoverPhotos.small})` : 'initial',
      height: theme.spacing(32),
    },
    borderBottom: ({ showCoverPhoto }) => showCoverPhoto ? '1px solid #E6E7E8' : 'none',
  },
  headerBackground: {
    width: '100%',
    height: '100%',
  },
  headerForeground: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
  },
  headerWithBackgroundCoverPhoto: {
    backgroundImage: ({ tripCoverPhotos }) =>
      tripCoverPhotos?.big ? `url(${tripCoverPhotos.big})` : 'initial',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    filter: 'brightness(0.25)',
  },
  headerWithCoverPhotoContainer: {
    overflow: 'hidden',
  },
  headerWithCoverPhoto: {
    backgroundImage: ({ tripCoverPhotos }) =>
      tripCoverPhotos?.original ?
        `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.444) 59.25%, rgba(0, 0, 0, 0.6) 100%), url(${tripCoverPhotos.original})` : 'initial',
    backgroundSize: 'auto 100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  buttonsOuterDiv: {
    float: 'right',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  shareButton: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
    top: theme.spacing(8),
    transform: 'scale(-1, 1)',
    boxShadow: '0 2px 4px 0',
  },
  editIcon: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
    top: () => EDIT_ICON_TOP,
    color: '#505050',
    background: '#ffffff',
    boxShadow: '0 2px 4px 0',
    "&:hover": {
      color: '#353535',
      background: "#ffffff",
    },
  },
}));

const SiteHeader = ({
  tripUpdatesNum,
  broadcastNum,
  currentUserAvatarUrl,
  currentUserId,
  hideMyTripsButton,
  isTripUpdatesHidden,
  isUserPlanner,
  isUserLoggedIn,
  logoPath,
  showCoverPhoto,
  trip,
}: SiteHeaderProps) => {
  const tripCoverPhotos = trip?.coverPhotos
  const editTripInitiatedEvent = useEditTripInitiated();

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const coverPhotoHeight = isMobile ? CoverPhotoHeight.Mobile : CoverPhotoHeight.Desktop

  const classes = useStyles({
    tripCoverPhotos,
    showCoverPhoto,
    coverPhotoHeight,
  });

  return (
    <>
      <HeaderBar
        tripUpdatesNum={tripUpdatesNum}
        broadcastNum={broadcastNum}
        currentUserAvatarUrl={currentUserAvatarUrl}
        currentUserId={currentUserId}
        hideMyTripsButton={hideMyTripsButton}
        isTripUpdatesHidden={isTripUpdatesHidden}
        isUserLoggedIn={isUserLoggedIn}
        logoPath={logoPath}
        tripId={trip?.id}
      />

      <Box data-test-id="siteHeader"
        className={clsx(
          classes.headerBox,
          {[classes.withCoverPhotoOnly]: showCoverPhoto},
        )}
      >
        {trip && showCoverPhoto && (
          <div className={classes.headerCover}>
            <div
              data-test-id="headerCoverPhoto"
              className={clsx(
              classes.headerBackground,
              { [classes.headerWithBackgroundCoverPhoto]: showCoverPhoto },
              )}
            />
            <div className={clsx(
              classes.headerForeground,
              { [classes.headerWithCoverPhoto]: showCoverPhoto },
              )}
            />
            <Hidden smUp><TripName name={trip.name}></TripName></Hidden>
          </div>
        )}
        <Grid className={classes.buttonsOuterDiv}>
          {trip && showCoverPhoto && isUserPlanner && (
            <IconButton
              href={editTripPath(trip.id)}
              className={classes.editIcon}
              data-test-id="editTripButton"
              onClick={() => editTripInitiatedEvent()}
            >
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            </IconButton>
          )}
        </Grid>
      </Box>
      {trip && (
        <Hidden smDown>
          <TripName name={trip.name}/>
          <Divider/>
        </Hidden>
      )}
    </>
  );
};

export default SiteHeader;
