import React, { useState } from "react";
import {
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TRIP_TYPES } from '../../../../constants/tripTypes';

const useChipStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    lineHeight: '24px',
  },
  filled: {
    backgroundColor: '#EFF2F4',
  },
  outlined: {
    backgroundColor: '#F4F5FF',
    border: '2px solid #545DFC',
  },
}));

type TripTypesSelectionProps = {
  tripTypes?: string[],
};

const TripTypesSelection : React.FC<TripTypesSelectionProps> = ({ tripTypes = [] }) => {
  const chipClasses = useChipStyles();
  const [selectedTypes, setSelectedTypes] = useState(tripTypes);

  const handleSelectType = (type) => {
    setSelectedTypes(prevSelectedTypes => [...prevSelectedTypes, type]);
  };

  const handleDeselectType = (type) => {
    setSelectedTypes(prevSelectedTypes => prevSelectedTypes.filter(selectedType => selectedType !== type));
  };

  return (
    <Grid container spacing={2} direction="column">
      <input type="hidden" name="trip[trip_types][]" />
      {selectedTypes.map((type) => (
        <input type="hidden" name="trip[trip_types][]" value={type} key={`input-${type}`} />
      ))}
      <Grid item>
        <Typography variant="h3" mt={3}>
          What type of trip is this? (optional)
        </Typography>
      </Grid>
      <Grid item>
        {Object.entries(TRIP_TYPES).map(([key, value]) => (
          <Chip
            classes={chipClasses}
            key={key}
            label={value}
            onClick={() => (selectedTypes.includes(key) ? handleDeselectType(key) : handleSelectType(key))}
            variant={selectedTypes.includes(key) ? 'outlined' : 'filled'}
            sx={{ m: 0.5 }}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default TripTypesSelection;
