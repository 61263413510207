const serializeForm = (form: HTMLFormElement | undefined) => {
  let object: { [key: string]: any } = {};

  const formData = new FormData(form);

  for (let [key, value] of formData.entries()) {
    if (object[key]) {
      object[key] = [object[key]].flat().concat([value]);
    } else {
      object[key] = value;
    }
  }

  return object;
};

export default serializeForm;
