import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Button from "../../../../buttons/Button";

type FilterButtonProps = {
  active?: boolean,
  children: React.ReactNode,
  className?: string,
  onClick?: () => void,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  root: {
    border: [[`1px solid ${theme.palette.text.primary}`], '!important'],
    fontWeight: ['normal', '!important'],
  },
  active: {
    color: [[theme.palette.primary.main], '!important'],
    fontWeight: [600, '!important'],
  },
}));

const FilterButton = ({
  active,
  children,
  className,
  onClick,
  ...buttonProps
}: FilterButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      variant="tertiary"
      className={clsx(classes.root, className, { [classes.active]: active })}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default FilterButton;
