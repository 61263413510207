import { gql } from "@apollo/client";

export const TRIP_QUERY = gql`
  query fetchTrip($tripId: ID!) {
    node(id: $tripId) {
      ... on Trip {
        uuid
        name
        datesState
        destinationsState
        staysState
        chosenDateOption {
          startDate
          endDate
          endDateYear
        }
        chosenDestination {
          name
        }
        chosenStay {
          name
        }
      }
    }
  }
`;

