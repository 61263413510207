import { useReducer } from 'react';

export const OPEN_PROGRESS_DIALOG = 'openProgressDialog';
export const CLOSE_PROGRESS_DIALOG = 'closeProgressDialog';
type ActionType = {
  type: typeof OPEN_PROGRESS_DIALOG | typeof CLOSE_PROGRESS_DIALOG,
  voteCompleted?: boolean,
  displayDialog?: boolean,
};

const reducer = (state, action: ActionType) => {
  switch (action.type) {
    case OPEN_PROGRESS_DIALOG:
      return { displayDialog: true, voteCompleted: action.voteCompleted };
    case CLOSE_PROGRESS_DIALOG:
      return { ...state, displayDialog: false };
    default:
      throw new Error();
  };
};

export const useVotingProgressReducer = () => {
  const initialState = { displayDialog: false, voteCompleted: false }
  const [{ displayDialog, voteCompleted }, dispatch] = useReducer(reducer, initialState);

  return { displayDialog, voteCompleted, dispatch};
};
