export const DESTINATION_PAGE_FIELDS = `
  fragment DestinationPageFields on Destination {
    uuid
    currentUserRanking
    ranking
    googlePlaceId
    commentsCount
    googlePlaceUrl
    image
    isChosen
    name
    originalImage
    longitude
    latitude
    mostRecentComment {
      id
      body
      editedAt
      isAuthoredByCurrentUser
      timestamp
      uuid
      author {
        displayName
        avatarUrl
        id
      }
    }
    suggester {
      id
      displayName
      avatarUrl
    }
    trip {
      uuid
    }
  }
`
