import React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import AvatarGroup from '../../../../../../shared/AvatarGroup';
import { ActivitySentimentType } from '../../../../../../shared/TripObjectTypes';
import ExcludesPlusOnesMessage from "../../../../../../shared/ExcludesPlusOnesMessage";
import activitySentiments from './activitySentiments';

type VotesTableProps = {
  votes: ActivitySentimentType,
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: theme.spacing(1.8),
    fontWeight: 600,
  },
  votesRow: {
    marginBottom: theme.spacing(1),
  },
  votesCount: {
    fontSize: theme.spacing(2),
    marginRight: theme.spacing(0.6),
  },
  sentimentIcon: {
    width: 40,
    height: 40,
  },
}));

const VotesTable = ({
  votes,
}: VotesTableProps) => {
  const classes = useStyles();
  let showPlusOnesMessage = false;

  const renderVotesRow = (icon, label, sentiment) => {
    const sentimentVotes = votes[sentiment];
    const hasPlusOnes = sentimentVotes.filter(({ plusOneCount }) => plusOneCount > 0).length > 0;

    showPlusOnesMessage = showPlusOnesMessage || hasPlusOnes;

    return (
      <Grid container key={sentiment} spacing={1}>
        <Grid item xs={5} sx={{display: 'inline-flex'}} alignItems="center">
          <img data-test-id={icon} className={classes.sentimentIcon} src={`/assets/illustrations/${icon}.png`} />
          <Typography data-test-id={`${sentiment}-count`} className={clsx(classes.label, classes.votesCount)}>
            {`${label} (${sentimentVotes.length})`}{hasPlusOnes && '*'}
          </Typography>
        </Grid>
        <Grid item data-test-id={`${sentiment}-avatars`} >
          <AvatarGroup max={5} avatars={sentimentVotes} />
        </Grid>
      </Grid>
    );
  };

  return <>
    <Grid container direction="column" className={classes.votesRow} spacing={2}>
      {activitySentiments.map(({icon, label, value}, index) => (
        <Grid item>
          {renderVotesRow(icon, label, value)}
        </Grid>
      ))}
    </Grid>
    {showPlusOnesMessage && <ExcludesPlusOnesMessage />}
  </>;
};

export default VotesTable;
