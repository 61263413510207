import React from "react";
import { Typography, Grid, Stack } from "@mui/material";
import EditDeleteButtonGroup from "./EditDeleteButtonGroup";
import { useMutation } from "@apollo/client";
import { getOperationName } from "../../../../graphql/utilities";
import Errors from "../../../shared/Errors";
import useSegment from '../../../../services/useSegment';
import { DocumentNode } from "graphql";
import pluralize from "pluralize";
import { GuestType } from "../../../../types/GuestType";
import { PlusOneType } from "../../../../types/PlusOneTypes";

type AdditionalGuestActionsProps = {
  additionalTraveler: GuestType | PlusOneType,
  resourceName: string,
  displayName: string,
  mutation: DocumentNode,
  refetchOperation: DocumentNode
};

const AdditionalTravelerActions : React.FC<AdditionalGuestActionsProps> = ({ additionalTraveler, resourceName, displayName, mutation, refetchOperation}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [errors, setErrors] = React.useState<string[]>([]);
  const { trackSegment } = useSegment();
  const [ deleteAdditionalTravelerMutation ] = useMutation(mutation, {refetchQueries: [getOperationName(refetchOperation)]});

  const onDeleteClick = () => {
    deleteAdditionalTravelerMutation({
      variables: {
        additionalTravelerId: additionalTraveler.id,
      },
      onCompleted: (response) => {
        const result = Object.entries(response).map(entry => entry[1])[0] as any

        if (result.errors?.length > 0) {
          setErrors(errors);
        } else {
          setSnackbarMessage && setSnackbarMessage(`Your ${resourceName} has been deleted.`);
          trackSegment(`${displayName} Deleted`, { additionalTravelerId: additionalTraveler.uuid });
        }
      },
      onError: (error) => {
        setErrors([error.message]);
      },
    });
    setDialogOpen( false );
  };

  const openDialog = () => {
    trackSegment(`Delete ${displayName} Initiated`, { additionalTravelerId: additionalTraveler.uuid });
    setDialogOpen(true);
  };

  const deleteMessageBody = (
    <Typography>
      <span style={{fontWeight: 500}}>
        Heads up!
      </span> Once {pluralize(resourceName, 2)} are deleted, they will be removed from the trip’s headcount.
    </Typography>
  )

  return (
    <Stack>
      <EditDeleteButtonGroup
        disableDeleteButton={false}
        handleOpenDialog={openDialog}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        snackbarMessage={snackbarMessage}
        onConfirmClick={onDeleteClick}
        tooltipText={`You cannot remove a ${resourceName}`}
        deleteMessageBody={deleteMessageBody}
        resourceName={resourceName}
      />
      {errors && (
        <Grid item sx={{maxWidth: '200px'}}>
          <Errors errors={errors} />
        </Grid>
      )}
    </Stack>
  );
};

export default AdditionalTravelerActions;
