import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from "@mui/material";
import TextField, { BaseTextFieldProps } from "@mui/material/TextField";
import { DefaultTheme, makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import mergeRefs from "../../services/mergeRefs";
import getPlaceFromGooglePlacesApiResponse from "../../services/getPlaceFromGooglePlacesApiResponse";

export type Place = {
  id: string,
  image: string | undefined,
  latitude: string | number,
  longitude: string | number,
  name: string,
  url: string,
};

type GooglePlacesAutocompleteProps = BaseTextFieldProps & {
  onSelect: (value: Place | null) => void,
  defaultValue?: string,
  formattedAddress?: boolean,
  types?: string[],
  disabled?: boolean,
  onCloseIconClicked?: any;
};

const useStyles = makeStyles<DefaultTheme, { disabled: boolean }>((theme) => ({
  root: {
    position: 'relative',
  },
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,
    },
    '& input': {
      width: `calc(100% - ${theme.spacing(7.5)})`,
    },
  },
  closeButtonContainer: {
    position: 'absolute',
    right: theme.spacing(1),
    cursor: ({disabled}) => disabled ? 'default' : 'pointer',
    fontSize: 24,
    height: '100%',
  },
  closeButton: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

const GooglePlacesAutocomplete = React.forwardRef(({
  defaultValue,
  onSelect,
  types = [],
  disabled = false,
  formattedAddress = false,
  onCloseIconClicked,
  ...inputProps
}: GooglePlacesAutocompleteProps, externalInputRef) => {
  const [clearable, setClearable] = useState(!!defaultValue);

  const classes = useStyles({ disabled });
  const inputRef = useRef(null);
  let autocomplete: google.maps.places.Autocomplete;

  const unselectValue = () => {
    onCloseIconClicked && onCloseIconClicked()
    inputRef.current.value = '';
    onSelect(undefined);
    setClearable(false);
  };

  useEffect(() => {
    if (inputRef.current && defaultValue) {
      inputRef.current.value = defaultValue;
    }
  }, [inputRef.current, defaultValue])

  useEffect(() => {
    autocomplete = new google.maps.places.Autocomplete(
      inputRef.current,
      { types: types },
    );

    autocomplete.addListener('place_changed', onPlaceChange);

    inputRef.current.addEventListener('keydown', function(event) {
      const selectedItem = document.getElementsByClassName('pac-item-selected');
      if (event.key === 'Enter' && selectedItem.length !== 0) {
        event.preventDefault();
      }
    });
  }, []);

  const onPlaceChange = () => {
    const place = getPlaceFromGooglePlacesApiResponse(autocomplete.getPlace(), formattedAddress)
    onSelect(place);
    inputRef.current.value = place.name;
    setClearable(true);
  }

  return (
    <div
      id='googleplaceAutocompleteDiv'
      className={classes.root}
    >
      <TextField
        className={classes.input}
        defaultValue={defaultValue}
        disabled={disabled}
        id="googlePlacesSearchTextbox"
        inputRef={mergeRefs(externalInputRef, inputRef)}
        name="google_places"
        variant="outlined"
        {...inputProps}
      />
      {clearable && (
        <span className={classes.closeButtonContainer}>
          <IconButton
            className={classes.closeButton}
            data-test-id="clearButton"
            disabled={disabled}
            onClick={unselectValue}
          >
            <CloseIcon />
          </IconButton>
        </span>
      )}
    </div>
  );
});

GooglePlacesAutocomplete.displayName = 'GooglePlacesAutocomplete';

export default GooglePlacesAutocomplete;
