import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from '@mui/material';
import TimeStamp from "./TimeStamp";
import ButtonBase from "@mui/material/ButtonBase";
import { trackLink as trackAnalyticsEvent } from '../../services/analytics';
import UpdateIcon from '@mui/icons-material/Update';
import * as routes from '../../routes';

const {
  tripUpdatesPath,
} = routes;

type LastUpdateFilterLinkProps = {
  date: number,
  tripId: string,
  tripObjectType: string
};

const useStyles: () => any = makeStyles((theme): {} => ({
  updatedAtButton: {
    [theme.breakpoints.up("md")]: {
      padding: '14px',
    },
    height: '40px',
    '&:hover': {
      backgroundColor: '#F6F6F6',
    },
  },
  updatedAtButtonText: {
    color: '#5F6368',
    display: 'inline',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.spacing(2),
    lineHeight: '24px',
    fontWeight: 500,
    width: 'max-content',
    [theme.breakpoints.down("md")]: {
      paddingBottom: '8px',
    },
    '& svg': {
      verticalAlign: 'sub',
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

const LastUpdateFilterLink = ({
  date,
  tripId,
  tripObjectType,
}: LastUpdateFilterLinkProps) => {
  const classes = useStyles();
  const verticalFiltered = tripObjectType === 'TripDateOption' ? 'Dates' : tripObjectType;

  const refs = useCallback((element) => {
    if (element !== null) {
      trackAnalyticsEvent(
        element,
        'Updated At Selected',
        {
          verticalFiltered: verticalFiltered,
          tripId: tripId,
        },
      );
    }
  }, []);

  return (
    <ButtonBase
      data-test-id='lastUpdateFilterLink'
      href={tripUpdatesPath(tripId, { 'trip_event_filter': { 'trip_object_type': tripObjectType } })}
      ref={(element) => refs(element)}
      className={classes.updatedAtButton}
    >
      <Typography className={classes.updatedAtButtonText}>
        <UpdateIcon/><span className={classes.marginLeft}>Updated </span>
        <TimeStamp
          className={classes.updatedAtButtonText}
          date={date}
        />
      </Typography>
    </ButtonBase>
  )
};

export default LastUpdateFilterLink;
