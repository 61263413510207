import React, { useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";

type InfoWindowProps = {
  children?: React.ReactNode,
  onClickOut: () => void,
  position?: 'absolute' | 'relative',
};

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 2,
    left: -134,
    width: theme.spacing(35),
    backgroundColor: '#FFFFFF',
    boxShadow: '-2px -2px 7px rgba(1, 1, 1, 0.15)',
    zIndex: 100,
    borderRadius: '10px',
  },
}));

const InfoWindow = ({
  children,
  onClickOut,
  position = 'absolute',
}: InfoWindowProps) => {
  const classes = useStyles();
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClickOut = (event: MouseEvent | TouchEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      onClickOut();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOut);
    document.addEventListener('touchstart', handleClickOut);
    return () => {
      document.removeEventListener('mousedown', handleClickOut);
      document.removeEventListener('touchstart', handleClickOut);
    };
  }, []);

  return (
    <div className={classes.root} ref={wrapperRef} data-test-id="infoMarker" style={{position: position}}>
      {children}
    </div>
  );
};

export default InfoWindow;
