import React from "react";
import { Grid, ButtonBase } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import StayProperties from "../../../trips/microsite/HousingPage/Stay/StayProperties";
import StayRating from "../../../trips/microsite/HousingPage/Stay/StayRating";
import { ExternalListingProps } from "../../../../shared/TripObjectTypes";
import {useStayAffiliateClickEventRef} from "../../../../../services/segmentEvents/useStayAffiliateClickEvent";

const useStyles = makeStyles(() => ({
  properties: {
    fontSize: 14,
  },
  name: {
    lineHeight: '24px',
    color: 'black',
    fontSize: 16,
    fontWeight: 600,
    maxWidth: '100%',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  clickArea: {
    zIndex: 1,
  },
  ctaButton: {
    zIndex: 2,
    alignSelf: 'flex-start',
  },
}));

type ExternalListingContentProps = {
  stay: ExternalListingProps,
  nameLink: string,
  ctaButton: React.ReactNode,
  pricing: React.ReactNode,
};

const ExternalListingContent = ({
  stay,
  nameLink,
  pricing,
  ctaButton,
}: ExternalListingContentProps) => {
  const classes = useStyles();
  const isPublicSearch = window.location.pathname === '/search'

  const refs = useStayAffiliateClickEventRef({
    listingUrl: nameLink,
    targetClicked: 'card',
  });

  const ctaClicked = (event) => {
    if(!isPublicSearch) return event.preventDefault()
  };

  return (
    <ButtonBase
      data-test-id='stay-click-area'
      href={nameLink}
      className={classes.clickArea}
      ref={refs}
      target='_blank'
    >
      <Grid
        item
        container
        direction="column"
        spacing={1}
        alignItems='center'
      >
        <Grid container item justifyContent="space-between">
          <Grid item xs container className={classes.name}>
            {stay.name}
          </Grid>
          <StayRating stay={stay} />
        </Grid>
        <Grid
          item
          container
          wrap="nowrap"
          alignItems='center'
          spacing={1}
        >
          <Grid item container direction="column">
            <Grid item>
              {pricing}
            </Grid>
            <StayProperties className={classes.properties} stay={stay} />
          </Grid>
          <Grid item className={classes.ctaButton} onClick={ctaClicked}>
            {ctaButton}
          </Grid>
        </Grid>
      </Grid>
    </ButtonBase>
  )
};

export default ExternalListingContent;
