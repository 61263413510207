import React from "react";
import {
  ButtonBase,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CardButton from "../../../../buttons/CardButton";

type ItineraryEventProps = {
  showActionsMenu?: boolean,
  actionsMenu: React.ReactNode,
  itineraryDialog: React.ReactNode,
  children: React.ReactNode,
  sx?: object,
};

export const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #7D94A9',
    borderRadius: '4px',
    padding: theme.spacing(1),
  },
  imageWrapper: {
    width: 64,
    height: 64,
    marginRight: theme.spacing(1),
    '& > img': {
      width: 64,
      height: '100%',
      objectFit: 'cover',
    },
  },
  stayImageWrapper: {
    height: 32,
    marginRight: theme.spacing(1),
    marginTop: 3,
    background: '#EEEFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: 22,
      height: 15,
      margin: '0 21px',
      objectFit: 'cover',
    },
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  addTimeLink: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#4A6176',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.link.main,
    },
    '& > svg': {
      fontSize: 20,
      marginRight: theme.spacing(1),
      verticalAlign: 'middle',
      paddingBottom: 4,
    },
  },
  time: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#4A6176',
  },
  cardButton: {
    '&:focus': {
      border: 'none',
      outline:'none',
    },
    position: 'relative',
  },
  clickableArea: {
    position: "absolute",
    width: '98%',
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  clickableTime: {
    width: 'fit-content',
  },
  checkIcon: {
    color: '#1EBE95',
    fontSize: 24,
  },
  bookedImage: {
    width: 77,
    marginRight: theme.spacing(1),
    marginTop: 3,
    marginBottom: 2,
    background: '#EEEFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: 22,
      margin: '0 21px',
      objectFit: 'cover',
    },
  },
}));

type ClickAreaProps = {
  anchor?: string,
  linkHref?: string,
  linkTargetBlank?: boolean,
  onTripObjectClick?: () => any,
  isStayEvent?: boolean,
  sx?: object,
}

export const ClickArea = ({
  anchor,
  onTripObjectClick,
  sx,
}: ClickAreaProps) => {
  if (!onTripObjectClick) return null;

  const classes = useStyles();

  return (
    <ButtonBase
      className={classes.clickableArea}
      sx={sx}
      onClick={onTripObjectClick}
      id={anchor}
    />
  );
};

const ItineraryEvent = ({
  showActionsMenu = false,
  actionsMenu,
  itineraryDialog,
  children,
  sx,
}: ItineraryEventProps) => {
  const classes = useStyles();

  return (
    <CardButton className={classes.cardButton}>
      <Grid
        item
        container
        alignItems="center"
        flexWrap="nowrap"
        justifyContent="space-between"
        className={classes.root}
        sx={sx}
      >
        <Grid container data-test-id='itinerary-event' flexWrap="nowrap">
          {itineraryDialog}
          {children}
          {showActionsMenu && (
            <Grid item p={1} sx={{zIndex: 1, alignSelf: 'center'}}>
              {actionsMenu}
            </Grid>
          )}
        </Grid>
      </Grid>
    </CardButton>
  );
};

export default ItineraryEvent;
