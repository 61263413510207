import React from 'react';
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ErrorIcon from '@mui/icons-material/Error';

type ToDoAlertProps = {
  message: string,
};

const useStyles = makeStyles(theme => ({
  message: {
    fontSize: 16,
    lineHeight: '24px',
  },
  root: {
    backgroundColor: '#F4F5FF',
    border: '1px solid #545DFC',
    borderRadius: '4px',
    padding: theme.spacing(2),
  },
  errorIcon: {
    color: '#7D94A9',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  todo: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}));

const ToDoAlert = ({
  message,
}: ToDoAlertProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      wrap="nowrap"
      alignItems="center"
    >
      <Grid item>
        <ErrorIcon className={classes.errorIcon}/>
      </Grid>
      <Grid item xs>
        <Typography className={classes.message}>
          <Box component="span" className={classes.todo}>{'To Do > '}</Box>
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ToDoAlert;
