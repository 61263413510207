import React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import {
  Container,
  Grid,
  Typography,
} from "@mui/material";
import SiteFooter from "../../shared/SiteFooter";
import { hocRailsAction } from "../../Document";
import { searchPath } from "../../../routes";
import BackToButton from '../trips/microsite/shared/BackToButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import Button from "../../buttons/Button";
import TroupeLogoWithFavoritesHeader from '../../shared/TroupeLogoWithFavoritesHeader';
import NoSuggestionsYet from "../../shared/NoSuggestionsYet";
import ShareFavoriteDialog from "./ShareFavoriteDialog";
import { useQuery } from "@apollo/client";
import { GET_FAVORITES_LISTINGS } from '../../../graphql/queries/favorites';
import favoritesStorage from "../../../services/favoritesStorage";
import ExternalListingCard from '../stays_search/Listings/ExternalListingCard';
import dayjs from 'dayjs';

import SkeletonList from "../../shared/SkeletonPage/SkeletonList";
import * as Sentry from "@sentry/browser";
import SnackbarNotification from "../../shared/SnackbarNotification";
import { DefaultTheme } from '@mui/styles';
import {
  useShareFavoritesInitiatedEvent,
} from "../../../services/segmentEvents/favoriteEvents";

type FavoritesPageProps = {
  favoriteId: string,
};

const useStyles = makeStyles<DefaultTheme, {viewingShared: boolean}>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  pageContainer: {
    flex: 1,
    marginTop: ({viewingShared}) => theme.spacing(viewingShared ? 2 : 6),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  favoritesTitle: {
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  imageContainer: {
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(1, 0),
    width: 'min(90%, 450px)',
  },
  imageText: {
    fontWeight: 600,
    textAlign: "center",
  },
  viewButton: {
    height: '34px',
  },
  viewingSharedFavorites: {
    borderRadius: '8px',
    border: '1px solid #DDD',
    background: '#FDF6EE',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
  },
  viewingSharedFavoritesText: {
    textAlign: 'center',
  },
}));

const FavoritesPage : React.FC<FavoritesPageProps> = ({ favoriteId }) => {
  const classes = useStyles({viewingShared: !!favoriteId});
  const [dialogOpened, setDialogOpened] = React.useState(false);
  const { getFavoriteId } = favoritesStorage();
  const onShareClicked = () => {
    setDialogOpened(true);
    trackShareFavoritesInitiatedEvent();
  }

  const {data, loading, error} = useQuery(GET_FAVORITES_LISTINGS, {
    variables: { favoriteId: (favoriteId ? favoriteId : getFavoriteId()) },
    skip: !(favoriteId ? favoriteId : getFavoriteId()),
  });

  const favoritesData = data?.node || {};
  const favoritesListings = data?.node?.listings || [];
  const listingCount = favoritesListings.length
  const trackShareFavoritesInitiatedEvent = useShareFavoritesInitiatedEvent(listingCount);

  if (error) {
    Sentry.captureException(error)
    return <>
      <SnackbarNotification
        message='Sorry, something went wrong loading favorites. Please try again.'
        severity='error'
        open={true}
      />
      <SkeletonList itemLength={1} />
    </>
  }

  const renderFavoriteDates = (listing) => (
    listing.arrival && listing.departure &&
      <Typography sx={{fontSize: '14px'}}>
        {dayjs(listing.arrival, 'YYYY-MM-DD').format('MMM D')} - {dayjs(listing.departure, 'YYYY-MM-DD').format('MMM D')}
      </Typography>
  )

  const renderFavoritesListings = (listings) => (
    <Grid container item spacing={2} mt={2}>
      {listings.map((listing) => {
        return(
          <Grid item xs={12} sm={6} md={4} lg={3} key={listing.id}>
            <ExternalListingCard
              stay={listing.externalListing}
              favoriteListingId={listing.id}
              renderCtaButton={() => <Button
                  onClick={() => window.open(listing.externalListing.publicUrl, '_blank')}
                  className={classes.viewButton}
                >
                  View
                </Button>
              }
              footer={renderFavoriteDates(listing)}
              displayToggleFavoriteButton={!favoriteId}
            />
          </Grid>
        )
      })}
    </Grid>
  );

  const renderViewingSharedFavorites = () => (
    <Grid item container className={classes.viewingSharedFavorites}>
      <Typography className={classes.viewingSharedFavoritesText} data-test-id="viewing-shared-text">
        👀 <span style={{fontWeight: 600}}>You’re viewing a list someone shared with you.</span> Save this link to access this list again.
      </Typography>
    </Grid>
  )

  return(
    <div className={classes.root}>
      <TroupeLogoWithFavoritesHeader withMobilePadding viewingShared={!!favoriteId} />
      <Container className={classes.pageContainer}>
        { !favoriteId && (
          <BackToButton
            path={searchPath()}
            text="Back to Search"
          />
        )}
        <Grid container mt={5} mb={4} sx={{width: '100%'}} alignItems='center' justifyContent='center'>
          { loading ? (
            <Grid item flexWrap='nowrap' direction='row' container>
              <SkeletonList itemLength={2} />
            </Grid>
            ) : (
            <>
              <Grid item container alignItems='center' justifyContent='space-between'>
                { favoriteId && renderViewingSharedFavorites()}
                <Grid item>
                  <Typography variant="h2" className={classes.favoritesTitle}>Favorites</Typography>
                </Grid>
                <Grid item>
                  <Button
                    data-test-id='share-favorites'
                    onClick={onShareClicked}
                    variant='tertiary'
                    disabled={!favoritesData.link}
                  >
                    <span>Share</span>
                    <IosShareIcon />
                  </Button>
                  <ShareFavoriteDialog
                    listingCount={listingCount}
                    onClose={() => setDialogOpened(false)}
                    open={dialogOpened}
                    shareUrl={favoritesData.link}
                  />
                </Grid>
              </Grid>
              {
                favoritesListings.length > 0 ? (
                  renderFavoritesListings(favoritesListings)
                ) : (
                  <Grid container item className={classes.imageContainer}>
                    <NoSuggestionsYet illustration='favorites_fallback' />
                    <Typography className={classes.imageText}>
                      There’s nothing here!  Add and share your favorite stays without creating an account.
                    </Typography>
                  </Grid>
                )
              }
            </>
          )
        }
        </Grid>
      </Container>
      <SiteFooter />
    </div>
)};

export default hocRailsAction(FavoritesPage);
