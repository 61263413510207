import React, { useState, useEffect } from "react";
import { ButtonBase, Grid } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import IconButton from "../../../buttons/IconButton";
import { ChatBubbleOutline } from "@mui/icons-material";

export type Sentiments = 'yes' | 'no' | 'maybe' | 'missing';

type VotingDatesButtons = {
  currentUserVote: Sentiments,
  dateOptionId: string,
  isCommentDisabled: boolean,
  onCommentButtonClick: () => void,
  onVoteChange: (votePresent?: boolean) => void,
  updateSentimentCount: (oldSentiment: Sentiments, newSentiment: Sentiments) => void,
};

const useStyles = makeStyles(theme => ({
  commentsButton: {
    border: `1px solid ${theme.palette.general.gray}`,
    borderRadius: 30,
    height: 28.7,
    width: 28.8,
    boxShadow: 'none',
    '&:hover':{
      boxShadow: 'none',
    },
    marginTop: 0.5,
  },
  commentsIcon: {
    height: 17.5,
    width: 17.5,
    color: theme.palette.general.gray,
  },
  disabled: {
    opacity: 0.35,
  },
  illustrationsContainer: {
    minWidth: 150,
    paddingTop: 5,
  },
  img: {
    width: 28,
    verticalAlign: 'super',
  },
}));

const VotingDatesButtons = ({
  currentUserVote,
  dateOptionId,
  isCommentDisabled,
  onCommentButtonClick,
  onVoteChange,
  updateSentimentCount,
}: VotingDatesButtons) => {
  const classes = useStyles();
  const [voteValue, setVoteValue] = useState(currentUserVote || 'missing');

  const sentimentButton = (sentiment) => {
    const capitalizedSentiment = sentiment.charAt(0).toUpperCase() + sentiment.slice(1);

    return (
      <Grid item>
        <ButtonBase
          data-test-id={`${sentiment}-voting-button`}
          onClick={() => setSentiment(sentiment)}
        >
          <img
            alt={sentiment}
            aria-label={`${sentiment}-voting-img`}
            className={clsx(
              classes.img,
              {[classes.disabled]: voteValue !== sentiment},
            )}
            data-test-id={`${sentiment}-voting-img`}
            src={`/assets/illustrations/${capitalizedSentiment}.svg`}
          />
        </ButtonBase>
      </Grid>
    );
  };

  const setSentiment = (value) => {
    if (voteValue === value) {
      updateSentimentCount(voteValue, 'missing');
      setVoteValue('missing');
    } else {
      updateSentimentCount(voteValue, value);
      setVoteValue(value);
    }
  };

  const commentsDisabled = (!!!voteValue && isCommentDisabled) || (isCommentDisabled);

  useEffect(() => {
    onVoteChange(voteValue === 'missing')
  }, [voteValue]);

  return (
    <Grid
      container
      justifyContent="space-evenly"
      alignItems="center"
      className={classes.illustrationsContainer}
      key={dateOptionId}
    >
      <input
        type="hidden"
        value={voteValue}
        name={`sentiments[${dateOptionId}]`}
      />
      {sentimentButton('yes')}
      {sentimentButton('maybe')}
      {sentimentButton('no')}
      <IconButton
        className={clsx(
          classes.commentsButton,
          {[classes.disabled]: commentsDisabled},
        )}
        data-test-id="comment-icon"
        onClick={() => onCommentButtonClick()}
        disabled={commentsDisabled}
      >
        <ChatBubbleOutline
          className={classes.commentsIcon} />
      </IconButton>
    </Grid>
  );
};

export default VotingDatesButtons;
