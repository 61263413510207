import React, { useContext, useState } from "react";
import axios from 'axios';
import {
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "../../buttons/Button";
import { FormsContext } from "../../Document";
import InputWithCharacterCounter from "../InputWithCharacterCounter";

type CommentEditFormProps = {
  commentBody: string,
  commentPath: string,
  onCancelClick: (value: any) => void,
  onCommentChange: (value: any) => void,
};

const useStyles = makeStyles((theme) => ({
  formButton: {
    height: '36px !important',
  },
  textField: {
    '& textarea': {
      lineHeight: '1.4em',
    },
  },
}));

const CommentEditForm = ({
  commentBody,
  commentPath,
  onCancelClick,
  onCommentChange,
}: CommentEditFormProps) => {
  const classes = useStyles();
  const [body, setBody] = useState(commentBody);
  const { csrfToken } = useContext(FormsContext);

  const handleSave = (event) => {
    axios.patch(
      commentPath,
      {
        authenticity_token: csrfToken,
        comment: { body: body },
      },
    ).then((response) => {
      onCommentChange(response.data.comment.body);
    });
  };

  return <>
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <InputWithCharacterCounter
          characterLimit={5000}
          className={classes.textField}
          id="comment_edit_form_body"
          fullWidth
          multiline
          defaultValue={body}
          onChange={(event) => setBody(event.target.value)}
          withBorder
        />
      </Grid>
      <Grid item>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <Button
              className={classes.formButton}
              variant="secondary"
              onClick={onCancelClick}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.formButton}
              disabled={body === commentBody}
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>;
};

export default CommentEditForm;
