import React, { useCallback, useContext } from "react";
import {
  ButtonBase,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ItineraryDateType } from "../../../../shared/TripObjectTypes";
import CardButton from "../../../../buttons/CardButton";
import { tripItineraryDatePath } from '../../../../../routes';
import AddDescriptionLink from "./AddDescriptionLink";
import { trackLink as trackLinkAnalyticsEvent } from "../../../../../services/analytics";
import { SegmentContext } from "../../../../Document";

type ItineraryDateProps = {
  itineraryDate: ItineraryDateType,
};

const useStyles = makeStyles(theme => ({
  itinerary: {
    borderRadius: theme.spacing(.4),
    position: 'relative',
    pointerEvents: 'none',
    zIndex: 1,
  },
  dayBlock: {
    minWidth: '64px',
    width: '64px',
    height: '56px',
    background: '#EEEFFF',
    borderRadius: '4px',
    fontWeight: theme.typography.h3.fontWeight,
    fontSize: theme.typography.h3.fontSize,
    marginRight: '12px',
  },
  description: {
    fontSize: '16px',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  descriptionWrapper: {
    width: '100%',
  },
  itineraryDate: {
    fontSize: '18px',
    fontWeight: theme.typography.h2.fontWeight,
    lineHeight: '24px',
    textAlign: 'left',
  },
  chevronRight: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.primary.main,
    '& > svg': {
      fontSize: '2rem',
    },
  },
  chevronRightWrapper: {
    maxWidth: '16px',
  },
  dateBox: {
    '&:hover': {
      cursor: 'pointer',
    },
    width: '100%',
  },
  root: {
    position: 'relative',
  }
}));

const ItineraryDate = ({
  itineraryDate,
}: ItineraryDateProps) => {
  const { isPlanner, tripId, currentUserId } = useContext(SegmentContext);
  const classes = useStyles();

  const refs = useCallback((element) => {
    if (element !== null) {
      trackLinkAnalyticsEvent(
        element,
        'Itinerary Day Permapage loaded',
        {
          method: 'Date Card',
          planner: isPlanner,
          tripId: tripId,
          userId: currentUserId,
        },
      );
    }
  }, []);

  return <>
    <CardButton className={classes.root} testId='itinerary-date-card'>
      <Link
        data-test-id='itinerary-date-card-link'
        href={tripItineraryDatePath(itineraryDate.tripId, itineraryDate.id)}
        ref={(el) => refs(el)}
        sx={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
      />
      <Grid
        container
        item
        data-test-id='itinerary-date'
        className={classes.itinerary}
        sx={{ padding: 2 }}
        flexWrap="nowrap"
      >
        <Grid item container direction="column" alignItems='center' justifyContent='center' className={classes.dayBlock}>
          <Grid item>Day</Grid>
          <Grid item>{itineraryDate.dayNumber}</Grid>
        </Grid>
        <Grid item container direction="column" spacing={1} sx={{ width: "calc(100% - 88px)" }}>
          <Grid item>
            <Typography className={classes.itineraryDate}>{itineraryDate.formattedDate}</Typography>
          </Grid>
          <Grid item className={classes.descriptionWrapper}>
            {itineraryDate.description ? (
              <Typography className={classes.description}>{itineraryDate.description}</Typography>
            ) : (
              <AddDescriptionLink itineraryDate={itineraryDate} />
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.chevronRightWrapper}>
          <span className={classes.chevronRight}>
            <ChevronRightIcon />
          </span>
        </Grid>
      </Grid>
    </CardButton>
  </>;
};

export default ItineraryDate;
