import React, { useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CustomFiltersAppliedType, DateOptionProps } from "../StaysSearch";
import SearchCriteria from "./SearchCriteria";
import FiltersButton from "./FiltersButton";
import GooglePlacesAutocomplete, { Place } from "../../../shared/GooglePlacesAutocomplete";
import RailsForm from "../../../shared/RailsForm";
import useStateWithPromise from "../../../../services/useStateWithPromise";
import { makeStyles, DefaultTheme, useTheme } from "@mui/styles";
import { isCurrentlyInPublicSearch } from "../../../../services/windowLocation";
import InventoryRadioGroup from "./Filters/InventoryRadioGroup";
import { AmenitiesFiltersType } from "../../../../constants/amenitiesFilters";
import { PropertyType } from "./Filters/SortAndFilter/PropertyTypes";
import AiSearchPromptSection from "./AiSearchPromptSection";
import AiSearchCriteriaDialog from "./AiSearchCriteriaDialog";
import { createFormDataForFiltering } from "../../../../services/createFormDataForFiltering";

export type aiSearchType = 'aiPublicSearch' | 'aiTripSearch';

export type CurrentAppliedFilters = {
  amenities?: AmenitiesFiltersType[],
  arrival?: string,
  bathroomCount?: string,
  bedroomCount?: string,
  customDatesFilterApplied?: boolean,
  customGuestsFilterApplied?: boolean,
  departure?: string,
  freeCancellation?: boolean,
  hotelRoomCount?: string,
  instantBook?: boolean,
  inventory?: string,
  latitude?: string,
  locationName?: string,
  longitude?: string,
  maxPrice?: string,
  minGuests?: string,
  minPrice?: string,
  prompt?: string,
  propertyTypes?: PropertyType[],
  rating?: string,
};

type FiltersProps = {
  currentAppliedFilters: CurrentAppliedFilters,
  customFiltersApplied: CustomFiltersAppliedType,
  dateOptions: DateOptionProps[],
  filterFormPath: string,
  hasSearched: boolean,
  isLoading: boolean,
  onFilter: (filterParams: FormData) => void,
  searchPath: string,
  showFiltersButton?: boolean,
  aiSearch?: boolean,
  prompt?: string,
  aiSearchType?: aiSearchType,
  isDialogOpen: boolean,
  onDialogClose: () => void
};

const useStyles = makeStyles<DefaultTheme, { inPublicSearch: boolean }>((theme) => ({
  FiltersComponent: {
    justifyContent: 'center',
    maxWidth: '800px',
    width: '95%',
    margin: '0 auto',
  },
  logo: {
    height: '14px',
    verticalAlign: 'unset',
    width: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  googleAutoComplete: {
    flex: ({ inPublicSearch }) => inPublicSearch ? '1 2 278px' : '1 2 800px',
    '& .MuiInputBase-root': {
      height: ({ inPublicSearch }) => inPublicSearch ? '40px' : 'auto',
      borderRadius: '5px',
    },
  },
  searchCriteria:{
    flex: 1,
    minWidth: '222px',
    '& .MuiButtonBase-root': {
      height: ({ inPublicSearch }) => inPublicSearch ? '40px' : 'auto',
    },
  },
  filtersButton: {
    marginLeft: theme.spacing(1),
    '& .MuiButtonBase-root': {
      maxHeight: ({ inPublicSearch }) => inPublicSearch ? '40px' : 'auto',
      maxWidth: ({ inPublicSearch }) => inPublicSearch && '90px',
    },
  },
  poweredByText: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down(701)]: {
      paddingTop: theme.spacing(1),
    },
  },
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  inventoryWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(701)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > :last-child': {
        alignSelf: 'flex-end',
      },
    },
  },
}))

export const Filters = ({
  currentAppliedFilters = {},
  dateOptions,
  filterFormPath,
  hasSearched,
  onFilter,
  showFiltersButton = false,
  isLoading = false,
  aiSearch = false,
  prompt,
  aiSearchType,
  isDialogOpen = false,
  onDialogClose,
} : FiltersProps) => {
  const inPublicSearch = isCurrentlyInPublicSearch();
  const classes = useStyles({inPublicSearch});
  const formRef = useRef(null);
  const [filters, setFilters] = useStateWithPromise({...currentAppliedFilters});

  useEffect(() => {
    setFilters(prevState => ({ ...prevState, ...currentAppliedFilters, amenities: currentAppliedFilters?.amenities?.filter(a => a) }));
  }, [currentAppliedFilters]);

  const handleLocationChange = (value: Place) => {
    if (value) {
      const { latitude, longitude, name: locationName } = value;
      onFilterChange({ latitude, longitude, locationName, searchMethod: 'searchEntered'});
    }
  };

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const onFilterChange = (value = {}) => {
    setFilters(prevState => ({ ...prevState, ...value })).then((values) => {
      const formData = createFormDataForFiltering(values, new FormData(formRef.current))

      onFilter(formData);
    });
  };

  const renderPoweredBy = () => {
    const white = inPublicSearch && hasSearched;

    return (
      <div className={classes.poweredByText}>
        <Typography style={{ fontSize: '14px', color: white ? 'white' : 'rgb(151, 151, 151)' }}>
          Powered by
          <img className={classes.logo} style={{ position: 'relative', top: '2px', paddingLeft: '4px' }} src={white ? '/assets/vrbo_logo_white.svg' : '/assets/vrbo_logo_blue.svg'} alt='Vrbo logo' />
          <img className={classes.logo} style={{ position: 'relative', top: '2px', paddingLeft: '8px' }}src={white ? '/assets/expedia_logo_white.svg' : '/assets/expedia_logo.svg'} alt='Expedia logo' />
          {aiSearch && ' & ChatGPT API'}
        </Typography>
      </div>
    );
  };

  const handlePromptSubmit = (prompt: string) => {
    onFilterChange({ prompt, searchMethod: 'aiSearch' });
  }

  const renderGoogleAutocompleteSearch = () => (
    <div className={classes.googleAutoComplete}>
      <GooglePlacesAutocomplete
        autoFocus
        defaultValue={filters.locationName}
        disabled={isLoading}
        types={["geocode"]}
        formattedAddress
        fullWidth
        placeholder="Search stays"
        onSelect={(value) => handleLocationChange(value)}
      />
    </div>
  );

  const renderGoogleSearch = () => (
    <div className={classes.container} data-test-id='regular-stays-search-section'>
      <div className={classes.filters}>
        {renderGoogleAutocompleteSearch()}
        {(!aiSearch || showFiltersButton || hasSearched) && (
          <div className={classes.wrapper}>
            <div className={classes.searchCriteria}>
              <SearchCriteria
                arrival={filters.arrival}
                bedroomCount={filters.bedroomCount}
                departure={filters.departure}
                hotelRoomCount={filters.hotelRoomCount}
                minGuests={filters.minGuests}
                inventory={filters.inventory}
                dateOptions={dateOptions}
                onChange={onFilterChange}
                aiSearchType={aiSearchType}
              />
            </div>
            {showFiltersButton && (
              <div className={classes.filtersButton}>
                <FiltersButton
                  onChange={onFilterChange}
                  filters={filters}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Grid container className={classes.inventoryWrapper}>
        <Grid item>
          {(!aiSearch || showFiltersButton || hasSearched) && (
            <InventoryRadioGroup
              value={currentAppliedFilters.inventory}
              hasSearched={hasSearched}
              onChange={onFilterChange}
              isLoading={isLoading}
            />
          )}
        </Grid>
        <Grid item>
          {renderPoweredBy()}
        </Grid>
      </Grid>
    </div>
  )

  return <div className={classes.FiltersComponent}>
    <RailsForm
      action={filterFormPath}
      method="GET"
      ref={formRef}
      submitDisabled
      withChangeDetection={false}
    >
      <input type="hidden" value={filters.searchMethod} name="search_method" />
      {
        aiSearch ? (
          <AiSearchPromptSection
            aiTripSearch={aiSearchType === 'aiTripSearch'}
            handlePromptSubmit={handlePromptSubmit}
            hasSearched={hasSearched}
            isLoading={isLoading}
            isMobile={isMobile}
            prompt={prompt}
          />
        ) : renderGoogleSearch()
      }
      {aiSearch && <AiSearchCriteriaDialog
        defaultArrival={currentAppliedFilters.arrival || null}
        defaultLocationName={currentAppliedFilters.locationName}
        defaultDeparture={currentAppliedFilters.departure || null}
        isDialogOpen={isDialogOpen}
        defaultLatitude={currentAppliedFilters.latitude || null}
        defaultLongitude={currentAppliedFilters.longitude || null}
        defaultMinGuests={Number(currentAppliedFilters.minGuests)}
        onChange={onFilterChange}
        onClose={onDialogClose}
        aiSearchType={aiSearchType}
      />}
    </RailsForm>
  </div>;
};

export default Filters;
