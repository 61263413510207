import React, { useContext } from "react";
import AsyncSentimentButtons from "../../shared/AsyncSentimentButtons";
import SentimentButtons from "../../shared/SentimentButtons";
import rankLabels from "../../../../../shared/rankLabels";
import { StayType } from "../../../../../shared/TripObjectTypes";
import { TripSiteContext } from "../../../../../Document";
import { tripStayRankingsPath } from '../../../../../../routes';

type VotingSectionProps = {
  openVotingProgress: (voteCompleted: boolean) => void,
  stay: StayType,
  page?: 'index' | 'permapage' | null,
};

const VotingSection: React.FC<VotingSectionProps> = ({
  openVotingProgress,
  stay,
  page,
}) => {
  const { currentUserId, tripId, tripGid } = useContext(TripSiteContext);
  const stayId = stay.uuid ?? stay.id;
  const stayGid = stay.gid ?? stay.id;

  return (
    <>
      {currentUserId ? (
        <AsyncSentimentButtons
          currentUserSentiment={stay.currentUserRanking}
          disabled={stay.isDisabled}
          page={page}
          sentiments={rankLabels}
          tripObjectGid={stayGid}
          tripGid={tripGid}
          testId="tripStayButtons"
          openVotingProgress={openVotingProgress}
          tripObjectType="stay"
        />
      ) : (
        <SentimentButtons
          currentUserSentiment={stay.currentUserRanking}
          disabled={stay.isDisabled}
          sentiments={rankLabels}
          sentimentParamName="position"
          sentimentSettingPath={tripStayRankingsPath(tripId, stayId)}
          testId="tripStayButtons"
        />
      )}
    </>
  );
};

export default VotingSection;
