import React, { useCallback, useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import EmailInviteForm from "./EmailInviteForm";
import ShareLinkSection from "./InviteModal/ShareLinkSection";
import ManageInvitesLink from "./InviteModal/ManageInvitesLink";
import Dialog from "./Dialog";
import Button from "../buttons/Button";
import { trackLink as trackAnalyticsEvent } from '../../services/analytics';
import { SegmentContext } from "../Document";

type InviteModalProps = {
  inviterId: string,
  onClose: () => void,
  tripId: string,
  tripGid: string,
  tripName: string,
  shareLinkSource: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  subtitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 400,
    lineHeight: '24px',
    fontSize: 16,
    color: theme.palette.text.primary,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      minWidth: 480,
    },
  },
  titleWrapper: {
    textAlign: 'center',
    flexDirection: 'row',
  },
  imageWrapper: {
    textAlign: 'center',
    flexDirection: 'row',
    paddingBottom: theme.spacing(1),
  },
  imageWrapperAlt: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    height: '114px',
    textAlign: 'center',
  },
}));

const InviteModal = ({
  inviterId,
  onClose,
  tripId,
  tripGid,
  tripName,
  shareLinkSource,
}: InviteModalProps) => {
  const classes = useStyles();
  const [fullContent, setFullContent] = useState(true);
  const { currentUserId, isPlanner } = useContext(SegmentContext);

  const addEmailsRef = useCallback((element) => {
    if (element !== null) {
      trackAnalyticsEvent(
        element,
        'Add Emails Clicked',
        {
          tripId,
          userId: currentUserId,
          planner: isPlanner,
        },
      );
    }
  }, []);

  const renderFullContent = () => (
    <>
      <section className={classes.titleWrapper}>
        <Typography className={classes.title}>
          Share this trip
        </Typography>
        <Typography className={classes.subtitle}>
          Invite friends to suggest, comment, and vote on trip details.
        </Typography>
      </section>
      <section className={classes.imageWrapper}>
        <img
          className={classes.imageWrapperAlt}
          alt=""
          src={`/assets/illustrations/paper_airplane.svg`}
          data-test-id='invite-modal-image'
        />
      </section>
      <Typography variant='h3' pb={1}>
        Invite by text message or social
      </Typography>
      <ShareLinkSection
        inviterId={inviterId}
        tripName={tripName}
        tripId={tripId}
        source={shareLinkSource}
      />
      <Typography variant='h3' pt={2} pb={1}>
        Invite by email
      </Typography>
      <Button
        data-test-id="add-emails-button"
        fullWidth
        variant="secondary"
        onClick={() => {setFullContent(false)}}
        ref={(el) => addEmailsRef(el)}
      >
        Add emails
      </Button>
      <ManageInvitesLink tripId={tripId} />
    </>
  );

  const renderEmailInviteContent = () => (
    <>
      <Box className={classes.titleWrapper} mb={1}>
        <Typography className={classes.title}>
          Share this trip
        </Typography>
      </Box>
      <EmailInviteForm
        tripGid={tripGid}
        tripId={tripId}
        onClose={onClose}
      />
    </>
  );

  return (
    <Dialog
      data-test-id="inviteModal"
      isOpen
      message={fullContent ? renderFullContent() : renderEmailInviteContent()}
      onClose={() => onClose()}
    />
  );
};

export default InviteModal;
