import React from "react";
import { Grid } from "@mui/material";
import { Theme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { HEADER_BAR_HEIGHT } from "../../../../../shared/SiteHeader";
import { NAV_BAR_HEIGHT } from "../../../../../trip-page/NavigationBar";
import ActivitiesMapView from '../../shared/ActivitiesMap/ActivitiesMapView';
import { ActivityType } from "../../../../../shared/TripObjectTypes";
import MobileListButton from "../../../../../shared/MapView/MobileListButton";

type FullscreenMapViewProps = {
  activities: ActivityType[],
  onListButtonClick: () => void,
};

type fullScreenMapTopProps = {
  fullScreenMapTop: number,
};

const useStyles = makeStyles<Theme, fullScreenMapTopProps>((theme) => ({
  fullscreenMap: {
    position: 'absolute',
    width: '100%',
    left: 0,
    height: ({ fullScreenMapTop }) => `calc(100% - ${fullScreenMapTop}px)`,
    '@supports (height: env(safe-area-inset-top))': {
      height: ({ fullScreenMapTop }) => `calc(100% - env(safe-area-inset-top) - ${fullScreenMapTop}px)`,
    },
  },
}));

const FullscreenMapView = ({
  activities,
  onListButtonClick,
}: FullscreenMapViewProps) => {
  const fullScreenMapTop = HEADER_BAR_HEIGHT + NAV_BAR_HEIGHT;
  const classes = useStyles({ fullScreenMapTop });

  return (
    <Grid item className={classes.fullscreenMap} data-test-id="fullscreenMap">
      <ActivitiesMapView
        activities={activities}
      />
      <MobileListButton onClick={onListButtonClick} source='activities' />
    </Grid>
  );
};

export default FullscreenMapView;
