import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ImageUploader from '../../../shared/ImageUploader';
import RichTextEditor from '../../../shared/RichTextEditor';
import InputWithCharacterCounter from '../../../shared/InputWithCharacterCounter';
import TripTypesSelection from './TripTypesSelection';

type ThirdStepTripFormProps = {
  optionalData: any,
};

const useStyles = makeStyles((theme) => ({
  headLine: {
    marginTop: theme.spacing(3),
  },
  title: {
    textAlign: 'center',
  },
  subTitle: {
    textAlign: 'center',
  },
  blurbWrapper: {
    marginTop: theme.spacing(2),
  },
  uploaderContainer: {
    marginTop: 12,
  },
  travelerCount: {
    width: '50%',
  },
  image: {
    width: '100%',
    padding: theme.spacing(2),
    maxWidth: '100%',
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
      width: '360px',
    },
    [theme.breakpoints.up("md")]: {
      height: '200px',
    },
  },
  tripName: {
    lineHeight: '24px',
    marginBottom: theme.spacing(1),
  },
  tripNameInput : {
    borderWidth: '1px',
  },
}));

type EstimatedTravelerCountProps = {
  estimatedTraveler?: number | null,
}

export const EstimatedTravelerCount : React.FC<EstimatedTravelerCountProps> = ({
  estimatedTraveler,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h3" mt={3}>
          Estimated travelers (optional)
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Not including you, how many travelers do you think will join this trip?
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          id={"estimated_traveler"}
          className={classes.travelerCount}
          placeholder='Dont know? Skip this question'
          type='number'
          inputProps={{ min: 1, max: 99 }}
          name='trip[estimated_travelers]'
          defaultValue={estimatedTraveler}
        />
      </Grid>
    </Grid>
  )
};

export const UpperImage = () => {
  const classes = useStyles();
  return(
    <img src="/assets/illustrations/create_trip_third_step.svg" alt="road" className={classes.image} />
  )
};

const ThirdStepTripForm : React.FC<ThirdStepTripFormProps> = ({optionalData}) => {
  const [description, setDescription] = useState('');
  const classes = useStyles();
  const defaultTripName = 'Untitled trip';
  const setDefaultName = (field) => {
    if (field.value.trim() === '') {
      field.value = defaultTripName;
    }
  };
  const CHARACTER_LIMIT = 100;

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h3" className={classes.tripName}>
            Trip name
          </Typography>
          <InputWithCharacterCounter
            name="trip[name]"
            focused
            fullWidth
            defaultValue={defaultTripName}
            onTextFieldBlur={setDefaultName}
            defaultBlurValue={defaultTripName}
            characterLimit={CHARACTER_LIMIT}
            noLabel
            withBorder
            className={classes.tripNameInput}
          />
        </Grid>
      </Grid>
      <div data-test-id="tripDescription" className={classes.blurbWrapper}>
        <RichTextEditor
          onChange={(data) => setDescription(data)}
          placeholder="What’s the plan for this trip?"
        />
        <input type="hidden" name="trip[blurb]" value={description} />
      </div>
      <TripTypesSelection />
      <EstimatedTravelerCount />
      <Grid container spacing={2} direction="column">
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h3" className={classes.headLine}>
              Add cover photo
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              Cover photos help your group get excited about where you’re headed.
            </Typography>
          </Grid>
          <Grid item className={classes.uploaderContainer}>
            <ImageUploader
              defaultImage='https://res.cloudinary.com/troupe/image/upload/c_fill,h_416,q_auto:good,w_1120/v1612306537/manual-trips/default/cover_agiagz.jpg'
              forcedAspectRatio={2.2}
              inputName="trip[image_url]"
              isFullSize
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ThirdStepTripForm;
