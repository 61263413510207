import React from "react";
import { Badge } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  outerDot: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(3),
    },
    '& .MuiBadge-dot': {
      backgroundColor: 'rgba(84, 93, 252, 0.2)',
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
  },
  innerDot: {
    '& .MuiBadge-dot': {
      backgroundColor: theme.palette.primary.main,
      width: 10,
      height: 10,
    },
  },
}));

const NewTripUpdatesDot = () => {
  const classes = useStyles();

  return (
    <Badge
      variant="dot"
      className={classes.outerDot}
      data-test-id="unviewedEventDot"
    >
      <Badge
        variant="dot"
        className={classes.innerDot}
      />
    </Badge>
  )
};

export default NewTripUpdatesDot;
