import React, { useReducer, useRef } from "react";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DestinationPreview from "../destinations/DestinationPreview";
import { hocRailsAction } from "../../Document";
import Errors from "../../shared/Errors";
import ModalForm from "../../shared/ModalForm";
import { Place } from "../../shared/GooglePlacesAutocomplete";
import { FormContext } from "../../shared/RailsForm";
import DestinationField from "../destinations/DestinationField";
import useStateWithPromise from "../../../services/useStateWithPromise";
import { tripDestinationsPath } from '../../../routes';
import { DestinationLocationType } from '../../../types/DestinationTypes';

type NewDestinationPageProps = {
  errors: string[],
  tripId: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  emptyStateImage: {
    maxWidth: theme.spacing(50),
    width: '100%',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  noImage: {
    maxWidth: theme.spacing(50),
    width: '100%',
    paddingTop: theme.spacing(2),
  },
}));

const NewDestinationPage = ({
  errors,
  tripId,
}: NewDestinationPageProps) => {
  const classes = useStyles();
  const [location, setLocation] = useStateWithPromise({} as DestinationLocationType);
  const inputRef = useRef(null);

  const initialState = {
    showFetchErrorMessage: false,
    showEmptyState: true,
  };

  const reducer = (state, action) => {
    switch(action.type) {
      case 'showFetchErrorMessage':
        return { showFetchErrorMessage: true, showEmptyState: false }
      case 'hideAll':
        return { showFetchErrorMessage: false, showEmptyState: false }
      case 'resetForm':
        setLocation({}).then(() => inputRef.current.focus());
        return initialState;
      default:
        throw new Error();
    }
  };

  const [{ showFetchErrorMessage, showEmptyState }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const handleLocationChange = (place: Place) => {
    if (place) {
      setLocation({
        name: place.name,
        google_place_id: place.id,
        google_place_url: place.url,
        image: place.image,
        latitude: place.latitude,
        longitude: place.longitude,
      });
    } else {
      dispatch({ type: 'resetForm' });
    }
  };

  return (
    <ModalForm
      closePath={tripDestinationsPath(tripId)}
      formActionPath={tripDestinationsPath(tripId)}
      formMethod="post"
      saveButtonLabel="Save"
      subtitle="Suggest a destination for your upcoming trip."
      title="Add a destination"
      withChangeDetection
    >
      <FormContext.Consumer>
        {({ onFormChange, submit }) => (
          <Grid container direction="column" spacing={2}>
            { errors.length > 0 && (
              <Grid item><Errors errors={errors} /></Grid>
            )}
            <Grid item>
              <Typography className={classes.label}>
                Where to?*
              </Typography>
              <DestinationField
                currentGooglePlaceId={location.googlePlaceId}
                currentGooglePlaceUrl={location.googlePlaceUrl}
                currentLatitude={location.latitude}
                currentLongitude={location.longitude}
                currentImage={location.googlePlaceImage}
                onChange={handleLocationChange}
                ref={inputRef}
                required
              />
            </Grid>
            <Grid item>
              <Typography className={classes.label}>
                Add a comment
              </Typography>
              <TextField
                fullWidth
                id="destination_comment"
                label="Comment"
                name="destination[comments_attributes][0][body]"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <DestinationPreview
                onError={() => dispatch({ type: 'showFetchErrorMessage' })}
                onFetch={() => dispatch({ type: 'hideAll' })}
                onLoading={() => dispatch({ type: 'hideAll' })}
                onRemove={() => dispatch({ type: 'resetForm' })}
                resourcePath={tripDestinationsPath(tripId)}
                tripId={tripId}
                location={location}
              />
            </Grid>
            {showEmptyState && (
              <Grid item container justifyContent="center" data-test-id="emptyState">
                <img
                  className={classes.emptyStateImage}
                  alt="Search for destinations"
                  src="/assets/illustrations/globe.svg"
                />
              </Grid>
            )}
            {showFetchErrorMessage && (
              <Grid item container justifyContent="center">
                <img
                  className={classes.noImage}
                  alt="No preview found"
                  src="/assets/illustrations/map.svg"
                />
                <Typography>
                  We can't preview that location but you can still save if you like.
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </FormContext.Consumer>
    </ModalForm>
  );
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(NewDestinationPage);
