import React from "react";
import { MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { GlobalContext } from "../Document";

type BookingActionsMenuItemsProps = {
  displayMarkAsBooked?: boolean,
  newBookingPath?: string,
  updateBookingPath?: string,
  onMarkAsBookedClick?: () => void,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#334351',
    height: "20px",
    width: "20px",
    marginRight: "5px",
  },
}));

const BookingActionsMenuItems : React.FC<BookingActionsMenuItemsProps> = ({
  displayMarkAsBooked = false,
  newBookingPath = '',
  updateBookingPath = '',
  onMarkAsBookedClick = () => {},
}) => {
  const classes = useStyles();

  const goToStayBookingPath = () => {
    onMarkAsBookedClick();
    window.location.href = newBookingPath;
  };

  const goToEditStayBookingPath = () => {
    window.location.href = updateBookingPath;
  };

  return (
    <>
      {displayMarkAsBooked && (
        <MenuItem data-test-id={`book-stay-button`} onClick={goToStayBookingPath} dense>
          <CheckCircleIcon fontSize="small" sx={{marginRight: '5px'}}/>
          Mark as booked
        </MenuItem>
      )}
      {!displayMarkAsBooked && updateBookingPath && (
        <MenuItem data-test-id="update-stay-booking" onClick={goToEditStayBookingPath} dense>
          <img src="/assets/edit_calendar.svg" className={classes.icon} />
          Update booking
        </MenuItem>
      )}
    </>
  );
};

export default BookingActionsMenuItems;
