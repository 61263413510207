import React from "react";
import { Grid } from "@mui/material";
import Button from "../../../../../buttons/Button";
import Tooltip from "../../../../../shared/Tooltip";

type SkipVotingButtonProps = {
  disabled: boolean,
  finalizePath: string,
  isPlanner: boolean,
  tripObjectType: string,
};

const SkipVotingButton = ({
  disabled,
  finalizePath,
  isPlanner,
  tripObjectType,
}: SkipVotingButtonProps) => {

  const tooltipTitle = () => {
    if(disabled && isPlanner) return `Add a ${tripObjectType} before skipping voting`;
    if(disabled && !isPlanner) return `Only planners can skip voting`;

    return '';
  };

  const variant = disabled ? 'tertiary' : 'secondary';

  return (
    <Tooltip title={tooltipTitle()}>
      <Grid item>
        <Button
          aria-label='skip-voting-button'
          disabled={disabled}
          data-test-id='skip-voting-button'
          fullWidth
          href={`${finalizePath}?skip_voting=true`}
          variant={variant}
        >
          Skip voting
        </Button>
      </Grid>
    </Tooltip>
  );
};

export default SkipVotingButton;
