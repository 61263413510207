import React from "react";
import {
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { DateOptionProps } from "../../StaysSearch";
import { default as DateRangePicker } from "../../../../shared/CustomDateRangePicker";

type DatesSectionProps = {
  dateOptions: DateOptionProps[],
  selectedDates: string[],
  setSelectedDates: (dates) => void,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '24px',
  },
  sectionText: {
    fontSize: 16,
    color: theme.palette.text.primary,
    lineHeight: '21px',
  },
  clearDatesButton: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  disabled: {
    color: '#bdbdbd',
  },
  customDatesContainer: {
    maxWidth: '390px !important',
  },
}));

const DatesSection = ({
  dateOptions,
  selectedDates,
  setSelectedDates,
}: DatesSectionProps) => {
  const classes = useStyles();
  const isSERP = window.location.href.includes('/add_by_search')
  const [arrival, departure] = selectedDates;

  const selectedDatesInDateOptions = dateOptions.find((dateOption) => (
    dateOption.startDate === arrival && dateOption.endDate === departure
  ));
  const [customSelection, setCustomSelection] = React.useState(!selectedDatesInDateOptions)

  const handleDateChange = (event) => {
    if (event.target.value === 'custom') {
      setCustomSelection(true);
    } else {
      setSelectedDates(event.target.value.split('|'))
      setCustomSelection(false);
    }
  };

  const clearDatesLink = () => (
    <Link
      variant="h3"
      component="button"
      disabled={!arrival && !departure}
      className={clsx(classes.clearDatesButton, { [classes.disabled]: !arrival && !departure })}
      data-test-id="clearDatesButton"
      onClick={() => setSelectedDates([null, null])}
      underline="none"
    >
      Clear dates
    </Link>
  )

  const onDateRangePickerChange = (dateRange) => {
    const startDate = dateRange[0];
    const endDate = dateRange[1];

    if (startDate && endDate && endDate.isAfter(startDate, 'day')) {
      setSelectedDates([
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      ]);
    }
  };

  const currentRadioValue = customSelection ? 'custom' : `${arrival}|${departure}`;
  const chosenDate = dateOptions.find((dateOption) => dateOption.isChosenDate);

  const DateOptionRadio = ({ dateOption }: { dateOption: DateOptionProps }) => (
    <FormControlLabel
      disabled={dateOption.isPast}
      value={`${dateOption.startDate}|${dateOption.endDate}`}
      control={<Radio color="primary" />}
      data-test-id={`${dateOption.formattedStartDate} - ${dateOption.formattedEndDate}`}
      label={`${dateOption.formattedStartDate} - ${dateOption.formattedEndDate}`}
    />
  );

  return (
    <RadioGroup value={currentRadioValue} data-test-id='dates-radio-button' onChange={handleDateChange}>
      <Grid container direction="column" spacing={1} mt={2}>
        <Grid item container justifyContent='space-between' sx={{ maxWidth: '390px !impmortant' }}>
          <Typography className={classes.headerText}>Dates</Typography>
          {!isSERP && clearDatesLink()}
        </Grid>
        {dateOptions.length > 0 && !chosenDate && (
          <Grid item>
            <Typography className={classes.sectionText}>
              These are the dates your group is voting on.
            </Typography>
          </Grid>
        )}
        <Grid item container direction="column">
          {chosenDate ?
            <Grid item>
              <DateOptionRadio dateOption={chosenDate} />
            </Grid>
          : dateOptions.map((dateOption, index) => (
            <Grid key={`date-option-radio-${index}`} item>
              <DateOptionRadio dateOption={dateOption} />
            </Grid>
          ))}
          {isSERP && (
            <Grid
              className={classes.customDatesContainer}
              container
              flexWrap="nowrap"
              item
              justifyContent='space-between'
            >
              <FormControlLabel
                value="custom"
                control={<Radio color="primary" />}
                label="Custom dates"
                data-test-id="custom-dates"
              />
              {clearDatesLink()}
            </Grid>
          )}
        </Grid>

        <Grid item>
          <DateRangePicker
            helperText={false}
            autoComplete="off"
            disabled={!customSelection}
            startValue={arrival}
            endValue={departure}
            onChange={(dateRange) => onDateRangePickerChange(dateRange)}
            useMobilePicker
          />
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

export default DatesSection;
