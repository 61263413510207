import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Errors from "../../../shared/Errors";
import Button from "../../../buttons/Button";
import ModalForm from "../../../shared/ModalForm";
import { FormContext } from "../../../shared/RailsForm";
import useStateWithPromise from "../../../../services/useStateWithPromise";
import { StayType, DateOptionType, DestinationType } from '../../../shared/TripObjectTypes';

type ChoosingTripObject = StayType | DateOptionType | DestinationType;

export enum ChoosingTypeTripObject {
  Dates = 'dates',
  Destination = 'destination',
  Stay = 'stay',
};

type RenderTripObjectParams = {
  tripObject: | StayType | DateOptionType | DestinationType,
  isChosen: boolean,
  chooseFinalTripObject: (id: string) => void,
};

type TripObjectChoosingModalFormProps = {
  errors: string[],
  newChosenTripObjectPath: string,
  renderTripObject: ({tripObject, isChosen, chooseFinalTripObject}: RenderTripObjectParams) => React.ReactNode,
  subtitle: string,
  title: string,
  tripObjects: ChoosingTripObject[],
  tripSitePath: string,
  type: ChoosingTypeTripObject,
  isSkipVoting?: boolean,
};

const usePageStyles = makeStyles(theme => ({
  tripObjectInputs: {
    marginTop: theme.spacing(0),
  },
  tripObjectNoteContainer: {
    marginTop: theme.spacing(2),
    paddingTop: '0!important',
  },
  confirmTripObjectButton: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.general.primary,
    color: 'white',
    fontSize: 16,
  },
}));

const TripObjectChoosingModalForm = ({
  errors,
  newChosenTripObjectPath,
  renderTripObject,
  subtitle,
  title,
  tripObjects,
  tripSitePath,
  type,
  isSkipVoting = false,
}: TripObjectChoosingModalFormProps) => {
  const classes = usePageStyles();
  const defaultChosenId = tripObjects.length === 1 && !(type === ChoosingTypeTripObject.Dates && (tripObjects[0] as DateOptionType).isPast) ? tripObjects[0].id : null;
  const [chosenTripObjectId, setChosenTripObjectId] = useStateWithPromise(defaultChosenId);

  const skipVotingField = (isSkipVoting) => {
    if (isSkipVoting) {
      return <>
        <input type="hidden" value='true' name="skip_voting" />
      </>;
    };
  };

  return (
    <ModalForm
      title={title}
      subtitle={subtitle}
      closePath={tripSitePath}
      formActionPath={newChosenTripObjectPath}
      useDefaultSaveButton={false}
    >
      <FormContext.Consumer>
        {({ onFormChange }) => (<>
          <input type="hidden" value={chosenTripObjectId || ''} name="trip_object_id" />
          {skipVotingField(isSkipVoting)}
          <Box>
            <Errors errors={errors} />
            <Grid container direction="column">
              {tripObjects.map((tripObject, index) =>
                <React.Fragment key={index}>
                  <Grid item>
                    {chosenTripObjectId === tripObject.id && (
                      <Grid container spacing={2} className={classes.tripObjectInputs}>
                        <Grid item xs={12} className={classes.tripObjectNoteContainer}>
                          <Typography variant='h3' mb={2}>Add a note</Typography>
                          <TextField
                            autoFocus
                            label="Any additional info to share?"
                            variant="outlined"
                            name="note"
                            maxRows={10}
                            fullWidth
                            multiline
                            data-test-id='note-field'
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item>
                    {renderTripObject(
                      {
                        tripObject: tripObject,
                        isChosen: chosenTripObjectId === tripObject.id,
                        chooseFinalTripObject: (id) => setChosenTripObjectId(id).then(() => onFormChange()),
                      }
                    )}
                  </Grid>
                  <Grid item>
                    {chosenTripObjectId === tripObject.id && (
                      <Grid container spacing={2} className={classes.tripObjectInputs}>
                        <Grid item xs={12}>
                          <Button
                            className={classes.confirmTripObjectButton}
                            type="submit"
                            fullWidth
                            data-test-id='finalize-confirmation-button'
                          >
                            Finalize {type}
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </React.Fragment>,
              )}
            </Grid>
          </Box>
        </>)}
      </FormContext.Consumer>
    </ModalForm>
  );
};

// noinspection JSUnusedGlobalSymbols
export default TripObjectChoosingModalForm;
