import React, { useContext, useEffect, useRef, useState } from "react";
import { FormsContext } from "../Document";
import compareObjects from "../../services/compareObjects";
import serializeForm from "../../services/serializeForm";
import mergeRefs from "../../services/mergeRefs";
import type { FormEvent } from "react";

type RailsFormProps = {
  action: string,
  children?: React.ReactNode,
  className?: string,
  method?: string,
  onSubmit?: ((event?: FormEvent<HTMLFormElement>) => void) | null,
  submitDisabled?: boolean,
  withChangeDetection?: boolean,
};

type FormContextValue = {
  disabled: boolean,
  onFormChange?: (() => void) | null,
  submit?: ((event?: FormEvent<HTMLFormElement>) => void) | null,
};

export const FormContext = React.createContext<FormContextValue>({
  disabled: false,
  onFormChange: null,
  submit: null,
});

const RailsForm = React.forwardRef(({
  action,
  children,
  className,
  method,
  submitDisabled,
  onSubmit,
  withChangeDetection,
  ...formProps
}: RailsFormProps, externalFormRef) => {
  const { csrfToken } = useContext(FormsContext);
  const [disabled, setDisabled] = useState(!!withChangeDetection);
  const [initialFormValues, setInitialFormValues] = useState({});
  const formRef = useRef<HTMLFormElement | undefined>(undefined);

  useEffect(() => {
    setInitialFormValues(serializeForm(formRef.current));
  }, []);

  const isGetMethod = () => {
    return method && method.toUpperCase() === 'GET';
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (disabled || submitDisabled) {
      event.preventDefault();
    } else {
      !!onSubmit ? onSubmit(event) : setDisabled(true)
    }
  };

  const handleFormChange = () => {
    if (withChangeDetection) {
      const currentFormValues = serializeForm(formRef.current);

      const isUnchangedForm = compareObjects(
        initialFormValues,
        currentFormValues,
      );

      setDisabled(isUnchangedForm);
    }
  };

  const submitForm = () => {
    setDisabled(true);
    formRef.current?.submit();
  };

  return (
    <FormContext.Provider value={{
      disabled,
      onFormChange: handleFormChange,
      submit: submitForm,
    }}>
      <form
        action={action}
        className={className}
        method={isGetMethod() ? "get" : "post"}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        ref={mergeRefs(externalFormRef, formRef)}
        {...formProps}
      >
        {!isGetMethod() && (
          <>
            <input type="hidden" name="_method" value={method} />
            <input type="hidden" name="authenticity_token" value={csrfToken} />
          </>
        )}
        {children}
      </form>
    </FormContext.Provider>
  );
});

RailsForm.defaultProps = {
  method: 'POST',
  withChangeDetection: true,
};

export default RailsForm;
