import React, { useState } from 'react';
import clsx from "clsx";
import {
  Collapse,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertColor,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

type DismissableAlertProps = {
  children: React.ReactNode,
  className?: string,
  severity?: AlertColor,
  icon?: React.ReactNode,
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'flex-start',
  },
  closeButton: {
    paddingTop: theme.spacing(1),
  },
}));

const DismissibleAlert = ({
  children,
  className,
  severity,
  icon,
  ...alertProps
}: DismissableAlertProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={() => setOpen(false)}
          >
            <CloseIcon fontSize="inherit" data-test-id="closeAlertButton" />
          </IconButton>
        }
        className={clsx(className, classes.root)}
        data-test-id="alert"
        severity={severity}
        variant="outlined"
        icon={icon}
        {...alertProps}
      >
        {children}
      </Alert>
    </Collapse>
  );
};

export default DismissibleAlert;
