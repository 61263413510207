import React from 'react';
import useSegment from '../useSegment';

export const useAdClickedEvent = (vertical, label, title) => {
  const { trackLinkSegment } = useSegment();

  const ref = React.useCallback((element) => {
    if (element !== null) {
      trackLinkSegment(
        element,
        'Ad Clicked',
        {
          vertical,
          adDestination: label,
          activityAdType: title,
        },
      );
    }
  }, []);

  return ref;
};
