import React, { PropsWithChildren } from "react";

type RenderChildWhenFFOnProps = PropsWithChildren<{
  featureFlag: string,
  context: React.Context<any>,
}>;

const RenderChildWhenFFOn : React.FC<RenderChildWhenFFOnProps> = ({ featureFlag, context, children }) => {
  const contextInstance = React.useContext(context)
  return contextInstance[featureFlag] ? <>{children}</> : null;
}

export default RenderChildWhenFFOn;
