import React from "react";
import InputMask from "react-input-mask";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type BasicInfoFieldsProps = {
  user: UserType,
  fieldFocus?: string,
  setSmsSettingsState: (disabled: boolean) => void,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  email: {
    fontWeight: 600,
  },
  phoneNumberInstructions: {
    borderSpacing: theme.spacing(2),
    color: theme.palette.general.gray,
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
}));

const BasicInfoFields = ({
  fieldFocus,
  user,
  setSmsSettingsState,
}: BasicInfoFieldsProps) => {
  const classes = useStyles();
  const providerText = user.provider === 'Email' ? "You're logged in with" : `You're logged in with your ${user.provider} account`;
  const isPhoneNumberLengthValid = (value) => {
    return value.match(/\d+/g)?.join("").length === 10;
  };

  const setSmsSettingsOnChange = (event) => {
    //Enable SMS Notification check-boxes
    setSmsSettingsState(!isPhoneNumberLengthValid(event.target.value));
  };

  return (
    <>
      <Grid item>
        <Typography className={classes.subtitle}>
          {providerText}
          <span className={classes.email}> {user.email}</span>.
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          id="first_name"
          label="First name"
          name="user[first_name]"
          defaultValue={user.firstName}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          id="last_name"
          label="Last name"
          name="user[last_name]"
          defaultValue={user.lastName}
          variant="outlined"
        />
      </Grid>
      <Grid item>
        <TextField
          disabled
          fullWidth
          id="email_address"
          label="Email address"
          name="email_address"
          value={user.email}
          variant="outlined"
        />
      </Grid>
      <Grid item>
        <InputMask
          mask="(999) 999-9999"
          defaultValue={user.phoneNumber}
          onChange={setSmsSettingsOnChange}
        >
        {() =>
          <TextField
            autoFocus={fieldFocus === "phone_number"}
            defaultValue={user.phoneNumber}
            fullWidth
            id="phone_number"
            label="Phone number"
            name="user[phone_number]"
            placeholder="Phone number"
            type="tel"
            variant="outlined"
            data-test-id='phone-number-field'
          />
        }
        </InputMask>
        <Typography className={classes.phoneNumberInstructions}>
          This lets us notify you about updates to your trips and account via SMS.
        </Typography>
      </Grid>
    </>
  );
};

export type UserType = {
  avatarUrl: string,
  defaultAvatarUrl: string,
  email: string,
  firstName: string,
  fullName: string,
  provider: string,
  tripNotificationsEnabled: boolean,
  lastName?: string,
  phoneNumber?: string,
  notificationsSettingId: number,
  notificationsSetting: {
    emailComments: boolean,
    emailTravelerReaction: boolean,
    emailFinalChoices: boolean,
    emailNewTraveler: boolean,
    emailSuggestions: boolean,
    emailVotes: boolean,
    smsComments: boolean,
    smsTravelerReaction: boolean,
    smsFinalChoices: boolean,
    smsNewTraveler: boolean,
    smsSuggestions: boolean,
    smsVotes: boolean,
  },
};

export default BasicInfoFields;
