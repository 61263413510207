import React from 'react';
import { makeStyles, useTheme } from "@mui/styles";
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from '@mui/material';

const useStyles: () => any = makeStyles((theme): {} => ({
  active: {
    color: [['#243060'], '!important'],
  },
  completed: {
    color: [['#979797'], '!important'],
  },
  iconContainer: {
    '& svg': {
      border: '1px solid #CBCBCB',
      borderRadius: '50%',
      color: '#FFFFFF',
      '&.Mui-active': {
        border: '2px solid #D3D6FF',
        borderRadius: '50%',
        color: '#545DFC',
        '& .MuiStepIcon-text': {
          fill: '#FFFFFF',
        },
      },
      '&.Mui-completed': {
        border: '2px solid #94E4D0',
        borderRadius: '50%',
        color: '#1EBE95',
      },
      '& .MuiStepIcon-text': {
        fill: '#979797',
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '16px',
      },
    },
  },
  label: {
    color: '#979797',
    fontFamily: [[theme.typography.fontFamily], '!important'],
    fontSize: [[14], '!important'],
    fontWeight: [[600], '!important'],
    lineHeight: [['18px'], '!important'],
  },
  root: {
    '& .MuiStepConnector-line': {
      borderTopWidth: '2px',
      borderColor: '#CBCBCB',
    },
    paddingLeft: 0,
  },
}));

export type HorizontalStepperProps = {
  activeStep?: number,
  steps: string[],
};

const HorizontalStepper = ({
  activeStep,
  steps,
}: HorizontalStepperProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down(700));

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel={isMobile}>
        {steps.map((label) => (
          <Step classes={{ root: classes.root }} key={label}>
            <StepLabel
              classes={{
                active: classes.active,
                completed: classes.completed,
                iconContainer: classes.iconContainer,
                label: classes.label,
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

HorizontalStepper.defaultProps = {
  activeStep: 0,
};

export default HorizontalStepper;
