import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { DialogBottomOnMobile } from './Dialog';
import VotesTable from "./VotingResults/VotesTable";
import { track as trackAnalyticsEvent } from "../../services/analytics";
import { TravelerProps } from "./TravelersVotingBox";
import { Grid } from "@mui/material";
import VoteResultsButton from "../pages/trips/microsite/shared/VoteResultsButton";

const useStyles = makeStyles((theme) => ({
  votesContainer: {
    zIndex: 2,
  },
}));

type VotingSummaryDialogProps = {
  isPlanner: boolean,
  objectId: string,
  objectType: 'stays' | 'destinations',
  travelers: TravelerProps[],
};

const VotingSummaryDialog = ({
  isPlanner,
  objectId,
  objectType,
  travelers,
}: VotingSummaryDialogProps) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen ] = useState(false);

  const onButtonClick = () => {
    setIsDialogOpen(true);

    trackAnalyticsEvent('See All Votes Selected', {
      isPlanner: isPlanner,
      tripObjectId: objectId,
      vertical: objectType,
    });
  };

  const rankingType = objectType === 'stays' ? 'stayRankings' : 'destinationRankings';
  return (
    <>
      <DialogBottomOnMobile
        title="Voting results"
        fullWidth
        maxWidth="xs"
        message={
          <VotesTable
            rankingType={rankingType}
            objectId={objectId}
            travelers={travelers}
          />
        }
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
      </DialogBottomOnMobile>
      <Grid className={classes.votesContainer} item>
        <VoteResultsButton
          objectId={objectId}
          onClick={() => onButtonClick()}
          travelers={travelers}
          rankingType={rankingType}
        />
      </Grid>
    </>
  );
};

export default VotingSummaryDialog;
