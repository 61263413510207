import React from "react";
import { Grid, IconButton } from "@mui/material";
import Tooltip from "../../../../shared/Tooltip";
import clsx from "clsx";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  disabledButton: {
    backgroundColor: '#FFFFFF !important',
  },
  addIcon: {
    fontSize: theme.spacing(8),
    color: '#FFFFFF',
  },
  disabledAddIcon: {
    color: '#D6DEE4',
  },
  itineraryHeader: {
    maxWidth: '250px',
  },
  dayAndEditSection: {
    justifyContent: 'space-between',
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    bottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    zIndex: 20,
  },
  addButton: {
    boxShadow: '0 8px 16px 0 rgb(60 64 67 / 30%)',
    backgroundColor: '#545DFC',
    height: theme.spacing(7),
    width: theme.spacing(7),
    fontSize: theme.spacing(7),
    '&:hover': {
      backgroundColor: '#3B41B0',
    },
  },
}));

export type FloatingActionButtonProps = {
  testId: string,
  disabled?: boolean,
  onClick?: () => void,
  href?: string,
  toolTipText?: string,
}

const FloatingActionButton = ({
  disabled,
  onClick,
  href,
  toolTipText = "",
  testId,
}: FloatingActionButtonProps) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.addButtonContainer}>
      <Grid/>
      <Tooltip title={disabled ? toolTipText : ""}>
        <div>
          <IconButton
            className={clsx(classes.addButton,
              {[classes.disabledButton]: disabled},
            )}
            data-test-id={testId}
            disabled={disabled}
            onClick={onClick}
          >
            <AddIcon data-test-id="floatingActionIcon" className={clsx(classes.addIcon,
              {[classes.disabledAddIcon]: disabled},
            )}/>
          </IconButton>
        </div>
      </Tooltip>
    </Grid>
  )
};

export default FloatingActionButton;
