import React, { useCallback, useContext } from "react";
import { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import EditIcon from '@mui/icons-material/Edit';
import { ItineraryDateType } from "../../../../shared/TripObjectTypes";
import { editTripItineraryDatePath } from '../../../../../routes';
import { trackLink as trackAnalyticsEvent } from '../../../../../services/analytics';
import { SegmentContext } from "../../../../Document";

type AddDescriptionLinkProps = {
  itineraryDate: ItineraryDateType,
};

const useStyles = makeStyles(theme => ({
  addDescriptionLink: {
    pointerEvents: 'all',
    fontWeight: '400',
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '24px',
    color: '#4A6176',
    '&:hover': {
      color: theme.palette.link.main,
    },
  },
  editIcon: {
    fontSize: '15px',
    marginRight: '5px',
  },
}));

const AddDescriptionLink = ({
  itineraryDate,
}: AddDescriptionLinkProps) => {
  const classes = useStyles();
  const { currentUserId, tripId, isPlanner } = useContext(SegmentContext);
  const refs = useCallback((element) => {
    if(element !== null) {
      trackAnalyticsEvent(
        element,
        'Edit Itinerary Day Initiated ',
        {
          userId: currentUserId,
          tripId: tripId,
          planner: isPlanner,
          page: 'Itinerary List',
        },
      );
    }
  }, []);

  return (
    <Link
      href={editTripItineraryDatePath(itineraryDate.tripId, itineraryDate.id)}
      className={classes.addDescriptionLink}
      underline="hover"
      ref={(element) => refs(element)}
    >
      <EditIcon className={classes.editIcon}/>
      Add a description
    </Link>
  );
};

export default AddDescriptionLink;

