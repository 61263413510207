import { theme } from "../Document"

type ChipColorVariant = {
  fill: string,
  border: string,
  color: string,
};

type ChipColor = {
  default: ChipColorVariant,
  hover: ChipColorVariant,
  active: ChipColorVariant,
  activeHover: ChipColorVariant,
  disabled: ChipColorVariant,
};

type ChipColors = {
  primary: ChipColor,
  success: ChipColor,
  successLighter: ChipColor,
  danger: ChipColor,
};

const chipColors: ChipColors = {
  primary: {
    default: {
      fill: "white",
      border: "#555e64",
      color: "#505050",
    },
    hover: {
      fill: "rgba(85, 94, 100, 0.1)",
      border: "#555e64",
      color: "#353535",
    },
    active: {
      fill: "#3B41B0",
      border: "#244599",
      color: 'white',
    },
    activeHover: {
      fill: "#262A71",
      border: "#262A71",
      color: "#FFFFFF",
    },
    disabled: {
      fill: "white",
      border: "#c0c0c0",
      color: "#c0c0c0",
    },
  },
  success: {
    default: {
      fill: "white",
      border: "#555e64",
      color: "#272727",
    },
    hover: {
      fill: "rgba(85, 94, 100, 0.1)",
      border: "#555e64",
      color: "#272727",
    },
    active: {
      fill: "rgba(30, 190, 149, 0.15)",
      border: "#59c9a5",
      color: "#272727",
    },
    activeHover: {
      fill: "#55b597",
      border: "#55b597",
      color: "#272727",
    },
    disabled: {
      fill: "white",
      border: "#c0c0c0",
      color: "#c0c0c0",
    },
  },
  successLighter: {
    default: {
      fill: "white",
      border: "#555e64",
      color: "#272727",
    },
    hover: {
      fill: "rgba(85, 94, 100, 0.1)",
      border: "#555e64",
      color: "#272727",
    },
    active: {
      fill: "rgba(30, 190, 149, 0.15)",
      border: "#59c9a5",
      color: theme.palette.text.primary,
    },
    activeHover: {
      fill: "#55b597",
      border: "#55b597",
      color: "#272727",
    },
    disabled: {
      fill: "white",
      border: "#c0c0c0",
      color: "#c0c0c0",
    },
  },
  danger: {
    default: {
      fill: "white",
      border: "#555e64",
      color: "#272727",
    },
    hover: {
      fill: "rgba(85, 94, 100, 0.1)",
      border: "#555e64",
      color: "#272727",
    },
    active: {
      fill: "#FB5D5D26",
      border: "#bd2031",
      color: "#272727",
    },
    activeHover: {
      fill: "#b22536",
      border: "#b22536",
      color: "#272727",
    },
    disabled: {
      fill: "white",
      border: "#c0c0c0",
      color: "#c0c0c0",
    },
  },
};

export default chipColors;
