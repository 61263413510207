import React from "react";
import {
  Grid,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DateRangePicker from "../../../shared/CustomDateRangePicker";
import DestinationField from "../../destinations/DestinationField";
import { Place } from "../../../shared/GooglePlacesAutocomplete";
import CloseIcon from '@mui/icons-material/Close';
import StayPreview from "../../stays/StayForm/StayPreview";

type SecondStepTripFormProps = {
  setOptionalData: (data: any) => void,
  optionalData: any,
};

const useStyles = makeStyles((theme) => ({
  verticalTitle: {
    marginBottom: theme.spacing(1),
    lineHeight: '24px',
  },
  title: {
    textAlign: 'center',
  },
  subTitle: {
    textAlign: 'center',
  },
  image: {
    width: '100%',
    padding: theme.spacing(2),
    maxWidth: '100%',
    [theme.breakpoints.down("sm")]: {
      width: '360px',
    },
    [theme.breakpoints.up("md")]: {
      height: '200px',
    },
  },
  centeredSection: {
    textAlign: 'center',
  },
  noStayImage: {
    maxWidth: theme.spacing(50),
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  emptyStateImage: {
    maxWidth: theme.spacing(50),
    width: '100%',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
}));

export const UpperImage = () => {
  const classes = useStyles();
  return(
    <img src="/assets/illustrations/create_trip_second_step.svg" alt="posts" className={classes.image} />
  )
};

const SecondStepTripForm : React.FC<SecondStepTripFormProps> = ({
  setOptionalData,
  optionalData,
}) => {
  const classes = useStyles();

  const isVerticalPresentOrFilled = (isVerticalSelected : boolean, data: any) => (
    isVerticalSelected ? !!data : true
  );

  const handleDisabledSubmit = (value: any, type: string, optionalDataPrevState) => {
    const typeToSubmitButtonEnabled = {
      dates: !!(value[0] && value[1]) && isVerticalPresentOrFilled(optionalDataPrevState.verticals.destinations, optionalDataPrevState.location) && isVerticalPresentOrFilled(optionalDataPrevState.verticals.stays, optionalDataPrevState.stay),
      destinations: !!value && isVerticalPresentOrFilled(optionalDataPrevState.verticals.dates, !!(optionalDataPrevState.start_date && optionalDataPrevState.end_date)) && isVerticalPresentOrFilled(optionalDataPrevState.verticals.stays, optionalDataPrevState.stay),
      stays: !!value && isVerticalPresentOrFilled(optionalDataPrevState.verticals.dates, !!(optionalDataPrevState.start_date && optionalDataPrevState.end_date)) && isVerticalPresentOrFilled(optionalDataPrevState.verticals.destinations, optionalDataPrevState.location),
    }
    return !typeToSubmitButtonEnabled[type];
  }

  const renderDatesPicker = () => (
    <Grid item>
      <Typography variant="h3" className={classes.verticalTitle}>
        Dates
      </Typography>
      <DateRangePicker
        onChange={dates => {
          setOptionalData(prevState => ({
            ...prevState,
            start_date: dates[0]?.format('YYYY-MM-DD'),
            end_date: dates[1]?.format('YYYY-MM-DD'),
            disabledSubmit: handleDisabledSubmit(dates, 'dates', prevState),
          }));
        }}
        defaultStartValue={optionalData.start_date}
        defaultEndValue={optionalData.end_date}
      />
    </Grid>
  );

  const renderDestinationTextField = () => {
    const inputRef = React.useRef(null);
    const handleLocationChange = (place: Place) => {
      if (place) {
        setOptionalData(prevState => ({
          ...prevState,
          location: {
            name: place.name,
            google_place_id: place.id,
            google_place_url: place.url,
            image: place.image,
            latitude: place.latitude,
            longitude: place.longitude,
          },
          disabledSubmit: handleDisabledSubmit(place, 'destinations', prevState),
        }));
      } else {
        setOptionalData(prevState => ({
          ...prevState,
          location: {},
          disabledSubmit: handleDisabledSubmit(false, 'destinations', prevState),
        }));
        inputRef.current?.focus();
      }
    };

    return(
      <Grid item>
        <Typography variant="h3" className={classes.verticalTitle}>
          Destination
        </Typography>
        <DestinationField
          currentDestination={optionalData?.location?.name}
          currentGooglePlaceId={optionalData?.location?.googlePlaceId}
          currentGooglePlaceUrl={optionalData?.location?.googlePlaceUrl}
          currentLatitude={optionalData?.location?.latitude}
          currentLongitude={optionalData?.location?.longitude}
          currentImage={optionalData?.location?.googlePlaceImage}
          onChange={handleLocationChange}
          ref={inputRef}
          required
        />
      </Grid>
    )
  };

  const renderStayField = () => {
    const inputRef = React.useRef(null);
    return(
      <Grid item>
        <Typography variant="h3" className={classes.verticalTitle}>
          Stay
        </Typography>
        <TextField
          fullWidth
          value={optionalData?.stay || ''}
          id="stay_url"
          inputRef={inputRef}
          InputProps={{
            endAdornment: optionalData?.stay && (
              <IconButton onClick={() => {
                setOptionalData(prevState => ({
                  ...prevState,
                  stay: "",
                  disabledSubmit: handleDisabledSubmit(false, 'stays', prevState),
                }));
                inputRef.current?.focus();
              }}>
                <CloseIcon />
              </IconButton>
            ),
          }}
          onChange={(event) => setOptionalData(prevState => ({
            ...prevState,
            stay: event.target.value.trim(),
            disabledSubmit: handleDisabledSubmit(event.target.value.trim(), 'stays', prevState),
          }))}
          placeholder="Paste your link here"
          required
          variant="outlined"
        />
        {optionalData?.stay ? (
          <StayPreview
            url={optionalData?.stay}
          />
        ) : (
          <Grid item className={classes.centeredSection}>
            <img
              className={classes.noStayImage}
              alt="No preview found"
              src={`/assets/illustrations/camper.svg`}
            />
          </Grid>
        )}
      </Grid>
    )
  };

  const disabledSubmitOnFirstRender = (optionalData) => (
    !(isVerticalPresentOrFilled(optionalData.verticals.destinations, optionalData.location?.name) &&
    isVerticalPresentOrFilled(optionalData.verticals.dates, !!(optionalData.start_date && optionalData.end_date)) &&
    isVerticalPresentOrFilled(optionalData.verticals.stays, optionalData.stay))
  );

  React.useEffect(() => {
    setOptionalData(prevState => ({
      ...prevState,
      disabledSubmit: disabledSubmitOnFirstRender(prevState),
    }))
  }, []);

  return(
    <Grid container spacing={2} direction="column">
      {optionalData.verticals?.dates && renderDatesPicker()}
      {optionalData.verticals?.destinations && renderDestinationTextField()}
      {optionalData.verticals?.stays && renderStayField()}
    </Grid>
  );
};

export default SecondStepTripForm;
