import React, { useContext, useState } from "react";
import {
  Container,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EditIcon from '@mui/icons-material/Edit';
import withMicrositeLayout from "./withLayout";
import AttendeesAvatars from "./OverviewPage/AttendeesAvatars";
import SectionDivider from "./OverviewPage/SectionDivider";
import SectionHeader from "./OverviewPage/SectionHeader";
import TripStatus from "./OverviewPage/TripStatus";
import Button from "../../../buttons/Button";
import ShareTripButton from "../../../shared/SiteHeader/ShareTripButton";
import ManageTripButton from "../../../shared/SiteHeader/ManageTripButton";
import RichText from "../../../shared/RichText";
import InviteDialog from "../../../shared/InviteDialog";
import { editTripPath } from '../../../../routes';
import { track as trackAnalyticsEvent } from '../../../../services/analytics';
import { DateOptionType, StayType, DestinationType } from "../../../shared/TripObjectTypes";
import { TripType } from "../../../shared/TripTypes";
import InviteModal from "../../../shared/InviteModal";
import { useEditTripInitiated } from "../../../../services/segmentEvents/tripEvents";
import FinalizedDatesAndDestination from "./OverviewPage/FinalizedDatesAndDestination";

type OverviewPageType = {
  chosenDateOption?: DateOptionType,
  chosenDestination?: DestinationType,
  chosenStay?: StayType,
  currentUserId?: string,
  inviterName?: string,
  isInviteValid?: boolean,
  trip: TripType,
  utmMedium?: 'copied' | 'shared' | 'email',
  withInvite?: boolean,
};

const useStyles = makeStyles((theme) => ({
  tripName: {
    color: theme.palette.primary.main,
    fontSize: 32,
    fontWeight: 700,
    textAlign: "center",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    lineHeight: '1.4',
  },
  blurb: {
    width: '100%',
  },
  blurbContainer: {
    paddingTop: theme.spacing(3),
  },
  editIcon: {
    marginRight: theme.spacing(1),
  },
  noMarginTop: {
    marginTop: theme.spacing(-4),
  },
}));

const BlurbSection = ({trip}: {trip: TripType}) => {
  const classes = useStyles();
  const editTripInitiatedEvent = useEditTripInitiated();

  return <>
    <Grid container direction="column" spacing={3} alignItems="center">
      <Grid item className={classes.blurb}>
        <RichText value={trip.blurb} />
      </Grid>
      {trip.isPlanner && (
        <Grid item>
          <Button
            data-test-id="editTripDetailsButton"
            href={editTripPath(trip.id)}
            variant="tertiary"
            onClick={() => editTripInitiatedEvent()}
          >
            <EditIcon className={classes.editIcon} />
            Edit Trip Details
          </Button>
        </Grid>
      )}
    </Grid>
  </>;
};

const OverviewPage = ({
  currentUserId,
  chosenDateOption,
  chosenDestination,
  inviterName,
  isInviteValid,
  trip,
  utmMedium,
  withInvite,
}: OverviewPageType) => {
  const classes = useStyles();
  const [attendeesAvatarsExpanded, setAttendeesAvatarsExpanded] = useState(false);

  const shareMediums = {
    copied: 'Copy share link',
    shared: 'Sharesheet',
    email: 'Email',
  };

  if (!!utmMedium && !!shareMediums[utmMedium]) {
    trackAnalyticsEvent('Invite Link Opened', {
      category: 'Invites',
      source: shareMediums[utmMedium],
      tripId: trip.id,
    });
  }

  const firstTimeNew = (new URLSearchParams(window.location.search).get('invite_modal') === 'true')
  const [inviteModalFormOpen, setInviteModalFormOpen] = useState(firstTimeNew);
  return (
    <>
      {inviteModalFormOpen && (
        <InviteModal
          inviterId={currentUserId}
          onClose={() => setInviteModalFormOpen(false)}
          tripId={trip.id}
          tripGid={trip.gid}
          tripName={trip.name}
          shareLinkSource="Trip Creation"
        />
      )}
      {withInvite && (
        <InviteDialog isInviteValid={isInviteValid} inviterName={inviterName} />
      )}
      <FinalizedDatesAndDestination
        chosenDateOption={chosenDateOption}
        chosenDestination={chosenDestination}
      />
      {trip.blurb && <>
        <Container className={classes.blurbContainer}>
          <BlurbSection trip={trip} />
        </Container>
        <SectionDivider />
      </>}

      <TripStatus />
      <SectionDivider />

      <a id="attendees_avatars" />
      <SectionHeader
        heading="The Who"
        subheading="Here’s who’s joined this trip so far."
      />
      <AttendeesAvatars
        travelers={[...trip.travelers, ...trip.guests]}
        expanded={attendeesAvatarsExpanded}
        onToggleClick={() => setAttendeesAvatarsExpanded(!attendeesAvatarsExpanded)}
      />
      <Grid container justifyContent="center" flexWrap='nowrap' pb={3}>
        <Grid item data-test-id="secondaryInviteButton">
          <ShareTripButton
            disabled={!trip.isTraveler}
            collapseOnMobile={false}
            inviteBtnVariant='primary'
            inviterId={currentUserId}
            tripId={trip.id}
            tripGid={trip.gid}
            tripName={trip.name}
            segmentMethod='"Invite" CTA in The Who'
            shareLinkSource="Overview"
          />
        </Grid>
        <Grid item ml={2}>
          <ManageTripButton
            disabled={!trip.isTraveler}
            collapseOnMobile={false}
            inviteBtnVariant='tertiary'
            tripId={trip.id}
            segmentMethod='"Manage" CTA in The Who'
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withMicrositeLayout(OverviewPage);
