import useSegment from '../useSegment';

export const useTrackDeletionInitiated = () => {
  const { trackSegment } = useSegment();
  return (objectType : string, objectId : string, page : string) => {
    trackSegment('Itinerary Event Deletion Initiated',
      {
        objectId,
        objectType,
        page,
      },
    )
  }
};

export const useTrackEventCardClicked = () => {
  const { trackSegment } = useSegment();
  return (objectType : string, objectId : string, page : string) => {
    trackSegment('Itinerary Event Card Clicked',
      {
        objectId,
        objectType,
        page,
      },
    )
  }
};

export const useTrackItineraryEventEditInitiated = () => {
  const { trackSegment } = useSegment();
  return ( method: string, objectType : string, objectId : string, page : string) => {
    trackSegment(
      'Itinerary Event Edit Initiated',
      {
        method,
        objectId,
        objectType,
        page,
      },
    );
  }
};
