import useSegment from '../useSegment';
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

export const useGuestDismissed = () => {
  const { trackSegment } = useSegment();

  return(() =>
    trackSegment(
      'Add Guest Dismissed',
      {},
    )
  )
};

export const useAddGuestInitiated = () => {
  const isMobileApp = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { trackSegment } = useSegment();

  return(() =>
      trackSegment('Add Guest Traveler Initiated',
        {
          isMobileApp,
        },
      )
  )
};

export const useGuestTravelerToolTipClick = () => {
  const isMobileApp = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { trackSegment } = useSegment();

  return(() =>
    trackSegment('Guest Traveler Tooltip Activated',
      {
        isMobileApp,
      },
    )
  )
};
