import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

type LoadingStateProps = {
  texts: string[];
  interval: number;
};

const useStyles = makeStyles((theme) => ({
  loadTextItem: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontFamily: 'poppins, sans-serif',
  },
  loadingGif: {
    width: '75%',
    marginTop: '60px',
    marginBottom: '-20px',
    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
  },
}));

const LoadingState = ({ texts, interval }: LoadingStateProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    const maxIndex = texts.length;

    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % maxIndex);
    }, interval * 1000);

    return () => clearInterval(intervalId);
  }, [texts, interval]);

  return (
    <Grid container direction="column" alignItems="center" >
      <img src="/assets/Loading_White_Bkg" alt="loading" className={classes.loadingGif} />
      <Typography className={classes.loadTextItem}>
        {texts[currentIndex]}
      </Typography>
    </Grid>
  );
};

export default LoadingState;
