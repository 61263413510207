import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import { NumericFormat } from 'react-number-format';
import ActivityName from "./ActivityName";
import ActivityGroupsTitles from "./ActivityGroupsTitles";
import CategoryLabel from "../../shared/CategoryLabel";
import { ActivityType } from "../../../../../shared/TripObjectTypes";
import TextButton from "../../../../../buttons/TextButton";
import { editTripActivityPath } from '../../../../../../routes';

type HeaderProps = {
  activity: ActivityType,
  displayActivityLinks?: boolean,
  linkToComment?: string,
  onActivityClick?: () => void,
  showActions?: boolean,
  showGroupsTitles: boolean,
};

const useStyles = makeStyles((theme) => ({
  addPriceText: {
    fontSize: theme.spacing(2),
    color: '#3689FF',
    '&:hover': {
      color: '#2C71D3',
    },
  },
  editIcon: {
    fontSize: theme.spacing(2),
    '&:hover': {
      textDecoration: ["underline","!important"],
    },
    marginRight: theme.spacing(1),
    zIndex: 2,
  },
  priceText: {
    fontSize: theme.spacing(2),
    color: '#272727',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const Header = ({
  activity,
  displayActivityLinks,
  linkToComment,
  onActivityClick,
  showActions,
  showGroupsTitles,
}: HeaderProps) => {
  const classes = useStyles();
  const priceText = parseFloat(activity.price).toFixed(2).replace(/\.00$/,'')
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const wrapWithLink = (component) => {
    const onButtonClick = () => {
      window.location.replace(editTripActivityPath(activity.tripId, activity.id) + '?focus=price');
    };

    return (
      <>
        <TextButton
          data-test-id="activity-edit-pricing"
          className={classes.editIcon}
          onClick={() => onButtonClick()}
        >
          {component}
        </TextButton>
      </>
    );
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        {activity.category && !isMobile && (<CategoryLabel category={activity.category}/>)}
      </Grid>
      <Grid item>
        <ActivityName
          activityName={activity.name}
          linkToComment={linkToComment}
          showLink={displayActivityLinks}
          onActivityClick={onActivityClick}
        />
      </Grid>
      {!activity.price && showActions && (
        <Grid item>
          {wrapWithLink(
            <span data-test-id='add-pricing' className={classes.addPriceText}>
              <EditIcon fontSize="small" className={classes.editIcon}/>
              Add pricing
            </span>,
          )}
        </Grid>
      )}
      {activity.price && (
        <Grid item>
          <NumericFormat
            className={classes.priceText}
            value={priceText}
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale={Number(priceText) % 1 !== 0}
            thousandSeparator={true}
            prefix={'$'}
            suffix={' per person'}
          />
        </Grid>
      )}
      {showGroupsTitles && activity.groupsTitles?.length > 0 && (
        <Grid item container>
          <ActivityGroupsTitles titles={activity.groupsTitles} />
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
