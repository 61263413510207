import React, { useReducer } from "react";
import {
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import StayPreview from "./StayForm/StayPreview";
import { GlobalContext, hocRailsAction } from "../../Document";
import TextButton from "../../buttons/TextButton";
import ModalForm from "../../shared/ModalForm";
import * as routes from '../../../routes';

const {
  tripBatchStaysPath,
  tripStaysPath,
  addByURLTripStaysPath,
} = routes;

type BatchStayFormProps = {
  tripId: string,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  addButtonContainer: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  removeUrlButton: {
    color: '#c06f78',
  },
}));

const defaultUrlsSize = 3;
const maxAllowedUrls = 5;

const reducer = (state, action) => {
  const urls = Array.from(state.urls);

  switch (action.type) {
    case 'addUrl':
      if (urls.length === maxAllowedUrls) { return state }

      return { urls: urls.concat("") };
    case 'removeUrl':
      if (urls.length === 1) { return state }

      urls.splice(action.index, 1);

      return { urls: urls };
    case 'setUrl':
      urls[action.index] = action.value;

      return { urls: urls };
    default:
    throw new Error();
  }
};

const BatchStayForm = ({
  tripId,
}: BatchStayFormProps) => {
  const classes = useStyles();

  const initialState = {
    urls: Array(defaultUrlsSize).fill(''),
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ModalForm
      closePath={addByURLTripStaysPath(tripId) }
      formActionPath={tripBatchStaysPath(tripId)}
      formMethod="post"
      saveButtonLabel="Save now"
      subtitle="Paste links below to suggest where your group should stay."
      title="Add a stay"
      withChangeDetection
    >
      <Grid container direction="column" spacing={2}>
        {state.urls.map((url, index) => (
          <React.Fragment key={index}>
            <Grid item>
              <TextField
                fullWidth
                value={state.urls[index]}
                label="Paste your link here"
                name={`stay[urls][]`}
                onChange={(event) =>
                  dispatch({ type: 'setUrl', index: index, value: event.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <IconButton
                      data-test-id='removeUrl'
                      onClick={() => dispatch({ type: 'removeUrl', index: index })}
                    >
                      <RemoveCircleIcon className={classes.removeUrlButton} />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <StayPreview
              resourcePath={tripBatchStaysPath(tripId)}
              tripId={tripId}
              url={url}
            />
          </React.Fragment>
        ))}
        {state.urls.length < maxAllowedUrls && (
          <Grid item className={classes.addButtonContainer}>
            <TextButton detachedFromForm onClick={() => dispatch({ type: 'addUrl' })}>
              + Add another link
            </TextButton>
          </Grid>
        )}
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(BatchStayForm);
