import React, { useContext, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, TextField, Button, Stack, useMediaQuery } from '@mui/material';
import { GlobalContext, SegmentContext, TripSiteContext } from '../../../Document';
import { Done, Close } from '@mui/icons-material';
import { gql, useMutation } from "@apollo/client";
import { getOperationName } from "../../../../graphql/utilities";
import useSegment from "../../../../services/useSegment";
import Errors from "../../../shared/Errors";
import clsx from "clsx";
import { GUESTS_QUERY } from "../../../../graphql/queries/guests";
import { useGuestDismissed } from "../../../../services/segmentEvents/guestEvents";

type CreateGuestFormProps = {
  autoFocus?: boolean,
  onGuestFormClose?: () => void,
}

export const GUEST_CREATE = gql`
  mutation TripGuestCreate($tripId: ID!, $firstName: String!, $lastName: String) {
    tripGuestCreate(input: {
      tripId: $tripId,
      firstName: $firstName,
      lastName: $lastName,
    }) {
      tripGuest {
        firstName
        lastName
        id
      }
      errors
    }
  }
`

const useStyles = makeStyles(() => ({
  input: {
    minWidth: '115px',
    height: '24px',
    padding: '8px 12px',
  },
  mobileInput: {
    minWidth: '50px',
  },
  doneButton: {
    minWidth: '42px',
    width: '42px',
  },
  cancelButton: {
    minWidth: '42px',
    width: '42px',
    color: '#4A6176',
  },
}));

const CreateGuestForm : React.FC<CreateGuestFormProps> = ({
  autoFocus = true,
  onGuestFormClose,
}) => {
  const classes = useStyles();
  const { setSnackbar } = useContext(GlobalContext);
  const { tripId } = React.useContext(SegmentContext);
  const { tripGid } = React.useContext(TripSiteContext);
  const [errors, setErrors] = useState<string[]>([]);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const textRef = useRef<HTMLFormElement>();

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const { trackSegment } = useSegment();
  const GuestDismissedEvent = useGuestDismissed();

  const onCancelClick = () => {
    onGuestFormClose && onGuestFormClose()
    GuestDismissedEvent();
  };

  const [
    createGuestMutation,
    { loading },
  ] = useMutation(GUEST_CREATE, {refetchQueries: [getOperationName(GUESTS_QUERY)]});

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createGuestMutation({
      variables: {
        tripId: tripGid,
        firstName: firstName,
        lastName: lastName,
      },
      onCompleted: ({tripGuestCreate: {
        tripGuest,
        errors,
      }}) => {
        if(tripGuest && errors.length === 0) {
          setSnackbar && setSnackbar('Your guest has been added.')
          setFirstName('')
          setLastName('')
          trackSegment('Guest Traveler Added',
            {
              tripId: tripId,
              firstName: firstName,
            },
          );
          textRef.current.focus();
        } else {
          setErrors(errors);
        }
      },
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <form onSubmit={onFormSubmit} data-test-id="create-guest-form">
          <Stack direction="row" spacing={1}>
            <TextField
              aria-label='First name'
              id="guest-first-name"
              variant="outlined"
              name="first-name"
              placeholder="First name*"
              autoFocus={autoFocus}
              inputRef={textRef}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              inputProps={{className: clsx(classes.input, {
                  [classes.mobileInput]: isMobile,
                })}}
              required
            />
            <TextField
              id="guest-last-name"
              variant="outlined"
              name="last-name"
              placeholder="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              inputProps={{className: classes.input}}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={!firstName || loading}
              className={classes.doneButton}
              data-test-id="submit-guest"
            >
              <Done/>
            </Button>
            <Button
              className={classes.cancelButton}
              data-test-id="cancel-guest"
              onClick={() => {
                setFirstName('');
                setLastName('');
                onCancelClick();
              }}
            >
              <Close />
            </Button>
          </Stack>
        </form>
        {errors && (
          <Grid pt={2} item>
            <Errors errors={errors} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateGuestForm;
