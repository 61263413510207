import { gql } from "@apollo/client";

export const GUESTS_QUERY = gql`
  query fetchGuestsByTripId($tripId: ID!) {
    guests(tripId: $tripId) {
      id
      displayName
      uuid
    }
  }
`;

export const TRIP_GUEST_DELETE = gql`
  mutation TripGuestDelete($additionalTravelerId: ID!) {
    tripGuestDelete(input: {
      tripGuestId: $additionalTravelerId,
    }) {
      errors
    }
  }
`