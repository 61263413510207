import React, {FunctionComponent, useContext} from "react";
import {
  Dialog as DialogBase,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
  Slide,
  useMediaQuery,
  SlideProps,
  Divider,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, useTheme } from "@mui/styles";
import TextButton from "../../../../buttons/TextButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { track } from "../../../../../services/analytics";
import { ItineraryEventType } from "../../../../shared/TripObjectTypes";
import { SegmentContext } from "../../../../Document";

export type DialogProps = {
  actions?: React.ReactNode,
  children?: React.ReactNode,
  fullWidth?: boolean,
  isOpen: boolean,
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl",
  message: string | React.ReactNode,
  onClose?: () => void,
  onConfirm?: () => void,
  position?: 'center' | 'bottom',
  transition?: React.ForwardRefExoticComponent<SlideProps>,
  title?: string,
  imgUrl: string,
  eventObjectPath: string,
  itineraryEvent: ItineraryEventType,
};

const useStyles: () => any = makeStyles((theme): {} => ({
  modalFooter: {
    backgroundColor: '#4A6176',
    height: '50px',
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
    border: '2px solid #BEC9D4',
    color: '#505050',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#EFF2F4',
    },
  },
  dialogActions: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    paddingTop: [[theme.spacing(1)], '!important'],
  },
  message: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    paddingBottom: [[theme.spacing(2)], '!important'],
    paddingTop: [[theme.spacing(2)], '!important'],
  },
  messageBody: {
    margin: 0,
  },
  img: {
    maxHeight: 209,
    maxWidth: 530,
    objectFit: 'cover',
  },
  forward: {
    top: '4px',
    position: 'relative',
    color: ['#5C7993', '!important'],
  },
  footerMessage: {
    textDecoration: 'none',
    color: ['white', '!important'],
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ItineraryDialogModal = ({children, ...props}: DialogProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down(445));
  const position = isMobile ? 'bottom' : 'center'
  const transition = position === 'bottom' ? Transition : undefined;

  return (
    <Dialog
      position={position}
      transition={transition}
      {...props}
    >
      {children}
    </Dialog>
  );
};

const Dialog = ({
  actions,
  isOpen,
  message,
  onClose,
  onConfirm,
  position = 'center',
  title,
  transition,
  children,
  imgUrl,
  eventObjectPath,
  itineraryEvent,
  ...dialogBaseProps
}: DialogProps) => {
  const classes = useStyles();
  const { isPlanner } = useContext(SegmentContext);
  const footerText = `View full ${itineraryEvent.schedulableType.toLowerCase()} page`

  const closeDialog = () => {
    if (onClose) {
      onClose();
    }
  };

  const CloseDialogButton = () => (
    <IconButton
      className={classes.closeIcon}
      data-test-id="closeDialogButton"
      onClick={closeDialog}
    >
      <CloseIcon />
    </IconButton>
  );
  const trackViewFullPageClicked = () => {
    track('View Full Page Clicked',
      {
        tripId: itineraryEvent.tripId,
        planner: isPlanner,
        objectId: itineraryEvent.schedulableId,
        type: itineraryEvent.schedulableType,
      },
    )
  };


  return (
    <DialogBase
      classes={{ paper: classes[position] }}
      open={isOpen}
      onClose={closeDialog}
      TransitionComponent={transition}
      {...dialogBaseProps}
      data-test-id="itinerary-dialog-modal"
    >
      <img src={imgUrl} className={classes.img}/>
      <CloseDialogButton />
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <DialogContent className={classes.message}>
        {typeof message === 'string' ? (
          <DialogContentText className={classes.messageBody}>
            {message}
          </DialogContentText>
        ) : (
          message
        )}
      </DialogContent>
      {actions && (
        <DialogActions className={classes.dialogActions}>
          {actions}
        </DialogActions>
      )}
      {children}
      <Grid
        container
        item
        className={classes.modalFooter}
        data-test-id="itineraryModalPermapageLink"
        alignItems="center"
        justifyContent="center"
        onClick={trackViewFullPageClicked}
      >
        <Grid item>
          <TextButton
            className={classes.footerMessage}
            href={eventObjectPath}
          >
            {footerText}
          </TextButton>
        </Grid>
        <Grid item className={classes.forward}>
          <ArrowForwardIosIcon />
        </Grid>
      </Grid>
    </DialogBase>
  );
};

export default Dialog;
