import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from "@mui/styles/makeStyles"
import Button from "../../../../buttons/Button";
import getMostPopularDateOptions from "../../../../../services/getMostPopularDateOptions";
import DateOption from "../../../../shared/DateOption";

type VotingResultsButtonProps = {
  dateOptions: any[],
  className?: string,
};

const useStyles = makeStyles((theme) => ({
  votingResultsButton: {
    marginBottom: theme.spacing(2),
  },
  dateOption: {
    marginBottom: theme.spacing(2),
  },
  addPadding: {
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
  },
}))

const VotingResultsButton = ({
  className,
  dateOptions
}: VotingResultsButtonProps) => {
  const classes = useStyles();
  const [showDateOptions, setShowDateOptions] = useState(false);
  const mostPopularOptionsIds = getMostPopularDateOptions(dateOptions).map(option => option.id);

  return (
    <>
      <Button
        className={className}
        fullWidth
        onClick={() => setShowDateOptions(!showDateOptions)}
        variant="tertiary"
      >
        {showDateOptions ? "Hide" : "Show"} voting results
      </Button>
      {showDateOptions && dateOptions.map((dateOption) => (
        <div className={clsx("anchor", classes.addPadding)} key={dateOption.id}>
          <a id={`date_option_${dateOption.id}`} />
          <DateOption
            className={classes.dateOption}
            endDate={dateOption.endDate}
            endDateWeekday={dateOption.endDateWeekday}
            endDateYear={dateOption.endDateYear}
            isMostPopular={mostPopularOptionsIds.includes(dateOption.id)}
            key={dateOption.id}
            linkPath={dateOption.tripObjectPath}
            numNights={dateOption.numNights}
            startDate={dateOption.startDate}
            startDateWeekday={dateOption.startDateWeekday}
            startDateYear={dateOption.startDateYear}
            travelersVotes={dateOption.travelersVotes}
          />
        </div>
      ))}
    </>
  );
};

export default VotingResultsButton;
