import React from "react"
import { Grid, Typography } from "@mui/material"
import Button from "../../../../../buttons/Button";
import { makeStyles } from "@mui/styles";
import StaysMapView from '../MapView/StaysMapView';
import { StayType } from "../../../../../shared/TripObjectTypes";
import useExpandMapEventRef from "../../../../../../services/segmentEvents/useExpandMapEventRef";

type StaysMapSectionProps = {
  stays: StayType[],
  onExpandMapClick: () => void,
};

const useStyles = makeStyles( theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  header: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  mapWrapper: {
    width: '100%',
    height: `calc(80vh - ${theme.spacing(8)})`,
    left: 0,
  },
}));

const StaysMapSection = ({
  stays,
  onExpandMapClick,
} : StaysMapSectionProps ) => {
  const classes = useStyles();

  const expandMapButtonRef = useExpandMapEventRef('stays', 'expand map')

  return(
    <Grid container direction="column" spacing={2} className={classes.root}>
      <Grid item>
        <Typography className={classes.header}>
          Map view
        </Typography>
      </Grid>
      <Grid item className={classes.mapWrapper} data-test-id="sideBarMap">
        <StaysMapView stays={stays} />
      </Grid>
      <Grid item>
        <Button
          data-test-id='expand-map'
          variant="secondary"
          fullWidth
          onClick={onExpandMapClick}
          ref={expandMapButtonRef}
        >
          Expand map
        </Button>
      </Grid>
    </Grid>
  )
};

export default StaysMapSection;
