import React, { useCallback } from "react";
import { Grid, Box, Divider } from "@mui/material";
import FilteringAndSortingBar from "../shared/FilteringAndSortingBar";
import Button from "../../../../buttons/Button";
import makeStyles from "@mui/styles/makeStyles";
import DestinationsList from "./DestinationsList"
import PhasingStepper from "../shared/PhasingStepper";
import SectionHeader from "../shared/SectionHeader";
import CurrentSortingAndCount from "../shared/CurrentSortingAndCount";
import NoSuggestionsYet from "../../../../shared/NoSuggestionsYet";
import Tooltip from "../../../../shared/Tooltip";
import { DestinationType } from "../../../../shared/TripObjectTypes"
import useMediaQuery from "@mui/material/useMediaQuery";
import * as routes from '../../../../../routes';
import { useTheme } from "@mui/styles";
import { trackLink as trackAnalyticsEvent } from '../../../../../services/analytics';
import LastUpdateFilterLink from "../../../../shared/LastUpdateFilterLink";
import { usePhasingContext } from "../../../../../services/phasing";
import { TravelerProps } from '../../../../shared/TravelersVotingBox';
import ActionCallout from "../SuggestedDatesPage/ActionCallout";
import { TripSiteContext } from "../../../../Document";
import ActivityAd, { AdContent } from "../../../../shared/ActivityAd";

export type SortingType = {
  label: string,
  sortBy: string,
};

type MainContentProps = {
  activityAd: AdContent,
  currentSorting: string,
  currentSortingLabel: string,
  destinations: DestinationType[],
  isPlanner: boolean,
  travelers: TravelerProps[],
  lastEventUpdatedAt: number,
  sortings: SortingType[],
  tripId: string,
  userHasCompletedVoting: boolean,
};

const {
  newTripDestinationPath,
  tripDestinationsPath,
} = routes;

const useStyles = makeStyles((theme) => ({
  filters: {
    marginBottom: theme.spacing(3),
  },
}));

const MainContent = ({
  activityAd,
  currentSorting,
  currentSortingLabel,
  destinations,
  isPlanner,
  lastEventUpdatedAt,
  sortings,
  travelers,
  tripId,
  userHasCompletedVoting,
}: MainContentProps) => {
  const { currentUserId } = React.useContext(TripSiteContext);
  const classes = useStyles();
  const { areDestinationsInSuggestingPhase, areDestinationsInVotingPhase, destinationsState } = usePhasingContext();
  const noSuggestions = destinations.length === 0
  const addDestinationText = useMediaQuery(useTheme().breakpoints.down(470)) ? '+ Add' : '+ Add destination'
  const note = areDestinationsInVotingPhase() ? 'Vote for your favorite options from the list below.' : `Suggest new destinations using the “${addDestinationText}” button below.`;
  const isSuggestingEnabled = areDestinationsInSuggestingPhase();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const renderActionCallout = () => {
    const thisTraveler = travelers.find((traveler) => traveler.userId === currentUserId);
    if (!thisTraveler) return null;
    const showActionCallout = areDestinationsInVotingPhase() && !userHasCompletedVoting && thisTraveler.destinationMissingVotesCount > 0;
    return showActionCallout && (
      <>
        <Box my={2}><Divider /></Box>
        <ActionCallout
          currentUserAvatarUrl={thisTraveler.avatarUrl}
          missingVotesCount={thisTraveler.destinationMissingVotesCount}
        />
      </>
    )};

  const addDestinationRef = useCallback((el) => {
      trackAnalyticsEvent(
        el,
        'Add Destination Form Opened',
        {
          category: 'Destinations',
          buttonClicked: '"Add destinations" in Destinations',
          tripId: tripId,
        },
      );
  }, []);

  return <>
    <SectionHeader
      title="Destinations"
      note={note}
      rightElement={ lastEventUpdatedAt && (
        <LastUpdateFilterLink
          date={lastEventUpdatedAt}
          tripId={tripId}
          tripObjectType="Destination"
        />
      )}
    />
    {isMobile && activityAd && (
      <ActivityAd
        content={activityAd}
        small
        vertical="Destinations"
      />
    )}
    <PhasingStepper currentPhase={destinationsState} tripObjectType='destinations' />
    {renderActionCallout()}
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.filters}
    >
      <Grid item>
        <FilteringAndSortingBar
          currentSorting={currentSorting}
          disabled={destinations.length === 0}
          formPath={tripDestinationsPath(tripId)}
          sortByRadioGroupName="destination_filter[sort_by]"
          vertical="Destinations"
          sortings={sortings}
        />
      </Grid>
      <Tooltip title={isSuggestingEnabled ? '' : 'Suggestions can’t be made once voting has begun'}>
        <Grid item>
          <Button
            data-test-id="add-button"
            disabled={!isSuggestingEnabled}
            href={newTripDestinationPath(tripId)}
            variant="primary"
            ref={(el) => addDestinationRef(el)}
          >
            {addDestinationText}
          </Button>
        </Grid>
      </Tooltip>
    </Grid>
    {!noSuggestions && (
      <CurrentSortingAndCount
        count={destinations.length}
        sorting={currentSortingLabel}
        type="destination"
      />
    )}
    <DestinationsList
      destinations={destinations}
      isPlanner={isPlanner}
      travelers={travelers}
      tripId={tripId}
    />
    {noSuggestions && (
      <NoSuggestionsYet
        heading="Next steps"
        listItems={['Add destinations', 'Get your group to vote', 'Finalize and move to stays']}
        illustration='globe'
      />
     )}
  </>;
};

export default MainContent;
