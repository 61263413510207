import React from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Errors from '../../../shared/Errors';
import ModalForm from '../../../shared/ModalForm';
import { hocRailsAction } from "../../../Document";
import { tripItineraryPath } from "../../../../routes";
import { default as DateRangePicker } from "../../../shared/CustomDateRangePicker";
import { FormContext } from "../../../shared/RailsForm";
import { ItineraryType } from "../../../shared/TripObjectTypes";
import ItineraryDatesChangeExplanation from "./ItineraryDatesChangeExplanation";
import ItineraryDatesReduction from "./ItineraryDatesReduction";
import { durationInDays } from "../../../../services/dates";

export type ItineraryFormProps = {
  itinerary?: ItineraryType,
  errors?: string[],
  tripId: string
};

type FormOption = {
  formMethod: 'patch',
  subtitle: string,
  title: string,
  labelText: string,
};

type FormOptions = {
  edit: FormOption
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
  },
}));

const ItineraryEditForm = ({
  itinerary,
  errors,
  tripId,
}: ItineraryFormProps) => {

  const classes = useStyles();

  const [ initialTotalDays ] = React.useState(durationInDays(itinerary?.startDate, itinerary?.endDate));
  const [switchChecked, setSwitchChecked] = React.useState(false);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setDates':
        return {...state,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          areLessDays: durationInDays(action.payload.startDate, action.payload.endDate) < initialTotalDays,
        };
      default:
        throw new Error();
    }
  };

  const initialState = {
    startDate: itinerary?.startDate,
    endDate: itinerary?.endDate,
    areLessDays: false,
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const onDatesChange = (dates) => {
    dispatch({type: 'setDates', payload: {startDate: dates[0], endDate: dates[1]}});
  };

  const itineraryFormOptions:FormOptions = {
    edit: {
      formMethod: 'patch',
      subtitle: 'Change your start and end dates to reflect updates to your trip’s itinerary.',
      title: 'Edit itinerary',
      labelText: 'What are your new dates?',
    },
  }

  const disableSubmit = () => (
    state.areLessDays && !switchChecked
  );

  return (
    <ModalForm
      closePath={tripItineraryPath(tripId)}
      formActionPath={tripItineraryPath(tripId)}
      formMethod={itineraryFormOptions['edit'].formMethod}
      saveButtonLabel='Save'
      subtitle={itineraryFormOptions['edit'].subtitle}
      title={itineraryFormOptions['edit'].title}
      withChangeDetection
      disabledSubmit={disableSubmit()}
      saveTooltipText={disableSubmit() && 'Confirm your changes before pressing save.'}
    >
      {errors && (
        <Box mb={2}>
          <Errors errors={errors} />
        </Box>
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography className={classes.label}>{itineraryFormOptions['edit'].labelText}</Typography>
        </Grid>
        <Grid item>
          <FormContext.Consumer>
            {({ onFormChange }) => (
              <DateRangePicker
                autoComplete="off"
                defaultEndValue={itinerary?.endDate}
                defaultStartValue={itinerary?.startDate}
                helperText={false}
                nameEnd="itinerary[end_date]"
                nameStart="itinerary[start_date]"
                onChange={dates => {
                  onDatesChange(dates);
                  onFormChange()
                }}
              />
            )}
          </FormContext.Consumer>
        </Grid>
        <>
          <Grid item>
            <ItineraryDatesChangeExplanation />
          </Grid>
          {state.areLessDays && (
            <Grid item>
              <input type="hidden" name="shorten_trip_toggled" value={switchChecked.toString()} />
              <ItineraryDatesReduction
                switchChecked={switchChecked}
                onSwitchClick={e => setSwitchChecked(e.target.checked)} />
            </Grid>
          )}
        </>
      </Grid>
    </ModalForm>
  );
};

export default hocRailsAction(ItineraryEditForm);
