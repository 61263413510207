import React from "react";
import {
  Box,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TripUpdatesButton from "./HeaderBar/TripUpdatesButton";
import IntercomButton from "./HeaderBar/IntercomButton";
import LogInButtonForm from "./LogInButtonForm";
import NavBarMenu from "./NavBarMenu";
import BroadcastButton from "./HeaderBar/BroadcastButton";

type HeaderBarProps = {
  tripUpdatesNum?: number,
  broadcastNum?: number,
  currentUserAvatarUrl?: string,
  currentUserId?: string,
  hideMyTripsButton?: boolean,
  isTripUpdatesHidden?: boolean,
  isUserLoggedIn?: boolean,
  logoPath?: string,
  tripId?: string,
};

const useStyles = makeStyles((theme) => ({
  headerBar: {
    fontSize: 20,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    maxHeight: theme.spacing(8),
    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: `calc(env(safe-area-inset-top) + ${theme.spacing(2)})`,
      paddingRight: `calc(env(safe-area-inset-right) + ${theme.spacing(3)})`,
      paddingLeft: `calc(env(safe-area-inset-left) + ${theme.spacing(3)})`,
      maxHeight: `calc(env(safe-area-inset-top) + ${theme.spacing(8)})`,
    },
    backgroundColor: 'rgba(84, 93, 252, 0.97)',
    color: '#ffffff',
    boxShadow: '0 5px 15px 0 rgb(0 0 0 / 10%)',
  },
  logInButton: {
    background: '#545DFC',
    borderRadius: '6px',
    height: theme.spacing(6),
    paddingTop: parseInt(theme.spacing(1)) / 2,
    paddingBottom: parseInt(theme.spacing(1)) / 2,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: 16,
    verticalAlign: "middle",
    top: theme.spacing(-1),
  },
  logo: {
    background: 'url("/assets/logo_white.svg") no-repeat center center',
    backgroundSize: 'contain',
    textIndent: '-100em',
    overflow: 'hidden',
    width: parseInt(theme.spacing(4)) * 5,
    height: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      height: '20px',
      width: '125px',
      paddingTop: theme.spacing(4),
      marginLeft: theme.spacing(-1),
    },
    [theme.breakpoints.down(425)]: {
      backgroundImage: 'url("/assets/logo_octopus_white.svg")',
      width: 32,
    },
  },
  root: {
    position: 'fixed',
    width: '100%',
    zIndex: '1001',
  },
}));

const HeaderBar = ({
  tripUpdatesNum = 0,
  broadcastNum = 0,
  currentUserAvatarUrl,
  currentUserId,
  hideMyTripsButton = false,
  isTripUpdatesHidden = false,
  isUserLoggedIn,
  logoPath,
  tripId,
}: HeaderBarProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container justifyContent="space-between" className={classes.headerBar}>
        <Grid item>
          <Link href={logoPath} data-test-id='logo-link'>
            <Typography
              variant="h1"
              className={classes.logo}>
              Troupe
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          {isUserLoggedIn ? (
            <>
              {tripId && (
                <BroadcastButton
                  broadcastNum={broadcastNum}
                  currentUserId={currentUserId as string}
                  tripId={tripId}
                />
              )}
              {tripId && !isTripUpdatesHidden && (
                <TripUpdatesButton
                  tripUpdatesNum={tripUpdatesNum}
                  tripId={tripId}
                />
                )}
              <IntercomButton />
              <NavBarMenu
                currentUserAvatarUrl={currentUserAvatarUrl as string}
                hideMyTripsButton={hideMyTripsButton}
              />
            </>
          ) : (
            <LogInButtonForm className={classes.logInButton}/>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

HeaderBar.defaultProps = {
  logoPath: '/',
};

export default HeaderBar;
