import React from "react";
import ActivityGroup from "./ActivityGroup";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import { ActivityGroupType } from "../../../../shared/TripObjectTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  list: {
    width: '100%',
  },
}));

type ActivityGroupListProps = {
  activityGroupList: ActivityGroupType[],
};

const ActivityGroupList = ({
  activityGroupList,
}: ActivityGroupListProps) => {
  const classes = useStyles();

  return <>
    <Grid className={classes.root}>
      {activityGroupList.map((activityGroup) => (
        <ActivityGroup
          key={activityGroup.id}
          activityGroup={activityGroup}
         />
      ))}
    </Grid>
  </>;
};

export default ActivityGroupList;
