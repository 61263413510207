type TrophyType = "GoldTrophy" | "SilverTrophy" | "BronzeTrophy";
type LabelType = "1st Choice" | "2nd Choice" | "3rd Choice";
type ShortLabel = "1st" | "2nd" | "3rd";

type RankLabel = {
  icon: TrophyType,
  label: LabelType,
  shortLabel: ShortLabel,
  value: number,
}

export const rankLabels: RankLabel[] = [
  { icon: "GoldTrophy", label: "1st Choice", shortLabel: "1st", value: 0 },
  { icon: "SilverTrophy", label: "2nd Choice", shortLabel: "2nd", value: 1 },
  { icon: "BronzeTrophy", label: "3rd Choice", shortLabel: "3rd", value: 2 },
];

export default rankLabels;
