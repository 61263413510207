import React from 'react';
import Collapse from '@mui/material/Collapse';
import { Typography, Box } from '@mui/material';
import { makeStyles } from "@mui/styles";
import useSegment from "../../services/useSegment";
import {aiSearchType, CurrentAppliedFilters} from "../pages/stays_search/Listings/Filters";

type SeeMoreOrLessProps = {
  text: string,
  readMoreCharacterLimit?: number,
  showMoreText?: string,
  showLessText?: string,
  lineHeight?: number,
  aiSearchType: aiSearchType,
  currentAppliedFilters?: CurrentAppliedFilters,
};

const useStyles = makeStyles(() => ({
  seeMoreOrLessButton: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  ellipsisText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const SeeMoreOrLess : React.FC<SeeMoreOrLessProps> = ({
  showMoreText = 'See more',
  showLessText = 'See less',
  readMoreCharacterLimit = 150,
  text,
  aiSearchType,
  currentAppliedFilters,
}) => {
  const classes = useStyles();
  const truncateText = () => (
    text.slice(0, readMoreCharacterLimit) + (text.length > readMoreCharacterLimit ? '...' : '')
  );

  const [seeingMore, setSeeingMore] = React.useState(false);
  const [truncatedText, setTruncatedText] = React.useState(truncateText());
  const { trackSegment } = useSegment();
  const onSeeMoreOrLessClick = () => {
    const descriptionState = seeingMore ? 'Collapsed' : 'Expanded'
    setSeeingMore(!seeingMore)
    trackSegment(`Troupie Description ${descriptionState}`,
      {
        location: aiSearchType,
        destination: currentAppliedFilters?.locationName,
        latitude: currentAppliedFilters?.latitude,
        longitude: currentAppliedFilters?.longitude,
      },
    )
  }

  React.useEffect(() => {
    if(!seeingMore){
      setTimeout(() => {
        setTruncatedText(truncateText())
      }, 300)
    } else {
      setTruncatedText(text)
    }
  }, [seeingMore])

  return (
    <Box>
      <Collapse in={seeingMore} collapsedSize={50}>
        <Typography>
          {truncatedText}
        </Typography>
      </Collapse>
      <Typography
        onClick={() => onSeeMoreOrLessClick()}
        className={classes.seeMoreOrLessButton}
        data-test-id="see-more-or-less-button"
      >
        {seeingMore ? showLessText : showMoreText}
      </Typography>
    </Box>
  );
}

export default SeeMoreOrLess;
