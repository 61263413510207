import React from "react";
import SideBarPage from "./SideBarPage";
import SkeletonList from './SkeletonPage/SkeletonList';

const SkeletonPage = ({}) => {
  const SkeletonItems = 3;

  return <>
      <SideBarPage
        data-test-id='skeleton-page'
        mainContent={<SkeletonList itemLength={SkeletonItems} />}
      />
  </>
}

export default SkeletonPage;
