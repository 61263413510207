import React, { useContext } from "react";
import { TripSiteContext } from "../../../../../Document";
import TripObjectLink from "../../../../../shared/TripObjectLink";
import useSegment from "../../../../../../services/useSegment";

type StayDetailsProps = {
  page: string,
  provider: string,
  publicUrl: string,
};

const StayDetails = ({
  page,
  provider,
  publicUrl,
}: StayDetailsProps) => {
  const linkText = () => {
    switch (provider) {
      case 'VRBO':
        return 'View on Vrbo';
      case 'EXPEDIA':
        return 'View on Expedia';
      default:
        return 'View on source';
    }
  };
  const { trackSegment } = useSegment();
  const { staysState } = useContext(TripSiteContext);

  const onClick = () => {
    trackSegment(
      'View Source Link Clicked',
      {
        page,
        source: provider,
        stayVerticalStatus: staysState,
        listingURL: publicUrl,
      },
    )
  };

  return (
    <TripObjectLink
      url={publicUrl}
      linkText={linkText()}
      dataTestId='stay-details-link'
      onClick={onClick}
   />
  )
};

export default StayDetails;
