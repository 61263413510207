import React from "react";
import { Grid, Typography, Divider, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RichText from "../../../shared/RichText";
import moment from "moment/moment";
import { StayBookingType } from "../../../shared/TripObjectTypes";
import Avatar from "../../../avatars/Avatar";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StayBookingActions from "../../stays/StayBookingActions";
import { NumericFormat } from 'react-number-format';

export type StayBookingDetailsProps = {
  booking: StayBookingType,
  stayId?: string,
  tripId?: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: `-${theme.spacing(1)} !important`,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  note: {
    fontSize: 16,
    paddingBottom: theme.spacing(2),
  },
  confirmationCode: {
    color: '#272727',
    fontWeight: 600,
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  confirmationCodeText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  blueTitle: {
    fontFamily: 'Poppins',
    color:' #545DFC',
    fontWeight: 600,
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  headerText: {
    fontWeight: 400,
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: '#272727',
  },
  subText: {
    fontWeight: 400,
    fontSize: '14px',
    color: theme.palette.general.gray,
  },
  bookText: {
    fontWeight: 400,
    fontSize: '16px',
    margin: "auto 0",
  },
  greenCheck: {
    top: '4px',
    position: 'relative',
    marginRight: '8px',
    color: '#1EBE95',
  },
}));

const StayBookingDetails = ({
  booking,
  stayId,
  tripId,
}: StayBookingDetailsProps) => {
  const classes = useStyles();
  const formatDate = (date) => moment(date, "YYYY-MM-DD").format("ddd, MMM Do");
  const formatTime = (time) => moment(time, "YYYY-MM-DD HH:mm:ss").format("h:mm A");
  const showRefundable = ["refundable", "non_refundable"].includes(booking.refundability);
  const showCostandRefundable = booking.totalCost || showRefundable
  const refundability = {
    'non_refundable': 'Non-refundable',
    'refundable': `Refundable until ${moment((booking?.refundableUntil), "YYYY-MM-DD").format("MMM Do")}`,
  }

  return(
    <Grid data-test-id="stay-booking-details" container spacing={1} className={classes.root}>
      <Grid item xs={12} mb={1} container direction="row" alignItems="center" justifyContent="space-between">
        <Typography className={classes.title}>
          <CheckCircleIcon className={classes.greenCheck} />{'Booking details'}
        </Typography>
        <Box minHeight="24px">
          <StayBookingActions
            stayBooking={booking}
            stayId={stayId}
            tripId={tripId}
          />
        </Box>
      </Grid>
      <Grid item xs={12} pb={1}>
        <Typography className={classes.confirmationCode}>
          Confirmation Code
        </Typography>
        <Typography className={classes.confirmationCodeText} data-test-id="confirmation-code-text">
          {booking.confirmationCode}
        </Typography>
      </Grid>
      <Grid container item xs={12} pb={2}>
        <Grid>
          <Typography className={classes.blueTitle}>
            Check-in
          </Typography>
          <Typography className={classes.headerText} data-test-id="check-in-date">
            {formatDate(booking.checkInDate)}
          </Typography>
          {booking.checkInTime && (
            <Typography className={classes.subText} data-test-id="check-in-time">
              From {formatTime(booking.checkInTime)}
            </Typography>
          )}
        </Grid>
        <Divider orientation={'vertical'} sx={{height: '100%', margin: '0px 16px 0px 16px'}} />
        <Grid>
          <Typography className={classes.blueTitle}>
            Check-out
          </Typography>
          <Typography className={classes.headerText} data-test-id="check-out-date">
            {formatDate(booking.checkOutDate)}
          </Typography>
          {booking.checkOutTime && (
            <Typography className={classes.subText} data-test-id="check-out-time">
              Until {formatTime(booking.checkOutTime)}
            </Typography>
          )}
        </Grid>
      </Grid>
      {showCostandRefundable && (
        <Grid item xs={12} pb={2}>
          {booking.totalCost && (
            <Grid>
              <Typography className={classes.blueTitle}>
                Total
              </Typography>
              <NumericFormat
                  data-test-id="total-cost"
                  value={booking.totalCost}
                  displayType={'text'}
                  decimalScale={2}
                  fixedDecimalScale={booking.totalCost % 1 !== 0}
                  thousandSeparator={true}
                  prefix={'$'}
                  className={classes.headerText}
              />
            </Grid>
          )}
          {showRefundable && (
            <Typography className={classes.subText} data-test-id="refundability">
              {refundability[booking.refundability]}
            </Typography>
          )}
        </Grid>
      )}
      {booking.notes && (
        <Grid item xs={12} pb={2}>
          <RichText value={booking.notes} />
        </Grid>
      )}
      <Grid item container xs={12} pb={2}>
        <Grid item pr={1} >
          <Avatar src={booking.author.avatarUrl} size='xs' />
        </Grid>
        <Typography className={classes.bookText}>
          Booked by <span style={{fontWeight: 600}}>{booking.author.displayName}</span>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default StayBookingDetails;
