import React, { useContext } from "react";
import { FormContext } from "../../shared/RailsForm";
import GooglePlacesAutocomplete, { Place } from "../../shared/GooglePlacesAutocomplete";
import useStateWithPromise from "../../../services/useStateWithPromise";

type DestinationFieldProps = {
  currentDestination?: string,
  currentGooglePlaceId?: string,
  currentGooglePlaceUrl?: string,
  currentImage?: string,
  currentLongitude?: number,
  currentLatitude?: number,
  onChange?: (place: Place) => void,
  required?: boolean,
};

const DestinationField = React.forwardRef(({
  currentDestination,
  currentGooglePlaceId,
  currentGooglePlaceUrl,
  currentImage,
  currentLongitude,
  currentLatitude,
  onChange,
  required,
}: DestinationFieldProps, externalInputRef) => {
  const { onFormChange } = useContext(FormContext);
  const [destination, setDestination] = useStateWithPromise({
    id: currentGooglePlaceId,
    image: currentImage,
    latitude: currentLatitude,
    longitude: currentLongitude,
    name: currentDestination,
    url: currentGooglePlaceUrl,
  });

  const destinationName = destination ? destination.name : '';
  const googlePlaceId = destination ? destination.id : '';
  const googlePlaceUrl = destination ? destination.url : '';
  const googlePlaceLocation = (destination && destination.longitude && destination.latitude) ? `POINT(${destination.longitude} ${destination.latitude})` : '';
  const googlePlaceImage = destination ? destination.image : '';

  const handleDestinationChange = (value: Place) => {
    setDestination(value).then(() => {
      onFormChange();
      if (onChange) onChange(value);
    });
  };

  return (
    <>
      <input
        type="hidden"
        value={destinationName || ""}
        name="destination[name]"
      />
      <input
        type="hidden"
        value={googlePlaceId || ""}
        name="destination[google_place_id]"
      />
      <input
        type="hidden"
        value={googlePlaceUrl || ""}
        name="destination[google_place_url]"
      />
      <input
        type="hidden"
        value={googlePlaceLocation || ""}
        name="destination[location]"
      />
      <input
        type="hidden"
        value={googlePlaceImage || ""}
        name="destination[original_image]"
      />
      <GooglePlacesAutocomplete
        formattedAddress
        defaultValue={currentDestination}
        fullWidth
        onSelect={(value) => handleDestinationChange(value)}
        ref={externalInputRef}
        required={required}
      />
    </>
  );
});

export default DestinationField;
