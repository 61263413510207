import React from "react";
import {
  Avatar,
  Box,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

export type ObjectAuthorProps = {
  authorName: string,
  avatarUrl: string,
};

const useStyles = makeStyles((theme) => ({
  authorName: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '32px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  avatar: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
  },
  root: {
    display: 'inline-flex',
    maxWidth: '100%',
    verticalAlign: 'middle',
  },
}));

const ObjectAuthor = ({
  authorName,
  avatarUrl,
}: ObjectAuthorProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Avatar src={avatarUrl} className={classes.avatar} />
      <Typography className={classes.authorName}>Added by {authorName}</Typography>
    </Box>
  );
};

export default ObjectAuthor;
