import ReactRailsUJS from 'react_ujs';
import 'core-js';
import 'regenerator-runtime/runtime';
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";

elementScrollIntoViewPolyfill()

const requireFromComponentsFolder = require.context("components", true);
ReactRailsUJS.getConstructor = (className) => { // based on https://github.com/reactjs/react-rails/blob/master/react_ujs/src/getConstructor/fromRequireContext.js
  const parts = className.split(".");
  const filename = parts.shift();
  let component = requireFromComponentsFolder("./" + filename)
  parts.forEach(function (k) { component = component[k] })
  if (component.__esModule) component = component["default"];
  return component;
};
