import React from "react";
import ButtonBase from "@mui/material/ButtonBase";

type ExternalPageLinkProps = {
  children?: React.ReactNode | null,
  component?: React.FC | null,
  href: string,
  className?: string,
}

const ExternalPageLink = ({ href, component, children, ...otherProps }: ExternalPageLinkProps) => {
  const RootComponent = component || ButtonBase;

  return (
    <RootComponent href={href} target="_blank" {...otherProps}>
      {children}
    </RootComponent>
  );
};

export default ExternalPageLink;
