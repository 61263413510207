import React from 'react';
import { DropdownIconButton } from '../DropdownButton';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RestoreIcon from '@mui/icons-material/Restore';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { ListItemText, MenuItem, MenuList } from '@mui/material';

type CameraDropdownButtonProps = {
  canRestoreDefault: boolean,
  className?: string,
  onChoosePhoto: () => void,
  onRestoreDefault: () => void,
};

const CameraDropdownButton = ({
  canRestoreDefault,
  className,
  onChoosePhoto,
  onRestoreDefault,
}: CameraDropdownButtonProps) => {
  return (
    <DropdownIconButton
      testId='camera-dropdown'
      className={className}
      menuList={
        <MenuList>
          <MenuItem
            data-test-id='choose-photo-option'
            onClick={() => onChoosePhoto()}>
            <UploadFileIcon fontSize="small" sx={{ mr: 1 }} />
            <ListItemText primary='Upload photo' />
          </MenuItem>
          <MenuItem
            data-test-id='restore-default-option'
            disabled={!canRestoreDefault}
            onClick={() => onRestoreDefault()}
          >
            <RestoreIcon fontSize="small" sx={{ mr: 1 }} />
            <ListItemText primary='Restore default' />
          </MenuItem>
        </MenuList>
      }
      icon={<CameraAltIcon/>}
    />
  );
};

export default CameraDropdownButton;
