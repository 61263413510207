import React from "react";
import clsx from "clsx";
import {
  Container,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type SectionHeaderProps = {
  hasNoMarginBottom?: boolean,
  heading: string,
  subheading: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.spacing(96),
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  heading: {
    background: '#fff',
    color: '#272727',
    display: 'inline-block',
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
  },
  subheading: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  noMargin: {
    marginBottom: theme.spacing(-3),
  },
}));

const SectionHeader = ({
  hasNoMarginBottom = false,
  heading,
  subheading,
}: SectionHeaderProps) => {
  const classes = useStyles();

  return (
    <Container className={classes.root} data-test-id="section-header">
      <Typography className={classes.heading}>
        {heading}
      </Typography>
      {subheading && (
        <Typography className={clsx(classes.subheading,
          {[classes.noMargin]: hasNoMarginBottom})}>
          {subheading}
        </Typography>
      )}
    </Container>
  )
};

export default SectionHeader;
