import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteResourceConfirmationDialog from "../DeleteResourceConfirmationDialog";
import Tooltip from "../Tooltip";

type DateOptionActionsProps = {
  dateOptionPath: string,
  disableDeleteButton?: boolean,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 12,
    right: 16,
    [theme.breakpoints.down('sm')]: {
      top: 8,
    },
  },
  button: {
    height: 30,
    [theme.breakpoints.down('sm')]: {
      height: 36,
    },
    '&:hover': {
      backgroundColor: '#F6F6F6',
      borderColor: '#545DFC',
      background: theme.palette.general.hover,
    },
  },
  buttonGroup: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #B4B9BC',
    color: theme.palette.text.primary,
  },
}));

const DateOptionActions = ({
  disableDeleteButton,
  dateOptionPath,
}: DateOptionActionsProps) => {
  const classes = useStyles();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const tooltipTitle = disableDeleteButton ? 'You cannot delete finalized dates' : '';

  return (
    <Box className={classes.root}>
      <ButtonGroup
        classes={{
          groupedOutlined: classes.buttonGroup,
        }}
        disableElevation
        variant="outlined"
        size="small"
      >
        <Tooltip
          data-test-id="disabled-delete"
          placement="bottom-start"
          title={tooltipTitle}
        >
          <Grid>
            <Button
              className={classes.button}
              data-test-id="deleteDateOptionButton"
              disabled={disableDeleteButton}
              onClick={() => setDeleteDialogOpen(true)}
            >
              <DeleteIcon fontSize="small" />
            </Button>
          </Grid>
        </Tooltip>
      </ButtonGroup>
      <DeleteResourceConfirmationDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        path={dateOptionPath}
        resourceName="dates"
      />
    </Box>
  );
}

export default DateOptionActions;
