import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextButton from "../../../../../buttons/TextButton";
import * as routes from '../../../../../../routes';

const {
  tripDatesPath,
  tripDestinationsPath,
  tripStaysPath,
} = routes;

const useStyles = makeStyles((theme) => ({
  goToButton: {
    textAlign: 'center',
    width: '100%',
  },
}));

type FinishedVotedTripEventLinkProps = {
  eventType: string,
  tripId: string,
};

const buttonAttrs = {
  finished_voted_date_options: {
    tripObjectListPath: (tripId) => {return tripDatesPath(tripId)},
    text: 'Go to Dates',
  },
  finished_voted_destination: {
    tripObjectListPath: (tripId) => {return tripDestinationsPath(tripId)},
    text: 'Go to Destinations',
  },
  finished_voted_stay: {
    tripObjectListPath: (tripId) => {return tripStaysPath(tripId)},
    text: 'Go to Stays',
  },
};

const FinishedVotedTripEventLink = ({
  eventType,
  tripId,
}: FinishedVotedTripEventLinkProps) => {
  const classes = useStyles();
  const tripObjectListPath = buttonAttrs[eventType]['tripObjectListPath'](tripId);
  const linkButtonText = buttonAttrs[eventType]['text'];

  return (<>
      <TextButton
        href={tripObjectListPath}
        className={classes.goToButton}
      >
        {linkButtonText}
      </TextButton>
  </>)
};

export default FinishedVotedTripEventLink;
