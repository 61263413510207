import React, { useCallback } from 'react';
import {
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material';
import * as routes from "../../../../../../routes";
import DropdownButton from "../../../../../shared/DropdownButton";
import Button from "../../../../../buttons/Button";
import { trackLink as trackAnalyticsEvent } from '../../../../../../services/analytics';
import CategoryIcon from '@mui/icons-material/Category';
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import LinkIcon from "@mui/icons-material/Link";

type AddActivityButtonProps = {
  disabled: boolean,
  tripId: string,
};

const {
  newTripActivityPath,
} = routes;

const AddActivityButton = ({ tripId, disabled }: AddActivityButtonProps) => {
  const options = [
    {
      dataTestID: "custom",
      href:`${newTripActivityPath(tripId)}?add_by=custom`,
      icon: <CategoryIcon/>,
      label: "Add custom",
      method: "Custom",
    },
    {
      dataTestID: "add-by-url",
      href: `${newTripActivityPath(tripId)}?add_by=url`,
      icon: <LinkIcon/>,
      label: "Add by URL",
      method: "URL",
    },
    {
      dataTestID: "add-by-address",
      href: `${newTripActivityPath(tripId)}?add_by=address`,
      icon: <RoomOutlinedIcon/>,
      label: "Add by Google Maps",
      method: "Google Places",
    },
  ];

  const refs = useCallback((element, option) => {
      trackAnalyticsEvent(
        element,
        'Add Activities Opened',
        {
          buttonClicked: '"Add activity" in Activities',
          category: 'Activities',
          method: option.method,
          tripId: tripId,
        },
      );
  }, []);

  if (disabled) return (<>
      <Button
        data-test-id="add-button"
        disabled={true}
        variant="primary"
      >
        {'+ Add Activity'}
      </Button>
    </>
  );

  return (
    <DropdownButton
      conciseLabel="+ Add"
      label="+ Add activity"
      testId="add-button"
      menuList={
        <MenuList>
          {options.map((option, index) => (
            <Link
              key={index}
              color="inherit"
              href={option.href}
              underline="none"
              data-test-id={option.dataTestID}
              ref={(el) => refs(el, option)}
            >
              <MenuItem
                tabIndex={2}
                key={index}
              >
                <ListItemIcon key={index}>
                  {option.icon}
                </ListItemIcon>
                <ListItemText primary={option.label}/>
              </MenuItem>
            </Link>
          ))}
        </MenuList>
      }
    />
  );
};

export default AddActivityButton;
