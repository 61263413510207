import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Card,
  Grid,
  Theme,
} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme) => ({
  skeletonCard: {
    width: ({ isMobile }) => isMobile ? '100%' : '84%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  textSkeleton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: '1 1 auto',
  },
}));

const SkeletonCard = ({}) => {
  const classes = useStyles({ isMobile: false });
  const screenSize = useMediaQuery(useTheme().breakpoints.down('md')) ? 'mobile' : 'desktop';

  const imageSkeletonProps = {
    desktop: {
      width: 240,
      height:380,
    },
    mobile: {
      width: 335,
      height:235,
    },
  };

  return <>
    <Card className={classes.skeletonCard}>
      <Grid
        container
        direction="row"
        justifyContent='space-between'
      >
        <Grid item>
          <Skeleton variant="rectangular" {...imageSkeletonProps[screenSize]} animation={'wave'} />
        </Grid>
        <Grid item className={classes.textSkeleton}>
          <Skeleton width={132} height={24} animation={'wave'} />
          <Skeleton height={48} animation={'wave'} />
          <Skeleton height={48} animation={'wave'} />
          <Skeleton height={24} animation={'wave'} />
        </Grid>
      </Grid>
    </Card>
  </>
}

export default SkeletonCard;
