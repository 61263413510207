import React from "react"
import {
  Box,
  Divider,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import AiSearchPrompt from "./AiSearchPrompt";
import { makeStyles } from "@mui/styles";
import { ListingsContext } from "../StaysSearch";
import AiSearchPromptDialog from "./AiSearchPromptDialog";

type AiSearchPromptSectionProps = {
  aiTripSearch: boolean,
  handlePromptSubmit: (prompt: string) => void,
  hasSearched: boolean,
  isLoading: boolean,
  isMobile: boolean,
  prompt: string,
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  question: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  answer: {
    color: '#979797',
    fontSize: 16,
  },
  promptExample: () => ({
    color: '#4A6176',
    fontSize: 14,
    cursor: 'pointer',
    textDecoration: 'none',
    borderRadius: '10px',
    padding: '15 15px',
    textAlign: 'left',
    paddingLeft: '15px',
    wordWrap: 'break-word',
    [theme.breakpoints.down(480)]: {
      width: '100%',
    },
    [theme.breakpoints.between(480, 740)]: {
      width: '450px',
    },
    [theme.breakpoints.up(740)]: {
      width: '350px',
    },
    height: '80px',
    display: 'flex',
    paddingTop: '8px',
    paddingBottom: '8px',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  info: {
    width: 350,
    paddingBottom: '8px',
    color: '#7c93a9',
  },
  muiIcon: {
    height: 16,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

const AiSearchPromptSection : React.FC<AiSearchPromptSectionProps> = ({
  aiTripSearch,
  handlePromptSubmit,
  hasSearched,
  isLoading,
  isMobile,
  prompt,
}) => {
  const classes = useStyles();
  const { onPromptExampleClick } = React.useContext(ListingsContext);

  const PromptExample = ({ prompt, baseColor }) => {
    return (
      <Grid item>
        <Link
          onClick={() => onPromptExampleClick(prompt)}
          className={classes.promptExample}
          sx={{border: `2px solid ${baseColor}, 0.8)`, backgroundColor: `${baseColor}, 0.1)`}}
          data-test-id='prompt-example'
        >
          “{prompt}”
          <Grid sx={{position: "relative", bottom: 16}}>
            <img src="/assets/Right-arrow2.svg" className={classes.muiIcon} />
          </Grid>
        </Link>
      </Grid>
    );
  };

  const InfoTile = ({ text }) => {
    return (
      <Grid display={"flex"} direction={"row"} className={classes.info}>
        <img src={'/assets/Vector-3.svg'} className={classes.muiIcon} />
        <Typography sx={{fontSize: '14px'}}>
          {text}
        </Typography>
      </Grid>
    );
  };

  return(
    <Box>
      { !prompt ? (
        <AiSearchPrompt
          prompt={prompt}
          onSubmit={(prompt) => handlePromptSubmit(prompt)}
          disabled={isLoading}
          aiTripSearch={aiTripSearch}
        />
      ) : (
        <AiSearchPromptDialog
          prompt={prompt}
          onSubmit={(prompt) => handlePromptSubmit(prompt)}
          disabled={isLoading}
          aiTripSearch={aiTripSearch}
        />
      ) }
      {
        !(isLoading || hasSearched) && aiTripSearch && (
          <Grid container direction={"column"} justifyContent="space-between" my={4}>
            <Grid item pr={isMobile ? 0 : 5} pb={isMobile ? 2 : 0} xs>
              <Typography className={classes.question}>
                <img src="/assets/question_answer.svg" className={classes.icon} />
                What can I ask?
              </Typography>
              <Grid container spacing={2} justifyContent={"space-evenly"} pb={2}>
                <PromptExample
                  prompt="Hotel under $300/night with AC and free cancellation policy"
                  baseColor="rgba(166, 230, 213"
                />
                <PromptExample
                  prompt="Help me find a cabin for my family reunion in Asheville, NC; there will be 10 of us."
                  baseColor="rgba(190, 218, 255"
                />
                <PromptExample
                  prompt="I'm planning bachelorette party and need to find a house with four bedrooms near Miami"
                  baseColor="rgba(219, 221, 255"
                />
                <PromptExample
                  prompt="All inclusive resort in Cancun thats also good for kids"
                  baseColor="rgba(255, 228, 180"
                />
              </Grid>
            {isMobile && <Divider orientation={ "horizontal"} flexItem />}
            </Grid>


            <Typography className={classes.question}>
              <img src="/assets/privacy_tip.svg" className={classes.icon} />
              Things to know
            </Typography>
            <Grid container spacing={2} justifyContent={"space-evenly"} pb={2}>
              <InfoTile
                text="Troupie currently provides recommended filters and location suggestions, explaining the reasons behind its choices."
              />
              <InfoTile
                text="For accurate pricing, Troupie requires your travel dates."
              />
              <InfoTile
                text="Troupie understands many languages but might not perfectly interpret or translate."
              />
              <InfoTile
                text="Troupie aims to help but may occasionally provide inaccurate information. Double-check with other sources before important planning decisions!"
              />
            </Grid>
          </Grid>
        )
      }
    </Box>
  )
}

export default AiSearchPromptSection;
