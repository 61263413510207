type ValueOf<T> = T[keyof T]
type KeyValuePair<T> = [keyof T, ValueOf<T>]
const filterObject: <O extends {[_: string]: any}>(
  obj: O,
  fn: <T>(kv: KeyValuePair<T>, index: number, array: KeyValuePair<T>[]) => boolean
) => O = (obj, fn) => (
  Object.fromEntries(Object.entries(obj).filter(fn)) as typeof obj
)

export default filterObject;
