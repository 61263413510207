import React from "react";
import {
  Box,
  Menu,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import HoverBorderIconButton from "../buttons/HoverBorderIconButton";

type BaseActionsMenuProps = {
  children: React.ReactNode,
  dialogs?: React.ReactNode,
  testId?: string,
  className?: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(-0.625),
    marginBottom: theme.spacing(-0.625),
  },
  icon: {
    color: '#334351',
  },
}));

const BaseActionsMenu : React.FC<BaseActionsMenuProps> = ({
  children,
  dialogs,
  testId,
  className,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <Box className={clsx(classes.root, className)} data-test-id={testId}>
      <HoverBorderIconButton
        onClick={(event) => setAnchorEl(event.target)}
        data-test-id='actionButton'
      >
        <MoreHorizIcon fontSize="large" className={classes.icon}/>
      </HoverBorderIconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        autoFocus={false}
      >
        {children}
      </Menu>
      {dialogs}
    </Box>
  );
};

export default BaseActionsMenu;
