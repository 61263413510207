// source https://stackoverflow.com/a/53637828/4280615
export default function truncateString(str: string, num: number): string {
  if (!str) {
    return str
  }
  if (str.length > num) {
    return str.slice(0, num) + "…";
  } else {
    return str;
  }
}
