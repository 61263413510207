import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ProfileFormSectionGrid from "./ProfileForm/ProfileFormSectionGrid";
import BasicInfoFields from "./ProfileForm/BasicInfoFields";
import NotificationsFields from "./ProfileForm/NotificationsFields";
import AvatarSection from "./ProfileForm/AvatarSection";
import { UserType } from "./ProfileForm/BasicInfoFields";
import Button from "../../../buttons/Button";
import DismissibleAlert from "../../../shared/DismissibleAlert";
import Errors from "../../../shared/Errors";
import FixedSaveBar from "../../../shared/FixedSaveBar";
import RailsForm from "../../../shared/RailsForm";
import DeleteConfirmationDialog from "../../../shared/DeleteConfirmationDialog";
import { accountPath } from "../../../../routes";

type ProfileFormPageProps = {
  isPushNotificationsEnabled?: boolean,
  isTemporaryUser?: boolean,
  updatePath: string,
  user: UserType,
  errors?: string[],
  fieldFocus?: string,
  successNotification?: string,
};

const useStyles = makeStyles((theme) => ({
  formDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  formContent: {
    padding: theme.spacing(2),
  },
}));

const ProfileForm = ({
  errors,
  fieldFocus,
  isPushNotificationsEnabled = false,
  isTemporaryUser = false,
  successNotification,
  updatePath,
  user,
}: ProfileFormPageProps) => {
  const classes = useStyles();
  const [disabledSmsSettings, setSmsSettingsState] = useState(!user.phoneNumber);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState<boolean>(false);

  return (
    <>
      <RailsForm
        action={updatePath}
        method="patch"
      >
        <AvatarSection user={user} />
      </RailsForm>
      <RailsForm
        action={updatePath}
        method="patch"
      >
        <Box className={classes.formContent}>
          <Grid
            container
            spacing={3}
            direction="column"
          >
            {successNotification && (
              <Grid item>
                <DismissibleAlert>
                  <> {successNotification} </>
                </DismissibleAlert>
              </Grid>
            )}
            {errors && (
              <Grid item>
                <Errors errors={errors} />
              </Grid>
            )}
            <Grid item>
              <Divider />
            </Grid>
            {!isTemporaryUser && (
              <ProfileFormSectionGrid title="Basic info" spacing={3} data-test-id="profileBasicInfo">
                <BasicInfoFields fieldFocus={fieldFocus} user={user} setSmsSettingsState={setSmsSettingsState} />
              </ProfileFormSectionGrid>
            )}
            <ProfileFormSectionGrid
              title="Notifications"
              subtitle="Decide how you get updated about all trips you’ve joined."
            >
              <NotificationsFields
                disabledSmsSettings={disabledSmsSettings}
                isPushNotificationsEnabled={isPushNotificationsEnabled}
                isTemporaryUser={isTemporaryUser}
                user={user}
              />
            </ProfileFormSectionGrid>
            {!isTemporaryUser && (
              <ProfileFormSectionGrid
                title="Delete account"
                subtitle="Delete your account and erase all personal data stored on Troupe."
                spacing={2}
              >
                <Grid item>
                  <Button
                    detachedFromForm
                    onClick={() => setDeleteAccountDialogOpen(true)}
                    variant="tertiary"
                  >
                    Delete my account
                  </Button>
                </Grid>
              </ProfileFormSectionGrid>
            )}
          </Grid>
        </Box>
        <FixedSaveBar
          data-test-id="submit-profile-form"
          fullWidth
          buttonContent="Save"
          saveButtonType="submit"
        />
      </RailsForm>
      <DeleteConfirmationDialog
        confirmationToggleText="Yes, delete my account"
        fullWidthButtons
        isOpen={deleteAccountDialogOpen}
        message="Deleting your account will remove all trips you’ve created and associated data permanently. You cannot undo this."
        onClose={() => setDeleteAccountDialogOpen(false)}
        path={accountPath()}
        title="Delete account?"
      />
    </>
  );
};

export default ProfileForm;
