import React, { useCallback } from "react";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { trackLink as trackAnalyticsEvent } from '../../services/analytics';

export type CustomChipProps = ButtonBaseProps & {
  active?: boolean,
  href?: string,
  eventName?: string,
  eventProperties?: object,
  trackEventDisabled?: boolean,
};

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: '#EEEFFF !important',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
  },
  root: {
    backgroundColor: 'rgba(242, 242, 242, 0.75)',
    borderRadius: theme.spacing(4),
    color: '#243060',
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    fontWeight: 500,
    height: theme.spacing(5),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1.25),
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#EEEFFF',
    },
  },
}));

const CustomChip = ({
  active = false,
  eventName,
  eventProperties,
  trackEventDisabled = false,
  ...buttonProps
}: CustomChipProps) => {
  const classes = useStyles();

  const refs = useCallback((element) => {
    if(trackEventDisabled) return null;

    if(element !== null) {
      trackAnalyticsEvent(
        element,
        eventName,
        eventProperties,
      );
    }
  }, []);

  return (
    <ButtonBase
      {...buttonProps}
      className={clsx(
        classes.root,
        {
          [classes.active]: active,
        },
      )}
      ref={(element) => refs(element)}
    />
  );
};

export default CustomChip;
