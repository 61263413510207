const favoritesStorage = () => {
  const getFavoriteId = () => {
    return localStorage.getItem('favorite_id');
  };

  const setFavoriteId = (favoriteId) => {
    return localStorage.setItem('favorite_id', favoriteId);
  }

  return {
    getFavoriteId,
    setFavoriteId,
  }
};

export default favoritesStorage;
