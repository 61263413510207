import React from "react";
import {
  Grid,
  Link,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TimeStamp from "../../../../shared/TimeStamp";
import * as routes from '../../../../../routes';
import { TripNote } from '../NotesListPage';

type NotesListPageProps = {
  note: TripNote,
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    '&:hover': {
      color: theme.palette.link.default,
    }
  },
  updatedAt: {
    color: '#334351',
    display: 'inline',
    fontWeight: 400,
    fontSize: '16px',
    fontStyle: 'italic',
    lineHeight: '24px',
  },
}));

const {
  tripNotePath,
} = routes;

const NoteRow = ({
  note,
}: NotesListPageProps) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" data-test-id='trip-note'>
      <Grid item>
        <Link
          className={classes.root}
          href={tripNotePath(note.tripId, note.id)}
          underline="none"
        >
          {note.title}
        </Link>
      </Grid>
      <Grid item>
        <Typography className={classes.updatedAt}>
          Last updated <TimeStamp date={note.updatedAt} className={classes.updatedAt} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoteRow;
