import React from "react";
import { Hidden } from "@mui/material";
import MapIcon from '@mui/icons-material/Map';
import FloatingButton from "../../buttons/FloatingButton";
import useExpandMapEventRef from "../../../services/segmentEvents/useExpandMapEventRef";

type MobileMapButtonProps = {
  onClick: () => void,
  source: string,
};

const MobileMapButton = ({
  onClick,
  source,
}: MobileMapButtonProps) => {
  const expandMapButtonRef = useExpandMapEventRef(source, 'map');

  return (
    <Hidden mdUp>
      <FloatingButton onClick={onClick} ref={expandMapButtonRef} >
        <MapIcon /> Map
      </FloatingButton>
    </Hidden>
  );
};

export default MobileMapButton;
