import React, { useState } from "react";
import StayMarker from "./StayMarker";
import MapView from "../../../../../shared/MapView/MapView";
import { updatePosition } from "../../../../../../services/mapPosition";
import { StayType } from "../../../../../shared/TripObjectTypes";

type StaysMapViewProps = {
  fullscreen?: boolean,
  stays: StayType[],
};

const StaysMapView = ({
  stays,
  fullscreen = false,
}: StaysMapViewProps) => {
  const markers = stays?.filter((stay) => stay.longitude && stay.latitude) || [];
  const [activeMarker, setActiveMarker] = useState(null);

  const handleMarkerClick = (index, lat, lng, map) => {
    setActiveMarker(index);
    updatePosition(lat, lng, map);
    map.setOptions({ disableDoubleClickZoom: true });
  };

  const handleClickOut = (map) => {
    setActiveMarker(null);
    map.setOptions({ disableDoubleClickZoom: false });
  };

  return <>
    {markers.length > 0 && (
      <MapView
        markers={markers}
        renderMarker={(map, stay) =>
          <StayMarker
            stay={stay}
            active={activeMarker === stay.id}
            key={stay.id}
            lat={stay.latitude}
            lng={stay.longitude}
            onMarkerClick={() => handleMarkerClick(stay.id, stay.latitude, stay.longitude, map)}
            onMarkerClickOut={() => handleClickOut(map)}
            variant={fullscreen ? "mini" : "nano"}
          />
        }
      />
    )}
  </>;
};

export default StaysMapView;
