import React from "react";
import withLayout from "./withLayout";
import {
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Divider from "../../../shared/Divider";
import SideBarPage from "../../../shared/SideBarPage";
import TravelersVotingBox, { TravelerProps } from "../../../shared/TravelersVotingBox";
import SectionHeader from "./shared/SectionHeader";
import ClosedPollsSection from "./PollsPage/ClosedPollsSection";
import OpenedPollsSection from "./PollsPage/OpenedPollsSection";
import { Poll, PollState } from './PollsPage/PollView/PollTypes';
import { GlobalTrip } from "../../../shared/TripTypes";

type PollsPageProps = {
  polls: Array<Poll>,
  travelersVoting: Array<TravelerProps>,
  trip: GlobalTrip & {
    maxOpenPollCount: number,
  },
}

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const PollsPage = ({
  trip,
  polls,
  travelersVoting,
}: PollsPageProps) => {
  const classes = useStyles();
  const openedPolls = polls.filter(poll => poll.state === PollState.Open);
  const closedPolls = polls.filter(poll => poll.state === PollState.Closed);
  const openedPollCount = openedPolls.length;
  const hasOpenedPoll = openedPollCount > 0;
  const sectionHeaderNote = 'Create a poll to help your group narrow down options or answer key questions.';
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const travelersVotingBox = () => {
    return (
      <TravelersVotingBox
        isEmptyState={!hasOpenedPoll}
        travelers={travelersVoting}
        tripId={trip.id}
        verticalType='polls'
      />
    );
  };

  const sideBarContent = () => {
    if (isMobile) return;

    return (
      <Grid container direction="column">
        <Grid item>
          {travelersVotingBox()}
        </Grid>
      </Grid>
    );
  };

  return (
    <SideBarPage
      mainContent={
        <>
          {isMobile && <>
            <Box mb={3}><Divider /></Box>
            {travelersVotingBox()}
            <Box my={3}><Divider /></Box>
          </>}
          <SectionHeader title="Polls" note={sectionHeaderNote} />
          <OpenedPollsSection
            polls={openedPolls}
            maxOpenPollCount={trip.maxOpenPollCount}
          />
          <Divider className={classes.divider} />
          <ClosedPollsSection polls={closedPolls} />
        </>
      }
      sideBarContent={sideBarContent()}
    />
  );
};

export default withLayout(PollsPage, { fluid: true });
