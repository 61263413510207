import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import axios from 'axios';
import withLayout from "./withLayout";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import pluralize from 'pluralize';
import { FormsContext, TripSiteContext } from "../../../Document";
import Divider from "../../../shared/Divider";
import NoSuggestionsYet from "../../../shared/NoSuggestionsYet";
import Button from "../../../buttons/Button";
import { newTripBroadcastPath, tripBroadcastsPath } from "../../../../routes";
import { trackLink as trackAnalyticsEvent } from '../../../../services/analytics';
import { TripType } from "../../../shared/TripTypes";
import BroadcastView, { Broadcast } from "./BroadcastsPage/BroadcastView";
import FilteringAndSortingBar from './shared/FilteringAndSortingBar'
import MostRecentCommentBox from "./shared/MostRecentCommentBox";
import SectionHeader from './shared/SectionHeader'
import * as routes from '../../../../routes';
import Tooltip from "../../../shared/Tooltip";
import * as Sentry from "@sentry/browser";

const {
  tripUserBroadcastViewLogsPath,
} = routes;

type BroadcastPageProps = {
  currentUserId: string,
  trip: TripType,
  broadcasts: Array<Broadcast>,
  currentSorting: string,
  currentSortingLabel: string,
  sortings: Array<{
    label: string,
    sortBy: string,
  }>,
};

const useStyles = makeStyles((theme) => ({
  sortText: {
    fontWeight: 'normal',
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
}));

const BroadcastPage = ({
  broadcasts,
  currentUserId,
  currentSorting,
  currentSortingLabel,
  sortings,
  trip,
  trip: {
    isPlanner,
  },
}: BroadcastPageProps) => {
  const classes = useStyles();
  const { csrfToken } = useContext(FormsContext);

  const initialValue = Array(broadcasts.length).fill(false);
  const [
    showCommentForm,
    setShowCommentForm,
  ] = useState(initialValue);

  const toggleCommentFormVisibility = (index) => {
    const newArray = Array.from(showCommentForm);
    newArray[index] = !showCommentForm[index];
    setShowCommentForm(newArray);
  };

  const newBroadcastRef = useCallback((element) => {
    if (element !== null) {
      trackAnalyticsEvent(
        element,
        'Broadcast Initiated',
        {
          category: 'Broadcasts',
          planner: trip.isPlanner,
          tripId: trip.id,
          userId: currentUserId,
        },
      );
    }
  }, []);
  const newBroadcastLabel = useMediaQuery(useTheme().breakpoints.down(470)) ? '+ New' : '+ New broadcast'

  useEffect(() => {
    if (!currentUserId) return;
      const timer = setTimeout(() => {
        axios.post(
          tripUserBroadcastViewLogsPath(trip.id),
          { authenticity_token: csrfToken },
        ).catch((error) => {
          Sentry.captureException(error);
        })
      }, 3000);
  }, []);

  return (
    <Box my={4}>
      <SectionHeader title="Broadcasts" note="Here’s what you need to know about your upcoming trip." />
      <Grid container direction="column">
        <Grid item container justifyContent="space-between">
          <FilteringAndSortingBar
            data-test-id="broadcasts-sorting-bar"
            vertical="Broadcasts"
            currentSorting={currentSorting}
            disabled={broadcasts.length === 0}
            formPath={tripBroadcastsPath(trip.id)}
            sortByRadioGroupName="broadcast_filter[sort_by]"
            sortings={sortings}
          />
          <Tooltip
            data-test-id="new-broadcast-tooltip"
            title={trip.isTraveler ? '' : 'Join this trip to send a broadcast'}
          >
            <span>
              <Button
                data-test-id="new-broadcast-button"
                disabled={!trip.isTraveler}
                href={newTripBroadcastPath(trip.id)}
                ref={(element) => newBroadcastRef(element)}
              >
                {newBroadcastLabel}
              </Button>
            </span>
          </Tooltip>
        </Grid>
        {broadcasts.length > 0 &&
          <Grid item container justifyContent="space-between">
            <Typography className={classes.subtitle} data-test-id="sort-label" variant="h3">
              Sort: <span className={classes.sortText}>{currentSortingLabel}</span>
            </Typography>
            <Typography data-test-id="broadcasts-count" className={classes.subtitle} variant="h3">
              {`${broadcasts.length} ${pluralize('broadcast', broadcasts.length)}`}
            </Typography>
          </Grid>
        }
      </Grid>
      <Box my={2}><Divider /></Box>
      {broadcasts.map((broadcast, index) => (
        <Box my={4} key={index}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <BroadcastView
                broadcast={broadcast}
                displayCommentButton
                key={index}
                setComment={() => toggleCommentFormVisibility(index)}
                showLink
                tripId={trip.id}
              />
            </Grid>
            {(broadcast.mostRecentComment || showCommentForm[index]) && (
              <Grid item>
                <MostRecentCommentBox
                  comment={broadcast.mostRecentComment}
                  commentFormPath={broadcast.commentsPath}
                  commentsPath={broadcast.commentsPath}
                  showCommentForm={showCommentForm[index]}
                  showLoadMoreCommentsButton={broadcast.commentsCount > 1}
                  tripId={trip.id}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      ))}
      {broadcasts.length === 0 && (
        <NoSuggestionsYet
          subheading="No broadcasts have been sent to your group yet."
          illustration='celebration'
        />
      )}
    </Box>
  );
};

export default withLayout(BroadcastPage);
