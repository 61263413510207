export const getStaysSearchFilteredEventProps = (filters, method) => {
  const keyValuePairIfHasValue = (key : string, value) => (value ? { [key]: value } : {} );
  const propertyTypes = filters?.propertyTypes.reduce((t, e) => {
    return e.checked ? {...t, [e.label]: e.checked} : t;
  }, {});
  const propertyTypeCount = Object.values(propertyTypes).filter(Boolean).length;
  const isPriceFilterApplied = !!filters?.minPrice || !!filters?.maxPrice;
  const amenitiesSegmentProperties = filters?.amenities?.reduce((total, elem) => ({...total, [elem]: true}), {});

  return ({
    ...propertyTypes,
    ...keyValuePairIfHasValue('propertyTypeCount', propertyTypeCount),
    ...keyValuePairIfHasValue('bathroomCount', filters?.bathroomCount),
    ...keyValuePairIfHasValue('bedroomCount', filters?.bedroomCount),
    ...keyValuePairIfHasValue('rating', filters?.rating),
    ...keyValuePairIfHasValue('addPrice', isPriceFilterApplied),
    ...keyValuePairIfHasValue('minPrice', filters?.minPrice),
    ...keyValuePairIfHasValue('maxPrice', filters?.maxPrice),
    ...amenitiesSegmentProperties,
    ...keyValuePairIfHasValue('instantBook', filters?.instantBook),
    ...keyValuePairIfHasValue('freeCancellation', filters?.freeCancellation),
    ...keyValuePairIfHasValue('hotelRoomCount', filters?.hotelRoomCount),
    method: method,
  })
};
