import React, { useContext } from "react";
import { Box, Divider, Grid, Button } from "@mui/material";
import ActionsButtonGroup from "../../../../shared/ActionsButtonGroup";
import ActivityGroupCarousel from "./ActivityGroupCarousel";
import { ActivityGroupType } from "../../../../shared/TripObjectTypes";
import Linkify from "../../../../shared/Linkify";
import makeStyles from "@mui/styles/makeStyles";
import { Link as TitleLink } from "@mui/material"
import { track as trackAnalyticsEvent } from '../../../../../services/analytics';
import { SegmentContext } from "../../../../Document";
import {
  tripActivityGroupPath,
  editTripActivityGroupPath,
} from "../../../../../routes";

type ActivityGroupProps = {
  activityGroup: ActivityGroupType,
  hideActionButtons?: boolean,
  hideActivityCards?: boolean,
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #C1C1C1',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: theme.spacing(2),
    width: '100%',
  },
  divider: {
    paddingTop: theme.spacing(1),
  },
  header: {
    position: 'relative',
  },
  actions: {
    position: 'relative',
    bottom: '41px',
    left: '17px',
    paddingBottom: theme.spacing(2),
  },
  groupTitle: {
    width: 'calc(100% - 80px)',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: theme.spacing(3),
    position: 'relative',
    left: '3px',
    top: theme.spacing(0.5),
    textAlign: 'left',
    color: 'black',
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: [18, '!important'],
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: "calc(100% - 88px)",
      marginRight: theme.spacing(5),
    },
    maxWidth: '100%',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    overflow: 'hidden',
    wordBreak: 'break-word',
    '&:hover': {
      color: '#3B41B0',
      textDecoration: 'none',
    },
  },
  customLink: {
    color: '#555E64',
    display: 'inline-block',
    zIndex: 2,
    fontSize: 16,
    marginTop: theme.spacing(1),
    '&:hover': {
      textDecoration: ["underline","!important"],
    },
  },
  note: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflowWrap: "anywhere",
    lineClamp: 2,
  },
}));

const ActivityGroup = ({
  activityGroup,
  hideActionButtons,
  hideActivityCards,
}: ActivityGroupProps) => {
  const classes = useStyles();
  const { isPlanner, tripId } = useContext(SegmentContext);

  const trackClick = (eventName) => {
    trackAnalyticsEvent(
      eventName,
      {
        tripId,
        planner: isPlanner,
        objectId: activityGroup.id,
      },
    )
  }

  return (
    <Grid container ml={2} mt={3} mb={4} data-test-id={`activity_group_${activityGroup.id}`}>
      <Grid container direction="column" spacing={2}>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <TitleLink
              href={tripActivityGroupPath(activityGroup.tripId, activityGroup.id)}
              underline="none"
              color="inherit"
              className={classes.groupTitle}
              data-test-id="group-title"
              onClick={()=>trackClick('Activity Group Title Clicked')}
            >
              <Linkify>{activityGroup.title}</Linkify>
            </TitleLink>
            <Box className={classes.actions}>
              {!hideActionButtons && <ActionsButtonGroup
                deletePath={tripActivityGroupPath(activityGroup.tripId, activityGroup.id)}
                editPath={editTripActivityGroupPath(activityGroup.tripId, activityGroup.id)}
                disableDeleteButton={false}
                resourceName="activity group"
              />}
            </Box>
            <TitleLink
              href={tripActivityGroupPath(activityGroup.tripId, activityGroup.id)}
              underline="none"
              color="inherit"
            >
              { activityGroup.description && (
                <Grid item className={classes.note}>
                  <Linkify>{activityGroup.description}</Linkify>
                </Grid>)
              }
            </TitleLink>
            {!hideActivityCards && (
              <>
                <Divider className={classes.divider}/>
                <ActivityGroupCarousel activityGroup={activityGroup}/>
              </>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ActivityGroup;
