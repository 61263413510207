import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';

type DeclineInviteMenuItemProps = {
  onClick: () => void,
};

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: 'auto',
    paddingRight: theme.spacing(2),
  },
}));

const DeclineInviteMenuItem = ({
  onClick,
}: DeclineInviteMenuItemProps) => {
  const classes = useStyles();

  return (
    <MenuItem data-test-id='decline-option' tabIndex={2} onClick={onClick}>
      <ListItemIcon className={classes.listItemIcon}>
        ❌
      </ListItemIcon>
      <ListItemText primary="Decline"/>
    </MenuItem>
  )
};

export default DeclineInviteMenuItem;
