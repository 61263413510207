import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import { searchPath } from "../../../routes";

type FilterBackgroundProps = {
  children: React.ReactNode,
  logoPath: string,
  hasPrompt: boolean,
};

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: '600',
    fontSize: '20px',
    marginBottom: theme.spacing(2),
  },
  aiFiltersBackgroundContainer: {
    overflow: 'hidden',
    flexWrap: 'nowrap',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    width: '100%',
    objectFit: 'cover',
    height: '100vh',
    background: 'linear-gradient(163deg, #E5E7FF, #FFF6E6 100vh)',
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '40px',
    cursor: 'pointer',
  },
  logoFavorites: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'auto',
  },
}));

const AiFilterBackground : React.FC<FilterBackgroundProps> = ({
  children,
  logoPath = searchPath(),
  hasPrompt,
}) => {
  const classes = useStyles();

  return(
    <Grid container direction={hasPrompt ? 'row' : 'column'} className={classes.aiFiltersBackgroundContainer}>
      <Grid item className={classes.backgroundImage}></Grid>
      <Grid item container className={classes.logoFavorites}>
        <img
          src="/assets/logo_icon.svg"
          alt="Troupe logo"
          className={classes.logo}
          onClick={() => window.location.href = logoPath}
        />
      </Grid>
      <Grid item container>
        {children}
      </Grid>
    </Grid>
  )
}

export default AiFilterBackground;
