import React from "react";
import {
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteResourceConfirmationDialog from "./DeleteResourceConfirmationDialog";
import ListItemText from '@mui/material/ListItemText';
import BaseActionsMenu from "./BaseActionsMenu";
import BookingActionsMenuItems from "./BookingActionsMenuItems";

type ActionsMenuProps = {
  deletePath: string,
  onDeleteClick?: () => void,
  onEditClick?: (value: any) => void,
  resourceName: string,
  dense?: boolean,
  displayMarkAsBooked?: boolean,
  newBookingPath?: string,
  updateBookingPath?: string,
};

const ActionsMenu : React.FC<ActionsMenuProps> = ({
  deletePath,
  onDeleteClick = () => {},
  onEditClick,
  resourceName,
  dense = true,
  displayMarkAsBooked = false,
  newBookingPath = '',
  updateBookingPath = '',
}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleDeleteItemClick = () => {
    setDialogOpen(true);
    onDeleteClick();
  };

  return (
    <BaseActionsMenu
      dialogs={
        <DeleteResourceConfirmationDialog
          isOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
          path={deletePath}
          resourceName={resourceName}
        />
      }
    >
      <BookingActionsMenuItems
        displayMarkAsBooked={displayMarkAsBooked}
        newBookingPath={newBookingPath}
        updateBookingPath={updateBookingPath}
      />
      {onEditClick &&
        <MenuItem data-test-id={`edit-${resourceName}`} onClick={onEditClick} dense={dense}>
          <EditIcon fontSize="small" sx={{ mr: 1 }}/>
          <ListItemText>{`Edit ${resourceName}`}</ListItemText>
        </MenuItem>
      }
      <MenuItem data-test-id={`delete-${resourceName}`} onClick={handleDeleteItemClick} dense={dense}>
        <DeleteIcon fontSize="small" sx={{ mr: 1 }}/>
        <ListItemText>{`Delete ${resourceName}`}</ListItemText>
      </MenuItem>
    </BaseActionsMenu>
  );
};

export default ActionsMenu;
