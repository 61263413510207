import React, { useCallback } from "react";
import clsx from "clsx";
import CampaignIcon from '@mui/icons-material/Campaign';
import { DefaultTheme, makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from "../../buttons/Button";
import { trackLink as trackAnalyticsEvent } from '../../../services/analytics';
import { tripBroadcastsPath } from '../../../routes';

type BroadcastButtonProps = {
  currentUserId: string,
  disabled: boolean,
  collapseOnMobile?: boolean,
  tripId: string,
};

const useStyles = makeStyles<DefaultTheme, { isFullWidthBtn: boolean }>((theme) => ({
  root: {
    height: '100%',
    maxHeight: '48px',
  },
  rootMobile: {
    height: '100%',
  },
  btnWidth: {
    width: ({ isFullWidthBtn }) => isFullWidthBtn ? '164px' : '52px',
    [theme.breakpoints.down(370)]: {
      width: ({ isFullWidthBtn }) => isFullWidthBtn ? '145px' : '32px',
      padding: ({ isFullWidthBtn }) => isFullWidthBtn ? '12px 24px' : '12px 20px',
    },
  },
  hidden: {
    display: 'none',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    fontSize: theme.spacing(4),
  },
}));

const BroadcastButton = ({
  currentUserId,
  collapseOnMobile,
  disabled,
  tripId,
}: BroadcastButtonProps) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const hideLabel = collapseOnMobile && isMobile;

  const classes = useStyles({isFullWidthBtn: !hideLabel});

  const broadcastRef = useCallback((element) => {
    if (element !== null) {
      trackAnalyticsEvent(
        element,
        'Broadcasts Opened',
        {
          category: 'Broadcasts',
          source: 'secondary nav',
          tripId: tripId,
          userId: currentUserId,
        },
      );
    }
  }, []);

  return (
    <Button
      data-test-id='broadcast-button'
      href={tripBroadcastsPath(tripId)}
      variant='tertiary'
      ref={(element) => broadcastRef(element)}
      className={clsx(
        classes.root,
        {[classes.rootMobile]: isMobile},
        classes.btnWidth,
      )}>
      <CampaignIcon className={clsx({[classes.icon]: !disabled})} />
      <span className={clsx(
        classes.label,
        {[classes.hidden]: hideLabel},
      )}>
        Broadcasts
      </span>
    </Button>
  );
};

export default BroadcastButton;
