import React from "react";
import {
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  Box,
  Link,
} from "@mui/material";
import { makeStyles, useTheme } from '@mui/styles';
import TooltipBase from "@mui/material/Tooltip"
import InfoIcon from "@mui/icons-material/Info";
import {aiSearchType, CurrentAppliedFilters} from "./Filters";
import { Pagination as PaginationType } from "./ListingsBox"
import { convertSnakeCaseToNormalEnglish, omitFalsyAndUnchecked } from "../../../../services/stringService";
import SeeMoreOrLess from "../../../shared/SeeMoreOrLess";
import SearchFeedbackBox from "../SearchFeedbackBox";
import axios from "axios";
import { GlobalContext } from "../../../Document";
import AlternativesDialog from "./AlternativesDialog";
import { useTrackSERPEvent } from "../../../../services/segmentEvents/useSERPEvents";
import AiFilters from "./AiFilters";

export type AiResultsProps = {
  pagination: PaginationType,
  currentAppliedFilters: CurrentAppliedFilters,
  triggerFilterDialog: (value: boolean) => void,
  modelRequestId?: string,
  aiSearchType?: aiSearchType,
  onFilter: (value: object) => void,
  prompt?: string,
  hideRationaleAndAlternatives?: boolean,
}

export type Alternative = {
  name: string,
  rationale: string,
};

const useStyles = makeStyles(() => ({
  alternativesLink: {
    color: '#000',
    fontSize: 16,
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  arrow: {
    marginLeft: '4px',
    verticalAlign: 'middle',
  },
}));

const AiResults: React.FC<AiResultsProps> = ({
  pagination,
  currentAppliedFilters,
  triggerFilterDialog,
  modelRequestId,
  aiSearchType,
  onFilter,
  prompt,
  hideRationaleAndAlternatives,
}) => {
  const classes = useStyles();
  const { locationName, arrival, departure, amenities, latitude, longitude } = currentAppliedFilters;
  const { troupieAlternatives } = React.useContext(GlobalContext);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { trackSERPLinkEvent } = useTrackSERPEvent();
  const { rationaleTroupieApi } = React.useContext(GlobalContext);

  const tooltip = convertSnakeCaseToNormalEnglish(Object.entries(omitFalsyAndUnchecked(currentAppliedFilters)))
    .map(([filter, value]) => <Typography key={`filter-${filter}`}>{filter}: {value}</Typography>)

  const [rationale, setRationale] = React.useState('');
  const [alternativesOpen, setAlternativesOpen] = React.useState(false);

  const [alternatives, setAlternatives] = React.useState<Alternative[]>([]);

  const requestFilters = {
    amenities, arrival, departure, latitude, location_name: locationName, longitude,
  }

  const requestRationale = (modelRequestId = undefined) => {
    axios.get(
      '/api/search/ai_rationale',
      { params: { filters: {...requestFilters}, query: prompt, search_id: modelRequestId } },
    ).then((response) => {
      setRationale(response.data.rationale);
    }).catch(() => {
      console.log("something wrong happened")
    });
  }

  React.useEffect(() => {
    if (prompt && locationName && !rationaleTroupieApi) {
      requestRationale();
    }
  }, [currentAppliedFilters, prompt]);

  React.useEffect(() => {
    if (prompt && locationName && rationaleTroupieApi && modelRequestId) {
      requestRationale(modelRequestId);
    }
  }, [currentAppliedFilters, prompt, modelRequestId]);

  React.useEffect(() => {
    if (prompt && locationName) {
      axios.get(
        '/api/search/ai_alternatives',
        { params: {model_outputs: {...requestFilters}, prompt} },
      ).then((response) => {
        setAlternatives(response.data.alternatives);
      }).catch(() => {
        console.log("something wrong happened fetching alternatives")
      });
    }
  }, [prompt, currentAppliedFilters]);

  const viewAlternativesRef = React.useCallback((element) => {
    if (element !== null) {
      trackSERPLinkEvent(
        element,
        'View Alternatives Initiated',
        {
          originalDestination: locationName,
          resultCount: pagination.count,
        },
      )
    }
  }, []);

  return <>
    <Grid container item direction='column' justifyContent='space-between' spacing={1} sx={{ maxWidth: 'min(100%, 800px)' }}>
      <Grid item>
        <Typography sx={{display: 'inline-block'}} variant='h2'>Results</Typography>
        {
          !hideRationaleAndAlternatives && (
            <TooltipBase
              arrow
              enterTouchDelay={0}
              sx={{textAlign: 'left !important', width: '100vw !important'}}
              title={tooltip}
            >
              <div style={{display: 'inline-block'}}><InfoIcon sx={{position: 'relative', top: 4, left: 4}}/></div>
            </TooltipBase>
          )
        }
      </Grid>

      {rationale ? (
        <Grid item>
          <SeeMoreOrLess text={rationale} aiSearchType={aiSearchType} currentAppliedFilters={currentAppliedFilters}/>
        </Grid>
      ) : ( !hideRationaleAndAlternatives && (
        <Box sx={{ width: '100%' }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      ) ) }

      {troupieAlternatives && !hideRationaleAndAlternatives && (
        <Grid item>
          <Link
            className={classes.alternativesLink}
            onClick={() => setAlternativesOpen(true)}
            data-test-id="alternativesLink"
            ref={(element) => viewAlternativesRef(element)}
          >
            View alternatives
            <img src="/assets/right-arrow.svg" className={classes.arrow} />
          </Link>
          <AlternativesDialog alternatives={alternatives} open={alternativesOpen} onClose={() => setAlternativesOpen(false)}/>
        </Grid>
      )}

      {isMobile && modelRequestId && (
        <Grid item mt={1}>
          <SearchFeedbackBox
            modelRequestId={modelRequestId}
            aiSearchType={aiSearchType}
            filters={currentAppliedFilters}
            resultCount={pagination.count}
          />
        </Grid>
      )}
    </Grid>

    <AiFilters
      currentAppliedFilters={currentAppliedFilters}
      onHandleAiSearchFilterChange={(newValue) => onFilter({...currentAppliedFilters, ...newValue})}
      onFilter={onFilter}
      triggerFilterDialog={triggerFilterDialog}
      aiSearchType={aiSearchType}
      pagination={pagination}
    />
  </>
}

export default AiResults;
