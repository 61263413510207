import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ButtonBase from "@mui/material/ButtonBase";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

type BackToButtonProps = {
  path: string,
  text: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.link.default,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 600,
    verticalAlign: "middle",
  },
}));

const BackToButton = ({
  path,
  text,
}: BackToButtonProps) => {
  const classes = useStyles();

  return (
    <ButtonBase href={path} className={classes.root}>
      <ArrowBackIosIcon fontSize="small" /> {text}
    </ButtonBase>
  )
};

export default BackToButton;
