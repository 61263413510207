import React from "react";
import clsx from "clsx";
import {
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import StayProperties from "./Stay/StayProperties";
import StayName, {
  StayNameWithLink,
} from "./Stay/StayName";
import StayRating from "./Stay/StayRating";
import {
  Variation,
} from "../../../../shared/TripObject";
import { ExternalListingProps } from "../../../../shared/TripObjectTypes";
import ProviderName from "./shared/ProviderName";
import LiveStayIcon from "./shared/LiveStayIcon";
import { StayType } from "../../../../shared/TripObjectTypes";

const useStyles: () => any = makeStyles((theme): {} => ({
  disabled: {
    color: "#C0C0C0 !important",
  },
  hideDetails: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ratingAndReviews:{
    marginTop: theme.spacing(1.5),
    float: "left",
  },
  stayInfoContainer: {
    width: '100%',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  liveStayIconWrapper: {
    marginLeft: theme.spacing(0.5),
    zIndex: 2,
  },
  starRating: {
    justifyContent: 'left !important',
    display: 'grid',
    paddingBottom: theme.spacing(1),
  },
}));

type StayContentProps = {
  stay: | StayType | ExternalListingProps,
  className?: string,
  hideDetails?: boolean,
  isDisabled?: boolean,
  isNameClickable?: boolean,
  linkNameToProvider?: boolean,
  nameLink?: string,
  nameTargetLink?: 'blank',
  ctaButton?: React.ReactNode,
  pricing?: React.ReactNode,
  variation?: Variation,
  onClick?: () => void,
};

export const StayContent = ({
  stay,
  isDisabled,
  isNameClickable,
  hideDetails,
  linkNameToProvider,
  nameLink,
  nameTargetLink,
  pricing,
  variation,
  ctaButton,
  onClick,
}: StayContentProps) => {
  const classes = useStyles();

  return <>
    <Grid item container direction="column">
      <Grid item container className={classes.stayInfoContainer}>
        <Grid item>
          <ProviderName>
            {stay.provider}
          </ProviderName>
        </Grid>
        <Grid item className={classes.liveStayIconWrapper}>
          {stay.hasAutomaticAvailability && <LiveStayIcon />}
        </Grid>
      </Grid>
      <Grid
        item
        className={clsx({[classes.disabled]: isDisabled})}
        container
        direction="column"
        spacing={1}
        onClick={onClick}
      >
        <Grid item>
          {stay.name && linkNameToProvider ? (
            <StayNameWithLink
              stayUrl={nameLink}
              isClickable={isNameClickable}
              isDisabled={isDisabled}
              name={stay.name}
              target={nameTargetLink}
            />
          ) : (
            <StayName
              isClickable={isNameClickable}
              name={stay.name}
            />
          )}
        </Grid>
        {pricing && (
          <Grid item>
            {pricing}
          </Grid>
        )}
        <Grid
          item
          container
          direction="column"
          spacing={1}
          className={clsx({[classes.hideDetails]: hideDetails})}
        >
          <Grid item>
            <Grid
              className={clsx({[classes.disabled]: isDisabled})}
              container
              direction="row"
            >
              <StayProperties stay={stay} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item data-test-id="star-rating" className={classes.starRating}>
        <StayRating
          stay={stay}
          className={classes.ratingAndReviews}
        />
      </Grid>
        {ctaButton}
    </Grid>
  </>
};

export default StayContent;

