import React, { useContext } from "react";
import ButtonBase from "./ButtonBase";
import { GlobalContext } from "../../../Document";

declare global {
  interface Window { intercomSettings: any; }
}

window.intercomSettings = {
  custom_launcher_selector:'#custom_intercom_icon',
};

const IntercomButton = () => {
  const { isMobileApp } = useContext(GlobalContext);

  return (
    <ButtonBase
      href='mailto:help@troupe.com'
      id={isMobileApp ? 'trigger_email_icon' : 'custom_intercom_icon'}
      hoverState
      tooltipText={'Help'}
    >
      <img
        alt='intercom_icon'
        src="/assets/intercom_icon.svg"
        width='24'
      />
    </ButtonBase>
  );
};

export default IntercomButton;
