import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  FormControlLabel,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DisappearedLoading } from 'react-loadingg';
import StyledCheckbox from "../../../shared/StyledCheckbox";
import Button from "../../../buttons/Button";
import { ListingsContext } from "../StaysSearch";

type SearchCheckBoxProps = {
  hideSearch: boolean,
  isChecked: boolean,
  isLoading: boolean,
  onChange: (checked: boolean) => void,
};
const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(6),
    marginTop: theme.spacing(4),
    position: 'absolute',
    width: '100%',
    zIndex: 101,
    '@supports (margin-top: env(safe-area-inset-top))': {
      margin: `calc(env(safe-area-inset-top) + ${theme.spacing(2)}) env(safe-area-inset-right) 0 env(safe-area-inset-left)`,
    },
  },
  boxWrapper: {
    height: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    marginRight: theme.spacing(4),
  },
  box: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1),
    boxShadow: '0 2px 4px 0 rgba(60, 64, 67, 0.3)',
    height: '100%',
    alignItems: 'center',
  },
  loading: {
    height: '100%',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  searchCheckBox: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    paddingRight: theme.spacing(1),
  },
  searchLabel: {
    fontSize: '14px',
  },
  backButtonWrapper: {
    marginLeft: theme.spacing(4),
  },
  backButton: {
    border: 'none !important',
    borderRadius: theme.spacing(0.75),
    boxShadow: '0 2px 4px 0 rgba(60, 64, 67, 0.3)',
    paddingBottom: `${theme.spacing(1.5)} !important`,
    paddingLeft: `${theme.spacing(1)} !important`,
    paddingRight: `${theme.spacing(1)} !important`,
    paddingTop: `${theme.spacing(1.5)} !important`,
  },
}));

const SearchCheckBox = ({
  onChange,
  isChecked,
  isLoading,
  hideSearch = false,
}: SearchCheckBoxProps) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent={hideSearch ? 'flex-start' : 'center'} className={classes.root}>
      <Grid item className={classes.backButtonWrapper} data-test-id='list-view'>
        <ListingsContext.Consumer>
          {({ toggleView }) => (
            <Button className={classes.backButton} variant="tertiary" onClick={toggleView}>
              <ChevronLeftIcon fontSize="large"/>
            </Button>
          )}
        </ListingsContext.Consumer>
      </Grid>
      {!hideSearch && <Grid item className={classes.boxWrapper} data-test-id='map-search-as-move'>
        <Box className={classes.box}>
          {isLoading ?
            <Grid alignItems="center" className={classes.loading}>
              <DisappearedLoading
                size="small"
                color={"rgba(196, 196, 196, 1)"}
                style={{
                  backgroundColor: '#FFFFFF',
                  height: '100%',
                }}
              />
            </Grid> :
            <FormControlLabel
              className={classes.searchCheckBox}
              control={
                <StyledCheckbox
                  checked={isChecked}
                  onChange={(event) => onChange(event.target.checked)}
                />
              }
              label={<Typography className={classes.searchLabel}>Search as I move map</Typography>}
            />
          }
        </Box>
      </Grid>}
      <Grid item />
    </Grid>
  );
};

export default SearchCheckBox;
