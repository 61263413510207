import React from "react";
import clsx from "clsx";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

type CardButtonProps = {
  children: object,
  className?: string,
  disabled?: boolean,
  testId?: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid #e6e7e8",
    borderRadius: 5,
    "&:focus": {
      outline: "none",
    },
  },
  enabled: {
    border: "none",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.10)",
    },
    "&:focus": {
      boxShadow: "2px 2px 9px 6px rgba(1, 1, 1, 0.1)",
      border: "2px solid #80b4fb",
    },
    "&:active": {
      boxShadow: "2px 2px 5px 0 rgba(1, 1, 1, 0.2)",
      backgroundColor: "#f7f7f8",
    },
  },
}));

const CardButton = ({
  children,
  className,
  disabled = false,
  testId,
}: CardButtonProps) => {
  const classes = useStyles();
  const tabIndexProp = disabled ? {} : {tabIndex: 0};

  return (
    <Box
      className={clsx(
        className,
        classes.root,
        {
          [classes.enabled]: !disabled,
        },
      )}
      data-test-id={testId}
      {...tabIndexProp}
    >
      {children}
    </Box>
  );
}

export default CardButton;
