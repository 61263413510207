import React, { useContext } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { notificationsMap } from "./notificationsMap";
import { UserType } from "./BasicInfoFields";
import { FormContext } from "../../../../shared/RailsForm";
import StyledCheckbox from "../../../../shared/StyledCheckbox";
import Tooltip from "../../../../shared/Tooltip";
import useStateWithPromise from "../../../../../services/useStateWithPromise";
import Button from "../../../../buttons/Button"
import TroupeApp from '../../../../../services/TroupeApp';
import StyledSwitch from "../../../../shared/StyledSwitch";

type NotificationsFieldsProps = {
  isPushNotificationsEnabled: boolean,
  isTemporaryUser: boolean,
  user: UserType,
  disabledSmsSettings: boolean,
};

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: 0,
  },
  notificationsSwitchContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  notificationsSwitchLabel: {
    color: "#061722",
    fontSize: theme.spacing(2),
    fontWeight: 600,
  },
  tableBody: {
    "& td": {
      fontSize: theme.spacing(2),
      padding: theme.spacing(1),
    },
    "& td:first-child": {
      paddingLeft: 0,
    },
  },
  tableHead: {
    "& th": {
      fontSize: theme.spacing(2),
      padding: theme.spacing(1),
      borderTop: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th:first-child": {
      fontWeight: 600,
      paddingLeft: 0,
    },
  },
  pushNotificationsCallout: {
    background: '#F4F5FF',
    border: '1px solid #D4D4D4',
    borderRadius: '8px',
    padding: theme.spacing(2),
  },
  enablePushNotificationsButton: {
    width: 'fit-content',
    height: 40,
  },
}));

const NotificationsFields = ({
  isPushNotificationsEnabled,
  isTemporaryUser = false,
  user,
  disabledSmsSettings,
}: NotificationsFieldsProps) => {
  const classes = useStyles();
  const { tripNotificationsEnabled, notificationsSettingId } = user;
  const notification = TroupeApp.Notification
  const shouldShowPushNotifications = isPushNotificationsEnabled && !isTemporaryUser
  const [
    tripNotifications,
    setTripNotifications,
  ] = useStateWithPromise(tripNotificationsEnabled);

  const [
    notificationsSetting,
    setNotificationsSetting,
  ] = useStateWithPromise(user.notificationsSetting);

  const CheckboxCell = ({ propName, inputName, disabled = false, tooltip = null }) => {
    const checkbox = <span>
      {inputName && (
        <input
          type="hidden"
          value={notificationsSetting[propName].toString()}
          name={`user[notifications_setting_attributes][${inputName}]`}
        />
      )}
      <StyledCheckbox
        className={classes.checkbox}
        checked={notificationsSetting[propName]}
        id={propName}
        disabled={!propName || disabled}
        onChange={(event) => (
          setNotificationsSetting({...notificationsSetting, [propName]: event.target.checked })
          .then(() => onFormChange())
        )}
      />
    </span>;

    return tooltip ? <Tooltip title={tooltip}>{checkbox}</Tooltip> : checkbox;
  };

  const renderEnableTripNotificationsButton = () => (
    <Grid item container justifyContent="space-between" className={classes.notificationsSwitchContainer}>
      <Grid item>
        <Typography className={classes.notificationsSwitchLabel}>
          Enable trip notifications
        </Typography>
      </Grid>
      <Grid item>
        <input
          type="hidden"
          value={tripNotifications.toString()}
          name="user[trip_notifications_enabled]"
        />
        <StyledSwitch
          checked={tripNotifications}
          id="trip-notifications-enabled"
          onChange={(event) => setTripNotifications(event.target.checked).then(() => onFormChange())}
        />
      </Grid>
    </Grid>
  );

  const renderEnablePushNotificationsInstructions = () => (
    <Grid item>
      <Typography data-test-id="enable-push-notifications-instructions">
        To receive push notifications from Troupe, you will need to enable them in your iOS Settings.
        Tap “Troupe” and toggle “Allow Notifications” on.
      </Typography>
    </Grid>
  );

  const renderEnablePushNotificationsCallout = () => (
    <Grid item>
      <Stack spacing={1} className={classes.pushNotificationsCallout}>
        <Typography className={classes.notificationsSwitchLabel}>
          Enable push notifications
        </Typography>
        <Typography>
          Enable push for real-time updates on your trip. Don’t worry, we’ll group similar notifications for less spam.
        </Typography>
        <Button
          className={classes.enablePushNotificationsButton}
          data-test-id="enable-push-notifications"
          detachedFromForm
          onClick={() => {
            notification?.requestPermission()
          }}
          disableRipple
        >
          Enable
        </Button>
      </Stack>
    </Grid>
  );

  const { onFormChange } = useContext(FormContext);

  return (
    <>
      {renderEnableTripNotificationsButton()}
      {shouldShowPushNotifications && (
        <>
          {notification?.permission === 'denied' && renderEnablePushNotificationsInstructions()}
          {notification?.permission === 'notDetermined' && renderEnablePushNotificationsCallout()}
        </>
      )}
      {tripNotifications && (
        <Grid item>
          <input
            type="hidden"
            value={notificationsSettingId}
            name={`user[notifications_setting_attributes][id]`}
          />
          <TableContainer>
            <Table size="small">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>Notify me ...</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">SMS</TableCell>
                  {shouldShowPushNotifications && notification?.permission === 'authorized' && (
                    <TableCell align="right">Push</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {notificationsMap.map(({label, emailProp, smsProp, emailName, smsName, pushProp, pushName}, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {label}
                    </TableCell>
                    <TableCell align="right">
                      <CheckboxCell propName={emailProp} inputName={emailName} />
                    </TableCell>
                    <TableCell align="right">
                      <CheckboxCell
                        propName={smsProp}
                        inputName={smsName}
                        disabled={disabledSmsSettings}
                        tooltip={disabledSmsSettings && "Enter your phone number to receive SMS notifications"}
                      />
                    </TableCell>
                    {shouldShowPushNotifications && notification?.permission === 'authorized' && (
                      <TableCell align="right">
                        {pushProp && (
                          <CheckboxCell
                          propName={pushProp}
                          inputName={pushName}
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
};

export default NotificationsFields;
