import React, { useRef } from 'react';
import { makeStyles } from "@mui/styles";
import PollAnswerButton from '../../shared/PollAnswerButton';
import PollAnswerResponses from "../../shared/PollAnswerResponses";
import RailsForm from '../../../../../shared/RailsForm';
import useStateWithPromise from "../../../../../../services/useStateWithPromise";
import {
  Grid,
  Typography,
} from '@mui/material';

type ResponseType = {
  displayName: string,
  avatarUrl: string,
  plusOneCount: number,
};

export type PollAnswerType = {
  body: string,
  id: string,
  responses: Array<ResponseType>,
  responsesCount: number,
  responsesPercentage: number,
};

type PollAnswersFormProps = {
  pollAnswers: Array<PollAnswerType>,
  path: string,
  currentUserChoice?: PollAnswerType,
  shouldRedirectTo?: string,
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#272727',
    fontSize: 16,
    lineHeigth: '24px',
  },
}));

const PollAnswersForm = ({
  currentUserChoice,
  pollAnswers,
  path,
  shouldRedirectTo,
}: PollAnswersFormProps) => {
  const classes = useStyles();
  const [selectedAnswerId, setSelectedAnswerId] = useStateWithPromise(currentUserChoice?.id);
  const [formMethod, setFormMethod] = useStateWithPromise('POST');
  const formRef = useRef(null);

  const onAnswerClicked = (answerId) => {
    if (answerId !== selectedAnswerId) {
      setSelectedAnswerId(answerId).then(() => formRef.current.submit());
    } else {
      setFormMethod('DELETE').then(() => formRef.current.submit());
    };
  };

  return (
    <RailsForm
      method={formMethod}
      action={path}
      withChangeDetection
      ref={formRef}
    >
      {shouldRedirectTo && <input hidden name='after_response' value={shouldRedirectTo} />}
      <input hidden name='poll_answer_id' value={selectedAnswerId} />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography className={classes.title}>
            Select your answer below
          </Typography>
        </Grid>
        {pollAnswers.map((answer, index) => (
          <React.Fragment key={index}>
            <Grid item data-test-id={`answer-option-${index}`}>
              <PollAnswerButton
                answer={answer}
                onClick={() => onAnswerClicked(answer.id)}
                selected={currentUserChoice?.id == answer.id}
                showProgressBar
              />
              <PollAnswerResponses answer={answer} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </RailsForm>
  );
};

export default PollAnswersForm;
