import React, { useState, useContext } from "react";
import { FormsContext } from '../../../Document';
import { Typography } from "@mui/material";
import performAsyncRequestWithCancelToken from "../../../../services/performAsyncRequestWithCancelToken";
import {
  TravelersType,
} from "../TravelersByStatusType";
import useSegment from "../../../../services/useSegment";
import { apiTripTravelerPath } from '../../../../routes';
import EditDeleteButtonGroup from "./EditDeleteButtonGroup";

type TravelerActionsButtonGroupProps = {
  disableDeleteButton?: boolean,
  travelerId: string,
  tripId: string,
  plusOneCount: number,
  onRemoveTraveler: (travelers: TravelersType[]) => void,
  onRemoveTravelerError: (message: string) => void,
};

const TravelerActionsButtonGroup = ({
  travelerId,
  tripId,
  disableDeleteButton = false,
  plusOneCount,
  onRemoveTraveler,
  onRemoveTravelerError,
}: TravelerActionsButtonGroupProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { csrfToken } = useContext(FormsContext);
  const { trackSegment } = useSegment();

  const removeTraveler = () => {

    performAsyncRequestWithCancelToken({
      method: 'DELETE',
      url: apiTripTravelerPath(tripId, travelerId),
      data: {
        authenticity_token: csrfToken,
      },
      onSuccess: (response) => {
        setDialogOpen(false);
        setSnackbarMessage(response.data.message);
        onRemoveTraveler(response.data.travelersList)
        setSnackbarMessage('');

        trackSegment('Traveler Removed', {
          removedTravelerId: travelerId,
          removedUserId: response.data.userId,
          plusOneRemove: plusOneCount > 0,
          plusOneCount: plusOneCount,
        });
        location.replace(location.pathname.replace(/invites/, 'travelers'));
      },
      onError: (error) => {
        setDialogOpen(false);
        onRemoveTravelerError(error.response?.data.errors);
      },
    });
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
    trackSegment('Traveler Removal Initiated', {});
  };

  const deleteMessageBody = (
    <Typography>
      <span style={{fontWeight: 500, display: 'inline'}}>
        Heads up!
      </span> Deleting this traveler will remove their access to the trip and also delete their plus-ones.
    </Typography>
  )

  return (
    <EditDeleteButtonGroup
      disableDeleteButton={disableDeleteButton}
      handleOpenDialog={handleOpenDialog}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      snackbarMessage={snackbarMessage}
      onConfirmClick={removeTraveler}
      tooltipText='You cannot remove a traveler'
      deleteMessageBody={deleteMessageBody}
      resourceName="traveler"
    />
  );
};

export default TravelerActionsButtonGroup;
