import React, { useContext } from "react";
import {
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../../buttons/Button";
import { accountPath } from "../../../../../routes";
import { useMutation } from "@apollo/client";
import { USER_QUERY } from "../../../../../graphql/queries/users";
import { DISMISS_CALLOUT } from "../TripUpdatesPage";
import { GlobalContext } from "../../../../Document";
import {
  useCalloutDismissed,
  useNotificationCalloutClicked,
} from "../../../../../services/segmentEvents/useCalloutEvents";
import { isMobile } from "react-device-detect";
import { getOperationName } from "../../../../../graphql/utilities";

type handleOnClickType = (path?: string | null) => void

const useStyles = makeStyles((theme) => ({
  manageNotificationsCallout: {
    padding: theme.spacing(2),
    border: '2px solid #D9D9D9',
    borderRadius: '8px',
  },
}))

const ManageNotificationsCallout = () => {
  const classes = useStyles();
  const { setSnackbar } = useContext(GlobalContext);
  const useOnClickEvent = useNotificationCalloutClicked();
  const useDismissEvent = useCalloutDismissed();

  const [ dismissCalloutMutation ] = useMutation(DISMISS_CALLOUT,
    { refetchQueries: [getOperationName(USER_QUERY)] },
  );

  const handleOnClick: handleOnClickType = (path = null) => {
    dismissCalloutMutation({
      onCompleted: ({ userNotificationCalloutDismiss }) => {
        if (userNotificationCalloutDismiss?.errors.length === 0) {
          if (path) {
            window.location.href = path
            useOnClickEvent()
          } else {
            useDismissEvent()
          }
        } else {
          setSnackbar && setSnackbar('Sorry, something went wrong. Please try again.', 3000, 'error')
        }
      },
    })
  };

  return (
    <Grid item>
      <Stack className={classes.manageNotificationsCallout}>
        <Stack flexDirection='row' alignItems='center' justifyContent='space-between'>
          <Typography variant="h3" pb={1}>
            Don’t miss an update!
          </Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
          >
            <CloseIcon
              fontSize="inherit"
              data-test-id="closeManageNotificationButton"
              onClick={() => handleOnClick()}
            />
          </IconButton>
        </Stack>
        <Typography>
          Choose how you want to get notified by managing your notifications on the Account page. Select from email, SMS, or push (iOS app only).
        </Typography>
        <Grid pt={2} alignSelf="auto">
          <Button
            fullWidth={isMobile}
            onClick={() => handleOnClick(accountPath())}
            data-test-id="manageNotificationButton"
          >
            Manage Notifications
          </Button>
        </Grid>
      </Stack>
    </Grid>
  )
};

export default ManageNotificationsCallout;
