import React from "react";
import {
  Box,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { isMobile, isIOS } from "react-device-detect";

export type ModalProps = {
  children?: React.ReactNode,
  closePath: string,
  saveBar?: React.ReactNode,
  subtitle?: React.ReactNode,
  title: React.ReactNode | string,
  withDivider: boolean,
  onClose?: () => void,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '100%',
    maxWidth: '720px',
    '@supports (padding-top: env(safe-area-inset-top))': {
      padding: 'env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left)',
    },
    border: '2px solid #e6e7e8',
    [theme.breakpoints.down("md")]: {
      borderTop: 'none',
      borderRadius: '20px',
      boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.02)',
    },
    width: '100%',
    minWidth: '200px',
    backgroundColor: 'white',
  },
  modalContent: {
    flex: 1,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: '10px',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: 16,
  },
}));

const Modal = ({
  children,
  closePath,
  saveBar,
  subtitle = <></>,
  title,
  withDivider,
  onClose=() => {},
}: ModalProps) => {
  const classes = useStyles();

  const viewportHandler = () => {
    const saveBar = document.getElementById('save-bar');

    if (saveBar) {
      const bottom = window.innerHeight - window.visualViewport.height - window.visualViewport.offsetTop;
      saveBar.style.bottom = `${bottom}px`;
    }
  };

  if (isMobile && isIOS && window.visualViewport) {
    window.visualViewport.addEventListener('resize', viewportHandler);
    window.visualViewport.addEventListener('scroll', viewportHandler);
  }

  return (
    <>
      <Box className={classes.modal} data-test-id='modal'>
        <Box className={classes.modalContent}>
          <Box className={classes.closeButton}>
            <IconButton data-test-id="close-modal-button" href={closePath} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box px={2} pt={2} pb={1}>
            {title}
            { subtitle &&
              <div className={classes.subtitle} data-test-id='modal-subtitle'>
                {subtitle}
              </div>
            }
          </Box>
          {withDivider && <Divider />}
          <Box padding={2}>
            {children}
          </Box>
        </Box>
        {saveBar}
      </Box>
    </>
  );
};

Modal.defaultProps = {
  withDivider: true,
};

export default Modal;
