import React, { useState } from "react";
import {
  Grid,
  Link,
  Typography,
} from "@mui/material";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { ItineraryEventType } from "../../../../shared/TripObjectTypes";
import ActionsMenu from '../../../../shared/ActionsMenu';
import moment from "moment";
import * as routes from '../../../../../routes';
import { ItineraryDialogModal } from "./ItineraryDialogModal";
import ItineraryActivityEventDialog from "./ItineraryActivityEventDialog";
import ItineraryEvent, {
  ClickArea,
  useStyles,
} from "./ItineraryEvent";
import {
  useTrackDeletionInitiated,
  useTrackEventCardClicked,
  useTrackItineraryEventEditInitiated,
} from "../../../../../services/segmentEvents/useItineraryEvents";

const {
  tripItineraryEventPath,
  tripGetActivityPath,
  editTripActivityEventPath,
  tripItineraryDatePath,
} = routes;

type ItineraryActivityEventProps = {
  itineraryDateId?: string,
  activityEvent: ItineraryEventType,
  showAddTimeLink?: boolean,
  showActionsMenu?: boolean,
  isClickeable?: boolean,
  page: string,
};

const ItineraryActivityEvent = ({
  itineraryDateId = null,
  activityEvent,
  showAddTimeLink = false,
  showActionsMenu = false,
  isClickeable = false,
  page,
}: ItineraryActivityEventProps) => {
  const classes = useStyles();
  const fallbackSource = "/assets/activity_fallback_simple.svg";
  const { tripId } = activityEvent;
  const searchParams = new URLSearchParams(window.location.search);
  const startDialogOpen = searchParams.toString().includes(`eventId=${activityEvent.id}`);
  const [isDialogOpen, setIsDialogOpen ] = useState(startDialogOpen);
  const [imageSource, setImageSource] = useState(activityEvent.imageUrl || fallbackSource);
  const tripObjectPath = itineraryDateId ? tripGetActivityPath(tripId, activityEvent.schedulableId, { itinerary_date_id: itineraryDateId, source: page }) : tripGetActivityPath(tripId, activityEvent.schedulableId);
  const trackEventCardClicked = useTrackEventCardClicked();
  const trackItineraryEventEditInitiated = useTrackItineraryEventEditInitiated();
  const trackDeletionInitiated = useTrackDeletionInitiated();

  const onButtonClick = () => {
    if(page === 'tripUpdates'){
      window.location.href = tripItineraryDatePath(tripId, itineraryDateId, {eventId: activityEvent.id});
    } else {
      setIsDialogOpen(!isDialogOpen);
    }
    trackEventCardClicked(activityEvent.schedulableType, activityEvent.schedulableId, page);
  };

  const onEditTimeClick = (method: string) => {
    trackItineraryEventEditInitiated(method, activityEvent.schedulableType, activityEvent.schedulableId, page);
    window.location.href = `${editTripActivityEventPath(tripId, activityEvent.id)}?itinerary_date_id=${itineraryDateId}&source=${page}`;
  };


  const renderTimeText = () => {
    const { startTime, endTime } = activityEvent;

    const formattedStartTime = moment(startTime, "HH:mm").format('h:mm A');
    const formattedEndTime = moment(endTime, "HH:mm").format('h:mm A');
    const activityTimeText = (startTime && endTime) ? `${formattedStartTime} - ${formattedEndTime}` : "No time set";

    return(
      <Typography className={classes.time} data-test-id="itinerary-event-time">
        {activityTimeText}
      </Typography>
    )}

  const renderActionsMenu = () => (
    <ActionsMenu
      onEditClick={() => onEditTimeClick('overflow')}
      deletePath={`${tripItineraryEventPath(tripId, activityEvent.id)}?itinerary_date_id=${itineraryDateId}&source=${page}`}
      resourceName="event"
      onDeleteClick={() => trackDeletionInitiated(activityEvent.schedulableType, activityEvent.schedulableId, page)}
    />
  );

  const renderItineraryDialogModal = () => (
    <ItineraryDialogModal
      title={activityEvent.name}
      fullWidth
      maxWidth="xs"
      imgUrl={imageSource || fallbackSource}
      eventObjectPath={tripObjectPath}
      itineraryEvent={activityEvent}
      message={
        <ItineraryActivityEventDialog
          itineraryDateId={itineraryDateId}
          itineraryEvent={activityEvent}
          renderTimeText={renderTimeText}
        />
      }
      isOpen={isDialogOpen}
      onClose={()=>setIsDialogOpen(false)}
    />
  )

  return (
    <ItineraryEvent
      actionsMenu={renderActionsMenu()}
      showActionsMenu={showActionsMenu}
      itineraryDialog={renderItineraryDialogModal()}
      sx={{ height: 80 }}
    >
      <>
        { isClickeable &&
          <ClickArea
            anchor={`itineraryEvent_${activityEvent.id}`}
            onTripObjectClick={onButtonClick}
            sx={{ height: 80 }}
          />
        }
        <Grid item className={classes.imageWrapper}>
          <img
            alt=""
            onError={() => setImageSource(fallbackSource)}
            src={imageSource || fallbackSource}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-evenly"
          flexWrap="nowrap"
          flexGrow={2}
          sx={{ overflow: 'hidden' }}
        >
          <Grid item data-test-id="itinerary-event-name">
            <Typography className={classes.name}>{activityEvent.name}</Typography>
          </Grid>
          <Grid item className={classes.clickableTime} sx={{zIndex: showAddTimeLink ? 1 : 0}}>
            {showAddTimeLink ? (
              <Link
                onClick={() => onEditTimeClick('add time link')}
                underline="hover"
                className={classes.addTimeLink}
                data-test-id="add-time-link"
              >
                <MoreTimeIcon />
                Add a time
              </Link>
            ) : (
              renderTimeText()
            )}
          </Grid>
        </Grid>
      </>
    </ItineraryEvent>
  );
};

export default ItineraryActivityEvent;
