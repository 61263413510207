import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Stay from "./microsite/HousingPage/Stay";
import { hocRailsAction } from "../../Document";
import TripObjectChoosingModalForm, { ChoosingTypeTripObject } from "./shared/TripObjectChoosingModalForm";
import isSkipVoting from "../../../services/skipVoting";
import * as routes from '../../../routes';
import { StayType } from "../../shared/TripObjectTypes";

const {
  tripStaysPath,
  tripChosenStayPath,
} = routes;

type NewChosenStayPageProps = {
  errors: string[],
  tripId: string,
  tripObjects: StayType[],
};

const usePageStyles = makeStyles(theme => ({
  stay: {
    marginTop: theme.spacing(2),
  },
  chosenStay: {
    border: `2px solid ${theme.palette.general.primary} !important`,
  },
}));

const NewChosenStayPage = ({
  errors,
  tripId,
  tripObjects,
}: NewChosenStayPageProps) => {
  const classes = usePageStyles();

  return (
    <TripObjectChoosingModalForm
      title="Which stay do you want to finalize?"
      subtitle="Don’t worry, you can always edit your choice at a later point."
      tripSitePath={tripStaysPath(tripId)}
      newChosenTripObjectPath={tripChosenStayPath(tripId)}
      errors={errors}
      tripObjects={tripObjects}
      type={ChoosingTypeTripObject.Stay}
      isSkipVoting={isSkipVoting()}
      renderTripObject={({ tripObject, isChosen, chooseFinalTripObject }) => (
        <Stay
          stay={tripObject as StayType}
          className={clsx(classes.stay, {
            [classes.chosenStay]: isChosen,
          })}
          hideDateOptionsAvailability
          isClickable
          key={tripObject.id}
          onStayClick={() => chooseFinalTripObject(tripObject.id)}
        />
      )}
    />
  );
};

// noinspection JSUnusedGlobalSymbols
export default hocRailsAction(NewChosenStayPage);
