import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Switch,
  Typography,
} from "@mui/material"
import { GlobalContext } from "../Document";
import Button, { ButtonVariant } from "../buttons/Button";
import Dialog, { DialogProps } from "./Dialog";
import RailsForm from "./RailsForm";
import makeStyles from "@mui/styles/makeStyles";

export type ConfirmationDialogProps = DialogProps & {
  actionMethod?: string,
  actionPath?: string,
  actionText?: string | React.ReactNode,
  additionalAction?: React.ReactNode,
  buttonVariant?: ButtonVariant,
  confirmationToggleText?: string | React.ReactNode,
  disableWith?: React.ReactNode,
  disabled?: boolean,
  fullWidthButtons?: boolean,
  hideCancelButton?: boolean,
  snackbarMessage?: string,
};

const useStyles = makeStyles((theme) => ({
  confirmationToggleText: {
    fontWeight: 500,
  },
}));

const ConfirmationDialog = ({
  actionMethod,
  actionPath,
  actionText,
  additionalAction,
  buttonVariant,
  confirmationToggleText,
  disableWith,
  disabled,
  fullWidthButtons,
  hideCancelButton,
  isOpen,
  message,
  onClose,
  onConfirm,
  snackbarMessage,
  title,
}: ConfirmationDialogProps) => {
  const [confirmed, setConfirmed] = useState(false);
  const [confirmable, setConfirmable] = useState(confirmationToggleText ? false : true);
  const { setSnackbar } = useContext(GlobalContext);

  useEffect(() => {
    if(snackbarMessage) setSnackbar(snackbarMessage);
  },[snackbarMessage])

  const classes = useStyles();

  let variant = buttonVariant;
  variant ||= actionMethod === "DELETE" ? "danger" : "primary";

  const closeDialog = () => {
    if (onClose) {
      onClose();
    }

    setConfirmed(false);
  };

  const confirmAction = () => {
    onConfirm();
    setConfirmed(true);
  };

  const mustDisplayActionButton = () => !confirmed || (confirmed && !disableWith);
  const mustDisableActionButton = () => confirmed && disableWith;

  return (
    <>
      <Dialog
        actions={(
          <Grid
            container
            direction={fullWidthButtons ? "column" : "row"}
            justifyContent="flex-end"
            spacing={2}
          >
            {confirmationToggleText && (
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography className={classes.confirmationToggleText}>
                    {confirmationToggleText}
                  </Typography>
                </Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    data-test-id="enableConfirmButton"
                    onChange={(event) => setConfirmable(event.target.checked)}
                  />
                </Grid>
              </Grid>
            )}
            {mustDisplayActionButton() && (
              <>
                {actionPath && (
                  <Grid item>
                    <RailsForm
                      action={actionPath}
                      method={actionMethod}
                      withChangeDetection={false}
                    >
                      <Button
                        data-test-id="submitConfirmationButton"
                        disabled={!confirmable || disabled}
                        fullWidth={fullWidthButtons}
                        type="submit"
                        variant={variant}
                      >
                        {actionText}
                      </Button>
                    </RailsForm>
                  </Grid>
                )}
                {onConfirm && (
                  <Grid item>
                    <Button
                      data-test-id="confirmationButton"
                      disabled={!confirmable || disabled}
                      fullWidth={fullWidthButtons}
                      onClick={() => confirmAction()}
                      variant={variant}
                    >
                      {actionText}
                    </Button>
                  </Grid>
                )}
              </>
            )}
            {mustDisableActionButton() && (
              <Grid item>
                {disableWith}
              </Grid>
            )}
            {!hideCancelButton && (
              <Grid item>
                <Button onClick={closeDialog} variant="tertiary" fullWidth={fullWidthButtons}>
                  Cancel
                </Button>
              </Grid>
            )}
            {additionalAction}
          </Grid>
        )}
        data-test-id="confirmationDialog"
        isOpen={isOpen}
        message={message}
        onClose={closeDialog}
        title={title}
      />
    </>
  );
};

export default ConfirmationDialog;
