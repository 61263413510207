import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Badge,
  Box,
  Chip,
  Theme,
  useMediaQuery,
} from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import Button from "../../../buttons/Button";
import SortAndFilterDrawer from "./Filters/SortAndFilter/SortAndFilterDrawer";
import { CurrentAppliedFilters } from "./Filters";
import { ListingsContext } from "../StaysSearch";
import clsx from "clsx";
import { useTheme } from "@mui/styles";
import {
  ALL_AMENITIES_FILTERS,
} from "../../../../constants/amenitiesFilters";

type FiltersButtonProps = {
  onChange: (value: any) => void,
  filters: CurrentAppliedFilters
  aiSearch?: boolean,
};

type StyleProps = {
  aiSearch: boolean,
};

const useStyles = makeStyles<Theme, StyleProps>((theme): {} => ({
  root: {
    border: '1px solid #D9D9D9',
    borderRadius: '5px',
    maxHeight: ({ aiSearch }) => aiSearch ? '90px' : '32px',
    fontSize: 16,
    fontWeight: 600,
  },
  aiSearchFilter: {
    border: '1px solid #D9D9D9',
    borderRadius: '5px',
    height: '32px !important',
    marginRight: theme.spacing(1),
    marginTop: 14,
    padding: '0px 4px !important',
  },
  badge: {
    backgroundColor: "#272727",
    height: 12,
    minWidth: 12,
    borderRadius: 10,
  },
  currentFiltersCounter: {
    marginRight: theme.spacing(1),
    backgroundColor: "#334351",
    color: 'white',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    '& span': {
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      width: theme.spacing(3),
    },
  },
}));

export const FiltersButton = ({
  onChange,
  filters,
  aiSearch = false,
}: FiltersButtonProps) => {
  const classes = useStyles({ aiSearch });
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const {
    openFiltersDrawer,
    closeFiltersDrawer,
    isFiltersDrawerOpen,
  } = React.useContext(ListingsContext);

  const onSubmit = (newFilters) => {
    onChange({ ...newFilters, searchMethod: 'filtersButton' });
  }

  const isBedroomFilterApplied = filters.bedroomCount !== '' && !!filters.bedroomCount;
  const isBathroomFilterApplied = filters.bathroomCount !== '' && !!filters.bathroomCount;
  const isRatingFilterApplied = filters.rating !== '' && !!filters.rating;
  const isPriceFilterApplied = !!filters.minPrice || !!filters.maxPrice;
  const isAmenitiesFilterApplied = filters.amenities ?
    filters.amenities.filter((amenity) => ALL_AMENITIES_FILTERS.includes(amenity)).length > 0 : false;
  const isHotelRoomFilterApplied = filters.hotelRoomCount !== '' && !!filters.hotelRoomCount;

  const appliedFiltersCount : number = (filters.propertyTypes?.filter(type => type.checked).length ?? 0) +
    (isBedroomFilterApplied ? 1 : 0) +
    (isBathroomFilterApplied ? 1 : 0) +
    (isPriceFilterApplied ? 1 : 0) +
    (isRatingFilterApplied ? 1 : 0) +
    (isAmenitiesFilterApplied ? filters.amenities.length : 0) +
    (filters.instantBook ? 1 : 0) +
    (filters.freeCancellation ? 1 : 0) +
    (isHotelRoomFilterApplied ? 1 : 0);

  const numOfFilterChipLabel = Number(appliedFiltersCount)
  const isFilterApplied = numOfFilterChipLabel > 0;

  return <>
    <Box>
      <Button
        className={clsx(
          {
            [classes.aiSearchFilter]: aiSearch,
            [classes.root]: !aiSearch,
          },
        )}
        onClick={openFiltersDrawer}
        data-test-id="propertyTypeFilter"
        variant="tertiary"
      >
        <>
          {aiSearch && (
            <Badge
              data-test-id='dot-badge'
              classes={{ badge: classes.badge }}
              badgeContent=" "
              variant="dot"
              overlap='rectangular'
              invisible={!isFilterApplied}
            >
              {isMobile ?
                <TuneIcon /> :
                <>
                  <TuneIcon style={{paddingRight: 4}}/>
                  <span style={{ paddingRight: 4 }}>Filters</span>
                </>
              }
            </Badge>
            )}
          {!aiSearch && (isFilterApplied ? (
            <>
              <Chip
                label={numOfFilterChipLabel}
                className={classes.currentFiltersCounter}
                data-test-id="currentFiltersCounter"
              />
              {isMobile ? <TuneIcon style={{paddingLeft: 2}}/> : 'Filters'}
            </>
          ) : (
            <>
              <TuneIcon />
              Filters
            </>
          ))}
        </>
      </Button>
    </Box>
    <SortAndFilterDrawer
      filters={filters}
      onClose={closeFiltersDrawer}
      onSubmit={onSubmit}
      open={isFiltersDrawerOpen}
    />
  </>;
};

export default FiltersButton;
