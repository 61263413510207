import React from "react";
import {
  Grid,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import BookButton from "./Stay/BookButton";
import StayAddress from "./Stay/StayAddress";
import StayDetails from "./Stay/StayDetails";
import StayImages from "./Stay/StayImages";
import StayPricing from "./Stay/StayPricing";
import { StayType } from "../../../../shared/TripObjectTypes";
import StayContent from "./StayContent";
import TripObject, { Variation } from "../../../../shared/TripObject";
import ActionsButtonGroup from "../../../../shared/ActionsButtonGroup";
import { TripSiteContext } from "../../../../Document";
import {
  editTripStayPath,
  tripStayPath,
  newTripStayBookingPath,
  editTripStayBookingPath,
} from '../../../../../routes';
import {
  useTrackEditStayClick,
  useHandleMarkAsBookedClick
} from "../../../../../services/segmentEvents/useStayCardEvents";
import ShareButton from "../../../../shared/ShareButton";

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none !important',
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
    "& .stay-title": {
      fontSize: '20px !important',
    },
  },
  price: {
    fontWeight: 600,
    fontSize: 20,
  },
  shareButton: {
    position: 'absolute',
    top: '11px',
    right: '56px',
    zIndex: 3,
  },
}));

type StayPermapageCardProps = {
  stay: StayType,
};

const StayPermapageCard : React.FC<StayPermapageCardProps> = ({
  stay,
}) => {
  const classes = useStyles();
  const { tripId } = React.useContext(TripSiteContext);
  const stayId = stay.uuid ?? stay.id

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  // pricing
  const chosenDate = stay.dateOptions.filter(date => date.isChosenDate);
  const hasChosenDate = chosenDate.length > 0;
  const dateOptionsAvailability = hasChosenDate ? chosenDate : stay.dateOptions;
  const chosenDatePrice = hasChosenDate ? chosenDate[0].price : null;
  const price = stay.price?.price || stay.price?.prices;

  const shouldRenderCarousel = !stay.isDisabled && stay.images.length > 1;
  const firstStayBooking = (stay.stayBookings || []).at(0);

  const onEditStayClick = useTrackEditStayClick(stayId);

  const handleMarkAsBookedClick = useHandleMarkAsBookedClick('stayPermapage');

  const stayPricing = (
    <StayPricing
      available={stay.available}
      averagePricing={stay.averagePricing}
      className={classes.price}
      currency={stay.currency}
      dateOptions={dateOptionsAvailability}
      hasAutomaticAvailability={stay.hasAutomaticAvailability}
      isDisabled={stay.isDisabled}
      price={price}
      chosenDatePrice={chosenDatePrice}
      pricingType={stay.pricingType}
      stayPath={stayId ? editTripStayPath(tripId, stayId) : ''}
      stayId={stayId}
      tripId={tripId}
    />
  );

  return (
    <TripObject
      className={classes.root}
      contentClassName={classes.content}
      noMargin
      testId="tripStay"
      variation={Variation.Vertical}
      header={<>
        <ShareButton className={classes.shareButton} url={stay.publicUrl} />
        <ActionsButtonGroup
          deletePath={tripStayPath(tripId, stayId)}
          editPath={editTripStayPath(tripId, stayId)}
          disableDeleteButton={stay.isChosen}
          testId="stayActions"
          resourceName="stay"
          onEditClick={onEditStayClick}
          onMarkAsBookedClick={handleMarkAsBookedClick}
          displayMarkAsBooked={!firstStayBooking}
          newBookingPath={newTripStayBookingPath(tripId, stayId)}
          updateBookingPath={firstStayBooking ? editTripStayBookingPath(tripId, stayId, firstStayBooking.uuid) : undefined}
        />
    </>}
      visual={
        <StayImages
          images={stay.images}
          isDisabled={stay.isDisabled}
          shouldRenderCarousel={shouldRenderCarousel}
        />
      }
      content={
        <>
          <Grid container direction={isMobile ? "column" : "row"} pb={2} flexWrap="nowrap">
            <Grid item xs={8} container direction="column">
              <StayContent
                stay={stay}
                isDisabled={stay.isDisabled}
                nameLink={stay.publicUrl}
                nameTargetLink='blank'
              />
              <Grid item>
                <StayDetails page='permapage' publicUrl={stay.publicUrl} provider={stay.provider} />
                {stay.googlePlaceUrl && <StayAddress googlePlaceUrl={stay.googlePlaceUrl}/>}
              </Grid>
            </Grid>

            <Grid item xs={4} container direction="column" alignItems={isMobile ? "flex-start" : "flex-end"}>
              <Grid item>
                {stayPricing}
              </Grid>

              {stay.bookable && (
                <Grid item mt={2} sx={{ width: '100%' }}>
                  <BookButton fullWidth url={stay.publicUrl} page='permapage' provider={stay.provider} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default StayPermapageCard;
