import React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import AvatarGroup from '../AvatarGroup';
import { Grid, Typography } from '@mui/material';
import rankLabels from '../rankLabels'
import ExcludesPlusOnesMessage from "../ExcludesPlusOnesMessage";
import clsx from 'clsx';
import { getTravelersRankingsForTripObject, TripObjectRankingProps } from '../../../services/RankingSystem';

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: theme.spacing(1.8),
    fontWeight: 600,
  },
  totalVotes: {
    '&:not(:last-child)': {
      paddingRight: theme.spacing(2),
    },
  },
  votesRow: {
    height: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
  votesCount: {
    fontSize: 16,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.6),
  },
}));

const VotesTable = ({
  travelers,
  objectId,
  rankingType,
}: TripObjectRankingProps) => {
  const classes = useStyles();
  const votes = getTravelersRankingsForTripObject({travelers: travelers, objectId: objectId, rankingType: rankingType });
  let showPlusOnesMessage = false;

  const renderVotesRow = (icon, value, shortLabel) => {
    const rankingVotes = votes.filter(({ ranking }) => ranking === value);
    const hasPlusOnes = rankingVotes.filter(({ plusOneCount }) => plusOneCount > 0).length > 0;

    showPlusOnesMessage = showPlusOnesMessage || hasPlusOnes;

    return <>
      <Grid item container direction="row" xs={1} lg={1}>
        <img data-test-id={icon} src={`/assets/illustrations/${icon}.svg`}/>
        <Typography className={clsx(classes.label, classes.votesCount)}>
          {shortLabel}
        </Typography>
        <Typography data-test-id={`${shortLabel}-count`} className={classes.votesCount}>
          {`(${rankingVotes.length})`}{hasPlusOnes && '*'}
        </Typography>
      </Grid>
      <Grid data-test-id={`${shortLabel}-avatars`} item alignItems="flex-start" xs={9} lg={9}>
        <AvatarGroup max={5} avatars={rankingVotes} />
      </Grid>
    </>;
  };

  return (
    <>
      <Grid container width="130px">
        {rankLabels.map(({icon, value, shortLabel}, index) => (
          <Grid item container direction="column" className={classes.votesRow} key={index}>
            {renderVotesRow(icon, value, shortLabel)}
          </Grid>
        ))}
      </Grid>
      {showPlusOnesMessage && <ExcludesPlusOnesMessage />}
    </>
  )
};

export default VotesTable;
