import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Slider,
} from "@mui/material";
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export const MIN_PRICE_EMPTY_VALUE = 0;
export const MAX_PRICE_EMPTY_VALUE = 1000;

type PriceFilterProps = {
  min?: number | null,
  max?: number | null,
  updateMinPrice: (value) => void,
  updateMaxPrice: (value) => void,
};

interface CustomProps {
  emptyValue: number,
  emptyValueSuffix?: string,
  onBlur: () => void,
  onChange: (event: { target: { value: string } }) => void,
  value: number,
};

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onBlur, onChange, emptyValue, emptyValueSuffix, value, ...other } = props;

    const onValueChange = (values) => {
      onChange({target: { value: values.value }});
    };

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onBlur={onBlur}
        onValueChange={onValueChange}
        decimalScale={0}
        thousandSeparator=""
        valueIsNumericString
        prefix="$"
        allowNegative={false}
        value={value}
        suffix={emptyValueSuffix && value === emptyValue ? emptyValueSuffix : ''}
      />
    );
  },
);

const PriceFilter = ({
  min,
  max,
  updateMinPrice,
  updateMaxPrice,
}: PriceFilterProps) => {
  const [sliderValue, setSliderValue] = useState({
    minPrice: Number(min || MIN_PRICE_EMPTY_VALUE),
    maxPrice: Number(max || MAX_PRICE_EMPTY_VALUE)
  });
  const minDistance = 10;

  useEffect(() => {
    setSliderValue({
      minPrice: Number(min || MIN_PRICE_EMPTY_VALUE),
      maxPrice: Number(max || MAX_PRICE_EMPTY_VALUE),
    })
  }, [min, max])

  const onSliderChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    setSliderValue(prevState => ({
      minPrice: activeThumb === 0 ? Math.min(newValue[0], prevState.maxPrice - minDistance) : prevState.minPrice,
      maxPrice: activeThumb === 1 ? Math.max(newValue[1], prevState.minPrice + minDistance) : prevState.maxPrice,
    }))
  };

  const onMinPriceChange = (price: number | '') => {
    setSliderValue( prevState => ({...prevState, minPrice: Number(price)}));
  };

  const onMaxPriceChange = (price: number | '') => {
    setSliderValue( prevState => ({...prevState, maxPrice: Number(price)}));
  };

  const onMinPriceBlur = () => {
    const validMinPrice = sliderValue.minPrice ? Math.min(Number(sliderValue.minPrice), Number(sliderValue.maxPrice) - minDistance) : MIN_PRICE_EMPTY_VALUE;

    if (sliderValue.minPrice !== validMinPrice) setSliderValue( prevState => ({...prevState, minPrice: validMinPrice}));

    updateMinPrice(validMinPrice === MIN_PRICE_EMPTY_VALUE ? '' : validMinPrice);
  };

  const onMaxPriceBlur = () => {
    const validMaxPrice = sliderValue.maxPrice ? Math.max(Number(sliderValue.maxPrice), Number(sliderValue.minPrice) + minDistance) : MAX_PRICE_EMPTY_VALUE;

    if (sliderValue.maxPrice !== validMaxPrice) setSliderValue( prevState => ({...prevState, maxPrice: validMaxPrice}));

    updateMaxPrice(validMaxPrice === MAX_PRICE_EMPTY_VALUE ? '' : validMaxPrice);
  };

  const onSliderChangeCommitted = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    updateMinPrice(sliderValue.minPrice === MIN_PRICE_EMPTY_VALUE ? '' : sliderValue.minPrice);
    updateMaxPrice(sliderValue.maxPrice === MAX_PRICE_EMPTY_VALUE ? '' : sliderValue.maxPrice);
  };

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h3">Price range (nightly)</Typography>
      </Grid>

      <Grid item container alignItems="center" spacing={1} flexWrap="nowrap">
        <Grid item>
          <TextField
            data-test-id="min-price-field"
            label="min"
            onBlur={onMinPriceBlur}
            onChange={(event) => onMinPriceChange(parseInt(event.target.value) || '')}
            size="small"
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            inputProps={{
              emptyValue: MIN_PRICE_EMPTY_VALUE,
              value: sliderValue.minPrice,
            }}
          />
        </Grid>
        <Grid item>-</Grid>
        <Grid item>
          <TextField
            data-test-id="max-price-field"
            label="max"
            onBlur={onMaxPriceBlur}
            onChange={(event) => onMaxPriceChange(parseInt(event.target.value) || '')}
            size="small"
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            inputProps={{
              emptyValue: MAX_PRICE_EMPTY_VALUE,
              emptyValueSuffix: '+',
              value: sliderValue.maxPrice,
            }}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Slider
          valueLabelDisplay="auto"
          disableSwap
          value={[sliderValue.minPrice, sliderValue.maxPrice]}
          onChange={onSliderChange}
          onChangeCommitted={onSliderChangeCommitted}
          min={MIN_PRICE_EMPTY_VALUE}
          max={MAX_PRICE_EMPTY_VALUE}
        />
      </Grid>
    </Grid>
  );
};

export default PriceFilter;
