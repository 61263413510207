import React, { useCallback, useContext } from 'react';
import { makeStyles } from "@mui/styles";
import ButtonBase from "@mui/material/ButtonBase";
import HoverBorderIconButton from "../../../../buttons/HoverBorderIconButton";
import { ExternalListingProps } from "../../../../shared/TripObjectTypes";
import favoritesStorage from "../../../../../services/favoritesStorage";
import {
  useQuery,
  useMutation,
  gql,
} from "@apollo/client";
import { GlobalContext } from "../../../../Document";
import { getOperationName } from "../../../../../graphql/utilities";
import { ListingsContext } from '../../StaysSearch';
import { GET_FAVORITES_LISTINGS } from '../../../../../graphql/queries/favorites';
import {
  useListingFavoriteEvent,
  useListingFavoriteRemovedEvent,
} from "../../../../../services/segmentEvents/favoriteEvents";

type FavoriteToggleButtonProps = {
  stay: ExternalListingProps,
  favoriteListingId?: string,
};

const useStyles = makeStyles((theme) => ({
  favTest:{
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1,
  },
}));

export const FAVORITE_ADD = gql`
  mutation FavoriteAdd($favoriteId: ID, $listingId: String!, $arrival: String, $departure: String, $minGuests: Int, $bedroomCount: Int) {
    favoriteAdd(input: {
      favoriteId: $favoriteId,
      listingId: $listingId,
      arrival: $arrival,
      departure: $departure,
      minGuests: $minGuests,
      bedroomCount: $bedroomCount,
    }) {
      favoriteList {
        id
      }
    }
  }
`;

export const FAVORITE_REMOVE = gql`
  mutation FavoriteRemove($favoriteId: ID!, $favoriteListingId: ID!) {
    favoriteRemove(input: {
      favoriteId: $favoriteId, favoriteListingId: $favoriteListingId
    }) {
      errors
    }
  }
`;

const FavoriteToggleButton : React.FC<FavoriteToggleButtonProps> = ({
  stay,
  favoriteListingId,
}) => {
  const classes = useStyles();
  const { setSnackbar } = useContext(GlobalContext);
  const { getFavoriteId, setFavoriteId } = favoritesStorage();
  const { filters } = useContext(ListingsContext);

  const favoriteListings = useQuery(GET_FAVORITES_LISTINGS, {
    variables: { favoriteId: getFavoriteId() },
    skip: !getFavoriteId(),
  });

  const isFavorited = favoriteListings.data?.node?.listings.find((listing) => {
    return listing.id === favoriteListingId ||
      (listing.externalListing.externalId === stay.externalId &&
      (listing.arrival || '') === (filters.arrival || '') &&
      (listing.departure || '') === (filters.departure || ''))
  });
  const trackFavoriteEvent = useListingFavoriteEvent(stay.publicUrl);
  const trackFavoriteRemoveEvent = useListingFavoriteRemovedEvent(stay.publicUrl)

  const favoriteImgSrc = isFavorited ? 'assets/heart_favorited.svg': 'assets/heart_unfilled.svg';

  const refetchQueries = [getOperationName(GET_FAVORITES_LISTINGS)];
  const [addFavorite, { loading: addFavoriteLoading }] = useMutation(FAVORITE_ADD, {
    refetchQueries,
    onCompleted: ({ favoriteAdd }) => {
      if(!getFavoriteId()) {
        setFavoriteId(favoriteAdd.favoriteList.id)
      }
      setSnackbar('💖 Added to favorites', 3000)
      trackFavoriteEvent()
    },
  });

  const [favoriteRemove, { loading: removeFavoriteLoading }] = useMutation(FAVORITE_REMOVE, {
    refetchQueries,
    onCompleted: () => {
      setSnackbar('Removed from favorites', 3000)
      trackFavoriteRemoveEvent()
    },
  });

  const favoriteToggle = useCallback((e) => {
    e.preventDefault();
    if(isFavorited) {
      favoriteRemove({
        variables: {
          favoriteId: getFavoriteId(),
          favoriteListingId: favoriteListingId || isFavorited.id,
        },
      });
    } else {
      addFavorite({
        variables: {
          favoriteId: getFavoriteId(),
          listingId: stay.id,
          arrival: filters.arrival,
          departure: filters.departure,
          minGuests: Number(filters.minGuests),
          bedroomCount: Number(filters.bedroomCount),
        },
      });
    }
  }, [isFavorited]);

  return (
    <HoverBorderIconButton
      onClick={favoriteToggle}
      className={classes.favTest}
      data-test-id='fav-toggle-wrapper'
      disabled={addFavoriteLoading || removeFavoriteLoading}
    >
      <img
        data-test-id="fav-button"
        src={favoriteImgSrc}
      />
    </HoverBorderIconButton>
  );
};

export default FavoriteToggleButton;
