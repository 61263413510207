import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FlatButton from "../buttons/FlatButton";

export type StepperInputProps = {
  defaultValue?: number,
  hideSelect?: boolean,
  minValue?: number,
  maxValue?: number,
  onChange?: (value: number) => void,
};

const useStyles = makeStyles((theme) => ({
  numberBox: {
    fontWeight: 600,
    padding: '10px',
    border: '1px solid #C0C0C0',
    backgroundColor: 'white',
    width: theme.spacing(8),
    height: '100%',
    borderRadius: theme.spacing(8),
    textAlign: 'center',
  },
  select: {
    fontWeight: 600,
    fontSize: 'inherit',
    '& .MuiSelect-select': {
      padding: '0 !important',
      '&:focus': {
        background: 'none',
      },
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
}));

const StepperInput = ({
  defaultValue,
  hideSelect,
  minValue,
  maxValue,
  onChange,
}: StepperInputProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue || minValue || 0);
  const showSelect = !hideSelect && minValue !== undefined && maxValue !== undefined;

  const selectOptions = () => {
    const options = [];
    for (let i = minValue; i <= maxValue; i++) {
      options.push(<MenuItem value={i} key={i}>{i}</MenuItem>);
    }
    return options;
};


  const onAdd = () => {
    if (!maxValue || value < maxValue) {
      setValue(value + 1);
    }
  };

  const onSubtract = () => {
    if (!minValue || value > minValue) {
      setValue(value - 1);
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    setValue(Number(event.target.value));
  };

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <Grid container spacing={1} data-test-id="stepperInput">
      <Grid item>
        <FlatButton
          onClick={onSubtract}
          disabled={minValue === value}
          data-test-id="stepperSubtractButton"
        >
          <RemoveIcon />
        </FlatButton>
      </Grid>
      <Grid item>
        <Box className={classes.numberBox}>
          {showSelect ? (
            <Select
              data-test-id='stepper-number-value'
              className={classes.select}
              value={value}
              onChange={handleSelectChange}
              variant='standard'
            >
              {selectOptions()}
            </Select>
          ) : (
            <>{value}</>
          )}
        </Box>
      </Grid>
      <Grid item>
        <FlatButton
          disabled={maxValue === value}
          onClick={onAdd}
          data-test-id="stepperAddButton"
        >
          <AddIcon />
        </FlatButton>
      </Grid>
    </Grid>
  );
};

export default StepperInput;
