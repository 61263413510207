import React, { useState } from "react";
import {
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Theme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { hocRailsAction } from "../../Document";
import Errors from "../../shared/Errors";
import ModalForm from "../../shared/ModalForm";
import { Place } from "../../shared/GooglePlacesAutocomplete";
import { FormContext } from "../../shared/RailsForm";
import InputWithCharacterCounter from "../../shared/InputWithCharacterCounter";
import useStateWithPromise from "../../../services/useStateWithPromise";
import ActivityLocationField from "./ActivityLocationField";
import ActivityPriceField from "./ActivityForm/ActivityPriceField";
import ImageUploader from '../../shared/ImageUploader';
import CommentField from '../trips/microsite/shared/CommentField'
import * as routes from '../../../routes';
import CategoryLabel from "../trips/microsite/shared/CategoryLabel";
import { categoryList } from "./ActivityForm";
import {ActivityCategoryType} from "../../shared/TripObjectTypes";

const {
  tripActivitiesPath,
} = routes;

type ActivityOption = 'address' | 'null'

type NewActivityFormProps = {
  errors: string[],
  tripId: string,
  addActivityByOption?: ActivityOption,
};

export type ObjectFit = 'fill' | 'cover';
export type ObjectFitProps = {
  objectFit: ObjectFit,
}

const useStyles = makeStyles<Theme, ObjectFitProps>((theme) => ({
  label: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  categorySelect: {
    height: '56px',
  },
  imageUploaderWrapper: {
    '& img':{
      objectFit: ({objectFit}) => objectFit,
    },
  },
}));

const NewActivityForm = ({
  errors,
  tripId,
  addActivityByOption,
}: NewActivityFormProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const [objectFit, setObjectFit] = useState<ObjectFit>('fill');
  const classes = useStyles({objectFit});
  const methodValue = addActivityByOption === "address" ? "Google Places" : "Custom"
  const [selectedCategory, setSelectedCategory] = useState<ActivityCategoryType | ''>('')

  const [location, setLocation] = useStateWithPromise({});
  const fallbackSource = "/assets/activity_fallback.svg";
  const CHARACTER_LIMIT = 100;

  const handleLocationChange = (place: Place) => {
    if (place) {
      setLocation({
        location_name: place.name,
        google_place_id: place.id,
        google_place_url: place.url,
        image: place.image,
        latitude: place.latitude,
        longitude: place.longitude,
      });
    }
  };

  const handleImageUpload = (url) => {
    setImageUrl(url)
    setObjectFit(url === fallbackSource ? 'fill' : 'cover')
  }

  const handleCategoryClick = ( event ) => {
    if(event.target.textContent === selectedCategory) {
      setSelectedCategory("")
    }
  }

  const handleCategoryChange = ( event ) => {
    const category = event.target.value
    if (category) {
      setSelectedCategory(category)
    }
  }

  return (
    <ModalForm
      closePath={tripActivitiesPath(tripId)}
      formActionPath={tripActivitiesPath(tripId)}
      formMethod="post"
      saveButtonLabel="Save"
      saveButtonVariant="primary"
      subtitle="Suggest an activity for your group to do on your trip."
      title="Add activity"
      data-test-id='add-activity'
      withChangeDetection
    >
      <FormContext.Consumer>
        {({ onFormChange, submit }) => (
          <Grid container direction="column" spacing={2}>
            { errors.length > 0 && (
              <Grid item><Errors errors={errors} /></Grid>
            )}
            <Grid item>
              <Typography className={classes.label}>
                Name this activity
              </Typography>
              <InputWithCharacterCounter
                name="activity[name]"
                label="Activity name"
                focused
                fullWidth
                characterLimit={CHARACTER_LIMIT}
              />
            </Grid>
            <Grid item className={classes.imageUploaderWrapper}>
              <ImageUploader
                defaultImage={fallbackSource}
                image={imageUrl}
                inputName="activity[image]"
                onImageUpload={handleImageUpload}
                onImageRestoreDefault={() => setObjectFit('fill')}
              />
            </Grid >
            <Grid item>
              <Typography className={classes.label}>
                Link
              </Typography>
              <TextField
                placeholder="Paste your link here"
                fullWidth
                id="activity_link"
                variant="outlined"
                name="activity[link]"
              />
            </Grid>
            <Grid item>
              <Typography className={classes.label}>
                Google Maps location
              </Typography>
              <ActivityLocationField
                addActivityByOption={addActivityByOption}
                currentGooglePlaceId={location.googlePlaceId}
                currentGooglePlaceUrl={location.googlePlaceUrl}
                currentLatitude={location.latitude}
                currentLongitude={location.longitude}
                currentLocationName={location.locationName}
                currentImage={location.googlePlaceImage}
                onChange={handleLocationChange}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.label}>
                Category
              </Typography>
              <Select
                displayEmpty
                fullWidth
                value={selectedCategory}
                data-test-id="activity-category"
                name='activity[category]'
                onChange={handleCategoryChange}
                onClick={handleCategoryClick}
                input={<OutlinedInput />}
                className={classes.categorySelect}
                inputProps={{ 'aria-label': 'Select a category' }}
                renderValue={(selected) => {
                  if (!selected || selectedCategory === '') {
                    return <MenuItem disabled>Select an activity category</MenuItem>;
                  } else {
                    return <CategoryLabel selectBar category={selectedCategory}/>
                  }
                }}
              >
                {categoryList.map((category) => {
                  return (
                    <MenuItem
                      value={category}
                      key={`${category}-category-option`}
                      data-test-id={`${category}-category-option`}
                    >
                      {category}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <ActivityPriceField />
            <CommentField
              fieldName='activity[comments_attributes][0][body]'
             />
            <input
              type="hidden"
              name={"method"}
              value={methodValue}
            />
          </Grid>
        )}
      </FormContext.Consumer>
    </ModalForm>
  );
};

export default hocRailsAction(NewActivityForm);
