import React, { useContext, useState } from 'react';
import { Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import Tooltip from "../../../shared/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { GuestType } from "../../../../types/GuestType";
import Avatar from "../../../avatars/Avatar";
import Button from "../../../buttons/Button";
import Link from "../../../shared/Link";
import CreateGuestForm from "./CreateGuestForm";
import { useQuery } from "@apollo/client";
import { TripSiteContext } from "../../../Document";
import TravelersSkeleton from "./TravelersSkeleton";
import { useAddGuestInitiated, useGuestTravelerToolTipClick } from "../../../../services/segmentEvents/guestEvents";
import AdditionalTravelerActions from "./AdditionalTravelerActions";
import { GUESTS_QUERY } from "../../../../graphql/queries/guests";
import { TRIP_GUEST_DELETE } from "../../../../graphql/queries/guests";

type GuestsSectionProps = {
  isCurrentUserPlanner: boolean,
};

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: 'auto',
  },
  travelerStatusSection: {
    paddingTop: theme.spacing(3),
  },
  guestTravelersSection: {
    backgroundColor: "#ffcc00",
  },
  error: {
    color: '#e44444',
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  helpIcon: {
    color: '#9DAFBE',
    verticalAlign: 'bottom',
  },
  addGuestButton: {
    height: theme.spacing(5),
  },
  addGuestLink: {
    fontWeight: 600,
  },
}));

const GuestsSection = ({
  isCurrentUserPlanner,
}: GuestsSectionProps) => {
  const classes = useStyles();
  const { tripGid } = useContext(TripSiteContext)
  const addGuestInitiatedEvent = useAddGuestInitiated()
  const guestTravelerTooltipEvent = useGuestTravelerToolTipClick()

  const onAddGuestClick = () => {
    setDisplayGuestLink(true)
    addGuestInitiatedEvent()
  }

  const { data: guests_data } = useQuery(
    GUESTS_QUERY, {
      variables: {
        tripId: tripGid,
      },
    },
  )

  const totalGuests = guests_data?.guests.length;
  const showLink = totalGuests > 0;
  const [displayGuestLink, setDisplayGuestLink] = useState(false);

  return (
    <Grid
      alignItems='center'
      container
      className={classes.travelerStatusSection}
      item
    >
      <Grid item>
        <Typography variant='h3'>
          Guests ({totalGuests})
        </Typography>
      </Grid>
      <Grid item ml={1}>
        <Tooltip
          data-test-id="guests-tooltip"placement="bottom-start"
          title={'Guests are included in the headcount for your trip, but they will not be able to vote, suggest, or comment.'}
          onClick={() => guestTravelerTooltipEvent()}
        >
          <HelpIcon className={classes.helpIcon} data-test-id="guest-traveler-tooltip"/>
        </Tooltip>
      </Grid>
      <Grid
          container
          item
      >
        <Grid item pt={1} pb={2}>
          <Typography>
            Guests are travelers going on the trip who won’t be using Troupe.
          </Typography>
        </Grid>
      </Grid>
      {guests_data ? guests_data.guests.map((guest: GuestType, index: number) => {
        return (
        <Grid container spacing={1} key={`guest-${index}`} data-test-id='guest-list' >
          <Grid item py={1}>
            <Avatar>
              <img alt="" src='/assets/guest_avatar.svg' width="40" height="40"/>
            </Avatar>
          </Grid>
          <Grid item mt={1}>
            <Typography>
              {guest.displayName}
            </Typography>
          </Grid>
          {isCurrentUserPlanner &&
            <Grid item className={classes.actions}>
              <AdditionalTravelerActions
                  additionalTraveler={guest}
                  resourceName={'guest'}
                  displayName={'Guest'}
                  mutation={TRIP_GUEST_DELETE}
                  refetchOperation={GUESTS_QUERY}
              />
            </Grid>
          }
        </Grid>
      )}) : <TravelersSkeleton />}
      {!displayGuestLink && (showLink ?
        (isCurrentUserPlanner &&
          <Grid item ml={4}>
            <Link
              data-test-id="add-guest-link"
              className={classes.addGuestLink}
              onClick={onAddGuestClick}
              text='+ Add Guest'
            />
          </Grid>) :
        <Tooltip
          data-test-id="disabled-add-guest-tooltip"
          title={!isCurrentUserPlanner ? 'Only planners can add guests.' : ''}
        >
          <Grid pl={2}>
            <Button
              aria-label='add-guest-button'
              data-test-id='add-guest-button'
              className={classes.addGuestButton}
              disabled={!isCurrentUserPlanner}
              fullWidth
              onClick={onAddGuestClick}
              variant='secondary'
            >
              + Add Guest
            </Button>
          </Grid>
        </Tooltip>
      )}
      {displayGuestLink && (
        <Grid item pb={2}>
          <CreateGuestForm onGuestFormClose={() => setDisplayGuestLink(false)} />
        </Grid>
      )}
    </Grid>

  )
};

export default GuestsSection;
