import React from "react";
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { ButtonBase } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { DefaultTheme } from "@mui/styles";
import { favoritesPath } from "../../routes";
import favoritesStorage from "../../services/favoritesStorage";
import { useQuery } from "@apollo/client";
import { GET_FAVORITES_LISTINGS } from "../../graphql/queries/favorites";
import { useClickedFavoritesEvent } from "../../services/segmentEvents/favoriteEvents";
import buttonColors from "./buttonColors";

type Color = 'white' | 'blue';
type FavoritesButtonProps = {
  color?: Color,
  viewingShared?: boolean,
};

const colors = {
  white: {
    color: '#FFF',
    borderColor: '#FFF',
  },
  blue: {
    color: '#3B41B0',
    borderColor: '#3B41B0',
  },
};

const useStyles = makeStyles<DefaultTheme, { color: Color, isMobile : boolean }>((theme) => ({
  favoritesButton: {
    color: ({ color }) => colors[color].color,
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: '16px',
    border: ({color, isMobile}) => !isMobile && `1.5px solid ${colors[color].borderColor}`,
    borderRadius: ({isMobile}) => !isMobile && '20px',
    padding: ({isMobile}) => !isMobile && '8px 16px',
  },
  favoritesText: {
    minWidth: ({isMobile}) => !isMobile && '110px',
    textAlign: 'center',
    marginLeft: ({isMobile}) => isMobile && '4px',
  },
}));

const FavoritesButton : React.FC<FavoritesButtonProps> = ({
  color = 'white',
  viewingShared = false,
}) => {
  const { getFavoriteId } = favoritesStorage();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const classes = useStyles({color, isMobile});
  const favoriteListings = useQuery(GET_FAVORITES_LISTINGS, {
    variables: { favoriteId: getFavoriteId() },
    skip: !(getFavoriteId() && !viewingShared),
  });

  const favoritesCount = favoriteListings.data?.node?.listings.length || '';
  const trackClickedFavoriteEvent = useClickedFavoritesEvent();

  return(
    <ButtonBase
      className={classes.favoritesButton}
      href={favoritesPath()}
      data-test-id="favorites-button"
      onClick={trackClickedFavoriteEvent}
    >
      <FavoriteIcon /><span className={classes.favoritesText}>Favorites {favoritesCount && `(${favoritesCount})`}</span>
    </ButtonBase>
  );
};

export default FavoritesButton;
