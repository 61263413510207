import React from 'react';
import clsx from 'clsx';
import makeStyles from "@mui/styles/makeStyles";
import SectionCtaButton from './SectionCtaButton';
import CardsOverview from './CardsOverview';
import NoSuggestionsYet from '../../../../shared/NoSuggestionsYet';
import SectionHeader from './SectionHeader';
import { DateOptionType, StayType } from '../../../../shared/TripObjectTypes';

type TripObjectListProps = {
  ctaPath: string,
  ctaText: string,
  heading: string,
  illustration: 'calendar' | 'camper',
  renderCard: (tripObject) => React.ReactNode,
  subheading: string,
  tripObjects: DateOptionType[] | StayType[],
  useRef: (element) => void,
};

const useStyles = makeStyles((theme) => ({
  noMarginTop: {
    marginTop: theme.spacing(-4),
  },
}));

const TripObjectList = ({
  ctaPath,
  ctaText,
  heading,
  illustration,
  renderCard,
  subheading,
  tripObjects,
  useRef,
}: TripObjectListProps) => {
  const classes = useStyles();

  const cardsOverviewItems = tripObjects.map((tripObject) => {
    return { card: tripObject, renderCardFunc: renderCard };
  });

  return (
    <>
      <SectionHeader
        heading={heading}
        subheading={subheading}
        hasNoMarginBottom
      />
      {tripObjects.length > 0 ?
        <CardsOverview items={cardsOverviewItems} />
        :
        <NoSuggestionsYet
          illustration={illustration}
          heading="No suggestions yet."
          subheading="Have an idea? Suggest it below."
        />
      }
      <SectionCtaButton
        href={ctaPath}
        className={clsx({[classes.noMarginTop]: tripObjects.length > 0})}
        ref={(el) => useRef(el)}
        data-test-id="cta-button"
      >
        {ctaText}
      </SectionCtaButton>
    </>
  );
};

export default TripObjectList;
