import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Typography,
} from "@mui/material";
import pluralize from "pluralize";

type CurrentSortingAndCountProps = {
  count: number,
  sorting: string,
  type: string,
};

const useStyles = makeStyles((theme) => ({
  sortText: {
    fontWeight: 'normal',
  },
  count: {
    paddingRight: theme.spacing(2),
  },
}));

const CurrentSortingAndCount = ({
  count,
  sorting,
  type,
}: CurrentSortingAndCountProps) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" data-test-id="currentSortingAndCount">
      <Grid item>
        <Typography variant="h3">
          Sort: <span className={classes.sortText}>{sorting}</span>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" className={classes.count}>
          {count} {pluralize(type, count)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CurrentSortingAndCount;
