import React, { useCallback, useContext } from "react";
import { gql, useMutation, ApolloError } from "@apollo/client";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import Button from "../../../../buttons/Chip";
import RailsForm from "../../../../shared/RailsForm";
import { STAYS_LIST_QUERY } from '../../../../../graphql/queries/stays';
import { GlobalContext } from "../../../../Document"
import { useStyles, SentimentType } from './SentimentButtons'
import { getOperationName } from '../../../../../graphql/utilities'
import * as Sentry from "@sentry/browser";
import { DESTINATIONS_LIST_QUERY } from '../../../../../graphql/queries/destinations';
import { useRankingChangeEvent } from "../../../../../services/segmentEvents/useRankingChangeEvents";

type AsyncSentimentButtonsProps = {
  currentUserSentiment: number | string | null,
  disabled?: boolean,
  sentiments: SentimentType[],
  tripObjectGid: string,
  tripGid: string,
  testId?: string,
  tripObjectType: 'stay' | 'destination',
  page?: 'index' | 'permapage' | null,
  openVotingProgress?: (voteCompleted: boolean) => void,
};

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const tripObjectRank = (objectType: any) => {
  return(
  gql`
  mutation Rank${capitalize(objectType)}($position: Int!, $${objectType}Id: ID!, $tripId: ID!) {
    ${objectType}Rank(input: {
      position: $position,
      ${objectType}Id: $${objectType}Id,
      tripId: $tripId,
    }) {
      ${objectType} {
        id
        currentUserRanking
      }
      errors
      voteCompleted
    }
  }
`);}

export const tripObjectUnrank = (objectType) => (
  gql`
  mutation Unrank${capitalize(objectType)}($${objectType}Id: ID!, $tripId: ID!) {
    ${objectType}Unrank(input: {
      ${objectType}Id: $${objectType}Id,
      tripId: $tripId,
    }) {
      ${objectType} {
        id
        currentUserRanking
      }
      errors
      voteCompleted
    }
  }
`);

const AsyncSentimentButtons = ({
  currentUserSentiment,
  disabled,
  sentiments,
  tripObjectGid,
  tripGid,
  testId,
  tripObjectType,
  page,
  openVotingProgress,
}: AsyncSentimentButtonsProps) => {
  const classes = useStyles();
  const { setSnackbar } = useContext(GlobalContext);
  const onIndexPage = page === 'index';
  const [prevCurrentUserSentiment, setPrevCurrentUserSentiment] = React.useState(currentUserSentiment);
  const trackRankingChangeEvent = useRankingChangeEvent();

  // NOTE: Only refetch queries that have been run so far; stays list on
  //       the index page, and the individual node query for on permapage

  const tripObjectQuery = {
    stay: STAYS_LIST_QUERY,
    destination: DESTINATIONS_LIST_QUERY,
  };

  const query = tripObjectQuery[tripObjectType];

  const refetchQueries = [getOperationName(query)]

  const onError = (error: ApolloError) => {
    const { networkError, graphQLErrors } = error
    if (networkError) {
      Sentry.captureException(networkError)
      setSnackbar('Sorry, something went wrong. Please try again.', 5000, 'error')
    }
    if (graphQLErrors?.length > 0) {
      console.error('got graphqlerrors:', JSON.stringify(graphQLErrors, null, 2))
    }
  }
  const [rankMutationFn, { loading: rankLoading }] = useMutation(
    tripObjectRank(tripObjectType),
    {
      onCompleted: ({ [`${tripObjectType}Rank`]: { errors, voteCompleted }}) => {
        if(errors.length > 0){
          setSnackbar('Sorry, something went wrong. Please try again.', 3000, 'error');
        } else {
          if(prevCurrentUserSentiment === null) {
            openVotingProgress(voteCompleted);
          } else {
            setSnackbar('Success! Your vote was recorded.', 2000);
            trackRankingChangeEvent(capitalize(tripObjectType));
          }
          setPrevCurrentUserSentiment(currentUserSentiment);
        }
      },
      onError,
      refetchQueries,
    },
  )
  const [unrankMutationFn, { loading: unrankLoading }] = useMutation(
    tripObjectUnrank(tripObjectType),
    {
      onCompleted: ({ [`${tripObjectType}Unrank`]: { _, errors }}) => {
        errors.length > 0 ?
          setSnackbar('Sorry, something went wrong. Please try again.', 3000, 'error') :
          setSnackbar('Success! Your vote was removed.', 2000);
        setPrevCurrentUserSentiment(currentUserSentiment);
      },
      onError,
      refetchQueries,
    },
  )

  const loading = rankLoading || unrankLoading

  const optimisticRankResponse = (value: number | null) => ({
    [tripObjectType]: {
      id: tripObjectGid,
      currentUserRanking: value,
      __typename: capitalize(tripObjectType),
    },
    errors: [],
    voteCompleted: false,
  })

  const rankTripObject = (position) => rankMutationFn({
    variables: {
      position: position,
      [`${tripObjectType}Id`]: tripObjectGid,
      tripId: tripGid,
    },
    optimisticResponse: {
      [`${tripObjectType}Rank`]: optimisticRankResponse(position),
    },
  })

  const unrankTripObject = () => unrankMutationFn({
    variables: {
      [`${tripObjectType}Id`]: tripObjectGid,
      tripId: tripGid,
    },
    optimisticResponse: {
      [`${tripObjectType}Unrank`]: optimisticRankResponse(null),
    },
  })

  const AsyncSentimentButton = ({
    hideOnVoting,
    icon,
    iconExt,
    value,
  }: SentimentType) => {
    if (hideOnVoting) return null

    const defaultIconExt = 'svg';
    const isActiveRank = currentUserSentiment === value

    const handleRankSubmit = useCallback((e) => {
      e.preventDefault();

      if (isActiveRank) {
        unrankTripObject()
      } else {
        rankTripObject(value)
      }

      return false
    }, [isActiveRank, value])

    return (
      <Grid item className={clsx(
        classes.gridItem,
        {[classes.indexGridItem]: onIndexPage},
      )}>
        <RailsForm
          action={""}
          onSubmit={handleRankSubmit}
          withChangeDetection={false}
        >
          <Button
            active={isActiveRank}
            className={classes.button}
            data-test-id={`${value}-button`}
            disabled={disabled || loading}
            type="submit"
          >
            <span className={clsx(
              classes.emoji,
              { [classes.disabledEmoji]: disabled || loading},
            )}>
              {<img
                className={classes.icon}
                data-test-id={icon}
                src={`/assets/illustrations/${icon}.${iconExt || defaultIconExt}`}
              />}
            </span>
          </Button>
        </RailsForm>
      </Grid>
    )
  };

  return (
    <Grid
      container
      justifyContent="center"
      data-test-id={testId}
      className={classes.sentimentButtonsContainer}
    >
      {sentiments.map((sentimentProps: SentimentType) => (
        <AsyncSentimentButton {...sentimentProps} key={`${tripObjectGid}${sentimentProps.label}`}/>
      ))}
    </Grid>
  );
}

export default AsyncSentimentButtons;
